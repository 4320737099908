import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Progress,
} from "reactstrap";
import axios from "axios";
import { APIURL } from './Apiurl.js';
import { toast } from 'react-toastify';
import Papa from 'papaparse';

function JobrolemasterAdd() {
  const [activeStep, setActiveStep] = useState(1);
  const [progress, setProgress] = useState(0);

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileData, setFileData] = useState(null);


 
  // ... Existing code for fetchOrgnames and fetchOrgNode

  // ... Existing code for handleNext, handlePrevious, and handleFormSubmit

  // Function to handle file change
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  // Function to submit the selected file
 // ... Existing imports and other code

// Function to submit the selected file
const handleFileSubmit = () => {
    if (!selectedFile) {
      toast.error("Please select a file to import.");
      return;
    }
  
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      try {
        const data = fileReader.result;
        // Parse the CSV data using papaparse library
        const parsedData = Papa.parse(data, {
          header: true, // Use the first row as headers
          skipEmptyLines: true, // Skip empty lines in CSV
        });
  
        // Assuming your CSV has the following headers:
        // org_code, org_node_type, org_node_name, country_id, city_name, parent_org, cost_center
        const formData = parsedData.data.map((row) => ({
          edge_id: row.edge_id,
          machine_id: row.machine_id,
          machine_sub_type: row.machine_sub_type,
          machine_type: row.machine_type,
          tenant_id: row.tenant_id,
          activation_date: row.activation_date,
          app_version: row.app_version,
          imei_code: row.imei_code,
          latitude: row.latitude,
          longitude: row.longitude,
          machine_sub_type_name: row.machine_sub_type_name,
          machine_type_name: row.machine_type_name,
          tenant_name: row.tenant_name,
          created_at: row.created_at,
          updated_at: row.updated_at,
        }));

        const token = sessionStorage.getItem('token');
  const decodedToken = JSON.parse(atob(token.split('.')[1]));
  const roleId = decodedToken['sub'];
  let logintenantId = null;
  if(roleId == 1){
     logintenantId = sessionStorage.getItem('selectedTenantId');
  }else{
     logintenantId = decodedToken['https://hasura.io/jwt/claims']['x-hasura-org-id'];
  }
  
        // Send the data to the Hasura API using axios
        if (formData.length > 0) { // Check if there is data to be inserted
          axios
            .post(APIURL, {
              query: `
                mutation Insertoa_terminal_assignment($formData: [oa_terminal_assignment_insert_input!]!) {
                  insert_oa_terminal_assignment(objects: $formData) {
                    affected_rows
                  }
                }
              `,
              variables: {
                formData: formData,
              },
            },
            {
              headers: {
                'Content-Type': 'application/json',
                 Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
              },
            })
            .then((response) => {
              console.log(response); // Handle the response data as needed
              if (response.data.data && response.data.data.insert_oa_terminal_assignment.affected_rows > 0) {
                setFileData(formData);
                toast.success("CSV data uploaded successfully!");
              } else {
                toast.error("Failed to insert data.");
              }
            })
            .catch((error) => {
              console.error(error); // Handle the error
              toast.error("An error occurred while uploading data.");
            });
        } else {
          toast.warning("No data found in the CSV file.");
        }
      } catch (error) {
        console.error(error);
        toast.error("Error parsing the file. Please check the format.");
      }
    };
  
    fileReader.readAsText(selectedFile);
  };
  

  return (
    <div className="content">
      <Row className="progress-row">
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Import CSV File</h5>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                <Col className="px-md-1" md="6">
                    <FormGroup>
                    
                      <Label for="fileInput" className="custom-file-label">
                        {selectedFile ? selectedFile.name : "Choose CSV file"}
                      </Label>
                      <Input
                        type="file"
                        id="fileInput"
                        className="custom-file-input"
                        accept=".csv"
                        onChange={handleFileChange}
                      />
                    </FormGroup>
                  </Col>
                 
                </Row>
              
                <Row>
                 
                  <Col className="px-md-1" md="6">
                    <FormGroup>
                      <div className="">
                        <Button className="" style={{float: "right", marginTop: "-11px", marginRight: "-168px " }} color="success" onClick={handleFileSubmit}>
                          Submit CSV
                        </Button>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
            <CardFooter>
              {/* Rest of the card footer */}
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default JobrolemasterAdd;
