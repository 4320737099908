import React, { useState, useEffect } from "react";
import "./style.css";
import {
    Row,
    Col,
    TabContent, 
    TabPane,
    Nav,
    NavItem,
    NavLink
} from "reactstrap";
import redirectToLoginIfTokenMissing from './TokenUtility'; 
import MachineBreakdownDiagnostics from "./MachineBreakdownDiagnostics";
import BreakdownByShift from "./BreakdownByShift";
import BreakDownByPart from "./BreakdownByPart";
import BreakDownOperatorInsights from "./BreakdownOperatorInsights";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

function ManagePeople(props) {
  const [activeTab, setActiveTab] = useState("1");
  const [struct, setstruct] = useState([]);
  const [orgstruct, setorgstruct] = useState([]);
  const storedTenantId = sessionStorage.getItem('selectedTenantId');
  console.log("selected Tenant ID:", storedTenantId);
useEffect(() => {
  redirectToLoginIfTokenMissing(); // Call the utility function to validate the token
  // Your component's logic...
}, []);

const token = sessionStorage.getItem('token');
const decodedToken = JSON.parse(atob(token.split('.')[1]));
const tenantId = decodedToken['https://hasura.io/jwt/claims']['x-hasura-org-id'];
  
const [bigChartData, setbigChartData] = React.useState("data1");
const setBgChartData = (name) => {
setbigChartData(name);
};
const pieChartData = [
  { value: 60, color: 'red', label: 'Label 1' },
  { value: 40, color: 'orange', label: 'Label 2' },
];

const data1 = {
    labels: ['Label 1', 'Label 2'],
    datasets: [
      {
        data: [25, 75],
        backgroundColor: ['blue', 'green'], // You can specify colors here
      },
    ],
  };
  const data2 = {
    labels: ['Label 1'],
    datasets: [
      {
        data: [60, 40, 30, 50, 10, 12],
        backgroundColor: ['red', 'orange', 'green', 'blue', 'yellow', 'wheat'], // You can specify colors here
      },
    ],
  };

  const mixedChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };
  const mixedChartData = {
    labels: ['January', 'February', 'March', 'April', 'May'],
    datasets: [
      {
        label: 'Line Data 1',
        type: 'line', // Specify line type
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 2,
        tension: 0.5, 
        data: [7, 16, 25, 35, 54],
      },
      {
        label: 'Line Data 2',
        type: 'line', // Specify line type
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 2,
        tension: 0.5, 
        data: [9, 23, 29, 32, 34],
      },
    ],
  };

  const columns = [
    {
      dataField: "organization_structure_id",
      text: "Sr. No.",
    },
    {
      dataField: "organization_code",
      text: "Machine Name",
    },
    {
      dataField: "organization_node_name",
      text: "Breakdown Type",
    },
    {
      dataField: "country.code",
      text: "Breakdown Subtype",
    },
    {
      dataField: "city_id",
      text: "No of Instances",
    },
    {
      dataField: "parent_organization_id",
      text: "% Time loss Contribution",
    },
    {
      dataField: "cost_centre_code",
      text: "MTBF %",
    },
    {
      dataField: "cost_centre_code",
      text: "MTTR (in hrs)",
    },
    {
      dataField: "cost_centre_code",
      text: "Repair-inter./ext.",
    },
    {
      dataField: "Vendor",
      text: "Vendor",
    },
    {
      dataField: "Category",
      text: "Category",
    },
    {
      dataField: "Category",
      text: "Drill Down",
    },
   
  ];
 
  return (
    <>
        <div className="content">
            <Row>
                {/* <Col lg="4">
                    <Card className="h-100">
                        <div className="donut-chart d-flex align-items-center">
                            <Doughnut data={data1} options={{ responsive: true }} />
                            <Doughnut data={data2} options={{ responsive: true }} />
                        </div>
                    </Card>
                </Col> */}
                {/* <Col lg="6">
                    <Card className="card-chart h-100">
                        <CardHeader>
                            <h5 className="card-category">Downtime Treadline</h5>
                        </CardHeader>
                        <CardBody>
                            <div className="chart-area">
                                <Bar
                                    data={mixedChartData} // Use mixedChartData here
                                    options={mixedChartOptions}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col> */}
            </Row>
            <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={activeTab === "1" ? "active" : ""}
                            onClick={() => setActiveTab("1")}
                            style={{
                              width: "100%",
                              textAlign: "center",
                              backgroundColor:
                                activeTab === "1" ? "#5555e7" : "transparent",
                              color: activeTab === "1" ? "white" : "white",
                            }}
                        >
                            Machine Breakdown Diagnostics
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={activeTab === "2" ? "active" : ""}
                            onClick={() => setActiveTab("2")}
                            style={{
                              width: "100%",
                              textAlign: "center",
                              backgroundColor:
                                activeTab === "2" ? "#5555e7" : "transparent",
                              color: activeTab === "2" ? "white" : "white",
                            }}
                        >
                            Breakdown by Shift
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={activeTab === "3" ? "active" : ""}
                            onClick={() => setActiveTab("3")}
                            style={{
                              width: "100%",
                              textAlign: "center",
                              backgroundColor:
                                activeTab === "3" ? "#5555e7" : "transparent",
                              color: activeTab === "3" ? "white" : "white",
                            }}
                        >
                            Breakdown by Part
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={activeTab === "4" ? "active" : ""}
                            onClick={() => setActiveTab("4")}
                            style={{
                              width: "100%",
                              textAlign: "center",
                              backgroundColor:
                                activeTab === "4" ? "#5555e7" : "transparent",
                              color: activeTab === "4" ? "white" : "white",
                            }}
                        >
                            Operator Wise breakdown Insights
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={activeTab === "5" ? "active" : ""}
                            onClick={() => setActiveTab("5")}
                            style={{
                              width: "100%",
                              textAlign: "center",
                              backgroundColor:
                                activeTab === "5" ? "#5555e7" : "transparent",
                              color: activeTab === "5" ? "white" : "white",
                            }}
                        >
                            Cross Functional Rating Index
                        </NavLink>
                    </NavItem>
            </Nav>

                {/* Content for each tab goes here */}
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <Row>
                            <Col>
                              <MachineBreakdownDiagnostics />
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2">
                        <Row>
                            <Col>
                              <BreakdownByShift />
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="3">
                        <Row>
                            <Col>
                              <BreakDownByPart />
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="4">
                        <Row>
                            <Col>
                              <BreakDownOperatorInsights />
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="5">
                        <Row>
                            <Col>
                              {/* <BreakdownByShift /> */}
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>
        
        </div>
    </>
  );
}

export default ManagePeople;
