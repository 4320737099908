import React, { useState, useEffect } from "react";
import axios from "axios";
import  { APIURL } from './Apiurl.js';
import redirectToLoginIfTokenMissing from './TokenUtility'; 
import {
  TabContent,
  TabPane,
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { Badge } from "reactstrap";

function JobrolemasterAll() {
  const [activeTab, setActiveTab] = useState(1);
  const [machines, setmachines] = useState([]);
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    fetchData();
    redirectToLoginIfTokenMissing();
  }, []);

  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      const roleId = decodedToken['sub'];
      const logintenantId = decodedToken['https://hasura.io/jwt/claims']['x-hasura-org-id'];
      const storedTenantId = sessionStorage.getItem('selectedTenantId');
      const storedMachineId = sessionStorage.getItem('selectedMachineId');
      console.log(roleId === 1);
     
      const response = await axios.post(APIURL, {
        query: `
        query TntOrgmachineAssignTL($machineautoid: Int!) {
            tnt_org_machine_assignment(where: { machine_auto_id: { _eq: $machineautoid } }) {
              machine_auto_id
              machineid
              machine_name
              machine_type
              machine_sub_type
              organization {
                organization_node_name
                organization_structure_id
              }
            plant{
              organization_node_name
                organization_structure_id
            }
              dept{
                organization_node_name
                organization_structure_id
              }
              machinetype{
                machine_profile
              }
              machinesubtype{
                machine_profile
              }
              org_id
              edgeid
              machineid
              is_active
            }
          }
        `,
        variables: {
          machineautoid: storedMachineId,
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
           Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
        },
      });
      const MachinesMaster = response.data.data.tnt_org_machine_assignment.map(
        (structure, index) => ({
          ...structure,
          serialNo: index + 1,
        })
      );
  
      setmachines(MachinesMaster);
        console.log(MachinesMaster);
      } catch (error) {
        console.error(error);
      }
  };

 
function actionFormatter(cell, row) {
    return (
      <div>
        <Link to={`/admin/dashboard/machine-equipment/${row.machine_auto_id}`}>
          <FontAwesomeIcon
            icon={faArrowRightToBracket} // Use the same icon here
            className="btn btn-primary btn-go-deep"
            style={{
              fontSize: "30px !important",
              background: "none",
              pointerEvents: "none",
            }}
          />
        </Link>
      </div>
    );
  }

  function handleEdit(row) {
    // Logic for handling the edit action
    console.log("Editing row:", row);
  }
  const activeFormatter = (cell, row) => {
    const isActive = row.is_active === "1";
  
    return (
      <Badge color={isActive ? "info" : "danger"}>
        {isActive ? "Active" : "Inactive"}
      </Badge>
    );
  };
  const columns = [
    {
      dataField: "serialNo",
      text: "Sr No",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machine_name", // New dataField for the merged column
      text: "Tool/Die Number", // New text for the merged column
      headerStyle: { width: "500px" },
    },
    {
      dataField: "alarm",
      text: "Installation Date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "alarm_type",
      text: "Part No - Production",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "no_of_instances",
      text: "Total Parts Produced",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "avg_time_to_action",
      text: "Previous Maintenance",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "category",
      text: "Schedule Maintenance Date",
      headerStyle: { width: "500px" },
    },
    
    {
      dataField: "operator_present",
      text: "Life Indicator",
      headerStyle: { width: "500px" },
    },
  ];
  

  return (
    <div className="content">
      
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Row>
            <Col md="12">
              <Card>
                
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={machines}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <div>
                        <BootstrapTable
                          {...props.baseProps}
                          filter={filterFactory()}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
      
      </TabContent>
    
    </div>
  );
}

export default JobrolemasterAll;
