import React, { useState, useEffect } from "react";
import redirectToLoginIfTokenMissing from "views/TokenUtility";
import axios from "axios";
import { APIURL } from "views/Apiurl";
import {
  TabContent,
  TabPane,
  Card,
  CardBody,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { QueryApi } from "QueryApi.js";

function TableComponent({ apiData, columns, title }) {
  const [activeTab, setActiveTab] = useState(1);
  const [displayData, setDisplayData] = useState(apiData);
  useEffect(() => {
    setDisplayData(apiData);
  }, [apiData]);

  useEffect(() => {
    redirectToLoginIfTokenMissing();
  }, []);

  ///////////////////////////////////////////
  const [org, setorg] = useState("");
  const [unit, setunit] = useState("");
  const [dept, setdept] = useState("");
  const [depts, setdepts] = useState([]);
  const [machine, setMachine] = useState("");
  const [machines, setMachines] = useState([]);
  const [plants, setplants] = useState([]);
  const [orgs, setorgs] = useState([]);
  const [otherData, setOtherData] = useState([]);
  const [selectedOrgData, setSelectedOrgData] = useState(null);

  const token = sessionStorage.getItem("token");
  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const tenantId =
    decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
  useEffect(() => {
    fetchplant();
  }, [org]);
  const fetchorg = async (logintenantId) => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
      query MyQuery($logintenantId: Int!) {
        tnt_organization_structure(
          where: {
            parent_organization_id: { _eq: 0 },
            tenantid: { _eq: $logintenantId }
          }
        ) {
          organization_structure_id
          organization_node_name
        }
      }
    `,
          variables: {
            logintenantId: parseInt(logintenantId),
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );
      const fetchedOrgs = response.data.data.tnt_organization_structure;
      setorgs(fetchedOrgs);
      const parentIds = fetchedOrgs.map((org) => org.organization_structure_id);
      const childResponse = await axios.post(
        APIURL,
        {
          query: `
      query MyQuery($parentIds: [Int!]) {
        tnt_organization_structure(
          where: {
            parent_organization_id: { _in: $parentIds }
          }
        ) {
          organization_structure_id
          organization_node_name
        }
      }
    `,
          variables: {
            parentIds: parentIds,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      const childOrgs = childResponse.data.data.tnt_organization_structure;
      const updatedOtherData = otherData.map((row) => {
        const matchingOrg = childOrgs.find(
          (org) => org.organization_structure_id === row.parent_organization_id
        );

        if (matchingOrg) {
          return {
            ...row,
            parent_organization_id: matchingOrg.organization_structure_id,
          };
        }

        return row;
      });

      setOtherData(childOrgs);
    } catch (error) {}
  };
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["sub"];
    let logintenantId = null;

    if (roleId == 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
    }

    fetchorg(logintenantId);
  }, []);
  const fetchplant = async () => {
    if (org) {
      // Only fetch if org is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
        query MyQuery($p: Int!) {
          tnt_organization_structure(where: {parent_organization_id: {_eq: $p}}) {
            organization_structure_id
            organization_node_name
          }
        }
      `,
            variables: {
              p: org,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        setplants(response.data.data.tnt_organization_structure);
      } catch (error) {}
    }
  };
  useEffect(() => {
    fetchdept();
  }, [unit]);
  const fetchdept = async () => {
    if (unit) {
      // Only fetch if org is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
        query MyQuery($d: Int!) {
          tnt_organization_structure(where: {parent_organization_id: {_eq: $d}}) {
            organization_structure_id
            organization_node_name
          }
        }
      `,
            variables: {
              d: unit,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        setdepts(response.data.data.tnt_organization_structure);
      } catch (error) {}
    }
  };
  useEffect(() => {
    fetchMachine();
  }, [dept]);
  const fetchMachine = async () => {
    if (dept) {
      // Only fetch if dept is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
        query MyQuery($m: Int!) {
         tnt_org_machine_assignment(where: {dept: {organization_structure_id: {_eq: $m}}} ) {
            machine_name
          }
        }
      `,
            variables: {
              m: dept,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        setMachines(response.data.data.tnt_org_machine_assignment || []);
      } catch (error) {}
    }
  };

  const handleOrgSelect = async (selectedOrgId) => {
    if (selectedOrgId) {
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
        query GetCostCenter($orgId: Int!) {
          tnt_organization_structure(where: { organization_structure_id: { _eq: $orgId } }) {
            cost_centre_code
          }
        }
      `,
            variables: {
              orgId: selectedOrgId,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );

        if (
          response.data &&
          response.data.data &&
          response.data.data.tnt_organization_structure &&
          response.data.data.tnt_organization_structure.length > 0
        ) {
          setSelectedOrgData(response.data.data.tnt_organization_structure[0]);
        } else {
          setSelectedOrgData(null);
        }
      } catch (error) {}
    } else {
      setSelectedOrgData(null);
    }
    setorg(selectedOrgId);
  };

  /////////////////////////////////////////////

  const filteredApiData = apiData.filter((data) => data.machineid === machine);

  return (
    <div className="content">
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Row>
            <Col lg="2" md="3" sm="4" xs="6" className="ml-3">
              <h3>{title}</h3>
            </Col>
            <Col lg="2" md="3" sm="4" xs="6">
              <FormGroup>
                <Input
                  type="select"
                  value={org}
                  onChange={(e) => handleOrgSelect(e.target.value)}
                >
                  <option value="">Select Organization</option>
                  {otherData.map((org) => (
                    <option
                      key={org.organization_structure_id}
                      value={org.organization_structure_id}
                    >
                      {org.organization_node_name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col lg="2" md="3" sm="4" xs="6">
              <FormGroup>
                <Input
                  type="select"
                  value={unit}
                  onChange={(e) => setunit(e.target.value)}
                >
                  <option value="">Select Unit/Plant</option>
                  {plants.map((plant) => (
                    <option
                      key={plant.organization_structure_id}
                      value={plant.organization_structure_id}
                    >
                      {plant.organization_node_name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col lg="2" md="3" sm="4" xs="6">
              <FormGroup>
                <Input
                  type="select"
                  value={dept}
                  onChange={(e) => setdept(e.target.value)}
                >
                  <option value="">Select department</option>
                  {depts.map((dpt) => (
                    <option
                      key={dpt.organization_structure_id}
                      value={dpt.organization_structure_id}
                    >
                      {dpt.organization_node_name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col lg="2" md="3" sm="4" xs="6">
              <FormGroup>
                <Input
                  type="select"
                  value={machine}
                  onChange={(e) => setMachine(e.target.value)}
                >
                  <option value="">Select Machine</option>
                  {machines.map((mc) => (
                    <option
                      key={mc.organization_structure_id}
                      value={mc.organization_structure_id}
                    >
                      {mc.machine_name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col lg="1" md="1" sm="1" xs="1">
              <Col lg="2">
                <Button
                  lg="2"
                  className="btn btn-secondary"
                  onClick={() => setDisplayData(filteredApiData)}
                >
                  Submit
                </Button>
              </Col>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={apiData}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <div>
                        <BootstrapTable
                          {...(props.baseProps && props.baseProps)}
                          filter={filterFactory()}
                          pagination={paginationFactory()}
                          data={displayData || []}
                          // data={filteredApiData || []}
                          headerClasses="sorted-asc sorted-desc"
                          wrapperClasses="table-responsive"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}
export default TableComponent;
