import React, { useState } from "react";
import { Tooltip } from "@material-ui/core";
import { Tree } from "react-d3-tree";
import "./Mindmap.css";
import Popup from './MindMapPopup.js'

const data = {
  name: "CEO's Insights",
  children: [
    {
      name: "Cost or Finance",
      children: [
                {
                  // child of Cost and Finance
                  name: "Consumption",
                  description: "Description",
                },
                {
                  name: "Capacity",
                },
                {
                  name: "BreakDowns",
                },
                {
                  name: "Defect/Reject",
                },
              ],
    },
    {
      name: "Strategic Decisions",
      children: [
                {
                
                 name: "Breakdown Reason Analyasis",
                 description: "Description",
                },
                {
                  name: "Capacity Utilization",
                },
                {
                  name: "Revenue to Wage",
                },
                {
                  name: "Cost of Production",
                },
                {
                  name: "Super MQL Vs Bozo's",
                },
              ],
    },
    {
      name: "Operations",
      children: [
                {
                  // child of operations
                  name: "Production Shortfall",
                  description: "Description",
                },
                {
                  name: "MTBF / MTTF / MTTR",
                },
                {
                  name: "Defect Density",
                },
                {
                  name: "Cycle Time Deviation",
                },
                {
                  name: "Machine Utilization",
                },
                {
                  name: "Asset Life Hurnover ",
                },
                {
                  name: "Consumption",
                }
      ]
    },
              
    {
      name: "People",
      children: [
                {
                  // child of People
                  name: "Average Machine Time ",
                  description: "Description",
                },
                {
                  name: "Number of Errors by People",
                },
                {
                  name: "Peoples vs Outcomes",
                },
                {
                  name: "Skill Shortful",
                },
                {
                  name: "Absenteson",
                },
              ],
    },
    {
      name: "Business Efficiency",
      children: [
                {
                  // child of Cost and Finance
                  name: "O.E.E",
                  description: "Description",
                },
                {
                  name: "Production to Wage Ratio",
                },
                {
                  name: "Defects Arrival Rate",
                },
                {
                  name: "Production Recurrence Rate",
                },
                {
                  name: "Production Costs",
                },
                {
                  name: "Overtime Rate",
                },
                {
                  name: "Asset Utilization",
                },
                {
                  name: "Maintainance Efficiency",
                },
              ],
    },
    {
      name: "Losses",
      children: [
                {
                  // childes of Losses
                  name: "Quality Loss",
                  description: "Description",
                },
                {
                  name: "Cycletime Loss",
                },
                {
                  name: "Production Loss",
                },
                {
                  name: "Productivity Loss",
                },
                {
                  name: "Availability Loss",
                },
                {
                  name: "Capacity Loss",
                },
                {
                  name: "Downtime Loss",
                },
              ],
    },
  ]
};

const containerStyles = {
  width: "75vw",
  height: "1200px !important",
  overflowY: "auto",
  overflowX: "auto"
};


const renderNodeWithCustomEvents = ({ nodeDatum, toggleNode, wrapper, setClickedNode }) => {
  // Horizontal orientation
  // let y = 40;
  // let x = -10;

  const handleNodeClick = () => {
    if (!nodeDatum.children) {
      setClickedNode(nodeDatum);
    } else {
      toggleNode();
    }
  };

  return (
    <g ref={wrapper} onClick={handleNodeClick} >
      <Tooltip
        
        // title={<Typography>{nodeDatum.name}</Typography>}
        // arrow
        // placement="right"
      >
        <circle
          r="20"
          // fill={nodeDatum.children ? "" : ""}
          // onClick={handleNodeClick}
        />
      </Tooltip>
      <text fill="white" strokeWidth="0" x={20} y={6}> 
        {nodeDatum.name}
      </text>
    </g>
  );
};

export default function App() {
  const [clickedNode, setClickedNode] = useState(null);

  return (
    <div style={containerStyles}>
      <Tree
        data={data}
        collapsible={true}
        enableLegacyTransitions={true}
        initialDepth={"1"}
        orientation="horizontal"
        depthFactor={220}
        translate={{ x: 150, y: 450 }}
        shouldCollapseNeighborNodes={false}
        nodeSize={{ y: 80 }}
        renderCustomNodeElement={(props) =>
          renderNodeWithCustomEvents({ ...props, setClickedNode })
        }
        zoomable={false}
        separation={{ siblings: 0.7, nonSiblings: 0.9 }} 
      />

      {clickedNode && <Popup nodeData={clickedNode} onClose={() => setClickedNode(null)} />}
    </div>
  );
}

