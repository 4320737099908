import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from "reactstrap";
import "bootstrap/dist/css/bootstrap.css";

function OrgJobDetails({ employeeData }) {
  const [date, setDate] = useState("");
  const [purpose, setpurpose] = useState("");
  const [org, setorg] = useState("");
  const [orgs, setorgs] = useState([]);
  const [unit, setunit] = useState("");
  const [otherData, setOtherData] = useState([]);
  const [plants, setplants] = useState([]);
  const [selectedOrgData, setSelectedOrgData] = useState(null);
  const reportingManager = employeeData.reportingmanager || {};

  return (
    <div className="content">
      <Row className="progress-row">
        <Col md="12">
          <Card>
            <CardHeader></CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col className="px-md-1" md="2">
                    <FormGroup>
                      <Label for="Holiday">Job ID</Label>
                      <Input
                        type="text"
                        defaultValue={employeeData.jobrole?.job_id || ""}
                        readOnly
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col className="px-md-1" md="3">
                    <FormGroup>
                      <Label for="Holiday">Job</Label>
                      <Input
                        type="text"
                        defaultValue={employeeData.jobrole?.job_role || ""}
                        readOnly
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col className="px-md-1" md="2">
                    <FormGroup>
                      <Label for="Holiday">Position</Label>
                      <Input
                        type="text"
                        defaultValue={employeeData.emp_position}
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                  <Col className="px-md-1" md="3">
                    <FormGroup>
                      <Label for="Holiday">Department</Label>
                      <Input
                        type="Text"
                        defaultValue={
                          employeeData.dept?.organization_node_name || ""
                        }
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="px-md-1" md="2">
                    <FormGroup>
                      <Label for="Holiday">Unit</Label>
                      <Input
                        type="Text"
                        defaultValue={
                          employeeData.unit?.organization_node_name || ""
                        }
                        readOnly
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col className="px-md-1" md="3">
                    <FormGroup>
                      <Label for="Holiday">Organization</Label>
                      <Input
                        type="text"
                        defaultValue={
                          employeeData.organization?.organization_node_name ||
                          ""
                        }
                        readOnly
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col className="px-md-1" md="2">
                    <FormGroup>
                      <Label for="Holiday">Reporting Manager</Label>
                      {reportingManager && (
                        <Input
                          type="text"
                          defaultValue={`${reportingManager.first_name || ""}${reportingManager.last_name || ""}`}
                          readOnly
                        />
                      )}
                    </FormGroup>
                  </Col>
                  <Col className="px-md-1" md="3">
                    <FormGroup>
                      <Label for="Holiday">Date of Joining</Label>
                      <Input
                        type="Text"
                        defaultValue={employeeData.doj}
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="px-md-1" md="2">
                    <FormGroup>
                      <Label for="Holiday">Education</Label>
                      <Input
                        type="text"
                        defaultValue={employeeData.education}
                        readOnly
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col className="px-md-1" md="3">
                    <FormGroup>
                      <Label for="Holiday">Certification</Label>
                      <Input
                        type="text"
                        defaultValue={employeeData.certification}
                        readOnly
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col className="px-md-1" md="2">
                    <FormGroup>
                      <Label for="Holiday">Skills</Label>
                      <Input
                        type="text"
                        defaultValue={
                          employeeData.emptype && employeeData.emptype.code
                            ? employeeData.emptype.code
                            : ""
                        }
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                  <Col className="px-md-1" md="3">
                    <FormGroup>
                      <Label for="Holiday">Compentancies</Label>
                      <Input type="Text" readOnly />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="px-md-1" md="3">
                    <FormGroup>
                      <Label for="Holiday">Special Skills</Label>
                      <Input type="Text" readOnly />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
            <CardFooter>
              <div className="text-right">
                <></>
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default OrgJobDetails;
