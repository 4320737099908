import React from "react";
import { useState } from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Progress,
  } from "reactstrap";

function TenantOrganizationMaster() {
    const [activeStep, setActiveStep] = useState(1);
    const [checked, setChecked] = useState(false);
    const [progress, setProgress] = useState(0);
  
    const handleChange = (event) => {
      setChecked(event.target.checked);
    };
  
    const handleNext = () => {
      if (activeStep === 1 && progress === 0) {
        setProgress(50);
      } else if (activeStep === 2 && progress === 50) {
        setProgress(100);
      }
      setActiveStep(activeStep + 1);
    };
  
    const handlePrevious = () => {
      setActiveStep(activeStep - 1);
    };
  
    const handleSubmit = () => {
      // Handle form submission
      console.log("Form submitted");
    };
    const renderStepContent = () => {
      switch (activeStep) {
        case 1:
          return (
            <>
                <Row>
                    <Col className="pr-md-1" md="6">
                        <FormGroup>
                            <label>Tenant ID</label>
                            <Input
                                placeholder="Tenant ID"
                                type="text"
                            />
                        </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                        <FormGroup>
                            <label>Org Node ID</label>
                            <Input
                                placeholder="Org Node ID"
                                type="text"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-md-1" md="6">
                        <FormGroup>
                            <label>Organization Node Name</label>
                            <Input
                                placeholder="Organization Node Name"
                                type="text"
                            />
                        </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                        <FormGroup>
                            <label>Organization Short Name</label>
                            <Input
                                placeholder="Organization Short Name"
                                type="text"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-md-1" md="6">
                        <FormGroup>
                            <label>Organization Code (External)</label>
                            <Input
                                placeholder="Organization Code (External)"
                                type="text"
                            />
                        </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                        <FormGroup>
                            <label>Description</label>
                            <Input
                                placeholder="Description"
                                type="text"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-md-1" md="6">
                        <FormGroup>
                            <label>Organization Type</label>
                            <Input
                                placeholder="Organization Type"
                                type="text"
                            />
                        </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                        <FormGroup>
                            <label>Org Code ID</label>
                            <Input
                                placeholder="Org Code ID"
                                type="text"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-md-1" md="6">
                        <FormGroup>
                            <label>Organization Category</label>
                            <Input
                                placeholder="Organization Category"
                                type="text"
                            />
                        </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                        <FormGroup>
                            <label>Production Process Category</label>
                            <Input
                                placeholder="Production Process Category"
                                type="text"
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </>
          );
        case 2:
          return (
            <>
            <Row>
                <Col className="pr-md-1" md="6">
                    <FormGroup>
                        <label>Country ID</label>
                        <Input
                            placeholder="Country ID"
                            type="text"
                        />
                    </FormGroup>
                </Col>
                <Col className="pl-md-1" md="6">
                    <FormGroup>
                        <label>State ID</label>
                        <Input
                            placeholder="State ID"
                            type="text"
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col className="pr-md-1" md="6">
                    <FormGroup>
                        <label>City ID</label>
                        <Input
                            placeholder="City ID"
                            type="text"
                        />
                    </FormGroup>
                </Col>
                <Col className="pl-md-1" md="6">
                    <FormGroup>
                        <label>Parent Org ID</label>
                        <Input
                            placeholder="Parent Org ID"
                            type="text"
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col className="pr-md-1" md="6">
                    <FormGroup>
                        <label>Cost Center Code</label>
                        <Input
                            placeholder="Cost Center Code"
                            type="text"
                        />
                    </FormGroup>
                </Col>
                <Col className="pl-md-1" md="6">
                    <FormGroup>
                        <label>Is Active</label>
                        <Input
                            placeholder="Is Active"
                            type="checkbox"
                            checked={checked}
                            onChange={handleChange}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col className="pr-md-1" md="6">
                    <FormGroup>
                        <label>Is Deactive</label>
                        <Input
                            placeholder="Is Deactive"
                            type="checkbox"
                            checked={checked}
                            onChange={handleChange}
                        />
                    </FormGroup>
                </Col>
                <Col className="pl-md-1" md="6">
                    <FormGroup>
                        <label>Is Root Node</label>
                        <Input
                            placeholder="Is Root Node"
                            type="checkbox"
                            checked={checked}
                            onChange={handleChange}
                        />
                    </FormGroup>
                </Col>
            </Row>
            </>
          );
        case 3:
          return (
            <>
        <Row>
                <Col className="pr-md-1" md="6">
                    <FormGroup>
                        <label>Organization Node Leader</label>
                        <Input
                            placeholder="Organization Node Leader"
                            type="text"
                        />
                    </FormGroup>
                </Col>
                <Col className="pl-md-1" md="6">
                    <FormGroup>
                        <label>Plant/BUID</label>
                        <Input
                            placeholder="Plant/BUID"
                            type="text"
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col className="pr-md-1" md="6">
                    <FormGroup>
                        <label>Created On</label>
                        <Input
                            placeholder="Created On"
                            type="date"
                        />
                    </FormGroup>
                </Col>
                <Col className="pl-md-1" md="6">
                    <FormGroup>
                        <label>Created By</label>
                        <Input
                            placeholder="Created By"
                            type="text"
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col className="pr-md-1" md="6">
                    <FormGroup>
                        <label>Modified On</label>
                        <Input
                            placeholder="Modified On"
                            type="date"
                        />
                    </FormGroup>
                </Col>
                <Col className="pl-md-1" md="6">
                    <FormGroup>
                        <label>Modified By</label>
                        <Input
                            placeholder="Modified By"
                            type="text"
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col className="pr-md-1" md="6">
                    <FormGroup>
                        <label>Modification Reason</label>
                        <Input
                            placeholder="Modification Reason"
                            type="text"
                        />
                    </FormGroup>
                </Col>
            </Row>
            </>
          );
        default:
          return null;
      }
    };
    const calculateProgress = () => {
      return Math.round(((activeStep - 1) / 2) * 100); // Update the denominator to match the total number of steps minus one
    };
    
  
    const renderProgressBarDestinations = () => {
      const step1Class = activeStep >= 1 ? "progress-destination-active" : "";
      const step2Class = activeStep >= 2 ? "progress-destination-active" : "";
      const step3Class = activeStep >= 3 ? "progress-destination-active" : "";
      const step4Class = activeStep >= 4 ? "progress-destination-active" : "";
    
      return (
        <div className="progress-destinations">
          <div className={`progress-destination ${step1Class}`}>
            <span>1</span>
          </div>
          <div className={`progress-destination ${step2Class}`}>
            <span>2</span>
          </div>
          <div className={`progress-destination ${step3Class}`}>
            <span>3</span>
          </div>
          <div className={`progress-destination ${step4Class}`}>
            <span>4</span>
          </div>
        </div>
      );
    };

    return (
        <div className="content">
          <Row>
            <Col md="8">
              <div className="progress-container">
                {renderProgressBarDestinations()}
                <Progress
                  max="100"
                  value={calculateProgress()}
                  className="progress-bar-sm progress-bar-striped"
                />
              </div>
            </Col>
          </Row>
          <Row className="progress-row">
            <Col md="8">
              <Card>
                <CardHeader>
                  <h5 className="title">Add New Machine</h5>
                </CardHeader>
                <CardBody>
                  <Form>{renderStepContent()}</Form>
                </CardBody>
                <CardFooter>
                  <div className="text-right">
                    {activeStep > 1 && (
                      <Button color="primary" onClick={handlePrevious}>
                        Previous
                      </Button>
                    )}
                    {activeStep < 3 && (
                      <Button color="primary" onClick={handleNext}>
                        Next
                      </Button>
                    )}
                    {activeStep === 3 && (
                      <Button color="success" onClick={handleSubmit}>
                        Submit
                      </Button>
                    )}
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      );
}

export default TenantOrganizationMaster;
