import React from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import classNames from "classnames";
import { Line } from "react-chartjs-2";
import { chartExample2 } from "variables/charts.js";

function PopupChart({ title, xAxisData, dataset, closePopup }) {
  return (
    <div className="content">
      <Row>
        <Col lg="8">
          <Card
            className="card-chart"
            style={{ height: "25rem", width: "35rem" }}
          >
            <CardHeader>
              <Row>
                <Col className="text-left" sm="6">
                  <h5 className="card-category">{title}</h5>
                </Col>
                <Col sm="4">
                  <ButtonGroup
                    style={{ marginTop: "1rem" }}
                    className="btn-group-toggle float-right"
                    data-toggle="buttons"
                  >
                    <Button
                      tag="label"
                      className={classNames("btn-simple")}
                      color="info"
                      id="0"
                      size="sm"
                    >
                      <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                        Day
                      </span>
                      <span className="d-block d-sm-none">
                        <i className="tim-icons icon-single-02" />
                      </span>
                    </Button>
                    <Button
                      color="info"
                      id="1"
                      size="sm"
                      tag="label"
                      className={classNames("btn-simple")}
                    >
                      <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                        Week
                      </span>
                      <span className="d-block d-sm-none">
                        <i className="tim-icons icon-gift-2" />
                      </span>
                    </Button>
                    <Button
                      color="info"
                      id="2"
                      size="sm"
                      tag="label"
                      className={classNames("btn-simple")}
                    >
                      <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                        Month
                      </span>
                      <span className="d-block d-sm-none">
                        <i className="tim-icons icon-tap-02" />
                      </span>
                    </Button>
                    <Button
                      color="info"
                      id="2"
                      size="sm"
                      tag="label"
                      className={classNames("btn-simple")}
                    >
                      <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                        Year
                      </span>
                      <span className="d-block d-sm-none">
                        <i className="tim-icons icon-tap-02" />
                      </span>
                    </Button>
                  </ButtonGroup>
                </Col>
                <Col sm="2">
                  <button
                    style={{
                      border: "none",
                      zIndex: 1,
                      marginLeft: "7rem",
                      backgroundColor: "transparent",
                      color: "white",
                      fontSize: "40px",
                    }}
                    onClick={() => closePopup(false)}
                  >
                    &times;
                  </button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Line
                data={chartExample2.data}
                options={chartExample2.options}
                height="100%"
                width="100%"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default PopupChart;
