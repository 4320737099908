import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from "reactstrap";
import axios from "axios";
import { APIURL } from "views/Apiurl";
import "ag-charts-enterprise";
import { AgChartsReact } from "ag-charts-react";

const convertUtcToIst = (utcTime) => {
  const utcDateTime = new Date(`1970-01-01T${utcTime}Z`);
  const istDateTime = new Date(
    utcDateTime.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
  );
  return istDateTime;
};

function GanttChart({ fetchData, handleClose }) {
  const [machineStartTime, setMachineStartTime] = useState(null);
  const [machineEndTime, setMachineEndTime] = useState(null);

  const [options, setOptions] = useState({
    data: [],
    title: {
      text: "Machine Wise Utilization Schedule",
      color: "rgba(255, 255, 255, 0.8)",
    },
    subtitle: {
      text: "",
    },
    background: {
      visible: true,
      fill: "#27293d",
    },
    series: [
      {
        type: "range-bar",
        direction: "horizontal",
        xKey: "department",
        yLowKey: "low",
        yHighKey: "high",
        fill: "#4caf50",
        stroke: "#27293d",
        strokeWidth: 2, // Border width of the data points
        highlightStyle: {
          fill: "gold", // Color of the data points when highlighted
        },

        tooltip: {
          renderer: (params) => {
            return {
              content: `
                <b>End Customer:</b> ${params.datum.end_customer}<br/>
                <b>Machine:</b> ${params.datum.machine}<br/>
                <b>Operator:</b> ${params.datum.operator}<br>
                <b>Part:</b> ${params.datum.part}<br>
              `,
            };
          },
        },
      },
    ],
    axes: [
      {
        type: "time",
        position: "bottom",
        nice: "true",
        title: {
          text: "Time",
          fontSize: 14,
          color: "rgba(255, 255, 255, 0.8)",
        },
        label: {
          fontSize: 12,
          color: "rgba(255, 255, 255, 0.8)",
          formatter: (params) => {
            const date = new Date(params.value);
            const hours = date.getHours();
            const minutes = date.getMinutes().toString().padStart(2, "0");
            let shift = "Shift 3"; // Default to Shift 3
  
            if (hours >= 8 && hours < 16) {
              shift = "Shift 1";
            } else if (hours >= 16 && hours < 24) {
              shift = "Shift 2";
            }
            else{
              shift = "Shift 3";
            }
  
            return `${hours}:${minutes} (${shift})`; // Display the hour, minute and shift
          },
        },
      },
        // label: {
        //   fontSize: 12,
        //   color: "rgba(255, 255, 255, 0.8)",
        //   formatter: (params) => {
        //     const date = new Date(params.value);
        //     const hours = date.getHours().toString().padStart(2, "0");
        //     const minutes = date.getMinutes().toString().padStart(2, "0");
        //     console.log("hours, minutes ====>",hours, minutes);
        //     return `${hours}:${minutes}`; // Display only the hour and minute
        //   },
          //12 hours format
          // formatter: (params) => {
          //   const date = new Date(params.value);
          //   return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
          // },
      //   },
      // },
      {
        type: "category",
        position: "left",
        keys: ["department"],
        title: {
          text: " Machines",
          fontSize: 14,
          color: "rgba(255, 255, 255, 0.8)",
        },
        label: {
          fontSize: 12,
          color: "rgba(255, 255, 255, 0.8)",
        },
      },
    ],
  });
  console.log(options.data, "<----- options");
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [machines, setMachines] = useState([]);
  const [selectedMachine, setSelectedMachine] = useState("");
  const [selectedData, setSelectedData] = useState(null);
  const [productionPlanData, setProductionPlanData] = useState([]);
  const [machineName, setMachineName] = useState("");
  const [machineId, setMachineId] = useState("");
  const [fetchDate, setFetchedDate] = useState("");

  useEffect(() => {
    const fetchProductionPlanData = async () => {
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
              query ProductionPlan {
                tnt_production_plan_master {
                  date
                  machineid
                  production_starttime_desired
                  partno
                  end_customer
                  Operator {
                    operator
                    plan_id
                    date
                  }
                }
              }
            `,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );

        const productionPlanData =
          response.data.data.tnt_production_plan_master;
        setProductionPlanData(productionPlanData);
        const uniqueDates = [
          ...new Set(productionPlanData.map((item) => item.date)),
        ];
        setDates(uniqueDates);
      } catch (error) {}
    };

    fetchProductionPlanData();
  }, []);

  useEffect(() => {
    if (selectedDate) {
      const machinesByDate = productionPlanData
        .filter((item) => item.date === selectedDate)
        .map((item) => item.machineid);
      setMachines([...new Set(machinesByDate)]);
    }
  }, [selectedDate, productionPlanData]);

  //main code
  // const fetchDataByMachine = async () => {
  //   try {
  //     console.log(productionPlanData,"<----productionPlanData");
  //     const chartData = productionPlanData
  //       .filter((item) => item.date === selectedDate)
  //       .map((item) => {
  //         const machineStartTime = convertUtcToIst(
  //           item.production_starttime_desired
  //         );
  //         console.log(machineStartTime,"<----machineStartTime");
  //         const machineEndTime = new Date(machineStartTime);
  //         machineEndTime.setMinutes(machineEndTime.getMinutes() + 60);
  //         console.log(machineEndTime,"<----machineEndTime");
  //         return {
  //           department: item.machineid,
  //           low: machineStartTime,
  //           high: machineEndTime,
  //           end_customer: item.end_customer,
  //           machine: item.machineid,
  //           operator: item.Operator.operator,
  //           part: item.partno,
  //         };
  //       });
  //     setOptions({
  //       ...options,
  //       data: chartData,
  //     });
  //   } catch (error) {}
  // };
  //update code 2
  const fetchDataByMachine = async () => {
    try {
      // Sort the data by machineid and planid
      const sortedData = [...productionPlanData].sort((a, b) => {
        if (a.machineid !== b.machineid) {
          return a.machineid > b.machineid ? 1 : -1;
        }
        return a.planid - b.planid;
      });

      const chartData = sortedData
        .filter((item) => item.date === selectedDate)
        .map((item, index, arr) => {
          const machineStartTime = convertUtcToIst(
            item.production_starttime_desired
          );
          console.log(machineStartTime, "<----machineStartTime");

          let machineEndTime;
          if (
            index < arr.length - 1 &&
            arr[index + 1].machineid === item.machineid
          ) {
            // If the next item has the same machineid, use its production_starttime_desired as the end time
            machineEndTime = convertUtcToIst(
              arr[index + 1].production_starttime_desired
            );
          } else {
            // If there's no next item or the next item has a different machineid, add 30 minutes to the start time
            machineEndTime = new Date(machineStartTime);
            machineEndTime.setMinutes(machineEndTime.getMinutes() + 30);
          }

          setMachineStartTime(machineStartTime);
          setMachineEndTime(machineEndTime);

          return {
            department: item.machineid,
            low: machineStartTime,
            high: machineEndTime,
            end_customer: item.end_customer,
            machine: item.machineid,
            operator: item.Operator.operator,
            part: item.partno,
          };
        });

      setOptions({
        ...options,
        data: chartData,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleViewButtonClick = () => {
    if (selectedDate) {
      fetchDataByMachine();
    } else {
    }
  };

  return (
    <div className="content">
      <Row>
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Machine Utilization Schedule</h5>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col className="px-md-1" md="4">
                    <FormGroup>
                      <Label for="date">Date</Label>
                      <Input
                        type="select"
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(e.target.value)}
                      >
                        <option value="">Select Date</option>
                        {dates
                          .sort((a, b) => new Date(b) - new Date(a))
                          .map((date) => (
                            <option key={date} value={date}>
                              {date}
                            </option>
                          ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="view"> </Label>
                      <Button
                        style={{ marginTop: "22px" }}
                        onClick={handleViewButtonClick}
                      >
                        View
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
                <div></div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <div>
        <AgChartsReact options={options} />
      </div>
      <div></div>
    </div>
  );
}

export default GanttChart;
