import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import classnames from "classnames";
import { APIURL } from "views/Apiurl";
import axios from "axios";
import OrgJobDetails from "./OrgJobDetails";
import "./profile.css";

function EmpJobDescription({ selectedRow, id }) {
  const [activeTab, setActiveTab] = useState("1");
  const [activeStep, setActiveStep] = useState(1);
  const [progress, setProgress] = useState(0);

  const defaultJobRole = { job_role: "" };
  const [employeeData, setEmployeeData] = useState({ jobrole: defaultJobRole });
  const { employee_id } = useParams();

  useEffect(() => {
    fetchEmployeeData();
  }, [employee_id]);

  const fetchEmployeeData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await axios.post(
        APIURL,
        {
          query: `
            query GetEmployeeData($employee_id: Int!) {
              tenant_employees_by_pk(employee_id: $employee_id) {
                employee_id
                first_name
                middle_name
                employee_code
                is_active
                last_name
                unit {
                  organization_node_name
                }
                organization {
                  organization_node_name
                }
                dept {
                  organization_node_name
                }
                empcategory {
                  code
                }
                emptype {
                  code
                }
                jobrole {
                  job_role
                  job_id
                }
                reportingmanager {
                  first_name
                  middle_name
                  last_name
                }
                created_at
                contact_number
                updated_at
                doj
                education
                certification
                emp_position
              }
            }
          `,
          variables: { employee_id: parseInt(employee_id) },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const fetchedEmployeeData = response.data.data.tenant_employees_by_pk;
      setEmployeeData(fetchedEmployeeData);
    } catch (error) {}
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="4">
            <Card className="card-user">
              <CardBody>
                <CardText />
                <div className="author">
                  <div className="block block-one" />
                  <div className="block block-two" />
                  <div className="block block-three" />
                  <div className="block block-four" />
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="avatar"
                      src={require("assets/img/default-avatar.png")}
                    />
                    <h3 className="title">{`${employeeData.first_name} ${employeeData.middle_name} ${employeeData.last_name}`}</h3>
                  </a>
                  <p className="description">
                    {employeeData.jobrole?.job_role || ""}
                  </p>
                </div>
                <div className="card-description"></div>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Col>
          <Col md="8">
            <Card>
              <CardHeader>
                <h5 className="title">Employee Profile</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-md-1" md="4">
                      <FormGroup>
                        <label>First Name : </label>
                        <Input
                          type="text"
                          defaultValue={employeeData.first_name}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="4">
                      <FormGroup>
                        <label>Middle Name</label>
                        <Input
                          type="text"
                          defaultValue={employeeData.middle_name}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label>Last Name</label>
                        <Input
                          type="text"
                          defaultValue={employeeData.last_name}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="4">
                      <FormGroup>
                        <label>Employee Code</label>
                        <Input
                          type="text"
                          defaultValue={employeeData.employee_code}
                          readOnly
                        />
                        {/* <Input
                          type="text"
                        /> */}
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="4">
                      <FormGroup>
                        <label>Position</label>
                        <Input
                          type="text"
                          defaultValue={employeeData.jobrole?.job_role || ""}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label>Department</label>
                        <Input
                          type="text"
                          defaultValue={
                            employeeData.dept?.organization_node_name || ""
                          }
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="4">
                      <FormGroup>
                        <label>Date Of Joining</label>
                        <Input
                          type="text"
                          defaultValue={employeeData.doj}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <div style={{ position: "absolute", top: "10px", right: "10px" }}>
                <Link to="/admin/manage-people">
                  <Button className="btn-fill" color="success" type="submit">
                    Back
                  </Button>
                </Link>
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="4">
            <Card className="card-chart">
              <CardHeader>
                <CardTitle tag="h3"></CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area"></div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card className="card-chart">
              <CardHeader>
                <CardTitle tag="h3"></CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area"></div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card className="card-chart">
              <CardHeader></CardHeader>
              <CardBody>
                <div className="chart-area"></div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames("grid-item", { active: activeTab === "1" })}
              onClick={() => toggleTab("1")}
              style={{
                textAlign: "center",
                backgroundColor: activeTab === "1" ? "#5555e7" : "transparent",
                color: activeTab === "1" ? "white" : "white",
              }}
            >
              Performance
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames("grid-item", { active: activeTab === "2" })}
              onClick={() => toggleTab("2")}
              style={{
                textAlign: "center",
                backgroundColor: activeTab === "2" ? "#5555e7" : "transparent",
                color: activeTab === "2" ? "white" : "white",
              }}
            >
              Employee Job Description
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames("grid-item", { active: activeTab === "3" })}
              onClick={() => toggleTab("3")}
              style={{
                textAlign: "center",
                backgroundColor: activeTab === "3" ? "#5555e7" : "transparent",
                color: activeTab === "3" ? "white" : "white",
              }}
            >
              Employee Scorecard
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames("grid-item", { active: activeTab === "4" })}
              onClick={() => toggleTab("4")}
              style={{
                textAlign: "center",
                backgroundColor: activeTab === "4" ? "#5555e7" : "transparent",
                color: activeTab === "4" ? "white" : "white",
              }}
            >
              Organization and Job Details
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames("grid-item", { active: activeTab === "5" })}
              onClick={() => toggleTab("5")}
              style={{
                textAlign: "center",
                backgroundColor: activeTab === "5" ? "#5555e7" : "transparent",
                color: activeTab === "5" ? "white" : "white",
              }}
            >
              Compentancy
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames("grid-item", { active: activeTab === "6" })}
              onClick={() => toggleTab("6")}
              style={{
                textAlign: "center",
                backgroundColor: activeTab === "6" ? "#5555e7" : "transparent",
                color: activeTab === "6" ? "white" : "white",
              }}
            >
              Training and Upskilling
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col>{/* <ProductionPlan /> */}</Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col>{/* <PartandCustomers /> */}</Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col>{/* <ProductionPlanOperator /> */}</Col>
            </Row>
          </TabPane>
          <TabPane tabId="4">
            <Row>
              <Col>
                <OrgJobDetails employeeData={employeeData} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="5">
            <Row>
              <Col>{/* <EmpJobDescription /> */}</Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </>
  );
}

export default EmpJobDescription;
