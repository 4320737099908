import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from "reactstrap";
import axios from "axios";
import { APIURL } from "./Apiurl.js";
import { toast } from "react-toastify";

const JobrolemasterAdd = (props) => {
  const [activeStep, setActiveStep] = useState(1);
  const [progress, setProgress] = useState(0);

  const [empid, setempid] = useState("");
  const [employees, setemployees] = useState([]);

  const [tagid, settagid] = useState("");
  const [tags, settags] = useState([]);

  const [assigndate, setassigndate] = useState("");

  useEffect(() => {
    fetchemployee();
  }, []);

  const fetchemployee = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const roleId = decodedToken["sub"];

      const response = await axios.post(
        APIURL,
        {
          query: `
        query FetchEmployee {
            tenant_employees {
                employee_id
                first_name
                middle_name
                last_name
                employee_code
            }
          }
        `,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      setemployees(response.data.data.tenant_employees);
      console.log(response.data.data.tenant_employees);
    } catch (error) {
      console.error(error);
    }
  };

  const token = sessionStorage.getItem("token");

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["sub"];
    let logintenantId = null;

    if (roleId === 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
    }
    const today = new Date();
    const assignedDate = today.toISOString().split("T")[0];
    // Create an object with the form data
    const formData = {
      employee_id: empid,
      tenantid: logintenantId,
      tag_id: tagid,
      is_active: "1",
      assignment_date: assignedDate,
    };
    console.log(formData);
    axios
      .post(
        APIURL,
        {
          query: `
            mutation InsertEmployeeTagAssign($formData: employee_tag_assignment_insert_input!) {
                insert_employee_tag_assignment(objects: [$formData]) {
                affected_rows
                }
            }
        `,
          variables: {
            formData: formData,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      )
      .then((response) => {
        console.log(response); // Handle the response data as needed
        if (
          response.data.data &&
          response.data.data.insert_employee_tag_assignment.affected_rows > 0
        ) {
          console.log("hello");
          props.onInsertSuccess();
          setempid("");
          settagid("");
          setassigndate("");
          toast.success("Employee Tag Assignment inserted successfully!");
        } else {
          toast.error("Failed to insert data.");
        }
      })
      .catch((error) => {
        console.error(error); // Handle the error
        toast.error("An error occurred while inserting data.");
      });
  };

  return (
    <div className="content">
      <Row className="progress-row">
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Employee Tag Assignment</h5>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col className="px-md-1" md="6">
                    <FormGroup>
                      <Label for="orgnodeid">Employee ID</Label>
                      <Input
                        type="select"
                        id="orgnodeid"
                        value={empid}
                        onChange={(e) => {
                          setempid(e.target.value);
                        }}
                      >
                        <option value="">Select Employee ID</option>
                        {employees.map((emp) => (
                          <option key={emp.employee_id} value={emp.employee_id}>
                            {emp.first_name} {emp.last_name} (
                            {emp.employee_code})
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col className="px-md-1" md="6">
                    <FormGroup>
                      <Label for="orgtype">Tag Id</Label>
                      <Input
                        placeholder="Enter Tag Id"
                        type="text"
                        value={tagid}
                        onChange={(e) => settagid(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row></Row>
                {/* <Row>
            
               <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="cost_centre_code">Assingment Date</Label>
                  <Input
                    placeholder="Assingment Date"
                    type="text"
                    value={cost_centre_code}
                    onChange={(e) => setCostCentreCode(e.target.value)}
                  />
                </FormGroup>
              </Col>
             
            </Row> */}
              </Form>
            </CardBody>
            <CardFooter>
              <div className="text-right">
                <Button color="success" onClick={handleFormSubmit}>
                  Submit
                </Button>
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default JobrolemasterAdd;
