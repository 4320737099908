import React, { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import "./style.css";
import "./responsive.css";
import "./progress.css";
import "./gauge.css";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import m8Image from "../assets/img/m81.png";
import PercentageCircle from "./PercentageCircle";
import { ProgressBar } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDashboard,
  faCheckCircle,
  faChartArea,
  faBan,
  faArrowRightLong,
  faChartBar,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import redirectToLoginIfTokenMissing from "./TokenUtility";
import axios from "axios";
import ReactSpeedometer from "react-d3-speedometer";
import { APIURL } from "./Apiurl.js";
import SpeedGauge from "../assets/charts/SpeedGauge";
import LineChart from "assets/charts/LineChart";
import { QueryApi } from "QueryApi";

function Dashboard(props) {
  const storedTenantId = sessionStorage.getItem("selectedTenantId");
  const [dashboardData, setDashboardData] = useState([]);
  const [orgstruct, setorgstruct] = useState([]);
  console.log("performance, avail, quality in dash--->", orgstruct);
  const [containerWidth, setContainerWidth] = useState(0);
  const gaugeContainerRef = useRef(null);
  const chartRef = useRef(null);
  SpeedGauge(chartRef, "blue", 66);

  const [dailyUpdatesDataList, setDailyUpdatesDataList] = useState("No Data");
  useEffect(() => {
    dailyUpdates();
  }, []);
  const chartRef2 = useRef(null);
  SpeedGauge(chartRef2, "blue", dailyUpdatesDataList);

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const currDay = day < 10 ? `0${day}` : day;
  const currMonth = month < 10 ? `0${month}` : month;
  let currentDate = JSON.stringify(`${year}-${currMonth}-${currDay}`);

  useEffect(() => {
    const updateContainerWidth = () => {
      if (gaugeContainerRef.current) {
        setContainerWidth(gaugeContainerRef.current.clientWidth);
      }
    };
    window.addEventListener("resize", updateContainerWidth);
    updateContainerWidth();
    return () => {
      window.removeEventListener("resize", updateContainerWidth);
    };
  }, []);
  console.log("selected Tenant ID:", storedTenantId);
  useEffect(() => {
    redirectToLoginIfTokenMissing(); // Call the utility function to validate the token
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const roleId = decodedToken["sub"];
      console.log("roleId-->", roleId);
      const logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
      console.log("logintenantId---->", logintenantId);
      const storedTenantId = sessionStorage.getItem("selectedTenantId");
      console.log("storedTenantId-->", storedTenantId);
      let query = ""; // Define the query variable outside the if-else block
      let variables = {}; // Define the variables object

      if (roleId === 1) {
        if (storedTenantId !== null) {
          query = `
      query MyQuery($TenantId: Int!) {
        dm_daily(where: {tenantid: {_eq: $TenantId}, date: {_eq: "2024-01-01"}}) {
          machine_availability_percent
          quality_percent
          machine_performance_percent
        }
      }
      
  `;
          variables = {
            TenantId: storedTenantId,
          };
        } else {
          query = `
      query MyQuery {
        dm_daily(where: {date: {_eq: "2024-01-01"}}) {
          machine_availability_percent
          machine_performance_percent
          quality_percent
        }
      }
      
      
  `;
        }
      } else {
        query = `
    query MyQuery {
      dm_daily(where: {date: {_eq: "2024-01-01"}}) {
        machine_availability_percent
        machine_performance_percent
        quality_percent
      }
    }
    
    
  `;
      }
      const response = await axios.post(
        APIURL,
        {
          query: query,
          variables: variables,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // if (
      //   response.data &&
      //   response.data.data &&
      //   response.data.data.dm_daily &&
      //   response.data.data.dm_daily
      // ) {
      //   // Extract the aggregate data
      //   const aggregateData = response.data.data.dm_daily;

      //   // Update state with the fetched data
      //   setorgstruct([
      //     {
      //       ...aggregateData.avg,
      //       status: "On",
      //     },
      //   ]);
      // } else {
      //   console.error("Data not present in the response:", response.data);
      // }
      const dm_daily = response.data.data.dm_daily.map((structure) => ({
        ...structure,
        status: "On",
      }));
      setorgstruct(dm_daily);
    } catch (error) {
      console.error(error);
    }
  };

  const token = sessionStorage.getItem("token");

  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const roleId = decodedToken["sub"];
  console.log(roleId);
  let logintenantId = null;
  if (roleId === 1) {
    logintenantId = sessionStorage.getItem("selectedTenantId");
  } else {
    logintenantId =
      decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem("token");
        const response = await axios.post(
          APIURL,
          {
            query: `
              query tenantdashboardmaster($logintenantId: Int!) {
                tenant_dashboard_master(where: { is_default: { _eq: 1 }, tenantid: { _eq: $logintenantId } }) {
                  tenant_dashboard_master_id
                  is_default
                  dashboard_name
                }
              }
            `,
            variables: {
              logintenantId: logintenantId,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json", // Add your secret key here
            },
          }
        );

        setDashboardData(response.data.data.tenant_dashboard_master);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  const [bigChartData, setbigChartData] = React.useState("data1");
  const setBgChartData = (name) => {
    setbigChartData(name);
  };

  const needleShadow = "0 0 10px rgba(0, 0, 0, 0.3)";
  const percentage = 65; // Change this value to set the gauge level (between 0 and 100)

  // Define the dark and light colors
  const darkColor = "rgba(0, 0, 0, 0.8)";
  const lightColor = "rgba(0, 0, 0, 0.1)";

  const data = [
    { name: "Score", value: 80, fill: "#0088FE" },
    { name: "Remaining", value: 20, fill: "#E6E6E6" },
  ];
  const highlights = [
    { from: 0, to: 20, color: "#e63946" }, // Red
    { from: 20, to: 40, color: "#f1a9a0" }, // Orange
    { from: 40, to: 60, color: "#feca57" }, // Yellow
    { from: 60, to: 80, color: "#00b4d8" }, // Blue
    { from: 80, to: 100, color: "#90e0ef" }, // Light Blue
  ];
  // Calculate the intermediate colors
  const gradientSteps = 10; // Adjust the number of steps for the shading effect
  const colorStops = [];
  for (let i = 0; i <= gradientSteps; i++) {
    const intensity = i / gradientSteps;
    const r = Math.round(
      intensity * parseInt(lightColor.substring(4, 7)) +
        (1 - intensity) * parseInt(darkColor.substring(4, 7))
    );
    const g = Math.round(
      intensity * parseInt(lightColor.substring(9, 12)) +
        (1 - intensity) * parseInt(darkColor.substring(9, 12))
    );
    const b = Math.round(
      intensity * parseInt(lightColor.substring(14, 17)) +
        (1 - intensity) * parseInt(darkColor.substring(14, 17))
    );
    const colorStop = `rgb(${r}, ${g}, ${b})`;
    colorStops.push(colorStop);
  }

  const [targetAdherenceDataList, setTargetAdherenceDataList] = useState({
    parts_per_minute: 0,
    target_adherence: 0,
  });
  useEffect(() => {
    targetAdherence();
  }, []);
  const targetAdherence = async () => {
    try {
      const data = await QueryApi(`
      query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        dm_daily(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
              parts_per_minute
              target_adherence
            }
      }`);
      const partsAvg = data.dm_daily.reduce(
        (acc, curr) => acc + curr.parts_per_minute,
        0
      );
      const partAvgData = parseInt(partsAvg / data.dm_daily.length);
      const targetAvg = data.dm_daily.reduce(
        (acc, curr) => acc + curr.target_adherence,
        0
      );
      const targetAvgData = parseInt(targetAvg / data.dm_daily.length);
      setTargetAdherenceDataList({
        parts_per_minute: partAvgData,
        target_adherence: targetAvgData,
      });
    } catch (error) {
      setTargetAdherenceDataList({
        parts_per_minute: 0,
        target_adherence: 0,
      });
    }
  };

  const [capacityUtilizationDataList, setCapacityUtilizationDataList] =
    useState({
      availability_loss_percent: 0,
      capacity_utilized_percent: 0,
    });
  useEffect(() => {
    capacityUtilization();
  }, []);
  const capacityUtilization = async () => {
    try {
      const data = await QueryApi(`
      query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        dm_daily(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
              capacity_utilized_percent
              availability_loss_percent
            }
          }`);
      const firstAvg = data.dm_daily.reduce(
        (acc, curr) => acc + curr.capacity_utilized_percent,
        0
      );
      const firstAvgData = parseInt(firstAvg / data.dm_daily.length);
      const secondAvg = data.dm_daily.reduce(
        (acc, curr) => acc + curr.availability_loss_percent,
        0
      );
      const secondAvgData = parseInt(secondAvg / data.dm_daily.length);
      setCapacityUtilizationDataList({
        availability_loss_percent: firstAvgData,
        capacity_utilized_percent: secondAvgData,
      });
    } catch (error) {
      setCapacityUtilizationDataList({
        availability_loss_percent: 0,
        capacity_utilized_percent: 0,
      });
    }
  };
  //////////////productivity API////////////////////////////

  const dailyUpdates = async () => {
    try {
      const data = await QueryApi(`
      query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
      dm_daily(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
            parts_per_minute
          }
        }`);
      const avg = data.dm_daily.reduce(
        (acc, curr) => acc + curr.parts_per_minute,
        0
      );
      const avgData = parseInt(avg / data.dm_daily.length);
      setDailyUpdatesDataList(avgData);
    } catch (error) {
      setDailyUpdatesDataList("No Data");
    }
  };

  const [equipmentInsightsDataList, setEquipmentInsightsDataList] = useState({
    available_machines: "No Data",
    machines_under_maintenance: "No Data",
    unavailable_machines: "No Data",
  });

  useEffect(() => {
    equipmentInsights();
  }, []);
  const equipmentInsights = async () => {
    try {
      const data = await QueryApi(`
        query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
          fl2_avg_machines_available_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
            aggregate {
              avg {
                available_machines
                machines_under_maintenance
                unavailable_machines
              }
            }
          }
        } `);

      setEquipmentInsightsDataList({
        available_machines:
          data.fl2_avg_machines_available_aggregate.aggregate.avg
            .available_machines,
        machines_under_maintenance:
          data.fl2_avg_machines_available_aggregate.aggregate.avg
            .machines_under_maintenance,
        unavailable_machines:
          data.fl2_avg_machines_available_aggregate.aggregate.avg
            .unavailable_machines,
      });
    } catch (error) {
      setEquipmentInsightsDataList({
        available_machines: "No Data",
        machines_under_maintenance: "No Data",
        unavailable_machines: "No Data",
      });
    }
  };

  const [workforceEfficienciesDataList, setWorkforceEfficienciesDataList] =
    useState({
      overall_labor_effectiveness: 0,
    });
  useEffect(() => {
    WorkforceEfficiencies();
  }, []);
  const WorkforceEfficiencies = async () => {
    try {
      const data = await QueryApi(`
    query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
      wfl0_overall_labor_effectiveness(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
        overall_labor_effectiveness
          }
        }
     `);
      const avg = data.wfl0_overall_labor_effectiveness.reduce(
        (acc, curr) => acc + curr.overall_labor_effectiveness,
        0
      );
      const avgData = parseInt(
        avg / data.wfl0_overall_labor_effectiveness.length
      );
      setWorkforceEfficienciesDataList({
        overall_labor_effectiveness: avgData,
      });
    } catch (error) {
      setWorkforceEfficienciesDataList({
        overall_labor_effectiveness: 0,
      });
    }
  };

  ////// OEE ///////////////////////////////////////////////////////////

  const [OeeDataList, setOeeDataList] = useState({
    machine_availability_percent: "No Data",
    machine_performance_percent: "No Data",
    quality_percent: "No Data",
  });

  useEffect(() => {
    OeeFun();
  }, []);
  const OeeFun = async () => {
    try {
      const data = await QueryApi(`
      query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        dm_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
          aggregate {
            avg {
              machine_availability_percent
              machine_performance_percent
              quality_percent
            }
          }
        }
      } `);
      setOeeDataList(data.dm_daily_aggregate.aggregate.avg);
    } catch (error) {
      setOeeDataList({
        machine_availability_percent: "No Data",
        machine_performance_percent: "No Data",
        quality_percent: "No Data",
      });
    }
  };

  console.log("OeeDataList in dash--->", OeeDataList);
  //// Production Volume /////////////////////////////////////////////////////////////////////////////

  const [xAxisDataValueProductionVolume, setXAxisDataValueProductionVolume] =
    useState([]);
  const [plotPointProductionVolume, setPlotPointProductionVolume] = useState(
    []
  );
  const datasetProductionVolume = [
    {
      label: "Production Volume",
      data: plotPointProductionVolume,
      borderColor: "#1F8EF1",
    },
  ];

  useEffect(() => {
    GraphDataListProductionVolume();
  }, []);
  const GraphDataListProductionVolume = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
              dm_foundation(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                total_parts_produced
                timestamp
              }
            }
      `);
      const plotAllDataProductionVolume = data.dm_foundation.map((data) => {
        const objData = {
          point: data.total_parts_produced,
          Xaxis: data.timestamp,
        };
        return objData;
      });

      const plotPointDataProductionVolume = plotAllDataProductionVolume.map(
        (data) => data.point
      );
      setPlotPointProductionVolume(plotPointDataProductionVolume);

      const xAxisPointDataProductionVolume = plotAllDataProductionVolume.map(
        (data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
          var m = "0" + d.getMinutes();
          var ampm = h >= 12 ? "PM" : "AM";
          h = h % 12;
          h = h ? h : 12;
          var fullTime = h + ":" + m.substr(-2) + " " + ampm;
          return fullTime;
        }
      );
      setXAxisDataValueProductionVolume(xAxisPointDataProductionVolume);
    } catch (error) {
      console.log(error);
    }
  };

  //////Today's Production//////////////////////////////////////////////////////////////////////

  const [TodayProductionData, setTodayProductionData] = useState(0);
  useEffect(() => {
    TodayProduction();
  }, []);
  const TodayProduction = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
              dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                  sum {
                    total_parts_produced
                  }
                }
              }
            }
      `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.sum.total_parts_produced === null
      ) {
        setTodayProductionData("No Data");
      } else {
        setTodayProductionData(
          data.dm_daily_aggregate.aggregate.sum.total_parts_produced.toFixed(1)
        );
      }
    } catch (error) {
      console.log(error);
      setTodayProductionData("No Data");
    }
  };

  const [AvailabilityDataList, setAvailabilityDataList] = useState({
    machine_availability_percent: "No Data",
  });
  useEffect(() => {
    Availability();
  }, []);
  const Availability = async () => {
    try {
      const data = await QueryApi(`
        query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
          dm_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
            aggregate {
              avg {
                machine_availability_percent
              }
            }
          }
        } `);
      setAvailabilityDataList({
        machine_availability_percent:
          data.dm_daily_aggregate.aggregate.avg.machine_availability_percent,
      });
    } catch (error) {
      setAvailabilityDataList({
        machine_availability_percent: "No Data",
      });
    }
  };
  /////////////////////////// Down Time Trendline ////////////////////////////////////////////////////
  const mixedChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };
  const mixedChartData = {
    labels: ["January", "February", "March", "April", "May"],
    datasets: [
      {
        label: "Line Data 1",
        type: "line", // Specify line type
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 2,
        tension: 0.5,
        data: [7, 16, 25, 35, 54],
      },
      {
        label: "Line Data 2",
        type: "line", // Specify line type
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 2,
        tension: 0.5,
        data: [9, 23, 29, 32, 34],
      },
    ],
  };

  ///////////////////////down time trendline///////////////////////////////////////////////////

  const [
    xAxisDataValuePerformanceInsights,
    setXAxisDataValuePerformanceInsights,
  ] = useState([]);
  const [plotPointPerformanceInsights, setPlotPointPerformanceInsights] =
    useState([]);
  const datasetPerformanceInsights = [
    {
      label: "Down Time Trendline",
      data: plotPointPerformanceInsights,
      borderColor: "#1F8EF1",
    },
  ];

  useEffect(() => {
    GraphDataListPerformanceInsights();
  }, []);
  const GraphDataListPerformanceInsights = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
            dm_foundation(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
              total_machine_downtime
                timestamp
              }
            }
      `);
      const plotAllDataPerformanceInsights = data.dm_foundation.map((data) => {
        const objData = {
          point: data.total_machine_downtime,
          Xaxis: data.timestamp,
        };
        return objData;
      });

      const plotPointDataPerformanceInsights =
        plotAllDataPerformanceInsights.map((data) =>
          (data.point / (1000 * 60 * 60)).toFixed(2)
        );
      setPlotPointPerformanceInsights(plotPointDataPerformanceInsights);

      const xAxisPointDataPerformanceInsights =
        plotAllDataPerformanceInsights.map((data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
          var m = "0" + d.getMinutes();
          var ampm = h >= 12 ? "PM" : "AM";
          h = h % 12;
          h = h ? h : 12;
          var fullTime = h + ":" + m.substr(-2) + " " + ampm;
          return fullTime;
        });
      setXAxisDataValuePerformanceInsights(xAxisPointDataPerformanceInsights);
    } catch (error) {
      console.log(error);
    }
  };

  /////////////////////// Cycle Time Performance ///////////////////////////////////////////////////

  const [
    xAxisDataValueCycleTimePerformance,
    setXAxisDataValueCycleTimePerformance,
  ] = useState([]);
  const [plotPointCycleTimePerformance, setPlotPointCycleTimePerformance] =
    useState([]);
  const datasetCycleTimePerformance = [
    {
      label: "Cycle Time Performace ",
      data: plotPointCycleTimePerformance,
      borderColor: "#1F8EF1",
    },
  ];

  useEffect(() => {
    GraphDataListCycleTimePerformance();
  }, []);
  const GraphDataListCycleTimePerformance = async () => {
    try {
      const data = await QueryApi(`
         query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
           dm_foundation(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
            actual_cycletime
            timestamp
             }
           }
     `);
      const plotAllDataCycleTimePerformance = data.dm_foundation.map((data) => {
        const objData = {
          point: data.actual_cycletime,
          Xaxis: data.timestamp,
        };
        return objData;
      });

      const plotPointDataCycleTimePerformance =
        plotAllDataCycleTimePerformance.map((data) => data.point);
      setPlotPointCycleTimePerformance(plotPointDataCycleTimePerformance);

      const xAxisPointDataCycleTimePerformance =
        plotAllDataCycleTimePerformance.map((data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
          var m = "0" + d.getMinutes();
          var ampm = h >= 12 ? "PM" : "AM";
          h = h % 12;
          h = h ? h : 12;
          var fullTime = h + ":" + m.substr(-2) + " " + ampm;
          return fullTime;
        });
      setXAxisDataValueCycleTimePerformance(xAxisPointDataCycleTimePerformance);
    } catch (error) {
      console.log(error);
    }
  };

  /////// Capacity Utilization /////////////////////////////////////////////////////////////

  const [CapacityUtilizationShiftData, setCapacityUtilizationShiftData] =
    useState([]);
  useEffect(() => {
    CapacityUtilizationShift();
  }, []);
  const CapacityUtilizationShift = async () => {
    try {
      const data = await QueryApi(`
         query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = 15, $end_date: date = ${currentDate}) {
             dm_shiftwise(where: {date: {_gte: $start_date, _lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                capacity_utilized_percent
                timestamp
             }
           }
     `);
      if (data === undefined || data.dm_shiftwise === null) {
        setCapacityUtilizationShiftData([
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ]);
      } else {
        const CapacityUtilizationShift = [
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ];

        data.dm_shiftwise.map((data) => {
          var shiftDate = new Date(data.timestamp);
          var HoursshiftDate = shiftDate.getHours();
          if (HoursshiftDate >= 8 && HoursshiftDate <= 16) {
            CapacityUtilizationShift[0] = {
              name: "Shift 1",
              dataValue: data.capacity_utilized_percent,
            };
          } else if (HoursshiftDate >= 16 && HoursshiftDate <= 24) {
            CapacityUtilizationShift[1] = {
              name: "Shift 2",
              dataValue: data.capacity_utilized_percent,
            };
          } else if (HoursshiftDate >= 0 && HoursshiftDate <= 8) {
            CapacityUtilizationShift[2] = {
              name: "Shift 3",
              dataValue: data.capacity_utilized_percent,
            };
          }
        });
        setCapacityUtilizationShiftData(CapacityUtilizationShift);
      }
    } catch (error) {
      console.log(error);
      setCapacityUtilizationShiftData([
        {
          name: "shift 1",
          dataValue: "0",
        },
        {
          name: "shift 2",
          dataValue: "0",
        },
        {
          name: "shift 3",
          dataValue: "0",
        },
      ]);
    }
  };

  ///// Workforce Efficiency ///////////////////////////////////////////////////////////////////////////////////

  const [WorkforceEfficiencyShiftData, setWorkforceEfficiencyShiftData] =
    useState([]);
  useEffect(() => {
    WorkforceEfficiencyShift();
  }, []);
  const WorkforceEfficiencyShift = async () => {
    try {
      const data = await QueryApi(`
         query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = 15, $end_date: date = ${currentDate}) {
             wfl0_workforce_efficiency_shiftwise(where: {date: {_gte: $start_date, _lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                 overall_labor_effectiveness
                 shift
                 timestamp
               } 
           }
     `);
      if (
        data === undefined ||
        data.wfl0_workforce_efficiency_shiftwise === null
      ) {
        setWorkforceEfficiencyShiftData([
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ]);
      } else {
        const WorkforceEfficiencyShift = [
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ];

        data.wfl0_workforce_efficiency_shiftwise.map((data) => {
          var shiftDate = new Date(data.timestamp);
          var HoursshiftDate = shiftDate.getHours();
          if (HoursshiftDate >= 8 && HoursshiftDate <= 16) {
            WorkforceEfficiencyShift[0] = {
              name: "Shift 1",
              dataValue: data.overall_labor_effectiveness,
            };
          } else if (HoursshiftDate >= 16 && HoursshiftDate <= 24) {
            WorkforceEfficiencyShift[1] = {
              name: "Shift 2",
              dataValue: data.overall_labor_effectiveness,
            };
          } else if (HoursshiftDate >= 0 && HoursshiftDate <= 8) {
            WorkforceEfficiencyShift[2] = {
              name: "Shift 3",
              dataValue: data.overall_labor_effectiveness,
            };
          }
        });
        setWorkforceEfficiencyShiftData(WorkforceEfficiencyShift);
      }
    } catch (error) {
      console.log(error);
      setWorkforceEfficiencyShiftData([
        {
          name: "shift 1",
          dataValue: "0",
        },
        {
          name: "shift 2",
          dataValue: "0",
        },
        {
          name: "shift 3",
          dataValue: "0",
        },
      ]);
    }
  };

  ////// Cycle Time //////////////////////////////////////////////////////////////////////

  const [CycleTimeData, setCycleTimeData] = useState(0);
  useEffect(() => {
    CycleTime();
  }, []);
  const CycleTime = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
              dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                  avg {
                    actual_cycletime
                  }
                }
              }
            }
      `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.avg.actual_cycletime === null
      ) {
        setCycleTimeData("No Data");
      } else {
        const cycleValue =
          data.dm_daily_aggregate.aggregate.avg.actual_cycletime / 1000;
        setCycleTimeData(cycleValue.toFixed(1));
      }
    } catch (error) {
      console.log(error);
      setCycleTimeData("No Data");
    }
  };

  ////// Avg Plan Adherence //////////////////////////////////////////////////////////////////////

  const [AvgPlanAdherenceData, setAvgPlanAdherenceData] = useState(0);
  useEffect(() => {
    AvgPlanAdherence();
  }, []);
  const AvgPlanAdherence = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
              dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                  avg {
                    target_adherence
                  }
                }
              }
            }
      `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.avg.target_adherence === null
      ) {
        setAvgPlanAdherenceData("No Data");
      } else {
        setAvgPlanAdherenceData(
          data.dm_daily_aggregate.aggregate.avg.target_adherence.toFixed(1)
        );
      }
    } catch (error) {
      console.log(error);
      setAvgPlanAdherenceData("No Data");
    }
  };

  ////// Workforce Availability //////////////////////////////////////////////////////////////////////

  const [WorkforceAvailabilityData, setWorkforceAvailabilityData] = useState(0);
  useEffect(() => {
    WorkforceAvailability();
  }, []);
  const WorkforceAvailability = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
              wdm_workforce_availability_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                  avg {
                    available_workforce
                  }
                }
              }
            }
      `);
      if (
        data === undefined ||
        data.wdm_workforce_availability_daily_aggregate.aggregate.avg
          .available_workforce === null
      ) {
        setWorkforceAvailabilityData("No Data");
      } else {
        setWorkforceAvailabilityData(
          data.wdm_workforce_availability_daily_aggregate.aggregate.avg.available_workforce.toFixed(
            1
          )
        );
      }
    } catch (error) {
      console.log(error);
      setWorkforceAvailabilityData("No Data");
    }
  };

  ////// Today's Runtime //////////////////////////////////////////////////////////////////////

  const [TodayRuntimeData, setTodayRuntimeData] = useState(0);
  useEffect(() => {
    TodayRuntime();
  }, []);
  const TodayRuntime = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
              dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                  sum {
                    total_machine_runtime
                  }
                }
              }
            }
      `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.sum.total_machine_runtime === null
      ) {
        setTodayRuntimeData("No Data");
      } else {
        const numberData =
          data.dm_daily_aggregate.aggregate.sum.total_machine_runtime /
          (1000 * 60 * 60);
        setTodayRuntimeData(numberData.toFixed(1));
      }
    } catch (error) {
      console.log(error);
      setTodayRuntimeData("No Data");
    }
  };

  ////// Today's Time Loss //////////////////////////////////////////////////////////////////////

  const [TodayTimeLossData, setTodayTimeLossData] = useState(0);
  useEffect(() => {
    TodayTimeLoss();
  }, []);
  const TodayTimeLoss = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                sum {
                  availability_loss_time
                }
              }
            }
          }
      `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.sum.availability_loss_time === null
      ) {
        setTodayTimeLossData("No Data");
      } else {
        const numberData =
          data.dm_daily_aggregate.aggregate.sum.availability_loss_time /
          (1000 * 60 * 60);
        setTodayTimeLossData(numberData.toFixed(1));
      }
    } catch (error) {
      console.log(error);
      setTodayTimeLossData("No Data");
    }
  };

  return (
    <>
      <div className="content">
        <Row noGutters>
          {dashboardData.map((dashboard) => (
            <Col
              lg="2"
              className="text-center"
              key={dashboard.tenant_dashboard_master_id}
            >
              <Card className="card-chart">
                <CardHeader>
                  <div className="icon-circle">
                    <FontAwesomeIcon
                      icon={faChartBar}
                      rotation={270}
                      className="text-info icon-size"
                    />
                  </div>
                </CardHeader>
                <CardTitle tag="h4" className="mt-2">
                  <a
                    href={`/admin/custom-dashboard/${dashboard.tenant_dashboard_master_id}`}
                    onClick={() => {
                      sessionStorage.setItem(
                        "selectedDashboardId",
                        dashboard.tenant_dashboard_master_id
                      );
                      sessionStorage.setItem(
                        "is_default",
                        dashboard.is_default
                      );
                      window.location.reload();
                    }}
                    className="header-title"
                  >
                    My Dashboard
                  </a>
                </CardTitle>
              </Card>
            </Col>
          ))}
          <Col lg="2" className="text-center">
            <Card className="card-chart">
              <CardHeader>
                <div className="icon-circle">
                  <FontAwesomeIcon
                    icon={faDashboard}
                    className="text-info icon-size"
                  />
                </div>
              </CardHeader>
              <CardTitle tag="h4" className="mt-2">
                <a href="#" className="header-title">
                  Dashboard
                </a>
              </CardTitle>
            </Card>
          </Col>
          <Col lg="2" className="text-center">
            <Card className="card-chart">
              <CardHeader>
                <div className="icon-circle">
                  <FontAwesomeIcon
                    icon={faVolumeUp}
                    className="text-info icon-size"
                  />
                </div>
              </CardHeader>
              <CardTitle tag="h4" className="mt-2">
                <a href={`/admin/mindmap`} className="header-title">
                  Insights
                </a>
              </CardTitle>
            </Card>
          </Col>
          <Col lg="2" className="text-center">
            <Card className="card-chart">
              <CardHeader>
                <div className="icon-circle">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-info icon-size"
                  />
                </div>
              </CardHeader>
              <CardTitle tag="h4" className="mt-2">
                <a href="#" className="header-title">
                  {" "}
                  Decisions{" "}
                </a>
              </CardTitle>
            </Card>
          </Col>
          <Col lg="2" className="text-center">
            <Card className="card-chart">
              <CardHeader>
                <div className="icon-circle">
                  <FontAwesomeIcon
                    icon={faChartArea}
                    className="text-info icon-size"
                  />
                </div>
              </CardHeader>
              <CardTitle tag="h4" className="mt-2">
                <a href="#" className="header-title">
                  Predictions
                </a>
              </CardTitle>
            </Card>
          </Col>
          <Col lg="2" className="text-center">
            <Card className="card-chart">
              <CardHeader>
                <div className="icon-circle">
                  <FontAwesomeIcon
                    icon={faBan}
                    className="text-info icon-size"
                  />
                </div>
              </CardHeader>
              <CardTitle tag="h4" className="mt-2">
                <a href="#" className="header-title">
                  Anomaly & Deviations
                </a>
              </CardTitle>
            </Card>
          </Col>
        </Row>
        <Row
          noGutters
          className="flex-nowrap"
          style={{ width: "120%", overflowX: "auto" }}
        >
          <Col
            lg="1"
            md="2"
            sm="6"
            style={{ flex: "0 0 auto", width: "12.5%", padding: "7px" }}
            className="card-chart"
          >
            <Card style={{ height: "250px" }}>
              <CardHeader>
                <div
                  className="text-center"
                  style={{
                    height: "2.5rem",
                    font: "rgba(255, 255, 255, 0.8)",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Target Adherence
                </div>
              </CardHeader>
              <CardBody>
                <div className="percentage-circle-wrapper">
                  <div className="percentage-circle-line"></div>
                  <PercentageCircle
                    percentage={
                      targetAdherenceDataList.target_adherence
                        ? targetAdherenceDataList.target_adherence
                        : "No Data"
                    }
                    color="blue"
                    size="60px"
                  />
                </div>
              </CardBody>
              <CardFooter>
                <ProgressBar
                  now={90}
                  animated
                  variant={
                    90 < 40
                      ? "danger"
                      : 90 >= 40 && 90 <= 74
                        ? "warning"
                        : "success"
                  }
                />
              </CardFooter>
            </Card>
          </Col>
          <Col
            lg="1"
            md="2"
            sm="6"
            className="card-chart"
            style={{ flex: "0 0 auto", width: "12.5%", padding: "7px" }}
          >
            <Card style={{ height: "250px" }}>
              <CardHeader>
                <div className="icon-plus">
                  <a
                    className="anchor-bg"
                    href={`/admin/dashboard/cycle-time-analysis`}
                  >
                    <FontAwesomeIcon icon={faArrowRightLong} />
                  </a>
                </div>
                <div
                  className="text-center"
                  style={{
                    height: "2.5rem",
                    font: "rgba(255, 255, 255, 0.8)",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Cycle Time (seconds)
                </div>
              </CardHeader>
              <CardBody>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "#8c7575",
                    }}
                  >
                    {CycleTimeData}
                  </div>
                  {/* <div className="percentage-circle-line"></div> */}
                  {/* <PercentageCircle percentage={CycleTimeData} color="red" size="60px" /> */}
                </div>
              </CardBody>
              <CardFooter>
                <ProgressBar
                  now={90}
                  animated
                  variant={
                    90 < 40
                      ? "danger"
                      : 90 >= 40 && 90 <= 74
                        ? "warning"
                        : "success"
                  }
                />
              </CardFooter>
            </Card>
          </Col>
          <Col
            lg="1"
            md="2"
            sm="6"
            className="card-chart"
            style={{ flex: "0 0 auto", width: "12.5%", padding: "7px" }}
          >
            <Card style={{ height: "250px" }}>
              <CardHeader>
                <div
                  className="text-center"
                  style={{
                    height: "2.5rem",
                    font: "rgba(255, 255, 255, 0.8)",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Avg Plan Adherence
                </div>
              </CardHeader>
              <CardBody>
                <div className="percentage-circle-wrapper">
                  <div className="percentage-circle-line"></div>
                  <PercentageCircle
                    percentage={
                      AvgPlanAdherenceData ? AvgPlanAdherenceData : "No Data"
                    }
                    color="Yellow"
                    size="60px"
                  />
                </div>
              </CardBody>
              <CardFooter>
                <ProgressBar
                  now={35}
                  animated
                  variant={
                    35 < 40
                      ? "danger"
                      : 35 >= 40 && 35 <= 74
                        ? "warning"
                        : "success"
                  }
                />
              </CardFooter>
            </Card>
          </Col>
          <Col
            lg="1"
            md="2"
            sm="6"
            className="card-chart"
            style={{ flex: "0 0 auto", width: "12.5%", padding: "7px" }}
          >
            <Card style={{ height: "250px" }}>
              <CardHeader>
                <div
                  className="text-center"
                  style={{
                    height: "2.5rem",
                    font: "rgba(255, 255, 255, 0.8)",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Today's Production
                </div>
              </CardHeader>
              <CardBody>
                {/* <div className="percentage-circle-wrapper">
                  <div className="percentage-circle-line"></div>
                  <PercentageCircle
                    percentage={TodayProductionData}
                    color="brown"
                    size="60px"
                  />
                </div> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "#8c7575",
                    }}
                  >
                    {TodayProductionData}
                  </div>
                </div>
              </CardBody>
              <CardFooter>
                <ProgressBar
                  now={65}
                  animated
                  variant={
                    65 < 40
                      ? "danger"
                      : 65 >= 40 && 65 <= 74
                        ? "warning"
                        : "success"
                  }
                />
              </CardFooter>
            </Card>
          </Col>
          <Col
            lg="1"
            md="2"
            sm="6"
            className="card-chart"
            style={{ flex: "0 0 auto", width: "12.5%", padding: "7px" }}
          >
            <Card style={{ height: "250px" }}>
              <CardHeader>
                <div
                  className="text-center"
                  style={{
                    height: "2.5rem",
                    font: "rgba(255, 255, 255, 0.8)",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Workforce Availability
                </div>
              </CardHeader>
              <CardBody>
                <div className="percentage-circle-wrapper">
                  <div className="percentage-circle-line"></div>
                  <PercentageCircle
                    percentage={
                      WorkforceAvailabilityData
                        ? WorkforceAvailabilityData
                        : "No Data"
                    }
                    color="Yellow"
                    size="60px"
                  />
                </div>
              </CardBody>
              <CardFooter>
                <ProgressBar
                  now={90}
                  animated
                  variant={
                    90 < 40
                      ? "danger"
                      : 90 >= 40 && 90 <= 74
                        ? "warning"
                        : "success"
                  }
                />
              </CardFooter>
            </Card>
          </Col>
          <Col
            lg="1"
            md="2"
            sm="6"
            className="card-chart"
            style={{ flex: "0 0 auto", width: "12.5%", padding: "7px" }}
          >
            <Card style={{ height: "250px" }}>
              <CardHeader>
                <div
                  className="text-center"
                  style={{
                    height: "2.5rem",
                    font: "rgba(255, 255, 255, 0.8)",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Today's Runtime (Hours)
                </div>
              </CardHeader>
              <CardBody>
                {/* <div className="percentage-circle-wrapper">
                  <div className="percentage-circle-line"></div>
                  <PercentageCircle
                    percentage={
                      TodayRuntimeData
                        ? TodayRuntimeData
                        : "No Data"
                    }
                    color="Yellow"
                    size="60px"
                  />
                </div> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "#8c7575",
                    }}
                  >
                    {TodayProductionData}
                  </div>
                </div>
              </CardBody>
              <CardFooter>
                <ProgressBar
                  now={90}
                  animated
                  variant={
                    90 < 40
                      ? "danger"
                      : 90 >= 40 && 90 <= 74
                        ? "warning"
                        : "success"
                  }
                />
              </CardFooter>
            </Card>
          </Col>
          <Col
            lg="1"
            md="2"
            sm="6"
            className="card-chart"
            style={{ flex: "0 0 auto", width: "12.5%", padding: "7px" }}
          >
            <Card style={{ height: "250px" }}>
              <CardHeader>
                <div
                  className="text-center"
                  style={{
                    height: "2.5rem",
                    font: "rgba(255, 255, 255, 0.8)",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Today's Time Loss
                </div>
              </CardHeader>
              <CardBody>
                <div className="percentage-circle-wrapper">
                  <div className="percentage-circle-line"></div>
                  <PercentageCircle
                    percentage={
                      TodayTimeLossData ? TodayTimeLossData : "No Data"
                    }
                    color="Yellow"
                    size="60px"
                  />
                </div>
              </CardBody>
              <CardFooter>
                <ProgressBar
                  now={90}
                  animated
                  variant={
                    90 < 40
                      ? "danger"
                      : 90 >= 40 && 90 <= 74
                        ? "warning"
                        : "success"
                  }
                />
              </CardFooter>
            </Card>
          </Col>
          {/* <Col lg="1" md="2" sm="6" className="card-chart"
               style={{ flex: "0 0 auto", width: "15%", padding: "7px" }}>
                <Card style={{ height: '250px' }}>
                  <CardHeader>
                    <div className="text-center" style={{ height: '2.5rem' }}>Avg Productivity</div>
                  </CardHeader>
                  <CardBody>
                    <div className="percentage-circle-wrapper">
                      <div className="percentage-circle">
                        <PercentageCircle percentage={targetAdherenceDataList.parts_per_minute ? targetAdherenceDataList.parts_per_minute : "No Data"} color="blue" size="60px" />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col> */}
          <Col
            lg="3"
            md="4"
            sm="12"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Card sm="6" style={{ flex: 1 }}>
              <CardHeader>
                <div className="icon-plus">
                  <a
                    className="anchor-bg"
                    href={`/admin/dashboard/machine-equipment`}
                  >
                    <FontAwesomeIcon icon={faArrowRightLong} />
                  </a>
                </div>
                <h5 className="card-category">Machine/Equipment Insights</h5>
              </CardHeader>
              <CardBody>
                <div className="card-container machine-cards">
                  <div>
                    <div
                      className="card card-stats card-primary"
                      style={{
                        filter:
                          "drop-shadow(-4px 10px 7px 11px rgba(0, 0, 0, 0.1))",
                      }}
                    >
                      <div
                        className="card-body"
                        style={{
                          background: "red",
                          borderRadius: "13px",
                          textAlign: "center",
                        }}
                      >
                        <h5
                          className="card-title"
                          style={{ fontSize: "12px", fontWeight: "bold" }}
                        >
                          {equipmentInsightsDataList.unavailable_machines
                            ? equipmentInsightsDataList.unavailable_machines
                            : "No Data"}
                        </h5>
                        <p className="card-text">Unavailable Machines</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      className="card card-stats card-secondary"
                      style={{
                        filter:
                          "drop-shadow(-4px 10px 7px 11px rgba(0, 0, 0, 0.1))",
                      }}
                    >
                      <div
                        className="card-body"
                        style={{
                          background: "#B8860B",
                          borderRadius: "13px",
                          textAlign: "center",
                        }}
                      >
                        <h5
                          className="card-title"
                          style={{ fontSize: "12px", fontWeight: "bold" }}
                        >
                          {equipmentInsightsDataList.machines_under_maintenance
                            ? equipmentInsightsDataList.machines_under_maintenance
                            : "No Data"}
                        </h5>
                        <p className="card-text">Under Maintenance</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      className="card card-stats card-tertiary"
                      style={{
                        filter:
                          "drop-shadow(-4px 10px 7px 11px rgba(0, 0, 0, 0.1))",
                      }}
                    >
                      <div
                        className="card-body"
                        style={{
                          background: "green",
                          borderRadius: "13px",
                          textAlign: "center",
                        }}
                      >
                        <h5
                          className="card-title"
                          style={{ fontSize: "12px", fontWeight: "bold" }}
                        >
                          {equipmentInsightsDataList.available_machines
                            ? equipmentInsightsDataList.available_machines
                            : "No Data"}
                        </h5>
                        <p className="card-text">Running Machines</p>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col
            lg="6"
            sm="12"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Row noGutters>
              <Col lg="4" sm="6" className="card-chart d-flex flex-column">
                <Card>
                  <CardHeader>
                    <div className="icon-plus">
                      <a className="anchor-bg" href={`/admin/oee`}>
                        <FontAwesomeIcon icon={faArrowRightLong} />
                      </a>
                    </div>
                    <h5 className="card-category">O.E.E</h5>
                    <CardTitle tag="h3">
                      <i className="tim-icons icon-chart-pie text-info" /> 66%
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div
                      className="gauge-container"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div
                        ref={chartRef}
                        style={{ width: "100%", height: "350px" }}
                      />
                    </div>
                    <div
                      className="percentage-circles-container"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="percentage-circle-wrapper">
                        <div className="percentage-circle-line"></div>
                        <PercentageCircle
                          percentage={OeeDataList.machine_performance_percent}
                          color="green"
                          size="30px"
                        />
                        {/* {orgstruct.length > 0 && (
                          <PercentageCircle
                            percentage={orgstruct[0].machine_availability_percent}
                            color="green"
                            size="30px"
                          />
                        )} */}
                        <div className="percentage-circle-label">
                          Performance
                        </div>
                      </div>
                      <div style={{ width: "10%" }}></div>
                      <div
                        className="percentage-circle-wrapper"
                        style={{ textAlign: "center" }}
                      >
                        <div className="percentage-circle-line"></div>
                        <PercentageCircle
                          percentage={OeeDataList.machine_availability_percent}
                          color="blue"
                          size="30px"
                        />
                        {/* {orgstruct.length > 0 && (
                          <PercentageCircle
                            percentage={orgstruct[0].machine_performance_percent}
                            color="green"
                            size="30px"
                          />
                        )} */}
                        <div className="percentage-circle-label">
                          Availability
                        </div>
                      </div>
                      <div style={{ width: "10%" }}></div>
                      <div
                        className="percentage-circle-wrapper"
                        style={{ textAlign: "center" }}
                      >
                        <div className="percentage-circle-line"></div>
                        <PercentageCircle
                          percentage={OeeDataList.quality_percent}
                          color="red"
                          size="30px"
                        />
                        {/* {orgstruct.length > 0 && (
                          <PercentageCircle
                            percentage={orgstruct[0].machine_availability_percent}
                            color="green"
                            size="30px"
                          />
                        )} */}
                        <div className="percentage-circle-label">Quality</div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4" sm="6" className="card-chart d-flex flex-column">
                <Card
                  className="productivity-card"
                  style={{ height: "34.5rem" }}
                >
                  <CardHeader>
                    <div className="icon-plus">
                      <a
                        className="anchor-bg"
                        href={`/admin/dashboard/productivity`}
                      >
                        <FontAwesomeIcon icon={faArrowRightLong} />
                      </a>
                    </div>
                    <h5 className="card-category">Productivity</h5>
                    <CardTitle tag="h3">
                      <i className="tim-icons icon-chart-pie text-info" />{" "}
                      {dailyUpdatesDataList}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="gauge-container">
                      <div
                        ref={chartRef2}
                        style={{ width: "100%", height: "350px" }}
                      />
                    </div>
                    {/* <div
                      className="gauge-container"
                      style={{ marginTop: "1.5rem" }}
                    >
                      <ReactSpeedometer
                        value={dailyUpdatesDataList} // Change this to your desired value
                        minValue={0}
                        maxValue={100}
                        width={300}
                        height={250}
                        needleColor="wheat" // Customize the needle color
                        startColor="green" // Customize the start color of the gauge
                        segments={5} // Number of segments on the gauge
                        endColor="blue" // Customize the end color of the gauge
                        textColor="white" // Text color
                        ringWidth={25}
                        currentValueText="${value} %" // Text shown in the center
                      />
                    </div> */}
                    <div
                      className="percentage-circles-container"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div className="percentage-circle-wrapper">
                        <div className="percentage-circle-line"></div>
                        <PercentageCircle
                          percentage={dailyUpdatesDataList}
                          color="green"
                          size="30px"
                        />
                        <div className="percentage-circle-label">
                          Daily Updates
                        </div>{" "}
                        {/* Add the label */}
                      </div>
                      <div style={{ width: "10%" }}></div>
                      <div
                        className="percentage-circle-wrapper"
                        style={{ textAlign: "center" }}
                      >
                        <div className="percentage-circle-line"></div>
                        <PercentageCircle
                          percentage={65}
                          color="blue"
                          size="30px"
                        />
                        <div className="percentage-circle-label">
                          Productivity Goal
                        </div>{" "}
                        {/* Add the label */}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4" sm="6" className="card-chart d-flex flex-column">
                <Card
                  className="productivity-card"
                  style={{ height: "34.5rem" }}
                >
                  <CardHeader>
                    <div className="icon-plus">
                      <a
                        className="anchor-bg"
                        href={`/admin/dashboard/productivity`}
                      >
                        <FontAwesomeIcon icon={faArrowRightLong} />
                      </a>
                    </div>
                    <h5 className="card-category">Availability</h5>
                    <CardTitle tag="h3">
                      <i className="tim-icons icon-chart-pie text-info" />{" "}
                      {dailyUpdatesDataList}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div
                      className="gauge-container"
                      style={{ marginTop: "1.5rem" }}
                    >
                      <ReactSpeedometer
                        value={
                          AvailabilityDataList.machine_availability_percent
                        } // Change this to your desired value
                        minValue={0}
                        maxValue={100}
                        width={300}
                        height={250}
                        needleColor="wheat" // Customize the needle color
                        startColor="green" // Customize the start color of the gauge
                        segments={5} // Number of segments on the gauge
                        endColor="blue" // Customize the end color of the gauge
                        textColor="white" // Text color
                        ringWidth={25}
                        currentValueText="${value} %" // Text shown in the center
                      />
                    </div>
                    <div
                      className="percentage-circles-container"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "2.5rem",
                      }}
                    >
                      <div className="percentage-circle-wrapper">
                        <div className="percentage-circle-line"></div>
                        <PercentageCircle
                          percentage={dailyUpdatesDataList}
                          color="green"
                          size="30px"
                        />
                        <div className="percentage-circle-label">
                          Daily Updates
                        </div>{" "}
                        {/* Add the label */}
                      </div>
                      <div style={{ width: "10%" }}></div>
                      <div
                        className="percentage-circle-wrapper"
                        style={{ textAlign: "center" }}
                      >
                        <div className="percentage-circle-line"></div>
                        <PercentageCircle
                          percentage={65}
                          color="blue"
                          size="30px"
                        />
                        <div className="percentage-circle-label">
                          Productivity Goal
                        </div>{" "}
                        {/* Add the label */}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col
            lg="4"
            md="6"
            sm="12"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Card style={{ height: "50%" }}>
              <CardHeader>
                <div className="icon-plus">
                  <a className="anchor-bg" href="#">
                    <FontAwesomeIcon icon={faArrowRightLong} />
                  </a>
                </div>
                <h5 className="card-category">Capacity Utilization</h5>
              </CardHeader>
              <CardBody>
                <Row className="capacity-utilization-row">
                  <Col md="6" className="d-flex flex-column align-items-center">
                    <div
                      id="gauge-dasboard"
                      className="gauge-container"
                      ref={gaugeContainerRef}
                    >
                      <ReactSpeedometer
                        width={230} // Set the width based on the container's width
                        value={
                          capacityUtilizationDataList.capacity_utilized_percent
                        } // Change this to your desired value
                        minValue={0}
                        maxValue={100}
                        height={140}
                        needleColor="wheat" // Customize the needle color
                        startColor="orange" // Customize the start color of the gauge
                        segments={5} // Number of segments on the gauge
                        endColor="yellow" // Customize the end color of the gauge
                        textColor="white" // Text color
                        ringWidth={20}
                        currentValueText="${value} %" // Text shown in the center
                      />
                    </div>
                    <div className="text-center progress-container-footer">
                      Compliance with benchmark
                    </div>
                  </Col>
                  <Col lg="6">
                    <CardBody
                      className="dashboard-page"
                      id="progress-container"
                      style={{ height: "100%" }}
                    >
                      <div
                        style={{
                          height: "100%",
                          display: "grid",
                          gridTemplateRows: "1fr 1fr 1fr",
                        }}
                      >
                        {CapacityUtilizationShiftData.map((data, index) => (
                          <div>
                            <p>{data.name}</p>
                            <ProgressBar
                              now={data.dataValue}
                              label={data.dataValue}
                              animated
                            />
                          </div>
                        ))}
                      </div>
                    </CardBody>
                    <div className="text-center progress-container-footer">
                      Shift Wise Break up
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <div className="icon-plus">
                  <a
                    className="anchor-bg"
                    href={`/admin/dashboard/workforce-efficiency`}
                  >
                    <FontAwesomeIcon icon={faArrowRightLong} />
                  </a>
                </div>
                <h5 className="card-category">Workforce Efficiencies</h5>
              </CardHeader>
              <CardBody>
                <Row className="capacity-utilization-row">
                  <Col md="6" className="d-flex flex-column align-items-center">
                    <div
                      id="gauge-dasboard"
                      className="gauge-container"
                      ref={gaugeContainerRef}
                    >
                      <ReactSpeedometer
                        width={230} // Set the width based on the container's width
                        value={
                          workforceEfficienciesDataList.overall_labor_effectiveness
                        } // Change this to your desired value
                        minValue={0}
                        maxValue={100}
                        height={140}
                        needleColor="wheat" // Customize the needle color
                        startColor="#228b22" // Customize the start color of the gauge
                        segments={5} // Number of segments on the gauge
                        endColor="#4cbb17" // Customize the end color of the gauge
                        textColor="white" // Text color
                        ringWidth={20}
                        currentValueText="${value} %" // Text shown in the center
                      />
                    </div>
                    <div className="text-center progress-container-footer">
                      Compliance with benchmark
                    </div>
                  </Col>
                  <Col lg="6">
                    <CardBody
                      className="dashboard-page"
                      id="progress-container"
                      style={{ height: "100%" }}
                    >
                      {/* <p className="p-font">Shift 1</p>
                      <ProgressBar now={35} label={`35% completed`} animated />
                      <br />
                      <p className="p-font">Shift 2</p>
                      <ProgressBar now={50} label={`50% completed`} animated />
                      <br />
                      <p className="p-font">Shift 3</p>
                      <ProgressBar now={70} label={`70% completed`} animated />
                      <br /> */}
                      <div
                        style={{
                          height: "100%",
                          display: "grid",
                          gridTemplateRows: "1fr 1fr 1fr",
                        }}
                      >
                        {WorkforceEfficiencyShiftData.map((data, index) => (
                          <div>
                            <p>{data.name}</p>
                            <ProgressBar
                              now={data.dataValue}
                              label={data.dataValue}
                              animated
                            />
                          </div>
                        ))}
                      </div>
                    </CardBody>
                    <div className="text-center progress-container-footer">
                      Shift Wise Break up
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="2" md="3">
            <Card id="img-bg" style={{ background: "transperant !important" }}>
              <div className="image-container">
                <CardTitle tag="h3" className="text-center">
                  <img
                    src={m8Image}
                    alt="Image"
                    className="image1"
                    style={{
                      height: "595px",
                      padding: "30px",
                      // filter: "brightness(2) sepia(1) hue-rotate(200deg) saturate(2)"
                      filter:
                        "brightness(2) sepia(1) hue-rotate(240deg) saturate(2)",
                    }}
                  />
                </CardTitle>
              </div>
            </Card>
          </Col>
        </Row>
        <Row noGutters className="d-flex align-items-stretch">
          <Col lg="4" md="6">
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div
                className="card"
                style={{ flex: "1 0 auto", height: "400px" }}
              >
                <Card lg="3" sm="8" style={{ flex: 1 }}>
                  <CardHeader>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Col>
                          <h5 className="card-category">Production Volume</h5>
                        </Col>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ paddingRight: "5px" }}>
                          <ButtonGroup
                            className="btn-group-toggle float-right"
                            data-toggle="buttons"
                            // style={{ marginLeft: '50px' }}
                          >
                            <Button
                              tag="label"
                              className={classNames("btn-simple", {
                                active: bigChartData === "dat1",
                              })}
                              color="info"
                              id="0"
                              size="sm"
                              onClick={() => setBgChartData("dat1")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Day
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-single-02" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="1"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: bigChartData === "dat2",
                              })}
                              onClick={() => setBgChartData("dat2")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Week
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-gift-2" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="2"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: bigChartData === "dat3",
                              })}
                              onClick={() => setBgChartData("dat3")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Month
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-tap-02" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="2"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: bigChartData === "dat4",
                              })}
                              onClick={() => setBgChartData("dat4")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Year
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-tap-02" />
                              </span>
                            </Button>
                          </ButtonGroup>
                        </div>
                        <div style={{ paddingLeft: "5px" }}>
                          <div className="icon-plus">
                            <a
                              className="anchor-bg"
                              href={`/admin/dashboard/production-volume`}
                            >
                              <FontAwesomeIcon icon={faArrowRightLong} />
                            </a>
                          </div>
                        </div>
                      </div>
                      {/* <CardTitle tag="h3">
                  <i className="tim-icons icon-bell-55 text-info" /> 763,215
                </CardTitle> */}
                    </div>
                  </CardHeader>
                  <CardBody>
                    {/* <div className="chart-area dashboard-chart">
                  <Line
                    data={chartExample2.data}
                    options={chartExample2.options}
                  />
                </div> */}
                    <LineChart
                      xAxisData={xAxisDataValueProductionVolume}
                      dataset={datasetProductionVolume}
                      height="100%"
                      width="100%"
                    />
                  </CardBody>
                </Card>
              </div>
            </div>
          </Col>

          {/* /////////////////////////////////  Time Loss-Idle Time ///////////////////////// */}
          {/* <Col lg="4" md="6">
            <Card>
              <CardHeader>
                <div className="icon-plus">
                  <a className="anchor-bg" href={`/admin/dashboard/idle-time-loss`}>
                    <FontAwesomeIcon icon={faArrowRightLong} />
                  </a>
                </div>
                <h5 className="card-category">Time Loss-Idle Time</h5>
              </CardHeader>
              <CardBody>
                <Row className="capacity-utilization-row">
                  <Col md="6" className="d-flex flex-column align-items-center">
                    <div id="gauge-dasboard" className="gauge-container" ref={gaugeContainerRef}>
                      <ReactSpeedometer
                        width={230} // Set the width based on the container's width
                        value={capacityUtilizationDataList.availability_loss_percent} // Change this to your desired value
                        minValue={0}
                        maxValue={100}
                        height={140}
                        needleColor="wheat" // Customize the needle color
                        startColor="red" // Customize the start color of the gauge
                        segments={5} // Number of segments on the gauge
                        endColor="pink" // Customize the end color of the gauge
                        textColor="white" // Text color
                        ringWidth={20}
                        currentValueText="${value} %" // Text shown in the center
                      />
                    </div>
                    <div className="text-center progress-container-footer">Compliance with benchmark</div>
                  </Col>
                  <Col lg="6">
                    <CardBody className="dashboard-page" id="progress-container">
                      <p className="p-font">Shift 1</p>
                      <ProgressBar now={35} label={`35% completed`} animated />
                      <br />
                      <p className="p-font">Shift 2</p>
                      <ProgressBar now={50} label={`50% completed`} animated />
                      <br />
                      <p className="p-font">Shift 3</p>
                      <ProgressBar now={70} label={`70% completed`} animated />
                      <br />
                    </CardBody>
                    <div className="text-center progress-container-footer">Shift Wise Break up</div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col> */}
          {/* ///////////////////////////////////////////////////////////////////////////////////// */}
          {/* <Col lg="4" md="6">
            <Card className="card-chart h-100">
              <CardHeader>
                <h5 className="card-category">Downtime Treadline</h5>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Bar
                    data={mixedChartData} // Use mixedChartData here
                    options={mixedChartOptions}
                  />
                </div>
              </CardBody>
            </Card>
          </Col> */}
          <Col lg="4" md="6" xs="4">
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div
                className="card"
                style={{ flex: "1 0 auto", height: "400px" }}
              >
                <Card className="card-chart" style={{ height: "336px" }}>
                  <CardHeader>
                    <Row>
                      <Col className="text-left" sm="6">
                        <h5 className="card-category">Downtime Trendline</h5>
                      </Col>
                      <Col sm="6">
                        <ButtonGroup
                          className="btn-group-toggle float-right"
                          data-toggle="buttons"
                        >
                          <Button
                            tag="label"
                            className={classNames("btn-simple", {
                              active: bigChartData === "da1",
                            })}
                            color="info"
                            id="0"
                            size="sm"
                            onClick={() => setBgChartData("da1")}
                          >
                            <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                              Day
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="tim-icons icon-single-02" />
                            </span>
                          </Button>
                          <Button
                            color="info"
                            id="1"
                            size="sm"
                            tag="label"
                            className={classNames("btn-simple", {
                              active: bigChartData === "da2",
                            })}
                            onClick={() => setBgChartData("da2")}
                          >
                            <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                              Week
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="tim-icons icon-gift-2" />
                            </span>
                          </Button>
                          <Button
                            color="info"
                            id="2"
                            size="sm"
                            tag="label"
                            className={classNames("btn-simple", {
                              active: bigChartData === "da3",
                            })}
                            onClick={() => setBgChartData("da3")}
                          >
                            <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                              Month
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="tim-icons icon-tap-02" />
                            </span>
                          </Button>
                          <Button
                            color="info"
                            id="2"
                            size="sm"
                            tag="label"
                            className={classNames("btn-simple", {
                              active: bigChartData === "da4",
                            })}
                            onClick={() => setBgChartData("da4")}
                          >
                            <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                              Year
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="tim-icons icon-tap-02" />
                            </span>
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <LineChart
                      xAxisData={xAxisDataValuePerformanceInsights}
                      dataset={datasetPerformanceInsights}
                      height="100%"
                      width="100%"
                    />
                  </CardBody>
                </Card>
              </div>
            </div>
          </Col>
          <Col lg="4" md="6">
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div
                className="card"
                style={{ flex: "1 0 auto", height: "400px" }}
              >
                <Card className="card-chart" style={{ height: "336px" }}>
                  <CardHeader>
                    <Row>
                      <Col className="text-left" sm="6">
                        <h5 className="card-category">
                          Cycle Time Performance
                        </h5>
                      </Col>
                      <Col sm="6">
                        <ButtonGroup
                          className="btn-group-toggle float-right"
                          data-toggle="buttons"
                        >
                          <Button
                            tag="label"
                            className={classNames("btn-simple", {
                              active: bigChartData === "d1",
                            })}
                            color="info"
                            id="0"
                            size="sm"
                            onClick={() => setBgChartData("d1")}
                          >
                            <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                              Day
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="tim-icons icon-single-02" />
                            </span>
                          </Button>
                          <Button
                            color="info"
                            id="1"
                            size="sm"
                            tag="label"
                            className={classNames("btn-simple", {
                              active: bigChartData === "d2",
                            })}
                            onClick={() => setBgChartData("d2")}
                          >
                            <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                              Week
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="tim-icons icon-gift-2" />
                            </span>
                          </Button>
                          <Button
                            color="info"
                            id="2"
                            size="sm"
                            tag="label"
                            className={classNames("btn-simple", {
                              active: bigChartData === "d3",
                            })}
                            onClick={() => setBgChartData("d3")}
                          >
                            <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                              Month
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="tim-icons icon-tap-02" />
                            </span>
                          </Button>
                          <Button
                            color="info"
                            id="2"
                            size="sm"
                            tag="label"
                            className={classNames("btn-simple", {
                              active: bigChartData === "d4",
                            })}
                            onClick={() => setBgChartData("d4")}
                          >
                            <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                              Year
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="tim-icons icon-tap-02" />
                            </span>
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <LineChart
                      xAxisData={xAxisDataValueCycleTimePerformance}
                      dataset={datasetCycleTimePerformance}
                      height="100%"
                      width="100%"
                    />
                  </CardBody>
                </Card>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
