import React, { useRef, useState, useEffect } from "react";
import "./style.css";
import "./responsive.css";
import { Button, Card, Row, Col, FormGroup, Input } from "reactstrap";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import redirectToLoginIfTokenMissing from "./TokenUtility";
import classnames from "classnames";
import GearTechflip from "../assets/img/GearTechflip.png";
import axios from "axios";
import { APIURL } from "./Apiurl.js";
import PerformanceDashboard from "./PerformanceDashboard";
import MachineHealth from "./MachineHealth";
import BreakdownDiagnostics from "./BreakdownDiagnostics";

function ManagePeople(props) {
  const [MachineID, setMachineID] = React.useState("");
  const handleChangeMachineID = (event) => {
    setMachineID(event.target.value);
  };

  const [OrganizationData, setOrganizationData] = React.useState("");
  const handleChangeOrganization = (event) => {
    setOrganizationData(event.target.value);
  };

  const [PlantData, setPlantData] = React.useState("");
  const handleChangePlant = (event) => {
    setPlantData(event.target.value);
  };

  const [DepartmentData, setDepartmentData] = React.useState("");
  const handleChangeDepartment = (event) => {
    setDepartmentData(event.target.value);
  };

  const [bigChartData, setbigChartData] = React.useState("data1");
  const setBgChartData = (name) => {
    setbigChartData(name);
  };
  const [bigChartData1, setbigChartData1] = React.useState("data1");
  const setBgChartData1 = (name) => {
    setbigChartData1(name);
  };
  const [bigChartData2, setbigChartData2] = React.useState("data1");
  const setBgChartData2 = (name) => {
    setbigChartData2(name);
  };
  const [activeTab, setActiveTab] = useState("1");
  const [MachineData, setMachineData] = useState([]);

  const storedTenantId = sessionStorage.getItem("selectedTenantId");

  console.log("selected Tenant ID:", storedTenantId);
  console.log(
    "selected Machine ID:",
    sessionStorage.getItem("selectedMachineId")
  );

  useEffect(() => {
    redirectToLoginIfTokenMissing(); // Call the utility function to validate the token
  }, []);

  const gaugeContainerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem("token");
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        const roleId = decodedToken["sub"];
        let logintenantId = null;
        console.log(token);
        if (roleId === 1) {
          logintenantId = sessionStorage.getItem("selectedTenantId");
        } else {
          logintenantId =
            decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
        }
        const MachineAutoId = sessionStorage.getItem("selectedMachineId");
        const SECRET_KEY = process.env.REACT_APP_X_API_SECRET_KEY;
        // Ensure that the token is in the correct format (Bearer token)
        const authorizationHeader = `Bearer ${token}`;

        const response = await axios.post(
          APIURL,
          {
            query: `
              query TntOrgMachineAssignment($machineautoid: Int!) {
                tnt_org_machine_assignment(where: {machine_auto_id: { _eq: $machineautoid } }) {
                  machine_name
                  machineid
                  org_id
                  tenantid
                  unit
                  department
                  organization {
                    organization_node_name
                  }
                  plant {
                    organization_node_name
                  }
                  dept {
                    organization_node_name
                  }
                }
              }            
            `,
            variables: {
              machineautoid: MachineAutoId,
            },
          },
          {
            headers: {
              Authorization: authorizationHeader,
              "Content-Type": "application/json",
            },
          }
        );

        setMachineData(response.data.data.tnt_org_machine_assignment[0]);
        console.log(response.data.data.tnt_org_machine_assignment[0]);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const updateContainerWidth = () => {
      if (gaugeContainerRef.current) {
        setContainerWidth(gaugeContainerRef.current.clientWidth);
      }
    };

    // Update the container width when the window is resized
    window.addEventListener("resize", updateContainerWidth);

    // Initial width calculation
    updateContainerWidth();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateContainerWidth);
    };
  }, []);

  ///////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////
  const [org, setorg] = useState("");
  const [unit, setunit] = useState("");
  const [dept, setdept] = useState("");
  const [depts, setdepts] = useState([]);
  const [machine, setMachine] = useState("");
  const [machines, setMachines] = useState([]);
  const [plants, setplants] = useState([]);
  const [orgs, setorgs] = useState([]);
  const [otherData, setOtherData] = useState([]);
  const [selectedOrgData, setSelectedOrgData] = useState(null);

  const token = sessionStorage.getItem("token");
  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const tenantId =
    decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
  console.log(tenantId, "is a tenantId for cycle time");
  useEffect(() => {
    fetchplant();
  }, [org]);
  const fetchorg = async (logintenantId) => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
      query MyQuery($logintenantId: Int!) {
        tnt_organization_structure(
          where: {
            parent_organization_id: { _eq: 0 },
            tenantid: { _eq: $logintenantId }
          }
        ) {
          organization_structure_id
          organization_node_name
        }
      }
    `,
          variables: {
            logintenantId: parseInt(logintenantId),
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );
      const fetchedOrgs = response.data.data.tnt_organization_structure;
      setorgs(fetchedOrgs);
      console.log(fetchedOrgs); //PsplDemoTent
      const parentIds = fetchedOrgs.map((org) => org.organization_structure_id);
      console.log(parentIds); //parentID == 41

      const childResponse = await axios.post(
        APIURL,
        {
          query: `
      query MyQuery($parentIds: [Int!]) {
        tnt_organization_structure(
          where: {
            parent_organization_id: { _in: $parentIds }
          }
        ) {
          organization_structure_id
          organization_node_name
        }
      }
    `,
          variables: {
            parentIds: parentIds,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      const childOrgs = childResponse.data.data.tnt_organization_structure;
      console.log(childOrgs); // select organization == psplDemoTent_Org
      const updatedOtherData = otherData.map((row) => {
        const matchingOrg = childOrgs.find(
          (org) => org.organization_structure_id === row.parent_organization_id
        );

        if (matchingOrg) {
          return {
            ...row,
            parent_organization_id: matchingOrg.organization_structure_id,
          };
        }

        return row;
      });

      setOtherData(childOrgs);
      console.log(otherData);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["sub"];
    let logintenantId = null;

    if (roleId == 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
    }

    fetchorg(logintenantId);
  }, []);
  const fetchplant = async () => {
    if (org) {
      // Only fetch if org is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
        query MyQuery($p: Int!) {
          tnt_organization_structure(where: {parent_organization_id: {_eq: $p}}) {
            organization_structure_id
            organization_node_name
          }
        }
      `,
            variables: {
              p: org,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        // console.log(org);
        setplants(response.data.data.tnt_organization_structure);
        console.log(response.data.data.tnt_organization_structure);
      } catch (error) {
        console.error(error);
      }
    }
  };
  useEffect(() => {
    fetchdept();
  }, [unit]);
  const fetchdept = async () => {
    if (unit) {
      // Only fetch if org is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
        query MyQuery($d: Int!) {
          tnt_organization_structure(where: {parent_organization_id: {_eq: $d}}) {
            organization_structure_id
            organization_node_name
          }
        }
      `,
            variables: {
              d: unit,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        // console.log(org);
        setdepts(response.data.data.tnt_organization_structure);
        console.log(response.data.data.tnt_organization_structure);
      } catch (error) {
        console.error(error);
      }
    }
  };
  console.log(process.env.REACT_APP_X_API_SECRET_KEY);

  useEffect(() => {
    fetchMachine();
  }, [dept]);
  const fetchMachine = async () => {
    if (dept) {
      // Only fetch if dept is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
        query MyQuery($m: Int!) {
         tnt_org_machine_assignment(where: {dept: {organization_structure_id: {_eq: $m}}} ) {
            machine_name
          }
        }
      `,
            variables: {
              m: dept,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        // console.log(org);
        setMachines(response.data.data.tnt_org_machine_assignment || []);
        console.log(response.data.data.tnt_organization_structure);
      } catch (error) {
        console.error(error);
      }
    }
  };

  console.log(process.env.REACT_APP_X_API_SECRET_KEY);

  const handleOrgSelect = async (selectedOrgId) => {
    if (selectedOrgId) {
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
        query GetCostCenter($orgId: Int!) {
          tnt_organization_structure(where: { organization_structure_id: { _eq: $orgId } }) {
            cost_centre_code
          }
        }
      `,
            variables: {
              orgId: selectedOrgId,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );

        if (
          response.data &&
          response.data.data &&
          response.data.data.tnt_organization_structure &&
          response.data.data.tnt_organization_structure.length > 0
        ) {
          setSelectedOrgData(response.data.data.tnt_organization_structure[0]);
        } else {
          console.log(response);
          setSelectedOrgData(null);
        }
      } catch (error) {
        console.error("Error fetching organization data:", error);
      }
    } else {
      setSelectedOrgData(null);
    }

    setorg(selectedOrgId);
  };

  return (
    <>
      <div className="content">
        <div className="image-container">
          <img
            src={GearTechflip}
            alt="GearTechflip"
            className="top-right-image"
          />
        </div>
        {/* <Row>
          <Col lg="2">
            <FormGroup>
              <Input
                type="select"
                value={org}
                onChange={(e) => handleOrgSelect(e.target.value)}
              >
                <option value="">Select Organization</option>
                {otherData.map((org) => (
                  <option
                    key={org.organization_structure_id}
                    value={org.organization_structure_id}
                  >
                    {org.organization_node_name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <Input
                type="select"
                value={unit}
                onChange={(e) => setunit(e.target.value)}
              >
                <option value="">Select Unit/Plant</option>
                {plants.map((plant) => (
                  <option
                    key={plant.organization_structure_id}
                    value={plant.organization_structure_id}
                  >
                    {plant.organization_node_name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <Input
                type="select"
                value={dept}
                onChange={(e) => setdept(e.target.value)}
              >
                <option value="">Select department</option>
                {depts.map((dpt) => (
                  <option
                    key={dpt.organization_structure_id}
                    value={dpt.organization_structure_id}
                  >
                    {dpt.organization_node_name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <Input
                type="select"
                value={machine}
                onChange={(e) => setMachine(e.target.value)}
              >
                <option value="">Select Machine</option>
                {machines.map((mc) => (
                  <option
                    key={mc.organization_structure_id}
                    value={mc.organization_structure_id}
                  >
                    {mc.machine_name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <Input type="date" />
            </FormGroup>
          </Col>
          <Col lg="2">
            <Button lg="2" className="btn btn-secondary">
              Submit
            </Button>
            <Button lg="2" className="btn btn-secondary">
              Clear
            </Button>
          </Col>
        </Row> */}

        <Row>
          <Col lg="2" md="3" sm="4" xs="6">
            <Card>
              <div className="info-header">
                Machine/Equipment Analysis - Level 1
              </div>
            </Card>
          </Col>
          <Col lg="2" md="3" sm="4" xs="6">
            <FormGroup>
              <Input
                type="select"
                value={org}
                onChange={(e) => handleOrgSelect(e.target.value)}
              >
                <option value="">Select Organization</option>
                {otherData.map((org) => (
                  <option
                    key={org.organization_structure_id}
                    value={org.organization_structure_id}
                  >
                    {org.organization_node_name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col lg="2" md="3" sm="4" xs="6">
            <FormGroup>
              <Input
                type="select"
                value={unit}
                onChange={(e) => setunit(e.target.value)}
              >
                <option value="">Select Unit/Plant</option>
                {plants.map((plant) => (
                  <option
                    key={plant.organization_structure_id}
                    value={plant.organization_structure_id}
                  >
                    {plant.organization_node_name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col lg="2" md="3" sm="4" xs="6">
            <FormGroup>
              <Input
                type="select"
                value={dept}
                onChange={(e) => setdept(e.target.value)}
              >
                <option value="">Select department</option>
                {depts.map((dpt) => (
                  <option
                    key={dpt.organization_structure_id}
                    value={dpt.organization_structure_id}
                  >
                    {dpt.organization_node_name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col lg="2" md="3" sm="4" xs="6">
            <FormGroup>
              <Input
                type="select"
                value={machine}
                onChange={(e) => setMachine(e.target.value)}
              >
                <option value="">Select Machine</option>
                {machines.map((mc) => (
                  <option
                    key={mc.organization_structure_id}
                    value={mc.organization_structure_id}
                  >
                    {mc.machine_name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col lg="1" md="1" sm="1" xs="1">
            <Col lg="2">
              <Button lg="2" className="btn btn-secondary">
                Submit
              </Button>
            </Col>
          </Col>
        </Row>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => toggleTab("1")}
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor: activeTab === "1" ? "#5555e7" : "transparent",
                color: activeTab === "1" ? "white" : "white",
              }}
            >
              Performance Dashboard
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => toggleTab("2")}
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor: activeTab === "2" ? "#5555e7" : "transparent",
                color: activeTab === "2" ? "white" : "white",
              }}
            >
              Machine Health
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => toggleTab("3")}
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor: activeTab === "3" ? "#5555e7" : "transparent",
                color: activeTab === "3" ? "white" : "white",
              }}
            >
              Breakdown Diagnostics
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col>
                <PerformanceDashboard />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col>
                <MachineHealth />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col>
                <BreakdownDiagnostics />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </>
  );
}

export default ManagePeople;
