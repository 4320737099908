import React, { useRef, useState, useEffect } from "react";
// import "./style.css";
// import "./responsive.css";
import {
  Button,
  Card,
  ButtonGroup,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import redirectToLoginIfTokenMissing from "views/TokenUtility";
import classnames from "classnames";
import classNames from "classnames";
import PercentageCircle from "views/PercentageCircle";
import MachineBasedStatistics from "views/MachineBasedStatistics";
import axios from "axios";
import { APIURL } from "views/Apiurl.js";
import ReactSpeedometer from "react-d3-speedometer";
import { QueryApi } from "QueryApi";
import LineChart from "assets/charts/LineChart";
import BarChart from "../../assets/charts/BarChart";

function FocusProductionVolume(props) {
  const [bigChartData, setbigChartData] = React.useState("data1");
  const [activeTab, setActiveTab] = useState("1");
  const storedTenantId = sessionStorage.getItem("selectedTenantId");
  console.log("selected Tenant ID:", storedTenantId);
  useEffect(() => {
    redirectToLoginIfTokenMissing(); // Call the utility function to validate the token
  }, []);
  const [org, setorg] = useState("");

  const [unit, setunit] = useState("");

  const [dept, setdept] = useState("");
  const [depts, setdepts] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const gaugeContainerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [plants, setplants] = useState([]);
  const [orgs, setorgs] = useState([]);
  const [otherData, setOtherData] = useState([]);
  const [selectedOrgData, setSelectedOrgData] = useState(null);
  const token = sessionStorage.getItem("token");
  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const tenantId =
    decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
  useEffect(() => {
    fetchplant();
  }, [org]);
  const fetchorg = async (logintenantId) => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery($logintenantId: Int!) {
          tnt_organization_structure(
            where: {
              parent_organization_id: { _eq: 0 },
              tenantid: { _eq: $logintenantId }
            }
          ) {
            organization_structure_id
            organization_node_name
          }
        }
      `,
          variables: {
            logintenantId: parseInt(logintenantId),
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      const fetchedOrgs = response.data.data.tnt_organization_structure;
      setorgs(fetchedOrgs);
      console.log(fetchedOrgs);
      const parentIds = fetchedOrgs.map((org) => org.organization_structure_id);
      console.log(parentIds);

      const childResponse = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery($parentIds: [Int!]) {
          tnt_organization_structure(
            where: {
              parent_organization_id: { _in: $parentIds }
            }
          ) {
            organization_structure_id
            organization_node_name
          }
        }
      `,
          variables: {
            parentIds: parentIds,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      const childOrgs = childResponse.data.data.tnt_organization_structure;
      console.log(childOrgs);
      const updatedOtherData = otherData.map((row) => {
        const matchingOrg = childOrgs.find(
          (org) => org.organization_structure_id === row.parent_organization_id
        );

        if (matchingOrg) {
          return {
            ...row,
            parent_organization_id: matchingOrg.organization_structure_id,
          };
        }

        return row;
      });

      setOtherData(childOrgs);
      console.log(otherData);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["sub"];
    let logintenantId = null;

    if (roleId === 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
    }

    fetchorg(logintenantId);
  }, []);
  const fetchplant = async () => {
    if (org) {
      // Only fetch if org is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
          query MyQuery($p: Int!) {
            tnt_organization_structure(where: {parent_organization_id: {_eq: $p}}) {
              organization_structure_id
              organization_node_name
            }
          }
        `,
            variables: {
              p: org,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        // console.log(org);
        setplants(response.data.data.tnt_organization_structure);
        console.log(response.data.data.tnt_organization_structure);
      } catch (error) {
        console.error(error);
      }
    }
  };
  useEffect(() => {
    fetchdept();
  }, [unit]);
  const fetchdept = async () => {
    if (unit) {
      // Only fetch if org is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
          query MyQuery($d: Int!) {
            tnt_organization_structure(where: {parent_organization_id: {_eq: $d}}) {
              organization_structure_id
              organization_node_name
            }
          }
        `,
            variables: {
              d: unit,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        // console.log(org);
        setdepts(response.data.data.tnt_organization_structure);
        console.log(response.data.data.tnt_organization_structure);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleOrgSelect = async (selectedOrgId) => {
    if (selectedOrgId) {
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
          query GetCostCenter($orgId: Int!) {
            tnt_organization_structure(where: { organization_structure_id: { _eq: $orgId } }) {
              cost_centre_code
            }
          }
        `,
            variables: {
              orgId: selectedOrgId,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );

        if (
          response.data &&
          response.data.data &&
          response.data.data.tnt_organization_structure &&
          response.data.data.tnt_organization_structure.length > 0
        ) {
          setSelectedOrgData(response.data.data.tnt_organization_structure[0]);
        } else {
          console.log(response);
          setSelectedOrgData(null);
        }
      } catch (error) {
        console.error("Error fetching organization data:", error);
      }
    } else {
      setSelectedOrgData(null);
    }

    setorg(selectedOrgId);
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const mixedChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };
  const mixedChartData = {
    labels: ["January", "February", "March", "April", "May"],
    datasets: [
      {
        label: "Line Data 1",
        type: "line", // Specify line type
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 2,
        tension: 0.5,
        data: [7, 16, 25, 35, 54],
      },
      {
        label: "Line Data 2",
        type: "line", // Specify line type
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 2,
        tension: 0.5,
        data: [9, 23, 29, 32, 34],
      },
    ],
  };

  useEffect(() => {
    const updateContainerWidth = () => {
      if (gaugeContainerRef.current) {
        setContainerWidth(gaugeContainerRef.current.clientWidth);
      }
    };

    // Update the container width when the window is resized
    window.addEventListener("resize", updateContainerWidth);

    // Initial width calculation
    updateContainerWidth();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateContainerWidth);
    };
  }, []);

  const handleSubmit = () => {
    console.log("Selected Organization:", org);
    console.log("Selected Unit/Plant:", unit);
    console.log("Selected Department:", dept);
    console.log("Selected Date:", selectedDate);
  };

  const handleClear = () => {
    setorg("");
    setunit("");
    setdept("");
    setSelectedDate("");
  };

  //////Target Adherence ////////////////////////////////////////////////////////////////////////

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const currDay = day < 10 ? `0${day}` : day;
  const currMonth = month < 10 ? `0${month}` : month;
  let currentDate = JSON.stringify(`${year}-${currMonth}-${currDay}`);

  const [dayData, setDayData] = useState({
    details: "This Day",
    target: "No Data",
    achieved: "No Data",
    targetValue: "No Data",
  });
  useEffect(() => {
    targetAdherenceDay();
  }, []);
  const targetAdherenceDay = async () => {
    try {
      const data = await QueryApi(`
      query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
          dm_daily(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
          target_parts
          total_parts_produced
          target_adherence
          }
      }
      `);
      const targetPartsSum = data.dm_daily.reduce(
        (acc, curr) => acc + curr.target_parts,
        0
      );
      const partsProducedSum = data.dm_daily.reduce(
        (acc, curr) => acc + curr.total_parts_produced,
        0
      );
      const targetAdherenceSum = data.dm_daily.reduce(
        (acc, curr) => acc + curr.target_adherence,
        0
      );
      setDayData({
        details: "This Day",
        target: targetPartsSum,
        achieved: partsProducedSum,
        targetValue: targetAdherenceSum,
      });
    } catch (error) {
      setDayData({
        details: "This Day",
        target: "No Data",
        achieved: "No Data",
        targetValue: "No Data",
      });
    }
  };

  const dateData = new Date();
  dateData.setDate(dateData.getDate() - 7);
  var dayDataValue = dateData.getDate();
  var monthDataValue = dateData.getMonth() + 1;
  var yearDataValue = dateData.getFullYear();
  var formattedDay = dayDataValue < 10 ? `0${dayDataValue}` : dayDataValue;
  var formattedMonth =
    monthDataValue < 10 ? `0${monthDataValue}` : monthDataValue;
  var weekDataDate = JSON.stringify(
    `${yearDataValue}-${formattedMonth}-${formattedDay}`
  );

  const [weekData, setWeekData] = useState({
    details: "This Week",
    target: "No Data",
    achieved: "No Data",
    targetValue: "No Data",
  });
  useEffect(() => {
    targetAdherenceWeek();
  }, []);
  const targetAdherenceWeek = async () => {
    try {
      const data = await QueryApi(`
      query MyQuery($start_date: date = ${weekDataDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
          dm_weekly(where: {start_date: {_eq: $start_date}, end_date: {_eq: $end_date}, tenantid: {_eq: $tenantid}}) {
              target_adherence
              target_parts
              total_parts_produced
          }
          }
      `);
      const targetPartsSumweekly = data.dm_weekly.reduce(
        (acc, curr) => acc + curr.target_parts,
        0
      );
      const partsProducedSumweekly = data.dm_weekly.reduce(
        (acc, curr) => acc + curr.total_parts_produced,
        0
      );
      const targetAdherenceSumweekly = data.dm_weekly.reduce(
        (acc, curr) => acc + curr.target_adherence,
        0
      );
      setWeekData({
        details: "This Week",
        target: targetPartsSumweekly,
        achieved: partsProducedSumweekly,
        targetValue: targetAdherenceSumweekly,
      });
    } catch (error) {
      setWeekData({
        details: "This Week",
        target: "No Data",
        achieved: "No Data",
        targetValue: "No Data",
      });
    }
  };

  const previousDate = new Date();
  previousDate.setDate(1);
  const previousDay = previousDate.getDate();
  const previousMonth = previousDate.getMonth() + 1;
  const previousYear = previousDate.getFullYear();
  const formattedPreviousDay =
    previousDay < 10 ? `0${previousDay}` : previousDay;
  const formattedPreviousMonth =
    previousMonth < 10 ? `0${previousMonth}` : previousMonth;
  const previousMonthDate = JSON.stringify(
    `${previousYear}-${formattedPreviousMonth}-${formattedPreviousDay}`
  );

  const [monthData, setMonthData] = useState({
    details: "This Month",
    target: "No Data",
    achieved: "No Data",
    targetValue: "No Data",
  });
  useEffect(() => {
    targetAdherenceMonth();
  }, []);
  const targetAdherenceMonth = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${previousMonthDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
              dm_monthly(where: {start_date: {_eq: $start_date}, end_date: {_eq: $end_date}, tenantid: {_eq: $tenantid}}) {
                total_parts_produced
                target_adherence
                target_parts
              }
            }
      `);
      const targetPartsSumMonthly = data.dm_monthly.reduce(
        (acc, curr) => acc + curr.target_parts,
        0
      );
      const partsProducedSumMonthly = data.dm_monthly.reduce(
        (acc, curr) => acc + curr.total_parts_produced,
        0
      );
      const targetAdherenceSumMonthly = data.dm_monthly.reduce(
        (acc, curr) => acc + curr.target_adherence,
        0
      );
      setMonthData({
        details: "This Month",
        target: targetPartsSumMonthly,
        achieved: partsProducedSumMonthly,
        targetValue: targetAdherenceSumMonthly,
      });
    } catch (error) {
      setMonthData({
        details: "This Month",
        target: "No Data",
        achieved: "No Data",
        targetValue: "No Data",
      });
    }
  };

  const yearCurrentDate = new Date();
  yearCurrentDate.setDate(1);
  yearCurrentDate.setMonth(1);
  const yearDay = yearCurrentDate.getDate();
  const yearMonth = yearCurrentDate.getMonth();
  const yearYear = yearCurrentDate.getFullYear();
  const formattedYearDay = yearDay < 10 ? `0${yearDay}` : yearDay;
  const formattedYearMonth = yearMonth < 10 ? `0${yearMonth}` : yearMonth;
  const yearPreviousMonthDate = JSON.stringify(
    `${yearYear}-${formattedYearMonth}-${formattedYearDay}`
  );

  const [yearData, setYearData] = useState({
    details: "Year Till Date",
    target: "No Data",
    achieved: "No Data",
    targetValue: "No Data",
  });
  useEffect(() => {
    targetAdherenceYear();
  }, []);
  const targetAdherenceYear = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${yearPreviousMonthDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
              dm_yearly(where: {start_date: {_eq: $start_date}, end_date: {_eq: $end_date}, tenantid: {_eq: $tenantid}}) {
                target_adherence
                target_parts
                total_parts_produced
              }
            }
      `);
      const targetPartsSumYearly = data.dm_yearly.reduce(
        (acc, curr) => acc + curr.target_parts,
        0
      );
      const partsProducedSumYearly = data.dm_yearly.reduce(
        (acc, curr) => acc + curr.total_parts_produced,
        0
      );
      const targetAdherenceSumYearly = data.dm_yearly.reduce(
        (acc, curr) => acc + curr.target_adherence,
        0
      );
      setYearData({
        details: "Year Till Date",
        target: targetPartsSumYearly,
        achieved: partsProducedSumYearly,
        targetValue: targetAdherenceSumYearly,
      });
    } catch (error) {
      setYearData({
        details: "Year Till Date",
        target: "No Data",
        achieved: "No Data",
        targetValue: "No Data",
      });
    }
  };

  //// Availability ////////////////////////////////////////////////////////////////////////

  const [AvailabilityDataList, setAvailabilityDataList] = useState({
    machine_availability_percent: "No Data",
  });
  useEffect(() => {
    Availability();
  }, []);
  const Availability = async () => {
    try {
      const data = await QueryApi(`
        query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
          dm_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
            aggregate {
              avg {
                machine_availability_percent
              }
            }
          }
        } `);
      setAvailabilityDataList({
        machine_availability_percent:
          data.dm_daily_aggregate.aggregate.avg.machine_availability_percent,
      });
    } catch (error) {
      setAvailabilityDataList({
        machine_availability_percent: "No Data",
      });
    }
  };

  ///// Average Downtime ////////////////////////////////////////////

  const [AverageDowntimeDataList, setAverageDowntimeDataList] = useState({
    total_machine_downtime: "No Data",
  });
  useEffect(() => {
    AverageDowntime();
  }, []);
  const AverageDowntime = async () => {
    try {
      const data = await QueryApi(`
        query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
          dm_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
            aggregate {
              sum {
                total_machine_downtime
              }
            }
          }
        } `);
      const hoursDowntime =
        data.dm_daily_aggregate.aggregate.sum.total_machine_downtime /
        (1000 * 60 * 60);
      setAverageDowntimeDataList({
        total_machine_downtime: hoursDowntime.toFixed(0),
      });
    } catch (error) {
      setAverageDowntimeDataList({
        total_machine_downtime: "No Data",
      });
    }
  };

  //// Performance Insights ///////////////////////////////////////

  const [
    xAxisDataValuePerformanceInsights,
    setXAxisDataValuePerformanceInsights,
  ] = useState([]);
  const [plotPointPerformanceInsights, setPlotPointPerformanceInsights] =
    useState([]);
  const datasetPerformanceInsights = [
    {
      label: "Production Time",
      data: plotPointPerformanceInsights,
      borderColor: "#1F8EF1",
    },
  ];

  useEffect(() => {
    GraphDataListPerformanceInsights();
  }, [bigChartData]);
  const GraphDataListPerformanceInsights = async () => {
    if (bigChartData === "data1") {
      try {
        const data = await QueryApi(`
                query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
                    dm_org_tenant_hourly(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                        total_machine_downtime
                        timestamp
                    }
                }
            `);
        setXAxisDataValuePerformanceInsights([
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
          20, 21, 22, 23, 24,
        ]);
        const plotAllDataProductionVolume = data.dm_org_tenant_hourly.map(
          (data) => {
            const objData = {
              // point: (data.total_machine_downtime / (1000 * 60 * 60)).toFixed(2),
              point: (data.total_machine_downtime / (1000 * 60 * 60)).toFixed(
                2
              ),
              Xaxis: data.timestamp,
            };
            return objData;
          }
        );
        const pointDate = new Date();
        const resPointDate = pointDate.getHours();
        let plotValue = [];
        for (var i = 0; i <= resPointDate; i++) {
          plotValue[i] = 0;
        }
        plotAllDataProductionVolume.forEach((data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
          if (h >= 0 && h <= 1) {
            plotValue[0] = data.point;
          } else if (h >= 1 && h <= 2) {
            plotValue[1] = data.point;
          } else if (h >= 2 && h <= 3) {
            plotValue[2] = data.point;
          } else if (h >= 3 && h <= 4) {
            plotValue[3] = data.point;
          } else if (h >= 4 && h <= 5) {
            plotValue[4] = data.point;
          } else if (h >= 5 && h <= 6) {
            plotValue[5] = data.point;
          } else if (h >= 6 && h <= 7) {
            plotValue[6] = data.point;
          } else if (h >= 7 && h <= 8) {
            plotValue[7] = data.point;
          } else if (h >= 8 && h <= 9) {
            plotValue[8] = data.point;
          } else if (h >= 9 && h <= 10) {
            plotValue[9] = data.point;
          } else if (h >= 10 && h <= 11) {
            plotValue[10] = data.point;
          } else if (h >= 11 && h <= 12) {
            plotValue[11] = data.point;
          } else if (h >= 12 && h <= 13) {
            plotValue[12] = data.point;
          } else if (h >= 13 && h <= 14) {
            plotValue[13] = data.point;
          } else if (h >= 14 && h <= 15) {
            plotValue[14] = data.point;
          } else if (h >= 15 && h <= 16) {
            plotValue[15] = data.point;
          } else if (h >= 16 && h <= 17) {
            plotValue[16] = data.point;
          } else if (h >= 17 && h <= 18) {
            plotValue[17] = data.point;
          } else if (h >= 18 && h <= 19) {
            plotValue[18] = data.point;
          } else if (h >= 19 && h <= 20) {
            plotValue[19] = data.point;
          } else if (h >= 20 && h <= 21) {
            plotValue[20] = data.point;
          } else if (h >= 21 && h <= 22) {
            plotValue[21] = data.point;
          } else if (h >= 22 && h <= 23) {
            plotValue[22] = data.point;
          } else if (h >= 23 && h <= 24) {
            plotValue[23] = data.point;
          } else {
            return null;
          }
        });
        setPlotPointPerformanceInsights(plotValue);
      } catch (error) {
        console.log(error);
        setXAxisDataValuePerformanceInsights([]);
        setPlotPointPerformanceInsights([]);
      }
    } else if (bigChartData === "data2") {
      try {
        const data = await QueryApi(`
                query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                    dm_org_tenant_daily(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                        total_machine_downtime
                        timestamp
                    }
                }
            `);
        const plotAllDataProductionVolume = data.dm_org_tenant_daily.map(
          (data) => {
            const objData = {
              point: (data.total_machine_downtime / (1000 * 60 * 60)).toFixed(
                2
              ),
              Xaxis: data.timestamp,
            };
            return objData;
          }
        );

        // const plotPointDataProductionVolume = plotAllDataProductionVolume.map(
        //     (data) => data.point
        // );
        // setPlotPointProductionVolume(plotPointDataProductionVolume);

        function getPlotAllDaysBetween(startDate, endDate) {
          var newDate = new Date(startDate);
          var weekDATE = new Date(endDate);
          var days = [];
          while (newDate <= weekDATE) {
            const dd = newDate.getDate();
            const mm = newDate.getMonth() + 1;
            const yy = newDate.getFullYear();
            const formattedd = dd < 10 ? `0${dd}` : dd;
            const formattedmm = mm < 10 ? `0${mm}` : mm;
            const fullDATE = `${yearYear}-${formattedmm}-${formattedd}`;
            days.push(fullDATE);
            newDate.setDate(newDate.getDate() + 1);
          }
          return days;
        }
        var daysBetween = getPlotAllDaysBetween(weekDataDate, currentDate);
        var pointArr = [];
        daysBetween.map((data) => {
          var arrlet = plotAllDataProductionVolume.map((data) => {
            var arrDate = new Date(data.Xaxis);
            let nday = arrDate.getUTCDate();
            let nmonth = arrDate.getMonth() + 1;
            let nyear = arrDate.getFullYear();
            const ncurrDay = nday < 10 ? `0${nday}` : nday;
            const ncurrMonth = nmonth < 10 ? `0${nmonth}` : nmonth;
            let nDateList = `${nyear}-${ncurrMonth}-${ncurrDay}`;
            return nDateList;
          });
          const res = arrlet.includes(data);
          const resindex = arrlet.indexOf(data);
          if (res === true) {
            pointArr.push(plotAllDataProductionVolume[resindex].point);
          } else {
            pointArr.push(0);
          }
        });
        setPlotPointPerformanceInsights(pointArr);

        function getAllDaysBetween(startDate, endDate) {
          var currentDate = new Date(startDate);
          var days = [];
          while (currentDate <= endDate) {
            days.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
          }
          return days;
        }
        function getDayOfWeek(date) {
          var days = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];
          return days[date.getDay()];
        }
        function getDaysOfWeekBetween(startDate, endDate) {
          var daysBetween = getAllDaysBetween(startDate, endDate);
          var daysOfWeek = [];
          for (var i = 0; i < daysBetween.length; i++) {
            var dayOfWeek = getDayOfWeek(daysBetween[i]);
            daysOfWeek.push(dayOfWeek);
          }
          return daysOfWeek;
        }
        var daysOfWeekBetween = getDaysOfWeekBetween(
          new Date(weekDataDate),
          new Date(currentDate)
        );
        setXAxisDataValuePerformanceInsights(daysOfWeekBetween);
      } catch (error) {
        console.log(error);
        setXAxisDataValuePerformanceInsights([]);
        setPlotPointPerformanceInsights([]);
      }
    } else if (bigChartData === "data3") {
      try {
        const data = await QueryApi(`
        query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_org_tenant_daily(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                total_machine_downtime
                date
            }
        }
      `);
        const plotAllDataPerformanceInsights = data.dm_org_tenant_daily.map(
          (data) => {
            const objData = {
              point: (data.total_machine_downtime / (1000 * 60 * 60)).toFixed(
                2
              ),
              Xaxis: data.date,
            };
            return objData;
          }
        );

        const bothAxisPoint = [];
        for (var i = 1; i <= 31; i++) {
          bothAxisPoint[i] = { pointValue: 0, XaxisValue: i };
        }
        plotAllDataPerformanceInsights.forEach((data) => {
          const allDate = new Date(data.Xaxis);
          const dayss = allDate.getDate();
          bothAxisPoint[dayss] = { pointValue: data.point, XaxisValue: dayss };
        });
        const NewPointData = bothAxisPoint.map((data) => data.pointValue);
        const dd = new Date();
        NewPointData.splice(dd.getDate() + 1, NewPointData.length);
        const NewXaxisData = bothAxisPoint.map((data) => data.XaxisValue);
        setPlotPointPerformanceInsights(NewPointData);
        setXAxisDataValuePerformanceInsights(NewXaxisData);
      } catch (error) {
        console.log(error);
        setXAxisDataValuePerformanceInsights([]);
        setPlotPointPerformanceInsights([]);
      }
    } else if (bigChartData === "data4") {
      try {
        const data = await QueryApi(`
                query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                    dm_org_tenant_monthly(where: {start_date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                      total_machine_downtime
                      timestamp
                 }
                }
            `);
        const plotAllDataProductionVolume = data.dm_org_tenant_monthly.map(
          (data) => {
            const objData = {
              point: (data.total_machine_downtime / (1000 * 60 * 60)).toFixed(
                2
              ),
              Xaxis: data.timestamp,
            };
            return objData;
          }
        );

        const plotPointDataProductionVolume = plotAllDataProductionVolume.map(
          (data) => data.point
        );
        setPlotPointPerformanceInsights(plotPointDataProductionVolume);
        setXAxisDataValuePerformanceInsights([
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ]);
      } catch (error) {
        console.log(error);
        setXAxisDataValuePerformanceInsights([]);
        setPlotPointPerformanceInsights([]);
      }
    } else {
      setbigChartData("data1");
    }
  };

  // const GraphDataListPerformanceInsights = async () => {
  //   try {
  //     const data = await QueryApi(`
  //       query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem('tenantid')}) {
  //           dm_daily(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
  //             total_machine_downtime
  //             timestamp
  //           }
  //         }
  //     `);
  // setXAxisDataValuePerformanceInsights([])
  // setPlotPointPerformanceInsights([])
  // };

  //// Performance Insights (Total Production) ////////////////////////////////////////////////////////

  const [TotalProduction, setTotalProduction] = useState([]);
  const [XaxisTotalProduction, setXaxisTotalProduction] = useState([]);

  useEffect(() => {
    TotalProductionfun();
  }, []);
  const TotalProductionfun = async () => {
    try {
      const data = await QueryApi(`
      query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
          dm_daily(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
            total_parts_produced
            machineid
          }
      }
    `);
      var xAxisDataPerformance = data.dm_daily.map((data) => data.machineid);
      setXaxisTotalProduction(xAxisDataPerformance);
      var DataPerformance = data.dm_daily.map((data) => {
        return { value: data.total_parts_produced, color: "#1F8EF1" };
      });
      setTotalProduction(DataPerformance);
    } catch (error) {
      setTotalProduction([]);
      setXaxisTotalProduction([]);
    }
  };

  return (
    <>
      <div className="content">
        <Row className="align-items-stretch" style={{ height: "33rem" }}>
        <Col
            lg="3"
            md="8"
            sm="6"
            className="d-flex"
            style={{ height: "100%" }}
          ><Card>
             <CardHeader>
                <h5 className="card-category">Plant Selection (Press control to select multiple)</h5> 
              </CardHeader>
            <Row className="mt-3 ml-1 mr-1">
            <Col> 
            <Card className="card-chart w-100 border border-white text-center">
              <CardBody className="d-flex align-items-center justify-content-center">
                <h3>OPP1</h3>
              </CardBody>
            </Card>
            </Col>
            <Col> 
            <Card className="card-chart w-100 border border-white text-center">
              <CardBody className="d-flex align-items-center justify-content-center">
                <h3>OPP2</h3>
              </CardBody>
            </Card>
            </Col>
            <Col> 
            <Card className="card-chart w-100 border border-white text-center">
              <CardBody className="d-flex align-items-center justify-content-center">
                <h3>OPP3</h3>
              </CardBody>
            </Card>
            </Col>
            </Row>
            <Row className="mt-3 ml-1 mr-1">
            <Col> 
            <Card className="card-chart w-100 border border-white text-center">
              <CardBody className="d-flex align-items-center justify-content-center">
                <h3>OPP4</h3>
              </CardBody>
            </Card>
            </Col>
            <Col> 
            <Card className="card-chart w-100 border border-white text-center">
              <CardBody className="d-flex align-items-center justify-content-center">
                <h3>OPP5</h3>
              </CardBody>
            </Card>
            </Col>
            <Col> 
            <Card className="card-chart w-100 border border-white text-center">
              <CardBody className="d-flex align-items-center justify-content-center">
                <h3>OPP6</h3>
              </CardBody>
            </Card>
            </Col>
            </Row>
            <Row className="mt-3 ml-1 mr-1">
            <Col> 
            <Card className="card-chart w-100 border border-white text-center">
              <CardBody className="d-flex align-items-center justify-content-center">
                <h3>OPP7</h3>
              </CardBody>
            </Card>
            </Col>
            <Col> 
            <Card className="card-chart w-100 border border-white text-center">
              <CardBody className="d-flex align-items-center justify-content-center">
                <h3>OPP8</h3>
              </CardBody>
            </Card>
            </Col>
            <Col> 
            <Card className="card-chart w-100  text-center">
              <CardBody className="d-flex align-items-center justify-content-center">
                {/* <h3>OPP9</h3> */}
              </CardBody>
            </Card>
            </Col>
            </Row>
          </Card>  
          </Col>
          <Col
            lg="3"
            md="8"
            sm="6"
            className="d-flex"
            style={{ height: "100%" }}
          >
            <Card className="card-chart w-100">
              <CardHeader>
                <h5 className="card-category">Reusable Waste (Generation)Mt by Plant</h5>
                
              </CardHeader>
              <CardBody className="d-flex align-items-center justify-content-center">
                <div className="chart-area" style={{ height: "96%" }}>
                  <BarChart
                    xAxisData={XaxisTotalProduction}
                    yAxisData={TotalProduction}
                    height="100%"
                    width="100%"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col
            lg="3"
            md="8"
            sm="6"
            className="d-flex"
            style={{ height: "100%" }}
          >
            <Card className="card-chart w-100">
              <CardHeader>
                <h5 className="card-category">Reusable Waste (Consumption)Mt by Plant</h5>
              </CardHeader>
              <CardBody className="d-flex align-items-center justify-content-center">
                <div className="chart-area" style={{ height: "96%" }}>
                  <BarChart
                    xAxisData={XaxisTotalProduction}
                    yAxisData={TotalProduction}
                    height="100%"
                    width="100%"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col
            lg="3"
            md="8"
            sm="6"
            className="d-flex"
            style={{ height: "100%" }}
          >
            <Card className="card-chart w-100">
              <CardHeader>
                <h5 className="card-category">Non-reusable Waste (Generation)Mt by Plant</h5>
              </CardHeader>
              <CardBody className="d-flex align-items-center justify-content-center">
                <div className="chart-area" style={{ height: "96%" }}>
                  <BarChart
                    xAxisData={XaxisTotalProduction}
                    yAxisData={TotalProduction}
                    height="100%"
                    width="100%"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="align-items-stretch" style={{ height: "33rem" }}>
        <Col
            lg="3"
            md="8"
            sm="6"
            className="d-flex"
            style={{ height: "100%" }}
          >
            <Card className="card-chart w-100">
              <CardHeader>
                <h5 className="card-category">Jumbo Produced Mt by Plant</h5>
                
              </CardHeader>
              <CardBody className="d-flex align-items-center justify-content-center">
                <div className="chart-area" style={{ height: "96%" }}>
                  <BarChart
                    xAxisData={XaxisTotalProduction}
                    yAxisData={TotalProduction}
                    height="100%"
                    width="100%"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col
            lg="3"
            md="8"
            sm="6"
            className="d-flex"
            style={{ height: "100%" }}
          >
            <Card className="card-chart w-100">
              <CardHeader>
                <h5 className="card-category">Jumbo Efficiency of Line% by Plant</h5>
              </CardHeader>
              <CardBody className="d-flex align-items-center justify-content-center">
                <div className="chart-area" style={{ height: "96%" }}>
                  <BarChart
                    xAxisData={XaxisTotalProduction}
                    yAxisData={TotalProduction}
                    height="100%"
                    width="100%"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col
            lg="3"
            md="8"
            sm="6"
            className="d-flex"
            style={{ height: "100%" }}
          >
            <Card className="card-chart w-100">
              <CardHeader>
                <h5 className="card-category">Slit Efficiency of Line% by Plant</h5>
              </CardHeader>
              <CardBody className="d-flex align-items-center justify-content-center">
                <div className="chart-area" style={{ height: "96%" }}>
                  <BarChart
                    xAxisData={XaxisTotalProduction}
                    yAxisData={TotalProduction}
                    height="100%"
                    width="100%"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col
            lg="3"
            md="8"
            sm="6"
            className="d-flex"
            style={{ height: "100%" }}
          >
            <Card className="card-chart w-100">
              <CardHeader>
                <h5 className="card-category">Slit Production (B)Mt by Plant</h5>
              </CardHeader>
              <CardBody className="d-flex align-items-center justify-content-center">
                <div className="chart-area" style={{ height: "96%" }}>
                  <BarChart
                    xAxisData={XaxisTotalProduction}
                    yAxisData={TotalProduction}
                    height="100%"
                    width="100%"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="align-items-stretch" style={{ height: "33rem" }}>
        <Col
            lg="3"
            md="8"
            sm="6"
            className="d-flex"
            style={{ height: "100%" }}
          >
            <Card className="card-chart w-100">
              <CardHeader>
                <h5 className="card-category">Packed Production Mt by Plant</h5>
                
              </CardHeader>
              <CardBody className="d-flex align-items-center justify-content-center">
                <div className="chart-area" style={{ height: "96%" }}>
                  <BarChart
                    xAxisData={XaxisTotalProduction}
                    yAxisData={TotalProduction}
                    height="100%"
                    width="100%"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col
            lg="3"
            md="8"
            sm="6"
            className="d-flex"
            style={{ height: "100%" }}
          >
            <Card className="card-chart w-100">
              <CardHeader>
                <h5 className="card-category">Packed Production (Average)Mt by Plant</h5>
              </CardHeader>
              <CardBody className="d-flex align-items-center justify-content-center">
                <div className="chart-area" style={{ height: "96%" }}>
                  <BarChart
                    xAxisData={XaxisTotalProduction}
                    yAxisData={TotalProduction}
                    height="100%"
                    width="100%"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col
            lg="3"
            md="8"
            sm="6"
            className="d-flex"
            style={{ height: "100%" }}
          >
            <Card className="card-chart w-100">
              <CardHeader>
                <h5 className="card-category">WIP Reduction Mt by Plant</h5>
              </CardHeader>
              <CardBody className="d-flex align-items-center justify-content-center">
                <div className="chart-area" style={{ height: "96%" }}>
                  <BarChart
                    xAxisData={XaxisTotalProduction}
                    yAxisData={TotalProduction}
                    height="100%"
                    width="100%"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col
            lg="3"
            md="8"
            sm="6"
            className="d-flex"
            style={{ height: "100%" }}
          >
            <Card className="card-chart w-100">
              <CardHeader>
                <h5 className="card-category">Power Consumption Units/T by Plant</h5>
              </CardHeader>
              <CardBody className="d-flex align-items-center justify-content-center">
                <div className="chart-area" style={{ height: "96%" }}>
                  <BarChart
                    xAxisData={XaxisTotalProduction}
                    yAxisData={TotalProduction}
                    height="100%"
                    width="100%"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
       
      </div>
    </>
  );
}

export default FocusProductionVolume;
