import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from "reactstrap";
import axios from "axios";
import { APIURL } from './Apiurl.js';
import { toast } from 'react-toastify';
import Papa from 'papaparse';
import exampleCSV from '../assets/csv/tnt_organization_structure_csv.csv';
import exampleCSV1 from '../assets/csv/config_code_master_csv.csv';

function JobrolemasterAdd() {
  const [activeStep, setActiveStep] = useState(1);
  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileData, setFileData] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDownload = async () => {
    const fileName = 'tnt_organization_structure_csv.csv';
    const filePath = exampleCSV;

    try {
      // Fetch the CSV file using the filePath
      const response = await fetch(filePath);

      if (!response.ok) {
        throw new Error('Failed to fetch the file');
      }

      const csvData = await response.text();

      // Create a Blob object from the CSV data
      const blob = new Blob([csvData], { type: 'text/csv' });

      // Generate a URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary anchor element and trigger the download
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();

      // Cleanup: Revoke the Blob URL and remove the temporary anchor element
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleDownloadConfig = async () => {
    const fileName = 'config_code_master.csv';
    const filePath = exampleCSV1;

    try {
      // Fetch the CSV file using the filePath
      const response = await fetch(filePath);

      if (!response.ok) {
        throw new Error('Failed to fetch the file');
      }

      const csvData = await response.text();

      // Create a Blob object from the CSV data
      const blob = new Blob([csvData], { type: 'text/csv' });

      // Generate a URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary anchor element and trigger the download
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();

      // Cleanup: Revoke the Blob URL and remove the temporary anchor element
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
const handleFileSubmit = () => {
    if (!selectedFile) {
      toast.error("Please select a file to import.");
      return;
    }
  
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      try {
        const data = fileReader.result;
        // Parse the CSV data using papaparse library
        const parsedData = Papa.parse(data, {
          header: true, // Use the first row as headers
          skipEmptyLines: true, // Skip empty lines in CSV
        });
  
        // Assuming your CSV has the following headers:
        // org_code, org_node_type, org_node_name, country_id, city_name, parent_org, cost_center
        const formData = parsedData.data.map((row) => ({
          org_code: row.org_code,
          org_node_type: row.org_node_type,
          org_node_name: row.org_node_name,
          country_id: row.country_id,
          city_name: row.city_name,
          parent_org: row.parent_org,
          cost_center: row.cost_center,
          is_active: "1",
        }));
  
        // Send the data to the Hasura API using axios
        if (formData.length > 0) { // Check if there is data to be inserted
          axios
            .post(APIURL, {
              query: `
                mutation InsertOrganization($formData: [organization_insert_input!]!) {
                  insert_organization(objects: $formData) {
                    affected_rows
                  }
                }
              `,
              variables: {
                formData: formData,
              },
            })
            .then((response) => {
              console.log(response); // Handle the response data as needed
              if (response.data.data && response.data.data.insert_organization.affected_rows > 0) {
                setFileData(formData);
                toast.success("CSV data uploaded successfully!");
              } else {
                toast.error("Failed to insert data.");
              }
            })
            .catch((error) => {
              console.error(error); // Handle the error
              toast.error("An error occurred while uploading data.");
            });
        } else {
          toast.warning("No data found in the CSV file.");
        }
      } catch (error) {
        console.error(error);
        toast.error("Error parsing the file. Please check the format.");
      }
    };
  
    fileReader.readAsText(selectedFile);
  };
  

  return (
    <div className="content">
      <Row className="progress-row">
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Import CSV File for Organization</h5>
            </CardHeader>
            <CardBody id="import-card">
              <Form>
                <Row>
                <Col className="px-md-1" md="6">
                    <FormGroup>
                    
                      <Label for="fileInput" className="custom-file-label">
                        {selectedFile ? selectedFile.name : "Choose CSV file"}
                      </Label>
                      <Input
                        type="file"
                        id="fileInput"
                        className="custom-file-input"
                        accept=".csv"
                        onChange={handleFileChange}
                      />
                    </FormGroup>
                  </Col>
                 
                </Row>
              
                <Row>
                 
                  <Col className="px-md-1" md="6">
                    <FormGroup>
                      <div className="">
                        <Button className="" style={{float: "right", marginTop: "-11px", marginRight: "-168px" }} color="success" onClick={handleFileSubmit}>
                          Submit CSV
                        </Button>
                       
                        
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
            <CardFooter>
              <div className="download-btn">
                <Button className="" color="success" onClick={handleDownload}>
                  Download Example CSV
                </Button>
                <Button className="" color="success" onClick={handleDownloadConfig}>
                  Download Config Master
                </Button>
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default JobrolemasterAdd;
