import React, { useState, useEffect } from "react";
import redirectToLoginIfTokenMissing from "../../TokenUtility";
import { TabContent, TabPane, Card, CardBody, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { Badge } from "reactstrap";
import { QueryApi } from "QueryApi.js";

function JobrolemasterAll() {
  const [activeTab, setActiveTab] = useState(1);
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    redirectToLoginIfTokenMissing();
    fetchDataList();
  }, []);

  const fetchDataList = async () => {
    try {
      const data = await QueryApi(`
        query MyQuery {
           extrusion_dt_reason_daily{
            downtime_contribution_percent
            downtime_reason_category
            downtime_type
            downtime_subtype
            machineid
            mean_time_bet_failures
            recurrances
          }
        }
      `);
      setApiData(data?.extrusion_dt_reason_daily || []);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const convertMillisecondsToHours = (milliseconds) => {
    const hours = milliseconds / (1000 * 60 * 60);
    return hours.toFixed(2);
  };

  const formatPercentage = (percentage) => {
    return parseFloat(percentage).toFixed(3);
  };

  const actionFormatter = (cell, row) => {
    return (
      <div>
        <Link to={`/admin/dashboard/machine-equipment/${row.machine_auto_id}`}>
          <FontAwesomeIcon
            icon={faArrowRightToBracket}
            className="btn btn-primary btn-go-deep"
            style={{
              fontSize: "30px !important",
              background: "none",
              pointerEvents: "none",
            }}
          />
        </Link>
      </div>
    );
  };

  const activeFormatter = (cell, row) => {
    const isActive = row.is_active === "1";
    return (
      <Badge color={isActive ? "info" : "danger"}>
        {isActive ? "Active" : "Inactive"}
      </Badge>
    );
  };

  const columns = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machineid",
      text: "Machine Name",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "downtime_reason_category",
      text: "Breakdown Category",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "downtime_type",
      text: "Breakdown Type",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "downtime_subtype",
      text: "Breakdown Subtype",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "recurrances",
      text: "Recurrances",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "downtime_contribution_percent",
      text: "% Time loss Contribution",
      formatter: (cell) => formatPercentage(cell),
      headerStyle: { width: "500px" },
    },
    {
      dataField: "mean_time_bet_failures",
      text: "MTBF(in hrs.)",
      formatter: (cell) => convertMillisecondsToHours(cell),
      headerStyle: { width: "500px" },
    },
    {
      dataField: "operator_present",
      text: "MTTR(in hrs.)",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "operator_present",
      text: "Repair-inter./ext.",
      headerStyle: { width: "500px" },
    },
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="content">
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={apiData}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <div>
                        <BootstrapTable
                          {...props.baseProps}
                          filter={filterFactory()}
                          pagination={paginationFactory()}
                          data={apiData}
                          headerClasses="sorted-asc sorted-desc"
                          wrapperClasses="table-responsive"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default JobrolemasterAll;
