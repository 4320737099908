import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Progress,
} from "reactstrap";
import axios from "axios";
import { APIURL } from './Apiurl.js';
import { toast } from 'react-toastify';

const JobrolemasterAdd = (props) => {
  const [activeStep, setActiveStep] = useState(1);
    const [checked, setChecked] = useState(false);
    const [org, setorg] = useState("");

    const [unit, setunit] = useState("");
    const [units, setunits] = useState([]);

    const [break1name, setbreak1name] = useState("");
    const [break2name, setbreak2name] = useState("");


    const [shiftId, setShiftId] = useState("");
    const [shiftname, setshiftname] = useState("");

    const [endtime, setendtime] = useState("");
    const [starttime, setstarttime] = useState();
    const [break1StartTime, setBreak1StartTime] = useState();
    const [break1EndTime, setBreak1EndTime] = useState();
    const [break2StartTime, setBreak2StartTime] = useState();
    const [break2EndTime, setBreak2EndTime] = useState();
    const [progress, setProgress] = useState(0);
    const [tenantId, setTenantId] = useState("");
    const [createdby, setcreatedby] = useState("");
    const [isLastStep, setIsLastStep] = useState(false);
    const [tenants, setTenants] = useState([]);
    const [shifttypes, setshifttypestypes] = useState([]);
    console.log(shifttypes, "<---------shifttypes")
    const [orgs, setorgs] = useState([]);
    const [otherData, setOtherData] = useState([]);
    const [shift, setShift] = useState("");

    const [plants, setplants] = useState([]);
    useEffect(() => {
      fetchorg();
      fetchstypes();
      fetchTenants();
    }, []);
    useEffect(() => {
      fetchplant();
    }, [org]);
    const token = sessionStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["sub"];
    let logintenantId = null;
      if (roleId == 1) {
        logintenantId = sessionStorage.getItem("selectedTenantId");
      } else {
        logintenantId = decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
      }

    const fetchorg = async (logintenantId) => {
      try {
        const response = await axios.post(APIURL, {
          query: `
            query FetchOrgStruct($logintenantId: Int!) {
              tnt_organization_structure(
                where: {
                  parent_organization_id: { _eq: 0 },
                  tenantid: { _eq: $logintenantId }
                }
              ) {
                organization_structure_id
                organization_node_name
              }
            }
          `,
          variables: {
            logintenantId: parseInt(logintenantId),
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
             Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        });

        const fetchedOrgs = response.data.data.tnt_organization_structure;
        setorgs(fetchedOrgs);
        const parentIds = fetchedOrgs.map(org => org.organization_structure_id);

        const childResponse = await axios.post(APIURL, {
          query: `
            query OrgStructure($parentIds: [Int!]) {
              tnt_organization_structure(
                where: {
                  parent_organization_id: { _in: $parentIds }
                }
              ) {
                organization_structure_id
                organization_node_name
              }
            }
          `,
          variables: {
            parentIds: parentIds,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
             Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        });

        const childOrgs = childResponse.data.data.tnt_organization_structure;
        const updatedOtherData = otherData.map(row => {
          const matchingOrg = childOrgs.find(
            org => org.organization_structure_id === row.parent_organization_id
          );

          if (matchingOrg) {
            return {
              ...row,
              parent_organization_id: matchingOrg.organization_structure_id,
            };
          }

          return row;
        });

        setOtherData(childOrgs);
      } catch (error) {
        // console.error(error);
      }
    };
    useEffect(() => {
      fetchorg(logintenantId);
    }, []);
    const fetchplant = async () => {
      if (org) { // Only fetch if org is selected
        try {
          const response = await axios.post(APIURL, {
            query: `
              query MyQuery($p: Int!) {
                tnt_organization_structure(where: {parent_organization_id: {_eq: $p}}) {
                  organization_structure_id
                  organization_node_name
                }
              }
            `,
            variables: {
              p: org,
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
               Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          });
          setplants(response.data.data.tnt_organization_structure);
        } catch (error) {
          // console.error(error);
        }
      }
    };
    const fetchstypes = async () => {
      try {
        const response = await axios.post(APIURL, {
          query: `
          query MyQuery {
            config_code_master(where: {code_type: {_eq: "ShiftType"}}) {
              code_id
              code_type
              code
            }
          }
          `,
        },
        {
          headers: {
            'Content-Type': 'application/json',
             Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        });

        setshifttypestypes(response.data.data.config_code_master);
      } catch (error) {
        // console.error(error);
      }      
    };
    const fetchTenants = async () => {
      try {
        const response = await axios.post(APIURL, {
          query: `
            query {
              tenants {
                tenantid
                tenant_name
              }
            }
          `,
        },
        {
          headers: {
            'Content-Type': 'application/json',
             Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        });

        setTenants(response.data.data.tenants);
      } catch (error) {
        // console.error(error);
      }
    };  

    const convertIstToUtc = (istTime) => {
      const [hours, minutes] = istTime.split(':');
      const date = new Date();
      date.setUTCHours(hours - 5, minutes - 30, 0, 0); 
      const utcTime = date.toISOString().split('T')[1].substring(0, 8);
      return utcTime;
    };

    const convertbreakoneIstToUtc = (istTime) => {
      const [hours, minutes] = istTime.split(':');
      const date = new Date();
      date.setUTCHours(hours - 5, minutes - 30, 0, 0); 
      const utcTime = date.toISOString().split('T')[1].substring(0, 8);
      return utcTime;
    };

    const convertbreaktwoIstToUtc = (istTime) => {
      const [hours, minutes] = istTime.split(':');
      const date = new Date();
      date.setUTCHours(hours - 5, minutes - 30, 0, 0); 
      const utcTime = date.toISOString().split('T')[1].substring(0, 8);
      return utcTime;
    };

    const handleFormSubmit = (event) => {
      event.preventDefault();
      const token = sessionStorage.getItem('token');
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const roleId = decodedToken['sub'];
    let logintenantId = null;
    if(roleId == 1){
       logintenantId = sessionStorage.getItem('selectedTenantId');
    }else{
       logintenantId = decodedToken['https://hasura.io/jwt/claims']['x-hasura-org-id'];
    }
    if(!starttime){
      toast.error('Please enter shift start time');
      return;
    }
    if(!endtime){
      toast.error('Please enter shift end time');
      return;
    }
    if (!break1StartTime || !break1EndTime || !break2StartTime || !break2EndTime) {
      toast.error('Please enter break times.');
      return;
    }

      // Create an object with the form data
      const formData = {

        tenantid: logintenantId,
        organization_id: org,
        plant:unit,
        shift_type: shiftId,
        shift_name: shiftname,
        shift:shift,
        shift_start_time: convertIstToUtc(starttime),
        shift_end_time: convertIstToUtc(endtime),
        break1_name: break1name,
        break_one_start_time:convertbreakoneIstToUtc(break1StartTime),
        break_one_end_time: convertbreakoneIstToUtc(break1EndTime),
        break2_name: break2name,
        break_two_start_time: convertbreaktwoIstToUtc(break2StartTime),
        break_two_end_time: convertbreaktwoIstToUtc(break2EndTime),
        created_by: logintenantId,
      };
      axios
      .post(APIURL, {
          query: `
              mutation InsertWorkScedule($formData: tnt_work_schedule_masters_insert_input!) {
                  insert_tnt_work_schedule_masters(objects: [$formData]) {
                  affected_rows
                  }
              }
          `,

          variables: {
            formData: formData,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
             Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        })
        .then((response) => {
          if (response.data.data && response.data.data.insert_tnt_work_schedule_masters.affected_rows > 0) {
              props.onInsertSuccess();
              setTenantId("");
              setorg("");
              setunit("");
              setShiftId("");
              setshiftname("");
              setstarttime("");
              setendtime("");
              setbreak1name("");
              setBreak1StartTime("");
              setBreak1EndTime("");
              setbreak2name("");
              setBreak2StartTime("");
              setBreak2EndTime("");
              setProgress(0);
              setActiveStep(1);
            toast.success('Work Schedule inserted successfully!');
          } else {
            toast.error('Failed to insert data.');
          }
        })
        .catch((error) => {
          toast.error('An error occurred while inserting data.');
        });
      };

  const handleNext = () => {
    if (activeStep === 1) {
      setProgress(100);
    }
    setActiveStep(activeStep + 1);
    if (activeStep + 1 === 3) {
      setIsLastStep(true);
    }
  };

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
    setIsLastStep(false);
  };
  const shifts = [
    { id: 1, value: 'Shift 1' },
    { id: 2, value: 'Shift 2' },
    { id: 3, value: 'Shift 3' },
  ];
  // const shifts = ['Shift 1', 'Shift 2', 'Shift 3'];

  const renderStepContent = () => {
    switch (activeStep) {
        case 1:
          return (
            <>
              <Row>
               <Col className="pr-md-1" md="6">
                 <FormGroup>
                   <label>Organization</label>
                   <Input
                     type="select"
                     value={org}
                     onChange={(e) => setorg(e.target.value)}
                   >
                     <option value="">Select Organization</option>
                     {otherData.map((org) => (
                       <option key={org.organization_structure_id} value={org.organization_structure_id}>
                         {org.organization_node_name}
                       </option>
                     ))}
                   </Input>
                 </FormGroup>
               </Col>
               <Col className="pr-md-1" md="6">
                 <FormGroup>
                   <label>Plant/Unit</label>
                   <Input
                     type="select"
                     value={unit}
                     onChange={(e) => setunit(e.target.value)}
                   >
                     <option value="">Select Unit/Plant</option>
                     {plants.map((plant) => (
                       <option key={plant.organization_structure_id} value={plant.organization_structure_id}>
                         {plant.organization_node_name}
                       </option>
                     ))}
                   </Input>
                 </FormGroup>
               </Col>
          </Row>

            <Row>
            <Col className="px-md-1" md="4">
              <FormGroup>
                <Label for="orgnodeid">Shift Type</Label>
                <Input
                  type="select"
                  id="orgnodeid"
                  value={shiftId}
                  onChange={(e) => {
                    setShiftId(e.target.value);
                    const selectedShiftType = shifttypes.find(styp => styp.code_id === parseInt(e.target.value, 10));
                    if (selectedShiftType.code === "Routine Shift") {
                      setShift(""); // Reset the shift value
                    } else if (selectedShiftType.code === "General Shift") {
                      setShift(0);
                    } else if (selectedShiftType.code === "Odd Time Shift") {
                      setShift(4);
                    }
                  }} 
                >
                  <option value="">Select Shift Type</option>
                  {shifttypes.map((styp) => (
                    <option
                      key={styp.code_id}
                      value={styp.code_id}
                    >
                      {styp.code}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              </Col>
              <Col className="px-md-1" md="4">
              <FormGroup>
                <label>Shift</label>
                <Input 
                  type="select" 
                  name="shift"
                  value={shift}
                  onChange={(e) => setShift(e.target.value)}
                  disabled={shiftId === "" || shift !== ""}
                >
                  <option value="">Select Shift</option>
                  {shifts.map((shift) => (
                    <option key={shift.id} value={shift.id}>
                      {shift.value}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              </Col>
              {/* <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="orgnodeid">Shift Type</Label>
                  <Input
                    type="select"
                    id="orgnodeid"
                    value={shiftId}
                    onChange={(e) => {
                        setShiftId(e.target.value);
                        // handleOrgNodeChange(e); // Call handleOrgNodeChange function here
                      }}    
                  >
                    <option value="">Select Shift Type</option>
                    {shifttypes.map((styp) => (
                      <option
                        key={styp.code_id}
                        value={styp.code_id}
                      >
                        {styp.code}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <label>Shift</label>
                  <Input type="select" 
                  name="shift"
                  value={shift}
                  onChange={(e) => setShift(e.target.value)}
                  >
                  <option value="">Select Shift</option>
                  {shifts.map((shift, index) => (
                    <option key={shift.id} value={shift.id}>
                      {shift.value}
                    </option>
                  ))}
                </Input>
                </FormGroup>
              </Col> */}
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <label>Shift Name</label>
                  <Input placeholder="Shift Name" type="text" 
                  value={shiftname}
                  onChange={(e) => setshiftname(e.target.value)}
                  />
                </FormGroup>
              </Col>

            </Row>
            <Row>
              <Col className="px-md-1" md="6">
                <FormGroup>
                  <label>Start Time</label>
                  <Input placeholder="Start time" type="time" 
                  value={starttime}
                  onChange={(e) => setstarttime(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="6">
                <FormGroup>
                  <label>End Time</label>
                  <Input placeholder="End time" type="time" 
                  value={endtime}
                  onChange={(e) => setendtime(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            </>
          );
        case 2:
          return (
            <>
              <Row>
                <Col className="px-md-1" md="4">
                  <FormGroup>
                    <label>1st Break Name</label>
                    <Input placeholder="Enter 1st Break Name" type="text" 
                    value={break1name}
                    onChange={(e) => setbreak1name(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col className="pr-md-1" md="4">
                  <FormGroup>
                    <label>Break 1 Start Time</label>
                    <Input placeholder="Break 1 Start Time" type="time" 
                    value={break1StartTime}
                    onChange={(e) => setBreak1StartTime(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col className="px-md-1" md="4">
                  <FormGroup>
                    <label>Break 1 End Time</label>
                    <Input placeholder="Break 1 End Time" type="time" 
                    value={break1EndTime}
                    onChange={(e) => setBreak1EndTime(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="px-md-1" md="4">
                  <FormGroup>
                    <label>2nd Break Name</label>
                    <Input placeholder="Enter 2nd Break Name" type="text" 
                    value={break2name}
                    onChange={(e) => setbreak2name(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col className="pl-md-1" md="4">
                  <FormGroup>
                    <label>Break 2 Start Time</label>
                    <Input placeholder="Break 2 Start Time" type="time" 
                    value={break2StartTime}
                    onChange={(e) => setBreak2StartTime(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col className="pr-md-1" md="4">
                  <FormGroup>
                    <label>Break 2 End Time</label>
                    <Input placeholder="Break 2 End Time" type="time" 
                    value={break2EndTime}
                    onChange={(e) => setBreak2EndTime(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </>
          );

        default:
          return null;
      }
  };
  const calculateProgress = () => {
    return Math.round(((activeStep - 1) / 2) * 100); // Update the denominator to match the total number of steps minus one
  };


  const renderProgressBarDestinations = () => {
    const step1Class = activeStep >= 1 ? "progress-destination-active" : "";
    const step2Class = activeStep >= 2 ? "progress-destination-active" : "";
    const step3Class = activeStep >= 3 ? "progress-destination-active" : "";


    return (
      <div className="progress-destinations">
        <div className={`progress-destination ${step1Class}`}>
          <span>1</span>
        </div>
        <div className={`progress-destination ${step2Class}`}>
          <span>2</span>
        </div>
        <div className={`progress-destination ${step3Class}`}>
          <span>3</span>
        </div>
      </div>
    );
  };
  const renderStepButtons = () => {
    if (activeStep === 1) {
      return (
        <Button color="primary" onClick={handleNext}>
          Next
        </Button>
      );
    } else if (activeStep === 2) {
      return (
        <>
          <Button color="primary" onClick={handlePrevious}>
            Previous
          </Button>
          <Button color="success" onClick={handleFormSubmit}>
            Submit
          </Button>
        </>
      );
    }
  };


  return (
    <div className="content">
      <Row>
        <Col md="8">
          <div className="progress-container">
            {renderProgressBarDestinations()}
            <Progress
              max="100"
              value={calculateProgress()}
              className="progress-bar-sm progress-bar-striped"
            />
          </div>
        </Col>
      </Row>
      <Row className="progress-row">
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Word Schedule Master</h5>
            </CardHeader>
            <CardBody>
              <Form>{renderStepContent()}</Form>
            </CardBody>
            <CardFooter>
              <div className="text-right">
                {renderStepButtons()}
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );

}

export default JobrolemasterAdd;
