import React, { useState, useEffect } from "react";
import { TabContent, TabPane, Card, CardBody, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { QueryApi } from "QueryApi";

const WorkForceByJobRole = (props) => {
  const [activeTab, setActiveTab] = useState(1);
  const [employees, setEmployees] = useState([]);

  const [apiData, setApiData] = useState([]);
  useEffect(() => {
    fetchDataList();
  }, []);
  const fetchDataList = async () => {
    const data = await QueryApi(`
  query MyQuery {
    wdm_workforce_availability {
      org_id
    unit_id
    department_id
    Org {
      organization_node_name
    }
    Unit {
      organization_node_name
    }
    Dept {
      organization_node_name
    }
    total_workforce
    available_workforce
    unavailable_workforce_per
    job_role
    dmfoundation {
      target_adherence
    }
  }
}
  
  `);
    setApiData(data?.wdm_workforce_availability);
  };

  function actionFormatter(cell, row) {
    return (
      <div>
        <Col
          className="font-icon-list col-xs-6 col-xs-6"
          lg="2"
          md="3"
          sm="4"
          onClick={() => handleEdit(row)}
        ></Col>
        <FontAwesomeIcon
          icon={faPencilAlt}
          className="btn btn-primary tim-icons icon-pencil"
          style={{
            fontSize: "70%",
            background: "none",
            marginRight: "-46px",
            pointerEvents: "none",
          }}
        />
        <Link to={`/admin/org-structure/${row.organization_structure_id}`}>
          <FontAwesomeIcon
            icon={faTrash}
            className="btn btn-primary tim-icons icon-pencil"
            style={{
              fontSize: "70%",
              background: "none",
              pointerEvents: "none",
            }}
          />
        </Link>
      </div>
    );
  }
  function handleEdit(row) {}
  const columns = [
    {
      dataField: "date",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
    },
    {
      dataField: "Org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "Unit.organization_node_name",
      text: "Plant / Unit ",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "Dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "job_role",
      text: "Job role",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "total_workforce",
      text: "Ideal workforce availability",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "available_workforce",
      text: "Average workforce Availability",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "unavailable_workforce_per",
      text: "Leave/ absenteeism Rate",
      formatter: (cell, row) => parseFloat(cell).toFixed(3),
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dmfoundation.target_adherence",
      text: "Production plan achievement %",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "capacity_utilized_percent",
      text: "Category",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "capacity_utilized_percent",
      text: "Drill Down",
      headerStyle: { width: "500px" },
    },
  ];

  return (
    <div className="content">
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={employees}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <div>
                        <BootstrapTable
                          {...(props.baseProps && props.baseProps)}
                          filter={filterFactory()}
                          pagination={paginationFactory()}
                          data={apiData || []}
                          headerClasses="sorted-asc sorted-desc"
                          wrapperClasses="table-responsive"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default WorkForceByJobRole;
