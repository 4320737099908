import React, { useState, useEffect } from "react";
import axios from "axios";
import  { APIURL } from './Apiurl.js';
import {
  TabContent,
  TabPane,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import "./datatable.css";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import Modal from '@material-ui/core/Modal';
import MachineAdd from "./MachineAdd";
import AssignParameters from "./AssignParameters";
import AssignAttribute from "./AssignAttribute";
import { Badge } from "reactstrap";
import { faPencilAlt, faTrash, faCheck } from "@fortawesome/free-solid-svg-icons";

const { SearchBar } = Search;

function AllOrganization() {
  const [activeTab, setActiveTab] = useState(1);
  const [struct, setstruct] = useState([]);
  const [orgstruct, setorgstruct] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);

  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [openAssignAttributeModal, setOpenAssignAttributeModal] = useState(false);

  const [machineType, setMachineType] = useState("");
  const [machineSubType, setMachineSubType] = useState("");
  const [machineTypename, setMachineTypename] = useState("");
  const [machineSubTypename, setMachineSubTypename] = useState("");
  const [machineid, setmachineid] = useState("");
  const [edgeid, setedgeid] = useState("");

  const handleClose = () => {
    setOpen(false);
};
const [machinesWithParameters, setMachinesWithParameters] = useState([]);
const [machinesWithAttribute, setMachinesWithAttribute] = useState([]);
const token = sessionStorage.getItem('token');

useEffect(() => {
  fetchMachinesWithParameters();
}, []);
const fetchMachinesWithParameters = async () => {
  try {
    // Fetch machineid values from tnt_machine_parameter_mapping
    const response = await axios.post(APIURL, {
      query: `
        query {
          tnt_machine_parameter_mapping {
            machineid
          }
        }
      `,
    }, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    const machines = response.data.data.tnt_machine_parameter_mapping.map(item => item.machineid);
    setMachinesWithParameters(machines);
  } catch (error) {
    console.error(error);
  }
};

const handleOpen = () => {
    setOpen(true);
};
useEffect(() => {
  fetchData();
}, []);
  const fetchData = async () => {
    try {
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      const roleId = decodedToken['sub'];
      const logintenantId = decodedToken['https://hasura.io/jwt/claims']['x-hasura-org-id'];
      const storedTenantId = sessionStorage.getItem('selectedTenantId');
      console.log(roleId == 1);
      
      let query = ''; // Define the query variable outside the if-else block
      let variables = {}; // Define the variables object
      
      if (roleId == 1) {
        if(storedTenantId !==null){
          query = `
          query MachineForTntSort($tenantId: Int!) {
            tnt_org_machine_assignment(where: { tenantid: { _eq: $tenantId } }) {
              machine_auto_id
              machineid
              machine_name
              machine_type
              machine_sub_type
              organization {
                organization_node_name
                organization_structure_id
              }
            plant{
              organization_node_name
                organization_structure_id
            }
              dept{
                organization_node_name
                organization_structure_id
              }
              machinetype{
                machine_profile
              }
              machinesubtype{
                machine_profile
              }
              org_id
              edgeid
              machineid
              is_active
              tenantid
            }
          }
        `;
        
        variables = {
          tenantId: storedTenantId,
        };
        }else{
          query = `
          query MachineForAD {
            tnt_org_machine_assignment {
              machine_auto_id
              machineid
              machine_name
              machine_type
              machine_sub_type
              organization {
                organization_node_name
                organization_structure_id
              }
            plant{
              organization_node_name
                organization_structure_id
            }
              dept{
                organization_node_name
                organization_structure_id
              }
              machinetype{
                machine_profile
              }
              machinesubtype{
                machine_profile
              }
              org_id
              edgeid
              machineid
              is_active
              tenantid
            }
          }
          `;
        }
      
      } else {
        query = `
        query MachineForTnt {
          tnt_org_machine_assignment {
            machine_auto_id
            machineid
            machine_name
            machine_type
            machine_sub_type
            organization {
              organization_node_name
              organization_structure_id
            }
          plant{
            organization_node_name
              organization_structure_id
          }
            dept{
              organization_node_name
              organization_structure_id
            }
            machinetype{
              machine_profile
            }
            machinesubtype{
              machine_profile
            }
            org_id
            edgeid
            machineid
            is_active
            tenantid
          }
        }
        `;
      }

      const response = await axios.post(APIURL, {
        query: query,
        variables: variables,
      }, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });


      setorgstruct(response.data.data.tnt_org_machine_assignment);
        console.log(response.data.data);
      } catch (error) {
        console.error(error);
      }
  };
  const activeFormatter = (cell, row) => {
    const isActive = row.is_active === "1";

    return (
      <Badge color={isActive ? "info" : "danger"}>
        {isActive ? "Active" : "Inactive"}
      </Badge>
    );
  };
  function actionFormatter(cell, row) {
    return (
      <div>
        <Col
          className="font-icon-list col-xs-6 col-xs-6"
          lg="2"
          md="3"
          sm="4"
          onClick={() => handleEdit(row)}
        ></Col>
       <Link to={`/admin/employee-job-details/${row.emp_job_id}`}>
          <i className="btn btn-primary tim-icons icon-pencil" />
        </Link>
      </div>
    );
  }
  const handleCloseAssignModal = () => {
    setOpenAssignModal(false);
  };
  
  const handleAssign = (row) => {
    setMachineType(row.machine_type);
    setMachineSubType(row.machine_sub_type);
    setMachineTypename(row.machinetype.machine_profile);
    setMachineSubTypename(row.machinesubtype.machine_profile);
    setmachineid(row.machine_auto_id);
    setedgeid(row.edgeid);
    console.log("machineid: " + machineid + ", edgeid: " + edgeid);
    setOpenAssignModal(true);
  };
 const handleAssignAttribute = (row) => {
  setMachineType(row.machine_type);
  setMachineSubType(row.machine_sub_type);
  setMachineTypename(row.machinetype.machine_profile);
  setMachineSubTypename(row.machinesubtype.machine_profile);
  setmachineid(row.machine_auto_id);
  setedgeid(row.edgeid);
  setOpenAssignAttributeModal(true); // Use the correct state variable here
};

const handleCloseAssignAttributeModal = () => {
  setOpenAssignAttributeModal(false); // Use the correct state variable here
};

  function handleEdit(row) {
    // Logic for handling the edit action
    console.log("Editing row:", row);
  }

  const columns = [
    {
      dataField: "machine_auto_id",
      text: "Primary Machine ID",
    },
    {
      dataField: "machine_name",
      text: "Machine Name",
      headerStyle: { width: '500px' }
    },
    {
      dataField: "organization.organization_node_name",
      text: "Organization",
      headerStyle: { width: '500px' }
    },
    {
      dataField: "plant.organization_node_name",
      text: "Plant/Unit",
      headerStyle: { width: '500px' }
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: '500px' }
    },
    {
    dataField: "Parameter Status",
    text: "Parameter Status",
    formatter: assignFormatter,
  },
  {
    dataField: "Attribute Status",
    text: "Attributes",
    formatter: assignattributeFormatter,
  },
    {
      dataField: "is_active",
      text: "Status",
      formatter: activeFormatter,
    },
    {
      dataField: "action",
      text: "Action",
      formatter: actionFormatter,
    },
  ];
  function assignFormatter(cell, row) {
    const isAssigned = machinesWithParameters.includes(row.machine_auto_id);

    return (
      <div>
        {isAssigned ? (
          <FontAwesomeIcon icon={faCheck} style={{ color: "green" , fontSize: "40px" }} />
        ) : (
          <button className="btn btn-secondary" onClick={() => handleAssign(row)}>
            Assign
          </button>
        )}
      </div>
    );
  }
  function assignattributeFormatter(cell, row) {
    const isAssigned = machinesWithParameters.includes(row.machine_auto_ids);

    return (
      <div>
       <button className="btn btn-secondary" onClick={() => handleAssignAttribute(row)}>
            Assign
          </button>
      </div>
    );
  }
  function actionFormatter(cell, row) {
    return (
      <div>
        <Col
          className="font-icon-list col-xs-6 col-xs-6"
          lg="2"
          md="3"
          sm="4"
          onClick={() => handleEdit(row)}
        ></Col>
       <FontAwesomeIcon
          icon={faPencilAlt}
          className="btn btn-primary tim-icons icon-pencil"
          style={{ fontSize: "70%", background: "none",  marginRight: "-46px", pointerEvents: "none" }}
        />
        <Link to={`/admin/org-structure/${row.organization_structure_id}`}>
          <FontAwesomeIcon
            icon={faTrash}
            className="btn btn-primary tim-icons icon-pencil"
            style={{ fontSize: "70%", background: "none", pointerEvents: "none" }}
          />
        </Link>
      </div>
    );
  }
 

  return (
    <div className="content">
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4" className="table-card-header">
                    Machine/Equipment
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={struct}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <div>
                        <div
                          className="float-right"
                          style={{
                            display: "flex",
                            marginBottom: "18px",
                            gap: "12px",
                          }}
                        >
                          <div className="float-left">
                            <SearchBar {...props.searchProps} />
                          </div>
                          <div className="float-right">
                          <button className="btn btn-secondary" onClick={handleOpen}>
                              Add new Machine
                            </button>
                          
                          </div>
                        </div>
                        <BootstrapTable
                          {...props.baseProps}
                          keyField="id"
                          data={orgstruct}
                          columns={columns}
                          filter={filterFactory()}
                          pagination={paginationFactory({
                            bootstrap4: true,
                          })}                         
                          headerClasses="sorted-asc sorted-desc"
                          wrapperClasses="table-responsive"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
      
      <Modal
        onClose={handleClose}
        open={open}
        style={{
            boxShadow: '2px solid black',
            width: '100%',
            top: '15%',
            left: '15%',
            
        }}
    >
        <div>
    <button
      onClick={handleClose}
      style={{
        position: 'fixed',
            top: '112px',
        right: '399px',
        background: 'transparent',
        border: 'none',
        color: 'white',
        fontSize: '40px',
        /* margin-top: -85px; */
        cursor: 'pointer',
        float: 'right',
        zIndex: 1,
      }}
    >
      &times; {/* This is the 'x' character for the close button */}
    </button>
        <MachineAdd />
        </div>
    </Modal>
    <Modal
      onClose={handleCloseAssignModal}
      open={openAssignModal}
      style={{
        boxShadow: '2px solid black',
        // width: '100%',
        top: '25%',
        left: '8%',
        marginRight: '180px'

      }}
    >
       <div>
    <button
      onClick={handleCloseAssignModal}
      style={{
        position: 'fixed',
        top: '202px',
        right: '195px',
        background: 'transparent',
        border: 'none',
        color: 'white',
        fontSize: '40px',
        /* margin-top: -85px; */
        cursor: 'pointer',
        float: 'right',
        zIndex: 1,
      }}
    >
      &times; {/* This is the 'x' character for the close button */}
    </button>
          <AssignParameters
            machineType={machineType}
            machineSubType={machineSubType}
            machineTypename={machineTypename}
            machineSubTypename={machineSubTypename}
            machineid={machineid}
            edgeid={edgeid}

          />
        </div>
    </Modal>  
    
<Modal
  onClose={handleCloseAssignAttributeModal}
  open={openAssignAttributeModal}
  style={{
    boxShadow: '2px solid black',
    // width: '100%',
    top: '15%',
    left: '8%',
    marginRight: '180px'
  }}
>
  
  <div>
    <button
      onClick={handleCloseAssignAttributeModal}
      style={{
        position: 'fixed',
        top: '116px',
        right: '195px',
        background: 'transparent',
        border: 'none',
        color: 'white',
        fontSize: '40px',
        /* margin-top: -85px; */
        cursor: 'pointer',
        float: 'right',
        zIndex: 1,
      }}
    >
      &times; {/* This is the 'x' character for the close button */}
    </button>
    <AssignAttribute
      machineType={machineType}
      machineSubType={machineSubType}
      machineTypename={machineTypename}
      machineSubTypename={machineSubTypename}
      machineid={machineid}
      edgeid={edgeid}
    />
  </div>
</Modal>
 


    </div>
  );
}

export default AllOrganization;
