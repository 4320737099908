import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Progress,
} from "reactstrap";
import axios from "axios";
import { APIURL } from './Apiurl.js';
import { toast } from 'react-toastify';

const JobrolemasterAdd = (props) => {
  const [activeStep, setActiveStep] = useState(1);
  const [progress, setProgress] = useState(0);

  const [tenantId, setTenantId] = useState("");
  const [organization_node_id, setOrganizationNodeId] = useState("");
  const [organization_node_name, setOrganizationNodeName] = useState("");
  const [organization_shortname, setOrganizationShortName] = useState("");
  const [organization_code, setOrganizationCode] = useState("");
  const [description, setDescription] = useState("");
  const [organization_type, setOrganizationType] = useState("");
  const [organization_category_id, setOrganizationCategoryId] = useState("");
  const [country_id, setCountryId] = useState("");

  const [state_id, setStateId] = useState("");
  const [city_id, setCityId] = useState("");
  const [parent_organization_id, setParentOrganizationId] = useState(0);
  const [cost_centre_code, setCostCentreCode] = useState("");
  const [organization_node_leader, setOrganizationNodeLeader] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [orgnodes, setOrgNodes] = useState([]);
  const [orgTypes, setOrgTyes] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [orgcats, setOrgCats] = useState([]);
  const [orgnames, setOrgnames] = useState([]);
  const [isLastStep, setIsLastStep] = useState(false);
  const [countries, setcountries] = useState([]);


  useEffect(() => {
    fetchTenants();
    fetchOrgNode();
    fetchTypes();
    fetchCats();
    fetchOrgnames();
    fetchcountry();
  }, []);

  const fetchOrgnames = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      const roleId = decodedToken['sub'];
      const tenantId = decodedToken['https://hasura.io/jwt/claims']['x-hasura-org-id'];
      const storedTenantId = sessionStorage.getItem('selectedTenantId');
      let query = '';
      let variables = {}; // You can define variables if needed

      if (roleId === 1) {
        if(storedTenantId !==null){
          query = `
          query ($tenantId: Int!) {
            tnt_organization_structure (
              where: { tenantid: { _eq: $tenantId } }
            ) {
              organization_structure_id
              organization_shortname
              organization_node_name
            }
          }
        `;
        variables = { tenantId: storedTenantId };
        }
        else{
          console.log("sdfasnisarg");
          query = `
            query {
              tnt_organization_structure {
                organization_structure_id
                organization_shortname
                organization_node_name
              }
            }
          `;
        }

      } else {
        query = `
          query ($tenantId: Int!) {
            tnt_organization_structure (
              where: { tenantid: { _eq: $tenantId } }
            ) {
              organization_structure_id
              organization_shortname
              organization_node_name
            }
          }
        `;
        variables = { tenantId: tenantId };
      }

      const response = await axios.post(APIURL, {
        query,
        variables,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
        },
      });

      setOrgnames(response.data.data.tnt_organization_structure);
      console.log(response.data.data.tnt_organization_structure);
    } catch (error) {
      console.error(error);
    }
  };


  const fetchcountry = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.post(APIURL, {
        query: `
        query MyQuery {
          config_code_master(where: {code_type: {_eq: "Country"}}) {
            code_id
            code_type
            code
          }
        }
        `,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
        },
      });

      setcountries(response.data.data.config_code_master);
      console.log(response.data.data.config_code_master);
    } catch (error) {
      console.error(error);
    }

  };
  const fetchCats = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.post(APIURL, {
        query: `
        query MyQuery {
          config_code_master(where: {code_type: {_eq: "Organization category"}}) {
            code_id
            code_type
            code
          }
        }
        `,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
        },
      });

      setOrgCats(response.data.data.config_code_master); // Make sure 'organization_category' is an array
    } catch (error) {
      console.error(error);
    }
  };
  const fetchOrgNode = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.post(APIURL, {
        query: `
          query {
            organization_node {
                organization_node_id
                organization_node_name
              }
          }
        `,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
        },
      });

      setOrgNodes(response.data.data.organization_node);
      console.log(response.data.data.organization_node);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchTenants = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.post(APIURL, {
        query: `
          query {
            tenants {
              tenantid
              tenant_name
            }
          }
        `,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
        },
      });

      setTenants(response.data.data.tenants);
      console.log(response.data.data.tenants);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchTypes = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.post(APIURL, {
        query: `
        query MyQuery {
          config_code_master(where: {code_type: {_eq: "OrgType"}}) {
            code_id
            code_type
            code
          }
        }
        `,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
        },
      });

      setOrgTyes(response.data.data.config_code_master);
    } catch (error) {
      console.error(error);
    }
  };
  const handleOrgNodeChange = (e) => {
    const selectedNodeName = e.target.options[e.target.selectedIndex].text;
    setOrganizationNodeName(selectedNodeName);
  };

  const handleNext = () => {
    if (activeStep === 1) {
      setProgress(100);
    }
    setActiveStep(activeStep + 1);
    if (activeStep + 1 === 3) {
      setIsLastStep(true);
    }
  };

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
    setIsLastStep(false);
  };
  const handleFormSubmit = (event) => {
    event.preventDefault();
    const token = sessionStorage.getItem('token');
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const roleId = decodedToken['sub'];
    console.log(roleId);
    let logintenantId = null;
    if(roleId === 1){
       logintenantId = sessionStorage.getItem('selectedTenantId');
    }else{
       logintenantId = decodedToken['https://hasura.io/jwt/claims']['x-hasura-org-id'];
    }

    console.log("tenantid " +logintenantId);
    // Create an object with the form data
    const formData = {
      tenantid: logintenantId,
      organization_node_name: organization_node_name, 
      organization_shortname: organization_shortname, 
      organization_code: organization_code, 
      description: description, 
      organization_category_id: organization_category_id,  
      country_id: country_id, 
      state_id: state_id, 
      city_id: city_id, 
      parent_organization_id: parent_organization_id, 
      cost_centre_code: cost_centre_code,
      organization_node_leader: organization_node_leader,
      organization_type: organization_type, 
      created_by: logintenantId,
      is_active: "1",
    };
    console.log('========>',formData);
    axios
    .post(APIURL, {
        query: `
            mutation InsertOrganizationStructure($formData: tnt_organization_structure_insert_input!) {
                insert_tnt_organization_structure(objects: [$formData]) {
                affected_rows
                }
            }
        `,
        variables: {
          formData: formData,
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
        },
      })
      .then((response) => {
        console.log(response); // Handle the response data as needed
        if (response.data.data && response.data.data.insert_tnt_organization_structure.affected_rows > 0) {
            console.log("hello");
            props.onInsertSuccess();
            setTenantId("");
            setOrganizationNodeId("");
            setOrganizationNodeName("");
            setOrganizationShortName("");
            setOrganizationCode("");
            setDescription("");
            setOrganizationType("");
            setOrganizationCategoryId("");
            setCountryId("");
            setStateId("");
            setCityId("");
            setParentOrganizationId("");
            setCostCentreCode("");
            setOrganizationNodeLeader("");
            setCreatedBy("");
            setProgress(0);
            setActiveStep(1);
          toast.success('Data inserted successfully!');
        } else {
          toast.error('Failed to insert data.');
        }
      })
      .catch((error) => {
        console.error(error); // Handle the error
        toast.error('An error occurred while inserting data.');
      });
    };

  const renderStepContent = () => {
    switch (activeStep) {
        case 1:
          return (
            <>
              <Row>

                {/* <Col className="px-md-1" md="6">
                    <FormGroup>
                    <Label for="tenantId">Tenant ID</Label>
                    <Input
                        type="select"
                        id="tenantId"
                        value={tenantId}
                        onChange={(e) => setTenantId(e.target.value)}
                    >
                        <option value="">Select Tenant ID</option>
                        {tenants.map((tenant) => (
                        <option key={tenant.tenantid} value={tenant.tenantid}>
                            {tenant.tenant_name}
                        </option>
                        ))}
                    </Input>
                    </FormGroup>
                </Col> */}



              </Row>
              <Row>
                <Col className="px-md-1" md="6">
                    <FormGroup>
                    <Label for="organization_node_name">Organization Node Name</Label>
                    <Input
                        placeholder="Organization Node Name"
                        type="text"
                        value={organization_node_name}
                        onChange={(e) => setOrganizationNodeName(e.target.value)}


                    />
                    </FormGroup>
                </Col>
                <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="organization_shortname">Organization Short name</Label>
                  <Input
                    placeholder="Organization Short name"
                    type="text"
                    value={organization_shortname}
                    onChange={(e) => setOrganizationShortName(e.target.value)}
                  />
                </FormGroup>
              </Col>
              </Row>
              <Row>
                <Col className="px-md-1" md="6">
                   <FormGroup>
                   <Label for="orgtype">Country</Label>
                   <Input
                       type="select"
                       id="orgtype"
                       value={country_id}
                       onChange={(e) => setCountryId(e.target.value)}
                   >
                       <option value="">Select Country</option>
                       {countries.map((cnt) => (
                       <option key={cnt.code_id} value={cnt.code_id}>
                           {cnt.code}
                       </option>
                       ))}
                   </Input>
                   </FormGroup>
               </Col>
               <Col className="px-md-1" md="6">
                   <FormGroup>
                   <Label for="orgtype">State</Label>
                   <Input
                       type="select"
                       id="orgtype"
                       value={state_id}
                       onChange={(e) => setStateId(e.target.value)}
                   >
                       <option value="">Select State</option>
                       <option value="1">Maharashtra</option>
                       <option value="2">Odisa</option>
                       <option value="3">Bangal</option>
                       <option value="4">AP</option>
                       <option value="5">UP</option>


                   </Input>
                   </FormGroup>
               </Col>
             </Row>
              <Row>
               <Col className="px-md-1" md="6">
                   <FormGroup>
                   <Label for="orgtype">Organization Type</Label>
                   <Input
                       type="select"
                       id="orgtype"
                       value={organization_type}
                       onChange={(e) => setOrganizationType(e.target.value)}
                   >
                       <option value="">Select Organization Type</option>
                       {orgTypes.map((orgtype) => (
                       <option key={orgtype.code_id} value={orgtype.code_id}>
                           {orgtype.code}
                       </option>
                       ))}
                   </Input>
                   </FormGroup>
               </Col>

               <Col className="px-md-1" md="6">
                    <FormGroup>
                    <Label for="tenantId">Organization Category ID</Label>
                    <Input
                        type="select"
                        id="tenantId"
                        value={organization_category_id}
                        onChange={(e) => setOrganizationCategoryId(e.target.value)}
                    >
                        <option value="">Select Org Category ID</option>
                        {orgcats.map((orgcat) => (
                         <option key={orgcat.code_id} value={orgcat.code_id}>
                         {orgcat.code}
                     </option>
                        ))}
                    </Input>
                    </FormGroup>
                </Col>

             </Row>
             <Row>
              <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="organization_code">Organization Code</Label>
                  <Input
                    placeholder="Organization Code"
                    type="text"
                    value={organization_code}
                    onChange={(e) => setOrganizationCode(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="6">
                   <FormGroup>
                   <Label for="orgtype">Parent Organization</Label>
                   <Input
                       type="select"
                       id="orgtype"
                       value={parent_organization_id}
                       onChange={(e) => setParentOrganizationId(e.target.value)}
                   >
                       <option value="">Select Parent ID</option>
                       {orgnames.map((orgname) => (
                       <option key={orgname.organization_structure_id} value={orgname.organization_structure_id}>
                           {orgname.organization_node_name}
                       </option>
                       ))}
                   </Input>
                   </FormGroup>
               </Col>
            </Row>
            </>
          );
        case 2:
          return (
            <>


             <Row>
             <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="city_id">City</Label>
                  <Input
                    placeholder="City Name"
                    type="text"
                    value={city_id}
                    onChange={(e) => setCityId(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="6">
              <FormGroup>
                <Label for="description">Description</Label>
                <Input
                  placeholder="Description"
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormGroup>
            </Col>
             </Row>
             <Row>

               <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="cost_centre_code">Cost Centre Code</Label>
                  <Input
                    placeholder="Cost Centre Code"
                    type="text"
                    value={cost_centre_code}
                    onChange={(e) => setCostCentreCode(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="organization_node_leader">Organization Node Leader</Label>
                  <Input
                    placeholder="Organization Node Leader"
                    type="text"
                    value={organization_node_leader}
                    onChange={(e) => setOrganizationNodeLeader(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>

              {/* <Col className="px-md-1" md="6">
                  <FormGroup>
                    <label>Created By</label>
                    <Input
                      placeholder="Created By"
                      type="text"
                      value={createdBy}
                      onChange={(e) => setCreatedBy(e.target.value)}
                    />
                  </FormGroup>
                </Col> */}
            </Row>

            </>
          );

        default:
          return null;
      }
  };
  const calculateProgress = () => {
    return Math.round(((activeStep - 1) / 2) * 100); // Update the denominator to match the total number of steps minus one
  };


  const renderProgressBarDestinations = () => {
    const step1Class = activeStep >= 1 ? "progress-destination-active" : "";
    const step2Class = activeStep >= 2 ? "progress-destination-active" : "";
    const step3Class = activeStep >= 3 ? "progress-destination-active" : "";


    return (
      <div className="progress-destinations">
        <div className={`progress-destination ${step1Class}`}>
          <span>1</span>
        </div>
        <div className={`progress-destination ${step2Class}`}>
          <span>2</span>
        </div>
        <div className={`progress-destination ${step3Class}`}>
          <span>3</span>
        </div>
      </div>
    );
  };
  const renderStepButtons = () => {
    if (activeStep === 1) {
      return (
        <Button color="primary" onClick={handleNext}>
          Next
        </Button>
      );
    } else if (activeStep === 2) {
      return (
        <>
          <Button color="primary" onClick={handlePrevious}>
            Previous
          </Button>
          <Button color="success" onClick={handleFormSubmit}>
            Submit
          </Button>
        </>
      );
    }
  };


  return (
    <div className="content">
      <Row>
        <Col md="8">
          <div className="progress-container">
            {renderProgressBarDestinations()}
            <Progress
              max="100"
              value={calculateProgress()}
              className="progress-bar-sm progress-bar-striped"
            />
          </div>
        </Col>
      </Row>
      <Row className="progress-row">
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Organization Structure</h5>
            </CardHeader>
            <CardBody>
              <Form>{renderStepContent()}</Form>
            </CardBody>
            <CardFooter>
              <div className="text-right">
                {renderStepButtons()}
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );

}

export default JobrolemasterAdd;



/////////////////////////////////////////////////////////////



// import React, { useState, useEffect } from "react";
// import {
//   Button,
//   Card,
//   CardHeader,
//   CardBody,
//   CardFooter,
//   FormGroup,
//   Form,
//   Input,
//   Row,
//   Col,
//   Label,
//   Progress,
// } from "reactstrap";
// import axios from "axios";
// import { APIURL } from './Apiurl.js';
// import { toast } from 'react-toastify';
// import { useForm } from 'react-hook-form'
// import * as yup from "yup";
// import { yupResolver } from "@hookform/resolvers/yup";

// const JobrolemasterAdd = (props) => {
//   const [activeStep, setActiveStep] = useState(1);
//   const [progress, setProgress] = useState(0);
//   const [orgnodes, setOrgNodes] = useState([]);
//   const [orgTypes, setOrgTyes] = useState([]);
//   const [tenants, setTenants] = useState([]);
//   const [orgcats, setOrgCats] = useState([]);
//   const [orgnames, setOrgnames] = useState([]);
//   const [isLastStep, setIsLastStep] = useState(false);
//   const [countries, setcountries] = useState([]);
//   const [firstForm, setFirstForm] = useState();
//   useEffect(() => {
//     fetchTenants();
//     fetchOrgNode();
//     fetchTypes();
//     fetchCats();
//     fetchOrgnames();
//     fetchcountry();
//   }, []);
//   const mainSchema = yup.object({
//     organization_node_name: yup.string().required("Organization Node Name is required"),
//     organization_shortname: yup.string().required("Organization Short name is required"),
//     country_id: yup.string().required("Country is required"),
//     state_id: yup.string().required("State is required"),
//     organization_type: yup.string().required("Organization Type is required"),
//     organization_category_id: yup.string().required("Organization Category ID is required"),
//     organization_code: yup.string().required("Organization Code is required"),
//     parent_organization_id: yup.string().required("Parent Organization is required"),
//   });
  
//   const additionalSchema = yup.object({
//     city_id: yup.string().required("City ID is required"),
//     description: yup.string().required("Description is required"),
//     cost_centre_code: yup.string().required("Cost Centre Code is required"),
//     organization_node_leader: yup.string().required("Organization Node Leader is required"),
//   });
  
//   const combinedSchema = yup.object().shape({
//     ...mainSchema.fields,
//     ...additionalSchema.fields,
//   });
  
//   const mainForm = useForm({
//     defaultValues: {
//       organization_node_name: "",
//       organization_shortname: "",
//       country_id: "",
//       state_id: "",
//       organization_type: "",
//       organization_category_id: "",
//       organization_code: "",
//       parent_organization_id: "",
//     },
//     resolver: yupResolver(mainSchema),
//   });
  
//   const additionalForm = useForm({
//     defaultValues: {
//       city_id: "",
//       description: "",
//       cost_centre_code: "",
//       organization_node_leader: "",
//     },
//     resolver: yupResolver(additionalSchema),
//   });
  
//   const { register: mainRegister, control: mainControl, handleSubmit: mainHandleSubmit, formState: mainFormState } = mainForm;
//   const { errors: mainErrors, isSubmitSuccessful: mainIsSubmitSuccessful } = mainFormState;
  
//   const { register: additionalRegister, control: additionalControl, handleSubmit: additionalHandleSubmit, formState: additionalFormState } = additionalForm;
//   const { errors: additionalErrors, isSubmitSuccessful: additionalIsSubmitSuccessful } = additionalFormState;
  



//   const fetchOrgnames = async () => {
//     try {
//       const token = sessionStorage.getItem('token');
//       const decodedToken = JSON.parse(atob(token.split('.')[1]));
//       const roleId = decodedToken['sub'];
//       const tenantId = decodedToken['https://hasura.io/jwt/claims']['x-hasura-org-id'];
//       const storedTenantId = sessionStorage.getItem('selectedTenantId');
//       let query = '';
//       let variables = {}; // You can define variables if needed

//       if (roleId === 1) {
//         if (storedTenantId !== null) {
//           query = `
//           query ($tenantId: Int!) {
//             tnt_organization_structure (
//               where: { tenantid: { _eq: $tenantId } }
//             ) {
//               organization_structure_id
//               organization_shortname
//               organization_node_name
//             }
//           }
//         `;
//           variables = { tenantId: storedTenantId };
//         }
//         else {
//           console.log("sdfasnisarg");
//           query = `
//             query {
//               tnt_organization_structure {
//                 organization_structure_id
//                 organization_shortname
//                 organization_node_name
//               }
//             }
//           `;
//         }

//       } else {
//         query = `
//           query ($tenantId: Int!) {
//             tnt_organization_structure (
//               where: { tenantid: { _eq: $tenantId } }
//             ) {
//               organization_structure_id
//               organization_shortname
//               organization_node_name
//             }
//           }
//         `;
//         variables = { tenantId: tenantId };
//       }

//       const response = await axios.post(APIURL, {
//         query,
//         variables,
//       },
//         {
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
//           },
//         });

//       setOrgnames(response.data.data.tnt_organization_structure);
//       console.log(response.data.data.tnt_organization_structure);
//     } catch (error) {
//       console.error(error);
//     }
//   };


//   const fetchcountry = async () => {
//     try {
//       const token = sessionStorage.getItem('token');
//       const response = await axios.post(APIURL, {
//         query: `
//         query MyQuery {
//           config_code_master(where: {code_type: {_eq: "Country"}}) {
//             code_id
//             code_type
//             code
//           }
//         }
//         `,
//       },
//         {
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
//           },
//         });

//       setcountries(response.data.data.config_code_master);
//       console.log(response.data.data.config_code_master);
//     } catch (error) {
//       console.error(error);
//     }

//   };
//   const fetchCats = async () => {
//     try {
//       const token = sessionStorage.getItem('token');
//       const response = await axios.post(APIURL, {
//         query: `
//         query MyQuery {
//           config_code_master(where: {code_type: {_eq: "Organization category"}}) {
//             code_id
//             code_type
//             code
//           }
//         }
//         `,
//       },
//         {
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
//           },
//         });

//       setOrgCats(response.data.data.config_code_master); // Make sure 'organization_category' is an array
//     } catch (error) {
//       console.error(error);
//     }
//   };
//   const fetchOrgNode = async () => {
//     try {
//       const token = sessionStorage.getItem('token');
//       const response = await axios.post(APIURL, {
//         query: `
//           query {
//             organization_node {
//                 organization_node_id
//                 organization_node_name
//               }
//           }
//         `,
//       },
//         {
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
//           },
//         });

//       setOrgNodes(response.data.data.organization_node);
//       console.log(response.data.data.organization_node);
//     } catch (error) {
//       console.error(error);
//     }
//   };
//   const fetchTenants = async () => {
//     try {
//       const token = sessionStorage.getItem('token');
//       const response = await axios.post(APIURL, {
//         query: `
//           query {
//             tenants {
//               tenantid
//               tenant_name
//             }
//           }
//         `,
//       },
//         {
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
//           },
//         });
//       setTenants(response.data.data.tenants);
//       console.log(response.data.data.tenants);
//     } catch (error) {
//       console.error(error);
//     }
//   };
//   const fetchTypes = async () => {
//     try {
//       const token = sessionStorage.getItem('token');
//       const response = await axios.post(APIURL, {
//         query: `
//         query MyQuery {
//           config_code_master(where: {code_type: {_eq: "OrgType"}}) {
//             code_id
//             code_type
//             code
//           }
//         }
//         `,
//       },
//         {
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
//           },
//         });

//       setOrgTyes(response.data.data.config_code_master);
//     } catch (error) {
//       console.error(error);
//     }
//   };
//   const handleOrgNodeChange = (e) => {
//     const selectedNodeName = e.target.options[e.target.selectedIndex].text;
//   };

//   const handleNext = (data) => {
//     if (activeStep === 1) {
//       setProgress(100);
//       setFirstForm(data)
//       console.log('======first=====>', data)

//     }
//     setActiveStep(activeStep + 1);
//     if (activeStep + 1 === 3) {
//       setIsLastStep(true);
//     }
//   };

//   const handlePrevious = () => {
//     setActiveStep(activeStep - 1);
//     setIsLastStep(false);
//   };
//   const handleFormSubmit = (data) => {
//     // event.preventDefault();
//     console.log('======second=====>', data)
//     const token = sessionStorage.getItem('token');
//     const decodedToken = JSON.parse(atob(token.split('.')[1]));
//     const roleId = decodedToken['sub'];
//     console.log(roleId);
//     let logintenantId = null;
//     if (roleId === 1) {
//       logintenantId = sessionStorage.getItem('selectedTenantId');
//     } else {
//       logintenantId = decodedToken['https://hasura.io/jwt/claims']['x-hasura-org-id'];
//     }

//     console.log("tenantid " + logintenantId);
//     // Create an object with the form data
//     const formData = {...firstForm,...data,tenantid: logintenantId,created_by: logintenantId,is_active: "1"};
//     axios
//       .post(APIURL, {
//         query: `
//             mutation InsertOrganizationStructure($formData: tnt_organization_structure_insert_input!) {
//                 insert_tnt_organization_structure(objects: [$formData]) {
//                 affected_rows
//                 }
//             }
//         `,
//         variables: {
//           formData: formData,
//         },
//       },
//         {
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
//           },
//         })
//       .then((response) => {
//         console.log(response); // Handle the response data as needed
//         if (response.data.data && response.data.data.insert_tnt_organization_structure.affected_rows > 0) {
//           console.log("hello");
//           props.onInsertSuccess();
//           setProgress(0);
//           setActiveStep(1);
//           toast.success('Data inserted successfully!');
//         } else {
//           toast.error('Failed to insert data.');
//         }
//       })
//       .catch((error) => {
//         console.error(error); // Handle the error
//         toast.error('An error occurred while inserting data.');
//       });
//   };

//   const renderStepContent = () => {
//     switch (activeStep) {
//       case 1:
//         return (
//           <form onSubmit={mainHandleSubmit(handleNext)} noValidate >
//             <Row>
//             </Row>
//             <Row>
//               <Col className="px-md-1" md="6">
//                 <FormGroup>
//                   <Label for="organization_node_name">Organization Node Name</Label>
//                   <input
//                     class="form-control"
//                     placeholder="Organization Node Name"
//                     type="text"
//                     id='organization_node_name'
//                     {...mainRegister("organization_node_name")}
//                   />
//                   <div>{mainErrors.organization_node_name?.message}</div>
//                 </FormGroup>
//               </Col>
//               <Col className="px-md-1" md="6">
//                 <FormGroup>
//                   <Label for="organization_shortname">Organization Short name</Label>
//                   <input
//                     class="form-control"
//                     placeholder="Organization Short name"
//                     type="text"
//                     id='organization_shortname'
//                     {...mainRegister("organization_shortname")}
//                   />
//                   <div>{mainErrors.organization_shortname?.message}</div>
//                 </FormGroup>
//               </Col>
//             </Row>
//             <Row>
//               <Col className="px-md-1" md="6">
//                 <FormGroup>
//                   <Label for="orgtype">Country</Label>
//                   <select
//                     class="form-control"
//                     type="select"
//                     id='country_id'
//                     {...mainRegister("country_id")}
//                   >
//                     <option selected>Select Country</option>
//                     {countries.map((cnt) => (
//                       <option key={cnt.code_id} value={cnt.code_id}>
//                         {cnt.code}
//                       </option>
//                     ))}
//                   </select>
//                   <div>{mainErrors.country_id?.message}</div>
//                 </FormGroup>
//               </Col>
//               <Col className="px-md-1" md="6">
//                 <FormGroup>
//                   <Label for="orgtype">State</Label>
//                   <select
//                     class="form-control"
//                     type="select"
//                     id='state_id'
//                     {...mainRegister("state_id")}
//                   >
//                     <option selected>Select State</option>
//                     <option value="1">Maharashtra</option>
//                     <option value="2">Odisa</option>
//                     <option value="3">Bangal</option>
//                     <option value="4">AP</option>
//                     <option value="5">UP</option>
//                   </select>
//                   <div>{mainErrors.state_id?.message}</div>
//                 </FormGroup>
//               </Col>
//             </Row>
//             <Row>
//               <Col className="px-md-1" md="6">
//                 <FormGroup>
//                   <Label for="orgtype">Organization Type</Label>
//                   <select
//                     class="form-control"
//                     type="select"
//                     id='organization_type'
//                     {...mainRegister("organization_type")}
//                   >
//                     <option selected>Select Organization Type</option>
//                     {orgTypes.map((orgtype) => (
//                       <option key={orgtype.code_id} value={orgtype.code_id}>
//                         {orgtype.code}
//                       </option>
//                     ))}
//                   </select>
//                   <div>{mainErrors.organization_type?.message}</div>
//                 </FormGroup>
//               </Col>

//               <Col className="px-md-1" md="6">
//                 <FormGroup>
//                   <Label for="tenantId">Organization Category ID</Label>
//                   <select
//                     class="form-control"
//                     type="select"
//                     id='organization_category_id'
//                     {...mainRegister("organization_category_id")}
//                   >
//                     <option selected>Select Org Category ID</option>
//                     {orgcats.map((orgcat) => (
//                       <option key={orgcat.code_id} value={orgcat.code_id}>
//                         {orgcat.code}
//                       </option>
//                     ))}
//                   </select>
//                   <div>{mainErrors.organization_category_id?.message}</div>
//                 </FormGroup>
//               </Col>

//             </Row>
//             <Row>
//               <Col className="px-md-1" md="6">
//                 <FormGroup>
//                   <Label for="organization_code">Organization Code</Label>
//                   <input
//                     class="form-control"
//                     placeholder="Organization Code"
//                     type="text"
//                     id='organization_code'
//                     {...mainRegister("organization_code")}
//                   />
//                   <div>{mainErrors.organization_code?.message}</div>
//                 </FormGroup>
//               </Col>
//               <Col className="px-md-1" md="6">
//                 <FormGroup>
//                   <Label for="orgtype">Parent Organization</Label>
//                   <select
//                     class="form-control"
//                     type="select"
//                     id='parent_organization_id'
//                     {...mainRegister("parent_organization_id")}
//                   >
//                     <option selected>Select Parent ID</option>
//                     {orgnames.map((orgname) => (
//                       <option key={orgname.organization_structure_id} value={orgname.organization_structure_id}>
//                         {orgname.organization_node_name}
//                       </option>
//                     ))}
//                   </select>
//                   <div>{mainErrors.parent_organization_id?.message}</div>
//                 </FormGroup>
//               </Col>
//             </Row>
//             <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
//               <Button type="submit" color="primary">
//                 Next
//               </Button>
//             </div>
//           </form>
//         );
//       case 2:
//         return (
//           <form onSubmit={additionalHandleSubmit(handleFormSubmit)} noValidate >
//             <Row>
//               <Col className="px-md-1" md="6">
//                 <FormGroup>
//                   <Label for="city_id">City</Label>
//                   <input
//                     class="form-control"
//                     placeholder="City Name"
//                     type="text"
//                     id='city_id'
//                     {...additionalRegister("city_id")}
//                   />
//                   <div>{additionalErrors.city_id?.message}</div>
//                 </FormGroup>
//               </Col>
//               <Col className="px-md-1" md="6">
//                 <FormGroup>
//                   <Label for="description">Description</Label>
//                   <input
//                     class="form-control"
//                     placeholder="Description"
//                     type="text"
//                     id='description'
//                     {...additionalRegister("description")}
//                   />
//                   <div>{additionalErrors.description?.message}</div>
//                 </FormGroup>
//               </Col>
//             </Row>
//             <Row>
//               <Col className="px-md-1" md="6">
//                 <FormGroup>
//                   <Label for="cost_centre_code">Cost Centre Code</Label>
//                   <input
//                     class="form-control"
//                     placeholder="Cost Centre Code"
//                     type="text"
//                     id='cost_centre_code'
//                     {...additionalRegister("cost_centre_code")}
//                   />
//                   <div>{additionalErrors.cost_centre_code?.message}</div>
//                 </FormGroup>
//               </Col>
//               <Col className="px-md-1" md="6">
//                 <FormGroup>
//                   <Label for="organization_node_leader">Organization Node Leader</Label>
//                   <input
//                     class="form-control"
//                     placeholder="Organization Node Leader"
//                     type="text"
//                     id='organization_node_leader'
//                     {...additionalRegister("organization_node_leader")}
//                   />
//                   <div>{additionalErrors.organization_node_leader?.message}</div>
//                 </FormGroup>
//               </Col>
//             </Row>
//             <Row>
//             </Row>
//             <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
//               <Button color="primary" onClick={handlePrevious}>
//                 Previous
//               </Button>
//               <Button type="submit" color="success" >
//                 Submit
//               </Button>
//             </div>
//           </form>
//         );
//       default:
//         return null;
//     }
//   };
//   const calculateProgress = () => {
//     return Math.round(((activeStep - 1) / 2) * 100); // Update the denominator to match the total number of steps minus one
//   };


//   const renderProgressBarDestinations = () => {
//     const step1Class = activeStep >= 1 ? "progress-destination-active" : "";
//     const step2Class = activeStep >= 2 ? "progress-destination-active" : "";
//     const step3Class = activeStep >= 3 ? "progress-destination-active" : "";


//     return (
//       <div className="progress-destinations">
//         <div className={`progress-destination ${step1Class}`}>
//           <span>1</span>
//         </div>
//         <div className={`progress-destination ${step2Class}`}>
//           <span>2</span>
//         </div>
//         <div className={`progress-destination ${step3Class}`}>
//           <span>3</span>
//         </div>
//       </div>
//     );
//   };
//   const renderStepButtons = () => {
//     if (activeStep === 1) {
//       return (
//         <Button color="primary" onClick={handleNext}>
//           Next
//         </Button>
//       );
//     } else if (activeStep === 2) {
//       return (
//         <>
//           <Button color="primary" onClick={handlePrevious}>
//             Previous
//           </Button>
//           <Button color="success" onClick={handleFormSubmit}>
//             Submit
//           </Button>
//         </>
//       );
//     }
//   };


//   return (
//     <div className="content">
//       <Row>
//         <Col md="8">
//           <div className="progress-container">
//             {renderProgressBarDestinations()}
//             <Progress
//               max="100"
//               value={calculateProgress()}
//               className="progress-bar-sm progress-bar-striped"
//             />
//           </div>
//         </Col>
//       </Row>
//       <Row className="progress-row">
//         <Col md="8">
//           <Card>
//             <CardHeader>
//               <h5 className="title">Organization Structure</h5>
//             </CardHeader>
//             <CardBody>
//               <div>{renderStepContent()}</div>
//             </CardBody>
//             {/* <CardFooter>
//               <div className="text-right">
//                 {renderStepButtons()}
//               </div>
//             </CardFooter> */}
//           </Card>
//         </Col>
//       </Row>
//     </div>
//   );

// }

// export default JobrolemasterAdd;
