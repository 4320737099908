import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Progress,
} from "reactstrap";
import axios from "axios";
import  { APIURL } from './Apiurl.js';
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

function TenantMachinesMaster() {
  const [activeStep, setActiveStep] = useState(1);
  const [checked, setChecked] = useState(false);
  const [progress, setProgress] = useState(0);

  
  const [udpedit, setudpedit] = useState({
    machineID: "",
    organizationID: "",
    plantSBU: "",
    department: "",
    productionLine: "",
    machineCode: "",
    machineName: "",
    machineType: "",
    machineSubtype: "",
    machineCategory: "",
    geoLocation: "",
    description: "",
    baselineAttributes: "",
    startDate: "",
    isActive: "",
    isDeactive: "",
    createdOn: "",
    createdBy: "",
    modifiedOn: "",
    modifiedBy: "",
    modificationReason: "",

  });

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${APIURL}/api/tmm/${id}`)
      .then((response) => {
        setudpedit((prevState) => ({
          ...prevState,
            machineID: response.data.machine_id,
            organizationID: response.data.org_id,
            plantSBU: response.data.plant,
            department: response.data.department,
            productionLine: response.data.production_line,
            machineCode: response.data.machine_code,
            machineName: response.data.machine_name,
            machineType: response.data.machine_type,
            machineSubtype: response.data.machine_subtype,
            machineCategory: response.data.machine_category,
            geoLocation: response.data.geolocation,
            description: response.data.description,
            baselineAttributes: response.data.baseline_attributes,
            startDate: response.data.start_date,
            createdBy: response.data.created_by,
            modifiedBy: response.data.modified_by,
            modificationReason: response.data.modification_reason,
        }));
       
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if the machine name is provided
   
    axios
      .post(`${APIURL}/api/tmm/${id}`, udpedit)
      .then((response) => {
        console.log(response);
        navigate(`/tmm/${id}`);
        if (response.status === 201) {
          window.location.href = "/admin/tenant-machines-master/all";
        } else {
          // Reset the form or show a success message
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setudpedit((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleNext = () => {
    if (activeStep === 1 && progress === 0) {
      setProgress(50);
    } else if (activeStep === 2 && progress === 50) {
      setProgress(100);
    }
    setActiveStep(activeStep + 1);
  };

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };

 

  const renderStepContent = () => {
    switch (activeStep) {
      case 1:
        return (
          <>
             <Row>
              <Col md="6">
                <FormGroup>
                  <label>Machine ID</label>
                  <Input
                    type="text"
                    name="machineID"
                    placeholder="Enter Machine ID"
                    value={udpedit.machineID}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label>Organization ID</label>
                  <Input
                    type="text"
                    name="organizationID"
                    placeholder="Enter Organization ID"
                    value={udpedit.organizationID}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label>Plant SBU</label>
                  <Input
                    type="text"
                    name="plantSBU"
                    placeholder="Enter Plant SBU"
                    value={udpedit.plantSBU}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label>Department</label>
                  <Input
                    type="text"
                    name="department"
                    placeholder="Enter Department"
                    value={udpedit.department}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label>Production Line</label>
                  <Input
                    type="text"
                    name="productionLine"
                    placeholder="Enter Production Line"
                    value={udpedit.productionLine}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label>Machine Code</label>
                  <Input
                    type="text"
                    name="machineCode"
                    placeholder="Enter Machine Code"
                    value={udpedit.machineCode}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label>Machine Name</label>
                  <Input
                    type="text"
                    name="machineName"
                    placeholder="Enter Machine Name"
                    value={udpedit.machineName}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label>Machine Type</label>
                  <Input
                    type="text"
                    name="machineType"
                    placeholder="Enter Machine Type"
                    value={udpedit.machineType}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        );
      case 2:
        return (
          <>
             <Row>
              <Col md="6">
                <FormGroup>
                  <label>Machine Subtype</label>
                  <Input
                    type="text"
                    name="machineSubtype"
                    placeholder="Enter Machine Subtype"
                    value={udpedit.machineSubtype}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label>Machine Category</label>
                  <Input
                    type="text"
                    name="machineCategory"
                    placeholder="Enter Machine Category"
                    value={udpedit.machineCategory}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label>Geo Location</label>
                  <Input
                    type="text"
                    name="geoLocation"
                    placeholder="Enter Geo Location"
                    value={udpedit.geoLocation}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label>Description</label>
                  <Input
                    type="text"
                    name="description"
                    placeholder="Enter Description"
                    value={udpedit.description}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        );
      case 3:
        return (
          <>
          <Row>
              <Col md="6">
                <FormGroup>
                  <label>Baseline Attributes</label>
                  <Input
                    type="text"
                    name="baselineAttributes"
                    placeholder="Enter Baseline Attributes"
                    value={udpedit.baselineAttributes}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label>Start Date</label>
                  <Input
                    type="date"
                    name="startDate"
                    placeholder="Enter Start Date"
                    value={udpedit.startDate}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          
            <Row>
              <Col md="6">
                <FormGroup>
                  <label>Created By</label>
                  <Input
                    type="text"
                    name="createdBy"
                    placeholder="Enter Created By"
                    value={udpedit.createdBy}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            
              <Col md="6">
                <FormGroup>
                  <label>Modified By</label>
                  <Input
                    type="text"
                    name="modifiedBy"
                    placeholder="Enter Modified By"
                    value={udpedit.modifiedBy}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label>Modification Reason</label>
                  <Input
                    type="text"
                    name="modificationReason"
                    placeholder="Enter Modification Reason"
                    value={udpedit.modificationReason}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        );
      default:
        return null;
    }
  };

  const calculateProgress = () => {
    return Math.round(((activeStep - 1) / 2) * 100); // Update the denominator to match the total number of steps minus one
  };
  

  const renderProgressBarDestinations = () => {
    const step1Class = activeStep >= 1 ? "progress-destination-active" : "";
    const step2Class = activeStep >= 2 ? "progress-destination-active" : "";
    const step3Class = activeStep >= 3 ? "progress-destination-active" : "";
    const step4Class = activeStep >= 4 ? "progress-destination-active" : "";
  
    return (
      <div className="progress-destinations">
        <div className={`progress-destination ${step1Class}`}>
          <span>1</span>
        </div>
        <div className={`progress-destination ${step2Class}`}>
          <span>2</span>
        </div>
        <div className={`progress-destination ${step3Class}`}>
          <span>3</span>
        </div>
        <div className={`progress-destination ${step4Class}`}>
          <span>4</span>
        </div>
      </div>
    );
  };
  
  
  
  
  
  
  
  

  return (
    <div className="content">
      <Row>
        <Col md="8">
          <div className="progress-container">
            {renderProgressBarDestinations()}
            <Progress
              max="100"
              value={calculateProgress()}
              className="progress-bar-sm progress-bar-striped"
            />
          </div>
        </Col>
      </Row>
      <Row className="progress-row">
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Add New Machine</h5>
            </CardHeader>
            <CardBody>
              <Form>{renderStepContent()}</Form>
            </CardBody>
            <CardFooter>
              <div className="text-right">
                {activeStep > 1 && (
                  <Button color="primary" onClick={handlePrevious}>
                    Previous
                  </Button>
                )}
                {activeStep < 3 && (
                  <Button color="primary" onClick={handleNext}>
                    Next
                  </Button>
                )}
                {activeStep === 3 && (
                  <Button color="success" onClick={handleSubmit}>
                    Submit
                  </Button>
                )}
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default TenantMachinesMaster;
