import React from "react";
import './style.css';
import './progress.css';
import './gauge.css'
import {
  Card,
  CardHeader,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import 'react-circular-progressbar/dist/styles.css';
import "bootstrap/dist/css/bootstrap.min.css";

const Popup = ({ nodeData,onClose }) => {
  return (
      <div className="popup">
        <Row>
        <Col lg="10" className="text-center">
        <Card className="card-chart" style={{width: '580px', height:'200px'}}>
                <CardHeader>
                   <div>
                   <p>{nodeData.name}</p>
                    <h4>Trends</h4>
                    </div>
                    <div class="input-container">
                      <div class="input-number">1</div>
                      <input type="text" placeholder="Trends-1" class="input-style" /><br />
                      <button className="button">...</button>
                    </div>
                    <div class="input-container">
                      <div class="input-number">2</div>
                      <input type="text" placeholder="Trends-2" class="input-style" /><br />
                      <button className="button">...</button>
                    </div>
                    <div class="input-container">
                      <div class="input-number">3</div>
                      <input type="text" placeholder="Trends-3" class="input-style" /><br />
                      <button className="button">...</button>
                    </div>
                    <div class="input-container">
                      <div class="input-number">4</div>
                      <input type="text" placeholder="Trends-4" class="input-style" /><br />
                      <button className="button">...</button>
                    </div>
                </CardHeader>
                <CardTitle tag="h4" className="mt-2">
                </CardTitle>
              </Card>
        </Col>
        </Row>
        <Row>
        <Col lg="10" className="text-center">
        <Card className="card-chart" style={{width: '580px',height:'200px'}}>
                <CardHeader>
                    <div>
                        <h4>Key-Insights</h4>
                    </div>
                    <div class="input-container">
                      <div class="input-number">1</div>
                      <input type="text" placeholder="Key-Insights-1" class="input-style" /><br />
                      <button className="button">...</button>
                    </div>
                    <div class="input-container">
                      <div class="input-number">2</div>
                      <input type="text" placeholder="Key-Insights-2" class="input-style" /><br />
                      <button className="button">...</button>
                    </div>
                    <div class="input-container">
                      <div class="input-number">3</div>
                      <input type="text" placeholder="Key-Insights-3" class="input-style" /><br />
                      <button className="button">...</button>
                    </div>
                    <div class="input-container">
                      <div class="input-number">4</div>
                      <input type="text" placeholder="Key-Insights-4" class="input-style" /><br />
                      <button className="button">...</button>
                    </div>
                </CardHeader>
              </Card>
        </Col>
        </Row>
        <Row>
        <Col lg="10" className="text-center">
        <Card className="card-chart" style={{width: '580px',height:'200px'}}>
                <CardHeader>
                    <div>
                        <h4>Actiions</h4>
                    </div>
                    <div class="input-container">
                      <div class="input-number">1</div>
                      <input type="text" placeholder="Action-1" class="input-style" /><br />
                      <button className="button">...</button>
                    </div>
                    <div class="input-container">
                      <div class="input-number">2</div>
                      <input type="text" placeholder="Action-2" class="input-style" /><br />
                      <button className="button">...</button>
                    </div>
                    <div class="input-container">
                      <div class="input-number">3</div>
                      <input type="text" placeholder="Action-3" class="input-style" /><br />
                      <button className="button">...</button>
                    </div>
                    <div class="input-container">
                      <div class="input-number">4</div>
                      <input type="text" placeholder="Action-4" class="input-style" /><br />
                      <button className="button">...</button>
                    </div>
                </CardHeader>
                <CardTitle tag="h4" className="mt-2">
                  
                </CardTitle>
              </Card>
        </Col>
        </Row>

        <button onClick={onClose}>Close</button>
      </div>
    
  );
};

export default Popup;