import React, { useState, useEffect } from "react";
import { Card, Row, Col, CardHeader, CardBody } from "reactstrap";
import redirectToLoginIfTokenMissing from "views/TokenUtility.js";
import OleScoreMachineWise from "./OleMachineWise";
import OleOperatorWise from "./OleOperatorWise";
import OlePartWise from "./OlePartWise";

function OleScoreCard(props) {
  const [activeTab, setActiveTab] = useState("1");
  const storedTenantId = sessionStorage.getItem("selectedTenantId");
  useEffect(() => {
    redirectToLoginIfTokenMissing();
  }, []);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const [bigChartData, setbigChartData] = React.useState("data1");
  const setBgChartData = (name) => {
    setbigChartData(name);
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="8">
            <Card className="card-workforce">
              <CardHeader>
                <h5 className="card-category">Machine Wise OLE Dashboard</h5>
              </CardHeader>
            </Card>
            <Card className="card-chart">
              <CardBody>
                <div className="workforce-by-machine">
                  <OleScoreMachineWise />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card className="card-workforce">
              <CardHeader>
                <h5 className="card-category">Key Trends</h5>
              </CardHeader>
            </Card>
            <Card className="card-chart">
              <CardBody>
                <div className="workforce-by-machine">
                  <input
                    type="text"
                    style={{
                      backgroundColor: "blue",
                      width: "100%",
                      height: "100%",
                      color: "white", // You can set the text color as well
                      padding: "10px", // Adjust padding as needed
                      border: "none", // Optionally remove border
                    }}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="8">
            <Card className="card-workforce">
              <CardHeader>
                <h5 className="card-category">Operator Wise OLE Dashboard</h5>
              </CardHeader>
            </Card>
            <Card className="card-chart">
              <CardBody>
                <div className="workforce-by-machine">
                  <OleOperatorWise />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card className="card-workforce">
              <CardHeader>
                <h5 className="card-category">Key Trends</h5>
              </CardHeader>
            </Card>
            <Card className="card-chart">
              <CardBody>
                <div className="workforce-by-machine">
                  <input
                    type="text"
                    style={{
                      backgroundColor: "blue",
                      width: "100%",
                      height: "100%",
                      color: "white", // You can set the text color as well
                      padding: "10px", // Adjust padding as needed
                      border: "none", // Optionally remove border
                    }}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="8">
            <Card className="card-workforce">
              <CardHeader>
                <h5 className="card-category">Part Wise OLE Dashboard</h5>
              </CardHeader>
            </Card>
            <Card className="card-chart">
              <CardBody>
                <div className="workforce-by-machine">
                  <OlePartWise />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card className="card-workforce">
              <CardHeader>
                <h5 className="card-category">Key Trends</h5>
              </CardHeader>
            </Card>
            <Card className="card-chart">
              <CardBody>
                <div className="workforce-by-machine">
                  <input
                    type="text"
                    style={{
                      backgroundColor: "blue",
                      width: "100%",
                      height: "100%",
                      color: "white", // You can set the text color as well
                      padding: "10px", // Adjust padding as needed
                      border: "none", // Optionally remove border
                    }}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default OleScoreCard;
