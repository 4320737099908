import React, { useState, useEffect, useImperativeHandle } from "react";
import axios from "axios";
import { APIURL } from "views/Apiurl.js";
import { toast } from "react-toastify";
import {
  TextField,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import "./Dynamictable.css";
import { Container } from "reactstrap";

function DynamicTableNew({ setFormSubmitData, selectedRowData ,fetchData}, ref) {
  const selectedPlanId = selectedRowData?.planid;
  const [numRows, setNumRows] = useState(0);
  const [rowData, setRowData] = useState([
    {
      id: 1,
      data: "",
      operator: "",
      code_description: "",
    },
  ]);
  const [production, setProductionPlan] = useState([
    { planid: "", partno: "" },
  ]);
  const [oprPartno, setOprPartNo] = useState("");
  const [oprdate, setDate] = useState("");
  const [machinename, setMachineName] = useState("");
  const [planid, setPlan] = useState("");
  const [emp_operator, setEmpOperator] = useState([]); //employee
  const [code_description, setCodeDescription] = useState([]);
  const [time, setTime] = useState("");

  const [production_date, setProductionDate] = useState("");
  const [part_no, setPartNo] = useState("");
  const [operator, setOperator] = useState("");
  const [skill, setSkill] = useState("");

  const token = sessionStorage.getItem("token");
  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const tenantId =
    decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"]; //18

  useEffect(() => {
    fetchproductionplan();
    fetchemployee();
  }, []);

  const fetchproductionplan = async (logintenantId) => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
          query MyQuery($logintenantId: Int!, $selectedPlanId: Int!) {
            tnt_production_plan_master(where: {tenantid: {_eq: $logintenantId}, planid: {_eq: $selectedPlanId}}) {
              planid
              partno
              date
              actual_time_taken
              machine_name
              required_time_ideal
            }
          }
        
        `,
          variables: {
            logintenantId: parseInt(logintenantId),
            selectedPlanId: selectedPlanId,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            // "x-hasura-admin-secret": process.env.REACT_APP_X_API_SECRET_KEY, // Replace with your Hasura admin secret
          },
        }
      );

      setProductionPlan(response.data.data.tnt_production_plan_master);
    } catch (error) {}
  };

  const fetchemployee = async (logintenantId) => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
          query MyQuery($logintenantId: Int!) {
            tenant_employees(where: {tenantid: {_eq: $logintenantId}}) {
              first_name
              last_name
              employee_type
              employee_id
              employee_code
              emptype {
                code_id
                code_type
                code_description
              }
            }
          }
        `,
          variables: {
            logintenantId: parseInt(logintenantId),
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            // "x-hasura-admin-secret": process.env.REACT_APP_X_API_SECRET_KEY, // Replace with your Hasura admin secret
          },
        }
      );

      setEmpOperator(response.data.data.tenant_employees || []);
    } catch (error) {}
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["sub"];
    let logintenantId = null;

    if (roleId == 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
    }

    fetchproductionplan(logintenantId);
    fetchemployee(logintenantId);
  }, []);

  let date = production[0].date;
  const getdate = production[0].date;
  if (getdate !== date) {
    setDate(getdate);
  }
  let mcname = production[0].machine_name;
  const getmachinename = production[0].machine_name;
  if (getmachinename !== mcname) {
    setMachineName(getmachinename);
  }
  let requiredTime = production[0].required_time_ideal;
  const getrequiredtime = production[0].required_time_ideal;
  if (getrequiredtime !== requiredTime) {
    setTime(getrequiredtime);
  }
  const handleOperatorChange = (event, id) => {
    const selectedEmp = emp_operator.find(
      (emp) => emp.first_name + " " + emp.last_name === event.target.value
    );

    const {
      emptype: { code_description },
    } = selectedEmp;

    if (selectedEmp) {
      const { first_name, last_name, employee_id } = selectedEmp;
      setRowData((prevRowData) =>
        prevRowData.map((row) =>
          row.id === id
            ? {
                ...row,
                operator: first_name + " " + last_name,
                code_description: code_description, // Use code_description
                skill: code_description, // Autofill skill with code_description
                employee_id: employee_id, // Store employee_id (operator_id)
              }
            : row
        )
      );
    }
  };

  const handleNumRowsChange = (e) => {
    const newNumRows = parseInt(e.target.value, 10);
    setNumRows(newNumRows);
    const newData = Array.from({ length: newNumRows }, (_, index) => ({
      id: index + 1,
      data: "",
    }));
    setRowData(newData);
  };
  const handleDataChange = (e, id) => {
    const updatedData = rowData.map((row) =>
      row.id === id ? { ...row, data: e.target.value } : row
    );
    setRowData(updatedData);
  };
  const handleFormSubmit1 = (event) => {
    if (event) event.preventDefault();

    const hasEmptyField = rowData.some(
      (row) => row.operator === "" || row.code_description === ""
    );

    if (hasEmptyField) {
      toast.error("Please Select Operators");
      return;
    }
    const token = sessionStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["sub"];
    let logintenantId = null;
    if (roleId === 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
    }
    const planIdToSave = production.length > 0 ? production[0].planid : "";
    const partnoToSave = production.length > 0 ? production[0].partno : "";

    const formData = rowData.map((row) => ({
      tenantid: tenantId,
      operator: row.operator,
      operator_id: row.employee_id.toString(),
      skill: row.code_description,
      part_no: partnoToSave,
      plan_id: planIdToSave,
      date: date,
      machine_name: mcname,
      estimated_time: requiredTime,
    }));
    const modifiedFormData = formData.map((data, index) => ({
      ...data,
      operator: formData[index].operator, // Use the operator name
    }));
    axios
      .post(
        APIURL,
        {
          query: `
          mutation InsertMultipleOperatorProductionPlans($formData: [operator_production_plan_assignment_insert_input!]!) {
            insert_operator_production_plan_assignment(objects: $formData) {
              affected_rows
            }
          }
          
        `,
          variables: {
            formData: modifiedFormData,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-hasura-admin-secret": process.env.REACT_APP_X_API_SECRET_KEY, // Replace with your Hasura admin secret
          },
        }
      )
      .then((response) => {
        if (
          response.data.data &&
          response.data.data.insert_operator_production_plan_assignment
            .affected_rows > 0
        ) {
          //to erase data
          setCodeDescription("");
          // fetchData();
          toast.success("Data inserted in Operator successfully!");
          
          // window.location.reload();
        } else {
          toast.error("Failed to insert data.");
        }
      })
      .catch((error) => {
        toast.error("An error occurred while inserting data.");
      });
  };

  useImperativeHandle(ref, () => ({
    handleFormSubmit1,
  }));
  return (
    <>
      <div style={{ maxHeight: "400px", overflow: "auto", marginLeft: "40px" }}>
        <TextField
          label="Enter the number of Operators"
          type="number"
          value={numRows}
          onChange={handleNumRowsChange}
          InputProps={{
            style: { color: "antiquewhite", marginBottom: "20px" }, // Change the color to your desired font color
          }}
        />
        <Container style={{ maxHeight: "400px", overflow: "auto" }}>
          <TableContainer component={Paper} className="dynamic-table">
            <Table className="dynamic-table">
              <TableHead>
                <TableRow className="tbl-row">
                  <TableCell className="tbl-cell">Sr.NO.</TableCell>
                  <TableCell className="tbl-cell">Plan ID</TableCell>
                  <TableCell className="tbl-cell">Part No.</TableCell>
                  <TableCell className="tbl-cell">Operator</TableCell>
                  <TableCell className="tbl-cell">Skill-Level</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData.map((row) => (
                  <TableRow key={row.id} className="tbl-row1">
                    <TableCell style={{ color: "antiquewhite", width: "70px" }}>
                      {row.id}
                    </TableCell>
                    <TableCell>
                      <TextField
                        className="dy-txt-fld"
                        value={
                          production.length > 0 ? production[0].planid : ""
                        }
                        InputProps={{
                          style: {
                            color: "antiquewhite",
                            width: "70px",
                            height: "30px",
                          },
                        }}
                        onChange={(e) => {
                          setPlan(e.target.value);
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        className="dy-txt-fld"
                        value={
                          production.length > 0 ? production[0].partno : ""
                        }
                        InputProps={{
                          style: {
                            color: "antiquewhite",
                            width: "175px",
                            height: "30px",
                          },
                        }}
                        onChange={(e) => setOprPartNo(e.target.value)}
                      />
                    </TableCell>
                    <TableCell>
                      <FormControl sx={{ m: 1, minWidth: 150 }}>
                        <Select
                          sx={{
                            color: "antiquewhite",
                            width: "250px",
                            height: "30px",
                          }}
                          InputProps={{
                            style: {
                              color: "antiquewhite",
                              width: "500px",
                              height: "30px",
                            },
                          }}
                          displayEmpty
                          value={row.operator}
                          onChange={(event) =>
                            handleOperatorChange(event, row.id)
                          }
                        >
                          {" "}
                          <MenuItem disabled>
                            <em>Select Operator</em>
                          </MenuItem>
                          {emp_operator.map((employee) => (
                            <MenuItem
                              key={
                                employee.first_name + " " + employee.last_name
                              }
                              value={
                                employee.first_name + " " + employee.last_name
                              }
                            >
                              {`${employee.first_name} ${employee.last_name} (${employee.employee_code}`}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <TextField
                        className="dy-txt-fld"
                        value={row.code_description}
                        // value={row.skill}
                        InputProps={{
                          style: { color: "antiquewhite", height: "30px" },
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </div>
    </>
  );
}
export default React.forwardRef(DynamicTableNew);
