import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { APIURL, SECRET_KEY } from "./Apiurl.js";
import redirectToLoginIfTokenMissing from "./TokenUtility";
import { CSVLink } from "react-csv";
import {
  TabContent,
  TabPane,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Label,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import "./datatable.css";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import Modal from "@material-ui/core/Modal";
import ProductionAdd from "./ProductionAdd.js";
import { Badge } from "reactstrap";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import DynamicTableNew from "components/DynamicTable/DynamictableNew.js";
import ProductionPlanEdit from "./ProductionPlanEdit.js";



// import "../components/DynamicTable/Dynamictable.css";
const { SearchBar } = Search;

function ProductionPlan() {
  const [numRows, setNumRows] = useState(0);
  const dynamicTableNewRef = useRef();
  const [dataToExport, setDataToExport] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [struct, setstruct] = useState([]);
  const [orgstruct, setorgstruct] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [assinOperatorOpen, setAssignOperatorOpen] = React.useState(false);
  const [isDynamicTableOpen, setDynamicTableOpen] = React.useState(false);
  // const [selectedRow, setSelectedRow] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const token = sessionStorage.getItem("token");
  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const tenantId =
    decodedToken["https://hasura.io/jwt/claims"]["x-hasura-tenant-id"];

    const handleOpenEditModal = (row) => {
      setSelectedRow(row);
      setOpenEditModal(true);
    };
    const handleCloseEditModal = (row) => {
      // setSelectedRow(row);
      setOpenEditModal(false);
    };

  const handleButtonClick = () => {
    if (dynamicTableNewRef.current) {
      dynamicTableNewRef.current.handleFormSubmit1();
    }
  };

  const handleNumRowsChange = (event) => {
    setNumRows(event.target.value);
  };

  const handleAssign = (row) => {
    setSelectedRow(row);
    setDynamicTableOpen(true);
  };
  const closeDynamicTable = () => {
    setDynamicTableOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    redirectToLoginIfTokenMissing();
  }, []);

  // axios.defaults.headers.common["x-hasura-admin-secret"] = SECRET_KEY;

  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const roleId = decodedToken["sub"];
      const logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
      const storedTenantId = sessionStorage.getItem("selectedTenantId");

      let query = ""; // Define the query variable outside the if-else block
      let variables = {}; // Define the variables object

      if (roleId === 1) {
        if (storedTenantId !== null) {
          query = `
        query MyQuery($TenantId: Int!) {
          tnt_production_plan_master(order_by: {planid: desc},where: { tenantid: { _eq: $TenantId } }) {
            date
            actual_quantity_produced
            planid
            planned_quantity
            shift_id
            tenantid
            actual_starttime
            actual_time_taken
            end_customer
            machineid
            machine_name
            operation_details
            partno
            planned_quantity_uom
            remarks
            required_time_ideal
            shiftname
            target
            target_uom
            production_starttime_desired
            batch_start_time
            balance_schedule_quantity
            available_wip
            actual_required_quantity
            no_of_batches
          }
        } 
      `;
          variables = {
            TenantId: storedTenantId,
          };
        } else {
          query = `
        query MyQuery {
          tnt_production_plan_master(order_by: {planid: desc}) {
            date
            actual_quantity_produced
            planid
            planned_quantity
            shift_id
            tenantid
            actual_starttime
            actual_time_taken
            end_customer
            machineid
            machine_name
            operation_details
            partno
            planned_quantity_uom
            remarks
            required_time_ideal
            shiftname
            target
            target_uom
            production_starttime_desired
            batch_start_time
            balance_schedule_quantity
            available_wip
            actual_required_quantity
            no_of_batches
          }
        } 
      `;
        }
      } else {
        query = `
        query MyQuery {
          tnt_production_plan_master(order_by: {planid: desc}) {
            date
            actual_quantity_produced
            planid
            planned_quantity
            shift_id
            tenantid
            actual_starttime
            actual_time_taken
            end_customer
            machineid
            machine_name
            operation_details
            partno
            planned_quantity_uom
            remarks
            required_time_ideal
            shiftname
            target
            target_uom
            production_starttime_desired
            batch_start_time
            balance_schedule_quantity
            available_wip
            actual_required_quantity
            no_of_batches
          }
        } 
      `;
      }
      const response = await axios.post(
        APIURL,
        {
          query: query,
          variables: variables,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const tnt_production_plan_master =
        response.data.data.tnt_production_plan_master.map((structure) => ({
          ...structure,
          status: "On",
        }));
      setorgstruct(tnt_production_plan_master);
      setDataToExport(tnt_production_plan_master);
    } catch (error) {
      console.error(error);
    }
  };
  const activeFormatter = (cell, row) => {
    const isActive = row.is_active === "1";
    return (
      <Badge color={isActive ? "info" : "danger"}>
        {isActive ? "Active" : "Inactive"}
      </Badge>
    );
  };
  function handleEdit(row) {
    //write here Logic for handling the edit action
  }

  const convertUtcToIst = (utcTime) => {
    const utcDateTime = new Date(`1970-01-01T${utcTime}Z`);
    const istDateTime = new Date(
      utcDateTime.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
    );

    const hours = istDateTime.getHours();
    const minutes = istDateTime.getMinutes();
    const seconds = istDateTime.getSeconds();
    if (hours === 24 && minutes === 0 && seconds === 0) {
      return "12:00:00 am";
    }
    return istDateTime.toLocaleTimeString("en-IN", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
  };
  const columns = [
    {
      dataField: "planid",
      text: "planid",
    },
    {
      dataField: "date",
      text: "date",
    },
    {
      dataField: "partno",
      text: "Part No",
    },
    {
      dataField: "end_customer",
      text: "end customer",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "operation_details",
      text: "operation details",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machineid",
      text: "machine no",
    },
    {
      dataField: "machine_name",
      text: "machine name",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "shiftname",
      text: "shift name",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "production_starttime_desired",
      text: "production start time",
      formatter: (cell, row) =>
        convertUtcToIst(row.production_starttime_desired),
    },
    {
      dataField: "planned_quantity",
      text: "planned quantity",
    },
    {
      dataField: "actual_required_quantity",
      text: "actual required quantity",
    },
    {
      dataField: "balance_schedule_quantity",
      text: "balance schedule quantity",
    },
    {
      dataField: "available_wip",
      text: "available wip",
    },
    {
      dataField: "required_time_ideal",
      text: "required time",
      formatter:(cell)=>{
        return `${cell} minutes`
      }
    },
    {
      dataField: "remarks",
      text: "remarks",
    },
    {
      dataField: "re",
      text: "Assign Operator",
      formatter: assignFormatter,
    },
    {
      dataField: "action",
      text: "Action",
      formatter: actionFormatter,
     
    },
  ];

  function assignFormatter(cell, row) {
    return (
      <div>
        <button className="btn btn-secondary" onClick={() => handleAssign(row)}>
          Assign
        </button>
      </div>
    );
  }
  //////////////////////////////////////////////////////////
  function actionFormatter(cell, row) {
    return (
      <div>
        <Col
          className="font-icon-list col-xs-6 col-xs-6"
          lg="2"
          md="3"
          sm="4"
        ></Col>
        <FontAwesomeIcon
          icon={faPencilAlt}
          className="btn btn-primary tim-icons icon-pencil"
          style={{
            fontSize: "70%",
            background: "none",
            // marginRight: "-46px",
            // pointerEvents: "none",
            cursor: "pointer"
          }}
          onClick={() => handleOpenEditModal(row)}
        />
      </div>
    );
  }
  /////////////////////////////////////////////////////////
  // function actionFormatter(cell, row) {
  //   return (
  //     <div>
  //       <Col
  //         className="font-icon-list col-xs-6 col-xs-6"
  //         lg="2"
  //         md="3"
  //         sm="4"
  //         onClick={() => handleEdit(row)}
  //       ></Col>
  //       <Link to={`/admin/production-plan-edit/${row.planid}`}>
  //         <FontAwesomeIcon
  //           icon={faPencilAlt}
  //           className="btn btn-primary tim-icons icon-pencil"
  //           style={{
  //             fontSize: "70%",
  //             background: "none",
  //             marginRight: "-46px",
  //             pointerEvents: "none",
  //           }}
  //         />
  //       </Link>
  //       <Link to={`/admin/DELETE PATH/${row.planid}`}>
  //         <FontAwesomeIcon
  //           // icon={faTrash}
  //           className="btn btn-primary tim-icons icon-pencil"
  //           style={{
  //             fontSize: "70%",
  //             background: "none",
  //             pointerEvents: "none",
  //           }}
  //         />
  //       </Link>
  //     </div>
  //   );
  // }
  return (
    <div className="content">
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4" className="table-card-header">
                    Manage Production Plan
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={struct}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <div>
                        <div
                          className="float-right"
                          style={{
                            display: "flex",
                            marginBottom: "18px",
                            gap: "12px",
                          }}
                        >
                          <div className="float-left">
                            <SearchBar {...props.searchProps} />
                          </div>
                          <div className="float-right">
                            <button
                              className="btn btn-secondary"
                              onClick={handleOpen}
                            >
                              Create New Production Plan
                            </button>
                            <CSVLink
                              data={dataToExport}
                              filename="production_plan_data.csv" // Customize the filename
                              className="btn btn-primary"
                            >
                              Export to CSV
                            </CSVLink>
                          </div>
                        </div>
                        <BootstrapTable
                          {...props.baseProps}
                          keyField="id"
                          data={orgstruct}
                          columns={columns}
                          filter={filterFactory()}
                          pagination={paginationFactory({
                            bootstrap4: true,
                          })}
                          headerClasses="sorted-asc sorted-desc"
                          wrapperClasses="table-responsive"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId={2}>{/* Content for Tab 2 */}</TabPane>
        <TabPane tabId={3}>{/* Content for Tab 3 */}</TabPane>
      </TabContent>

      <Modal
        onClose={handleClose}
        open={open}
        style={{
          boxShadow: "2px solid black",
          width: "120%",
          top: "10%",
          left: "10%",
        }}
      >
        <div>
          {/* <button
            onClick={handleClose}
            style={{
              position: "fixed",
              top: "112px",
              right: "399px",
              background: "transparent",
              border: "none",
              color: "white",
              fontSize: "40px",
              cursor: "pointer",
              float: "right",
              zIndex: 1,
            }}
          >
            &times;
          </button> */}
          <ProductionAdd handleClose={handleClose} fetchData={fetchData} />
        </div>
      </Modal>

      <Modal
        open={isDynamicTableOpen}
        onClose={closeDynamicTable}
        style={{
          boxShadow: "2px solid black",
          top: "18%",
          left: "8%",
          marginRight: "175px",
        }}
      >
        <div>
          <button
            onClick={closeDynamicTable}
            style={{
              position: "fixed",
              top: "112px",
              right: "399px",
              background: "transparent",
              border: "none",
              color: "white",
              fontSize: "40px",
              cursor: "pointer",
              float: "right",
              zIndex: 1,
            }}
          >
            &times;
          </button>
          <Card>
            <Row>
              <Col md="4">
                <FormGroup>
                  <div className="dynamic-tbl">
                    <Label for="no_of_operator" style={{ margin: "20px",color:"#ffffff",fontSize:"16px" }}>
                      No. of Operators
                    </Label>
                    <DynamicTableNew
                      selectedRowData={selectedRow}
                      ref={dynamicTableNewRef}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "10px",
              }}
            >
              <Button
                color="danger"
                onClick={(event) => {
                  closeDynamicTable(event);
                }}
                style={{ marginRight: "10px" }}
              >
                Cancel
              </Button>
              <Button
                color="success"
                onClick={(event) => {
                  handleButtonClick();
                  closeDynamicTable(event);
                }}
                style={{ marginRight: "30px" }}
              >
                {" "}
                Save
              </Button>
            </div>
          </Card>
        </div>
      </Modal>
      <Modal
          onClose={() => setOpenEditModal(false)}
          open={openEditModal}
          style={{
            boxShadow: "2px solid black",
            width: "100%",
            top: "15%",
            left: "15%",
            
          }}
        >
            <ProductionPlanEdit 
            selectedRow={selectedRow} 
            handleCloseEditModal={handleCloseEditModal} 
            fetchData={fetchData}
            />
          
        </Modal>
    </div>
  );
}
export default ProductionPlan;
