import React, { useState, useEffect } from "react";
import "./style.css";
import {
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import TenantMasterall from "./TenantMasterall";
import TenantAdministration from "./TenantAdministration";

function ManagePeople(props) {
  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      sessionStorage.setItem("activeTab", tab);
    }
  };
  useEffect(() => {
    // Retrieve the active tab from sessionStorage
    const storedActiveTab = sessionStorage.getItem("activeTab");
    if (storedActiveTab) {
      setActiveTab(storedActiveTab);
    }
  }, []);
  

  const getBreadcrumbItemText = (tabId) => {
    switch (tabId) {
      case "1":
        return "Tenant Master";
      case "2":
        return "Tenant Administration";
      case "3":
        return "Tenant Employee Job Description";
      case "4":
        return "Org Employee Machines";
      case "5":
        return "Work Schedule";
      default:
        return "Tenant Master";
    }
  };

  return (
    <>
      <div className="content">
        <div class="container1">
        <h1 className="main-heading">Manage Tenants</h1>
        <div class="breadcrumb-container">
          <Breadcrumb>
            <BreadcrumbItem><FontAwesomeIcon icon={faHouse} /></BreadcrumbItem>
            <BreadcrumbItem>Manage Tenants</BreadcrumbItem>
            <BreadcrumbItem active>{getBreadcrumbItemText(activeTab)}</BreadcrumbItem>
          </Breadcrumb>
        </div>
      </div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => toggleTab("1")}
            >
              Tenant Master
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => toggleTab("2")}
            >
             Tenant Administration
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => toggleTab("3")}
            >
              Employee Job Description
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "4" })}
              onClick={() => toggleTab("4")}
            >
              Org Employee Machines
            </NavLink>
          </NavItem> */}
          
        </Nav>

        <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col>
              <TenantMasterall />
            </Col>
          </Row>
        </TabPane>
       
        <TabPane tabId="2">
          <Row>
            <Col>
            <TenantAdministration />
            </Col>
          </Row>
        </TabPane>
        {/* <TabPane tabId="3">
          <Row>
            <Col>
            <TenantEmployeeJobDescription />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="4">
          <Row>
            <Col>
            <OrgEmployeeMachine />
            </Col>
          </Row>
        </TabPane> */}
      </TabContent>
      </div>
    </>
  );
}

export default ManagePeople;
