import React, { useState, useEffect } from "react";
import redirectToLoginIfTokenMissing from "views/TokenUtility.js";
import { TabContent, TabPane, Card, CardBody, Row, Col } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { QueryApi } from "QueryApi.js";

function ReusableWaseGeneration() {
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    redirectToLoginIfTokenMissing();
  }, []);

  const [apiData, setApiData] = useState([]);
  useEffect(() => {
    fetchDataList();
  }, []);
  const fetchDataList = async () => {
    const data = await QueryApi(`
      query MyQuery {
        bopp_fl0_total_usable_waste {
          date
          department_id
          egdeid
          line_id
          machineid
          org_id
          plant_usable_waste_volume
          product_id
          secondary_slitter_usable_waste_volume
          Primary_usable_waste__Volume
          c1_usable_waste_volume
          shiftid
          tenantid
          timestamp
          total_usable_waste_volume
          unit_id
          dept {
            organization_node_name
          }
          org {
            organization_node_name
          }
          plant {
            organization_node_name
          }
        }
      }
  `);
    setApiData(data?.bopp_fl0_total_usable_waste);
  };
  const columns = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "ORGANIZATION",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "plant.organization_node_name",
      text: "plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machineid",
      text: "machine",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "plant_usable_waste_volume",
      text: "plant usable waste volume",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "product_id",
      text: "product id",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "secondary_slitter_usable_waste_volume",
      text: "secondary slitter usable waste volume",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "Primary_usable_waste__Volume",
      text: "Primary usable waste Volume",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "c1_usable_waste_volume",
      text: "c1 usable waste volume",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "total_usable_waste_volume",
      text: "total usable waste volume",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "shiftid",
      text: "shift id",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "egdeid",
      text: "egde id",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "line_id",
      text: "line id",
      headerStyle: { width: "500px" },
    },
  ];

  return (
    <div className="content">
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={apiData}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <div>
                        <BootstrapTable
                          {...(props.baseProps && props.baseProps)}
                          filter={filterFactory()}
                          pagination={paginationFactory()}
                          data={apiData || []}
                          headerClasses="sorted-asc sorted-desc"
                          wrapperClasses="table-responsive"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default ReusableWaseGeneration;
