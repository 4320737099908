import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from "reactstrap";
import axios from "axios";
import { APIURL } from "./Apiurl.js";
import { toast } from "react-toastify";

const AssignAttributeNew = ({
  machineType,
  machineSubType,
  machineTypename,
  machineSubTypename,
  machineId,
  edgeid,
}) => {
  const [activeStep, setActiveStep] = useState(1);
  const [progress, setProgress] = useState(0);

  const [attribute, setattribute] = useState("");
  const [attributename, setattributename] = useState("");
  const [attributes, setattributes] = useState([]);

  const [unitofmeasure, setunitofmeasure] = useState("");
  const [direction, setdirection] = useState("");
  const [idealvalue, setidealvalue] = useState("");
  const [minvalue, setminvalue] = useState("");
  const [maxvalue, setmaxvalue] = useState("");
  const [warningthreshold, setwarningthreshold] = useState("");
  const [requiredtrigger, setrequiredtrigger] = useState("");
  const [alertaction, setalertaction] = useState("");

  const [selectedAttribute, setSelectedAttribute] = useState(null);

  const [attributecategory, setattributecategory] = useState(null);
  const [attributetype, setattributetype] = useState(null);

  console.log(machineId);

  useEffect(() => {
    fetchAttributes();
  }, []);

  const fetchAttributes = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery {
          config_machine_attribute(where: { is_default: { _eq: 0 } }) {
            config_machine_attribute_id
            machine_attribute_name
            attribute_category
            attribute_type
            unit_of_measure
            direction
            min_value
            max_value
          }
        }       
        `,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      setattributes(response.data.data.config_machine_attribute);
      console.log(response.data.data.config_machine_attribute);
    } catch (error) {
      console.error(error);
    }
  };
  const handleFormSubmit = (event) => {
    event.preventDefault();
    const token = sessionStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["sub"];
    // const logintenantId = decodedToken['https://hasura.io/jwt/claims']['x-hasura-org-id'];
    let logintenantId = null;
    if (roleId === 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
    }
    const formData = {
      tenant_id: logintenantId,
      machine_id: machineId,
      edge_id: edgeid,
      machine_type: machineType,
      machine_sub_type: machineSubType,
      machine_type_name: machineTypename,
      machine_sub_type_name: machineSubTypename,
      machine_attribute_name: attributename,
      attribute_category: attributecategory,
      attribute_type: attributetype,
      unit_of_measure: unitofmeasure,
      direction: direction,
      idea_value: idealvalue,
      min_value: minvalue,
      max_value: maxvalue,
      warning_threshold: warningthreshold,
      required_trigger: requiredtrigger,
      alert_action: alertaction,
      attribute_id: attribute,
    };
    console.log(formData);
    axios
      .post(
        APIURL,
        {
          query: `
            mutation Inserttnt_machine_attributes_master($formData: tnt_machine_attributes_master_insert_input!) {
              insert_tnt_machine_attributes_master(objects: [$formData]) {
                affected_rows
                }
            }
        `,
          variables: {
            formData: formData,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      )
      .then((response) => {
        console.log(response.data); // Handle the response data as needed
        if (
          response.data.data &&
          response.data.data.insert_tnt_machine_attributes_master
            .affected_rows > 0
        ) {
          console.log("inserted");

          toast.success("Attribute Assigned successfully!");
        } else {
          toast.error("Failed to insert data.");
        }
      })
      .catch((error) => {
        console.error(error); // Handle the error
        toast.error("An error occurred while inserting data.");
      });
  };

  return (
    <div className="content">
      <Row className="progress-row">
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Add new Attribute</h5>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col className="px-md-1" md="4">
                    <FormGroup>
                      <Label for="machineType">Attribute</Label>
                      <Input
                        type="select"
                        id="machineType"
                        value={attribute}
                        onChange={(e) => {
                          const selectedAttributeId = e.target.value;
                          const attributeObject = attributes.find(
                            (attr) =>
                              attr.config_machine_attribute_id ===
                              parseInt(selectedAttributeId)
                          );
                          setSelectedAttribute(attributeObject); // Set the selected attribute object

                          // Set other form values
                          setattribute(selectedAttributeId);
                          setattributename(
                            attributeObject.machine_attribute_name
                          );
                          setattributecategory(
                            attributeObject.attribute_category
                          );
                          setattributetype(attributeObject.attribute_type);
                        }}
                      >
                        <option value="">Select Machine Type</option>
                        {attributes.map((at) => (
                          <option
                            key={at.config_machine_attribute_id}
                            value={at.config_machine_attribute_id}
                          >
                            {at.machine_attribute_name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col className="px-md-1" md="4">
                    <FormGroup>
                      <Label for="machineType">Unit Of Measure</Label>
                      <Input
                        type="text"
                        id="unitofmeasure"
                        value={unitofmeasure}
                        onChange={(e) => {
                          setunitofmeasure(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="px-md-1" md="4">
                    <FormGroup>
                      <Label for="machineType">Direction</Label>
                      <Input
                        type="select"
                        id="machineType"
                        value={direction}
                        onChange={(e) => {
                          setdirection(e.target.value);
                        }}
                      >
                        <option value="">Select Direction Type</option>
                        <option value="Maximize">Maximize</option>
                        <option value="Minimize">Minimize</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="px-md-1" md="4">
                    <FormGroup>
                      <Label for="machineType">Ideal Value</Label>
                      <Input
                        type="text"
                        id="idealvalue"
                        value={idealvalue}
                        onChange={(e) => {
                          setidealvalue(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="px-md-1" md="4">
                    <FormGroup>
                      <Label for="machineType">Min Value</Label>
                      <Input
                        type="text"
                        id="idealvalue"
                        value={minvalue}
                        onChange={(e) => {
                          setminvalue(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="px-md-1" md="4">
                    <FormGroup>
                      <Label for="machineType">Max Value</Label>
                      <Input
                        type="text"
                        id="idealvalue"
                        value={maxvalue}
                        onChange={(e) => {
                          setmaxvalue(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col className="px-md-1" md="4">
                    <FormGroup>
                      <Label for="machineType">Warning Threshold</Label>
                      <Input
                        type="text"
                        id="idealvalue"
                        value={warningthreshold}
                        onChange={(e) => {
                          setwarningthreshold(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="px-md-1" md="4">
                    <FormGroup>
                      <Label for="machineType">Required Trigger</Label>
                      <Input
                        type="select"
                        id="machineType"
                        value={requiredtrigger}
                        onChange={(e) => {
                          setrequiredtrigger(e.target.value);
                        }}
                      >
                        <option value="">Select Required Trigger</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col className="px-md-1" md="4">
                    <FormGroup>
                      <Label for="machineType">Alert Action</Label>
                      <Input
                        type="select"
                        id="machineType"
                        value={alertaction}
                        onChange={(e) => {
                          setalertaction(e.target.value);
                        }}
                      >
                        <option value="">Select Alert Action</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
            <CardFooter>
              <div className="text-right">
                <Button color="success" onClick={handleFormSubmit}>
                  Submit
                </Button>
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AssignAttributeNew;
