import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "./Apiurl.js";
import {
  TabContent,
  TabPane,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import Modal from "@material-ui/core/Modal";
import EmpTagAssignAdd from "./EmpTagAssignAdd";
import { Badge } from "reactstrap";

const { SearchBar } = Search;

function JobrolemasterAll() {
  const [activeTab, setActiveTab] = useState(1);
  const [emptag, setemptag] = useState([]);
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    fetchData();
  }, []);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const roleId = decodedToken["sub"];
      const logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
      const storedTenantId = sessionStorage.getItem("selectedTenantId");

      let query = ""; // Define the query variable outside the if-else block
      let variables = {}; // Define the variables object

      if (roleId == 1) {
        if (storedTenantId !== null) {
          query = `
        query MyQuery($tenantId: Int!) {
          employee_tag_assignment(where: { tenantid: { _eq: $tenantId } }) {
            emp_tag_assign_id
          employee_id
          is_active
          assignment_date
          employee {
            first_name
            last_name
            employee_code
          }
          created_at
          tag_id
          
          }
        }
      `;

          variables = {
            tenantId: storedTenantId,
          };
        } else {
          query = `
        query MyQuery {
          employee_tag_assignment {
            emp_tag_assign_id
            employee_id
            is_active
            assignment_date
            employee {
              first_name
              last_name
              employee_code
            }
            created_at
            tag_id
          }
        }
        `;
        }
      } else {
        query = `
        query MyQuery {
          employee_tag_assignment {
            emp_tag_assign_id
            employee_id
            is_active
            assignment_date
            employee {
              first_name
              last_name
              employee_code
            }
            created_at
            tag_id
          }
        }
      `;
      }

      const response = await axios.post(
        APIURL,
        {
          query: query,
          variables: variables,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setemptag(response.data.data.employee_tag_assignment);
      console.log(APIURL);
    } catch (error) {
      console.error(error);
    }
  };

  function actionFormatter(cell, row) {
    return (
      <div>
        <Col
          className="font-icon-list col-xs-6 col-xs-6"
          lg="2"
          md="3"
          sm="4"
          onClick={() => handleEdit(row)}
        ></Col>
        <FontAwesomeIcon
          icon={faPencilAlt}
          className="btn btn-primary tim-icons icon-pencil"
          style={{
            fontSize: "70%",
            background: "none",
            marginRight: "-46px",
            pointerEvents: "none",
          }}
        />
        <Link to={`/admin/org-structure/${row.organization_structure_id}`}>
          <FontAwesomeIcon
            icon={faTrash}
            className="btn btn-primary tim-icons icon-pencil"
            style={{
              fontSize: "70%",
              background: "none",
              pointerEvents: "none",
            }}
          />
        </Link>
      </div>
    );
  }

  function handleEdit(row) {
    // Logic for handling the edit action
    console.log("Editing row:", row);
  }

  const columns = [
    {
      dataField: "emp_tag_assign_id",
      text: "Emp Tag Assignment ID",
    },
    {
      dataField: "employee.employee_code",
      text: "Employee Code",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "full_name", // New dataField for the merged column
      text: "Full Name", // New text for the merged column
      formatter: (cell, row) =>
        `${row.employee.first_name} ${row.employee.last_name}`,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "tag_id",
      text: "Tag ID",
    },
    {
      dataField: "assignment_date",
      text: "Assignment Date",
      headerStyle: { width: "500px" },
      // formatter: (cell, row) => new Date(cell).toLocaleString(),
    },
    {
      dataField: "is_active",
      text: "Status",
      formatter: (cell, row) => {
        const isActive = cell === "1";
        return (
          <Badge color={isActive ? "info" : "danger"}>
            {isActive ? "Active" : "Inactive"}
          </Badge>
        );
      },
    },

    {
      dataField: "action",
      text: "Action",
      formatter: actionFormatter,
      headerStyle: { width: "500px" },
    },
  ];

  return (
    <div className="content">
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4" className="table-card-header">
                    Manage Employe Tag Assignment
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={emptag}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <div>
                        <div
                          className="float-right"
                          style={{
                            display: "flex",
                            marginBottom: "18px",
                            gap: "12px",
                          }}
                        >
                          <div className="float-left">
                            <SearchBar {...props.searchProps} />
                          </div>
                          <div className="float-right">
                            <button
                              className="btn btn-secondary"
                              onClick={handleOpen}
                            >
                              Add new Employee Tag Assignment
                            </button>
                          </div>
                        </div>
                        <BootstrapTable
                          {...props.baseProps}
                          filter={filterFactory()}
                          pagination={paginationFactory()}
                          headerClasses="sorted-asc sorted-desc"
                          wrapperClasses="table-responsive"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
      <Modal
        onClose={handleClose}
        open={open}
        style={{
          boxShadow: "2px solid black",
          width: "100%",
          top: "15%",
          left: "15%",
        }}
      >
        <div>
          <button
            onClick={handleClose}
            style={{
              position: "fixed",
              top: "112px",
              right: "399px",
              background: "transparent",
              border: "none",
              color: "white",
              fontSize: "40px",
              /* margin-top: -85px; */
              cursor: "pointer",
              float: "right",
              zIndex: 1,
            }}
          >
            &times; {/* This is the 'x' character for the close button */}
          </button>
          <EmpTagAssignAdd onInsertSuccess={fetchData} />
        </div>
      </Modal>
    </div>
  );
}

export default JobrolemasterAll;
