import React, { useState, useEffect, useRef } from "react";
import "./AddTilesSidebar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartSimple } from "@fortawesome/free-solid-svg-icons";
import { Card, CardHeader, CardTitle, Row, Col } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import "./responsive.css";
import axios from "axios";
import { APIURL } from "./Apiurl.js";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const AddTilesSidebar = ({ isOpen, toggleSidebar }) => {
  const { dashboard_id } = useParams();
  console.log(dashboard_id);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);
  const [selectedDriverCategory, setselectedDriverCategory] = useState("");
  const [selectedcategory, setselectedcategory] = useState([]);
  const [featurenames, setfeaturenames] = useState([]);
  const [selectedValues, setSelectedValues] = useState({
    selectedValue: "",
    widgetId: "",
    primaryId: "",
  });
  const fetchCats = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery {
          config_code_master(where: {code_type: {_eq: "Primary Driver Category"}}) {
            code_id
            code_type
            code
          }
        }
        `,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setselectedcategory(response.data.data.config_code_master);
      console.log(response.data.data.config_code_master);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchCats();
    setSidebarOpen(isOpen);
    if (isOpen) {
      document.addEventListener("click", handleDocumentClick);
    } else {
      document.removeEventListener("click", handleDocumentClick);
    }

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [isOpen]);

  const handleDocumentClick = (e) => {
    if (sidebarRef.current && !sidebarRef.current.contains(e.target)) {
      toggleSidebar();
    }
  };

  console.log(selectedDriverCategory);
  useEffect(() => {
    fetchfeaturename();
  }, [selectedDriverCategory]);
  const fetchfeaturename = async () => {
    if (selectedDriverCategory) {
      // Only fetch if org is selected
      try {
        const token = sessionStorage.getItem("token");
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        const roleId = decodedToken["sub"];
        const response = await axios.post(
          APIURL,
          {
            query: `
            query MyQuery($p: String!) {
              widget_feather_mapping_master(where: {primary_driver_category: {_eq: $p}}) {
                widget_feather_mapping_master_id
                daily
                dept
                feature_id
                feature_name
                is_active
                machine
                monthly
                organization
                plant
                primary_driver_category
                unit_of_measure
                visualization
                weekly
                widget_id
                yearly
              }
            }
          `,
            variables: {
              p: selectedDriverCategory,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log(org);
        setfeaturenames(response.data.data.widget_feather_mapping_master);
        console.log(response.data.data.widget_feather_mapping_master);
      } catch (error) {
        console.error(error);
      }
    }
  };
  const handleFeatureSelect = (selectedValue, widgetId, primaryId) => {
    setSelectedValues({
      selectedValue,
      widgetId,
      primaryId,
    });
  };
  const handleAddTiles = () => {
    // Get the existing data from session storage (if any)
    const existingDataJSON = sessionStorage.getItem("selectedValues");
    let existingData = existingDataJSON ? JSON.parse(existingDataJSON) : [];

    // Ensure existingData is an array
    if (!Array.isArray(existingData)) {
      existingData = [];
    }

    // Check if the combination already exists
    // const combinationExists = existingData.some((item) => {
    //   return (
    //     item.selectedValue === selectedValues.selectedValue &&
    //     item.widgetId === selectedValues.widgetId &&
    //     item.primaryId === selectedValues.primaryId
    //   );
    // });

    // If the combination doesn't exist, add it to the array
    const token = sessionStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["sub"];
    console.log(roleId);
    let logintenantId = null;
    if (roleId == 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
    }
    console.log(logintenantId);
    const dashboardid = sessionStorage.getItem("selectedDashboardId");
    console.log("Selected dashboard id:", dashboardid);
    console.log("Selected Value:", selectedValues.selectedValue);
    console.log("Widget Id:", selectedValues.widgetId);
    console.log("Primary Id:", selectedValues.primaryId);
    const formData = {
      tenantid: logintenantId,
      widget_id: selectedValues.widgetId,
      widget_feather_mapping_master_id: selectedValues.primaryId,
      dashboard_id: dashboardid,
    };
    axios
      .post(
        APIURL,
        {
          query: `
        mutation Insertwidget_dashboard_assignment($formData: widget_dashboard_assignment_insert_input!) {
          insert_widget_dashboard_assignment(objects: [$formData]) {
            affected_rows
          }
        }
      `,
          variables: {
            formData: formData,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response); // Handle the response data as needed
        if (
          response.data.data &&
          response.data.data.insert_widget_dashboard_assignment.affected_rows >
            0
        ) {
          console.log("hello");
          toast.success("Widget added in dashboard successfully!");
          window.location.reload();
        } else {
          // Handle the case where the insert was not successful due to a duplicate entry
          toast.error("Failed to insert data: Duplicate entry.");
        }
      })
      .catch((error) => {
        console.error(error); // Handle the error
        toast.error("An error occurred while inserting data.");
      });

    // window.location.reload();
  };

  return (
    <div className={`add-tiles-sidebar ${isOpen ? "open" : ""}`}>
      <button className="close-button float-left" onClick={toggleSidebar}>
        <i className="fas fa-times" style={{ fontSize: "35px" }}></i>
      </button>
      <div className="ant-drawer-body">
        <Row>
          {selectedcategory.map((category) => (
            <Col key={category.code_id} lg="3" className="text-center">
              <Card className="card-chart">
                <CardHeader>
                  <div className="icon-circle">
                    <FontAwesomeIcon
                      icon={faChartSimple}
                      className="text-success icon-size"
                    />
                  </div>
                </CardHeader>
                <CardTitle tag="h4" className="mt-2">
                  <a onClick={(e) => setselectedDriverCategory(category.code)}>
                    {category.code}
                  </a>
                </CardTitle>
              </Card>
            </Col>
          ))}
        </Row>

        <hr />
        <div
          style={{
            color: "white",
            margin: "30px",
            overflowY: "scroll",
            maxHeight: "500px",
            padding: "10px",
          }}
        >
          {featurenames.length === 0 ? (
            <p>No feature names available.</p>
          ) : (
            // Display the fetched feature names here
            featurenames.map((feature) => (
              <div key={feature.feature_id}>
                <label>
                  <input
                    type="radio"
                    style={{ marginRight: "25px" }}
                    name="featureGroup"
                    value={feature.visualization}
                    onChange={() =>
                      handleFeatureSelect(
                        feature.visualization,
                        feature.widget_id,
                        feature.widget_feather_mapping_master_id
                      )
                    }
                  />
                  {feature.feature_name}
                </label>
              </div>
            ))
          )}
        </div>

        <div className="update-button-container">
          <button color="primary" onClick={handleAddTiles}>
            Add Widget
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddTilesSidebar;
