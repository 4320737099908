import React, { useState } from "react";
import { TabContent, TabPane, Card, CardBody, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";

function OEEGridByMachine(props) {
  const [activeTab, setActiveTab] = useState(1);

  const columns = [
    {
      dataField: "serialNo",
      text: "Sr. No.",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
    },
    {
      dataField: "machineid",
      text: "Machine",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machine_availability_percent",
      text: "Availability %",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        if (data == null) {
          return "N/A"; // or whatever default value you want
        } else {
          return `${data.toFixed(3)}%`;
        }
      },
    },
    {
      dataField: "machine_performance_percent",
      text: "Performance %",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        if (data == null) {
          return "N/A"; // or whatever default value you want
        } else {
          return `${data.toFixed(3)}%`;
        }
      },
    },
    {
      dataField: "quality_percent",
      text: "Quality %",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        if (data == null) {
          return "N/A"; // or whatever default value you want
        } else {
          return `${data.toFixed(3)}%`;
        }
      },
    },
    {
      dataField: "oee",
      text: "OEE %",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        if (data == null) {
          return "N/A"; // or whatever default value you want
        } else {
          return `${data.toFixed(3)}%`;
        }
      },
    },
  ];

  return (
    <div className="content">
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={props.tableData}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <div>
                        <div
                          className="float-right"
                          style={{
                            display: "flex",
                            marginBottom: "18px",
                            gap: "12px",
                          }}
                        ></div>
                        <BootstrapTable
                          {...props.baseProps}
                          filter={filterFactory()}
                          pagination={paginationFactory()}
                          headerClasses="sorted-asc sorted-desc"
                          wrapperClasses="table-responsive"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default OEEGridByMachine;
