import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "./Apiurl.js";
import redirectToLoginIfTokenMissing from "./TokenUtility";
import {
  TabContent,
  TabPane,
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Badge } from "reactstrap";

function JobrolemasterAll(props) {
  const [activeTab, setActiveTab] = useState(1);
  const [employees, setEmployees] = useState([]);
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    fetchData();
    redirectToLoginIfTokenMissing();
  }, []);
  const [apiData, setApiData] = useState([]);
  useEffect(() => {
    setApiData(props.apiData);
  })

  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      const roleId = decodedToken['sub'];
      const logintenantId = decodedToken['https://hasura.io/jwt/claims']['x-hasura-org-id'];
      const storedTenantId = sessionStorage.getItem('selectedTenantId');
      console.log(roleId === 1);

      let query = ''; // Define the query variable outside the if-else block
      let variables = {}; // Define the variables object

      if (roleId === 1) {
        if (storedTenantId !== null) {
          query = `
        query MyQuery($tenantId: Int!) {
          tenant_employees(where: { tenant_id: { _eq: $tenantId } }) {
            employee_id
          first_name
          middle_name
          is_active
          last_name
          unit {
            organization_node_name
          }
          organization {
            organization_node_name
          }
          dept {
            organization_node_name
          }
          empcategory {
            code
          }
          emptype {
            code
          }
          jobrole {
            job_role
          }
          reportingmanager {
            first_name
            middle_name
            last_name
          }
          created_at
          contact_number
          updated_at
          }
        }
      `;

          variables = {
            tenantId: storedTenantId,
          };
        } else {
          query = `
        query {
          tenant_employees {
            employee_id
            first_name
            middle_name
            is_active
            last_name
            unit {
              organization_node_name
            }
            organization {
              organization_node_name
            }
            dept {
              organization_node_name
            }
            empcategory {
              code
            }
            emptype {
              code
            }
            jobrole {
              job_role
            }
            reportingmanager {
              first_name
              middle_name
              last_name
            }
            created_at
            contact_number
            updated_at
          }
        }
        `;
        }
      } else {
        query = `
        query MyQuery($tenantId: Int!) {
          tenant_employees(where: { tenant_id: { _eq: $tenantId } }) {
            employee_id
          first_name
          middle_name
          is_active
          last_name
          unit {
            organization_node_name
          }
          organization {
            organization_node_name
          }
          dept {
            organization_node_name
          }
          empcategory {
            code
          }
          emptype {
            code
          }
          jobrole {
            job_role
          }
          reportingmanager {
            first_name
            middle_name
            last_name
          }
          created_at
          contact_number
          updated_at
          }
        }
      `;

        variables = {
          tenantId: parseInt(
            decodedToken["https://hasura.io/jwt/claims"]["x-hasura-tenant-id"]
          ),
        };
      }

      const response = await axios.post(
        APIURL,
        {
          query: query,
          variables: variables,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      setEmployees(response.data.data.tenant_employees);
      console.log(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  function actionFormatter(cell, row) {
    return (
      <div>
        <Col
          className="font-icon-list col-xs-6 col-xs-6"
          lg="2"
          md="3"
          sm="4"
          onClick={() => handleEdit(row)}
        ></Col>
        <FontAwesomeIcon
          icon={faPencilAlt}
          className="btn btn-primary tim-icons icon-pencil"
          style={{
            fontSize: "70%",
            background: "none",
            marginRight: "-46px",
            pointerEvents: "none",
          }}
        />
        <Link to={`/admin/org-structure/${row.organization_structure_id}`}>
          <FontAwesomeIcon
            icon={faTrash}
            className="btn btn-primary tim-icons icon-pencil"
            style={{
              fontSize: "70%",
              background: "none",
              pointerEvents: "none",
            }}
          />
        </Link>
      </div>
    );
  }

  function handleEdit(row) {
    // Logic for handling the edit action
    console.log("Editing row:", row);
  }
  const activeFormatter = (cell, row) => {
    const isActive = row.is_active === "1";

    return (
      <Badge color={isActive ? "info" : "danger"}>
        {isActive ? "Active" : "Inactive"}
      </Badge>
    );
  };
  const columns = [
    {
      dataField: "index",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
    },
    {
      dataField: "machineid", // New dataField for the merged column
      text: "Machine id", // New text for the merged column
    },
    {
      dataField: "date", // New dataField for the merged column
      text: "Date", // New text for the merged column
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
    },
    {
      dataField: "unit.organization_node_name",
      text: "Unit/Plant",
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
    },
    {
      dataField: "shift_id",
      text: "Shift Name",
    },
    {
      dataField: "de",
      text: "Operator Name",
    },
    {
      dataField: "supervisor_id",
      text: "Supervisor/Manager",
    },
    {
      dataField: "planned_production_time",
      text: "Planned Production Time",
      formatter: (data) => {
        const minutes = data / 3600000;
        return `${minutes.toFixed(2)} hours`;//minutes.toFixed(3);
        }
    },
    {
      dataField: "machine_idle_time",
      text: "Idle Time Loss",
      formatter: (data) => {
        const minutes = data / 60000;
        return `${minutes.toFixed(3)} minutes`;//minutes.toFixed(3);
        }
    },
    {
      dataField: "cycletime_deviation",
      text: "Cycle Time Loss",
      formatter: (data) => {
        const minutes = data / 60000;
        return `${minutes.toFixed(3)} minutes`;//minutes.toFixed(3);
        }
    },
    {
      dataField: "capacity_utilized_percent",
      text: "Capacity Utilization(%)",
      formatter: (data) => {
        return `${data.toFixed(3)}%`;
        }
    },
    {
      dataField: "time_between_job_parts",
      text: "Time between jobs",
      formatter: (data) => {
        const minutes = data / 60000;
        return `${minutes.toFixed(3)} minutes`;//minutes.toFixed(3);
        }
    },
    {
      dataField: "machine_performance_percent",
      text: "Performance (Equipment Performance)",
      formatter: (data) => {
        return `${data.toFixed(3)}%`;
        }
    },
    {
      dataField: "machine_availability_percent",
      text: "Availability (Equipment Availability)",
      formatter: (data) => {
        return `${data.toFixed(3)}%`;
        }
    },
    {
      dataField: "action",
      text: "Details",
      formatter: actionFormatter,
    },
  ];

  return (
    <div className="content">
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={employees}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <div>
                        <BootstrapTable
                          {...props.baseProps}
                          filter={filterFactory()}
                          pagination={paginationFactory()}
                          data={apiData}
                          headerClasses="sorted-asc sorted-desc"
                          wrapperClasses="table-responsive"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default JobrolemasterAll;
