import React, { useState, useEffect } from "react";
import "./style.css";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Progress,
} from "reactstrap";
import axios from "axios";
import { APIURL } from "./Apiurl.js";
import { toast } from "react-toastify";

function PartandCustomerAdd({ handleClose, fetchData }) {
  const [activeStep, setActiveStep] = useState(1);
  const [progress, setProgress] = useState(0);
  const [tenant, setTenantId] = useState("");

  const [end_customer_no, setEndCustomerNo] = useState("");
  const [end_customer_name, setEndCustomerName] = useState("");
  const [end_customer_location, setEndCustomerLocation] = useState("");
  const [part_number, setPartNumber] = useState("");
  const [partNameDescription, setPartNameDescription] = useState("");
  const [drawing, setDrawing] = useState("");
  const [revisionNo, setRevisionNo] = useState("");
  const [revisionNoDate, setRevisionDate] = useState("");
  const [pfd, setPfd] = useState("");
  const [processPlan, setProcessPlanStagewise] = useState("");
  const [packingStandard, setPackingStd] = useState("");
  const [qualityPlan, setQualityPlanPdir] = useState("");
  const [ppap, setPpap] = useState("");
  const [ppapRevisionDate, setPpapRevisionDate] = useState("");
  const [materialSpecification, setMaterialSpecification] = useState("");
  const [thickness, setThickness] = useState("");
  const [toolOrDieId, setToolOrDieId] = useState("");
  const [tagId, setTagId] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [isLastStep, setIsLastStep] = useState(false);
  const [isCardOpen, setIsCardOpen] = useState(true);

  useEffect(() => {}, []);

  const handleNext = () => {
    if (activeStep === 1) {
      setProgress(100);
    }
    setActiveStep(activeStep + 1);
    if (activeStep + 1 === 3) {
      setIsLastStep(true);
    }
  };
  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
    setIsLastStep(false);
  };
  const handlesaveandClose = (event) => {
    event.preventDefault(); // Ensure event is defined
    setIsCardOpen(false);
  };
  const token = sessionStorage.getItem("token");
  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const tenantId =
    decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"]; //logged in tenat

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const token = sessionStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["role_id"];

    let logintenantId = null;
    if (roleId === 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-tenant-id"];
    }

    const formData = {
      tenantid: tenantId,
      end_customer_name: end_customer_name,
      end_customer_location: end_customer_location,
      part_number: part_number,
      part_name_description: partNameDescription,
      drawing: drawing,
      revision_no: revisionNo,
      revisionno_date: revisionNoDate,
      pfd: pfd,
      process_plan_stagewise: processPlan,
      paking_std: packingStandard,
      quality_plan_pdir: qualityPlan,
      ppap: ppap,
      ppap_revision_date: ppapRevisionDate,
      material_specification: materialSpecification,
      thickness: thickness,
      toolidordieid: toolOrDieId,
      tag_id: tagId,

    };
    axios
      .post(
        APIURL,
        {
          query: `
            mutation InsertOrganizationStructure($formData: tnt_orgnization_partscustomer_master_insert_input!) {
                insert_tnt_orgnization_partscustomer_master(objects: [$formData]) {
                affected_rows
                }
            }
        `,
          variables: {
            formData: formData,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-hasura-admin-secret": process.env.REACT_APP_X_API_SECRET_KEY, // Replace with your Hasura admin secret
          },
        }
      )
      .then((response) => {
        console.log(response); // Handle the response data as needed
        if (
          response.data.data &&
          response.data.data.insert_tnt_orgnization_partscustomer_master
            .affected_rows > 0
        ) {
          setTenantId("");
          setEndCustomerNo("");
          setEndCustomerName("");
          setEndCustomerLocation("");
          setPartNumber("");
          setPartNameDescription("");
          setDrawing("");
          setRevisionNo("");
          setRevisionDate("");
          setPfd("");
          setProcessPlanStagewise("");
          setPackingStd("");
          setQualityPlanPdir("");
          setPpap("");
          setThickness("");
          setToolOrDieId("");
          setTagId("");
          setCreatedBy("");
          setProgress(0);
          setActiveStep(1);
          toast.success("Data inserted successfully!");
          fetchData();
        } else {
          toast.error("Failed to insert data.");
        }
      })
      .catch((error) => {
        console.error(error); // Handle the error
        toast.error("An error occurred while inserting data.");
      });
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case 1:
        return (
          <>
            <Row>
              <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="end_customer_name" className="plan-admin">End Customer Name</Label>
                  <Input
                    type="text"
                    value={end_customer_name}
                    onChange={(e) => setEndCustomerName(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="end_customer_location" className="plan-admin">
                    End Customer Location
                  </Label>
                  <Input
                    placeholder=""
                    type="text"
                    value={end_customer_location}
                    onChange={(e) => setEndCustomerLocation(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="partno" className="plan-admin">Part No</Label>
                  <Input
                    type="text"
                    id="orgtype"
                    value={part_number}
                    onChange={(e) => setPartNumber(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="6">
                <FormGroup>
                  <FormGroup>
                    <Label for="partNameDescription" className="plan-admin">
                      Part Name Description
                    </Label>
                    <Input
                      placeholder=""
                      type="text"
                      value={partNameDescription}
                      onChange={(e) => setPartNameDescription(e.target.value)}
                    />
                  </FormGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="orgtype" className="plan-admin">Drawing</Label>
                  <Input
                    type="text"
                    value={drawing}
                    onChange={(e) => setDrawing(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="tenantId" className="plan-admin">Revision Number</Label>
                  <Input
                    type="text"
                    value={revisionNo}
                    onChange={(e) => setRevisionNo(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="" className="plan-admin">Revision Number Date</Label>
                  <Input
                    type="date"
                    value={revisionNoDate}
                    onChange={(e) => setRevisionDate(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="pfd" className="plan-admin">P.F.D</Label>
                  <Input
                    type="text"
                    value={pfd}
                    onChange={(e) => setPfd(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        );
      case 2:
        return (
          <>
            <Row>
              <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="processPlan" className="plan-admin">Process Plan Stage Wise</Label>
                  <Input
                    type="text"
                    value={processPlan}
                    onChange={(e) => setProcessPlanStagewise(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="part_name_description" className="plan-admin">Packing Standard</Label>
                  <Input
                    placeholder=""
                    type="text"
                    value={packingStandard}
                    onChange={(e) => setPackingStd(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="qualityPlan" className="plan-admin">Tool ID / Die ID</Label>
                  <Input
                    type="text"
                    value={toolOrDieId}
                    onChange={(e) => setToolOrDieId(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="ppap" className="plan-admin">Tag ID</Label>
                  <Input
                    placeholder=""
                    type="text field"
                    value={tagId}
                    onChange={(e) => setTagId(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="qualityPlan" className="plan-admin">Quality Plan PDIR</Label>
                  <Input
                    type="text"
                    value={qualityPlan}
                    onChange={(e) => setQualityPlanPdir(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="ppap" className="plan-admin">PPAP</Label>
                  <Input
                    placeholder=""
                    type="text field"
                    value={ppap}
                    onChange={(e) => setPpap(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="" className="plan-admin">PPAP Revision Date</Label>
                  <Input
                    placeholder=""
                    type="date"
                    value={ppapRevisionDate}
                    onChange={(e) => setPpapRevisionDate(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="material_specification" className="plan-admin">
                    Material Specification
                  </Label>
                  <Input
                    type="text"
                    value={materialSpecification}
                    onChange={(e) => setMaterialSpecification(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="thickness" className="plan-admin">Thickness</Label>
                  <Input
                    type="text"
                    value={thickness}
                    onChange={(e) => setThickness(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row></Row>
          </>
        );
      default:
        return null;
    }
  };
  const calculateProgress = () => {
    return Math.round(((activeStep - 1) / 2) * 100); // Update the denominator to match the total number of steps minus one
  };

  const renderProgressBarDestinations = () => {
    const step1Class = activeStep >= 1 ? "progress-destination-active" : "";
    const step2Class = activeStep >= 2 ? "progress-destination-active" : "";
    const step3Class = activeStep >= 3 ? "progress-destination-active" : "";
    return (
      <div className="progress-destinations">
        <div className={`progress-destination ${step1Class}`}>
          <span>1</span>
        </div>
        <div className={`progress-destination ${step2Class}`}>
          <span>2</span>
        </div>
        <div className={`progress-destination ${step3Class}`}>
          <span>3</span>
        </div>
      </div>
    );
  };
  const renderStepButtons = () => {
    if (activeStep === 1) {
      return (
        <>
          <Button color="danger" onClick={handleClose}>
            Cancel
          </Button>

          <Button color="primary" onClick={handleNext}>
            Next
          </Button>
        </>
      );
    } else if (activeStep === 2) {
      return (
        <>
          <Button color="danger" onClick={handleClose}>
            Cancel
          </Button>
          <Button color="primary" onClick={handlePrevious}>
            Previous
          </Button>
          <Button
            color="success"
            onClick={(event) => {
              handleFormSubmit(event);
              handlesaveandClose(event);
              handleClose(event);
            }}
          >
            Save
          </Button>
        </>
      );
    }
  };
  return (
    <div className="content">
      <Row>
        {isCardOpen && (
          <Col md="8">
            <div className="progress-container">
              {renderProgressBarDestinations()}
              <Progress
                max="100"
                value={calculateProgress()}
                className="progress-bar-sm progress-bar-striped"
              />
            </div>
          </Col>
        )}
      </Row>
      <Row className="progress-row">
        {isCardOpen && (
          <Col md="8">
            <Card>
              <CardHeader>
                <h4 className="title" style={{color:"#ffffff"}}>Create New Part and Customer
                <button type="button" className="close" aria-label="Close" 
                    style={{
                    // float: "right", 
                    background: "transparent",
                    border: "none",
                    color: "#ffffff" ,
                    fontSize: "35px",
                    cursor: "pointer",
                    marginTop: "-40px",
                    }}
                    onClick={handleClose}
                    >
                    &times;
                    </button>
                </h4>
              </CardHeader>
              <CardBody>
                <Form>{renderStepContent()}</Form>
              </CardBody>
              <CardFooter>
                <div className="text-right">{renderStepButtons()}</div>
              </CardFooter>
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
}
export default PartandCustomerAdd;
