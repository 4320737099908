import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import redirectToLoginIfTokenMissing from "views/TokenUtility";
import { QueryApi } from "QueryApi";
import classnames from "classnames";
import JindalTableComponent from "../JindalTableComponent";

function PowerConsumptionGrids(props) {
  const [activeTab, setActiveTab] = useState("1");
  const storedTenantId = sessionStorage.getItem("selectedTenantId");
  useEffect(() => {
    redirectToLoginIfTokenMissing();
  }, []);
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const [powerConsumption, setPowerConsumption] = useState([]);
  useEffect(() => {
    PowerConsumptionData();
  }, []);
  const PowerConsumptionData = async () => {
    //  day data API
    try {
      const data = await QueryApi(`
      query MyQuery {
        bopp_power_consumption(order_by: {date: desc,line_id: asc}) {
          date
          department_id
          deviation
          line_id
          org_id
          power_consumption
          product_id
          production_mt
          shiftid
          target
          tenantid
          timestamp
          unit_id
          upt
          org {
            organization_node_name
          }
          unit {
            organization_node_name
          }
          dept {
            organization_node_name
            organization_structure_id
          }
        }
      }`);

      setPowerConsumption(data.bopp_power_consumption);
    } catch (error) {}
  };
  /////////////////////////////////////////////////////////
  const datewiseconsumption = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "Date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "unit.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "line_id",
      text: "Line",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "power_consumption",
      text: "Power Consumption",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        return cellContent + " Units";
      },
    },
    {
      dataField: "production_mt",
      text: "Production MT",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        return cellContent + " Mt";
      },
    },
    {
      dataField: "upt",
      text: "UPT",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        return cellContent + " Units";
      },
    },
    {
      dataField: "target",
      text: "Target",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        return cellContent + "";
      },
    },
  ];
  const shiftwiseconsumption = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "Date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "shiftid",
      text: "shift",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "unit.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "line_id",
      text: "Line",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "power_consumption",
      text: "Power Consumption",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        return cellContent + " Units";
      },
    },
    {
      dataField: "production_mt",
      text: "Production MT",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        return cellContent + " Mt";
      },
    },
    {
      dataField: "upt",
      text: "UPT",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        return cellContent + " Units";
      },
    },
    {
      dataField: "target",
      text: "Target",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        return cellContent + "";
      },
    },
  ];
  const deviation = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "Date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "unit.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "line_id",
      text: "Line",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "deviation",
      text: "Deviation",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        return cellContent ? `${cellContent} Units` : "No Data";
      },
    },
    {
      dataField: "power_consumption",
      text: "Power Consumption",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        return cellContent + " Units";
      },
    },
    {
      dataField: "production_mt",
      text: "Production MT",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        return cellContent + " Mt";
      },
    },
    {
      dataField: "upt",
      text: "UPT",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        return cellContent + " Units";
      },
    },
    {
      dataField: "target",
      text: "Target",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        return cellContent + "";
      },
    },
  ];
  const machineWiseConsumption = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "Date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "line_id",
      text: "Line",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "unit.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "power_consumption",
      text: "Power Consumption",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        return cellContent + " Units";
      },
    },
    {
      dataField: "production_mt",
      text: "Production MT",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        return cellContent + " Mt";
      },
    },
    {
      dataField: "upt",
      text: "UPT",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        return cellContent + " Units";
      },
    },
    {
      dataField: "target",
      text: "Target",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        return cellContent + "";
      },
    },
  ];

  return (
    <>
      <div className="content">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => toggleTab("1")}
              style={{
                backgroundColor: activeTab === "1" ? "#5555e7" : "transparent",
                color: activeTab === "1" ? "white" : "white",
              }}
            >
              Date Wise Power Consumption
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => toggleTab("2")}
              style={{
                backgroundColor: activeTab === "2" ? "#5555e7" : "transparent",
                color: activeTab === "2" ? "white" : "white",
              }}
            >
              Shift Wise Power Consumption
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => toggleTab("3")}
              style={{
                backgroundColor: activeTab === "3" ? "#5555e7" : "transparent",
                color: activeTab === "3" ? "white" : "white",
              }}
            >
              Power Consumption Deviation
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "4" })}
              onClick={() => toggleTab("4")}
              style={{
                backgroundColor: activeTab === "4" ? "#5555e7" : "transparent",
                color: activeTab === "4" ? "white" : "white",
              }}
            >
              Line Wise Power Consumption
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col>
                <JindalTableComponent
                  columns={datewiseconsumption}
                  apiData={powerConsumption}
                />
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="2">
            <Row>
              <Col>
                <JindalTableComponent
                  columns={shiftwiseconsumption}
                  apiData={powerConsumption}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col>
                <JindalTableComponent
                  columns={deviation}
                  apiData={powerConsumption}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="4">
            <Row>
              <Col>
                <JindalTableComponent
                  columns={machineWiseConsumption}
                  apiData={powerConsumption}
                />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </>
  );
}

export default PowerConsumptionGrids;
