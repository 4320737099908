import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Progress,
} from "reactstrap";
import axios from "axios";
import { APIURL } from './Apiurl.js';
import { toast } from 'react-toastify';
import Papa from 'papaparse';

function CSVImporter({handleClose1}) {
  const [activeStep, setActiveStep] = useState(1);
  const [progress, setProgress] = useState(0);

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileData, setFileData] = useState(null);


 
  // ... Existing code for fetchOrgnames and fetchOrgNode

  // ... Existing code for handleNext, handlePrevious, and handleFormSubmit

  // Function to handle file change
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  // Function to submit the selected file
 // ... Existing imports and other code

// Function to submit the selected file
const handleFileSubmit = () => {
    if (!selectedFile) {
      toast.error("Please select a file to import.");
      return;
    }
  
    const token = sessionStorage.getItem('token');
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const roleId = decodedToken['sub'];
    let logintenantId = null;
    if(roleId == 1){
       logintenantId = sessionStorage.getItem('selectedTenantId');
    }else{
       logintenantId = decodedToken['https://hasura.io/jwt/claims']['x-hasura-org-id'];
    } 

    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      try {
        const data = fileReader.result;
        // Parse the CSV data using papaparse library
        const parsedData = Papa.parse(data, {
          header: true, // Use the first row as headers
          skipEmptyLines: true, // Skip empty lines in CSV
        });
        
  
        // Assuming your CSV has the following headers:
        // org_code, org_node_type, org_node_name, country_id, city_name, parent_org, cost_center
        const formData = parsedData.data.map((row) => ({
            tool_location_no: row.tool_location_no,
            tenantid: logintenantId,
            tag_number: row.tag_number,
            part_no: row.part_no,
            part_name: row.part_name,
            op_no: row.op_no,
            op_name: row.op_name,
            die_number: row.die_number,
            actual_part_id: row.actual_part_id,
        }));

        const token = sessionStorage.getItem('token');
  const decodedToken = JSON.parse(atob(token.split('.')[1]));
  const roleId = decodedToken['sub'];
  let logintenantId = null;
  if(roleId == 1){
     logintenantId = sessionStorage.getItem('selectedTenantId');
  }else{
     logintenantId = decodedToken['https://hasura.io/jwt/claims']['x-hasura-org-id'];
  }
  
        // Send the data to the Hasura API using axios
        if (formData.length > 0) { // Check if there is data to be inserted
          axios
            .post(APIURL, {
              query: `
                mutation Inserttnt_tool_and_die_master($formData: [tnt_tool_and_die_master_insert_input!]!) {
                  insert_tnt_tool_and_die_master(objects: $formData) {
                    affected_rows
                  }
                }
              `,
              variables: {
                formData: formData,
              },
            },
            {
              headers: {
                'Content-Type': 'application/json',
                 Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
              },
            })
            .then((response) => {
              console.log(response); // Handle the response data as needed
              if (response.data.data && response.data.data.insert_tnt_tool_and_die_master.affected_rows > 0) {
                setFileData(formData);
                toast.success("CSV data uploaded successfully!");
              } else {
                toast.error("Failed to insert data.");
              }
            })
            .catch((error) => {
              console.error(error); // Handle the error
              toast.error("An error occurred while uploading data.");
            });
        } else {
          toast.warning("No data found in the CSV file.");
        }
      } catch (error) {
        console.error(error);
        toast.error("Error parsing the file. Please check the format.");
      }
    };
  
    fileReader.readAsText(selectedFile);
  };
  

  return (
    <div className="content">
      <Row className="progress-row">
        <Col md="6">
          <Card>
            <CardHeader>
              <h5 className="title" style={{color:"#ffffff"}}>Import CSV File </h5>
              <button type="button" className="close" aria-label="Close" 
              style={{
              float: "right", 
              background: "transparent",
              border: "none",
              color: "#ffffff" ,
              fontSize: "35px",
              cursor: "pointer",
              marginTop: "-75px",
             }}
              onClick={handleClose1}
              >
              &times;
              </button>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                <Col className="px-md-1" md="8">
                    <FormGroup style={{flexGrow: 1, display: 'flex', justifyContent: 'center',marginLeft: "20px"}}>
                    
                      <Label for="fileInput" className="custom-file-label">
                        {selectedFile ? selectedFile.name : "Choose CSV file"}
                      </Label>
                      <Input
                        type="file"
                        id="fileInput"
                        className="custom-file-input"
                        accept=".csv"
                        onChange={handleFileChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="px-md-1" md="4">
                    <FormGroup>
                      <div className="">
                        <Button className="" style={{ marginTop: "-1px", }} color="success" onClick={handleFileSubmit}>
                          Submit CSV
                        </Button>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              
                {/* <Row>
                 
                  <Col className="px-md-1" md="6">
                    <FormGroup>
                      <div className="">
                        <Button className="" style={{float: "right", marginTop: "-11px", marginRight: "-350px " }} color="success" onClick={handleFileSubmit}>
                          Submit CSV
                        </Button>
                      </div>
                    </FormGroup>
                  </Col>
                </Row> */}
              </Form>
            </CardBody>
            <CardFooter>
              {/* Rest of the card footer */}
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default CSVImporter;



// import React, { useState } from "react";
// import { Button, FormGroup, Label, Input,Card } from "reactstrap";
// import axios from "axios";
// import { APIURL } from './Apiurl.js';
// import { toast } from 'react-toastify';
// import Papa from 'papaparse';

// function CSVImporter({ mutation, mapData }) {
//   const [selectedFile, setSelectedFile] = useState(null);

//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     setSelectedFile(file);
//   };

//   const handleFileSubmit = () => {
//     if (!selectedFile) {
//       toast.error("Please select a file to import.");
//       return;
//     }

//     const fileReader = new FileReader();
//     fileReader.onloadend = () => {
//       try {
//         const data = fileReader.result;
//         const parsedData = Papa.parse(data, { header: true, skipEmptyLines: true });
//         const formData = parsedData.data.map(mapData);

//         const token = sessionStorage.getItem('token');

//         if (formData.length > 0) {
//           axios.post(APIURL, {
//             query: mutation,
//             variables: { formData },
//           }, {
//             headers: {
//               'Content-Type': 'application/json',
//               Authorization: `Bearer ${token}`,
//             },
//           })
//           .then((response) => {
//             if (response.data.data && response.data.data.insert_tnt_tool_and_die_master.affected_rows > 0) {
//               toast.success("CSV data uploaded successfully!");
//             } else {
//               toast.error("Failed to insert data.");
//             }
//           })
//           .catch((error) => {
//             console.error(error);
//             toast.error("An error occurred while uploading data.");
//           });
//         } else {
//           toast.warning("No data found in the CSV file.");
//         }
//       } catch (error) {
//         console.error(error);
//         toast.error("Error parsing the file. Please check the format.");
//       }
//     };

//     fileReader.readAsText(selectedFile);
//   };

//   return (
//     <Card className="content" style={{height:'40%', display: 'flex', flexDirection: 'column', justifyContent: 'center',right:"10px"}}>
//   <FormGroup style={{flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '70%', margin: '0 auto',marginTop:"50px"}}>
//     <Label for="fileInput" className="custom-file-label">
//       {selectedFile ? selectedFile.name : "Choose CSV file"}
//     </Label>
//     <Input
//       type="file"
//       id="fileInput"
//       className="custom-file-input"
//       accept=".csv"
//       onChange={handleFileChange}
//     />
//   </FormGroup>
//   <div style={{display: 'flex', justifyContent: 'flex-end',marginRight:"30px",marginBottom:"10px"}}>
//     <Button color="success" onClick={handleFileSubmit}>
//       Submit CSV
//     </Button>
//   </div>
// </Card>
// //     <Card className="content" style={{height:'40%', display: 'flex', flexDirection: 'column'}}>
// //   <FormGroup style={{flexGrow: 1, display: 'flex', justifyContent: 'center'}}>
// //     <Label for="fileInput" className="custom-file-label">
// //       {selectedFile ? selectedFile.name : "Choose CSV file"}
// //     </Label>
// //     <Input
// //       type="file"
// //       id="fileInput"
// //       className="custom-file-input"
// //       accept=".csv"
// //       onChange={handleFileChange}
// //     />
// //   </FormGroup>
// //   <div style={{display: 'flex', justifyContent: 'flex-end'}}>
// //     <Button color="success" onClick={handleFileSubmit}>
// //       Submit CSV
// //     </Button>
// //   </div>
// // </Card>
// //     <Card className="content" style={{height:'40%'}}>
// //     <FormGroup>
// //       <Label for="fileInput" className="custom-file-label">
// //         {selectedFile ? selectedFile.name : "Choose CSV file"}
// //       </Label>
// //       <Input
// //         type="file"
// //         id="fileInput"
// //         className="custom-file-input"
// //         accept=".csv"
// //         onChange={handleFileChange}
// //       /> 
// //     </FormGroup>
// //     <div style={{display: 'flex', justifyContent: 'flex-end'}}>
// //     <Button color="success" onClick={handleFileSubmit}>
// //       Submit CSV
// //     </Button>
// //   </div>
// //     </Card>
//   );
// }

// export default CSVImporter;