import React from "react";
import "./style.css";
import "./responsive.css";
import { Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import ManageRoleAll from "./ManageRoleAll";
import ManageRoleAdd from "./ManageRoleAdd";

function ManagePeople(props) {
  const [activeTab, setActiveTab] = useState("1");

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const getBreadcrumbItemText = (tabId) => {
    switch (tabId) {
      case "1":
        return "Role";
      case "2":
        return "Add Role";
      default:
        return "Role";
    }
  };
  return (
    <>
      <div className="content">
        <div class="container1">
          <h1 className="main-heading">Manage Role</h1>
          <div class="breadcrumb-container">
            <Breadcrumb>
              <BreadcrumbItem>
                <FontAwesomeIcon icon={faHouse} />
              </BreadcrumbItem>
              <BreadcrumbItem>Configuration Role</BreadcrumbItem>
              <BreadcrumbItem active>
                {getBreadcrumbItemText(activeTab)}
              </BreadcrumbItem>
            </Breadcrumb>
          </div>
        </div>

        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => toggleTab("1")}
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor: activeTab === "1" ? "#5555e7" : "transparent",
                color: activeTab === "1" ? "white" : "white",
              }}
            >
              Role List
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => toggleTab("2")}
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor: activeTab === "2" ? "#5555e7" : "transparent",
                color: activeTab === "2" ? "white" : "white",
              }}
            >
              Add Role
            </NavLink>
          </NavItem>
          <NavItem></NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col>
                <ManageRoleAll />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col>
                <ManageRoleAdd />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </>
  );
}

export default ManagePeople;
