import React, { useState } from "react";
import axios from "axios";
import "./login.css";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const [name, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const apiURL = process.env.REACT_APP_API_URL;
  console.log(apiURL);
  // sessionStorage.setItem('token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwibmFtZSI6Ik5pc2FyZyBOYXZhbGUiLCJlbWFpbCI6Im5pc2FyZ0BnbWFpbC5jb20iLCJyb2xlX2lkIjoxLCJodHRwczovL2hhc3VyYS5pby9qd3QvY2xhaW1zIjp7IngtaGFzdXJhLWFsbG93ZWQtcm9sZXMiOlsidXNlciJdLCJ4LWhhc3VyYS1kZWZhdWx0LXJvbGUiOiJ1c2VyIiwieC1oYXN1cmEtdXNlci1pZCI6IjEiLCJ4LWhhc3VyYS10ZW5hbnQtaWQiOm51bGx9LCJpYXQiOjE2OTIzNTYxMjMsImV4cCI6MTY5MjM1OTcyM30.dj6KtzKpDIvWZZyDKo-yjDHU01_drwLv1RMCh77fczs');
  // console.log(sessionStorage.getItem('token'));
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(apiURL, { name, password });
      const { token } = response.data;
      console.log(response.data);

      sessionStorage.setItem("token", token);
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const roleId = decodedToken["sub"];
      sessionStorage.setItem("roleId", roleId);
      const logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
      sessionStorage.setItem("tenantid", logintenantId);
      if (logintenantId === "29") {
        navigate("/admin/Extrusion");
      } else {
        navigate("/admin/dashboard");
      }
    } catch (error) {
      // Handle login error
      setErrorMessage("Invalid email or password");
    }
  };
  return (
    <div className="login-page sidebar-collapse">
      <div className="page-header clear-filter" filter-color="orange">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url('https://images.unsplash.com/photo-1438783122414-75b2c084bd44?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2378&q=80 2378w')",
          }}
        ></div>
        <div className="content">
          <div className="container">
            <div className="col-md-4 ml-auto mr-auto" id="custom-change">
              <div className="card card-login card-plain">
                <form className="form" onSubmit={handleLogin}>
                  <div className="card-header text-center">
                    <div className="logo-container">
                      {/* <img src="https://www.humac.dk/sites/default/files/2018-11/humac_logo_RGB.png" alt="" /> */}
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="input-group no-border input-lg">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="now-ui-icons users_circle-08"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Contact No."
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="input-group no-border input-lg">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="now-ui-icons text_caps-small"></i>
                        </span>
                      </div>
                      <input
                        type="password"
                        placeholder="Password"
                        className="form-control"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="card-footer text-center">
                    <button
                      type="submit"
                      id="loginbutton"
                      className="btn btn-primary btn-round btn-lg btn-block"
                    >
                      Login
                    </button>
                    {errorMessage && (
                      <p className="text-danger">{errorMessage}</p>
                    )}
                    {/* <a href="/admin/dashboard" id="loginbutton" style={{background: '#b2b108 !important'}} className="btn btn-primary btn-round btn-lg btn-block">Login</a> */}
                    <div className="pull-left">
                      {/* <h6>
                        <a href="#pablo" className="link">Create Account</a>
                      </h6> */}
                    </div>
                    <div className="pull-right">
                      {/* <h6>
                        <a href="#pablo" className="link">Need Help?</a>
                      </h6> */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
