import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Progress,
} from "reactstrap";
import axios from "axios";
import  { APIURL } from './Apiurl.js';
import { toast } from 'react-toastify';
import { useParams } from "react-router-dom";

function JobrolemasterAdd(props) {
  const [activeStep, setActiveStep] = useState(1);
  const [progress, setProgress] = useState(0);

  const { id } = useParams();

  const [tenantId, setTenantId] = useState("");
  const [organization_node_id, setOrganizationNodeId] = useState("");
  const [organization_node_name, setOrganizationNodeName] = useState("");
  const [organization_shortname, setOrganizationShortName] = useState("");
  const [organization_code, setOrganizationCode] = useState("");
  const [description, setDescription] = useState("");
  const [organization_type, setOrganizationType] = useState("");
  const [organization_category_id, setOrganizationCategoryId] = useState("");
  const [country_id, setCountryId] = useState("");
  const [state_id, setStateId] = useState("");
  const [city_id, setCityId] = useState("");
  const [parent_organization_id, setParentOrganizationId] = useState("");
  const [cost_centre_code, setCostCentreCode] = useState("");
  const [organization_node_leader, setOrganizationNodeLeader] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [orgnodes, setOrgNodes] = useState([]);
  const [orgTypes, setOrgTyes] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [orgcats, setOrgCats] = useState([]);
  const [orgnames, setOrgnames] = useState([]);

  useEffect(() => {
    fetchTenants();
    fetchOrgNode();
    fetchTypes();
    fetchCats();
    fetchOrgnames();
    fetchRecordToEdit();
  }, []);

  const fetchOrgnames = async () => {
    try {
      const response = await axios.post(APIURL, {
        query: `
          query {
            tnt_organization_structure {
                organization_structure_id
                organization_shortname
              }
          }
        `,
      });

      setOrgnames(response.data.data.tnt_organization_structure);
      console.log(response.data.data.tnt_organization_structure);
    } catch (error) {
      console.error(error);
    }
    
  };
  const fetchCats = async () => {
    try {
      const response = await axios.post(APIURL, {
        query: `
        query MyQuery {
            organization_category {
              organization_category_id
              organization_category
            }
          }
        `,
      });
  
      setOrgCats(response.data.data.organization_category); // Make sure 'organization_category' is an array
    } catch (error) {
      console.error(error);
    }
  };
  const fetchOrgNode = async () => {
    try {
      const response = await axios.post(APIURL, {
        query: `
          query {
            organization_node {
                organization_node_id
                organization_node_name
              }
          }
        `,
      });

      setOrgNodes(response.data.data.organization_node);
      console.log(response.data.data.organization_node);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchTenants = async () => {
    try {
      const response = await axios.post(APIURL, {
        query: `
          query {
            tenants {
              tenant_id
              tenant_name
            }
          }
        `,
      });

      setTenants(response.data.data.tenants);
      console.log(response.data.data.tenants);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchTypes = async () => {
    try {
      const response = await axios.post(APIURL, {
        query: `
          query {
            organization_type {
              organization_type_id
              organization_type
            }
          }
        `,
      });

      setOrgTyes(response.data.data.organization_type);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchRecordToEdit = async () => {
    try {
      // Use the 'id' obtained from the URL in your API call
      const response = await axios.post(APIURL, {
        query: `
          query GetOrganizationStructure($id: Int!) {
            tnt_organization_structure_by_pk(organization_structure_id: $id) {
              tenant_id
              organization_node_id
              organization_node_name
              organization_shortname
              organization_code
              description
              organization_type
              organization_category_id
              country_id
              state_id
              city_id
              parent_organization_id
              cost_centre_code
              organization_node_leader
              created_by
            }
          }
        `,
        variables: { id: parseInt(id) }, // Use the 'id' parameter from useParams
      });
      const record = response.data.data.tnt_organization_structure_by_pk;
      setTenantId(record.tenant_id);
      setOrganizationNodeId(record.organization_node_id);
      setOrganizationNodeName(record.organization_node_name);
      setOrganizationShortName(record.organization_shortname);
      setOrganizationCode(record.organization_code);
      setDescription(record.description);
      setOrganizationType(record.organization_type_id);
      setOrganizationCategoryId(record.organization_category_id);
      setCountryId(record.country_id);
      setStateId(record.state_id);
      setCityId(record.city_id);
      setParentOrganizationId(record.parent_organization_id);
      setCostCentreCode(record.cost_centre_code);
      setOrganizationNodeLeader(record.organization_node_leader);
      setCreatedBy(record.created_by);
      console.log(record);

    } catch (error) {
      console.error(error);
    }
  };

  const handleOrgNodeChange = (e) => {
    const selectedNodeName = e.target.options[e.target.selectedIndex].text;
    setOrganizationNodeName(selectedNodeName);
  };

  const handleNext = () => {
    if (activeStep === 1 && progress === 0) {
      setProgress(50);
    } else if (activeStep === 2 && progress === 50) {
      setProgress(100);
    }
    setActiveStep(activeStep + 1);
  };

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    
    // Create an object with the form data
    const formData = {
      tenant_id: tenantId,
      organization_node_id: organization_node_id,
      organization_node_name: organization_node_name,
      organization_shortname: organization_shortname,
      organization_code: organization_code,
      description: description,
      organization_category_id: organization_category_id,  
      country_id: country_id,
      state_id: state_id,
      city_id: city_id,
      parent_organization_id: parent_organization_id,
      cost_centre_code: cost_centre_code,
      organization_node_leader: organization_node_leader,
      organization_type: organization_type,
      created_by: createdBy,
      is_active: "1",
    };
    console.log(formData);
    axios
    .post(APIURL, {
        query: `
            mutation UpdateOrganizationStructure($id: Int!, $formData: tnt_organization_structure_set_input!) {
            update_tnt_organization_structure_by_pk(pk_columns: { organization_structure_id: $id }, _set: $formData) {
              organization_structure_id
            }
          }
        `,
        variables: {
        id: parseInt(id),
          formData: formData,
        },
      })
      .then((response) => {
        console.log(response); // Handle the response data as needed
        console.log(response.data);
        const updatedStructure = response.data?.data?.update_tnt_organization_structure_by_pk;
        if (updatedStructure) {
            console.log("hello");
            setProgress(0);
            setActiveStep(1);
          toast.success('Data Updated successfully!');
        } else {
          toast.error('Failed to insert data.');
        }
      })
      .catch((error) => {
        console.error(error); // Handle the error
        toast.error('An error occurred while inserting data.');
      });
    };

  const renderStepContent = () => {
    switch (activeStep) {
        case 1:
          return (
            <>
              <Row>
               
                <Col className="px-md-1" md="6">
                    <FormGroup>
                    <Label for="tenantId">Tenant ID</Label>
                    <Input
                        type="select"
                        id="tenantId"
                        value={tenantId}
                        onChange={(e) => setTenantId(e.target.value)}
                    >
                        <option value="">Select Tenant ID</option>
                        {tenants.map((tenant) => (
                        <option key={tenant.tenant_id} value={tenant.tenant_id}>
                            {tenant.tenant_name}
                        </option>
                        ))}
                    </Input>
                    </FormGroup>
                </Col>
                <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="orgnodeid">Organization Node ID</Label>
                  <Input
                    type="select"
                    id="orgnodeid"
                    value={organization_node_id}
                    onChange={(e) => {
                        setOrganizationNodeId(e.target.value);
                        handleOrgNodeChange(e); // Call handleOrgNodeChange function here
                      }}    
                  >
                    <option value="">Select Organization Node ID</option>
                    {orgnodes.map((orgnode) => (
                      <option
                        key={orgnode.organization_node_id}
                        value={orgnode.organization_node_id}
                      >
                        {orgnode.organization_node_name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              </Row>
              <Row>
                <Col className="px-md-1" md="6">
                    <FormGroup>
                    <Label for="organization_node_name">Organization Node Name</Label>
                    <Input
                        placeholder="Organization Node Name"
                        type="text"
                        value={organization_node_name}
                        onChange={(e) => setOrganizationNodeName(e.target.value)}
                    />
                    </FormGroup>
                </Col>
                <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="organization_shortname">Organization Short name</Label>
                  <Input
                    placeholder="Organization Short name"
                    type="text"
                    value={organization_shortname}
                    onChange={(e) => setOrganizationShortName(e.target.value)}
                  />
                </FormGroup>
              </Col>
              </Row>
              <Row>
              <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="organization_code">Organization Code</Label>
                  <Input
                    placeholder="Organization Code"
                    type="text"
                    value={organization_code}
                    onChange={(e) => setOrganizationCode(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="description">Description</Label>
                  <Input
                    placeholder="Description"
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </FormGroup>
              </Col>
              </Row>
            </>
          );
        case 2:
          return (
            <>
             <Row>
               <Col className="px-md-1" md="6">
                   <FormGroup>
                   <Label for="orgtype">Organization Type</Label>
                   <Input
                       type="select"
                       id="orgtype"
                       value={organization_type}
                       onChange={(e) => setOrganizationType(e.target.value)}
                   >
                       <option value="">Select Organization Type</option>
                       {orgTypes.map((orgtype) => (
                       <option key={orgtype.organization_type_id} value={orgtype.organization_type_id}>
                           {orgtype.organization_type}
                       </option>
                       ))}
                   </Input>
                   </FormGroup>
               </Col>
              
               <Col className="px-md-1" md="6">
                    <FormGroup>
                    <Label for="tenantId">Organization Category ID</Label>
                    <Input
                        type="select"
                        id="tenantId"
                        value={organization_category_id}
                        onChange={(e) => setOrganizationCategoryId(e.target.value)}
                    >
                        <option value="">Select Org Category ID</option>
                        {orgcats.map((orgcat) => (
                        <option key={orgcat.organization_category_id} value={orgcat.organization_category_id}>
                            {orgcat.organization_category}
                        </option>
                        ))}
                    </Input>
                    </FormGroup>
                </Col>
              
             </Row>
             <Row>
                <Col className="px-md-1" md="6">
                   <FormGroup>
                   <Label for="orgtype">Country</Label>
                   <Input
                       type="select"
                       id="orgtype"
                       value={country_id}
                       onChange={(e) => setCountryId(e.target.value)}
                   >
                       <option value="">Select Country</option>
                       <option value="1">India</option>
                       <option value="2">USA</option>
                       <option value="3">England</option>
                       <option value="4">Bangladesh</option>
                       <option value="5">Shrilanka</option>
                      
                       
                   </Input>
                   </FormGroup>
               </Col>
               <Col className="px-md-1" md="6">
                   <FormGroup>
                   <Label for="orgtype">State</Label>
                   <Input
                       type="select"
                       id="orgtype"
                       value={state_id}
                       onChange={(e) => setStateId(e.target.value)}
                   >
                       <option value="">Select State</option>
                       <option value="1">Maharashtra</option>
                       <option value="2">Odisa</option>
                       <option value="3">Bangal</option>
                       <option value="4">AP</option>
                       <option value="5">UP</option>
                      
                       
                   </Input>
                   </FormGroup>
               </Col>
             </Row>
             <Row>
             <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="city_id">City</Label>
                  <Input
                    placeholder="City ID"
                    type="text"
                    value={city_id}
                    onChange={(e) => setCityId(e.target.value)}
                  />
                </FormGroup>
              </Col>
             </Row>
            </>
          );
        case 3:
          return (
            <>
            <Row>
            <Col className="px-md-1" md="6">
                   <FormGroup>
                   <Label for="orgtype">Parent Organization</Label>
                   <Input
                       type="select"
                       id="orgtype"
                       value={parent_organization_id}
                       onChange={(e) => setParentOrganizationId(e.target.value)}
                   >
                       <option value="">Select Parent ID</option>
                       {orgnames.map((orgname) => (
                       <option key={orgname.organization_structure_id} value={orgname.organization_structure_id}>
                           {orgname.organization_shortname}
                       </option>
                       ))}
                   </Input>
                   </FormGroup>
               </Col>
               <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="cost_centre_code">Cost Centre Code</Label>
                  <Input
                    placeholder="Cost Centre Code"
                    type="text"
                    value={cost_centre_code}
                    onChange={(e) => setCostCentreCode(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="organization_node_leader">Organization Node Leader</Label>
                  <Input
                    placeholder="Organization Node Leader"
                    type="text"
                    value={organization_node_leader}
                    onChange={(e) => setOrganizationNodeLeader(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="6">
                  <FormGroup>
                    <label>Created By</label>
                    <Input
                      placeholder="Created By"
                      type="text"
                      value={createdBy}
                      onChange={(e) => setCreatedBy(e.target.value)}
                    />
                  </FormGroup>
                </Col>
            </Row>
            </>
          );
        default:
          return null;
      }
  };
  const calculateProgress = () => {
    return Math.round(((activeStep - 1) / 2) * 100); // Update the denominator to match the total number of steps minus one
  };
  

  const renderProgressBarDestinations = () => {
    const step1Class = activeStep >= 1 ? "progress-destination-active" : "";
    const step2Class = activeStep >= 2 ? "progress-destination-active" : "";
    const step3Class = activeStep >= 3 ? "progress-destination-active" : "";
    const step4Class = activeStep >= 4 ? "progress-destination-active" : "";
  
    return (
      <div className="progress-destinations">
        <div className={`progress-destination ${step1Class}`}>
          <span>1</span>
        </div>
        <div className={`progress-destination ${step2Class}`}>
          <span>2</span>
        </div>
        <div className={`progress-destination ${step3Class}`}>
          <span>3</span>
        </div>
        <div className={`progress-destination ${step4Class}`}>
          <span>4</span>
        </div>
      </div>
    );
  };


  return (
    <div className="content">
      <Row>
        <Col md="8">
          <div className="progress-container">
            {renderProgressBarDestinations()}
            <Progress
              max="100"
              value={calculateProgress()}
              className="progress-bar-sm progress-bar-striped"
            />
          </div>
        </Col>
      </Row>
      <Row className="progress-row">
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Organization Structure</h5>
            </CardHeader>
            <CardBody>
              <Form>{renderStepContent()}</Form>
            </CardBody>
            <CardFooter>
              <div className="text-right">
                {activeStep > 1 && (
                  <Button color="primary" onClick={handlePrevious}>
                    Previous
                  </Button>
                )}
                {activeStep < 3 && (
                  <Button color="primary" onClick={handleNext}>
                    Next
                  </Button>
                )}
                {activeStep === 3 && (
                  <Button color="success" onClick={handleFormSubmit}>
                    Submit
                  </Button>
                )}
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );

}

export default JobrolemasterAdd;
