import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  FormGroup,
  Input,
} from "reactstrap";
import classNames from "classnames";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import SlitEfficiencyDaySumPage from "./SlitEfficiencyDaySumPage";

function SlitEfficiencyDaySum({ tab1Component, tab2Component, tab3Component }) {
  const [activeTab, setActiveTab] = useState("1");

  return (
    <>
      <div className="content">
        <Row style={{ marginTop: "30px" }}>
          <Col lg="3">
            <Card
              className="Workforce-availability"
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                color: "white",
                height: "40px",
                display: "flex",
                alignItems: "left",
                justifyContent: "center",
              }}
            >
              I.F.A
            </Card>
          </Col>
          <Col lg="9">
            <Nav
              tabs
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                borderBottom: "none",
              }}
            >
              <NavItem style={{ flex: 1, padding: "0 10px" }}>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => setActiveTab("1")}
                  style={{
                    width: "100%",
                    textAlign: "center",
                    backgroundColor:
                      activeTab === "1" ? "white" : "transparent",
                    color: activeTab === "1" ? "blue" : "white",
                    borderRadius: "5px",
                    margin: "0px 20px",
                    border: "1px solid #D3D3D3",
                  }}
                >
                  Information
                </NavLink>
              </NavItem>
              <NavItem style={{ flex: 1, padding: "0 10px" }}>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => setActiveTab("2")}
                  style={{
                    width: "100%",
                    textAlign: "center",
                    backgroundColor:
                      activeTab === "2" ? "white" : "transparent",
                    color: activeTab === "2" ? "blue" : "white",
                    borderRadius: "5px",
                    margin: "0px 20px",
                    border: "1px solid #D3D3D3",
                  }}
                >
                  Focus
                </NavLink>
              </NavItem>
              <NavItem style={{ flex: 1, padding: "0 10px" }}>
                <NavLink
                  className={classnames({ active: activeTab === "3" })}
                  onClick={() => setActiveTab("3")}
                  style={{
                    width: "100%",
                    textAlign: "center",
                    backgroundColor:
                      activeTab === "3" ? "white" : "transparent",
                    color: activeTab === "3" ? "blue" : "white",
                    borderRadius: "5px",
                    margin: "0px 20px",
                    border: "1px solid #D3D3D3",
                  }}
                >
                  Action
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
        {activeTab === "1" ? (
          <SlitEfficiencyDaySumPage />
        ) : activeTab === "2" ? (
          tab2Component
        ) : activeTab === "3" ? (
          tab3Component
        ) : null}
      </div>
    </>
  );
}

export default SlitEfficiencyDaySum;
