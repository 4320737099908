import React, { useState, useEffect } from "react";
import "./style.css";
import "./responsive.css";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import redirectToLoginIfTokenMissing from "./TokenUtility";
import classnames from "classnames";
import DateWishIdleTime from "./DateWishIdleTime";
import ShiftWishIdleTime from "./ShiftWishIdleTime";
import MachineWiseIdleTime from "./MachineWiseIdleTime";
import OperatorWiseIdleTime from "./OperatorWiseIdleTime";
import classNames from "classnames";
import axios from "axios";
import ReactSpeedometer from "react-d3-speedometer";
import { APIURL } from "./Apiurl.js";
import { QueryApi } from "QueryApi";
import LineChart from "assets/charts/LineChart";

function ManagePeople(props) {
  const [activeTab, setActiveTab] = useState("1");
  const storedTenantId = sessionStorage.getItem("selectedTenantId");
  console.log("selected Tenant ID:", storedTenantId);
  useEffect(() => {
    redirectToLoginIfTokenMissing(); // Call the utility function to validate the token
    // Your component's logic...
  }, []);
  const [org, setorg] = useState("");
  const [orgnames, setOrgnames] = useState([]);

  const [unit, setunit] = useState("");

  const [dept, setdept] = useState("");
  const [depts, setdepts] = useState([]);

  const [plants, setplants] = useState([]);
  const [orgs, setorgs] = useState([]);
  const [otherData, setOtherData] = useState([]);
  const [selectedOrgData, setSelectedOrgData] = useState(null);
  const token = sessionStorage.getItem("token");
  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const tenantId =
    decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
  useEffect(() => {
    fetchplant();
  }, [org]);

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const currDay = day < 10 ? `0${day}` : day;
  const currMonth = month < 10 ? `0${month}` : month;
  let currentDate = JSON.stringify(`${year}-${currMonth}-${currDay}`);

  const fetchorg = async (logintenantId) => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery($logintenantId: Int!) {
          tnt_organization_structure(
            where: {
              parent_organization_id: { _eq: 0 },
              tenant_id: { _eq: $logintenantId }
            }
          ) {
            organization_structure_id
            organization_node_name
          }
        }
      `,
          variables: {
            logintenantId: parseInt(logintenantId),
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      const fetchedOrgs = response.data.data.tnt_organization_structure;
      setorgs(fetchedOrgs);
      console.log(fetchedOrgs);
      const parentIds = fetchedOrgs.map((org) => org.organization_structure_id);
      console.log(parentIds);

      const childResponse = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery($parentIds: [Int!]) {
          tnt_organization_structure(
            where: {
              parent_organization_id: { _in: $parentIds }
            }
          ) {
            organization_structure_id
            organization_node_name
          }
        }
      `,
          variables: {
            parentIds: parentIds,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      const childOrgs = childResponse.data.data.tnt_organization_structure;
      console.log(childOrgs);
      const updatedOtherData = otherData.map((row) => {
        const matchingOrg = childOrgs.find(
          (org) => org.organization_structure_id === row.parent_organization_id
        );

        if (matchingOrg) {
          return {
            ...row,
            parent_organization_id: matchingOrg.organization_structure_id,
          };
        }

        return row;
      });

      setOtherData(childOrgs);
      console.log(otherData);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["role_id"];
    let logintenantId = null;

    if (roleId === 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-tenant-id"];
    }

    fetchorg(logintenantId);
  }, []);
  const fetchplant = async () => {
    if (org) {
      // Only fetch if org is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
          query MyQuery($p: Int!) {
            tnt_organization_structure(where: {parent_organization_id: {_eq: $p}}) {
              organization_structure_id
              organization_node_name
            }
          }
        `,
            variables: {
              p: org,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        // console.log(org);
        setplants(response.data.data.tnt_organization_structure);
        console.log(response.data.data.tnt_organization_structure);
      } catch (error) {
        console.error(error);
      }
    }
  };
  useEffect(() => {
    fetchdept();
  }, [unit]);
  const fetchdept = async () => {
    if (unit) {
      // Only fetch if org is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
          query MyQuery($d: Int!) {
            tnt_organization_structure(where: {parent_organization_id: {_eq: $d}}) {
              organization_structure_id
              organization_node_name
            }
          }
        `,
            variables: {
              d: unit,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        // console.log(org);
        setdepts(response.data.data.tnt_organization_structure);
        console.log(response.data.data.tnt_organization_structure);
      } catch (error) {
        console.error(error);
      }
    }
  };
  console.log(process.env.REACT_APP_X_API_SECRET_KEY);

  const handleOrgSelect = async (selectedOrgId) => {
    if (selectedOrgId) {
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
          query GetCostCenter($orgId: Int!) {
            tnt_organization_structure(where: { organization_structure_id: { _eq: $orgId } }) {
              cost_centre_code
            }
          }
        `,
            variables: {
              orgId: selectedOrgId,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );

        if (
          response.data &&
          response.data.data &&
          response.data.data.tnt_organization_structure &&
          response.data.data.tnt_organization_structure.length > 0
        ) {
          setSelectedOrgData(response.data.data.tnt_organization_structure[0]);
        } else {
          console.log(response);
          setSelectedOrgData(null);
        }
      } catch (error) {
        console.error("Error fetching organization data:", error);
      }
    } else {
      setSelectedOrgData(null);
    }

    setorg(selectedOrgId);
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const highlights = [
    { from: 0, to: 20, color: "#e63946" }, // Red
    { from: 20, to: 40, color: "#f1a9a0" }, // Orange
    { from: 40, to: 60, color: "#feca57" }, // Yellow
    { from: 60, to: 80, color: "#00b4d8" }, // Blue
    { from: 80, to: 100, color: "#90e0ef" }, // Light Blue
  ];

  const getBreadcrumbItemText = (tabId) => {
    switch (tabId) {
      case "1":
        return "Date Wise Idle Time";
      case "2":
        return "Shift Wise Idle Time";
      case "3":
        return "Operator Wise Idle Time";
      case "4":
        return "Machine Wise Idle Time";
      default:
        return "Date Wise Idle Time";
    }
  };

  const [bigChartData, setbigChartData] = React.useState("data1");
  const setBgChartData = (name) => {
    setbigChartData(name);
  };

  //// Availability ////////////////////////////////////////////////////////////////////////

  const [AvailabilityDataList, setAvailabilityDataList] = useState({
    machine_availability_percent: "No Data",
  });
  useEffect(() => {
    Availability();
  }, []);
  const Availability = async () => {
    try {
      const data = await QueryApi(`
        query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
          dm_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
            aggregate {
              avg {
                machine_availability_percent
              }
            }
          }
        } `);
      setAvailabilityDataList({
        machine_availability_percent:
          data.dm_daily_aggregate.aggregate.avg.machine_availability_percent,
      });
    } catch (error) {
      setAvailabilityDataList({
        machine_availability_percent: "No Data",
      });
    }
  };

  ///// Average Downtime ////////////////////////////////////////////

  const [AverageDowntimeDataList, setAverageDowntimeDataList] = useState({
    total_machine_downtime: "No Data",
  });
  useEffect(() => {
    AverageDowntime();
  }, []);
  const AverageDowntime = async () => {
    try {
      const data = await QueryApi(`
        query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
          dm_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
            aggregate {
              sum {
                total_machine_downtime
              }
            }
          }
        } `);
      const hoursDowntime =
        data.dm_daily_aggregate.aggregate.sum.total_machine_downtime /
        (1000 * 60 * 60);
      setAverageDowntimeDataList({
        total_machine_downtime: hoursDowntime.toFixed(0),
      });
    } catch (error) {
      setAverageDowntimeDataList({
        total_machine_downtime: "No Data",
      });
    }
  };

  //// Downtime Trendline ///////////////////////////////////////

  const [
    xAxisDataValuePerformanceInsights,
    setXAxisDataValuePerformanceInsights,
  ] = useState([]);
  const [plotPointPerformanceInsights, setPlotPointPerformanceInsights] =
    useState([]);
  const datasetPerformanceInsights = [
    {
      label: "Production Time",
      data: plotPointPerformanceInsights,
      borderColor: "#1F8EF1",
    },
  ];

  useEffect(() => {
    GraphDataListPerformanceInsights();
  }, []);
  const GraphDataListPerformanceInsights = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
              dm_daily(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
              total_machine_downtime
                timestamp
              }
            }
      `);
      const plotAllDataPerformanceInsights = data.dm_daily.map((data) => {
        const objData = {
          point: data.total_machine_downtime,
          Xaxis: data.timestamp,
        };
        return objData;
      });

      const plotPointDataPerformanceInsights =
        plotAllDataPerformanceInsights.map((data) =>
          (data.point / (1000 * 60 * 60)).toFixed(2)
        );
      setPlotPointPerformanceInsights(plotPointDataPerformanceInsights);

      const xAxisPointDataPerformanceInsights =
        plotAllDataPerformanceInsights.map((data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
          var m = "0" + d.getMinutes();
          var ampm = h >= 12 ? "PM" : "AM";
          h = h % 12;
          h = h ? h : 12;
          var fullTime = h + ":" + m.substr(-2) + " " + ampm;
          return fullTime;
        });
      setXAxisDataValuePerformanceInsights(xAxisPointDataPerformanceInsights);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="2">
            <Card
              style={{ fontSize: "20px", fontWeight: "bold", color: "white" }}
            >
              Idle Time Analysis- Level 1
            </Card>
          </Col>
          {/* <Col lg="2">
            <FormGroup>
              <Input
                type="select"
                value={org}
                onChange={(e) => handleOrgSelect(e.target.value)}
              >
                <option value="">Select Organization</option>
                {otherData.map((org) => (
                  <option key={org.organization_structure_id} value={org.organization_structure_id}>
                    {org.organization_node_name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <Input
                type="select"
                value={unit}
                onChange={(e) => setunit(e.target.value)}
              >
                <option value="">Select Unit/Plant</option>
                {plants.map((plant) => (
                  <option key={plant.organization_structure_id} value={plant.organization_structure_id}>
                    {plant.organization_node_name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <Input
                type="select"
                value={dept}
                onChange={(e) => setdept(e.target.value)}
              >
                <option value="">Select department</option>
                {depts.map((dpt) => (
                  <option key={dpt.organization_structure_id} value={dpt.organization_structure_id}>
                    {dpt.organization_node_name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <Input
                type="date"

              />

            </FormGroup>
          </Col>
          <Col lg="2">
            <Button lg="3" className="btn btn-secondary">Submit</Button>
            <Button lg="3" className="btn btn-secondary">Clear</Button>
          </Col> */}
        </Row>
        <Row>
          <Col lg="2" md="6" sm="6">
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5
                      className="card-category"
                      style={{
                        fontSize: "24px",
                        width: "250px",
                        fontWeight: "bold",
                      }}
                    >
                      Availability
                    </h5>
                    {/* <CardTitle tag="h2">Performance</CardTitle> */}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="gauge-container" id="speedometer">
                  <ReactSpeedometer
                    value={AvailabilityDataList.machine_availability_percent} // Change this to your desired value
                    minValue={0}
                    maxValue={100}
                    width={200}
                    height={200}
                    needleColor="wheat" // Customize the needle color
                    startColor="green" // Customize the start color of the gauge
                    segments={5} // Number of segments on the gauge
                    endColor="blue" // Customize the end color of the gauge
                    textColor="white" // Text color
                    ringWidth={20}
                    currentValueText="${value} %" // Text shown in the center
                  />
                </div>
                {/* <ButtonGroup 
                        className="btn-group-toggle d-flex justify-content-center"
                        data-toggle="buttons"
                    >
                        <Button
                        tag="label"
                        className={classNames("btn-simple", {
                            active: bigChartData === "data1",
                        })}
                        color="info"
                        id="0"
                        size="sm"
                        onClick={() => setBgChartData("data1")}
                        >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Day
                        </span>
                        <span className="d-block d-sm-none">
                            <i className="tim-icons icon-single-02" />
                        </span>
                        </Button>
                        <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                            active: bigChartData === "data2",
                        })}
                        onClick={() => setBgChartData("data2")}
                        >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Week
                        </span>
                        <span className="d-block d-sm-none">
                            <i className="tim-icons icon-gift-2" />
                        </span>
                        </Button>
                        <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                            active: bigChartData === "data3",
                        })}
                        onClick={() => setBgChartData("data3")}
                        >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Month
                        </span>
                        <span className="d-block d-sm-none">
                            <i className="tim-icons icon-tap-02" />
                        </span>
                        </Button>
                        <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                            active: bigChartData === "data4",
                        })}
                        onClick={() => setBgChartData("data1")}
                        >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Year
                        </span>
                        <span className="d-block d-sm-none">
                            <i className="tim-icons icon-tap-02" />
                        </span>
                        </Button>
                    </ButtonGroup> */}
              </CardBody>
            </Card>
          </Col>
          <Col lg="2" md="6" sm="6">
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5
                      className="card-category"
                      style={{
                        fontSize: "24px",
                        width: "250px",
                        fontWeight: "bold",
                      }}
                    >
                      Average Downtime
                    </h5>
                    {/* <CardTitle tag="h2">Performance</CardTitle> */}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="gauge-container" id="speedometer">
                  <ReactSpeedometer
                    value={AverageDowntimeDataList.total_machine_downtime} // Change this to your desired value
                    minValue={0}
                    maxValue={100}
                    width={200}
                    height={200}
                    needleColor="wheat" // Customize the needle color
                    startColor="green" // Customize the start color of the gauge
                    segments={5} // Number of segments on the gauge
                    endColor="blue" // Customize the end color of the gauge
                    textColor="white" // Text color
                    ringWidth={20}
                    currentValueText="${value} %" // Text shown in the center
                  />
                </div>
                {/* <ButtonGroup 
                        className="btn-group-toggle d-flex justify-content-center"
                        data-toggle="buttons"
                    >
                        <Button
                        tag="label"
                        className={classNames("btn-simple", {
                            active: bigChartData === "data1",
                        })}
                        color="info"
                        id="0"
                        size="sm"
                        onClick={() => setBgChartData("data1")}
                        >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Day
                        </span>
                        <span className="d-block d-sm-none">
                            <i className="tim-icons icon-single-02" />
                        </span>
                        </Button>
                        <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                            active: bigChartData === "data2",
                        })}
                        onClick={() => setBgChartData("data2")}
                        >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Week
                        </span>
                        <span className="d-block d-sm-none">
                            <i className="tim-icons icon-gift-2" />
                        </span>
                        </Button>
                        <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                            active: bigChartData === "data3",
                        })}
                        onClick={() => setBgChartData("data3")}
                        >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Month
                        </span>
                        <span className="d-block d-sm-none">
                            <i className="tim-icons icon-tap-02" />
                        </span>
                        </Button>
                        <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                            active: bigChartData === "data4",
                        })}
                        onClick={() => setBgChartData("data1")}
                        >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Year
                        </span>
                        <span className="d-block d-sm-none">
                            <i className="tim-icons icon-tap-02" />
                        </span>
                        </Button>
                    </ButtonGroup> */}
              </CardBody>
            </Card>
          </Col>
          <Col lg="2" md="6" sm="6">
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5
                      className="card-category"
                      style={{
                        fontSize: "24px",
                        width: "250px",
                        fontWeight: "bold",
                      }}
                    >
                      Cycle Time Loss
                    </h5>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="gauge-container" id="speedometer">
                  <ReactSpeedometer
                    value={45} // Change this to your desired value
                    minValue={0}
                    maxValue={100}
                    width={200}
                    height={200}
                    needleColor="wheat" // Customize the needle color
                    startColor="green" // Customize the start color of the gauge
                    segments={5} // Number of segments on the gauge
                    endColor="blue" // Customize the end color of the gauge
                    textColor="white" // Text color
                    ringWidth={20}
                    currentValueText="${value} %" // Text shown in the center
                  />
                </div>
                {/* <ButtonGroup className="btn-group-toggle d-flex justify-content-center"
                        data-toggle="buttons"
                    >
                        <Button
                        tag="label"
                        className={classNames("btn-simple", {
                            active: bigChartData === "data1",
                        })}
                        color="info"
                        id="0"
                        size="sm"
                        onClick={() => setBgChartData("data1")}
                        >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Day
                        </span>
                        <span className="d-block d-sm-none">
                            <i className="tim-icons icon-single-02" />
                        </span>
                        </Button>
                        <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                            active: bigChartData === "data2",
                        })}
                        onClick={() => setBgChartData("data2")}
                        >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Week
                        </span>
                        <span className="d-block d-sm-none">
                            <i className="tim-icons icon-gift-2" />
                        </span>
                        </Button>
                        <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                            active: bigChartData === "data3",
                        })}
                        onClick={() => setBgChartData("data3")}
                        >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Month
                        </span>
                        <span className="d-block d-sm-none">
                            <i className="tim-icons icon-tap-02" />
                        </span>
                        </Button>
                        <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                            active: bigChartData === "data4",
                        })}
                        onClick={() => setBgChartData("data1")}
                        >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Year
                        </span>
                        <span className="d-block d-sm-none">
                            <i className="tim-icons icon-tap-02" />
                        </span>
                        </Button>
                    </ButtonGroup> */}
              </CardBody>
            </Card>
          </Col>
          <Col xs="3" sm="6" md="6" lg="6">
            <Card className="card-chart" style={{ height: "330px" }}>
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5
                      className="card-category"
                      style={{
                        fontSize: "24px",
                        width: "250px",
                        fontWeight: "bold",
                      }}
                    >
                      Downtime Trendline
                    </h5>
                    {/* <CardTitle tag="h2">Performance</CardTitle> */}
                  </Col>
                  <Col sm="6">
                    <ButtonGroup
                      className="btn-group-toggle d-flex justify-content-center"
                      data-toggle="buttons"
                    >
                      <Button
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data1",
                        })}
                        color="info"
                        id="0"
                        size="sm"
                        onClick={() => setBgChartData("data1")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Day
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-single-02" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data2",
                        })}
                        onClick={() => setBgChartData("data2")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Week
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-gift-2" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data3",
                        })}
                        onClick={() => setBgChartData("data3")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Month
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-tap-02" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data4",
                        })}
                        onClick={() => setBgChartData("data1")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Year
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-tap-02" />
                        </span>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {/* <div className="chart-area" style={{ height: "100%" }}>
                  <Line
                    data={chartExample1[bigChartData]}
                    options={chartExample1.options}
                  />
                </div> */}
                <LineChart
                  xAxisData={xAxisDataValuePerformanceInsights}
                  dataset={datasetPerformanceInsights}
                  height="100%"
                  width="100%"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => toggleTab("1")}
              style={{
                backgroundColor: activeTab === "1" ? "#5555e7" : "transparent",
                color: activeTab === "1" ? "white" : "white",
              }}
            >
              Date Wise Idle Time
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => toggleTab("2")}
              style={{
                backgroundColor: activeTab === "2" ? "#5555e7" : "transparent",
                color: activeTab === "2" ? "white" : "white",
              }}
            >
              Shift Wise Idle Time
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => toggleTab("3")}
              style={{
                backgroundColor: activeTab === "3" ? "#5555e7" : "transparent",
                color: activeTab === "3" ? "white" : "white",
              }}
            >
              Operator Wise Idle Time
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "4" })}
              onClick={() => toggleTab("4")}
              style={{
                backgroundColor: activeTab === "4" ? "#5555e7" : "transparent",
                color: activeTab === "4" ? "white" : "white",
              }}
            >
              Machine Wise Idle Time
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col>
                <DateWishIdleTime />
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="2">
            <Row>
              <Col>
                <ShiftWishIdleTime />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col>
                <OperatorWiseIdleTime />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="4">
            <Row>
              <Col>
                <MachineWiseIdleTime />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </>
  );
}

export default ManagePeople;
