import React, { useState, useEffect } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import redirectToLoginIfTokenMissing from "views/TokenUtility";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import DateWishCycleTime from "views/DateWishCycleTime";
import ShiftWishCycleTime from "views/ShiftWishCycleTime";
import CycleTimeDeviation from "views/CycleTimeDeviation";
import OperatorWiseCycleTime from "views/OperatorWiseCycleTime";
import classNames from "classnames";
import axios from "axios";
import { APIURL } from "views/Apiurl";
import MachineWiseCycleTime from "components/CycleTime/MachineWiseCycleTime";
import PartWiseCycleTime from "components/CycleTime/PartWiseCycleTime";
import TableComponent from "./Productivity Grids/TableComponent";
import { QueryApi } from "QueryApi";
import { set } from "react-hook-form";

function InfoCycleTime(props) {
  const [activeTab, setActiveTab] = useState("1");
  const storedTenantId = sessionStorage.getItem("selectedTenantId");
  useEffect(() => {
    redirectToLoginIfTokenMissing();
  }, []);
  const token = sessionStorage.getItem("token");
  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const tenantId =
    decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  ///////////////////////////////////////////////////////////////
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const currDay = day < 10 ? `0${day}` : day;
  const currMonth = month < 10 ? `0${month}` : month;
  let currentDate = JSON.stringify(`${year}-${currMonth}-${currDay}`);

  const dateData = new Date();
  dateData.setDate(dateData.getDate() - 7);
  var dayDataValue = dateData.getDate();
  var monthDataValue = dateData.getMonth() + 1;
  var yearDataValue = dateData.getFullYear();
  var formattedDay = dayDataValue < 10 ? `0${dayDataValue}` : dayDataValue;
  var formattedMonth =
    monthDataValue < 10 ? `0${monthDataValue}` : monthDataValue;
  var weekDataDate = JSON.stringify(
    `${yearDataValue}-${formattedMonth}-${formattedDay}`
  );

  const previousDate = new Date();
  previousDate.setDate(1);
  const previousDay = previousDate.getDate();
  const previousMonth = previousDate.getMonth() + 1;
  const previousYear = previousDate.getFullYear();
  const formattedPreviousDay =
    previousDay < 10 ? `0${previousDay}` : previousDay;
  const formattedPreviousMonth =
    previousMonth < 10 ? `0${previousMonth}` : previousMonth;
  const previousMonthDate = JSON.stringify(
    `${previousYear}-${formattedPreviousMonth}-${formattedPreviousDay}`
  );

  const yearCurrentDate = new Date();
  yearCurrentDate.setDate(1);
  yearCurrentDate.setMonth(1);
  const yearDay = yearCurrentDate.getDate();
  const yearMonth = yearCurrentDate.getMonth();
  const yearYear = yearCurrentDate.getFullYear();
  const formattedYearDay = yearDay < 10 ? `0${yearDay}` : yearDay;
  const formattedYearMonth = yearMonth < 10 ? `0${yearMonth}` : yearMonth;
  const yearPreviousMonthDate = JSON.stringify(
    `${yearYear}-${formattedYearMonth}-${formattedYearDay}`
  );

  const [apiData, setApiData] = useState([]);
  const [aggrigateData, setAggrigateData] = useState([]);
  const [shiftwiseData, setShiftWiseData] = useState([]);

  const [bigChartData, setBgChartData] = React.useState("data1");
  useEffect(() => {
    fetchAllCardData();
  }, [bigChartData]);
  const fetchAllCardData = async () => {
    if (bigChartData === "data1") {
      try {
        const data = await QueryApi(`
        query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
          dm_daily(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}},order_by: {date: desc}) {
            actual_cycletime
            actual_production_time
            all_available_time
            asset_utilization_percent
            availability_loss_percent
            availability_loss_time
            capacity_utilized_percent
            cycletime_deviation
            date
            department_id
            edgeid
            machine_availability_percent
            machine_idle_time
            machine_performance_percent
            machine_up_time
            machineid
            net_available_time
            no_of_parts_rejected
            org_id
            parts_per_minute
            planned_downtime_percent
            planned_production_time
            plant_operating_time_percent
            quality_percent
            target_adherence
            target_parts
            tenantid
            time_between_job_parts
            timestamp
            total_machine_downtime
            total_machine_runtime
            total_parts_produced
            total_planned_downtime
            total_production_per_batch
            unit_id
            unplanned_downtime
            org {
              organization_node_name
            }
            dept {
              organization_node_name
            }
            unit {
              organization_node_name
            }
          }
          dm_daily_aggrigate: dm_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
            aggregate {
              avg {
                actual_cycletime
                cycletime_deviation
                time_between_job_parts
              }
            }
          }
          dm_shiftwise_data:dm_machine_shiftwise(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}},order_by: {date: desc}) {
            machineid
            date
            org {
              organization_node_name
            }
            dept {
              organization_node_name
            }
            shift_id
            actual_cycletime
            planned_production_time
            cycletime_deviation
            capacity_utilized_percent
            time_between_job_parts
            machine_performance_percent
            machine_availability_percent
          }
        }
        `);
        setShiftWiseData(data.dm_shiftwise_data);
        setApiData(data.dm_daily);
        setAggrigateData(data.dm_daily_aggrigate);
      } catch (error) {}
    } else if (bigChartData === "data2") {
      try {
        const data = await QueryApi(`
        query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! =  ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
          dm_daily(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}, order_by: {date: desc}) {
            actual_cycletime
            date
            actual_production_time
            all_available_time
            asset_utilization_percent
            availability_loss_percent
            availability_loss_time
            capacity_utilized_percent
            cycletime_deviation
            department_id
            edgeid
            machine_availability_percent
            machine_idle_time
            machine_performance_percent
            machine_up_time
            machineid
            net_available_time
            no_of_parts_rejected
            org_id
            parts_per_minute
            planned_downtime_percent
            planned_production_time
            plant_operating_time_percent
            quality_percent
            target_adherence
            target_parts
            tenantid
            time_between_job_parts
            timestamp
            total_machine_downtime
            total_machine_runtime
            total_parts_produced
            total_planned_downtime
            total_production_per_batch
            unit_id
            unplanned_downtime
            org {
              organization_node_name
            }
            dept {
              organization_node_name
            }
            unit {
              organization_node_name
            }
            }
            dm_daily_aggrigate: dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}, order_by: {date: desc}) {
              aggregate {
                avg {
                  actual_cycletime
                  cycletime_deviation
                  time_between_job_parts
                }
              }
            }
            dm_shiftwise_data:dm_machine_shiftwise(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}, order_by: {date: desc}) {
              machineid
              date
              org {
                organization_node_name
              }
              dept {
                organization_node_name
              }
              shift_id
              actual_cycletime
              planned_production_time
              cycletime_deviation
              capacity_utilized_percent
              time_between_job_parts
              machine_performance_percent
              machine_availability_percent
            }
          }
        `);
        setShiftWiseData(data.dm_shiftwise_data);
        setApiData(data.dm_daily);
        setAggrigateData(data.dm_daily_aggrigate);
      } catch (error) {}
    } else if (bigChartData === "data3") {
      try {
        const data = await QueryApi(`
        query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! =  ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
          dm_daily(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}, order_by: {date: desc}) {
            actual_cycletime
            actual_production_time
            all_available_time
            asset_utilization_percent
            availability_loss_percent
            availability_loss_time
            capacity_utilized_percent
            cycletime_deviation
            date
            department_id
            edgeid
            machine_availability_percent
            machine_idle_time
            machine_performance_percent
            machine_up_time
            machineid
            net_available_time
            no_of_parts_rejected
            org_id
            parts_per_minute
            planned_downtime_percent
            planned_production_time
            plant_operating_time_percent
            quality_percent
            target_adherence
            target_parts
            tenantid
            time_between_job_parts
            timestamp
            total_machine_downtime
            total_machine_runtime
            total_parts_produced
            total_planned_downtime
            total_production_per_batch
            unit_id
            unplanned_downtime
            org {
              organization_node_name
            }
            dept {
              organization_node_name
            }
            unit {
              organization_node_name
            }
            }
            dm_monthly_aggrigate: dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}, order_by: {date: desc}) {
              aggregate {
                avg {
                  actual_cycletime
                  cycletime_deviation
                  time_between_job_parts
                }
              }
            }
            dm_shiftwise_data:dm_machine_shiftwise(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}, order_by: {date: desc}) {
              machineid
              date
              org {
                organization_node_name
              }
              dept {
                organization_node_name
              }
              shift_id
              actual_cycletime
              planned_production_time
              cycletime_deviation
              capacity_utilized_percent
              time_between_job_parts
              machine_performance_percent
              machine_availability_percent
            }
          }
        `);
        setShiftWiseData(data.dm_shiftwise_data);
        setApiData(data.dm_daily);
        setAggrigateData(data.dm_monthly_aggrigate);
      } catch (error) {}
    } else if (bigChartData === "data4") {
      try {
        const data = await QueryApi(`
        query MyQuery($start_date: date= ${yearPreviousMonthDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
          dm_daily(where: {tenantid: {_eq: $tenantid}}, order_by: {date: desc}) {
            actual_cycletime
            actual_production_time
            all_available_time
            asset_utilization_percent
            availability_loss_percent
            availability_loss_time
            capacity_utilized_percent
            cycletime_deviation
            date
            department_id
            edgeid
            machine_availability_percent
            machine_idle_time
            machine_performance_percent
            machine_up_time
            machineid
            net_available_time
            no_of_parts_rejected
            org_id
            parts_per_minute
            planned_downtime_percent
            planned_production_time
            plant_operating_time_percent
            quality_percent
            target_adherence
            target_parts
            tenantid
            time_between_job_parts
            timestamp
            total_machine_downtime
            total_machine_runtime
            total_parts_produced
            total_planned_downtime
            total_production_per_batch
            unit_id
            unplanned_downtime
            org {
              organization_node_name
            }
            dept {
              organization_node_name
            }
            unit {
              organization_node_name
            }
          }
          dm_yearly_aggrigate: dm_daily_aggregate(where: { tenantid: {_eq: $tenantid}}) {
            aggregate {
              avg {
                actual_cycletime
                cycletime_deviation
                time_between_job_parts
              }
            }
          }
          dm_shiftwise_data:dm_machine_shiftwise(where: {tenantid: {_eq: $tenantid}}, order_by: {date: desc}) {
            machineid
            date
            org {
              organization_node_name
            }
            dept {
              organization_node_name
            }
            shift_id
            actual_cycletime
            planned_production_time
            cycletime_deviation
            capacity_utilized_percent
            time_between_job_parts
            machine_performance_percent
            machine_availability_percent
          }
        }
        `);
        setShiftWiseData(data.dm_shiftwise_data);
        setApiData(data.dm_daily);
        setAggrigateData(data.dm_yearly_aggrigate);
      } catch (error) {}
    }
  };

  function handleEdit(row) {}
  function actionFormatter(cell, row) {
    return (
      <div>
        <Col
          className="font-icon-list col-xs-6 col-xs-6"
          lg="2"
          md="3"
          sm="4"
          onClick={() => handleEdit(row)}
        ></Col>
        <FontAwesomeIcon
          icon={faPencilAlt}
          className="btn btn-primary tim-icons icon-pencil"
          style={{
            fontSize: "70%",
            background: "none",
            marginRight: "-46px",
            pointerEvents: "none",
          }}
        />
        <Link to={`/admin/org-structure/${row.organization_structure_id}`}>
          <FontAwesomeIcon
            icon={faTrash}
            className="btn btn-primary tim-icons icon-pencil"
            style={{
              fontSize: "70%",
              background: "none",
              pointerEvents: "none",
            }}
          />
        </Link>
      </div>
    );
  }

  const dateWiseColumns = [
    {
      dataField: "index",
      text: "Sr. No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
    },
    {
      dataField: "machineid",
      text: "Machine id",
    },
    {
      dataField: "date",
      text: "Date",
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
    },
    {
      dataField: "actual_cycletime",
      text: "Avg Actual Cycle Time",
      formatter: (data) => {
        const minutes = data / 60000;
        return `${minutes.toFixed(3)} minutes`;
      },
    },
    {
      dataField: "planned_production_time",
      text: "Planned Production Time",
      formatter: (data) => {
        const minutes = data / 3600000;
        return `${minutes.toFixed(2)} hours`;
      },
    },
    {
      dataField: "machine_idle_time",
      text: "Time Loss(Idle Time)",
      formatter: (data) => {
        const minutes = data / 3600000;
        return `${minutes.toFixed(3)} hours`;
      },
    },
    {
      dataField: "cycletime_deviation",
      text: "Time Loss (Cycle Time)",
      formatter: (data) => {
        const minutes = data / 60000;
        return `${minutes.toFixed(3)} minutes`;
      },
    },
    {
      dataField: "capacity_utilized_percent",
      text: "Capacity Utilization(%)",
      formatter: (data) => {
        return `${data.toFixed(3)}%`;
      },
    },
    {
      dataField: "machine_performance_percent",
      text: "Performance (Equipment Performance)",
      formatter: (data) => {
        return `${data.toFixed(3)}%`;
      },
    },
    {
      dataField: "machine_availability_percent",
      text: "Availability (Equipment Availability)",
      formatter: (data) => {
        return `${data.toFixed(3)}%`;
      },
    },
    {
      dataField: "time_between_job_parts",
      text: "Time between jobs",
      formatter: (data) => {
        const minutes = data / 60000;
        return `${minutes.toFixed(3)} minutes`;
      },
    },
    {
      dataField: "action",
      text: "Details",
      formatter: actionFormatter,
    },
  ];
  const shiftWiseColumns = [
    {
      dataField: "index",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
    },
    {
      dataField: "machineid",
      text: "Machine id",
    },
    {
      dataField: "date",
      text: "Date",
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
    },
    {
      dataField: "shift_id",
      text: "Shift Name",
    },
    {
      dataField: "actual_cycletime",
      text: "Avg Cycle Time",
      formatter: (data) => {
        const minutes = data / 60000;
        return `${minutes.toFixed(3)} minutes`;
      },
    },
    {
      dataField: "planned_production_time",
      text: "Planned Production Time",
      formatter: (data) => {
        const minutes = data / 3600000;
        return `${minutes.toFixed(2)} hours`;
      },
    },
    {
      dataField: "cycletime_deviation",
      text: "Time Loss (cycle Time)",
      formatter: (data) => {
        const minutes = data / 60000;
        return `${minutes.toFixed(3)} minutes`;
      },
    },
    {
      dataField: "capacity_utilized_percent",
      text: "Capacity Utilization(%)",
      formatter: (data) => {
        return `${data.toFixed(3)}%`;
      },
    },
    {
      dataField: "time_between_job_parts",
      text: "Time between jobs",
      formatter: (data) => {
        const minutes = data / 60000;
        return `${minutes.toFixed(2)} minutes`;
      },
    },
    {
      dataField: "machine_performance_percent",
      text: "Performance (Equipment Performance)",
      formatter: (data) => {
        return `${data.toFixed(3)}%`;
      },
    },
    {
      dataField: "machine_availability_percent",
      text: "Availability (Equipment Availability)",
      formatter: (data) => {
        return `${data.toFixed(3)}%`;
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: actionFormatter,
    },
  ];
  const opertorWiseColumns = [
    {
      dataField: "index",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
    },
    {
      dataField: "machineid",
      text: "Machine id",
    },
    {
      dataField: "date",
      text: "Date",
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
    },
    {
      dataField: "unit.organization_node_name",
      text: "Unit/Plant",
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
    },
    {
      dataField: "shift_id",
      text: "Shift Name",
    },
    {
      dataField: "de",
      text: "Operator Name",
    },
    {
      dataField: "supervisor_id",
      text: "Supervisor/Manager",
    },
    {
      dataField: "planned_production_time",
      text: "Planned Production Time",
      formatter: (data) => {
        const minutes = data / 3600000;
        return `${minutes.toFixed(2)} hours`;
      },
    },
    {
      dataField: "machine_idle_time",
      text: "Idle Time Loss",
      formatter: (data) => {
        const minutes = data / 60000;
        return `${minutes.toFixed(3)} minutes`;
      },
    },
    {
      dataField: "cycletime_deviation",
      text: "Cycle Time Loss",
      formatter: (data) => {
        const minutes = data / 60000;
        return `${minutes.toFixed(3)} minutes`;
      },
    },
    {
      dataField: "capacity_utilized_percent",
      text: "Capacity Utilization(%)",
      formatter: (data) => {
        return `${data.toFixed(3)}%`;
      },
    },
    {
      dataField: "time_between_job_parts",
      text: "Time between jobs",
      formatter: (data) => {
        const minutes = data / 60000;
        return `${minutes.toFixed(3)} minutes`;
      },
    },
    {
      dataField: "machine_performance_percent",
      text: "Performance (Equipment Performance)",
      formatter: (data) => {
        return `${data.toFixed(3)}%`;
      },
    },
    {
      dataField: "machine_availability_percent",
      text: "Availability (Equipment Availability)",
      formatter: (data) => {
        return `${data.toFixed(3)}%`;
      },
    },
    {
      dataField: "action",
      text: "Details",
      formatter: actionFormatter,
    },
  ];
  const cycleTimeColumns = [
    {
      dataField: "index",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "Date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machineid",
      text: "Machine ID",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "operator_id",
      text: "Operator",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "shift_id",
      text: "Shift",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machine_idle_time",
      text: "Ideal CycleTime",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        const minutes = data / 60000;
        return `${minutes.toFixed(3)} minutes`;
      },
    },
    {
      dataField: "actual_cycletime",
      text: "Actual Cycle Time",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        const minutes = data / 60000;
        return `${minutes.toFixed(3)} minutes`;
      },
    },
    {
      dataField: "cycletime_deviation",
      text: "Cycle Time Deviation(%)",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        const minutes = data / 60000;
        return `${minutes.toFixed(3)} %`;
      },
    },
    {
      dataField: "action",
      text: "Details",
      formatter: actionFormatter,
      headerStyle: { width: "500px" },
    },
  ];
  const machineWiseColumns = [
    {
      dataField: "index",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "Date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machineid",
      text: "Machine id",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "actual_cycletime",
      text: "Avg Cycle time",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        const minutes = data / 60000;
        return `${minutes.toFixed(3)} minutes`;
      },
    },
    {
      dataField: "machine_idle_time",
      text: "Ideal Cycle time",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        const minutes = data / 60000;
        return minutes.toFixed(3);
      },
    },
    {
      dataField: "cycletime_deviation",
      text: "Cycle time deviation",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        const minutes = data / 60000;
        return `${minutes.toFixed(3)} minutes`;
      },
    },
    {
      dataField: "time_between_job_parts",
      text: "Time between jobs",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        const minutes = data / 60000;
        return `${minutes.toFixed(3)} minutes`;
      },
    },
    {
      dataField: "machine_performance_percent",
      text: "Performance (Equipment Performance)",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        return `${data.toFixed(3)}%`;
      },
    },
    {
      dataField: "machine_availability_percent",
      text: "Availability (Equipment Availability)",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        return `${data.toFixed(3)}%`;
      },
    },
    {
      dataField: "capacity_utilized_percent",
      text: "Capacity Utilization %",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        return `${data.toFixed(3)}%`;
      },
    },
    {
      dataField: "action",
      text: "Details",
      formatter: actionFormatter,
      headerStyle: { width: "500px" },
    },
  ];
  const partWiseColumns = [
    {
      dataField: "index",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "Date",
    },
    {
      dataField: "machineid",
      text: "Machine id",
    },
    {
      dataField: "actual_cycletime",
      text: "Avg Cycle time (Minutes)",
      formatter: (data) => {
        const minutes = data / 60000;
        return minutes.toFixed(3);
      },
    },
    {
      dataField: "Ideal Cycle time",
      text: "Ideal Cycle time (Minutes)",
      formatter: (data) => {
        const minutes = data / 60000;
        return minutes.toFixed(3);
      },
    },
    {
      dataField: "cycletime_deviation",
      text: "Cycle time deviation (Minutes)",
      formatter: (data) => {
        const minutes = data / 60000;
        return minutes.toFixed(3);
      },
    },
    {
      dataField: "time_between_job_parts",
      text: "Time between jobs (Minutes)",
      formatter: (data) => {
        const minutes = data / 60000;
        return minutes.toFixed(3);
      },
    },
    {
      dataField: "machine_performance_percent",
      text: "Performance (Equipment Performance)",
      formatter: (data) => {
        return `${data.toFixed(3)}%`;
      },
    },
    {
      dataField: "machine_availability_percent",
      text: "Availability (Equipment Availability)",
      formatter: (data) => {
        return `${data.toFixed(3)}%`;
      },
    },
    {
      dataField: "capacity_utilized_percent",
      text: "Capacity Utilization %",
      formatter: (data) => {
        return `${data.toFixed(3)}%`;
      },
    },
    {
      dataField: "Avg Cycle time for part",
      text: "Avg Cycle time for part",
    },
    {
      dataField: "Deviation from All time Average",
      text: "Deviation from All time Average",
    },
    {
      dataField: "action",
      text: "Details",
      formatter: actionFormatter,
    },
  ];
  const [meterDataList, setMeterDataList] = useState();

  useEffect(() => {
    meterData();
  }, []);

  const meterData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const roleId = decodedToken["sub"];
      const tenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-tenant-id"];
      const storedTenantId = sessionStorage.getItem("selectedTenantId");
      let variables = {};
      if (roleId === 1) {
        if (storedTenantId !== null) {
          variables = { tenantId: storedTenantId };
        }
      } else {
        variables = { tenantId: tenantId };
      }
      let query = `
      query MyQuery($date: date = "", $start_date: date = "2024-01-20", $end_date: date = "2024-01-20") {
        dm_foundation_aggregate(where: {date: {_gte: $start_date, _lte: $end_date}}) {
          aggregate {
            avg {
              machine_availability_percent
              total_machine_downtime
              cycletime_deviation
            }
          }
        }
      }      
      `;
      const response = await axios.post(
        APIURL,
        {
          query,
          variables,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-hasura-admin-secret": process.env.REACT_APP_X_API_SECRET_KEY,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMeterDataList(
        response.data.data.dm_foundation_aggregate.aggregate.avg
      );
    } catch (error) {}
  };

  //// Availability ////////////////////////////////////////////////////////////////////////

  const [AvailabilityDataList, setAvailabilityDataList] = useState({
    machine_availability_percent: "No Data",
  });
  useEffect(() => {
    Availability();
  }, []);
  const Availability = async () => {
    try {
      const data = await QueryApi(`
        query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
          dm_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
            aggregate {
              avg {
                machine_availability_percent
              }
            }
          }
        } `);
      setAvailabilityDataList({
        machine_availability_percent:
          data.dm_daily_aggregate.aggregate.avg.machine_availability_percent,
      });
    } catch (error) {
      setAvailabilityDataList({
        machine_availability_percent: "No Data",
      });
    }
  };

  ///// Average Downtime ////////////////////////////////////////////

  const [AverageDowntimeDataList, setAverageDowntimeDataList] = useState({
    total_machine_downtime: "No Data",
  });
  useEffect(() => {
    AverageDowntime();
  }, []);
  const AverageDowntime = async () => {
    try {
      const data = await QueryApi(`
        query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
          dm_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
            aggregate {
              sum {
                total_machine_downtime
              }
            }
          }
        } `);
      const hoursDowntime =
        data.dm_daily_aggregate.aggregate.sum.total_machine_downtime /
        (1000 * 60 * 60);
      setAverageDowntimeDataList({
        total_machine_downtime: hoursDowntime.toFixed(0),
      });
    } catch (error) {
      setAverageDowntimeDataList({
        total_machine_downtime: "No Data",
      });
    }
  };

  //// Downtime Trendline ///////////////////////////////////////

  const [
    xAxisDataValuePerformanceInsights,
    setXAxisDataValuePerformanceInsights,
  ] = useState([]);
  const [plotPointPerformanceInsights, setPlotPointPerformanceInsights] =
    useState([]);
  const datasetPerformanceInsights = [
    {
      label: "Production Time",
      data: plotPointPerformanceInsights,
      borderColor: "#1F8EF1",
    },
  ];

  useEffect(() => {
    GraphDataListPerformanceInsights();
  }, []);
  const GraphDataListPerformanceInsights = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
              dm_foundation(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
              total_machine_downtime
                timestamp
              }
            }
      `);
      const plotAllDataPerformanceInsights = data.dm_foundation.map((data) => {
        const objData = {
          point: data.total_machine_downtime,
          Xaxis: data.timestamp,
        };
        return objData;
      });

      const plotPointDataPerformanceInsights =
        plotAllDataPerformanceInsights.map((data) =>
          (data.point / (1000 * 60 * 60)).toFixed(2)
        );
      setPlotPointPerformanceInsights(plotPointDataPerformanceInsights);

      const xAxisPointDataPerformanceInsights =
        plotAllDataPerformanceInsights.map((data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
          var m = "0" + d.getMinutes();
          var ampm = h >= 12 ? "PM" : "AM";
          h = h % 12;
          h = h ? h : 12;
          var fullTime = h + ":" + m.substr(-2) + " " + ampm;
          return fullTime;
        });
      setXAxisDataValuePerformanceInsights(xAxisPointDataPerformanceInsights);
    } catch (error) {}
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="3">
            <Card
              style={{ fontSize: "16px", fontWeight: "bold", color: "white" }}
            >
              Cycle Time Analysis Deep down - Level 1
            </Card>
          </Col>
        </Row>
        <Card
          style={{
            background: "transparent",
            boxShadow: "0px 10px 20px 2px rgba(0,0,0,0.75)",
            height: "18rem",
          }}
        >
          <CardHeader>
            <div className="d-flex justify-content-end">
              <div>
                <ButtonGroup
                  className="btn-group-toggle float-right"
                  data-toggle="buttons"
                >
                  <Button
                    tag="label"
                    className={classNames("btn-simple", {
                      active: bigChartData === "data1",
                    })}
                    color="info"
                    id="0"
                    size="sm"
                    style={{ width: "100px" }}
                    onClick={() => setBgChartData("data1")}
                  >
                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      Day
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="tim-icons icon-single-02" />
                    </span>
                  </Button>
                  <Button
                    color="info"
                    id="1"
                    size="sm"
                    style={{ width: "100px" }}
                    tag="label"
                    className={classNames("btn-simple", {
                      active: bigChartData === "data2",
                    })}
                    onClick={() => setBgChartData("data2")}
                  >
                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      Week
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="tim-icons icon-gift-2" />
                    </span>
                  </Button>
                  <Button
                    color="info"
                    id="2"
                    size="sm"
                    style={{ width: "100px" }}
                    tag="label"
                    className={classNames("btn-simple", {
                      active: bigChartData === "data3",
                    })}
                    onClick={() => setBgChartData("data3")}
                  >
                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      Month
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="tim-icons icon-tap-02" />
                    </span>
                  </Button>
                  <Button
                    color="info"
                    id="2"
                    size="sm"
                    style={{ width: "100px" }}
                    tag="label"
                    className={classNames("btn-simple", {
                      active: bigChartData === "data4",
                    })}
                    onClick={() => setBgChartData("data4")}
                  >
                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      Year
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="tim-icons icon-tap-02" />
                    </span>
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "0.8rem",
                marginLeft: "0.5rem",
                height: "13rem",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr ",
                    gap: "0.8rem",
                  }}
                >
                  {/* ////////////////card 1////////////////////// */}
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        Cycle time ( Avg Actual)
                      </div>
                      <div style={{ fontSize: "1.2rem", color: "#1976D2" }}>
                        {aggrigateData &&
                        aggrigateData.aggregate &&
                        aggrigateData.aggregate.avg &&
                        aggrigateData.aggregate.avg.actual_cycletime
                          ? (
                              aggrigateData.aggregate.avg.actual_cycletime /
                              60000
                            ).toFixed(3) + " Minutes"
                          : "No Data"}
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "100%",
                      }}
                    >
                      {/* <img
                        src={timeLoss}
                        style={{
                          height: "2rem",
                          width: "2rem",
                          position: "absolute",
                          top: "10px",
                          right: "2.5rem",
                          cursor: "pointer",
                        }}
                        onClick={() => setIsOpen(true)}
                        title="Trend Line"
                      /> */}
                      <div
                        className="icon-plus"
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: 0,
                        }}
                      >
                        {/* <NavLink className="anchor-bg" href="#">
                          <FontAwesomeIcon icon={faArrowRightLong} />
                        </NavLink> */}
                      </div>
                    </div>
                    {/* call the popup here */}
                  </div>
                  {/* /////////////////card 2///////////////////////// */}
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        Cycle Time Loss ( Avg)
                      </div>
                      <div style={{ fontSize: "1.2rem", color: "#1976D2" }}>
                        No Data
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "100%",
                      }}
                    >
                      <div
                        className="icon-plus"
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: 0,
                        }}
                      ></div>
                    </div>
                    {/* call popup here */}
                  </div>
                </div>

                {/* ////////////////////////////////////////////////// */}
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr ",
                    gap: "0.8rem",
                    marginBottom: "0.6rem",
                    marginTop: "0.8rem",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        Cycle Time deviation
                      </div>
                      <div style={{ fontSize: "1.2rem", color: "#1976D2" }}>
                        {aggrigateData &&
                        aggrigateData.aggregate &&
                        aggrigateData.aggregate.avg &&
                        aggrigateData.aggregate.avg.cycletime_deviation
                          ? (
                              aggrigateData.aggregate.avg.cycletime_deviation /
                              60000
                            ).toFixed(3) + " Minutes"
                          : "No Data"}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "5.5rem",
                        width: "3rem",
                      }}
                    >
                      <img
                      // src={runTime}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        Time Between Jobs
                      </div>
                      <div style={{ fontSize: "1.2rem", color: "#1976D2" }}>
                        {aggrigateData &&
                        aggrigateData.aggregate &&
                        aggrigateData.aggregate.avg &&
                        aggrigateData.aggregate.avg.time_between_job_parts
                          ? (
                              aggrigateData.aggregate.avg
                                .time_between_job_parts / 60000
                            ).toFixed(3) + " Minutes"
                          : "No Data"}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "3rem",
                      }}
                    >
                      <img
                      // src={timeLoss}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => toggleTab("1")}
              style={{
                backgroundColor: activeTab === "1" ? "#5555e7" : "transparent",
                color: activeTab === "1" ? "white" : "white",
              }}
            >
              Date Wise Cycle Time
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => toggleTab("2")}
              style={{
                backgroundColor: activeTab === "2" ? "#5555e7" : "transparent",
                color: activeTab === "2" ? "white" : "white",
              }}
            >
              Shift Wise Cycle Time
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => toggleTab("3")}
              style={{
                backgroundColor: activeTab === "3" ? "#5555e7" : "transparent",
                color: activeTab === "3" ? "white" : "white",
              }}
            >
              Operator Wise Cycle Time
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "4" })}
              onClick={() => toggleTab("4")}
              style={{
                backgroundColor: activeTab === "4" ? "#5555e7" : "transparent",
                color: activeTab === "4" ? "white" : "white",
              }}
            >
              Cycle Time Deviation
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "5" })}
              onClick={() => toggleTab("5")}
              style={{
                backgroundColor: activeTab === "5" ? "#5555e7" : "transparent",
                color: activeTab === "5" ? "white" : "white",
              }}
            >
              Machine Wise Cycle Time
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "6" })}
              onClick={() => toggleTab("6")}
              style={{
                backgroundColor: activeTab === "6" ? "#5555e7" : "transparent",
                color: activeTab === "6" ? "white" : "white",
              }}
            >
              Part Wise Cycle Time
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col>
                <TableComponent
                  title={"Date Wise Cycle Time"}
                  apiData={apiData}
                  columns={dateWiseColumns}
                />
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="2">
            <Row>
              <Col>
                <TableComponent
                  title={"Shift Wise Cycle Time"}
                  apiData={shiftwiseData}
                  columns={shiftWiseColumns}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col>
                <TableComponent
                  title={"Operator Wise Cycle Time"}
                  apiData={apiData}
                  columns={opertorWiseColumns}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="4">
            <Row>
              <Col>
                <TableComponent
                  title={"Cycle Time Deviation"}
                  apiData={apiData}
                  columns={cycleTimeColumns}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="5">
            <Row>
              <Col>
                <TableComponent
                  title={"Machine Wise Cycle Time"}
                  apiData={apiData}
                  columns={machineWiseColumns}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="6">
            <Row>
              <Col>
                <TableComponent
                  title={"Part Wise Cycle Time"}
                  apiData={apiData}
                  columns={partWiseColumns}
                />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </>
  );
}

export default InfoCycleTime;
