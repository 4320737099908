import React, { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import "react-circular-progressbar/dist/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import LineChart from "assets/charts/LineChart";
import { QueryApi } from "QueryApi";
import PieChart from "assets/charts/PieChart";
import ProductivityFocusLineChart from "./JindalProductivityFocusLineChart";

const ProductivityFocus = () => {
  const [
    xAxisDataValuePerformanceInsights,
    setXAxisDataValuePerformanceInsights,
  ] = useState([]);
  const [plotPointPerformanceInsights, setPlotPointPerformanceInsights] =
    useState([]);
  const [DowntimebigChartData, setDowntimebigChartData] = useState("data1");
  const [DowntimebigChartDataPieChart, setDowntimebigChartDataPieChart] =
    useState("data2");
  const [DowntimebigChartDataPieChartOne, setDowntimebigChartDataPieChartOne] =
    useState("data2");

  const componentName = "";
  const dataList = [
    { value: 60, name: "Operator", color: "#8187E9" },
    { value: 10, name: "Floor Manager", color: "#A3EDBA" },
    { value: 15, name: "Setters", color: "#F19D54" },
    { value: 5, name: "Helpers", color: "#6799A2" },
    { value: 10, name: "Supervisors", color: "#E1D369" },
  ];

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const currDay = day < 10 ? `0${day}` : day;
  const currMonth = month < 10 ? `0${month}` : month;
  let currentDate = JSON.stringify(`${year}-${currMonth}-${currDay}`);

  const dateData = new Date();
  dateData.setDate(dateData.getDate() - 7);
  var dayDataValue = dateData.getDate();
  var monthDataValue = dateData.getMonth() + 1;
  var yearDataValue = dateData.getFullYear();
  var formattedDay = dayDataValue < 10 ? `0${dayDataValue}` : dayDataValue;
  var formattedMonth =
    monthDataValue < 10 ? `0${monthDataValue}` : monthDataValue;
  var weekDataDate = JSON.stringify(
    `${yearDataValue}-${formattedMonth}-${formattedDay}`
  );

  const previousDate = new Date();
  previousDate.setDate(1);
  const previousDay = previousDate.getDate();
  const previousMonth = previousDate.getMonth() + 1;
  const previousYear = previousDate.getFullYear();
  const formattedPreviousDay =
    previousDay < 10 ? `0${previousDay}` : previousDay;
  const formattedPreviousMonth =
    previousMonth < 10 ? `0${previousMonth}` : previousMonth;
  const previousMonthDate = JSON.stringify(
    `${previousYear}-${formattedPreviousMonth}-${formattedPreviousDay}`
  );

  const yearCurrentDate = new Date();
  yearCurrentDate.setDate(1);
  yearCurrentDate.setMonth(1);
  const yearDay = yearCurrentDate.getDate();
  const yearMonth = yearCurrentDate.getMonth();
  const yearYear = yearCurrentDate.getFullYear();
  const formattedYearDay = yearDay < 10 ? `0${yearDay}` : yearDay;
  const formattedYearMonth = yearMonth < 10 ? `0${yearMonth}` : yearMonth;
  const yearPreviousMonthDate = JSON.stringify(
    `${yearYear}-${formattedYearMonth}-${formattedYearDay}`
  );

  /////////////////////// downtime trendline ///////////////////////////////////////////////////

  const datasetPerformanceInsights = [
    {
      label: "Down Time Trendline",
      data: plotPointPerformanceInsights,
      borderColor: "#1F8EF1",
    },
  ];

  useEffect(() => {
    GraphDataListPerformanceInsights();
  }, [DowntimebigChartData]);

  const GraphDataListPerformanceInsights = async () => {
    try {
      const data = await QueryApi(`
      query MyQuery {
        bopp_fl0_total_downtime_yearly {
          orgname {
            organization_node_name
          }
          total_downtime
        }
      }
    `);

      const xdata = data.bopp_fl0_total_downtime_yearly.map((data) => {
        return data.orgname.organization_node_name;
      });

      const ydata = data.bopp_fl0_total_downtime_yearly.map((data) => {
        return data.total_downtime;
      });

      setXAxisDataValuePerformanceInsights(xdata);
      setPlotPointPerformanceInsights(ydata);
    } catch {
      console.log("error");
    }
    // if (DowntimebigChartData === "data1") {
    //   try {
    //     const data = await QueryApi(`
    //     query MyQuery {
    //       bopp_fl0_total_downtime {
    //         date
    //         total_downtime
    //       }
    //     }
    //           `);
    //     setXAxisDataValuePerformanceInsights([
    //       0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
    //       20, 21, 22, 23, 24,
    //     ]);
    //     const plotAllDataPerformanceInsights = data.bopp_fl0_total_downtime.map(
    //       (data) => {
    //         const objData = {
    //           point: (data.total_downtime / (1000 * 60 * 60)).toFixed(
    //             2
    //           ),
    //           Xaxis: data.date,
    //         };
    //         return objData;
    //       }
    //     );
    //     const pointDate = new Date();
    //     const resPointDate = pointDate.getHours();
    //     let plotValue = [];
    //     for (var i = 0; i <= resPointDate; i++) {
    //       plotValue[i] = 0;
    //     }
    //     plotAllDataPerformanceInsights.forEach((data) => {
    //       var d = new Date(data.Xaxis);
    //       var h = d.getHours();
    //       if (h >= 0 && h <= 1) {
    //         plotValue[0] = data.point;
    //       } else if (h >= 1 && h <= 2) {
    //         plotValue[1] = data.point;
    //       } else if (h >= 2 && h <= 3) {
    //         plotValue[2] = data.point;
    //       } else if (h >= 3 && h <= 4) {
    //         plotValue[3] = data.point;
    //       } else if (h >= 4 && h <= 5) {
    //         plotValue[4] = data.point;
    //       } else if (h >= 5 && h <= 6) {
    //         plotValue[5] = data.point;
    //       } else if (h >= 6 && h <= 7) {
    //         plotValue[6] = data.point;
    //       } else if (h >= 7 && h <= 8) {
    //         plotValue[7] = data.point;
    //       } else if (h >= 8 && h <= 9) {
    //         plotValue[8] = data.point;
    //       } else if (h >= 9 && h <= 10) {
    //         plotValue[9] = data.point;
    //       } else if (h >= 10 && h <= 11) {
    //         plotValue[10] = data.point;
    //       } else if (h >= 11 && h <= 12) {
    //         plotValue[11] = data.point;
    //       } else if (h >= 12 && h <= 13) {
    //         plotValue[12] = data.point;
    //       } else if (h >= 13 && h <= 14) {
    //         plotValue[13] = data.point;
    //       } else if (h >= 14 && h <= 15) {
    //         plotValue[14] = data.point;
    //       } else if (h >= 15 && h <= 16) {
    //         plotValue[15] = data.point;
    //       } else if (h >= 16 && h <= 17) {
    //         plotValue[16] = data.point;
    //       } else if (h >= 17 && h <= 18) {
    //         plotValue[17] = data.point;
    //       } else if (h >= 18 && h <= 19) {
    //         plotValue[18] = data.point;
    //       } else if (h >= 19 && h <= 20) {
    //         plotValue[19] = data.point;
    //       } else if (h >= 20 && h <= 21) {
    //         plotValue[20] = data.point;
    //       } else if (h >= 21 && h <= 22) {
    //         plotValue[21] = data.point;
    //       } else if (h >= 22 && h <= 23) {
    //         plotValue[22] = data.point;
    //       } else if (h >= 23 && h <= 24) {
    //         plotValue[23] = data.point;
    //       } else {
    //         return null;
    //       }
    //     });
    //     setPlotPointPerformanceInsights(plotValue);
    //   } catch (error) {
    //     setXAxisDataValuePerformanceInsights([]);
    //     setPlotPointPerformanceInsights([]);
    //   }
    // } else if (DowntimebigChartData === "data2") {
    //   try {
    //     const data = await QueryApi(`
    //             query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
    //                 dm_org_tenant_daily(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
    //                     total_machine_downtime
    //                     date
    //                 }
    //             }
    //           `);
    //     const plotAllDataPerformanceInsights = data.dm_org_tenant_daily.map(
    //       (data) => {
    //         const objData = {
    //           point: (data.total_machine_downtime / (1000 * 60 * 60)).toFixed(2),
    //           Xaxis: data.date,
    //         };
    //         return objData;
    //       }
    //     );
    //     function getPlotAllDaysBetween(startDate, endDate) {
    //       var newDate = new Date(startDate);
    //       var weekDATE = new Date(endDate);
    //       var days = [];
    //       while (newDate <= weekDATE) {
    //         const dd = newDate.getDate();
    //         const mm = newDate.getMonth() + 1;
    //         const yy = newDate.getFullYear();
    //         const formattedd = dd < 10 ? `0${dd}` : dd;
    //         const formattedmm = mm < 10 ? `0${mm}` : mm;
    //         const fullDATE = `${yearYear}-${formattedmm}-${formattedd}`;
    //         days.push(fullDATE);
    //         newDate.setDate(newDate.getDate() + 1);
    //       }
    //       return days;
    //     }
    //     var daysBetween = getPlotAllDaysBetween(weekDataDate, currentDate);
    //     var pointArr = [];
    //     daysBetween.map((data) => {
    //       var arrlet = plotAllDataPerformanceInsights.map((data) => {
    //         var arrDate = new Date(data.Xaxis);
    //         let nday = arrDate.getUTCDate();
    //         let nmonth = arrDate.getMonth() + 1;
    //         let nyear = arrDate.getFullYear();
    //         const ncurrDay = nday < 10 ? `0${nday}` : nday;
    //         const ncurrMonth = nmonth < 10 ? `0${nmonth}` : nmonth;
    //         let nDateList = `${nyear}-${ncurrMonth}-${ncurrDay}`;
    //         return nDateList;
    //       });
    //       const res = arrlet.includes(data);
    //       const resindex = arrlet.indexOf(data);
    //       if (res === true) {
    //         pointArr.push(plotAllDataPerformanceInsights[resindex].point);
    //       } else {
    //         pointArr.push(0);
    //       }
    //     });
    //     setPlotPointPerformanceInsights(pointArr);

    //     function getAllDaysBetween(startDate, endDate) {
    //       var currentDate = new Date(startDate);
    //       var days = [];
    //       while (currentDate <= endDate) {
    //         days.push(new Date(currentDate));
    //         currentDate.setDate(currentDate.getDate() + 1);
    //       }
    //       return days;
    //     }
    //     function getDayOfWeek(date) {
    //       var days = [
    //         "Sunday",
    //         "Monday",
    //         "Tuesday",
    //         "Wednesday",
    //         "Thursday",
    //         "Friday",
    //         "Saturday",
    //       ];
    //       return days[date.getDay()];
    //     }
    //     function getDaysOfWeekBetween(startDate, endDate) {
    //       var daysBetween = getAllDaysBetween(startDate, endDate);
    //       var daysOfWeek = [];
    //       for (var i = 0; i < daysBetween.length; i++) {
    //         var dayOfWeek = getDayOfWeek(daysBetween[i]);
    //         daysOfWeek.push(dayOfWeek);
    //       }
    //       return daysOfWeek;
    //     }
    //     var daysOfWeekBetween = getDaysOfWeekBetween(
    //       new Date(weekDataDate),
    //       new Date(currentDate)
    //     );
    //     setXAxisDataValuePerformanceInsights(daysOfWeekBetween);
    //   } catch (error) {
    //     setXAxisDataValuePerformanceInsights([]);
    //     setPlotPointPerformanceInsights([]);
    //   }
    // } else if (DowntimebigChartData === "data3") {
    //   try {
    //     const data = await QueryApi(`
    //             query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
    //                 dm_org_tenant_daily(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
    //                     total_machine_downtime
    //                     date
    //                 }
    //             }
    //         `);
    //     const plotAllDataProductionVolume = data.dm_org_tenant_daily.map(
    //       (data) => {
    //         const objData = {
    //           point: (data.total_machine_downtime / (1000 * 60 * 60)).toFixed(2),
    //           Xaxis: data.date,
    //         };
    //         return objData;
    //       }
    //     );

    //     const bothAxisPoint = [];
    //     for (var i = 1; i <= 31; i++) {
    //       bothAxisPoint[i] = { pointValue: 0, XaxisValue: i };
    //     }
    //     plotAllDataProductionVolume.forEach((data) => {
    //       const allDate = new Date(data.Xaxis);
    //       const dayss = allDate.getDate();
    //       bothAxisPoint[dayss] = { pointValue: data.point, XaxisValue: dayss };
    //     });
    //     const NewPointData = bothAxisPoint.map((data) => data.pointValue);
    //     const dd = new Date();
    //     NewPointData.splice(dd.getDate() + 1, NewPointData.length);
    //     const NewXaxisData = bothAxisPoint.map((data) => data.XaxisValue);
    //     setPlotPointPerformanceInsights(NewPointData);
    //     setXAxisDataValuePerformanceInsights(NewXaxisData);
    //   } catch (error) {
    //     setXAxisDataValuePerformanceInsights([]);
    //     setPlotPointPerformanceInsights([]);
    //   }
    // } else if (DowntimebigChartData === "data4") {
    //   try {
    //     const data = await QueryApi(`
    //             query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
    //                 dm_org_tenant_monthly(where: {start_date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
    //                     total_machine_downtime
    //                    start_date
    //               }
    //             }
    //         `);
    //     const plotAllDataInsights = data.dm_org_tenant_monthly.map((data) => {
    //       const objData = {
    //         point: (data.total_machine_downtime / (1000 * 60 * 60)).toFixed(2),
    //         Xaxis: data.date,
    //       };
    //       return objData;
    //     });

    //     const plotPointDataInsights = plotAllDataInsights.map(
    //       (data) => data.point
    //     );
    //     setPlotPointPerformanceInsights(plotPointDataInsights);
    //     setXAxisDataValuePerformanceInsights([
    //       "January",
    //       "February",
    //       "March",
    //       "April",
    //       "May",
    //       "June",
    //       "July",
    //       "August",
    //       "September",
    //       "October",
    //       "November",
    //       "December",
    //     ]);
    //   } catch (error) {
    //     setXAxisDataValuePerformanceInsights([]);
    //     setPlotPointPerformanceInsights([]);
    //   }
    // } else {
    //   setDowntimebigChartData("data1");
    // }
  };

  /////////////////////// Count Of Downtime By Reason ///////////////////////////////////////////////////

  const [downtimeData, setDowntimeData] = useState([]);
  const chartRefData = useRef(null);
  PieChart(chartRefData, componentName, downtimeData);

  useEffect(() => {
    DowntimeByReason();
  }, [DowntimebigChartDataPieChart]);

  const funCall = (data) => {
    const newArr = data.map((data, index) => {
      if (index === 0) {
        return {
          value: data.recurrance_rate,
          name: data.downtime_type,
          color: "#8187E9",
        };
      } else if (index === 1) {
        return {
          value: data.recurrance_rate,
          name: data.downtime_type,
          color: "#A3EDBA",
        };
      } else if (index === 2) {
        return {
          value: data.recurrance_rate,
          name: data.downtime_type,
          color: "#F19D54",
        };
      } else if (index === 3) {
        return {
          value: data.recurrance_rate,
          name: data.downtime_type,
          color: "#6799A2",
        };
      } else if (index === 4) {
        return {
          value: data.recurrance_rate,
          name: data.downtime_type,
          color: "#E1D369",
        };
      } else if (index === 5) {
        return {
          value: data.recurrance_rate,
          name: data.downtime_type,
          color: "red",
        };
      } else if (index === 6) {
        return {
          value: data.recurrance_rate,
          name: data.downtime_type,
          color: "yellow",
        };
      } else if (index === 7) {
        return {
          value: data.recurrance_rate,
          name: data.downtime_type,
          color: "gray",
        };
      } else if (index === 8) {
        return {
          value: data.recurrance_rate,
          name: data.downtime_type,
          color: "orange",
        };
      } else if (index === 9) {
        return {
          value: data.recurrance_rate,
          name: data.downtime_type,
          color: "blue",
        };
      } else if (index === 10) {
        return {
          value: data.recurrance_rate,
          name: data.downtime_type,
          color: "green",
        };
      } else {
        return {
          value: data.recurrance_rate,
          name: data.downtime_type,
          color: "red",
        };
      }
    });
    return newArr;
  };

  const DowntimeByReason = async () => {
    if (DowntimebigChartDataPieChart === "data1") {
      try {
        const data = await QueryApi(`
query MyQuery {
  extrusion_dt_reason_by_type_yearly(where: {machineid: {_eq: "jumbo001"}}) {
    downtime_type
    downtime_contribution_percent
    recurrance_rate
  }
}
               `);
        const allData = funCall(data.extrusion_dt_reason_by_type_yearly);
        setDowntimeData(allData);
      } catch (error) {}
    } else if (DowntimebigChartDataPieChart === "data2") {
      try {
        const data = await QueryApi(`
query MyQuery {
  extrusion_dt_reason_by_type_yearly(where: {machineid: {_eq: "jumbo001"}}) {
    downtime_type
    downtime_contribution_percent
    recurrance_rate
  }
}
               `);
        const allData = funCall(data.extrusion_dt_reason_by_type_yearly);
        setDowntimeData(allData);
      } catch (error) {}
    } else if (DowntimebigChartDataPieChart === "data3") {
      try {
        const data = await QueryApi(`
query MyQuery {
  extrusion_dt_reason_by_type_yearly(where: {machineid: {_eq: "jumbo001"}}) {
    downtime_type
    downtime_contribution_percent
    recurrance_rate
  }
}
               `);
        const allData = funCall(data.extrusion_dt_reason_by_type_yearly);
        setDowntimeData(allData);
      } catch (error) {}
    } else if (DowntimebigChartDataPieChart === "data4") {
      try {
        const data = await QueryApi(`
query MyQuery {
  extrusion_dt_reason_by_type_yearly(where: {machineid: {_eq: "jumbo001"}}) {
    downtime_type
    downtime_contribution_percent
    recurrance_rate
  }
}
               `);
        const allData = funCall(data.extrusion_dt_reason_by_type_yearly);
        setDowntimeData(allData);
      } catch (error) {}
    } else {
      setDowntimebigChartDataPieChart("data1");
    }
  };

  //////// Duration Of Downtime By Reason /////////////////////////////////////////////////////////////////////

  const [DowntimeReasonData, setDowntimeReasonData] = useState([]);
  const newchartRefData = useRef(null);
  PieChart(newchartRefData, componentName, DowntimeReasonData);

  useEffect(() => {
    NewDowntimeByReason();
  }, [DowntimebigChartDataPieChartOne]);

  const newfunCall = (data) => {
    const newArr = data.map((data, index) => {
      if (index === 0) {
        return {
          value: data.downtime_contribution_percent,
          name: data.downtime_type,
          color: "#8187E9",
        };
      } else if (index === 1) {
        return {
          value: data.downtime_contribution_percent,
          name: data.downtime_type,
          color: "#A3EDBA",
        };
      } else if (index === 2) {
        return {
          value: data.downtime_contribution_percent,
          name: data.downtime_type,
          color: "#F19D54",
        };
      } else if (index === 3) {
        return {
          value: data.downtime_contribution_percent,
          name: data.downtime_type,
          color: "#6799A2",
        };
      } else if (index === 4) {
        return {
          value: data.downtime_contribution_percent,
          name: data.downtime_type,
          color: "#E1D369",
        };
      } else if (index === 5) {
        return {
          value: data.downtime_contribution_percent,
          name: data.downtime_type,
          color: "red",
        };
      } else if (index === 6) {
        return {
          value: data.downtime_contribution_percent,
          name: data.downtime_type,
          color: "yellow",
        };
      } else if (index === 7) {
        return {
          value: data.downtime_contribution_percent,
          name: data.downtime_type,
          color: "gray",
        };
      } else if (index === 8) {
        return {
          value: data.downtime_contribution_percent,
          name: data.downtime_type,
          color: "orange",
        };
      } else if (index === 9) {
        return {
          value: data.downtime_contribution_percent,
          name: data.downtime_type,
          color: "blue",
        };
      } else if (index === 10) {
        return {
          value: data.downtime_contribution_percent,
          name: data.downtime_type,
          color: "green",
        };
      } else {
        return {
          value: data.recurrance_rate,
          name: data.downtime_type,
          color: "red",
        };
      }
    });
    return newArr;
  };

  const NewDowntimeByReason = async () => {
    if (DowntimebigChartDataPieChartOne === "data1") {
      try {
        const data = await QueryApi(`
query MyQuery {
  extrusion_dt_reason_by_type_yearly(where: {machineid: {_eq: "jumbo001"}}) {
    downtime_type
    downtime_contribution_percent
    recurrance_rate
  }
}
               `);
        const allData = newfunCall(data.extrusion_dt_reason_by_type_yearly);
        setDowntimeReasonData(allData);
      } catch (error) {}
    } else if (DowntimebigChartDataPieChartOne === "data2") {
      try {
        const data = await QueryApi(`
query MyQuery {
  extrusion_dt_reason_by_type_yearly(where: {machineid: {_eq: "jumbo001"}}) {
    downtime_type
    downtime_contribution_percent
    recurrance_rate
  }
}
               `);
        const allData = newfunCall(data.extrusion_dt_reason_by_type_yearly);
        setDowntimeReasonData(allData);
      } catch (error) {}
    } else if (DowntimebigChartDataPieChartOne === "data3") {
      try {
        const data = await QueryApi(`
 query MyQuery {
  extrusion_dt_reason_by_type_yearly(where: {machineid: {_eq: "jumbo001"}}) {
    downtime_type
    downtime_contribution_percent
    recurrance_rate
  }
}
               `);
        const allData = newfunCall(data.extrusion_dt_reason_by_type_yearly);
        setDowntimeReasonData(allData);
      } catch (error) {}
    } else if (DowntimebigChartDataPieChartOne === "data4") {
      try {
        const data = await QueryApi(`
query MyQuery {
  extrusion_dt_reason_by_type_yearly(where: {machineid: {_eq: "jumbo001"}}) {
    downtime_type
    downtime_contribution_percent
    recurrance_rate
  }
}
               `);
        const allData = newfunCall(data.extrusion_dt_reason_by_type_yearly);
        setDowntimeReasonData(allData);
      } catch (error) {}
    } else {
      setDowntimebigChartDataPieChartOne("data1");
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          height: "400px",
        }}
      >
        <Col lg="9" md="9" xs="9">
          <div style={{ display: "flex", flexWrap: "wrap", height: "100%" }}>
            <Card className="card-chart" style={{ height: "97%" }}>
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5 className="card-category">Downtime Trendline</h5>
                  </Col>
                  <Col sm="6">
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <Button
                        tag="label"
                        className={classNames("btn-simple", {
                          active: DowntimebigChartData === "data1",
                        })}
                        color="info"
                        id="0"
                        size="sm"
                        onClick={() => setDowntimebigChartData("data1")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Day
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-single-02" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: DowntimebigChartData === "data2",
                        })}
                        onClick={() => setDowntimebigChartData("data2")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Week
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-gift-2" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: DowntimebigChartData === "data3",
                        })}
                        onClick={() => setDowntimebigChartData("data3")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Month
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-tap-02" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: DowntimebigChartData === "data4",
                        })}
                        onClick={() => setDowntimebigChartData("data4")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Year
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-tap-02" />
                        </span>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <LineChart
                  xAxisData={xAxisDataValuePerformanceInsights}
                  dataset={datasetPerformanceInsights}
                  height="100%"
                  width="100%"
                />
              </CardBody>
            </Card>
          </div>
        </Col>
      </div>
      <Row style={{ height: "50rem" }}>
        <Col lg="6" md="6" xs="6">
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <Card className="card-chart" style={{ height: "430px" }}>
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5 className="card-category">
                      Count Of Downtime by Reason
                    </h5>
                  </Col>
                  <Col sm="6">
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: DowntimebigChartDataPieChart === "data2",
                        })}
                        onClick={() => setDowntimebigChartDataPieChart("data2")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Week
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-gift-2" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: DowntimebigChartDataPieChart === "data3",
                        })}
                        onClick={() => setDowntimebigChartDataPieChart("data3")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Month
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-tap-02" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: DowntimebigChartDataPieChart === "data4",
                        })}
                        onClick={() => setDowntimebigChartDataPieChart("data4")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Year
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-tap-02" />
                        </span>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div
                  ref={chartRefData}
                  style={{ width: "100%", height: "400px" }}
                />
              </CardBody>
            </Card>
          </div>
        </Col>
        <Col lg="6" md="6" xs="6">
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <Card className="card-chart" style={{ height: "430px" }}>
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5 className="card-category">
                      Duration Of Downtime by Reason
                    </h5>
                  </Col>
                  <Col sm="6">
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: DowntimebigChartDataPieChartOne === "data2",
                        })}
                        onClick={() =>
                          setDowntimebigChartDataPieChartOne("data2")
                        }
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Week
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-gift-2" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: DowntimebigChartDataPieChartOne === "data3",
                        })}
                        onClick={() =>
                          setDowntimebigChartDataPieChartOne("data3")
                        }
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Month
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-tap-02" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: DowntimebigChartDataPieChartOne === "data4",
                        })}
                        onClick={() =>
                          setDowntimebigChartDataPieChartOne("data4")
                        }
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Year
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-tap-02" />
                        </span>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div
                  ref={newchartRefData}
                  style={{ width: "100%", height: "400px" }}
                />
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ProductivityFocus;
