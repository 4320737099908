import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Progress,
} from "reactstrap";
import Select from "react-select";
import axios from "axios";
import  { APIURL } from './Apiurl.js';
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

function TenantAdministration() {
  const [activeStep, setActiveStep] = useState(1);
  const [checked, setChecked] = useState(false);
  const [progress, setProgress] = useState(0);
    const [udpedit, setudpedit] = useState({
    tenantid: "",
    clientname: "",
    holdingcname: "",
    holdingcid: "",
    country: "",
    location: "",
    address: "",
    industrysegment: "",
    machinetype1: "",
    nofmachinetype1: "",
    machinetype2: "",
    nofmachinetype2: "",
    machinetype3: "",
    nofmachinetype3: "",
    machinetype4: "",
    nofmachinetype4: "",
    machinetype5: "",
    nofmachinetype5: "",
    totalmachines: "",
    accountmanager: "",
    accmngeremail: "",
    promoter: "",
    directoremail: "",
    spocemail: "",
    purchasespocemail: "",
    billingmethod: "",
    startdate: "",
    enddate: "",

  });

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${APIURL}/api/tenant-administration/${id}`)
      .then((response) => {
        setudpedit((prevState) => ({
          ...prevState,
          tenantid: response.data.tenant_id,
          holdingcname: response.data.holding_company_name,
          holdingcid: response.data.holding_company_id,
          country: response.data.country, 
          location: response.data.location,
          address: response.data.address,
          industrysegment: response.data.industry_segment,
          machinetype1: response.data.machine_type1,
          nofmachinetype1: response.data.no_of_machine_type_1,
          machinetype2: response.data.machine_type2,
          nofmachinetype2: response.data.no_of_machine_type_2,
          machinetype3: response.data.machine_type3,
          nofmachinetype3: response.data.no_of_machine_type_3,
          machinetype4: response.data.machine_type4,
          nofmachinetype4: response.data.no_of_machine_type_4,
          machinetype5: response.data.machine_type5,
          nofmachinetype5: response.data.no_of_machine_type_5,
          totalmachines: response.data.total_machines,
          accountmanager: response.data.account_manager,
          accmngeremail: response.data.account_manager_email_id,
          promoter: response.data.promoter_or_director,
          directoremail: response.data.director_email_id,
          spocemail: response.data.finance_spoc_email_id,
          purchasespocemail: response.data.purchase_spoc_email_id,
          billingmethod: response.data.billing_method,
          startdate: response.data.start_datetime,
          enddate: response.data.end_datetime,


        }));
       
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if the machine name is provided
   
    axios
      .post(`${APIURL}/api/tenant-administration/${id}`, udpedit)
      .then((response) => {
        console.log(response);
        navigate(`/udp/${id}`);
        if (response.status === 201) {
          window.location.href = "/admin/tenant-administration/all";
        } else {
          // Reset the form or show a success message
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setudpedit((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  const handleNext = () => {
    if (activeStep === 1 && progress === 0) {
      setProgress(50);
    } else if (activeStep === 2 && progress === 50) {
      setProgress(100);
    }
    setActiveStep(activeStep + 1);
  };

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };

 
  const renderStepContent = () => {
    switch (activeStep) {
      case 1:
        return (
          <>
           <Row>
                  
                    <Col className="px-md-1" md="6">
                      <FormGroup>
                        <label>Tenant ID</label>
                        <Input
                          placeholder="Tenant ID"
                          type="text"
                            name="tenantid"
                          value={udpedit.tenantid}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Tenant ClientName</label>
                        <Input
                          placeholder="Tenant ClientName"
                          name="clientname"
                          type="text"
                          value={udpedit.clientname}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Holding Company Name</label>
                        <Input
                          placeholder="Holding Company Name"
                          type="text"
                          name="holdingcname"
                          value={udpedit.holdingcname}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Holding Company ID</label>
                        <Input
                          placeholder="Holding Company ID"
                          type="text"
                          name="holdingcid"
                          value={udpedit.holdingcid}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Country</label>
                        <Input
                          placeholder="Country"
                          type="text"
                          name="country"
                          value={udpedit.country}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Location</label>
                        <Input
                          placeholder="Location"
                          type="text"
                        name="location"
                          value={udpedit.location}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Address</label>
                        <Input
                          placeholder="Address"
                          type="text"
                          name="address"
                          value={udpedit.address}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Industry Segment</label>
                        <Input
                          placeholder="Industry Segment"
                          type="text"
                          name="insdustrysegment"
                          value={udpedit.industrysegment}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Machine Type 1</label>
                        <Input
                          placeholder="Machine Type 1"
                          type="text"
                          name="machinetype1"
                          value={udpedit.machinetype1}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>No of Machines Type 1</label>
                        <Input
                          placeholder="No of Machines Type 1"
                          type="number"
                          name="nofmachinetype1"
                          value={udpedit.nofmachinetype1}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Machine Type 2</label>
                        <Input
                          placeholder="Machine Type 2"
                          type="text"
                          name="machinetype2"
                          value={udpedit.machinetype2}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>No of Machines Type 2</label>
                        <Input
                          placeholder="No of Machines Type 2"
                          type="number"
                          name="nofmachinetype2"
                          value={udpedit.nofmachinetype2}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
          </>
        );
      case 2:
        return (
          <>
         <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Machine Type 3</label>
                        <Input
                          placeholder="Machine Type 3"
                          type="text"
                           name="machinetype3"
                          value={udpedit.machinetype3}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>No of Machines Type 3</label>
                        <Input
                          placeholder="No of Machines Type 3"
                          type="number"
                          name="nofmachinetype3"
                          value={udpedit.nofmachinetype3}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Machine Type 4</label>
                        <Input
                          placeholder="Machine Type 4"
                          type="text"
                          name="machinetype4"
                          value={udpedit.machinetype4}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>No of Machines Type 4</label>
                        <Input
                          placeholder="No of Machines Type 4"
                          type="number"
                          name="nofmachinetype4"
                          value={udpedit.nofmachinetype4}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Machine Type 5</label>
                        <Input
                          placeholder="Machine Type 5"
                          type="text"
                          name="machinetype5"
                          value={udpedit.machinetype5}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>No of Machines Type 5</label>
                        <Input
                          placeholder="No of Machines Type 5"
                          type="number"
                          name="nofmachinetype5"
                          value={udpedit.nofmachinetype5}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Total Machines</label>
                        <Input
                          placeholder="Total Machines"
                          type="number"
                          name="totalmachines"
                          value={udpedit.totalmachines}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Account Manager</label>
                        <Input
                          placeholder="Account Manager"
                          type="text"
                          name="accountmanager"
                          value={udpedit.accountmanager}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Account Manager Email ID</label>
                        <Input
                          placeholder="Account Manager Email ID"
                          type="email"
                          name="accmngeremail"
                          value={udpedit.accmngeremail}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Promoter or Director</label>
                        <Input
                          placeholder="Promoter or Director"
                          type="text"
                          name="promoter"
                          value={udpedit.promoter}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Director Email</label>
                        <Input
                          placeholder="Director Email"
                          type="email"
                          name="directoremail"
                          value={udpedit.directoremail}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Finance SPOC Email ID</label>
                        <Input
                          placeholder="Finance SPOC Email ID"
                          type="email"
                          name="spocemail"
                          value={udpedit.spocemail}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
          </>
        );
      case 3:
        return (
          <>
         <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Purchase SPOC Email ID</label>
                        <Input
                          placeholder="Purchase SPOC Email ID"
                          type="email"
                            name="purchasespocemail"
                          value={udpedit.purchasespocemail}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Billing Method</label>
                        <Input
                          placeholder="Billing Method"
                          type="text"
                          name="billingmethod"
                          value={udpedit.billingmethod}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Invoice Frequency</label>
                        <Input
                          placeholder="Invoice Frequency"
                          type="text"
                          name="invoicefrequency"
                          value={udpedit.invoicefrequency}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Tenant Subdomain</label>
                        <Input
                          placeholder="Tenant Subdomain"
                          type="text"
                          name="tenantsubdomain"
                          value={udpedit.tenantsubdomain}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Start Date</label>
                        <Input
                          placeholder="Start Date"
                          type="date"
                          name="startdate"
                          value={udpedit.startdate}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>End Date</label>
                        <Input
                          placeholder="End Date"
                          type="date"
                            name="enddate"
                          value={udpedit.enddate}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
               
          </>
        );
      default:
        return null;
    }
  };
  const calculateProgress = () => {
    return Math.round(((activeStep - 1) / 2) * 100); // Update the denominator to match the total number of steps minus one
  };
  

  const renderProgressBarDestinations = () => {
    const step1Class = activeStep >= 1 ? "progress-destination-active" : "";
    const step2Class = activeStep >= 2 ? "progress-destination-active" : "";
    const step3Class = activeStep >= 3 ? "progress-destination-active" : "";
    const step4Class = activeStep >= 4 ? "progress-destination-active" : "";
  
    return (
      <div className="progress-destinations">
        <div className={`progress-destination ${step1Class}`}>
          <span>1</span>
        </div>
        <div className={`progress-destination ${step2Class}`}>
          <span>2</span>
        </div>
        <div className={`progress-destination ${step3Class}`}>
          <span>3</span>
        </div>
        <div className={`progress-destination ${step4Class}`}>
          <span>4</span>
        </div>
      </div>
    );
  };

  return (
    <div className="content">
      <Row>
        <Col md="8">
          <div className="progress-container">
            {renderProgressBarDestinations()}
            <Progress
              max="100"
              value={calculateProgress()}
              className="progress-bar-sm progress-bar-striped"
            />
          </div>
        </Col>
      </Row>
      <Row className="progress-row">
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Add New Machine</h5>
            </CardHeader>
            <CardBody>
              <Form>{renderStepContent()}</Form>
            </CardBody>
            <CardFooter>
              <div className="text-right">
                {activeStep > 1 && (
                  <Button color="primary" onClick={handlePrevious}>
                    Previous
                  </Button>
                )}
                {activeStep < 3 && (
                  <Button color="primary" onClick={handleNext}>
                    Next
                  </Button>
                )}
                {activeStep === 3 && (
                  <Button color="success" onClick={handleSubmit}>
                    Submit
                  </Button>
                )}
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default TenantAdministration;
