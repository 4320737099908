import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, Card, CardHeader, CardBody } from "reactstrap";
import redirectToLoginIfTokenMissing from "views/TokenUtility";
import classNames from "classnames";

function ProductivityWorkForce(props) {
  const [activeTab, setActiveTab] = useState("1");
  const storedTenantId = sessionStorage.getItem("selectedTenantId");
  useEffect(() => {
    redirectToLoginIfTokenMissing();
  }, []);

  return (
    <>
      <div className="content">
        <Card
          style={{
            background: "transparent",
            boxShadow: "0px 10px 20px 2px rgba(0,0,0,0.75)",
          }}
        >
          <CardHeader>
            <div className="d-flex justify-content-end">
              <div>
                <ButtonGroup
                  className="btn-group-toggle float-right"
                  data-toggle="buttons"
                >
                  <Button
                    tag="label"
                    className={classNames("btn-simple", {
                      // active: bigChartData === "data1",
                    })}
                    color="info"
                    id="0"
                    size="sm"
                    style={{ width: "100px" }}
                    // onClick={() => setBgChartData("data1")}
                  >
                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      Day
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="tim-icons icon-single-02" />
                    </span>
                  </Button>
                  <Button
                    color="info"
                    id="1"
                    size="sm"
                    style={{ width: "100px" }}
                    tag="label"
                    className={classNames("btn-simple", {
                      // active: bigChartData === "data2",
                    })}
                    // onClick={() => setBgChartData("data2")}
                  >
                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      Week
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="tim-icons icon-gift-2" />
                    </span>
                  </Button>
                  <Button
                    color="info"
                    id="2"
                    size="sm"
                    style={{ width: "100px" }}
                    tag="label"
                    className={classNames("btn-simple", {
                      // active: bigChartData === "data3",
                    })}
                    // onClick={() => setBgChartData("data3")}
                  >
                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      Month
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="tim-icons icon-tap-02" />
                    </span>
                  </Button>
                  <Button
                    color="info"
                    id="2"
                    size="sm"
                    style={{ width: "100px" }}
                    tag="label"
                    className={classNames("btn-simple", {
                      // active: bigChartData === "data4",
                    })}
                    // onClick={() => setBgChartData("data4")}
                  >
                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      Year
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="tim-icons icon-tap-02" />
                    </span>
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "0.8rem",
                marginLeft: "0.5rem",
                height: "13rem",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                    gap: "0.8rem",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        No Data
                      </div>
                      <div style={{ fontSize: "1.2rem", color: "#1976D2" }}>
                        {/* {targetAdherenceDataList.target_adherence ? targetAdherenceDataList.target_adherence : "No Data"} */}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "3rem",
                      }}
                    >
                      <img
                      // src={targetImg}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                      cursor: "pointer",
                    }}
                    // onClick={() => navigate('/admin/dashboard/cycle-time-analysis')}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        No Data
                      </div>
                      <div style={{ fontSize: "1.2rem", color: "#1976D2" }}>
                        {/* {CycleTimeData ? CycleTimeData : "No Data"}  */}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "3rem",
                      }}
                    >
                      <img
                      // src={cycleTime}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        No Data
                      </div>
                      <div style={{ fontSize: "1.2rem", color: "#1976D2" }}>
                        {/* {TodayProductionData ? TodayProductionData : "No Data"} */}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "3rem",
                      }}
                    >
                      <img
                      // src={todayProductionImg}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        {" "}
                        No Data
                      </div>
                      <div style={{ fontSize: "1.2rem", color: "#1976D2" }}>
                        {/* {WorkforceAvailabilityData ? WorkforceAvailabilityData : "No Data"} */}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "3rem",
                      }}
                    >
                      <img
                      // src={workforceAvailability}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        No Data
                      </div>
                      <div style={{ fontSize: "1.2rem", color: "#1976D2" }}>
                        {/* {WorkforceAvailabilityData ? WorkforceAvailabilityData : "No Data"} */}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "3rem",
                      }}
                    >
                      <img
                      // src={workforceAvailability}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        No Data
                      </div>
                      <div style={{ fontSize: "1.2rem", color: "#1976D2" }}>
                        {/* {WorkforceAvailabilityData ? WorkforceAvailabilityData : "No Data"} */}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "3rem",
                      }}
                    >
                      <img
                      // src={workforceAvailability}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                    gap: "0.8rem",
                    marginBottom: "0.6rem",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        No Data
                      </div>
                      <div style={{ fontSize: "1.2rem", color: "#1976D2" }}>
                        {/* {TodayProductionData ? TodayProductionData : "No Data"} */}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "5.5rem",
                        width: "3rem",
                      }}
                    >
                      <img
                      // src={runTime}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        No Data
                      </div>
                      <div style={{ fontSize: "1.2rem", color: "#1976D2" }}>
                        {/* {TodayTimeLossData ? TodayTimeLossData : "No Data"} */}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "3rem",
                      }}
                    >
                      <img
                      // src={timeLoss}
                      />
                    </div>
                  </div>
                  <div
                    //  onClick={() => navigate('/admin/dashboard/productivity')}
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        No Data
                      </div>
                      <div style={{ fontSize: "1.2rem", color: "#1976D2" }}>
                        {/* {dailyUpdatesDataList} */}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        No Data
                      </div>
                      <div
                        style={{ fontSize: "1.2rem", color: "#1976D2" }}
                      ></div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        No Data
                      </div>
                      <div
                        style={{ fontSize: "1.2rem", color: "#1976D2" }}
                      ></div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        No Data
                      </div>
                      <div
                        style={{ fontSize: "1.2rem", color: "#1976D2" }}
                      ></div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default ProductivityWorkForce;
