import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import PopUpProductionTime from "./PopupProductionTime";
import { Button, ButtonGroup, Card, CardHeader, CardBody } from "reactstrap";
import "react-circular-progressbar/dist/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import redirectToLoginIfTokenMissing from "views/TokenUtility";
import axios from "axios";
import { APIURL } from "views/Apiurl";
import { QueryApi } from "QueryApi";
import timeLoss from "assets/img/timeLoss.svg";
import PopUpPlantOperatingTime from "./PopUpPlantOperatingTime";
import TableComponent from "./Productivity Grids/TableComponent";

function ProductivityTime(props) {
  const [activeTab, setActiveTab] = useState("1");
  const storedTenantId = sessionStorage.getItem("selectedTenantId");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenpopUpProductiontime, setIsOpenPopUpProductiontime] =
    useState(false);
  const [openCard, setOpenCard] = useState("PlantOperatingTimeGridCard");

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    redirectToLoginIfTokenMissing(); // Call the utility function to validate the token
    // Your component's logic...
  }, []);
  const [org, setorg] = useState("");
  const [orgnames, setOrgnames] = useState([]);

  const [unit, setunit] = useState("");

  const [dept, setdept] = useState("");
  const [depts, setdepts] = useState([]);

  const [plants, setplants] = useState([]);
  const [orgs, setorgs] = useState([]);
  const [otherData, setOtherData] = useState([]);
  const [selectedOrgData, setSelectedOrgData] = useState(null);
  const token = sessionStorage.getItem("token");
  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const tenantId =
    decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
  useEffect(() => {
    fetchplant();
  }, [org]);

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const currDay = day < 10 ? `0${day}` : day;
  const currMonth = month < 10 ? `0${month}` : month;
  let currentDate = JSON.stringify(`${year}-${currMonth}-${currDay}`);

  const dateData = new Date();
  dateData.setDate(dateData.getDate() - 7);
  var dayDataValue = dateData.getDate();
  var monthDataValue = dateData.getMonth() + 1;
  var yearDataValue = dateData.getFullYear();
  var formattedDay = dayDataValue < 10 ? `0${dayDataValue}` : dayDataValue;
  var formattedMonth =
    monthDataValue < 10 ? `0${monthDataValue}` : monthDataValue;
  var weekDataDate = JSON.stringify(
    `${yearDataValue}-${formattedMonth}-${formattedDay}`
  );

  const previousDate = new Date();
  previousDate.setDate(1);
  const previousDay = previousDate.getDate();
  const previousMonth = previousDate.getMonth() + 1;
  const previousYear = previousDate.getFullYear();
  const formattedPreviousDay =
    previousDay < 10 ? `0${previousDay}` : previousDay;
  const formattedPreviousMonth =
    previousMonth < 10 ? `0${previousMonth}` : previousMonth;
  const previousMonthDate = JSON.stringify(
    `${previousYear}-${formattedPreviousMonth}-${formattedPreviousDay}`
  );

  const yearCurrentDate = new Date();
  yearCurrentDate.setDate(1);
  yearCurrentDate.setMonth(1);
  const yearDay = yearCurrentDate.getDate();
  const yearMonth = yearCurrentDate.getMonth();
  const yearYear = yearCurrentDate.getFullYear();
  const formattedYearDay = yearDay < 10 ? `0${yearDay}` : yearDay;
  const formattedYearMonth = yearMonth < 10 ? `0${yearMonth}` : yearMonth;
  const yearPreviousMonthDate = JSON.stringify(
    `${yearYear}-${formattedYearMonth}-${formattedYearDay}`
  );

  const fetchorg = async (logintenantId) => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
          query MyQuery($logintenantId: Int!) {
            tnt_organization_structure(
              where: {
                parent_organization_id: { _eq: 0 },
                tenantid: { _eq: $logintenantId }
              }
            ) {
              organization_structure_id
              organization_node_name
            }
          }
        `,
          variables: {
            logintenantId: parseInt(logintenantId),
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      const fetchedOrgs = response.data.data.tnt_organization_structure;
      setorgs(fetchedOrgs);
      const parentIds = fetchedOrgs.map((org) => org.organization_structure_id);
      const childResponse = await axios.post(
        APIURL,
        {
          query: `
          query MyQuery($parentIds: [Int!]) {
            tnt_organization_structure(
              where: {
                parent_organization_id: { _in: $parentIds }
              }
            ) {
              organization_structure_id
              organization_node_name
            }
          }
        `,
          variables: {
            parentIds: parentIds,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      const childOrgs = childResponse.data.data.tnt_organization_structure;
      const updatedOtherData = otherData.map((row) => {
        const matchingOrg = childOrgs.find(
          (org) => org.organization_structure_id === row.parent_organization_id
        );

        if (matchingOrg) {
          return {
            ...row,
            parent_organization_id: matchingOrg.organization_structure_id,
          };
        }

        return row;
      });

      setOtherData(childOrgs);
    } catch (error) {}
  };
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["sub"];
    let logintenantId = null;

    if (roleId === 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
    }

    fetchorg(logintenantId);
  }, []);
  const fetchplant = async () => {
    if (org) {
      // Only fetch if org is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
            query MyQuery($p: Int!) {
              tnt_organization_structure(where: {parent_organization_id: {_eq: $p}}) {
                organization_structure_id
                organization_node_name
              }
            }
          `,
            variables: {
              p: org,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        setplants(response.data.data.tnt_organization_structure);
      } catch (error) {}
    }
  };
  useEffect(() => {
    fetchdept();
  }, [unit]);
  const fetchdept = async () => {
    if (unit) {
      // Only fetch if org is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
            query MyQuery($d: Int!) {
              tnt_organization_structure(where: {parent_organization_id: {_eq: $d}}) {
                organization_structure_id
                organization_node_name
              }
            }
          `,
            variables: {
              d: unit,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        setdepts(response.data.data.tnt_organization_structure);
      } catch (error) {}
    }
  };

  const handleOrgSelect = async (selectedOrgId) => {
    if (selectedOrgId) {
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
            query GetCostCenter($orgId: Int!) {
              tnt_organization_structure(where: { organization_structure_id: { _eq: $orgId } }) {
                cost_centre_code
              }
            }
          `,
            variables: {
              orgId: selectedOrgId,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );

        if (
          response.data &&
          response.data.data &&
          response.data.data.tnt_organization_structure &&
          response.data.data.tnt_organization_structure.length > 0
        ) {
          setSelectedOrgData(response.data.data.tnt_organization_structure[0]);
        } else {
          setSelectedOrgData(null);
        }
      } catch (error) {}
    } else {
      setSelectedOrgData(null);
    }

    setorg(selectedOrgId);
  };

  const darkColor = "rgba(0, 0, 0, 0.8)";
  const lightColor = "rgba(0, 0, 0, 0.1)";

  const gradientSteps = 10; // Adjust the number of steps for the shading effect
  const colorStops = [];
  for (let i = 0; i <= gradientSteps; i++) {
    const intensity = i / gradientSteps;
    const r = Math.round(
      intensity * parseInt(lightColor.substring(4, 7)) +
        (1 - intensity) * parseInt(darkColor.substring(4, 7))
    );
    const g = Math.round(
      intensity * parseInt(lightColor.substring(9, 12)) +
        (1 - intensity) * parseInt(darkColor.substring(9, 12))
    );
    const b = Math.round(
      intensity * parseInt(lightColor.substring(14, 17)) +
        (1 - intensity) * parseInt(darkColor.substring(14, 17))
    );
    const colorStop = `rgb(${r}, ${g}, ${b})`;
    colorStops.push(colorStop);
  }
  const gradientColor = `linear-gradient(to right, ${colorStops.join(", ")})`;

  //////Target Adherence ////////////////////////////////////////////////////////////////////////

  const [dayData, setDayData] = useState({
    details: "This Day",
    target: "No Data",
    achieved: "No Data",
    targetValue: "No Data",
  });
  useEffect(() => {
    targetAdherenceDay();
  }, []);
  const targetAdherenceDay = async () => {
    try {
      const data = await QueryApi(`
        query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
            dm_daily(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
            target_parts
            total_parts_produced
            target_adherence
            }
        }
        `);
      const targetPartsSum = data.dm_daily.reduce(
        (acc, curr) => acc + curr.target_parts,
        0
      );
      const partsProducedSum = data.dm_daily.reduce(
        (acc, curr) => acc + curr.total_parts_produced,
        0
      );
      const targetAdherenceSum = data.dm_daily.reduce(
        (acc, curr) => acc + curr.target_adherence,
        0
      );
      setDayData({
        details: "This Day",
        target: targetPartsSum,
        achieved: partsProducedSum,
        targetValue: targetAdherenceSum,
      });
    } catch (error) {
      setDayData({
        details: "This Day",
        target: "No Data",
        achieved: "No Data",
        targetValue: "No Data",
      });
    }
  };

  const [weekData, setWeekData] = useState({
    details: "This Week",
    target: "No Data",
    achieved: "No Data",
    targetValue: "No Data",
  });
  useEffect(() => {
    targetAdherenceWeek();
  }, []);
  const targetAdherenceWeek = async () => {
    try {
      const data = await QueryApi(`
        query MyQuery($start_date: date = ${weekDataDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
            dm_weekly(where: {start_date: {_eq: $start_date}, end_date: {_eq: $end_date}, tenantid: {_eq: $tenantid}}) {
                target_adherence
                target_parts
                total_parts_produced
            }
            }
        `);
      const targetPartsSumweekly = data.dm_weekly.reduce(
        (acc, curr) => acc + curr.target_parts,
        0
      );
      const partsProducedSumweekly = data.dm_weekly.reduce(
        (acc, curr) => acc + curr.total_parts_produced,
        0
      );
      const targetAdherenceSumweekly = data.dm_weekly.reduce(
        (acc, curr) => acc + curr.target_adherence,
        0
      );
      setWeekData({
        details: "This Week",
        target: targetPartsSumweekly,
        achieved: partsProducedSumweekly,
        targetValue: targetAdherenceSumweekly,
      });
    } catch (error) {
      setDayData({
        details: "This Week",
        target: "No Data",
        achieved: "No Data",
        targetValue: "No Data",
      });
    }
  };

  const [monthData, setMonthData] = useState({
    details: "This Month",
    target: "No Data",
    achieved: "No Data",
    targetValue: "No Data",
  });
  useEffect(() => {
    targetAdherenceMonth();
  }, []);
  const targetAdherenceMonth = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${previousMonthDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
                dm_monthly(where: {start_date: {_eq: $start_date}, end_date: {_eq: $end_date}, tenantid: {_eq: $tenantid}}) {
                  total_parts_produced
                  target_adherence
                  target_parts
                }
              }
        `);
      const targetPartsSumMonthly = data.dm_monthly.reduce(
        (acc, curr) => acc + curr.target_parts,
        0
      );
      const partsProducedSumMonthly = data.dm_monthly.reduce(
        (acc, curr) => acc + curr.total_parts_produced,
        0
      );
      const targetAdherenceSumMonthly = data.dm_monthly.reduce(
        (acc, curr) => acc + curr.target_adherence,
        0
      );
      setMonthData({
        details: "This Month",
        target: targetPartsSumMonthly,
        achieved: partsProducedSumMonthly,
        targetValue: targetAdherenceSumMonthly,
      });
    } catch (error) {
      setDayData({
        details: "This Month",
        target: "No Data",
        achieved: "No Data",
        targetValue: "No Data",
      });
    }
  };

  const [yearData, setYearData] = useState({
    details: "Year Till Date",
    target: "No Data",
    achieved: "No Data",
    targetValue: "No Data",
  });
  useEffect(() => {
    targetAdherenceYear();
  }, []);
  const targetAdherenceYear = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${yearPreviousMonthDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
                dm_yearly(where: {start_date: {_eq: $start_date}, end_date: {_eq: $end_date}, tenantid: {_eq: $tenantid}}) {
                  target_adherence
                  target_parts
                  total_parts_produced
                }
              }
        `);
      const targetPartsSumYearly = data.dm_yearly.reduce(
        (acc, curr) => acc + curr.target_parts,
        0
      );
      const partsProducedSumYearly = data.dm_yearly.reduce(
        (acc, curr) => acc + curr.total_parts_produced,
        0
      );
      const targetAdherenceSumYearly = data.dm_yearly.reduce(
        (acc, curr) => acc + curr.target_adherence,
        0
      );
      setYearData({
        details: "This Year",
        target: targetPartsSumYearly,
        achieved: partsProducedSumYearly,
        targetValue: targetAdherenceSumYearly,
      });
    } catch (error) {
      setDayData({
        details: "This Year",
        target: "No Data",
        achieved: "No Data",
        targetValue: "No Data",
      });
    }
  };

  /////////Plant Operating Time  //  Operating Time Deviation//////////////////////////////////////////////////////////////////

  const [PlantOperatingTimeShiftData, setPlantOperatingTimeShiftData] =
    useState([]);
  useEffect(() => {
    PlantOperatingTimeShift();
  }, []);
  const PlantOperatingTimeShift = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = 15, $end_date: date = ${currentDate}) {
                dm_shiftwise(where: {date: {_gte: $start_date, _lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                  plant_operating_time_percent
                  timestamp
                }
              }
        `);
      if (data === undefined || data.dm_shiftwise === null) {
        setPlantOperatingTimeShiftData([
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ]);
      } else {
        const PlantOperatingTimeShift = [
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ];

        data.dm_shiftwise.map((data) => {
          var shiftDate = new Date(data.timestamp);
          var HoursshiftDate = shiftDate.getHours();
          if (HoursshiftDate >= 8 && HoursshiftDate <= 16) {
            PlantOperatingTimeShift[0] = {
              name: "Shift 1",
              dataValue: data.plant_operating_time_percent,
            };
          } else if (HoursshiftDate >= 16 && HoursshiftDate <= 24) {
            PlantOperatingTimeShift[1] = {
              name: "Shift 2",
              dataValue: data.plant_operating_time_percent,
            };
          } else if (HoursshiftDate >= 0 && HoursshiftDate <= 8) {
            PlantOperatingTimeShift[2] = {
              name: "Shift 3",
              dataValue: data.plant_operating_time_percent,
            };
          }
        });
        setPlantOperatingTimeShiftData(PlantOperatingTimeShift);
      }
    } catch (error) {
      setPlantOperatingTimeShiftData([
        {
          name: "shift 1",
          dataValue: "0",
        },
        {
          name: "shift 2",
          dataValue: "0",
        },
        {
          name: "shift 3",
          dataValue: "0",
        },
      ]);
    }
  };

  const [shiftPercentageList, setShiftPercentageList] = useState([
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
  ]);

  const [OperatingTimeDeviationList, setOperatingTimeDeviationList] = useState([
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
  ]);

  const [plantOperatingTimeData, setPlantOperatingTimeData] = useState(0);
  useEffect(() => {
    plantOperatingTime();
  }, []);
  const plantOperatingTime = async () => {
    try {
      const data = await QueryApi(`
              query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                  aggregate {
                    avg {
                      plant_operating_time_percent
                    }
                  }
                }
              }
        `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent ===
          null
      ) {
        setPlantOperatingTimeData("No Data");
      } else {
        setPlantOperatingTimeData(
          data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent.toFixed(
            1
          )
        );
      }
    } catch (error) {
      setPlantOperatingTimeData("No Data");
    }
  };

  const [xAxisDataValue, setXAxisDataValue] = useState([]);
  const [plotPoint, setPlotPoint] = useState([]);
  const dataset = [
    {
      label: "Plant Operating Time",
      data: plotPoint,
      borderColor: "#1F8EF1",
    },
  ];
  useEffect(() => {
    GraphDataList();
  }, []);
  const GraphDataList = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
                dm_foundation(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                  plant_operating_time_percent
                  timestamp
                }
              }
        `);
      const plotAllData = data.dm_foundation.map((data) => {
        const objData = {
          point: data.plant_operating_time_percent,
          Xaxis: data.timestamp,
        };
        return objData;
      });
      const plotPointData = plotAllData.map((data) => data.point);
      setPlotPoint(plotPointData);

      const xAxisPointData = plotAllData.map((data) => {
        var d = new Date(data.Xaxis);
        var h = d.getHours();
        var m = "0" + d.getMinutes();
        var ampm = h >= 12 ? "PM" : "AM";
        h = h % 12;
        h = h ? h : 12;
        var fullTime = h + ":" + m.substr(-2) + " " + ampm;
        return fullTime;
      });
      setXAxisDataValue(xAxisPointData);
    } catch (error) {}
  };

  const [xAxisDataValueOperatingTime, setXAxisDataValueOperatingTime] =
    useState([]);
  const [plotPointOperatingTime, setPlotPointOperatingTime] = useState([]);
  const datasetOperatingTime = [
    {
      label: "Operating Time Deviation",
      data: plotPointOperatingTime,
      borderColor: "#1F8EF1",
    },
  ];
  useEffect(() => {
    GraphDataListOperatingTime();
  }, []);
  const GraphDataListOperatingTime = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
                dm_foundation(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                  plant_operating_time_percent
                  timestamp
                }
              }
        `);
      const plotAllDataOperatingTime = data.dm_foundation.map((data) => {
        const objData = {
          point: data.plant_operating_time_percent,
          Xaxis: data.timestamp,
        };
        return objData;
      });

      const plotPointDataOperatingTime = plotAllDataOperatingTime.map(
        (data) => 100 - data.point
      );
      setPlotPointOperatingTime(plotPointDataOperatingTime);

      const xAxisPointDataOperatingTime = plotAllDataOperatingTime.map(
        (data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
          var m = "0" + d.getMinutes();
          var ampm = h >= 12 ? "PM" : "AM";
          h = h % 12;
          h = h ? h : 12;
          var fullTime = h + ":" + m.substr(-2) + " " + ampm;
          return fullTime;
        }
      );
      setXAxisDataValueOperatingTime(xAxisPointDataOperatingTime);
    } catch (error) {}
  };

  useEffect(() => {
    DailyDataFun();
  }, []);
  const DailyDataFun = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}) {
                dm_foundation_aggregate(where: {date: {_gte: $date}, tenantid: {_eq: $tenantid}}) {
                  aggregate {
                    avg {
                      plant_operating_time_percent
                    }
                    max {
                      plant_operating_time_percent
                    }
                    min {
                      plant_operating_time_percent
                    }
                  }
                }
              }
           `);

      if (data !== undefined) {
        setShiftPercentageList((prevData) => {
          const newarr = [...prevData];
          newarr[0] = {
            percentage:
              data.dm_foundation_aggregate.aggregate.min
                .plant_operating_time_percent !== null
                ? data.dm_foundation_aggregate.aggregate.min.plant_operating_time_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[4] = {
            percentage:
              data.dm_foundation_aggregate.aggregate.avg
                .plant_operating_time_percent !== null
                ? data.dm_foundation_aggregate.aggregate.avg.plant_operating_time_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[8] = {
            percentage:
              data.dm_foundation_aggregate.aggregate.max
                .plant_operating_time_percent !== null
                ? data.dm_foundation_aggregate.aggregate.max.plant_operating_time_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });

        setOperatingTimeDeviationList((prevData) => {
          const newarr = [...prevData];
          newarr[0] = {
            percentage:
              data.dm_foundation_aggregate.aggregate.min
                .plant_operating_time_percent !== null
                ? 100 -
                  data.dm_foundation_aggregate.aggregate.min.plant_operating_time_percent.toFixed(
                    0
                  )
                : "No Data",
          };
          newarr[4] = {
            percentage:
              data.dm_foundation_aggregate.aggregate.avg
                .plant_operating_time_percent !== null
                ? 100 -
                  data.dm_foundation_aggregate.aggregate.avg.plant_operating_time_percent.toFixed(
                    0
                  )
                : "No Data",
          };
          newarr[8] = {
            percentage:
              data.dm_foundation_aggregate.aggregate.max
                .plant_operating_time_percent !== null
                ? 100 -
                  data.dm_foundation_aggregate.aggregate.max.plant_operating_time_percent.toFixed(
                    0
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setShiftPercentageList((prevData) => {
        const newarr = [...prevData];
        newarr[0] = { percentage: "No Data" };
        newarr[4] = { percentage: "No Data" };
        newarr[8] = { percentage: "No Data" };
        return newarr;
      });
      setOperatingTimeDeviationList((prevData) => {
        const newarr = [...prevData];
        newarr[0] = { percentage: "No Data" };
        newarr[4] = { percentage: "No Data" };
        newarr[8] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    WeeklyDataFun();
  }, []);
  const WeeklyDataFun = async () => {
    try {
      const data = await QueryApi(`
              query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                  aggregate {
                    avg {
                      plant_operating_time_percent
                    }
                    max {
                      plant_operating_time_percent
                    }
                    min {
                      plant_operating_time_percent
                    }
                  }
                }
              }
        `);

      if (data !== undefined) {
        setShiftPercentageList((prevData) => {
          const newarr = [...prevData];
          newarr[1] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min
                .plant_operating_time_percent !== null
                ? data.dm_daily_aggregate.aggregate.min.plant_operating_time_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[5] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg
                .plant_operating_time_percent !== null
                ? data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[9] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max
                .plant_operating_time_percent !== null
                ? data.dm_daily_aggregate.aggregate.max.plant_operating_time_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
        setOperatingTimeDeviationList((prevData) => {
          const newarr = [...prevData];
          newarr[1] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min
                .plant_operating_time_percent !== null
                ? 100 -
                  data.dm_daily_aggregate.aggregate.min.plant_operating_time_percent.toFixed(
                    0
                  )
                : "No Data",
          };
          newarr[5] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg
                .plant_operating_time_percent !== null
                ? 100 -
                  data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent.toFixed(
                    0
                  )
                : "No Data",
          };
          newarr[9] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max
                .plant_operating_time_percent !== null
                ? 100 -
                  data.dm_daily_aggregate.aggregate.max.plant_operating_time_percent.toFixed(
                    0
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setShiftPercentageList((prevData) => {
        const newarr = [...prevData];
        newarr[1] = { percentage: "No Data" };
        newarr[5] = { percentage: "No Data" };
        newarr[9] = { percentage: "No Data" };
        return newarr;
      });
      setOperatingTimeDeviationList((prevData) => {
        const newarr = [...prevData];
        newarr[1] = { percentage: "No Data" };
        newarr[5] = { percentage: "No Data" };
        newarr[9] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    MonthlyDataFun();
  }, []);
  const MonthlyDataFun = async () => {
    try {
      const data = await QueryApi(`
              query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                  aggregate {
                    avg {
                      plant_operating_time_percent
                    }
                    max {
                      plant_operating_time_percent
                    }
                    min {
                      plant_operating_time_percent
                    }
                  }
                }
              }
        `);
      if (data !== undefined) {
        setShiftPercentageList((prevData) => {
          const newarr = [...prevData];
          newarr[2] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min
                .plant_operating_time_percent !== null
                ? data.dm_daily_aggregate.aggregate.min.plant_operating_time_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[6] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg
                .plant_operating_time_percent !== null
                ? data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[10] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max
                .plant_operating_time_percent !== null
                ? data.dm_daily_aggregate.aggregate.max.plant_operating_time_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
        setOperatingTimeDeviationList((prevData) => {
          const newarr = [...prevData];
          newarr[2] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min
                .plant_operating_time_percent !== null
                ? 100 -
                  data.dm_daily_aggregate.aggregate.min.plant_operating_time_percent.toFixed(
                    0
                  )
                : "No Data",
          };
          newarr[6] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg
                .plant_operating_time_percent !== null
                ? 100 -
                  data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent.toFixed(
                    0
                  )
                : "No Data",
          };
          newarr[10] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max
                .plant_operating_time_percent !== null
                ? 100 -
                  data.dm_daily_aggregate.aggregate.max.plant_operating_time_percent.toFixed(
                    0
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setShiftPercentageList((prevData) => {
        const newarr = [...prevData];
        newarr[2] = { percentage: "No Data" };
        newarr[6] = { percentage: "No Data" };
        newarr[10] = { percentage: "No Data" };
        return newarr;
      });
      setOperatingTimeDeviationList((prevData) => {
        const newarr = [...prevData];
        newarr[2] = { percentage: "No Data" };
        newarr[6] = { percentage: "No Data" };
        newarr[10] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    YearlyDataFun();
  }, []);
  const YearlyDataFun = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                  aggregate {
                    avg {
                      plant_operating_time_percent
                    }
                    max {
                      plant_operating_time_percent
                    }
                    min {
                      plant_operating_time_percent
                    }
                  }
                }
              }
        `);
      if (data !== undefined) {
        setShiftPercentageList((prevData) => {
          const newarr = [...prevData];
          newarr[3] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min
                .plant_operating_time_percent !== null
                ? data.dm_daily_aggregate.aggregate.min.plant_operating_time_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[7] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg
                .plant_operating_time_percent !== null
                ? data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[11] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max
                .plant_operating_time_percent !== null
                ? data.dm_daily_aggregate.aggregate.max.plant_operating_time_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
        setOperatingTimeDeviationList((prevData) => {
          const newarr = [...prevData];
          newarr[3] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min
                .plant_operating_time_percent !== null
                ? 100 -
                  data.dm_daily_aggregate.aggregate.min.plant_operating_time_percent.toFixed(
                    0
                  )
                : "No Data",
          };
          newarr[7] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg
                .plant_operating_time_percent !== null
                ? 100 -
                  data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent.toFixed(
                    0
                  )
                : "No Data",
          };
          newarr[11] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max
                .plant_operating_time_percent !== null
                ? 100 -
                  data.dm_daily_aggregate.aggregate.max.plant_operating_time_percent.toFixed(
                    0
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setShiftPercentageList((prevData) => {
        const newarr = [...prevData];
        newarr[3] = { percentage: "No Data" };
        newarr[7] = { percentage: "No Data" };
        newarr[11] = { percentage: "No Data" };
        return newarr;
      });
      setOperatingTimeDeviationList((prevData) => {
        const newarr = [...prevData];
        newarr[3] = { percentage: "No Data" };
        newarr[7] = { percentage: "No Data" };
        newarr[11] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  /////// Production Time // Avaliability ///////////////////////////////////////////////////////////

  const [productionTimeShiftData, setproductionTimeShiftData] = useState([]);
  useEffect(() => {
    productionTimeShift();
  }, []);
  const productionTimeShift = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = 15, $end_date: date = ${currentDate}) {
                dm_shiftwise(where: {date: {_gte: $start_date, _lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                  machine_availability_percent
                  timestamp
                }
              }
        `);
      if (data === undefined || data.dm_shiftwise === null) {
        setproductionTimeShiftData([
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ]);
      } else {
        const productionTimeShift = [
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ];

        data.dm_shiftwise.map((data) => {
          var shiftDate = new Date(data.timestamp);
          var HoursshiftDate = shiftDate.getHours();
          if (HoursshiftDate >= 8 && HoursshiftDate <= 16) {
            productionTimeShift[0] = {
              name: "Shift 1",
              dataValue: data.machine_availability_percent,
            };
          } else if (HoursshiftDate >= 16 && HoursshiftDate <= 24) {
            productionTimeShift[1] = {
              name: "Shift 2",
              dataValue: data.machine_availability_percent,
            };
          } else if (HoursshiftDate >= 0 && HoursshiftDate <= 8) {
            productionTimeShift[2] = {
              name: "Shift 3",
              dataValue: data.machine_availability_percent,
            };
          }
        });
        setproductionTimeShiftData(productionTimeShift);
      }
    } catch (error) {
      setproductionTimeShiftData([
        {
          name: "shift 1",
          dataValue: "0",
        },
        {
          name: "shift 2",
          dataValue: "0",
        },
        {
          name: "shift 3",
          dataValue: "0",
        },
      ]);
    }
  };

  const [productionTimeData, setProductionTimeData] = useState(0);
  useEffect(() => {
    productionTime();
  }, []);
  const productionTime = async () => {
    try {
      const data = await QueryApi(`
              query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                  aggregate {
                    avg {
                        machine_availability_percent
                    }
                  }
                }
              }
        `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.avg.machine_availability_percent ===
          null
      ) {
        setProductionTimeData("No Data");
      } else {
        setProductionTimeData(
          data.dm_daily_aggregate.aggregate.avg.machine_availability_percent.toFixed(
            1
          )
        );
      }
    } catch (error) {
      setProductionTimeData("No Data");
    }
  };

  const [xAxisDataValueProductionTime, setXAxisDataValueProductionTime] =
    useState([]);
  const [plotPointProductionTime, setPlotPointProductionTime] = useState([]);
  const datasetProductionTime = [
    {
      label: "Production Time",
      data: plotPointProductionTime,
      borderColor: "#1F8EF1",
    },
  ];

  useEffect(() => {
    GraphDataListProductionTime();
  }, []);
  const GraphDataListProductionTime = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
                dm_foundation(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                    machine_availability_percent
                  timestamp
                }
              }
        `);
      const plotAllDataProductionTime = data.dm_foundation.map((data) => {
        const objData = {
          point: data.machine_availability_percent,
          Xaxis: data.timestamp,
        };
        return objData;
      });

      const plotPointDataProductionTime = plotAllDataProductionTime.map(
        (data) => data.point
      );
      setPlotPointProductionTime(plotPointDataProductionTime);

      const xAxisPointDataProductionTime = plotAllDataProductionTime.map(
        (data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
          var m = "0" + d.getMinutes();
          var ampm = h >= 12 ? "PM" : "AM";
          h = h % 12;
          h = h ? h : 12;
          var fullTime = h + ":" + m.substr(-2) + " " + ampm;
          return fullTime;
        }
      );
      setXAxisDataValueProductionTime(xAxisPointDataProductionTime);
    } catch (error) {}
  };

  const [ProductionTimeList, setProductionTimeList] = useState([
    {
      percentage: "0",
    },
    {
      percentage: "0",
    },
    {
      percentage: "0",
    },
    {
      percentage: "0",
    },
    {
      percentage: "0",
    },
    {
      percentage: "0",
    },
    {
      percentage: "0",
    },
    {
      percentage: "0",
    },
    {
      percentage: "0",
    },
    {
      percentage: "0",
    },
    {
      percentage: "0",
    },
    {
      percentage: "0",
    },
  ]);

  useEffect(() => {
    DailyDataFunProductionTime();
  }, []);
  const DailyDataFunProductionTime = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}) {
            dm_foundation_aggregate(where: {date: {_gte: $date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                    machine_availability_percent
                }
                max {
                    machine_availability_percent
                }
                min {
                    machine_availability_percent
                }
              }
            }
          }
    `);
      if (data !== undefined) {
        setProductionTimeList((prevData) => {
          const newarr = [...prevData];
          newarr[0] = {
            percentage:
              data.dm_foundation_aggregate.aggregate.min
                .machine_availability_percent !== null
                ? data.dm_foundation_aggregate.aggregate.min.machine_availability_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[4] = {
            percentage:
              data.dm_foundation_aggregate.aggregate.avg
                .machine_availability_percent !== null
                ? data.dm_foundation_aggregate.aggregate.avg.machine_availability_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[8] = {
            percentage:
              data.dm_foundation_aggregate.aggregate.max
                .machine_availability_percent !== null
                ? data.dm_foundation_aggregate.aggregate.max.machine_availability_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setProductionTimeList((prevData) => {
        const newarr = [...prevData];
        newarr[0] = { percentage: "No Data" };
        newarr[4] = { percentage: "No Data" };
        newarr[8] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    WeeklyDataFunProductionTime();
  }, []);
  const WeeklyDataFunProductionTime = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                  machine_availability_percent
                }
                max {
                  machine_availability_percent
                }
                min {
                  machine_availability_percent
                }
              }
            }
          } 
    `);
      if (data !== undefined) {
        setProductionTimeList((prevData) => {
          const newarr = [...prevData];
          newarr[1] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min
                .machine_availability_percent !== null
                ? data.dm_daily_aggregate.aggregate.min.machine_availability_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[5] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg
                .machine_availability_percent !== null
                ? data.dm_daily_aggregate.aggregate.avg.machine_availability_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[9] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max
                .machine_availability_percent !== null
                ? data.dm_daily_aggregate.aggregate.max.machine_availability_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setProductionTimeList((prevData) => {
        const newarr = [...prevData];
        newarr[1] = { percentage: "No Data" };
        newarr[5] = { percentage: "No Data" };
        newarr[9] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    MonthlyDataFunProductionTime();
  }, []);
  const MonthlyDataFunProductionTime = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                  machine_availability_percent
                }
                max {
                  machine_availability_percent
                }
                min {
                  machine_availability_percent
                }
              }
            }
          }
    `);
      if (data !== undefined) {
        setProductionTimeList((prevData) => {
          const newarr = [...prevData];
          newarr[2] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min
                .machine_availability_percent !== null
                ? data.dm_daily_aggregate.aggregate.min.machine_availability_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[6] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg
                .machine_availability_percent !== null
                ? data.dm_daily_aggregate.aggregate.avg.machine_availability_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[10] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max
                .machine_availability_percent !== null
                ? data.dm_daily_aggregate.aggregate.max.machine_availability_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setProductionTimeList((prevData) => {
        const newarr = [...prevData];
        newarr[2] = { percentage: "No Data" };
        newarr[6] = { percentage: "No Data" };
        newarr[10] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    YearlyDataFunProductionTime();
  }, []);
  const YearlyDataFunProductionTime = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                  machine_availability_percent
                }
                max {
                  machine_availability_percent
                }
                min {
                  machine_availability_percent
                }
              }
            }
          }
    `);
      if (data !== undefined) {
        setProductionTimeList((prevData) => {
          const newarr = [...prevData];
          newarr[3] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min
                .machine_availability_percent !== null
                ? data.dm_daily_aggregate.aggregate.min.machine_availability_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[7] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg
                .machine_availability_percent !== null
                ? data.dm_daily_aggregate.aggregate.avg.machine_availability_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[11] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max
                .machine_availability_percent !== null
                ? data.dm_daily_aggregate.aggregate.max.machine_availability_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setProductionTimeList((prevData) => {
        const newarr = [...prevData];
        newarr[3] = { percentage: "No Data" };
        newarr[7] = { percentage: "No Data" };
        newarr[11] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  ///////Total Parts Produced//////////////////////////////////////////////////////////////

  const [TotalPartsProducedShiftData, setTotalPartsProducedShiftData] =
    useState([]);
  useEffect(() => {
    TotalPartsProducedShift();
  }, []);
  const TotalPartsProducedShift = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = 15, $end_date: date = ${currentDate}) {
                dm_shiftwise(where: {date: {_gte: $start_date, _lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                    total_parts_produced
                  timestamp
                }
              }
        `);
      if (data === undefined || data.dm_shiftwise === null) {
        setTotalPartsProducedShiftData([
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ]);
      } else {
        const TotalPartsProducedShift = [
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ];

        data.dm_shiftwise.map((data) => {
          var shiftDate = new Date(data.timestamp);
          var HoursshiftDate = shiftDate.getHours();
          if (HoursshiftDate >= 8 && HoursshiftDate <= 16) {
            TotalPartsProducedShift[0] = {
              name: "Shift 1",
              dataValue: data.total_parts_produced,
            };
          } else if (HoursshiftDate >= 16 && HoursshiftDate <= 24) {
            TotalPartsProducedShift[1] = {
              name: "Shift 2",
              dataValue: data.total_parts_produced,
            };
          } else if (HoursshiftDate >= 0 && HoursshiftDate <= 8) {
            TotalPartsProducedShift[2] = {
              name: "Shift 3",
              dataValue: data.total_parts_produced,
            };
          }
        });
        setTotalPartsProducedShiftData(TotalPartsProducedShift);
      }
    } catch (error) {
      setTotalPartsProducedShiftData([
        {
          name: "shift 1",
          dataValue: "0",
        },
        {
          name: "shift 2",
          dataValue: "0",
        },
        {
          name: "shift 3",
          dataValue: "0",
        },
      ]);
    }
  };

  const [totalPartsProducedData, settotalPartsProducedData] = useState(0);
  useEffect(() => {
    totalPartsProduced();
  }, []);
  const totalPartsProduced = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                    total_parts_produced
                }
              }
            }
          }
       `);

      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.avg.total_parts_produced === null
      ) {
        settotalPartsProducedData("No Data");
      } else {
        settotalPartsProducedData(
          data.dm_daily_aggregate.aggregate.avg.total_parts_produced
        );
      }
    } catch (error) {
      settotalPartsProducedData("No Data");
    }
  };

  const [
    xAxisDataValueTotalPartsProduced,
    setXAxisDataValueTotalPartsProduced,
  ] = useState([]);
  const [plotPointTotalPartsProduced, setPlotPointTotalPartsProduced] =
    useState([]);
  const datasetTotalPartsProduced = [
    {
      label: "Production Time",
      data: plotPointTotalPartsProduced,
      borderColor: "#1F8EF1",
    },
  ];

  useEffect(() => {
    GraphDataListTotalPartsProduced();
  }, []);
  const GraphDataListTotalPartsProduced = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
                dm_foundation(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                total_parts_produced
                  timestamp
                }
              }
        `);
      const plotAllDataTotalPartsProduced = data.dm_foundation.map((data) => {
        const objData = {
          point: data.total_parts_produced,
          Xaxis: data.timestamp,
        };
        return objData;
      });

      const plotPointDataTotalPartsProduced = plotAllDataTotalPartsProduced.map(
        (data) => data.point
      );
      setPlotPointTotalPartsProduced(plotPointDataTotalPartsProduced);

      const xAxisPointDataTotalPartsProduced =
        plotAllDataTotalPartsProduced.map((data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
          var m = "0" + d.getMinutes();
          var ampm = h >= 12 ? "PM" : "AM";
          h = h % 12;
          h = h ? h : 12;
          var fullTime = h + ":" + m.substr(-2) + " " + ampm;
          return fullTime;
        });
      setXAxisDataValueTotalPartsProduced(xAxisPointDataTotalPartsProduced);
    } catch (error) {}
  };

  const [TotalPartsProducedList, setTotalPartsProducedList] = useState([
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
  ]);

  useEffect(() => {
    TotalPartsProducedDailyDataFun();
  }, []);
  const TotalPartsProducedDailyDataFun = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}) {
            dm_foundation_aggregate(where: {date: {_gte: $date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                  total_parts_produced
                }
                max {
                  total_parts_produced
                }
                min {
                  total_parts_produced
                }
              }
            }
          }
    `);
      if (data !== undefined) {
        setTotalPartsProducedList((prevData) => {
          const newarr = [...prevData];
          newarr[0] = {
            percentage:
              data.dm_foundation_aggregate.aggregate.min
                .total_parts_produced !== null
                ? data.dm_foundation_aggregate.aggregate.min.total_parts_produced.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[4] = {
            percentage:
              data.dm_foundation_aggregate.aggregate.avg
                .total_parts_produced !== null
                ? data.dm_foundation_aggregate.aggregate.avg.total_parts_produced.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[8] = {
            percentage:
              data.dm_foundation_aggregate.aggregate.max
                .total_parts_produced !== null
                ? data.dm_foundation_aggregate.aggregate.max.total_parts_produced.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setTotalPartsProducedList((prevData) => {
        const newarr = [...prevData];
        newarr[0] = { percentage: "No Data" };
        newarr[4] = { percentage: "No Data" };
        newarr[8] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    TotalPartsProducedWeeklyDataFun();
  }, []);
  const TotalPartsProducedWeeklyDataFun = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                  total_parts_produced
                }
                max {
                  total_parts_produced
                }
                min {
                  total_parts_produced
                }
              }
            }
          }
    `);
      if (data !== undefined) {
        setTotalPartsProducedList((prevData) => {
          const newarr = [...prevData];
          newarr[1] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min.total_parts_produced !==
              null
                ? data.dm_daily_aggregate.aggregate.min.total_parts_produced.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[5] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg.total_parts_produced !==
              null
                ? data.dm_daily_aggregate.aggregate.avg.total_parts_produced.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[9] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max.total_parts_produced !==
              null
                ? data.dm_daily_aggregate.aggregate.max.total_parts_produced.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setTotalPartsProducedList((prevData) => {
        const newarr = [...prevData];
        newarr[1] = { percentage: "No Data" };
        newarr[5] = { percentage: "No Data" };
        newarr[9] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    TotalPartsProducedMonthlyDataFun();
  }, []);
  const TotalPartsProducedMonthlyDataFun = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                  total_parts_produced
                }
                max {
                  total_parts_produced
                }
                min {
                  total_parts_produced
                }
              }
            }
          }
    `);
      if (data !== undefined) {
        setTotalPartsProducedList((prevData) => {
          const newarr = [...prevData];
          newarr[2] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min.total_parts_produced !==
              null
                ? data.dm_daily_aggregate.aggregate.min.total_parts_produced.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[6] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg.total_parts_produced !==
              null
                ? data.dm_daily_aggregate.aggregate.avg.total_parts_produced.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[10] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max.total_parts_produced !==
              null
                ? data.dm_daily_aggregate.aggregate.max.total_parts_produced.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setTotalPartsProducedList((prevData) => {
        const newarr = [...prevData];
        newarr[2] = { percentage: "No Data" };
        newarr[6] = { percentage: "No Data" };
        newarr[10] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    TotalPartsProducedYearlyDataFun();
  }, []);
  const TotalPartsProducedYearlyDataFun = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                  total_parts_produced
                }
                max {
                  total_parts_produced
                }
                min {
                  total_parts_produced
                }
              }
            }
          }
    `);
      if (data !== undefined) {
        setTotalPartsProducedList((prevData) => {
          const newarr = [...prevData];
          newarr[3] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min.total_parts_produced !==
              null
                ? data.dm_daily_aggregate.aggregate.min.total_parts_produced.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[7] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg.total_parts_produced !==
              null
                ? data.dm_daily_aggregate.aggregate.avg.total_parts_produced.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[11] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max.total_parts_produced !==
              null
                ? data.dm_daily_aggregate.aggregate.max.total_parts_produced.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setTotalPartsProducedList((prevData) => {
        const newarr = [...prevData];
        newarr[3] = { percentage: "No Data" };
        newarr[7] = { percentage: "No Data" };
        newarr[11] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  /////// Avg (YTD) /////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [AvgData, setAvgData] = useState("No data");
  useEffect(() => {
    Avg();
  }, []);
  const Avg = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                    plant_operating_time_percent
                }
              }
            }
          }
    `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent ===
          null
      ) {
        setAvgData("No Data");
      } else {
        setAvgData(
          data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent.toFixed(
            1
          )
        );
      }
    } catch (error) {
      setAvgData("No Data");
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////
  const [apiData, setApiData] = useState([]);
  const [bigChartData, setBgChartData] = React.useState("data1");
  const [dayDataProductivityAnalysis, setDayDataProductivityAnalysis] =
    useState([]);
  useEffect(() => {
    fetchAllCardData();
  }, [bigChartData]);
  const fetchAllCardData = async () => {
    if (bigChartData === "data1") {
      try {
        const data = await QueryApi(`
        query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
          dm_org_tenant_daily(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
              plant_operating_time_percent
              actual_production_time
              total_machine_runtime
              total_machine_downtime
              actual_cycletime
              total_planned_downtime
              time_between_job_parts
              availability_loss_time
              unplanned_downtime
              cycletime_deviation
              quality_percent
            }
            dm_daily_data: dm_daily(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}, order_by: {date: desc}) {
              org {
                organization_node_name
              }
              unit {
                organization_node_name
              }
              dept {
                organization_node_name
              }
              machineid
              date
              plant_operating_time_percent
              actual_production_time
              total_machine_runtime
              total_machine_downtime
              actual_cycletime
              total_planned_downtime
              time_between_job_parts
              availability_loss_time
              unplanned_downtime
              cycletime_deviation
              quality_percent
              planned_downtime_percent
              machine_idle_time
              planned_production_time
            }
          }
        `);
        setDayDataProductivityAnalysis(data.dm_org_tenant_daily[0]);
        setApiData(data.dm_daily_data);
      } catch (error) {
        setDayDataProductivityAnalysis([]);
      }
    } else if (bigChartData === "data2") {
      try {
        const data = await QueryApi(`
        query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! =  ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
          dm_org_tenant_weekly(where: {start_date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              plant_operating_time_percent
              actual_production_time
              total_machine_runtime
              total_machine_downtime
              actual_cycletime
              total_planned_downtime
              time_between_job_parts
              availability_loss_time
              unplanned_downtime
              cycletime_deviation
              quality_percent
            }
            dm_weekly_data:  dm_daily(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}},order_by: {date: desc}) {
              org {
                organization_node_name
              }
              unit {
                organization_node_name
              }
              dept {
                organization_node_name
              }
              machineid
              date
              plant_operating_time_percent
              actual_production_time
              total_machine_runtime
              total_machine_downtime
              actual_cycletime
              total_planned_downtime
              time_between_job_parts
              availability_loss_time
              unplanned_downtime
              cycletime_deviation
              quality_percent
              planned_downtime_percent
              machine_idle_time
              planned_production_time
            }
          }
        `);
        setDayDataProductivityAnalysis(data.dm_org_tenant_weekly[0]);
        setApiData(data.dm_weekly_data);
      } catch (error) {
        setDayDataProductivityAnalysis([]);
      }
    } else if (bigChartData === "data3") {
      try {
        const data = await QueryApi(`
        query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! =  ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
          dm_org_tenant_monthly(where: {start_date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              plant_operating_time_percent
              actual_production_time
              total_machine_runtime
              total_machine_downtime
              actual_cycletime
              total_planned_downtime
              time_between_job_parts
              availability_loss_time
              unplanned_downtime
              cycletime_deviation
              quality_percent
            }
            dm_montly_data: dm_daily(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}},order_by: {date: desc}) {
              org {
                organization_node_name
              }
              unit {
                organization_node_name
              }
              dept {
                organization_node_name
              }
              machineid
              date
              plant_operating_time_percent
              actual_production_time
              total_machine_runtime
              total_machine_downtime
              actual_cycletime
              total_planned_downtime
              time_between_job_parts
              availability_loss_time
              unplanned_downtime
              cycletime_deviation
              quality_percent
              planned_downtime_percent
              machine_idle_time
              planned_production_time
            }
          }
        `);
        setDayDataProductivityAnalysis(data.dm_org_tenant_monthly[0]);
        console.log("monthly data", data.dm_org_tenant_monthly[0]);
        console.log("monthly apidata", data.dm_montly_data);
        setApiData(data.dm_montly_data);
      } catch (error) {
        setDayDataProductivityAnalysis([]);
      }
    } else if (bigChartData === "data4") {
      try {
        const data = await QueryApi(`
        query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
          dm_org_tenant_yearly(where: {start_date: {_eq: $start_date}, tenantid: {_eq: $tenantid}}) {
              plant_operating_time_percent
              actual_production_time
              total_machine_runtime
              total_machine_downtime
              actual_cycletime
              total_planned_downtime
              time_between_job_parts
              availability_loss_time
              unplanned_downtime
              cycletime_deviation
              quality_percent
            }
            dm_yearly_data: dm_daily( order_by: {date: desc}) {
              org {
                organization_node_name
              }
              unit {
                organization_node_name
              }
              dept {
                organization_node_name
              } 
              machineid
              date
              plant_operating_time_percent
              actual_production_time
              total_machine_runtime
              total_machine_downtime
              actual_cycletime
              total_planned_downtime
              time_between_job_parts
              availability_loss_time
              unplanned_downtime
              cycletime_deviation
              quality_percent
              planned_downtime_percent
              machine_idle_time
              planned_production_time
            }
          }
        `);
        setApiData(data.dm_yearly_data);
        setDayDataProductivityAnalysis(data.dm_org_tenant_yearly[0]);
      } catch (error) {
        setDayDataProductivityAnalysis([]);
      }
    }
  };
  ///////////////////////////////////////////////////////

  const columns = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "Date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "unit.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machineid",
      text: "Machine Name",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "actual_cycletime",
      text: "Cycle Time",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        if (cellContent === null) {
          return "null";
        }
        const minutes = (cellContent / (1000 * 60)).toFixed(2);
        return minutes + " minutes";
      },
    },
  ];
  const columnsBreakDownTime = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "Date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "unit.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machineid",
      text: "Machine Name",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "total_machine_downtime",
      text: "Breakdown Time",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        if (cellContent === null) {
          return "null";
        }
        const hours = (cellContent / (1000 * 60 * 60)).toFixed(2);
        return hours + " hours";
      },
    },
  ];
  /////////////////  cycle time loss grid ////////////////////
  const columnsCycleTimeLossGrid = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "Date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "unit.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machineid",
      text: "Machine Name",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "cycletime_deviation",
      text: "Cycle Time Loss",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        if (cellContent === null) {
          return "null";
        }
        const hours = (cellContent / (1000 * 60 * 60)).toFixed(2);
        return hours + " hours";
      },
    },
  ];
  ////////////////unplanned down time/////////////////
  const columnsUnPlannedDownTime = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "Date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "unit.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machineid",
      text: "Machine Name",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "unplanned_downtime",
      text: "Unplanned Down Time Loss",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        if (cellContent === null) {
          return "null";
        }
        const hours = (cellContent / (1000 * 60 * 60)).toFixed(2);
        return hours + " hours";
      },
    },
  ];
  ////////////////////////////// Idle Time Loss Grid //////////////
  const columnsIdleTimeLossGrid = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "Date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "unit.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machineid",
      text: "Machine Name",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machine_idle_time",
      text: "Idle Time Loss",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        if (cellContent === null) {
          return "null";
        }
        const hours = (cellContent / (1000 * 60 * 60)).toFixed(2);
        return hours + " hours";
      },
    },
  ];
  /////////////////////Planned Down Time///////////////////////
  const columnsPlannedDownTime = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "Date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "unit.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machineid",
      text: "Machine Name",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "planned_downtime_percent",
      text: "Planned Down Time",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        if (cellContent === null) {
          return "null";
        }
        const hours = (cellContent / (1000 * 60 * 60)).toFixed(2);
        return hours + " hours";
      },
    },
  ];
  //////////////////// Run Time Grid ////////////////////////
  const columnsRunTime = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "Date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "unit.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machineid",
      text: "Machine Name",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "total_machine_runtime",
      text: "Run Time",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        if (cellContent === null) {
          return "null";
        }
        const hours = (cellContent / (1000 * 60 * 60)).toFixed(2);
        return hours + " hours";
      },
    },
  ];
  ////////////////// Production Time Grid ////////////////////
  const columnsProductionTime = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "Date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "unit.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machineid",
      text: "Machine Name",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "planned_production_time",
      text: "Production Time",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        if (cellContent === null) {
          return "null";
        }
        const hours = (cellContent / (1000 * 60 * 60)).toFixed(2);
        return hours + " hours";
      },
    },
  ];
  /////////////////// Plant Operating Time Grid //////////////////
  const columnsPlantOperatingTime = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "Date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "unit.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machineid",
      text: "Machine Name",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "plant_operating_time_percent",
      text: "Plant Operating Time",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        return cellContent + "%";
      },
    },
  ];

  return (
    <>
      <div className="content">
        <Card
          style={{
            background: "transparent",
            boxShadow: "0px 10px 20px 2px rgba(0,0,0,0.75)",
            height: "18rem",
          }}
        >
          <CardHeader>
            <div className="d-flex justify-content-end">
              <div>
                <ButtonGroup
                  className="btn-group-toggle float-right"
                  data-toggle="buttons"
                >
                  <Button
                    tag="label"
                    className={classNames("btn-simple", {
                      active: bigChartData === "data1",
                    })}
                    color="info"
                    id="0"
                    size="sm"
                    onClick={() => setBgChartData("data1")}
                  >
                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      Day
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="tim-icons icon-single-02" />
                    </span>
                  </Button>
                  <Button
                    color="info"
                    id="1"
                    size="sm"
                    tag="label"
                    className={classNames("btn-simple", {
                      active: bigChartData === "data2",
                    })}
                    onClick={() => setBgChartData("data2")}
                  >
                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      Week
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="tim-icons icon-gift-2" />
                    </span>
                  </Button>
                  <Button
                    color="info"
                    id="2"
                    size="sm"
                    tag="label"
                    className={classNames("btn-simple", {
                      active: bigChartData === "data3",
                    })}
                    onClick={() => setBgChartData("data3")}
                  >
                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      Month
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="tim-icons icon-tap-02" />
                    </span>
                  </Button>
                  <Button
                    color="info"
                    id="2"
                    size="sm"
                    tag="label"
                    className={classNames("btn-simple", {
                      active: bigChartData === "data4",
                    })}
                    onClick={() => setBgChartData("data4")}
                  >
                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      Year
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="tim-icons icon-tap-02" />
                    </span>
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "0.8rem",
                marginLeft: "0.5rem",
                height: "13rem",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                    gap: "0.8rem",
                  }}
                >
                  {/* ////////////////card 1////////////////////// */}
                  <div
                    onClick={() => setOpenCard("PlantOperatingTimeGridCard")}
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        Plant Operating Time
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        {dayDataProductivityAnalysis &&
                        dayDataProductivityAnalysis.plant_operating_time_percent
                          ? dayDataProductivityAnalysis.plant_operating_time_percent +
                            " %"
                          : "No Data"}
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "100%",
                      }}
                    >
                      <img
                        src={timeLoss}
                        style={{
                          height: "2rem",
                          width: "2rem",
                          position: "absolute",
                          top: "10px",
                          right: "2.5rem",
                          cursor: "pointer",
                        }}
                        onClick={() => setIsOpen(true)}
                        title="Trend Line"
                      />
                      <div
                        className="icon-plus"
                        style={{
                          position: "absolute", // Position the icon absolutely
                          top: "10px", // Align it to the top
                          right: 0, // Align it to the right of the image
                        }}
                      >
                        <NavLink className="anchor-bg" href="#">
                          {/* <FontAwesomeIcon icon={faArrowRightLong} /> */}
                        </NavLink>
                      </div>
                    </div>
                    {isOpen && (
                      <>
                        <div
                          style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100vw",
                            height: "100vh",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            backdropFilter: "blur(3px)",
                            zIndex: 999,
                          }}
                        />
                        <div
                          style={{
                            position: "fixed",
                            top: "150px",
                            left: "70%",
                            transform: "translate(-50%, 0)",
                            zIndex: 1000,
                            width: "100%",
                            height: "100vh",
                            padding: "1rem",
                            boxSizing: "border-box",
                          }}
                        >
                          <div style={{ position: "relative", height: "100%" }}>
                            <PopUpPlantOperatingTime closePopup={setIsOpen} />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {/* /////////////////card 2///////////////////////// */}
                  <div
                    onClick={() => setOpenCard("productionTimeGridCard")}
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        Production Time
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        {dayDataProductivityAnalysis &&
                        dayDataProductivityAnalysis.actual_production_time
                          ? (
                              dayDataProductivityAnalysis.actual_production_time /
                              (1000 * 60 * 60)
                            ).toFixed(2) + " Hours"
                          : "No Data"}
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "100%",
                      }}
                    >
                      <img
                        src={timeLoss}
                        style={{
                          height: "2rem",
                          width: "2rem",
                          position: "absolute",
                          top: "10px",
                          right: "2.5rem",
                          cursor: "pointer",
                        }}
                        onClick={() => setIsOpenPopUpProductiontime(true)}
                        title="Trend Line"
                      />
                      <div
                        className="icon-plus"
                        style={{
                          position: "absolute", // Position the icon absolutely
                          top: "10px", // Align it to the top
                          right: 0, // Align it to the right of the image
                        }}
                      >
                        <NavLink className="anchor-bg" href="#">
                          {/* <FontAwesomeIcon icon={faArrowRightLong} /> */}
                        </NavLink>
                      </div>
                    </div>
                    {isOpenpopUpProductiontime && (
                      <>
                        <div
                          style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100vw",
                            height: "100vh",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            backdropFilter: "blur(5px)",
                            zIndex: 999,
                          }}
                        />
                        <div
                          style={{
                            position: "fixed",
                            top: "150px",
                            left: "70%",
                            transform: "translate(-50%, 0)",
                            zIndex: 1000,
                            width: "100%",
                            height: "100vh",
                            padding: "1rem",
                            boxSizing: "border-box",
                          }}
                        >
                          <div style={{ position: "relative", height: "100%" }}>
                            <PopUpProductionTime
                              closePopup={setIsOpenPopUpProductiontime}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {/* ///////////////card 3 ////////////////////////////// */}
                  <div
                    onClick={() => setOpenCard("runTimeGridCard")}
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        Run Time
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        {dayDataProductivityAnalysis &&
                        dayDataProductivityAnalysis.total_machine_runtime
                          ? (
                              dayDataProductivityAnalysis.total_machine_runtime /
                              (1000 * 60 * 60)
                            ).toFixed(2) + " Hours"
                          : "No Data"}
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "100%",
                      }}
                    >
                      <div
                        className="icon-plus"
                        style={{
                          position: "absolute", // Position the icon absolutely
                          top: "10px", // Align it to the top
                          right: 0, // Align it to the right of the image
                        }}
                      >
                        <NavLink className="anchor-bg" href="#">
                          {/* <FontAwesomeIcon icon={faArrowRightLong} /> */}
                        </NavLink>
                      </div>
                    </div>
                  </div>
                  {/* ///////////////// card 4 ////////////////////// */}
                  <div
                    onClick={() => setOpenCard("breakDownTimeGridCard")}
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        Breakdown Time
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        {dayDataProductivityAnalysis &&
                        dayDataProductivityAnalysis.total_machine_downtime
                          ? (
                              dayDataProductivityAnalysis.total_machine_downtime /
                              (1000 * 60 * 60)
                            ).toFixed(2) + " Hours"
                          : "No Data"}
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "100%",
                      }}
                    >
                      <div
                        className="icon-plus"
                        style={{
                          position: "absolute", // Position the icon absolutely
                          top: "10px", // Align it to the top
                          right: 0, // Align it to the right of the image
                        }}
                      >
                        <Link to="/admin/IFADownTime">
                          {" "}
                          {/* Use the Link component to navigate to the route */}
                          <FontAwesomeIcon icon={faArrowRightLong} />
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* /////////////////// card 5 /////////////////////////// */}
                  <div
                    onClick={() => setOpenCard("cycleTimeGridCard")}
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        Cycle Time
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        {dayDataProductivityAnalysis &&
                        dayDataProductivityAnalysis.actual_cycletime
                          ? (
                              dayDataProductivityAnalysis.actual_cycletime /
                              (1000 * 60 * 60)
                            ).toFixed(2) + " Hours"
                          : "No Data"}
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "100%",
                      }}
                    >
                      <div
                        className="icon-plus"
                        style={{
                          position: "absolute", // Position the icon absolutely
                          top: "10px", // Align it to the top
                          right: 0, // Align it to the right of the image
                        }}
                      >
                        <Link to="/admin/IFACycleTime">
                          {" "}
                          {/* Use the Link component to navigate to the route */}
                          <FontAwesomeIcon icon={faArrowRightLong} />
                        </Link>
                        {/* <NavLink className="anchor-bg" href="admin/IFACycleTime">
                          <FontAwesomeIcon icon={faArrowRightLong} />
                        </NavLink> */}
                      </div>
                    </div>
                  </div>
                  {/* //////////////////////// card 6 ////////////////////////// */}
                  <div
                    onClick={() => setOpenCard("fullyProductiveTimeGridCard")}
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        Fully Productive Time
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        No Data
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "100%",
                      }}
                    >
                      <div
                        className="icon-plus"
                        style={{
                          position: "absolute", // Position the icon absolutely
                          top: "10px", // Align it to the top
                          right: 0, // Align it to the right of the image
                        }}
                      >
                        <NavLink className="anchor-bg" href="#">
                          {/* <FontAwesomeIcon icon={faArrowRightLong} /> */}
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>

                {/* ////////////////////////////////////////////////// */}
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                    gap: "0.8rem",
                    marginBottom: "0.6rem",
                    marginTop: "0.8rem",
                  }}
                >
                  {/* /////////////////////////// card 7 ////////////////////////// */}
                  <div
                    onClick={() => setOpenCard("plannedDownTimeGridCard")}
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        Planned Down Time
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        {dayDataProductivityAnalysis &&
                        dayDataProductivityAnalysis.total_planned_downtime
                          ? (
                              dayDataProductivityAnalysis.total_planned_downtime /
                              (1000 * 60 * 60)
                            ).toFixed(2) + " Hours"
                          : "No Data"}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "5.5rem",
                        width: "3rem",
                      }}
                    >
                      <img
                      // src={runTime}
                      />
                    </div>
                  </div>
                  {/* /////////////////////////// card 8 ////////////////////////// */}
                  <div
                    onClick={() => setOpenCard("timeBetweenJobsGridCard")}
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        Set UP Change Over Time Between Jobs
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        {dayDataProductivityAnalysis &&
                        dayDataProductivityAnalysis.time_between_job_parts
                          ? (
                              dayDataProductivityAnalysis.time_between_job_parts /
                              (1000 * 60 * 60)
                            ).toFixed(2) + " Hours"
                          : "No Data"}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "3rem",
                      }}
                    >
                      <img
                      // src={timeLoss}
                      />
                    </div>
                  </div>
                  {/* /////////////////////////// card 9 ////////////////////////// */}
                  <div
                    onClick={() => setOpenCard("idealTimeLossGridCard")}
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        Idle Time Loss
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        {dayDataProductivityAnalysis &&
                        dayDataProductivityAnalysis.availability_loss_time
                          ? (
                              dayDataProductivityAnalysis.availability_loss_time /
                              (1000 * 60 * 60)
                            ).toFixed(2) + " Hours"
                          : "No Data"}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    ></div>
                  </div>
                  {/* /////////////////////////// card 10 ////////////////////////// */}
                  <div
                    onClick={() => setOpenCard("unplannedDownTimeLossGridCard")}
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        Unplanned Downtime Loss
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        {dayDataProductivityAnalysis &&
                        dayDataProductivityAnalysis.unplanned_downtime
                          ? (
                              dayDataProductivityAnalysis.unplanned_downtime /
                              (1000 * 60 * 60)
                            ).toFixed(2) + " Hours"
                          : "No Data"}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    ></div>
                  </div>
                  {/* /////////////////////////// card 11 ////////////////////////// */}
                  <div
                    onClick={() => setOpenCard("cycleTimeLossGridCard")}
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        Cycle Time Loss
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        {dayDataProductivityAnalysis &&
                        dayDataProductivityAnalysis.cycletime_deviation
                          ? (
                              dayDataProductivityAnalysis.cycletime_deviation /
                              (1000 * 60 * 60)
                            ).toFixed(2) + " Hours"
                          : "No Data"}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    ></div>
                  </div>
                  {/* //////////////////////////////// card 12 ////////////////////////// */}
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        Quality Loss
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        {dayDataProductivityAnalysis &&
                        dayDataProductivityAnalysis.quality_percent
                          ? dayDataProductivityAnalysis.quality_percent -
                            100 +
                            " %"
                          : "No Data"}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
        <Card>
          {openCard === "PlantOperatingTimeGridCard" && (
            <TableComponent
              title={"Plant Operating Time"}
              columns={columnsPlantOperatingTime}
              apiData={apiData}
            />
          )}
          {openCard === "productionTimeGridCard" && (
            <TableComponent
              title={"Production Time"}
              columns={columnsProductionTime}
              apiData={apiData}
            />
          )}
          {openCard === "runTimeGridCard" && (
            <TableComponent
              title={"Run Time"}
              columns={columnsRunTime}
              apiData={apiData}
            />
          )}
          {openCard === "plannedDownTimeGridCard" && (
            <TableComponent
              title={"Planned Down Time"}
              columns={columnsPlannedDownTime}
              apiData={apiData}
            />
          )}
          {openCard === "idealTimeLossGridCard" && (
            <TableComponent
              title={"Idle Time Loss"}
              columns={columnsIdleTimeLossGrid}
              apiData={apiData}
            />
          )}
          {openCard === "unplannedDownTimeLossGridCard" && (
            <TableComponent
              title={"Unplanned Downtime Loss"}
              columns={columnsUnPlannedDownTime}
              apiData={apiData}
            />
          )}
          {openCard === "cycleTimeLossGridCard" && (
            <TableComponent
              title={"Cycle Time Loss"}
              columns={columnsCycleTimeLossGrid}
              apiData={apiData}
            />
          )}
          {openCard === "cycleTimeGridCard" && (
            <TableComponent
              title={"Cycle Time"}
              columns={columns}
              apiData={apiData}
            />
          )}
          {openCard === "breakDownTimeGridCard" && (
            <TableComponent
              title={"Break Down Time"}
              columns={columnsBreakDownTime}
              apiData={apiData}
            />
          )}
        </Card>
      </div>
    </>
  );
}

export default ProductivityTime;
