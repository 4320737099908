import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Progress,
  FormFeedback,
} from "reactstrap";
import axios from "axios";
import { APIURL } from "views/Apiurl";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.css";
import { useForm } from 'react-hook-form'
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

function OrgWeeklyOffsAdd({ handleClose }) {
  const Days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const [date, setDate] = useState("");
  const [purpose, setpurpose] = useState("");
  const [isPlantSelected, setIsPlantSelected] = useState(true);
  const [wklOff, setWklOff] = useState("");
  const [org, setorg] = useState("");
  const [orgs, setorgs] = useState([]);
  const [orgUnit, setOrgUnit] = useState("");
  const [otherData, setOtherData] = useState([]);
  const [plants, setplants] = useState([]);
  const [selectedOrgData, setSelectedOrgData] = useState(null);
  const [tenant, setTenant] = useState("");
  const [selectedOrg, setSelectedOrg] = useState("");
  useEffect(() => {
    fetchorg();
  }, []);

  const schema = yup.object({
    organization: yup.string().required("organization is required"),
    plant: yup.string().required("plant is required"),
    weekly_off: yup.string().required("Week Off is required"),
  })
  const form = useForm({
    defaultValues: {
      organization: "",
      plant: "",
      weekly_off: "",
    },
    resolver: yupResolver(schema)
  });
  const { register, control, handleSubmit, formState } = form;
  const { errors, isSubmitSuccessful } = formState;

  const handlePlantChange = (e) => {
    const selectedValue = e.target.value;
    setOrgUnit(selectedValue);
    setIsPlantSelected(
      plants.some((plant) => plant.organization_node_name === selectedValue)
    );
  };

  const token = sessionStorage.getItem("token");
  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const tenantId =
    decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"]; //18
  useEffect(() => {
    fetchplant();
  }, [org]);
  const fetchorg = async (logintenantId) => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery($logintenantId: Int!) {
          tnt_organization_structure(
            where: {
              parent_organization_id: { _eq: 0 },
              tenantid: { _eq: $logintenantId }
            }
          ) {
            organization_structure_id
            organization_node_name
            tenantid
          }
        }
      `,
          variables: {
            logintenantId: parseInt(logintenantId),
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );
      const fetchedOrgs = response.data.data.tnt_organization_structure;
      setorgs(fetchedOrgs);
      console.log(fetchedOrgs); //PsplDemoTent
      const parentIds = fetchedOrgs.map((org) => org.organization_structure_id);
      console.log(parentIds, "parentIds !!!"); //parentID == 41

      const childResponse = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery($parentIds: [Int!]) {
          tnt_organization_structure(
            where: {
              parent_organization_id: { _in: $parentIds }
            }
          ) {
            organization_structure_id
            organization_node_name
            tenantid
          }
        }
      `,
          variables: {
            parentIds: parentIds,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      const childOrgs = childResponse.data.data.tnt_organization_structure;
      console.log(childOrgs); // select organization == psplDemoTent_Org
      const updatedOtherData = otherData.map((row) => {
        const matchingOrg = childOrgs.find(
          (org) => org.organization_structure_id === row.parent_organization_id
        );

        if (matchingOrg) {
          return {
            ...row,
            parent_organization_id: matchingOrg.organization_structure_id,
          };
        }

        return row;
      });

      setOtherData(childOrgs);
      console.log(otherData);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["sub"];
    let logintenantId = null;

    if (roleId == 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
    }

    fetchorg(logintenantId);
  }, []);
  const fetchplant = async () => {
    if (org) {
      // Only fetch if org is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
          query MyQuery($p: Int!) {
            tnt_organization_structure(where: {parent_organization_id: {_eq: $p}}) {
              organization_structure_id
              organization_node_name
              tenantid
            }
          }
        `,
            variables: {
              p: org,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        setplants(response.data.data.tnt_organization_structure);
        console.log(response.data.data.tnt_organization_structure);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleOrgSelect = async (selectedOrgId) => {
    if (selectedOrgId) {
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
          query GetCostCenter($orgId: Int!) {
            tnt_organization_structure(where: { organization_structure_id: { _eq: $orgId } }) {
              cost_centre_code
            }
          }
        `,
            variables: {
              orgId: selectedOrgId,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );

        if (
          response.data &&
          response.data.data &&
          response.data.data.tnt_organization_structure &&
          response.data.data.tnt_organization_structure.length > 0
        ) {
          setSelectedOrgData(response.data.data.tnt_organization_structure[0]);
        } else {
          console.log(response);
          setSelectedOrgData(null);
        }
      } catch (error) {
        console.error("Error fetching organization data:", error);
      }
    } else {
      setSelectedOrgData(null);
    }

    setorg(selectedOrgId);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (!org || !orgUnit || !wklOff) {
      toast.error("Please Select All Options ");
      return;
    }
    const token = sessionStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["role_id"];
    console.log(roleId, "roleId in weeklyoffs add");

    let logintenantId = null;
    if (roleId === 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-tenant-id"];
    }

    const formData = {
      tenantid: tenantId,
      organization_id: org,
      plant: orgUnit,
      weekly_off: wklOff,
    };

    console.log('========form data =============>', formData)
    axios
      .post(
        APIURL,
        {
          query: `
            mutation InsertOrganizationStructure($formData: tnt_weeklyoff_insert_input!) {
                insert_tnt_weeklyoff(objects: [$formData]) {
                affected_rows
                }
            }
        `,
          variables: {
            formData: formData,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-hasura-admin-secret": process.env.REACT_APP_X_API_SECRET_KEY, // Replace with your Hasura admin secret
          },
        }
      )
      .then((response) => {
        console.log(response); // Handle the response data as needed
        if (
          response.data.data &&
          response.data.data.insert_tnt_weeklyoff.affected_rows > 0
        ) {
          //erase data
          toast.success("Data inserted successfully!");
        } else {
          toast.error("Failed to insert data.");
        }
      })
      .catch((error) => {
        console.error(error); // Handle the error
        toast.error("An error occurred while inserting data.");
      });
  };
  return (
    <div className="content">
      <Row className="progress-row">
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Add Weekly Offs</h5>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col className="px-md-1" md="4">
                    <FormGroup>
                      <Label for="Holiday">Organization</Label>
                      <Input
                        type="select"
                        value={org}
                        onChange={(e) => handleOrgSelect(e.target.value)}
                      >
                        <option value="">Select Organization</option>
                        {otherData.map((org) => (
                          <option
                            key={org.organization_structure_id}
                            value={org.organization_structure_id}
                          >
                            {org.organization_node_name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col className="px-md-1" md="3">
                    <FormGroup>
                      <Label for="Holiday">Plant</Label>
                      <Input
                        type="select"
                        value={orgUnit}
                        // onChange={(e) => setOrgUnit(e.target.value)}
                        onChange={handlePlantChange}
                        invalid={!isPlantSelected}
                      >
                        <option value="">Select Plant</option>
                        {plants.map((plant) => (
                          <option
                            key={plant.organization_node_name}
                            value={plant.organization_node_name}
                          >
                            {plant.organization_node_name}
                          </option>
                        ))}
                      </Input>
                      <FormFeedback>Please select a plant.</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col className="px-md-1" md="4">
                    <FormGroup>
                      <Label for="WeeklyOffs">Weekly Off's</Label>
                      <Input
                        type="select"
                        value={wklOff}
                        onChange={(e) => setWklOff(e.target.value)}
                      >
                        <option value="" disabled>
                          Select Day
                        </option>
                        {Days.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
            <CardFooter>
              <div className="text-right">
                <>
                  <Button color="danger" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button color="success" onClick={handleFormSubmit}>
                    Submit
                  </Button>
                </>
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default OrgWeeklyOffsAdd;


///////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////


// import React, { useState, useEffect } from "react";
// import {
//   Button,
//   Card,
//   CardHeader,
//   CardBody,
//   CardFooter,
//   FormGroup,
//   Form,
//   Input,
//   Row,
//   Col,
//   Label,
//   Progress,
//   FormFeedback,
// } from "reactstrap";
// import axios from "axios";
// import { APIURL } from "views/Apiurl";
// import { toast } from "react-toastify";
// import "bootstrap/dist/css/bootstrap.css";
// import { useForm } from 'react-hook-form'
// import * as yup from "yup";
// import { yupResolver } from "@hookform/resolvers/yup";

// function OrgWeeklyOffsAdd({ handleClose }) {
//   const Days = [
//     "Sunday",
//     "Monday",
//     "Tuesday",
//     "Wednesday",
//     "Thursday",
//     "Friday",
//     "Saturday",
//   ];
//   const [date, setDate] = useState("");
//   const [purpose, setpurpose] = useState("");
//   const [isPlantSelected, setIsPlantSelected] = useState(true);
//   const [wklOff, setWklOff] = useState("");
//   const [org, setorg] = useState("");
//   const [orgs, setorgs] = useState([]);
//   const [orgUnit, setOrgUnit] = useState("");
//   const [otherData, setOtherData] = useState([]);
//   const [plants, setplants] = useState([]);
//   const [selectedOrgData, setSelectedOrgData] = useState(null);
//   const [tenant, setTenant] = useState("");
//   const [selectedOrg, setSelectedOrg] = useState("");
//   useEffect(() => {
//     fetchorg();
//   }, []);

//   const schema = yup.object({
//     organization: yup.string().required("organization is required"),
//     plant: yup.string().required("plant is required"),
//     weekly_off: yup.string().required("Week Off is required"),
//   })
//   const form = useForm({
//     defaultValues: {
//       organization: "",
//       plant: "",
//       weekly_off: "",
//     },
//     resolver: yupResolver(schema)
//   });
//   const { register, control, handleSubmit, formState } = form;
//   const { errors, isSubmitSuccessful } = formState;

//   const handlePlantChange = (e) => {
//     const selectedValue = e.target.value;
//     setOrgUnit(selectedValue);
//     setIsPlantSelected(
//       plants.some((plant) => plant.organization_node_name === selectedValue)
//     );
//   };

//   const token = sessionStorage.getItem("token");
//   const decodedToken = JSON.parse(atob(token.split(".")[1]));
//   const tenantId =
//     decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"]; //18
//   useEffect(() => {
//     fetchplant();
//   }, [org]);
//   const fetchorg = async (logintenantId) => {
//     try {
//       const response = await axios.post(
//         APIURL,
//         {
//           query: `
//         query MyQuery($logintenantId: Int!) {
//           tnt_organization_structure(
//             where: {
//               parent_organization_id: { _eq: 0 },
//               tenantid: { _eq: $logintenantId }
//             }
//           ) {
//             organization_structure_id
//             organization_node_name
//             tenantid
//           }
//         }
//       `,
//           variables: {
//             logintenantId: parseInt(logintenantId),
//           },
//         },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
//           },
//         }
//       );
//       const fetchedOrgs = response.data.data.tnt_organization_structure;
//       setorgs(fetchedOrgs);
//       console.log(fetchedOrgs); //PsplDemoTent
//       const parentIds = fetchedOrgs.map((org) => org.organization_structure_id);
//       console.log(parentIds, "parentIds !!!"); //parentID == 41

//       const childResponse = await axios.post(
//         APIURL,
//         {
//           query: `
//         query MyQuery($parentIds: [Int!]) {
//           tnt_organization_structure(
//             where: {
//               parent_organization_id: { _in: $parentIds }
//             }
//           ) {
//             organization_structure_id
//             organization_node_name
//             tenantid
//           }
//         }
//       `,
//           variables: {
//             parentIds: parentIds,
//           },
//         },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
//           },
//         }
//       );

//       const childOrgs = childResponse.data.data.tnt_organization_structure;
//       console.log(childOrgs); // select organization == psplDemoTent_Org
//       const updatedOtherData = otherData.map((row) => {
//         const matchingOrg = childOrgs.find(
//           (org) => org.organization_structure_id === row.parent_organization_id
//         );

//         if (matchingOrg) {
//           return {
//             ...row,
//             parent_organization_id: matchingOrg.organization_structure_id,
//           };
//         }

//         return row;
//       });

//       setOtherData(childOrgs);
//       console.log(otherData);
//     } catch (error) {
//       console.error(error);
//     }
//   };
//   useEffect(() => {
//     const token = sessionStorage.getItem("token");
//     const decodedToken = JSON.parse(atob(token.split(".")[1]));
//     const roleId = decodedToken["sub"];
//     let logintenantId = null;

//     if (roleId == 1) {
//       logintenantId = sessionStorage.getItem("selectedTenantId");
//     } else {
//       logintenantId =
//         decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
//     }

//     fetchorg(logintenantId);
//   }, []);
//   const fetchplant = async () => {
//     if (org) {
//       // Only fetch if org is selected
//       try {
//         const response = await axios.post(
//           APIURL,
//           {
//             query: `
//           query MyQuery($p: Int!) {
//             tnt_organization_structure(where: {parent_organization_id: {_eq: $p}}) {
//               organization_structure_id
//               organization_node_name
//               tenantid
//             }
//           }
//         `,
//             variables: {
//               p: org,
//             },
//           },
//           {
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
//             },
//           }
//         );
//         setplants(response.data.data.tnt_organization_structure);
//         console.log(response.data.data.tnt_organization_structure);
//       } catch (error) {
//         console.error(error);
//       }
//     }
//   };

//   const handleOrgSelect = async (selectedOrgId) => {
//     if (selectedOrgId) {
//       try {
//         const response = await axios.post(
//           APIURL,
//           {
//             query: `
//           query GetCostCenter($orgId: Int!) {
//             tnt_organization_structure(where: { organization_structure_id: { _eq: $orgId } }) {
//               cost_centre_code
//             }
//           }
//         `,
//             variables: {
//               orgId: selectedOrgId,
//             },
//           },
//           {
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
//             },
//           }
//         );

//         if (
//           response.data &&
//           response.data.data &&
//           response.data.data.tnt_organization_structure &&
//           response.data.data.tnt_organization_structure.length > 0
//         ) {
//           setSelectedOrgData(response.data.data.tnt_organization_structure[0]);
//         } else {
//           console.log(response);
//           setSelectedOrgData(null);
//         }
//       } catch (error) {
//         console.error("Error fetching organization data:", error);
//       }
//     } else {
//       setSelectedOrgData(null);
//     }

//     setorg(selectedOrgId);
//   };

//   const handleFormSubmit = (data) => {
//     const token = sessionStorage.getItem("token");
//     const decodedToken = JSON.parse(atob(token.split(".")[1]));
//     const roleId = decodedToken["role_id"];
//     console.log(roleId, "roleId in weeklyoffs add");

//     let logintenantId = null;
//     if (roleId === 1) {
//       logintenantId = sessionStorage.getItem("selectedTenantId");
//     } else {
//       logintenantId =
//         decodedToken["https://hasura.io/jwt/claims"]["x-hasura-tenant-id"];
//     }

//     const formData = {
//       tenantid: tenantId,
//       ...data
//     };

//     axios
//       .post(
//         APIURL,
//         {
//           query: `
//             mutation InsertOrganizationStructure($formData: tnt_weeklyoff_insert_input!) {
//                 insert_tnt_weeklyoff(objects: [$formData]) {
//                 affected_rows
//                 }
//             }
//         `,
//           variables: {
//             formData: formData,
//           },
//         },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             "x-hasura-admin-secret": process.env.REACT_APP_X_API_SECRET_KEY, // Replace with your Hasura admin secret
//           },
//         }
//       )
//       .then((response) => {
//         console.log(response); // Handle the response data as needed
//         if (
//           response.data.data &&
//           response.data.data.insert_tnt_weeklyoff.affected_rows > 0
//         ) {
//           //erase data
//           toast.success("Data inserted successfully!");
//         } else {
//           toast.error("Failed to insert data.");
//         }
//       })
//       .catch((error) => {
//         console.error(error); // Handle the error
//         toast.error("An error occurred while inserting data.");
//       });
//   };
//   return (
//     <div className="content">
//       <Row className="progress-row">
//         <Col md="8">
//           <Card>
//             <CardHeader>
//               <h5 className="title">Add Weekly Offs</h5>
//             </CardHeader>
//             <CardBody>
//               <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
//                 <Row>
//                   <Col className="px-md-1" md="4">
//                     <FormGroup>
//                       <Label for="Holiday">Organization</Label>
//                       <select
//                         class="form-control"
//                         type="select"
//                         id="organization"
//                         {...register("organization", {
//                           onChange: (e) => {handleOrgSelect(e.target.value)},
//                         })}
//                       // value={org}
//                       // onChange={(e) => handleOrgSelect(e.target.value)}
//                       >
//                         <option value="">Select Organization</option>
//                         {otherData.map((org) => (
//                           <option
//                             key={org.organization_structure_id}
//                             value={org.organization_structure_id}
//                           >
//                             {org.organization_node_name}
//                           </option>
//                         ))}
//                       </select>
//                       <div>{errors.organization?.message}</div>
//                     </FormGroup>
//                   </Col>
//                   <Col className="px-md-1" md="3">
//                     <FormGroup>
//                       <Label for="Holiday">Plant</Label>
//                       <select
//                         class="form-control"
//                         type="select"
//                         id="plant"
//                         {...register("plant")}
//                       // value={orgUnit}
//                       // onChange={(e) => setOrgUnit(e.target.value)}
//                       // onChange={handlePlantChange}
//                       // invalid={!isPlantSelected}
//                       >
//                         <option value="">Select Plant</option>
//                         {plants.map((plant) => (
//                           <option
//                             key={plant.organization_node_name}
//                             value={plant.organization_node_name}
//                           >
//                             {plant.organization_node_name}
//                           </option>
//                         ))}
//                       </select>
//                       <div>{errors.plant?.message}</div>
//                     </FormGroup>
//                   </Col>
//                   <Col className="px-md-1" md="4">
//                     <FormGroup>
//                       <Label for="WeeklyOffs">Weekly Off's</Label>
//                       <select
//                         class="form-control"
//                         type="select"
//                         id="weekly_off"
//                         {...register("weekly_off")}
//                       // value={wklOff}
//                       // onChange={(e) => setWklOff(e.target.value)}
//                       >
//                         <option value="" disabled>
//                           Select Day
//                         </option>
//                         {Days.map((option, index) => (
//                           <option key={index} value={option}>
//                             {option}
//                           </option>
//                         ))}
//                       </select>
//                       <div>{errors.weekly_off?.message}</div>
//                     </FormGroup>
//                   </Col>
//                 </Row>
//                 <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
//                   <Button color="danger" onClick={handleClose}>
//                     Cancel
//                   </Button>
//                   <Button type="submit" color="success" >
//                     Submit
//                   </Button>
//                 </div>
//               </form>
//             </CardBody>
//           </Card>
//         </Col>
//       </Row>
//     </div>
//   );
// }

// export default OrgWeeklyOffsAdd;
