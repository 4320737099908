import React, { useState, useEffect } from "react";
import redirectToLoginIfTokenMissing from "views/TokenUtility";
import { TabContent, TabPane, Card, CardBody, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { Badge } from "reactstrap";
import { QueryApi } from "QueryApi.js";

function WorkforceEfficiencyTable() {
  const [activeTab, setActiveTab] = useState(1);
  const [machines, setmachines] = useState([]);
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    redirectToLoginIfTokenMissing();
  }, []);

  const convertMillisecondsToHours = (milliseconds) => {
    const hours = milliseconds / (1000 * 60 * 60);
    return hours.toFixed(2);
  };
  const formatPercentage = (percentage) => {
    return parseFloat(percentage).toFixed(3);
  };

  const [apiData, setApiData] = useState([]);
  useEffect(() => {
    fetchDataList();
  }, []);
  const fetchDataList = async () => {
    const data = await QueryApi(`
    query MyQuery {
        dm_workforce(order_by: {date: desc}) {
          org_id
          Org {
            organization_node_name
          }
          unit_id
          unit {
            organization_node_name
          }
          department_id
          dept {
            organization_node_name
          }
          operator_id
          Operator {
            employee_id
            first_name
            middle_name
            last_name
          }
          job_role
          skill
          workforce_utilization_per
          ole
          performance_per
          quality_per
          availability_per
          total_parts_produced
          proximity_in_time_total
        }
      }
      
  
  `);
    setApiData(data?.dm_workforce);
  };

  function actionFormatter(cell, row) {
    return (
      <div>
        <Link to={`/admin/dashboard/machine-equipment/${row.machine_auto_id}`}>
          <FontAwesomeIcon
            icon={faArrowRightToBracket} // Use the same icon here
            className="btn btn-primary btn-go-deep"
            style={{
              fontSize: "30px !important",
              background: "none",
              pointerEvents: "none",
            }}
          />
        </Link>
      </div>
    );
  }
  function handleEdit(row) {}
  const activeFormatter = (cell, row) => {
    const isActive = row.is_active === "1";

    return (
      <Badge color={isActive ? "info" : "danger"}>
        {isActive ? "Active" : "Inactive"}
      </Badge>
    );
  };

  const nullFormatter = (cell) => {
    return cell === null ? "Null" : cell;
  };
  const columns = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "Org.organization_node_name",
      text: "organization",
      headerStyle: { width: "500px" },
      formatter: nullFormatter,
    },
    {
      dataField: "unit.organization_node_name",
      text: "Unit",
      headerStyle: { width: "500px" },
      formatter: nullFormatter,
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
      formatter: nullFormatter,
    },
    {
      dataField: "operator_id",
      text: "Operator Id",
      headerStyle: { width: "500px" },
      formatter: nullFormatter,
    },
    {
      dataField: "Operator",
      text: "Operator Name",
      headerStyle: { width: "500px" },
      formatter: (cell) => {
        if (cell === null) {
          return "Null"; // or whatever default value you want
        } else {
          return `${cell.first_name} ${cell.last_name}`;
        }
      },
    },
    {
      dataField: "job_role",
      text: "job role",
      headerStyle: { width: "500px" },
      formatter: nullFormatter,
    },
    {
      dataField: "skill",
      text: "skill",
      headerStyle: { width: "500px" },
      formatter: nullFormatter,
    },
    {
      dataField: "workforce_utilization_per",
      text: "workforce utilization %",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        if (data == null) {
          return "Null"; // or whatever default value you want
        } else {
          return `${data.toFixed(3)}%`;
        }
      },
    },
    {
      dataField: "ole",
      text: "ole",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        if (data == null) {
          return "Null"; // or whatever default value you want
        } else {
          return `${data.toFixed(3)}%`;
        }
      },
    },
    {
      dataField: "availability_per",
      text: "availability %",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        if (data == null) {
          return "Null"; // or whatever default value you want
        } else {
          return `${data.toFixed(3)}%`;
        }
      },
    },
    {
      dataField: "performance_per",
      text: "performance %",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        if (data == null) {
          return "Null"; // or whatever default value you want
        } else {
          return `${data.toFixed(3)}%`;
        }
      },
    },

    {
      dataField: "quality_per",
      text: "quality %",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        if (data == null) {
          return "Null"; // or whatever default value you want
        } else {
          return `${data.toFixed(3)}%`;
        }
      },
    },
    {
      dataField: "total_parts_produced",
      text: "total parts produced",
      headerStyle: { width: "500px" },
      formatter: nullFormatter,
    },
    {
      dataField: "proximity_in_time_total",
      text: "proximity time (minutes)",
      headerStyle: { width: "500px" },
      formatter: (cell) => {
        if (cell === null) {
          return "Null"; // or whatever default value you want
        } else {
          let minutes = (cell / 60000).toFixed(3);
          return `${minutes}`;
        }
      },
    },
  ];

  return (
    <div className="content">
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={apiData}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <div>
                        <div
                          className="float-right"
                          style={{
                            display: "flex",
                            marginBottom: "18px",
                            gap: "12px",
                          }}
                        ></div>
                        <BootstrapTable
                          {...props.baseProps}
                          filter={filterFactory()}
                          pagination={paginationFactory()}
                          headerClasses="sorted-asc sorted-desc"
                          wrapperClasses="table-responsive"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default WorkforceEfficiencyTable;
