import React, { useState, useEffect } from "react";
import "./style.css";
import { Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import EmpJobAll from "./EmpJobAll";
import TenantEmployeeJobDescription from "./TenantEmployeeJobDescription";
import EmpTagAssignAll from "./EmpTagAssignAll";

function ManagePeople(props) {
  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      sessionStorage.setItem("activeTab", tab);
    }
  };
  useEffect(() => {
    // Retrieve the active tab from sessionStorage
    const storedActiveTab = sessionStorage.getItem("activeTab");
    if (storedActiveTab) {
      setActiveTab(storedActiveTab);
    }
  }, []);

  const getBreadcrumbItemText = (tabId) => {
    switch (tabId) {
      case "1":
        return "Employee Master";
      case "2":
        return "Add Employee";
      case "3":
        return "Tenant Employee Job Description";
      case "4":
        return "Org Employee Machines";
      case "5":
        return "Work Schedule";
      default:
        return "Manage People";
    }
  };

  return (
    <>
      <div className="content">
        <div class="container1">
          <h1 className="main-heading">Manage People</h1>
          <div class="breadcrumb-container">
            <Breadcrumb>
              <BreadcrumbItem>
                <FontAwesomeIcon icon={faHouse} />
              </BreadcrumbItem>
              <BreadcrumbItem>Manage People</BreadcrumbItem>
              <BreadcrumbItem active>
                {getBreadcrumbItemText(activeTab)}
              </BreadcrumbItem>
            </Breadcrumb>
          </div>
        </div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => toggleTab("1")}
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor: activeTab === "1" ? "#5555e7" : "transparent",
                color: activeTab === "1" ? "white" : "white",
              }}
            >
              Employee Master
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => toggleTab("2")}
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor: activeTab === "2" ? "#5555e7" : "transparent",
                color: activeTab === "2" ? "white" : "white",
              }}
            >
              Employee Tag Assignment
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => toggleTab("3")}
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor: activeTab === "3" ? "#5555e7" : "transparent",
                color: activeTab === "3" ? "white" : "white",
              }}
            >
              Employee Job Description
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col>
                <EmpJobAll />
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="2">
            <Row>
              <Col>
                <EmpTagAssignAll />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col>
                <TenantEmployeeJobDescription />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </>
  );
}

export default ManagePeople;
