import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "./Apiurl.js";
import {
  TabContent,
  TabPane,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import Modal from "@material-ui/core/Modal";
import WorkscheduleAdd from "./WorkScheduleAdd";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
const { SearchBar } = Search;

function WorkscheduleAll() {
  const [activeTab, setActiveTab] = useState(1);
  const [works, setworks] = useState([]);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const roleId = decodedToken["sub"];
      const logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
      const storedTenantId = sessionStorage.getItem("selectedTenantId");

      let query = ""; // Define the query variable outside the if-else block
      let variables = {}; // Define the variables object

      if (roleId == 1) {
        if (storedTenantId !== null) {
          query = `
          query MyQuery($tenantId: Int!) {
            tnt_work_schedule_masters(where: { tenantid: { _eq: $tenantId } }) {
              work_schedule_id
            unit {
              organization_node_name
            }
            shifttype {
              code
            }
            organization {
              organization_node_name
            }
            shift_name
            shift_start_time
            shift_end_time
            }
          }
        `;

          variables = {
            tenantId: storedTenantId,
          };
        } else {
          query = `
          query MyQuery {
            tnt_work_schedule_masters {
              work_schedule_id
              unit {
                organization_node_name
              }
              shifttype {
                code
              }
              organization {
                organization_node_name
              }
              shift_name
              shift_start_time
              shift_end_time
            }
          }
          `;
        }
      } else {
        query = `
          query MyQuery {
            tnt_work_schedule_masters {
              work_schedule_id
              unit {
                organization_node_name
              }
              shifttype {
                code
              }
              organization {
                organization_node_name
              }
              shift_name
              shift_start_time
              shift_end_time
            }
          }
        `;
      }

      const response = await axios.post(
        APIURL,
        {
          query: query,
          variables: variables,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );
      const WorkScheduleMaster =
        response.data.data.tnt_work_schedule_masters.map(
          (structure, index) => ({
            ...structure,
            serialNo: index + 1,
          })
        );
      setworks(WorkScheduleMaster);
      //  console.log(works);
    } catch (error) {
      // console.error(error);
    }
  };

  function actionFormatter(cell, row) {
    return (
      <div>
        <Col
          className="font-icon-list col-xs-6 col-xs-6"
          lg="2"
          md="3"
          sm="4"
          onClick={() => handleEdit(row)}
        ></Col>
        <FontAwesomeIcon
          icon={faPencilAlt}
          className="btn btn-primary tim-icons icon-pencil"
          style={{
            fontSize: "70%",
            background: "none",
            marginRight: "-46px",
            pointerEvents: "none",
          }}
        />
        <Link to={`/admin/org-structure/${row.organization_structure_id}`}>
          <FontAwesomeIcon
            icon={faTrash}
            className="btn btn-primary tim-icons icon-pencil"
            style={{
              fontSize: "70%",
              background: "none",
              pointerEvents: "none",
            }}
          />
        </Link>
      </div>
    );
  }

  function handleEdit(row) {
    // Logic for handling the edit action
    //  console.log("Editing row:", row);
  }

  const convertUtcToIst = (utcTime) => {
    const utcDateTime = new Date(`1970-01-01T${utcTime}Z`);
    const istDateTime = new Date(
      utcDateTime.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
    );
    const hours = istDateTime.getHours();
    const minutes = istDateTime.getMinutes();
    const seconds = istDateTime.getSeconds();
    if (hours === 12 && minutes === 0 && seconds === 0) {
      return "12:00:00 am";
    }
    return istDateTime.toLocaleTimeString("en-IN", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
  };

  const columns = [
    {
      dataField: "serialNo",
      text: "Sr. No.",
    },
    {
      dataField: "organization.organization_node_name",
      text: "Organization",
      headerStyle: { width: '500px' }
    },
    {
      dataField: "unit.organization_node_name",
      text: "Unit/Plant",
      headerStyle: { width: '500px' }
    },
    {
      dataField: "shift_name",
      text: "Shift Name",
      headerStyle: { width: '500px' }
    },
    {
      dataField: "shift_start_time",
      text: "Shift Start Time",
      formatter: (cell, row) => convertUtcToIst(row.shift_start_time),
    },
    {
      dataField: "shift_end_time",
      text: "Shift End Time",
      formatter: (cell, row) => convertUtcToIst(row.shift_end_time),
    },
    {
      dataField: "action",
      text: "Action",
      formatter: actionFormatter,
    },
  ];

  return (
    <div className="content">
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4" className="table-card-header">
                    Manage Workschedule Master
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={works}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <div>
                        <div
                          className="float-right"
                          style={{
                            display: "flex",
                            marginBottom: "18px",
                            gap: "12px",
                          }}
                        >
                          <div className="float-left">
                            <SearchBar {...props.searchProps} />
                          </div>
                          <div className="float-right">
                            <button
                              className="btn btn-secondary"
                              onClick={handleOpen}
                            >
                              Add new Work Schedule
                            </button>
                          </div>
                        </div>
                        <BootstrapTable
                          {...props.baseProps}
                          filter={filterFactory()}
                          pagination={paginationFactory()}
                          headerClasses="sorted-asc sorted-desc"
                          wrapperClasses="table-responsive"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
      <Modal
        onClose={handleClose}
        open={open}
        style={{
          boxShadow: "2px solid black",
          width: "100%",
          top: "15%",
          left: "15%",
        }}
      >
        <div>
          <button
            onClick={handleClose}
            style={{
              position: "fixed",
              top: "112px",
              right: "399px",
              background: "transparent",
              border: "none",
              color: "white",
              fontSize: "40px",
              /* margin-top: -85px; */
              cursor: "pointer",
              float: "right",
              zIndex: 1,
            }}
          >
            &times; {/* This is the 'x' character for the close button */}
          </button>
          <WorkscheduleAdd onInsertSuccess={fetchData} />
        </div>
      </Modal>
    </div>
  );
}

export default WorkscheduleAll;
