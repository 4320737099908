import React, { useState } from "react";
import { Card, Row, Col } from "reactstrap";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import InfoCycleTime from "./InfoCycleTime";
import FocusCycleTime from "./FocusCycleTime";
import ActionReusable from "components/ReusableComponents/ActionsReusable";

function IFACycleTime(props) {
  const [activeTab, setActiveTab] = useState("1");

  const tableData = [
    { id: 1, name: " ", description: " " },
    { id: 2, name: " ", description: "" },
    { id: 3, name: " ", description: "" },
    { id: 4, name: " ", description: "" },
    { id: 5, name: " ", description: "" },
  ];
  const tableData1 = [
    { id: 1, name: " ", description: " " },
    { id: 2, name: " ", description: "" },
    { id: 3, name: " ", description: "" },
    { id: 4, name: " ", description: "" },
    { id: 5, name: " ", description: "" },
  ];
  return (
    <>
      <div className="content">
        <Row>
          <Col lg="3">
            <Card
              className="Workforce-availability"
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                color: "white",
                height: "40px",
                display: "flex",
                alignItems: "left",
                justifyContent: "center",
              }}
            >
              I.F.A Cycle Time
            </Card>
          </Col>
          <Col lg="9">
            <Nav
              tabs
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                borderBottom: "none",
              }}
            >
              <NavItem style={{ flex: 1, padding: "0 10px" }}>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => setActiveTab("1")}
                  style={{
                    width: "100%",
                    textAlign: "center",
                    backgroundColor:
                      activeTab === "1" ? "white" : "transparent",
                    color: activeTab === "1" ? "blue" : "white",
                    borderRadius: "5px",
                    margin: "0px 20px",
                    border: "1px solid #D3D3D3",
                  }}
                >
                  Information
                </NavLink>
              </NavItem>
              <NavItem style={{ flex: 1, padding: "0 10px" }}>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => setActiveTab("2")}
                  style={{
                    width: "100%",
                    textAlign: "center",
                    backgroundColor:
                      activeTab === "2" ? "white" : "transparent",
                    color: activeTab === "2" ? "blue" : "white",
                    borderRadius: "5px",
                    margin: "0px 20px",
                    border: "1px solid #D3D3D3",
                  }}
                >
                  Focus
                </NavLink>
              </NavItem>
              <NavItem style={{ flex: 1, padding: "0 10px" }}>
                <NavLink
                  className={classnames({ active: activeTab === "3" })}
                  onClick={() => setActiveTab("3")}
                  style={{
                    width: "100%",
                    textAlign: "center",
                    backgroundColor:
                      activeTab === "3" ? "white" : "transparent",
                    color: activeTab === "3" ? "blue" : "white",
                    borderRadius: "5px",
                    margin: "0px 20px",
                    border: "1px solid #D3D3D3",
                  }}
                >
                  Action
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col>
                <InfoCycleTime />
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="2">
            <Row>
              <Col>
                <FocusCycleTime />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col><ActionReusable  tableData={[
          { id: 1, name: "", description: "" },
          { id: 2, name: "", description: "" },
          { id: 3, name: "", description: "" },
          { id: 4, name: "", description: "" },
          { id: 5, name: "", description: "" },
         
        ]}
        tableData1={[
          { id: 1, name: "", description: "" },
          { id: 2, name: "", description: "" },
          { id: 3, name: "", description: "" },
          { id: 4, name: "", description: "" },
          { id: 5, name: "", description: "" },
         
        ]}/></Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </>
  );
}

export default IFACycleTime;
