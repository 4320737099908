import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "views/Apiurl";
import redirectToLoginIfTokenMissing from "views/TokenUtility";
import {
  TabContent,
  TabPane,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import Modal from "@material-ui/core/Modal";
import OrgHolidayAdd from "./OrgHolidaysAdd";
import { Badge } from "reactstrap";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";

function OrganizationHolidays() {
  const [activeTab, setActiveTab] = useState(1);
  const [struct, setstruct] = useState([]);
  const [orgstruct, setorgstruct] = useState([]);
  const [open, setOpen] = React.useState(false);

  const token = sessionStorage.getItem("token");
  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const tenantId =
    decodedToken["https://hasura.io/jwt/claims"]["x-hasura-tenant-id"];

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    redirectToLoginIfTokenMissing(); // Call the utility function to validate the token
  }, []);

  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const roleId = decodedToken["sub"];
      const logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
      const storedTenantId = sessionStorage.getItem("selectedTenantId");

      let query = ""; // Define the query variable outside the if-else block
      let variables = {}; // Define the variables object
      console.log("Outside condition" + storedTenantId);

      if (roleId == 1) {
        if (storedTenantId !== null) {
          console.log("inside 1st condition" + storedTenantId);
          query = `
        query MyQuery($TenantId: Int!) {
          tnt_holidays(where: { tenantid: { _eq: $TenantId } }) {
            date
            org {
              organization_node_name
            }
            plant
            purpose
            tenantid
            sr_no
          }
        }
      `;

          variables = {
            TenantId: storedTenantId,
          };
        } else {
          query = ` 
        query GetTntHolidays {
          tnt_holidays {
            date
            sr_no
            tenantid
            org {
              organization_node_name
            }
            plant
            purpose
          }
        }
      `;
        }
      } else {
        query = `
      query GetTntHolidays {
        tnt_holidays {
          date
          sr_no
          tenantid
          org {
            organization_node_name
          }
          plant
          purpose
        }
      }
      `;
      }

      const response = await axios.post(
        APIURL,
        {
          query: query,
          variables: variables,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const orgStructuresWithStatus = response.data.data.tnt_holidays.map(
        (structure, index) => ({
          ...structure,
          serialNo: index + 1,
          status: "On",
        })
      );

      setorgstruct(orgStructuresWithStatus);
      //  console.log(orgstruct);
    } catch (error) {
      console.error(error);
    }
  };

  const activeFormatter = (cell, row) => {
    const isActive = row.is_active === "1";

    return (
      <Badge color={isActive ? "info" : "danger"}>
        {isActive ? "Active" : "Inactive"}
      </Badge>
    );
  };
  function actionFormatter(cell, row) {
    return (
      <div>
        <Col
          className="font-icon-list col-xs-6 col-xs-6"
          lg="2"
          md="3"
          sm="4"
          onClick={() => handleEdit(row)}
        ></Col>
        <Link to={`/admin/employee-job-details/${row.emp_job_id}`}>
          <i className="btn btn-primary tim-icons icon-pencil" />
        </Link>
      </div>
    );
  }

  function handleEdit(row) {}

  const columns = [
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "plant",
      text: " Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "Date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "purpose",
      text: "Purpose",
      headerStyle: { width: "500px" },
    },
  ];

  function actionFormatter(cell, row) {
    return (
      <div>
        <Col
          className="font-icon-list col-xs-6 col-xs-6"
          lg="2"
          md="3"
          sm="4"
          onClick={() => handleEdit(row)}
        ></Col>
        <FontAwesomeIcon
          icon={faPencilAlt}
          className="btn btn-primary tim-icons icon-pencil"
          style={{
            fontSize: "70%",
            background: "none",
            marginRight: "-46px",
            pointerEvents: "none",
          }}
        />
        <Link to={`/admin/org-structure/${row.organization_structure_id}`}>
          <FontAwesomeIcon
            icon={faTrash}
            className="btn btn-primary tim-icons icon-pencil"
            style={{
              fontSize: "70%",
              background: "none",
              pointerEvents: "none",
            }}
          />
        </Link>
      </div>
    );
  }
  const paginationClass = {
    noPagination: true, // Disable pagination
    paginationSize: 0, // Remove the dropdown button for records per page
    showTotal: (from, to, size) => `Total ${size} records`, // Customize the pagination label
  };

  return (
    <div className="content">
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4" className="table-card-header">
                    Manage Holiday's
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={struct}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <div>
                        <div
                          className="float-right"
                          style={{
                            display: "flex",
                            marginBottom: "18px",
                            gap: "12px",
                          }}
                        >
                          {/* <div className="float-left">
                            <SearchBar {...props.searchProps} />
                          </div> */}
                          <div className="float-right">
                            <button
                              className="btn btn-secondary"
                              onClick={handleOpen}
                            >
                              Add Holiday
                            </button>
                          </div>
                        </div>
                        <BootstrapTable
                          {...props.baseProps}
                          keyField="id"
                          data={orgstruct}
                          columns={columns}
                          filter={filterFactory()}
                          pagination={paginationFactory({
                            bootstrap4: true,
                          })}
                          headerClasses="sorted-asc sorted-desc"
                          wrapperClasses="table-responsive"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
      <Modal
        onClose={handleClose}
        open={open}
        style={{
          boxShadow: "2px solid black",
          width: "100%",
          top: "15%",
          left: "15%",
        }}
      >
        <div>
          <button
            onClick={handleClose}
            style={{
              position: "fixed",
              top: "112px",
              right: "399px",
              background: "transparent",
              border: "none",
              color: "white",
              fontSize: "40px",
              /* margin-top: -85px; */
              cursor: "pointer",
              float: "right",
              zIndex: 1,
            }}
          >
            &times;
          </button>
          {/* Modal content */}
          <OrgHolidayAdd fetchData={fetchData} handleClose={handleClose} />
        </div>
      </Modal>
    </div>
  );
}

export default OrganizationHolidays;
