import React, { useState, useEffect } from "react";
import "./style.css";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Progress,
} from "reactstrap";
import axios from "axios";
import { APIURL } from "./Apiurl.js";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

function PartAndCustomerEdit({handleCloseEditModal,selectedRow,fetchData}) {
  // const { id } = useParams();
  console.log("selectedRow===>",selectedRow);
  const [id,setId] = useState(selectedRow.tnt_part_no)
  const [activeStep, setActiveStep] = useState(1);
  const [progress, setProgress] = useState(0);
  const [showEdit, setShowEdit] = useState(true);
  const [tenantId, setTenantId] = useState("");
  const [end_customer_no, setEndCustomerNo] = useState("");
  const [end_customer_name, setEndCustomerName] = useState("");
  const [end_customer_location, setEndCustomerLocation] = useState("");
  const [part_number, setPartNumber] = useState("");
  const [partNameDec, setPartNameDesc] = useState("");
  const [drawing, setDrawing] = useState("");
  const [revisionNo, setRevisionNo] = useState("");
  const [revisionNoDate, setRevisionDate] = useState("");
  const [pfd, setPfd] = useState("");
  const [processPlan, setProcessPlanStagewise] = useState("");
  const [packingStandard, setPackingStd] = useState("");
  const [qualityPlan, setQualityPlanPdir] = useState("");
  const [ppap, setPpap] = useState("");
  const [materialSpecification, setMaterialSpecification] = useState("");
  const [thickness, setThickness] = useState("");
  const [isLastStep, setIsLastStep] = useState(false);
  const [pRevisionDate, setpRevisionDate] = useState("");
  const [toolOrDieId, setToolOrDieId] = useState("");
  const [tagId, setTagId] = useState("");

  useEffect(() => {
    fetchRecordToEdit();
  }, []);

  const handleNext = () => {
    if (activeStep === 1) {
      setProgress(100);
    }
    setActiveStep(activeStep + 1);
    if (activeStep + 1 === 3) {
      setIsLastStep(true);
    }
  };

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
    setIsLastStep(false);
  };

  const handleClosePage = () => {
    setShowEdit(false);
  };

  const fetchRecordToEdit = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const roleId = decodedToken["sub"];
      const logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
      const storedTenantId = sessionStorage.getItem("selectedTenantId");

      const response = await axios.post(
        APIURL,
        {
          query: `
          query GetOrganizationPartNo($id: Int!) {
            tnt_orgnization_partscustomer_master_by_pk(tnt_part_no: $id) {
                tenantid
                revisionno_date
                revision_no
                quality_plan_pdir
                created_at
                created_by
                created_on
                drawing
                end_customer_location
                end_customer_name
                material_specification
                paking_std
                part_name_description
                part_number
                pfd
                ppap
                ppap_revision_date
                process_plan_stagewise
                thickness
                tnt_part_no
                updated_at
                toolidordieid
                tag_id
            }
          }
        `,
          variables: { id: parseInt(id) }, // Use the 'id' parameter from useParams
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const record =
        response.data.data.tnt_orgnization_partscustomer_master_by_pk;
      console.log(record, "record");
      setTenantId(record.tenantid);
      setEndCustomerNo(record.end_customer_no);
      setEndCustomerName(record.end_customer_name);
      setEndCustomerLocation(record.end_customer_location);
      setPartNumber(record.part_number);
      setPartNameDesc(record.part_name_description);
      setDrawing(record.drawing);
      setRevisionNo(record.revision_no);
      setRevisionDate(record.revisionno_date);
      setPfd(record.pfd);
      setProcessPlanStagewise(record.process_plan_stagewise);
      setPackingStd(record.paking_std);
      setQualityPlanPdir(record.quality_plan_pdir);
      setPpap(record.ppap);
      setpRevisionDate(record.ppap_revision_date);
      setThickness(record.thickness);
      setToolOrDieId(record.toolidordieid);
      setTagId(record.tag_id);
      setMaterialSpecification(record.material_specification);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const token = sessionStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["role_id"];
    let logintenantId = null;
    if (roleId === 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-tenant-id"];
    }

    const formData = {
      tenantid: logintenantId,
      end_customer_name: end_customer_name,
      end_customer_location: end_customer_location,
      part_number: part_number,
      part_name_description: partNameDec,
      drawing: drawing,
      revision_no: revisionNo,
      revisionno_date: revisionNoDate,
      pfd: pfd,
      process_plan_stagewise: processPlan,
      paking_std: packingStandard,
      quality_plan_pdir: qualityPlan,
      ppap: ppap,
      material_specification: materialSpecification,
      thickness: thickness,
      ppap_revision_date: pRevisionDate,
      toolidordieid: toolOrDieId,
      tag_id: tagId,
    };
    axios
      .post(
        APIURL,
        {
          query: `
              mutation UpdatePartAndCustomer($id: Int!, $formData: tnt_orgnization_partscustomer_master_set_input!) {
              update_tnt_orgnization_partscustomer_master_by_pk(pk_columns: { tnt_part_no: $id }, _set: $formData) {
                tnt_part_no
              }
            }
          `,
          variables: {
            id: parseInt(id),
            formData: formData,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-hasura-admin-secret": process.env.REACT_APP_X_API_SECRET_KEY, // Replace with your Hasura admin secret
          },
        }
      )
      .then((response) => {
        console.log(response); // Handle the response data as needed
        console.log(response.data);
        const updatedPartAndCust =
          response.data?.data
            ?.update_tnt_orgnization_partscustomer_master_by_pk;
        if (updatedPartAndCust) {
          console.log("hello");
          setProgress(0);
          setActiveStep(1);
          toast.success("Data Updated successfully!");
          fetchData();
        } else {
          toast.error("Failed to insert data.");
        }
      })
      .catch((error) => {
        console.error(error); // Handle the error
        toast.error("An error occurred while inserting data.");
      });
  };
  //function to perform delete operation
  const handleDelete = () => {
    const confirmDeletion = window.confirm(
      "Are you sure you want to delete this record?"
    );
    if (confirmDeletion) {
      axios
        .post(APIURL, {
          query: `
                mutation DeletePartAndCustomer($id: Int!) {
                  delete_tnt_orgnization_partscustomer_master_by_pk(tnt_part_no: $id) {
                    tnt_part_no
                  }
                }
              `,
          variables: { id: parseInt(id) },
        })
        .then((response) => {
          const deletedPartAndCust =
            response.data?.data
              ?.delete_tnt_orgnization_partscustomer_master_by_pk;
          if (deletedPartAndCust) {
            toast.success("Record deleted successfully!");
            // Redirect to a different page or perform other actions as needed.
          } else {
            toast.error("Failed to delete the record.");
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error("An error occurred while deleting the record.");
        });
    }
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case 1:
        return (
          <>
            <Row>
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="end_customer_name" className="plan-admin">End Customer Name</Label>
                  <Input
                    type="text"
                    value={end_customer_name}
                    onChange={(e) => setEndCustomerName(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="end_customer_location" className="plan-admin">
                    End Customer Location
                  </Label>
                  <Input
                    placeholder=""
                    type="text"
                    value={end_customer_location}
                    onChange={(e) => setEndCustomerLocation(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="partno" className="plan-admin">Part No</Label>
                  <Input
                    type="text"
                    value={part_number}
                    onChange={(e) => setPartNumber(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {/* <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="partno">Part No</Label>
                  <Input
                    type="text"
                    value={part_number}
                    onChange={(e) => setPartNumber(e.target.value)}
                  />
                </FormGroup>
              </Col> */}
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <FormGroup>
                    <Label for="partNameDec" className="plan-admin">Part Name Description</Label>
                    <Input
                      type="text"
                      value={partNameDec}
                      onChange={(e) => setPartNameDesc(e.target.value)}
                    />
                  </FormGroup>
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="orgtype" className="plan-admin">Drawing</Label>
                  <Input
                    type="text"
                    value={drawing}
                    onChange={(e) => setDrawing(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="tenantId" className="plan-admin">Revision Number</Label>
                  <Input
                    type="text"
                    value={revisionNo}
                    onChange={(e) => setRevisionNo(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {/* <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="orgtype">Drawing</Label>
                  <Input
                    type="text"
                    value={drawing}
                    onChange={(e) => setDrawing(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="tenantId">Revision Number</Label>
                  <Input
                    type="text"
                    value={revisionNo}
                    onChange={(e) => setRevisionNo(e.target.value)}
                  />
                </FormGroup>
              </Col> */}
            </Row>
            <Row>
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="" className="plan-admin">Revision Number Date</Label>
                  <Input
                    type="text"
                    value={revisionNoDate}
                    onChange={(e) => setRevisionDate(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="pfd" className="plan-admin">P.F.D</Label>
                  <Input
                    type="text"
                    value={pfd}
                    onChange={(e) => setPfd(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="processPlan" className="plan-admin">Process Plan Stage Wise</Label>
                  <Input
                    type="text"
                    value={processPlan}
                    onChange={(e) => setProcessPlanStagewise(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        );
      case 2:
        return (
          <>
            <Row>
            <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="part_name_description" className="plan-admin">Packing Standard</Label>
                  <Input
                    placeholder=""
                    type="text"
                    value={packingStandard}
                    onChange={(e) => setPackingStd(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="qualityPlan" className="plan-admin">Tool ID / Die ID</Label>
                  <Input
                    type="text"
                    value={toolOrDieId}
                    onChange={(e) => setToolOrDieId(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="ppap" className="plan-admin">Tag ID</Label>
                  <Input
                    placeholder=""
                    type="text field"
                    value={tagId}
                    onChange={(e) => setTagId(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="qualityPlan" className="plan-admin">Quality Plan PDIR</Label>
                  <Input
                    type="text"
                    value={qualityPlan}
                    onChange={(e) => setQualityPlanPdir(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="ppap" className="plan-admin">PPAP</Label>
                  <Input
                    placeholder=""
                    type="text field"
                    value={ppap}
                    onChange={(e) => setPpap(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="material_specification" className="plan-admin">
                    Material Specification
                  </Label>
                  <Input
                    type="text"
                    value={materialSpecification}
                    onChange={(e) => setMaterialSpecification(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              
              <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="ppap_revision_date" className="plan-admin">PPAP Revision Date</Label>
                  <Input
                    type="text"
                    value={pRevisionDate}
                    onChange={(e) => setpRevisionDate(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="thickness" className="plan-admin">Thickness</Label>
                  <Input
                    type="text"
                    value={thickness}
                    onChange={(e) => setThickness(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row></Row>
          </>
        );
      default:
        return null;
    }
  };
  const calculateProgress = () => {
    return Math.round(((activeStep - 1) / 2) * 100); // Update the denominator to match the total number of steps minus one
  };

  const renderProgressBarDestinations = () => {
    const step1Class = activeStep >= 1 ? "progress-destination-active" : "";
    const step2Class = activeStep >= 2 ? "progress-destination-active" : "";
    const step3Class = activeStep >= 3 ? "progress-destination-active" : "";
    return (
      <div className="progress-destinations">
        <div className={`progress-destination ${step1Class}`}>
          <span>1</span>
        </div>
        <div className={`progress-destination ${step2Class}`}>
          <span>2</span>
        </div>
        <div className={`progress-destination ${step3Class}`}>
          <span>3</span>
        </div>
      </div>
    );
  };
  const renderStepButtons = () => {
    if (activeStep === 1) {
      return (
        <>
          <Link to="/admin/planing-administration">
            <Button color="danger" style={{ marginRight: "5px" }} onClick={handleCloseEditModal}>
              {" "}
              Cancel
            </Button>
          </Link>
          <Button color="primary" onClick={handleNext}>
            Next
          </Button>
        </>
      );
    } else if (activeStep === 2) {
      return (
        <>
          <Link to="/admin/planing-administration">
            <Button color="danger" style={{ marginRight: "5px" }} onClick={handleCloseEditModal}>
              {" "}
              Cancel
            </Button>
          </Link>
          <Button color="primary" onClick={handlePrevious}>
            Previous
          </Button>
          <Button color="success" onClick={handleFormSubmit}>
            Save
          </Button>
        </>
      );
    }
  };
  return (
    showEdit && (
      <div className="content" >
        <Row>
          <Col md="8">
            <div className="progress-container">
              {renderProgressBarDestinations()}
              <Progress
                max="100"
                value={calculateProgress()}
                className="progress-bar-sm progress-bar-striped"
              />
            </div>
          </Col>
        </Row>
        <Row className="progress-row">
          <Col md="8">
            <Card>
              <CardHeader>
                <h4 className="title">Update Your Parts And Customers
                <button type="button" className="close" aria-label="Close" 
                    style={{
                    // float: "right", 
                    background: "transparent",
                    border: "none",
                    color: "#ffffff" ,
                    fontSize: "35px",
                    cursor: "pointer",
                    marginTop: "-40px",
                    }}
                    onClick={handleCloseEditModal}
                    >
                    &times;
                    </button>
                </h4>
              </CardHeader>
              <CardBody>
                <Form>{renderStepContent()}</Form>
              </CardBody>
              <CardFooter>
                <div className="text-right">{renderStepButtons()}</div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    )
  );
}
export default PartAndCustomerEdit;
