import React, { useState, useEffect } from "react";
import axios from "axios";
import  { APIURL } from './Apiurl.js';
import {
  TabContent,
  TabPane,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import Modal from '@material-ui/core/Modal';
import TenantEmpJobDescriptionAdd from "./TenantEmpJobDescriptionAdd";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";

const { SearchBar } = Search;

function JobrolemasterAll() {
  const [activeTab, setActiveTab] = useState(1);
  const [tejd, settejd] = useState([]);
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    fetchData();
  }, []);
  const handleClose = () => {
    setOpen(false);
};

const handleOpen = () => {
    setOpen(true);
};
  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      const roleId = decodedToken['sub'];
      const logintenantId = decodedToken['https://hasura.io/jwt/claims']['x-hasura-org-id'];
      const storedTenantId = sessionStorage.getItem("selectedTenantId");
  
      
      let query = ''; // Define the query variable outside the if-else block
      let variables = {}; // Define the variables object
      
      if (roleId == 1) {
        if (storedTenantId !== null) {
          query = `
          query MyQuery($tenantId: Int!) {
            tenant_employee_job_descriptions(where: { tenantid: { _eq: $tenantId } }) {
              emp_jd_mapping_id
              employee_id
              emp_name
              organization_id
              tenant{
                tenant_client_name
              }
              organization{
                organization_node_name
              }
              unit{
                organization_node_name
              }
              dept{
                organization_node_name
              }
              job_id
              emp_job_role
              jobrole{
                job_role
              }
              job_title
              location
              reporting_manager_id
              position
              job_role_defination
              jd_id
            }
          }
          `;
          variables = {
            tenantId: storedTenantId,
          };
        }else{
          query = `
          query MyQuery {
            tenant_employee_job_descriptions {
              emp_jd_mapping_id
              employee_id
              emp_name
              organization_id
              tenant{
                tenant_client_name
              }
              organization{
                organization_node_name
              }
              unit{
                organization_node_name
              }
              dept{
                organization_node_name
              }
              job_id
              emp_job_role
              jobrole{
                job_role
              }
              job_title
              location
              reporting_manager_id
              position
              job_role_defination
              jd_id
            }
          }
          `;
        }
       
      } else {
        query = `
        query MyQuery {
          tenant_employee_job_descriptions {
            emp_jd_mapping_id
            employee_id
            emp_name
            organization_id
            tenant{
              tenant_client_name
            }
            organization{
              organization_node_name
            }
            unit{
              organization_node_name
            }
            dept{
              organization_node_name
            }
            job_id
            emp_job_role
            jobrole{
              job_role
            }
            job_title
            location
            reporting_manager_id
            position
            job_role_defination
            jd_id
          }
        }
        `;
      }
  
      const response = await axios.post(APIURL, {
        query: query,
        variables: variables,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const TEJD = response.data.data.tenant_employee_job_descriptions.map(
        (structure, index) => ({
          ...structure,
          serialNo: index + 1,
        })
      );
      
  
      settejd(TEJD);
      console.log(TEJD);
    } catch (error) {
      console.error(error);
    }
  };
  

 
  function actionFormatter(cell, row) {
    return (
      <div>
        <Col
          className="font-icon-list col-xs-6 col-xs-6"
          lg="2"
          md="3"
          sm="4"
          onClick={() => handleEdit(row)}
        ></Col>
       <FontAwesomeIcon
          icon={faPencilAlt}
          className="btn btn-primary tim-icons icon-pencil"
          style={{ fontSize: "70%", background: "none",  marginRight: "-46px", pointerEvents: "none" }}
        />
        <Link to={`/admin/org-structure/${row.organization_structure_id}`}>
          <FontAwesomeIcon
            icon={faTrash}
            className="btn btn-primary tim-icons icon-pencil"
            style={{ fontSize: "70%", background: "none", pointerEvents: "none" }}
          />
        </Link>
      </div>
    );
  }

  function handleEdit(row) {
    // Logic for handling the edit action
    console.log("Editing row:", row);
  }

  const columns = [
    {
      dataField: "serialNo",
      text: "Tenant Employee JD ID",
    },
    {
      dataField: "tenant.tenant_client_name",
      text: "Tenant Name",
      headerStyle: { width: '500px' }
    },
    {
        dataField: "emp_name",
        text: "Employee",
        headerStyle: { width: '500px' }
    }, 
    {
      dataField: "organization.organization_node_name",
      text: "Organization",
      headerStyle: { width: '500px' }
    },
    {
      dataField: "unit.organization_node_name",
      text: "Plant/Unit",
      headerStyle: { width: '500px' }
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: '500px' }
    },
    {
        dataField: "jobrole.job_role",
        text: "Job Role",
    },
    {
      dataField: "jd_id",
      text: "Job Description",
      headerStyle: { width: '500px' }
    },
    {
        dataField: "reporting_manager_id",
        text: "Reporting Manager",
    }, 
    
    {
      dataField: "action",
      text: "Action",
      formatter: actionFormatter,
      headerStyle: { width: '500px' }
    },
  ];
  

  return (
    <div className="content">
      
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Row>
            <Col md="12">
              <Card>
                
                <CardHeader>
                  <CardTitle tag="h4" className="table-card-header">
                    Tenant Employee Job Description
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={tejd}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <div>
                        <div
                          className="float-right"
                          style={{
                            display: "flex",
                            marginBottom: "18px",
                            gap: "12px",
                          }}
                        >
                          <div className="float-left">
                            <SearchBar {...props.searchProps} />
                          </div>
                          <div className="float-right">
                            <button className="btn btn-secondary" onClick={handleOpen}>
                              Add new Employe Job Details
                            </button>
                          </div>
                        </div>
                        <BootstrapTable
                          {...props.baseProps}
                          filter={filterFactory()}
                          pagination={paginationFactory()}
                          
                          headerClasses="sorted-asc sorted-desc"
                          wrapperClasses="table-responsive"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId={2}>
          {/* Content for Tab 2 */}
        </TabPane>
        <TabPane tabId={3}>
          {/* Content for Tab 3 */}
        </TabPane>
      </TabContent>
      <Modal
        onClose={handleClose}
        open={open}
        style={{
            boxShadow: '2px solid black',
            width: '100%',
            top: '15%',
            left: '15%',
            
        }}
    >
          <div>
    <button
      onClick={handleClose}
      style={{
        position: 'fixed',
            top: '112px',
        right: '399px',
        background: 'transparent',
        border: 'none',
        color: 'white',
        fontSize: '40px',
        /* margin-top: -85px; */
        cursor: 'pointer',
        float: 'right',
        zIndex: 1,
      }}
    >
      &times; {/* This is the 'x' character for the close button */}
    </button>
        <TenantEmpJobDescriptionAdd onInsertSuccess={fetchData} />
        </div>
    </Modal>
    </div>
  );
}

export default JobrolemasterAll;
