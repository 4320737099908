import React, { useState, useEffect } from "react";
import { Card, Row, Col, CardHeader, CardBody } from "reactstrap";
import redirectToLoginIfTokenMissing from "views/TokenUtility.js";

function ActionReusable({ tableData, tableData1 }) {
  const [activeTab, setActiveTab] = useState("1");
  const storedTenantId = sessionStorage.getItem("selectedTenantId");
  useEffect(() => {
    redirectToLoginIfTokenMissing();
  }, []);

 

  const idColumnStyle = {
    border: "1px solid #dddddd",
    textAlign: "center",
    padding: "8px",
    backgroundColor: "blue",
    width: "70px",
    color: "white",
    fontWeight: "bold",
  };

  const tableCellStyle = {
    border: "1px solid #dddddd",
    textAlign: "left",
    padding: "8px",
    backgroundColor: "#27293d",
    height: "70px",
  };

  const nameColumnStyle = {
    border: "1px solid #dddddd",
    textAlign: "center",
    padding: "8px",
    width: "200px",
    backgroundColor: "#27293d",
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col
            lg="5"
            style={{
              textAlign: "center",
              border: "1px solid white",
              paddingTop: "10px",
              paddingBottom: "1px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4>What's Working</h4>
          </Col>
          <Col lg="2"></Col>
          <Col
            lg="5"
            style={{
              textAlign: "center",
              border: "1px solid white",
              paddingTop: "10px",
              paddingBottom: "1px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4>What's Not Working</h4>
          </Col>
        </Row>
        <Row>
          <Col lg="5" style={{ paddingTop: "40px" }}>
            <table style={{ borderCollapse: "collapse", width: "100%" }}>
              <tbody>
                {tableData.map((item) => (
                  <tr key={item.id}>
                    <td style={idColumnStyle}>{item.id}</td>
                    <td style={nameColumnStyle}>{item.name}</td>
                    <td style={tableCellStyle}>{item.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
          <Col lg="2"></Col>
          <Col lg="5" style={{ paddingTop: "40px" }}>
            <table style={{ borderCollapse: "collapse", width: "100%" }}>
              <tbody>
                {tableData1.map((item) => (
                  <tr key={item.id}>
                    <td style={idColumnStyle}>{item.id}</td>
                    <td style={nameColumnStyle}>{item.name}</td>
                    <td style={tableCellStyle}>{item.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ActionReusable;
