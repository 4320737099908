import React, { useState, useEffect } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import redirectToLoginIfTokenMissing from "views/TokenUtility";
import classNames from "classnames";
import LineChart from "assets/charts/LineChart";
import { QueryApi } from "QueryApi";

function PopUpAvailability({ closePopup }) {
  const [activeTab, setActiveTab] = useState("1");
  const storedTenantId = sessionStorage.getItem("selectedTenantId");
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const currDay = day < 10 ? `0${day}` : day;
  const currMonth = month < 10 ? `0${month}` : month;
  let currentDate = JSON.stringify(`${year}-${currMonth}-${currDay}`);

  const dateData = new Date();
  dateData.setDate(dateData.getDate() - 7);
  var dayDataValue = dateData.getDate();
  var monthDataValue = dateData.getMonth() + 1;
  var yearDataValue = dateData.getFullYear();
  var formattedDay = dayDataValue < 10 ? `0${dayDataValue}` : dayDataValue;
  var formattedMonth =
    monthDataValue < 10 ? `0${monthDataValue}` : monthDataValue;
  var weekDataDate = JSON.stringify(
    `${yearDataValue}-${formattedMonth}-${formattedDay}`
  );

  const previousDate = new Date();
  previousDate.setDate(1);
  const previousDay = previousDate.getDate();
  const previousMonth = previousDate.getMonth() + 1;
  const previousYear = previousDate.getFullYear();
  const formattedPreviousDay =
    previousDay < 10 ? `0${previousDay}` : previousDay;
  const formattedPreviousMonth =
    previousMonth < 10 ? `0${previousMonth}` : previousMonth;
  const previousMonthDate = JSON.stringify(
    `${previousYear}-${formattedPreviousMonth}-${formattedPreviousDay}`
  );

  const yearCurrentDate = new Date();
  yearCurrentDate.setDate(1);
  yearCurrentDate.getMonth(1);
  const yearDay = yearCurrentDate.getDate();
  const yearMonth = yearCurrentDate.getMonth();
  const yearYear = yearCurrentDate.getFullYear();
  const formattedYearDay = yearDay < 10 ? `0${yearDay}` : yearDay;
  const formattedYearMonth = yearMonth < 10 ? `0${yearMonth}` : yearMonth;
  const yearPreviousMonthDate = JSON.stringify(
    `${yearYear}-${formattedYearMonth}-${formattedYearDay}`
  );

  useEffect(() => {
    redirectToLoginIfTokenMissing();
  }, []);

  const [totalPartsProducedData, settotalPartsProducedData] = useState(0);
  useEffect(() => {
    totalPartsProduced();
  }, []);
  const totalPartsProduced = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                    total_parts_produced
                }
              }
            }
          }
       `);

      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.avg.total_parts_produced === null
      ) {
        settotalPartsProducedData("No Data");
      } else {
        settotalPartsProducedData(
          data.dm_daily_aggregate.aggregate.avg.total_parts_produced
        );
      }
    } catch (error) {
      settotalPartsProducedData("No Data");
    }
  };

  const [
    xAxisDataValueTotalPartsProduced,
    setXAxisDataValueTotalPartsProduced,
  ] = useState([]);
  const [plotPointTotalPartsProduced, setPlotPointTotalPartsProduced] =
    useState([]);
  const datasetTotalPartsProduced = [
    {
      label: "Availability %",
      data: plotPointTotalPartsProduced,
      borderColor: "#1F8EF1",
    },
  ];

  useEffect(() => {
    GraphDataListTotalPartsProduced();
  }, []);
  const GraphDataListTotalPartsProduced = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
                dm_foundation(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                total_parts_produced
                  timestamp
                }
              }
        `);
      const plotAllDataTotalPartsProduced = data.dm_foundation.map((data) => {
        const objData = {
          point: data.total_parts_produced,
          Xaxis: data.timestamp,
        };
        return objData;
      });

      const plotPointDataTotalPartsProduced = plotAllDataTotalPartsProduced.map(
        (data) => data.point
      );
      setPlotPointTotalPartsProduced(plotPointDataTotalPartsProduced);

      const xAxisPointDataTotalPartsProduced =
        plotAllDataTotalPartsProduced.map((data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
          var m = "0" + d.getMinutes();
          var ampm = h >= 12 ? "PM" : "AM";
          h = h % 12;
          h = h ? h : 12;
          var fullTime = h + ":" + m.substr(-2) + " " + ampm;
          return fullTime;
        });
      setXAxisDataValueTotalPartsProduced(xAxisPointDataTotalPartsProduced);
    } catch (error) {
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="8">
            <Card
              className="card-chart"
              style={{ height: "25rem", width: "35rem" }}
            >
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5 className="card-category">Availability %</h5>
                  </Col>
                  <Col sm="4">
                    <ButtonGroup
                      style={{ marginTop: "1rem" }}
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <Button
                        tag="label"
                        className={classNames("btn-simple", {
                          // active: DowntimebigChartData === "data1",
                        })}
                        color="info"
                        id="0"
                        size="sm"
                        // onClick={() => setDowntimebigChartData("data1")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Day
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-single-02" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          // active: DowntimebigChartData === "data2",
                        })}
                        // onClick={() => setDowntimebigChartData("data2")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Week
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-gift-2" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          // active: DowntimebigChartData === "data3",
                        })}
                        // onClick={() => setDowntimebigChartData("data3")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Month
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-tap-02" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          // active: DowntimebigChartData === "data4",
                        })}
                        // onClick={() => setDowntimebigChartData("data4")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Year
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-tap-02" />
                        </span>
                      </Button>
                    </ButtonGroup>
                  </Col>
                  <Col sm="2">
                    <button
                      style={{
                        border: "none",
                        zIndex: 1,
                        marginLeft: "7rem",
                        backgroundColor: "transparent",
                        color: "white",
                        fontSize: "40px",
                      }}
                      onClick={() => closePopup(false)}
                    >
                      &times;
                    </button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <LineChart
                  xAxisData={xAxisDataValueTotalPartsProduced}
                  dataset={datasetTotalPartsProduced}
                  height="100%"
                  width="100%"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default PopUpAvailability;
