import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "./Apiurl.js";
import redirectToLoginIfTokenMissing from "./TokenUtility";
import { CSVLink } from "react-csv";
import {
  TabContent,
  TabPane,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import "./datatable.css";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import Modal from "@material-ui/core/Modal";
import ConfigImport from "./ConfigImport";
import PartandCustomerAdd from "./PartandCustomerAdd.js";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToolAndDieAdd from "./ToolAndDieAdd";
import { Link } from "react-router-dom";
import ToolAndDieEdit from "./ToolAndDieEdit.js";
// import CSVImporter from "./CSVImport.js";
import CSVImporter from "./CSVImport.js";

const { SearchBar } = Search;

function ToolAndDie() {
  const [dataToExport, setDataToExport] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [struct, setstruct] = useState([]);
  const [orgstruct, setorgstruct] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

const handleOpenEditModal = (row) => {
  setSelectedRow(row);
  setOpenEditModal(true);
};
const handleCloseEditModal = (row) => {
  // setSelectedRow(row);
  setOpenEditModal(false);
};

  const token = sessionStorage.getItem("token");
  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const tenantId =
    decodedToken["https://hasura.io/jwt/claims"]["x-hasura-tenant-id"];
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleOpen1 = () => {
    setOpen1(true);
  };
  useEffect(() => {
    fetchData();
    redirectToLoginIfTokenMissing(); // Call the utility function to validate the token
  }, []);

  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const roleId = decodedToken["sub"];
      const logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
      const storedTenantId = sessionStorage.getItem("selectedTenantId");

      let query = ""; // Define the query variable outside the if-else block
      let variables = {}; // Define the variables object

      if (roleId == 1) {
        if (storedTenantId !== null) {
          query = `
        query MyQuery($TenantId: Int!) {
          tnt_tool_and_die_master(where: { tenantid: { _eq: $TenantId } },order_by: {toolordie_id: desc}) {
            actual_part_id
            die_number
            op_name
            op_no
            part_name
            part_no
            tag_number
            tenantid
            tool_location_no
            toolordie_id
          }
        }
      `;

          variables = {
            TenantId: storedTenantId,
          };
        } else {
          query = `
        query MyQuery {
          tnt_tool_and_die_master(order_by: {toolordie_id: desc}) {
            actual_part_id
            die_number
            op_name
            op_no
            part_name
            part_no
            tag_number
            tenantid
            tool_location_no
            toolordie_id
          }
        } 
      `;
        }
      } else {
        query = ` 
      query MyQuery {
      tnt_tool_and_die_master(order_by: {toolordie_id: desc}) {
        actual_part_id
        die_number
        op_name
        op_no
        part_name
        part_no
        tag_number
        tenantid
        tool_location_no
        toolordie_id
      } }
      `;
      }

      const response = await axios.post(
        APIURL,
        {
          query: query,
          variables: variables,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const tnt_tool_and_die_master =
        response.data.data.tnt_tool_and_die_master.map((structure) => ({
          ...structure,
          status: "On",
        }));
      setorgstruct(tnt_tool_and_die_master);
      setDataToExport(tnt_tool_and_die_master);
    } catch (error) {
      console.error(error);
    }
  };

  /////////////////////////////////////////////////////////////////
  const columns = [
    {
      dataField: "toolordie_id",
      text: "Tool or Die ID",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "tag_number",
      text: "Tag Number",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "tool_location_no",
      text: "Tool Location No",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "op_no",
      text: "Op No",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "op_name",
      text: "Op Name",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "part_no",
      text: "Part No",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "part_name",
      text: "Part Name",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "die_number",
      text: "Die Number",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "actual_part_id",
      text: "Actual Part ID",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "action",
      text: "Action",
      headerStyle: { width: "500px" },
      formatter: actionFormatter,
    },
  ];

  function actionFormatter(cell, row) {
    return (
      <div>
        <Col
          className="font-icon-list col-xs-6 col-xs-6"
          lg="2"
          md="3"
          sm="4"
        ></Col>
        <FontAwesomeIcon
          icon={faPencilAlt}
          className="btn btn-primary tim-icons icon-pencil"
          style={{
            fontSize: "70%",
            background: "none",
            marginRight: "-46px",
            // pointerEvents: "none",
            cursor: "pointer"
          }}
          onClick={() => handleOpenEditModal(row)}
        />
      </div>
    );
  }
  // function actionFormatter(cell, row) {
  //   return (
  //     <div>
  //       <Col
  //         className="font-icon-list col-xs-6 col-xs-6"
  //         lg="2"
  //         md="3"
  //         sm="4"
  //       ></Col>
  //       <Link to={`/admin/part-and-custo/${row.tnt_part}`}>
  //         <FontAwesomeIcon
  //           icon={faPencilAlt}
  //           className="btn btn-primary tim-icons icon-pencil"
  //           style={{
  //             fontSize: "70%",
  //             background: "none",
  //             marginRight: "-46px",
  //             pointerEvents: "none",
  //           }}
  //         />
  //       </Link>
  //     </div>
  //   );
  // }
  return (
    <div className="content">
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4" className="table-card-header">
                    MANAGE TOOL AND DIE
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={struct}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <div>
                        <div
                          className="float-right"
                          style={{
                            display: "flex",
                            marginBottom: "18px",
                            gap: "12px",
                          }}
                        >
                          <div className="float-left">
                            <SearchBar {...props.searchProps} />
                          </div>
                          <div className="float-right">
                            <button
                              className="btn btn-secondary"
                              onClick={handleOpen}
                            >
                              Create Tool and Die
                            </button>
                            <button
                              className="btn btn-secondary"
                              onClick={handleOpen1}
                            >
                              Import CSV file
                            </button>
                            <CSVLink
                              data={dataToExport}
                              filename="tool_and_die_data.csv" // Customize the filename
                              className="btn btn-primary"
                            >
                              Export to CSV
                            </CSVLink>
                          </div>
                        </div>
                        <div className="data-table-plan-admin">
                          <BootstrapTable
                            className="table-part-customer"
                            {...props.baseProps}
                            keyField="id"
                            data={orgstruct}
                            columns={columns}
                            filter={filterFactory()}
                            pagination={paginationFactory({
                              bootstrap4: true,
                            })}
                            headerClasses="sorted-asc sorted-desc"
                            wrapperClasses="table-responsive"
                          />
                        </div>
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId={2}>{/* Content for Tab 2 */}</TabPane>
        <TabPane tabId={3}>{/* Content for Tab 3 */}</TabPane>
      </TabContent>
      <Modal
        onClose={handleClose}
        open={open}
        style={{
          boxShadow: "2px solid black",
          width: "100%",
          top: "15%",
          left: "15%",
        }}
      >
        <div>
         
          <ToolAndDieAdd handleClose={handleClose} fetchData={fetchData} />
        </div>
      </Modal>
      <Modal
        onClose={handleClose1}
        open={open1}
        style={{
          boxShadow: "2px solid black",
          width: "100%",
          top: "15%",
          left: "25%",
          right: "20%",
        }}
      >
        <div>
        <CSVImporter handleClose1={handleClose1}/>
        </div>
      </Modal>
      <Modal
          onClose={() => setOpenEditModal(false)}
          open={openEditModal}
          style={{
            boxShadow: "2px solid black",
            width: "70%",
            top: "15%",
            left: "15%",
            
          }}
        >
            <ToolAndDieEdit selectedRow={selectedRow} handleCloseEditModal={handleCloseEditModal} fetchData={fetchData}/>
          
        </Modal>
            </div>
  );
}
export default ToolAndDie;
