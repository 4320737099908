import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from "reactstrap";
import axios from "axios";
import { APIURL } from "./Apiurl.js";
import { toast } from "react-toastify";

function JobrolemasterAdd() {
  const [activeStep, setActiveStep] = useState(1);
  const [progress, setProgress] = useState(0);

  const [org, setorg] = useState("");
  const [orgnames, setOrgnames] = useState([]);

  const [unit, setunit] = useState("");
  const [units, setunits] = useState([]);

  const [dept, setdept] = useState("");
  const [depts, setdepts] = useState([]);

  const [machine, setmachine] = useState("");
  const [machineid, setmachineid] = useState("");

  const [machinetype, setmachinetype] = useState("");
  const [machines, setmachines] = useState([]);

  const [machinesubtype, setmachinesubtype] = useState("");
  const [subtypes, setsubtypes] = useState("");

  const [edgeid, setedgeid] = useState("");
  const [edges, setedges] = useState([]);

  const [selectedMachineType, setSelectedMachineType] = useState("");
  const [selectedMachineSubType, setSelectedMachineSubType] = useState("");

  const [orgs, setorgs] = useState([]);
  const [plants, setplants] = useState([]);

  const [organization_code, setOrganizationCode] = useState("");
  const [country_id, setCountryId] = useState("");
  const [city_id, setCityId] = useState("");
  const [parent_organization_id, setParentOrganizationId] = useState("");
  const [cost_centre_code, setCostCentreCode] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [types, settypes] = useState([]);
  const [filteredSubtypes, setFilteredSubtypes] = useState([]);
  const [selectedOrgData, setSelectedOrgData] = useState(null);
  const [otherData, setOtherData] = useState([]);

  useEffect(() => {
    fetchorg();
    fetchplant();

    fetchmachinetype();
    fetchdept();
    fetchedge();

    fetchsubmachinetype();
  }, [machinetype]);

  useEffect(() => {
    fetchplant();
  }, [org]);
  const token = sessionStorage.getItem("token");
  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const roleId = decodedToken["role_id"];
  let logintenantId = null;
  if (roleId === 1) {
    logintenantId = sessionStorage.getItem("selectedTenantId");
  } else {
    logintenantId =
      decodedToken["https://hasura.io/jwt/claims"]["x-hasura-tenant-id"];
  }


  const fetchorg = async (logintenantId) => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
          query MyQuery($logintenantId: Int!) {
            tnt_organization_structure(
              where: {
                parent_organization_id: { _eq: 0 },
                tenantid: { _eq: $logintenantId }
              }
            ) {
              organization_structure_id
              organization_node_name
            }
          }
        `,
          variables: {
            logintenantId: parseInt(logintenantId),
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      const fetchedOrgs = response.data.data.tnt_organization_structure;
      setorgs(fetchedOrgs);
      console.log(fetchedOrgs);
      const parentIds = fetchedOrgs.map((org) => org.organization_structure_id);
      console.log(parentIds);

      const childResponse = await axios.post(
        APIURL,
        {
          query: `
          query MyQuery($parentIds: [Int!]) {
            tnt_organization_structure(
              where: {
                parent_organization_id: { _in: $parentIds }
              }
            ) {
              organization_structure_id
              organization_node_name
            }
          }
        `,
          variables: {
            parentIds: parentIds,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      const childOrgs = childResponse.data.data.tnt_organization_structure;
      console.log("hsdfasd");
      console.log(childOrgs);
      const updatedOtherData = otherData.map((row) => {
        const matchingOrg = childOrgs.find(
          (org) => org.organization_structure_id === row.parent_organization_id
        );

        if (matchingOrg) {
          return {
            ...row,
            parent_organization_id: matchingOrg.organization_structure_id,
          };
        }

        return row;
      });

      setOtherData(childOrgs);
      console.log(otherData);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    console.log("token in machine add===>",token)
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    console.log("decoded token====>",decodedToken)
 
    

    if (roleId === 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-tenant-id"];
    }

    fetchorg(logintenantId);
  }, []);
  const fetchplant = async () => {
    if (org) {
      // Only fetch if org is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
            query MyQuery($p: Int!) {
              tnt_organization_structure(where: {parent_organization_id: {_eq: $p}}) {
                organization_structure_id
                organization_node_name
              }
            }
          `,
            variables: {
              p: org,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        // console.log(org);
        setplants(response.data.data.tnt_organization_structure);
        console.log(response.data.data.tnt_organization_structure);
      } catch (error) {
        console.error(error);
      }
    }
  };
  useEffect(() => {
    fetchdept();
  }, [unit]);
  const fetchdept = async () => {
    if (unit) {
      // Only fetch if org is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
            query MyQuery($d: Int!) {
              tnt_organization_structure(where: {parent_organization_id: {_eq: $d}}) {
                organization_structure_id
                organization_node_name
              }
            }
          `,
            variables: {
              d: unit,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        // console.log(org);
        setdepts(response.data.data.tnt_organization_structure);
        console.log(response.data.data.tnt_organization_structure);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const fetchmachinetype = async () => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery {
          ump_master(where: { type: { _neq: "Subtype" } }) {
            machine_profile
            type
            ump_id
          }
        }        
        `,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      setmachines(response.data.data.ump_master);
      // console.log(response.data.data.ump_master);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchsubmachinetype = async () => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery($a: String!) {
          ump_master(where: { parent_id: { _eq: $a } }) {
            machine_profile
            type
            ump_id
          }
        }
        `,
          variables: {
            a: machinetype, // Use the selected machine type here
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      setFilteredSubtypes(response.data.data.ump_master);
      console.log(response.data.data.ump_master);
    } catch (error) {
      console.error(error);
    }
  };
  const handleOrgSelect = async (selectedOrgId) => {
    if (selectedOrgId) {
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
            query GetCostCenter($orgId: Int!) {
              tnt_organization_structure(where: { organization_structure_id: { _eq: $orgId } }) {
                cost_centre_code
              }
            }
          `,
            variables: {
              orgId: selectedOrgId,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );

        if (
          response.data &&
          response.data.data &&
          response.data.data.tnt_organization_structure &&
          response.data.data.tnt_organization_structure.length > 0
        ) {
          setSelectedOrgData(response.data.data.tnt_organization_structure[0]);
        } else {
          console.log(response);
          setSelectedOrgData(null);
        }
      } catch (error) {
        console.error("Error fetching organization data:", error);
      }
    } else {
      setSelectedOrgData(null);
    }

    setorg(selectedOrgId);
  };

  const fetchedge = async () => {
    try {
      // Fetch assigned edge_ids from the machines table
      const assignedEdgeIdsResponse = await axios.post(
        APIURL,
        {
          query: `
          query MyQuery {
            tnt_org_machine_assignment {
              edgeid
            }
          }
        `,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      const assignedEdgeIds =
        assignedEdgeIdsResponse.data.data.tnt_org_machine_assignment.map(
          (machine) => machine.edgeid
        );
      console.log("assignedEdgeIds:", assignedEdgeIds);

      console.log("excludedAssignmentIds:", assignedEdgeIds);

      // Fetch edge_master records excluding assigned edge_ids
      const response = await axios.post(
        APIURL,
        {
          query: `
          query MyQuery($assignedEdgeIds: [Int!]) {
            edge_master(where: { tenantid: { _eq: "UNASSIGNED" }, assignment_id: { _nin: $assignedEdgeIds } }) {
              assignment_id
              edgeid
            }
          }
        `,
          variables: {
            assignedEdgeIds: assignedEdgeIds,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      setedges(response.data.data.edge_master);
      console.log("Edge Master Data:", response.data.data.edge_master);
    } catch (error) {
      console.error(error);
    }
  };

  console.log(selectedOrgData);

  console.log(machinetype);
  console.log(unit);

  console.log(filteredSubtypes);
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const token = sessionStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["sub"];
    console.log(roleId);

    try {
      let logintenantId = null;
      if (roleId === 1) {
        logintenantId = sessionStorage.getItem("selectedTenantId");
      } else {
        logintenantId =
          decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
      }

      // Create an object with the form data for the machine insertion
      const machineFormData = {
        tenantid: logintenantId,
        org_id: org,
        unit: unit,
        machineid: machineid,
        department: dept,
        machine_name: machine,
        machine_type: machinetype,
        machine_sub_type: machinesubtype,
        edgeid: edgeid,
        is_active: "1",
      };

      const response = await axios.post(
        APIURL,
        {
          query: `
        mutation InsertMachines($formData: tnt_org_machine_assignment_insert_input!) {
          insert_tnt_org_machine_assignment(objects: [$formData]) {
            affected_rows
            returning {
              machine_auto_id
              machine_type
              machine_sub_type
              edgeid
            }
          }
        }
      `,
          variables: {
            formData: machineFormData,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      if (
        response.data.data &&
        response.data.data.insert_tnt_org_machine_assignment.affected_rows > 0
      ) {
        const insertedMachine =
          response.data.data.insert_tnt_org_machine_assignment.returning[0];
        console.log(
          `Machine inserted with ID: ${insertedMachine.machine_auto_id}`
        );
        console.log(`Machine Type: ${insertedMachine.machine_type}`);
        console.log(`Machine Sub Type: ${insertedMachine.machine_sub_type}`);

        const machineEdgeMappingFormData = {
          tenantid: logintenantId,
          machineid: insertedMachine.machine_auto_id,
          edgeid: insertedMachine.edgeid,
          is_active: "1",
        };

        // Insert data into machine_edge_mappings table
        const machineEdgeMappingResponse = await axios.post(APIURL, {
          query: `
          mutation InsertMachineEdgeMappings($formData: machine_edge_mappings_insert_input!) {
            insert_machine_edge_mappings(objects: [$formData]) {
              affected_rows
            }
          }
        `,
          variables: {
            formData: machineEdgeMappingFormData,
          },
        });

        if (
          machineEdgeMappingResponse.data.data.insert_machine_edge_mappings
            .affected_rows > 0
        ) {
          // Data inserted successfully into machine_edge_mappings table
          const insertedEdgeMapping =
            machineEdgeMappingResponse.data.data.insert_machine_edge_mappings;
          console.log(
            `Machine Edge Mapping inserted with ID: ${insertedEdgeMapping.machine_auto_id}`
          );
        } else {
          toast.error("Failed to insert data into machine_edge_mapping table.");
        }
        // Fetch data for machine attributes from your query
        const attributeResponse = await axios.post(
          APIURL,
          {
            query: `
          query MyQuery {
            config_machine_attribute(where: { is_default: { _eq: 1 } }) {
              config_machine_attribute_id
              machine_attribute_name
              attribute_category
              attribute_type
              unit_of_measure
              direction
              min_value
              max_value
            }
          }
        `,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );

        const attributes = attributeResponse.data.data.config_machine_attribute;

        // Create an array of attributes with necessary fields
        const attributeData = attributes.map((attribute) => ({
          tenantid: logintenantId,
          machineid: insertedMachine.machine_auto_id,
          machine_type: insertedMachine.machine_type,
          machine_sub_type: insertedMachine.machine_sub_type,
          edgeid: insertedMachine.edgeid,
          machine_attribute_name: attribute.machine_attribute_name,
          attribute_category: attribute.attribute_category,
          attribute_type: attribute.attribute_type,
          unit_of_measure: attribute.unit_of_measure,
          direction: attribute.direction,
          attribute_id: attribute.config_machine_attribute_id,
          min_value: attribute.min_value.toString(),
          max_value: attribute.max_value,
          machine_type_name: selectedMachineType,
          machine_sub_type_name: selectedMachineSubType,
        }));
        console.log("attributeData:", attributeData);
        // Insert data into insert_tnt_machine_attributes_master table
        const attributeInsertResponse = await axios.post(
          APIURL,
          {
            query: `
          mutation InsertAttributeMaster($attributes: [tnt_machine_attributes_master_insert_input!]!) {
            insert_tnt_machine_attributes_master(objects: $attributes) {
              affected_rows
            }
          }
        `,
            variables: {
              attributes: attributeData,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        console.log("attributeInsertResponse:", attributeInsertResponse.data);
        if (
          attributeInsertResponse.data.data.insert_tnt_machine_attributes_master
            .affected_rows > 0
        ) {
          setorg("");
          setunit("");
          setdept("");
          setmachine("");
          setmachineid("");
          setParentOrganizationId("");
          setCostCentreCode("");
          setCreatedBy("");
          setProgress(0);
          setActiveStep(1);
          toast.success("Machine inserted successfully!");
        } else {
          toast.error("Failed to insert attribute data.");
        }
      } else {
        toast.error("Failed to insert machine data.");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while inserting data.");
    }
  };

  return (
    <div className="content">
      <Row className="progress-row">
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Machine</h5>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col className="pr-md-1" md="4">
                    <FormGroup>
                      <label>Organization</label>
                      <Input
                        type="select"
                        value={org}
                        onChange={(e) => handleOrgSelect(e.target.value)}
                      >
                        <option value="">Select Organization</option>
                        {otherData.map((org) => (
                          <option
                            key={org.organization_structure_id}
                            value={org.organization_structure_id}
                          >
                            {org.organization_node_name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1" md="4">
                    <FormGroup>
                      <label>Plant/Unit</label>
                      <Input
                        type="select"
                        value={unit}
                        onChange={(e) => setunit(e.target.value)}
                      >
                        <option value="">Select Unit/Plant</option>
                        {plants.map((plant) => (
                          <option
                            key={plant.organization_structure_id}
                            value={plant.organization_structure_id}
                          >
                            {plant.organization_node_name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col className="px-md-1" md="4">
                    <FormGroup>
                      <Label for="Department">Department</Label>
                      <Input
                        type="select"
                        value={dept}
                        onChange={(e) => setdept(e.target.value)}
                      >
                        <option value="">Select department</option>
                        {depts.map((dpt) => (
                          <option
                            key={dpt.organization_structure_id}
                            value={dpt.organization_structure_id}
                          >
                            {dpt.organization_node_name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label>Cost Center Code</label>
                      <Input
                        type="text"
                        value={
                          selectedOrgData
                            ? selectedOrgData.cost_centre_code
                            : ""
                        }
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                  <Col className="px-md-1" md="6">
                    <FormGroup>
                      <Label for="Department">Machine Name</Label>
                      <Input
                        placeholder="Enter Machine Name"
                        type="text"
                        value={machine}
                        onChange={(e) => setmachine(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="px-md-1" md="6">
                    <FormGroup>
                      <Label for="machineType">Machine Type</Label>
                      <Input
                        type="select"
                        id="machineType"
                        value={machinetype}
                        onChange={(e) => {
                          setmachinetype(e.target.value);
                          setSelectedMachineType(
                            e.target.options[e.target.selectedIndex].text
                          );
                        }}
                      >
                        <option value="">Select Machine Type</option>
                        {machines.map((mach) => (
                          <option key={mach.ump_id} value={mach.ump_id}>
                            {mach.machine_profile}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col className="px-md-1" md="6">
                    <FormGroup>
                      <Label for="machineSubType">Machine Sub Type</Label>
                      <Input
                        type="select"
                        id="machineSubType"
                        value={machinesubtype}
                        onChange={(e) => {
                          setmachinesubtype(e.target.value);
                          setSelectedMachineSubType(
                            e.target.options[e.target.selectedIndex].text
                          );
                        }}
                      >
                        <option value="">Select Sub-type</option>
                        {filteredSubtypes.map((subtype) => (
                          <option key={subtype.ump_id} value={subtype.ump_id}>
                            {subtype.machine_profile}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="px-md-1" md="6">
                    <FormGroup>
                      <Label for="orgtype">Machine ID</Label>
                      <Input
                        type="text"
                        id="machineid"
                        value={machineid}
                        onChange={(e) => setmachineid(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="px-md-1" md="6">
                    <FormGroup>
                      <Label for="machineSubType">Edge Id</Label>
                      <Input
                        type="select"
                        id="machineSubType"
                        value={edgeid}
                        onChange={(e) => setedgeid(e.target.value)}
                      >
                        <option value="">Select Edge ID</option>
                        {edges.map((e) => (
                          <option key={e.assignment_id} value={e.assignment_id}>
                            {e.edgeid}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
            <CardFooter>
              <div className="text-right">
                <Button color="success" onClick={handleFormSubmit}>
                  Submit
                </Button>
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default JobrolemasterAdd;
