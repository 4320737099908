import React, { useState, useEffect } from "react";
import { ProgressBar } from "react-bootstrap";
import redirectToLoginIfTokenMissing from "views/TokenUtility";
import { TabContent, TabPane, Card, CardBody, Row, Col } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { Badge } from "reactstrap";
import { QueryApi } from "QueryApi.js";

function OeeScoreMachineWise() {
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const currDay = day < 10 ? `0${day}` : day;
  const currMonth = month < 10 ? `0${month}` : month;
  let currentDate = JSON.stringify(`${year}-${currMonth}-${currDay}`);

  const [activeTab, setActiveTab] = useState(1);
  const [employees, setEmployees] = useState([]);
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    redirectToLoginIfTokenMissing();
  }, []);

  function handleEdit(row) {}
  const activeFormatter = (cell, row) => {
    const isActive = row.is_active === "1";

    return (
      <Badge color={isActive ? "info" : "danger"}>
        {isActive ? "Active" : "Inactive"}
      </Badge>
    );
  };
  const columns = [
    {
      dataField: "",
      text: "Sr.No",
      headerStyle: { border: "1px solid #ddd" },
      formatter: (cell, row, rowIndex) => rowIndex + 1,
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px", border: "1px solid #ddd" },
    },
    {
      dataField: "Plant.organization_node_name",
      text: "Plant/Unit",
      headerStyle: { width: "500px", border: "1px solid #ddd" },
    },
    {
      dataField: "machineid",
      text: "Machine",
      headerStyle: { width: "500px", border: "1px solid #ddd" },
    },
    {
      dataField: "ideal_oee",
      text: "Idle OEE %",
      headerStyle: { width: "500px", border: "1px solid #ddd" },
    },
    {
      dataField: "oee",
      text: "Actual OEE %",
      headerStyle: { width: "500px", border: "1px solid #ddd" },
      formatter: (cell) => {
        return cell !== null ? `${cell.toFixed(3)} %` : "Null";
      },
    },
    {
      dataField: "oee_deviation",
      text: "Deviation %",
      headerStyle: { width: "500px", border: "1px solid #ddd" },
      formatter: (cell) => {
        return cell !== null ? `${cell.toFixed(3)} %` : "Null";
      },
    },
    {
      dataField: "oee",
      text: "Classification",
      headerStyle: { width: "500px", border: "1px solid #ddd" },
      style: { verticalAlign: 'middle' },
      formatter: (cell, row, rowIndex, colIndex) => {
        let color;
        let fontColor;
        if (cell >= 0 && cell < 30) {
          color = 'red';
          fontColor = 'red'; // choose the font color based on your preference
        } else if (cell >= 30 && cell < 60) {
          color = 'yellow';
          fontColor = 'yellow'; // choose the font color based on your preference
        } else if (cell >= 60 && cell <= 100) {
          color = 'green';
          fontColor = 'green'; // choose the font color based on your preference
        }
    
        return (
          <div style={{ 
            backgroundColor: color, 
            color: fontColor,
            height: '80%', 
            width: '100%', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center' 
          }}>
            {cell}
          </div>
        );
      },
    },

    // {
    //   dataField: "oee",
    //   text: "Classification",
    //   headerStyle: { width: "500px", border: "1px solid #ddd" },
    //   style: { verticalAlign: 'middle' },
    //   formatter: (cell, row, rowIndex, colIndex) => {
    //     let color;
    //     if (cell >= 0 && cell < 30) {
    //       color = 'red';
    //     } else if (cell >= 30 && cell < 60) {
    //       color = 'yellow';
    //     } else if (cell >= 60 && cell <= 100) {
    //       color = 'green';
    //     }
    
    //     return (
    //       <div style={{ 
    //         backgroundColor: color, 
    //         height: '80%', 
    //         width: '100%', 
    //         display: 'flex', 
    //         alignItems: 'center', 
    //         justifyContent: 'center' 
    //       }}>
    //         {cell}
    //       </div>
    //     );
    //   },
    // },
    
    // {
    //   dataField: "oee",
    //   text: "Classification",
    //   headerStyle: { width: "500px", border: "1px solid #ddd" },
    //   style: { verticalAlign: 'middle' },
    //   formatter: (cell, row, rowIndex, colIndex) => {
    //     let color;
    //     if (cell >= 0 && cell < 30) {
    //       color = 'red';
    //     } else if (cell >= 30 && cell < 60) {
    //       color = 'yellow';
    //     } else if (cell >= 60 && cell <= 100) {
    //       color = 'green';
    //     }
    
    //     return (
    //       <div style={{ 
    //         backgroundColor: color, 
    //         height: '80%', 
    //         width: '100%', 
    //         display: 'flex', 
    //         alignItems: 'center', 
    //         justifyContent: 'center' 
    //       }}>
    //         {/* {cell} */}
    //       </div>
    //     );
    //   },
    // },
   
  ];

  const [apiData, setApiData] = useState([]);

  useEffect(() => {
    fetchDataList();
  }, []);

  const fetchDataList = async () => {
    try {
      const data = await QueryApi(`
    query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
      fl2_oee(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
        org_id
        unit_id
        machineid
        ideal_oee
        oee
        oee_deviation
        org {
          organization_node_name
        }
        Plant {
          organization_node_name
        }
      }
    } 
    `);
      setApiData(data?.fl2_oee);
    } catch (error) {
      setApiData([]);
    }
  };

  return (
    <div className="content">
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={employees}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <div>
                        <BootstrapTable
                          {...props.baseProps}
                          filter={filterFactory()}
                          pagination={paginationFactory()}
                          data={apiData || []}
                          headerClasses="sorted-asc sorted-desc"
                          wrapperClasses="table-responsive"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default OeeScoreMachineWise;
