import React from "react";
import { useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Progress,
} from "reactstrap";
import axios from "axios";
import  { APIURL } from './Apiurl.js';

function ConfigCodeMaster() {
  const [activeStep, setActiveStep] = useState(1);
  const [checked, setChecked] = useState(false);
  const [progress, setProgress] = useState(0);

  const [tenantid, settenantid] = useState("");
  const [pid, setpid] = useState("");
  const [widgetid, setwidgetid] = useState("");
  const [pname, setpname] = useState("");
  const [pcategory, setpcategory] = useState("");
  const [ptype, setptype] = useState("");
  const [machinetype, setmachinetype] = useState("");
  const [parentparameterid, setparentparameterid] = useState("");
  const [pdrivercategory, setpdrivercategory] = useState("");
  const [interlink1, setinterlink1] = useState("");
  const [interlink2, setinterlink2] = useState("");
  const [actioncat, setactioncat] = useState("");
  const [outputdriver, setoutputdriver] = useState("");
  const [unitofmeasure, setunitofmeasure] = useState("");
  const [direction, setdirection] = useState("");
  const [visualization, setvisualization] = useState("");
  

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleNext = () => {
    if (activeStep === 1 && progress === 0) {
      setProgress(50);
    } else if (activeStep === 2 && progress === 50) {
      setProgress(100);
    }
    setActiveStep(activeStep + 1);
  };

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    // Create an object with the form data
    const formData = {
        tenantid: tenantid,
        pid: pid,
        widgetid: widgetid,
        pname: pname,
        pcategory: pcategory,
        ptype: ptype,
        machinetype: machinetype,
        parentparameterid: parentparameterid,
        pdrivercategory: pdrivercategory,
        interlink1: interlink1,
        interlink2: interlink2,
        actioncat: actioncat,
        outputdriver: outputdriver,
        unitofmeasure: unitofmeasure,
        direction: direction,
        visualization: visualization,


   
    };
    console.log(formData);    
    // Make a POST request to the Laravel backend
    axios
      .post(`${APIURL}/api/udp-assignment/add`, formData)
      .then((response) => {
        console.log(response.data); // Handle the response data as needed
        // Reset the form or show a success message
        if (response.status === 201) {
          // Redirect to admin/machine-all
          window.location.href = "/admin/udp-assignment/all";
        } else {
          // Reset the form or show a success message
        }
      })
      .catch((error) => {
        console.error(error); // Handle the error
        // Show an error message
      });
  };
  const renderStepContent = () => {
    switch (activeStep) {
      case 1:
        return (
          <>
        <Row>
                    
                    <Col className="px-md-1" md="6">
                      <FormGroup>
                        <label>Tenant ID</label>
                        <Input
                          placeholder="Tenant ID"
                          type="text"
                          value={tenantid}
                          onChange={(e) => settenantid(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                        Parameter ID
                        </label>
                        <Input placeholder="Parameter ID" type="text" 
                        value={pid}
                        onChange={(e) => setpid(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Widget ID</label>
                        <Input
                          placeholder="Widget ID"
                          type="text"
                          value={widgetid}
                          onChange={(e) => setwidgetid(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Parameter Name</label>
                        <Input
                          
                          placeholder="Parameter Name"
                          type="text"
                          value={pname}
                          onChange={(e) => setpname(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  
                  <Row>
                  <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Parameter Category</label>
                        <Input
                          placeholder="Parameter Category"
                          type="text"
                          value={pcategory}
                          onChange={(e) => setpcategory(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Parameter Type</label>
                        <Input
                          
                          placeholder="Parameter Type"
                          type="text"
                          value={ptype}
                          onChange={(e) => setptype(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
          </>
        );
      case 2:
        return (
          <>
       <Row>
                  <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Machine Type</label>
                        <Input
                          placeholder="Machine Type"
                          type="text"
                          value={machinetype}
                          onChange={(e) => setmachinetype(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Parent Parameter ID</label>
                        <Input
                          
                          placeholder="Parent Parameter ID"
                          type="text"
                          value={parentparameterid}
                          onChange={(e) => setparentparameterid(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Primary Driver Category</label>
                        <Input
                          placeholder="Primary Driver Category"
                          type="text"
                          value={pdrivercategory}
                          onChange={(e) => setpdrivercategory(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Interlink 1</label>
                        <Input
                          
                          placeholder="Interlink 1"
                          type="text"
                          value={interlink1}
                          onChange={(e) => setinterlink1(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                  <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Interlink 2</label>
                        <Input
                          placeholder="Interlink 2"
                          type="text"
                          value={interlink2}
                          onChange={(e) => setinterlink2(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Actionability catergory</label>
                        <Input
                          placeholder="Actionability catergory"
                          type="text"
                          value={actioncat}
                          onChange={(e) => setactioncat(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
          </>
        );
      case 3:
        return (
          <>
           <Row>
                  <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Output Driver</label>
                        <Input
                          placeholder="Output Driver"
                          type="text"
                          value={outputdriver}
                          onChange={(e) => setoutputdriver(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Unit of Measure</label>
                        <Input
                          placeholder="Unit of Measure"
                          type="text"
                          value={unitofmeasure}
                          onChange={(e) => setunitofmeasure(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                  <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Direction</label>
                        <Input
                          placeholder="Direction"
                          type="text"
                          value={direction}
                          onChange={(e) => setdirection(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Visualization</label>
                        <Input
                          placeholder="Visualization"
                          type="text"
                          value={visualization}
                          onChange={(e) => setvisualization(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
          </>
        );
      default:
        return null;
    }
  };
  const calculateProgress = () => {
    return Math.round(((activeStep - 1) / 2) * 100); // Update the denominator to match the total number of steps minus one
  };
  

  const renderProgressBarDestinations = () => {
    const step1Class = activeStep >= 1 ? "progress-destination-active" : "";
    const step2Class = activeStep >= 2 ? "progress-destination-active" : "";
    const step3Class = activeStep >= 3 ? "progress-destination-active" : "";
    const step4Class = activeStep >= 4 ? "progress-destination-active" : "";
  
    return (
      <div className="progress-destinations">
        <div className={`progress-destination ${step1Class}`}>
          <span>1</span>
        </div>
        <div className={`progress-destination ${step2Class}`}>
          <span>2</span>
        </div>
        <div className={`progress-destination ${step3Class}`}>
          <span>3</span>
        </div>
        <div className={`progress-destination ${step4Class}`}>
          <span>4</span>
        </div>
      </div>
    );
  };

  
  
  return (
    <div className="content">
      <Row>
        <Col md="8">
          <div className="progress-container">
            {renderProgressBarDestinations()}
            <Progress
              max="100"
              value={calculateProgress()}
              className="progress-bar-sm progress-bar-striped"
            />
          </div>
        </Col>
      </Row>
      <Row className="progress-row">
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Add New Machine</h5>
            </CardHeader>
            <CardBody>
              <Form>{renderStepContent()}</Form>
            </CardBody>
            <CardFooter>
              <div className="text-right">
                {activeStep > 1 && (
                  <Button color="primary" onClick={handlePrevious}>
                    Previous
                  </Button>
                )}
                {activeStep < 3 && (
                  <Button color="primary" onClick={handleNext}>
                    Next
                  </Button>
                )}
                {activeStep === 3 && (
                  <Button color="success" onClick={handleFormSubmit}>
                    Submit
                  </Button>
                )}
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );

}

export default ConfigCodeMaster;
