import React, { useState, useEffect } from "react";
import axios from "axios";
import  { APIURL } from './Apiurl.js';
import redirectToLoginIfTokenMissing from './TokenUtility'; 
import {
  TabContent,
  TabPane,
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Badge } from "reactstrap";
import { QueryApi } from "QueryApi.js";

function JobrolemasterAll() {
  const [activeTab, setActiveTab] = useState(1);
  const [employees, setEmployees] = useState([]);
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    fetchData();
    redirectToLoginIfTokenMissing();
  }, []);

const fetchData = async () => {
  try {
    const token = sessionStorage.getItem('token');
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const roleId = decodedToken['sub'];
    const logintenantId = decodedToken['https://hasura.io/jwt/claims']['x-hasura-org-id'];
    const storedTenantId = sessionStorage.getItem('selectedTenantId');
    console.log(roleId === 1);
    
    let query = ''; // Define the query variable outside the if-else block
    let variables = {}; // Define the variables object
    
    if (roleId === 1) {
      if(storedTenantId !==null){
        query = `
        query MyQuery($tenantId: Int!) {
          tenant_employees(where: { tenant_id: { _eq: $tenantId } }) {
            employee_id
          first_name
          middle_name
          is_active
          last_name
          unit {
            organization_node_name
          }
          organization {
            organization_node_name
          }
          dept {
            organization_node_name
          }
          empcategory {
            code
          }
          emptype {
            code
          }
          jobrole {
            job_role
          }
          reportingmanager {
            first_name
            middle_name
            last_name
          }
          created_at
          contact_number
          updated_at
          }
        }
      `;
      
      variables = {
        tenantId: storedTenantId,
      };
      }else{
        query = `
        query {
          tenant_employees {
            employee_id
            first_name
            middle_name
            is_active
            last_name
            unit {
              organization_node_name
            }
            organization {
              organization_node_name
            }
            dept {
              organization_node_name
            }
            empcategory {
              code
            }
            emptype {
              code
            }
            jobrole {
              job_role
            }
            reportingmanager {
              first_name
              middle_name
              last_name
            }
            created_at
            contact_number
            updated_at
          }
        }
        `;
      }
     
    } else {
      query = `
        query MyQuery($tenantId: Int!) {
          tenant_employees(where: { tenant_id: { _eq: $tenantId } }) {
            employee_id
          first_name
          middle_name
          is_active
          last_name
          unit {
            organization_node_name
          }
          organization {
            organization_node_name
          }
          dept {
            organization_node_name
          }
          empcategory {
            code
          }
          emptype {
            code
          }
          jobrole {
            job_role
          }
          reportingmanager {
            first_name
            middle_name
            last_name
          }
          created_at
          contact_number
          updated_at
          }
        }
      `;
      
      variables = {
        tenantId: parseInt(decodedToken['https://hasura.io/jwt/claims']['x-hasura-tenant-id']),
      };
    }

    const response = await axios.post(APIURL, {
      query: query,
      variables: variables,
    },
    {
      headers: {
        'Content-Type': 'application/json',
         Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
      },
    });


    setEmployees(response.data.data.tenant_employees);
      console.log(response.data.data);
    } catch (error) {
      console.error(error);
    }
};

 
  function actionFormatter(cell, row) {
    return (
      <div>
        <Col
          className="font-icon-list col-xs-6 col-xs-6"
          lg="2"
          md="3"
          sm="4"
          onClick={() => handleEdit(row)}
        ></Col>
       <FontAwesomeIcon
          icon={faPencilAlt}
          className="btn btn-primary tim-icons icon-pencil"
          style={{ fontSize: "70%", background: "none",  marginRight: "-46px", pointerEvents: "none" }}
        />
        <Link to={`/admin/org-structure/${row.organization_structure_id}`}>
          <FontAwesomeIcon
            icon={faTrash}
            className="btn btn-primary tim-icons icon-pencil"
            style={{ fontSize: "70%", background: "none", pointerEvents: "none" }}
          />
        </Link>
      </div>
    );
  }

  function handleEdit(row) {
    // Logic for handling the edit action
    console.log("Editing row:", row);
  }
  const activeFormatter = (cell, row) => {
    const isActive = row.is_active === "1";
  
    return (
      <Badge color={isActive ? "info" : "danger"}>
        {isActive ? "Active" : "Inactive"}
      </Badge>
    );
  };
  const columns = [
    {
      dataField: "employee_id",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
    },
    {
      dataField: "date", // New dataField for the merged column
      text: "Date", // New text for the merged column
      headerStyle: { width: '500px' }
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: '500px' }
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: '500px' }
    },
    {
      dataField: "shift_id",
      text: "Shift Name",
      headerStyle: { width: '500px' }
    },
    {
      dataField: "planned_production_time",
      text: "Planned Production Time(in minutes)",
      formatter: (cell, row) => convertMillisecondsToMinutes(cell),
      headerStyle: { width: '500px' }
    },
    {
      dataField: "",
      text: "% Time Loss(Idle Time)",
      headerStyle: { width: '500px' },      
    },
    {
      dataField: "capacity_utilized_percent",
      text: "Capacity Utilization(%)",
      headerStyle: { width: '500px' },
      formatter: (data) => {
        return `${data.toFixed(3)}%`;
      }
    },
    {
      dataField: "action",
      text: "Action",
      formatter: actionFormatter,
      headerStyle: { width: '500px' }
    },
  ];
  
  const convertMillisecondsToMinutes = (milliseconds) => {
    const minutes = milliseconds / (1000 * 60);
    return minutes.toFixed(2);
  };

  
  const [apiData, setApiData] = useState([]);

  useEffect(() => {
    fetchDataList();
  }, [])
  const fetchDataList = async () => {
    const data = await QueryApi(`
    query MyQuery {
      dm_shiftwise {
        date
        org_id
        department_id
        shift_id
        planned_production_time
        capacity_utilized_percent
        org {
          organization_node_name
        }
        dept {
          organization_node_name
        }
      }
    }
    
    `);
    setApiData(data?.dm_shiftwise)
  }

  return (
    <div className="content">
      
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={employees}
                    columns={columns}
                    search
                  >
                     {(props) => (
                      <div>
                          <BootstrapTable
                            {...props.baseProps}
                            filter={filterFactory()}
                            pagination={paginationFactory()}
                            data={apiData}
                            headerClasses="sorted-asc sorted-desc"
                            wrapperClasses="table-responsive"
                          />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
      
      </TabContent>
    
    </div>
  );
}

export default JobrolemasterAll;
