import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Progress,
} from "reactstrap";
import Select from "react-select";
import axios from "axios";
import  { APIURL } from './Apiurl.js';
import { toast } from 'react-toastify';

const EmployeeAdd = (props) => {
  const [otherData, setOtherData] = useState([]);
  const [employee, setemployee] = useState("");
  const [employeename, setemployeename] = useState("");
  const [org, setorg] = useState("");
  const [orgs, setOrgs] = useState([]);

  const [unit, setunit] = useState("");
  const [plants, setplants] = useState([]);

  const [dept, setdept] = useState("");
  const [depts, setdepts] = useState([]);

  const [employeecode, setemployeecode] = useState("");
  const [jobid, setjobid] = useState("");

  const [jobtitle, setjobtitle] = useState("");
  const [rm, setrm] = useState("");
  const [location, setlocation] = useState("");
  const [position, setposition] = useState("");
  const [jobroledefination, setjobroledefination] = useState("");
  const [jdid, setjdid] = useState("");
  const [createdby, setcreatedby] = useState("");

  const [rolecat, setrolecat] = useState("");

  const [empjobroles, setempjobroles] = useState([]);
  const [jobroleid, setjobroleid] = useState("");

  const [employees, setEmployees] = useState([]);
  const [rms, setrms] = useState([]);

  const token = sessionStorage.getItem("token");
  useEffect(() => {
    fetchEmployees();
    fetchorg();
    fetchJobRole();
    fetchReportingmanager();
  }, [rolecat]);
 
  const fetchorg = async (logintenantId) => {
    try {
      const response = await axios.post(APIURL, {
        query: `
          query TntOrgStr {
            tnt_organization_structure(
              where: {
                parent_organization_id: { _eq: 0 },
              }
            ) {
              organization_structure_id
              organization_node_name
            }
          }
        `,
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      const fetchedOrgs = response.data.data.tnt_organization_structure;
      setOrgs(fetchedOrgs);
      console.log(fetchedOrgs);
      const parentIds = fetchedOrgs.map(org => org.organization_structure_id);
      console.log(parentIds);

      const childResponse = await axios.post(APIURL, {
        query: `
          query TntOrgStrData($parentIds: [Int!]) {
            tnt_organization_structure(
              where: {
                parent_organization_id: { _in: $parentIds }
              }
            ) {
              organization_structure_id
              organization_node_name
            }
          }
        `,
        variables: {
          parentIds: parentIds,
        },
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      const childOrgs = childResponse.data.data.tnt_organization_structure;
      console.log(childOrgs);
      const updatedOtherData = otherData.map(row => {
        const matchingOrg = childOrgs.find(
          org => org.organization_structure_id === row.parent_organization_id
        );

        if (matchingOrg) {
          return {
            ...row,
            parent_organization_id: matchingOrg.organization_structure_id,
          };
        }

        return row;
      });

      setOtherData(childOrgs);
      console.log(otherData);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["role_id"];
    let logintenantId = null;

    if (roleId === 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-tenant-id"];
    }

    fetchorg(logintenantId);
  }, []);
  useEffect(() => {
    fetchplant();
  }, [org]);
  const fetchplant = async () => {
    if (org) { // Only fetch if org is selected
      try {
        const response = await axios.post(APIURL, {
          query: `
            query TntOrgPlant($p: Int!) {
              tnt_organization_structure(where: {parent_organization_id: {_eq: $p}}) {
                organization_structure_id
                organization_node_name
              }
            }
          `,
          variables: {
            p: org,
          },
        }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        // console.log(org);
        setplants(response.data.data.tnt_organization_structure);
        console.log(response.data.data.tnt_organization_structure);
      } catch (error) {
        console.error(error);
      }
    }
  };
  useEffect(() => {
    fetchdept();
  }, [unit]);
  const fetchdept = async () => {
    if (unit) { // Only fetch if org is selected
      try {
        const response = await axios.post(APIURL, {
          query: `
            query TntOrgDept($d: Int!) {
              tnt_organization_structure(where: {parent_organization_id: {_eq: $d}}) {
                organization_structure_id
                organization_node_name
              }
            }
          `,
          variables: {
            d: unit,
          },
        }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        // console.log(org);
        setdepts(response.data.data.tnt_organization_structure);
        console.log(response.data.data.tnt_organization_structure);
      } catch (error) {
        console.error(error);
      }
    }
    
  };
  const fetchJobRole = async () => {
    try {
      const response = await axios.post(APIURL, {
        query: `
        query GetJobRoleMaster($jr: String!)  {
          job_role_master(where: {job_role_category: {_eq: $jr}}) {
            job_role
            job_id
          }
        }
        `,
        variables: {
          jr: rolecat,
        },
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      setempjobroles(response.data.data.job_role_master);
      console.log(response.data.data.job_role_master);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchEmployees = async () => {
    try {
      const response = await axios.post(APIURL, {
        query: `
        query MyQuery {
            tenant_employees {
                employee_id
                first_name
                middle_name
                last_name
            }
          }
        `,
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      setEmployees(response.data.data.tenant_employees); // Make sure 'organization_category' is an array
    } catch (error) {
      console.error(error);
    }
  };
 
 
 
  const fetchReportingmanager = async () => {
    try {
      const response = await axios.post(APIURL, {
        query: `
          query TntRM {
            tenant_employees {
                employee_id
                first_name
                last_name
            }
          }
        `,
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      setrms(response.data.data.tenant_employees);
    } catch (error) {
      console.error(error);
    }
  };
  const handleFormSubmit = (event) => {
    event.preventDefault();
    // Create an object with the form data
    const token = sessionStorage.getItem('token');
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const roleId = decodedToken['sub'];
    // const logintenantId = decodedToken['https://hasura.io/jwt/claims']['x-hasura-org-id'];
    let logintenantId = null;
  if(roleId === 1){
    logintenantId = sessionStorage.getItem('selectedTenantId');
  }else{
    logintenantId = decodedToken['https://hasura.io/jwt/claims']['x-hasura-org-id'];
  }
    const formData = {
      tenantid: logintenantId,
      employee_id: employee,
      emp_name: employeename,
      emp_job_role:jobroleid,
      emp_code:employeecode,
      job_id:jobid,
      job_title:jobtitle,
      organization_id:org,
      plant:unit,
      department:dept,
      location:location,
      reporting_manager_id: rm,
      position:position,
      job_role_defination:jobroledefination,
      jd_id: jdid,
      
      created_by:createdby,
    };
    console.log(formData);
    axios
    .post(APIURL, {
        query: `
            mutation InsertTenantEmployeesJobDescription($formData: tenant_employee_job_descriptions_insert_input!) {
                insert_tenant_employee_job_descriptions(objects: [$formData]) {
                affected_rows
                }
            }
        `,
        variables: {
          formData: formData,
        },
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data); // Handle the response data as needed
        if (response.data.data && response.data.data.insert_tenant_employee_job_descriptions.affected_rows > 0) {
            console.log("inserted");
            props.onInsertSuccess();
            setemployee("");
            setemployeename("");
            setjobroleid("");
            setemployeecode("");
            setjobid("");
            setjobtitle("");
            setorg("");
            setunit("");
            setdept("");
            setlocation("");
            setrm("");
            setposition("");
            setjobroledefination("");
            setjdid("");
           
           
          toast.success('Tenant Employees Job Description inserted successfully!');
        } else {
          toast.error('Failed to insert data.');
        }
      })
      .catch((error) => {
        console.error(error); // Handle the error
        toast.error('An error occurred while inserting data.');
      });
    };

  

  
  return (
      <div className="content">
      <Row className="progress-row">
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Tenant Employee Job Description</h5>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleFormSubmit}>
                <Row>
                    <Col className="px-md-1" md="6">
                    <FormGroup>
                    <Label for="tenantId">Employee ID</Label>
                    <Input
                        type="text"
                        id="employeeId"
                        value={employee}
                        onChange={(e) => setemployee(e.target.value)}
                    />
                
                    </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="6">
                    <FormGroup>
                    <Label for="tenantId">Employee Name</Label>
                    <Input
                        type="text"
                        id="employeeId"
                        value={employeename}
                        onChange={(e) => setemployeename(e.target.value)}
                    />
                    </FormGroup>
                    </Col>
                </Row>
                <Row>
               
               <Col className="pr-md-1" md="4">
                 <FormGroup>
                   <label>Organization</label>
                   <Input
                     type="select"
                     value={org}
                     onChange={(e) => setorg(e.target.value)}
                   >
                     <option value="">Select Organization</option>
                     {otherData.map((org) => (
                       <option key={org.organization_structure_id} value={org.organization_structure_id}>
                         {org.organization_node_name}
                       </option>
                     ))}
                   </Input>
                 </FormGroup>
               </Col>
               <Col className="pr-md-1" md="4">
                 <FormGroup>
                   <label>Plant/Unit</label>
                   <Input
                     type="select"
                     value={unit}
                     onChange={(e) => setunit(e.target.value)}
                   >
                     <option value="">Select Unit/Plant</option>
                     {plants.map((plant) => (
                       <option key={plant.organization_structure_id} value={plant.organization_structure_id}>
                         {plant.organization_node_name}
                       </option>
                     ))}
                   </Input>
                 </FormGroup>
               </Col>
               <Col className="px-md-1" md="4">
                   <FormGroup>
                   <Label for="Department">Department</Label>
                   <Input
                     type="select"
                     value={dept}
                     onChange={(e) => setdept(e.target.value)}
                   >
                     <option value="">Select department</option>
                     {depts.map((dpt) => (
                       <option key={dpt.organization_structure_id} value={dpt.organization_structure_id}>
                         {dpt.organization_node_name}
                       </option>
                     ))}
                   </Input>
                   </FormGroup>
               </Col>
                </Row>
                <Row>
                <Col className="px-md-1" md="4">
                  <FormGroup>
                  <Label for="tenantId">Select Job Role Category</Label>
                  <Input
                      type="select"
                      id="tenantId"
                      value={rolecat}
                      onChange={(e) => setrolecat(e.target.value)}
                  >
                      <option value="">Select Job Category</option>
                      <option value="Automation Technician">Automation Technician</option>
                      <option value="Mechatronics Engineers">Mechatronics Engineers</option>
                      <option value="Assembler">Assembler</option>
                      <option value="Welder">Welder</option>
                      <option value="Machinist / Operator">Machinist / Operator</option>
                      <option value="Production">Production</option>
                      <option value="Quality Assurance & Control">Quality Assurance & Control</option>
                      <option value="Production Support">Production Support</option>
                      <option value="Maintenance">Maintenance</option>
                      <option value="Management">Management</option>
                      
                  </Input>
                  </FormGroup>
                </Col>
                <Col className="px-md-1" md="4">
                  <FormGroup>
                  <Label for="tenantId">Select Job Role</Label>
                  <Input
                      type="select"
                      id="tenantId"
                      value={jobroleid} // The selected value should be stored in the jobroleid state
                      onChange={(e) => setjobroleid(e.target.value)} // Update the jobroleid state when the selection changes
                  >
                      <option value="">Select Job Role</option>
                      {empjobroles.map((empjobs) => (
                          <option key={empjobs.job_id} value={empjobs.job_id}>
                              {empjobs.job_role}
                          </option>
                      ))}
                  </Input>
                  </FormGroup>
                </Col>
                <Col className="pr-md-1" md="4">
                  <FormGroup>
                      <label>Employee Code</label>
                      <Input
                      type="text"
                      id="employeeId"
                      value={employeecode}
                      onChange={(e) => setemployeecode(e.target.value)}
                  />
                
                  </FormGroup>
                  </Col>
                 
                </Row>
                <Row>
                  <Col className="pr-md-1" md="4">
                    <FormGroup>
                        <label>Job ID</label>
                        <Input
                        type="text"
                        id="employeeId"
                        value={jobid}
                        onChange={(e) => setjobid(e.target.value)}
                    />
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1" md="4">
                    <FormGroup>
                        <label>Job Title</label>
                        <Input
                        type="text"
                        id="employeeId"
                        value={jobtitle}
                        onChange={(e) => setjobtitle(e.target.value)}
                    />
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1" md="4">
                    <FormGroup>
                        <label>Reporting Manager</label>
                        <Input
                        type="select"
                        id="employeeId"
                        value={rm}
                        onChange={(e) => setrm(e.target.value)}
                    >
                        <option value="">Select Reporting Manager</option>
                        {rms.map((rm) => (
                        <option key={rm.employee_id} value={rm.employee_id}>
                            {rm.first_name} {rm.last_name}
                        </option>
                        ))}
                    </Input>
                    </FormGroup>
                  </Col>
                   
                </Row>
                <Row>
                <Col className="pr-md-1" md="6">
                    <FormGroup>
                        <label>Location</label>
                        <Input
                        type="text"
                        id="employeeId"
                        value={location}
                        onChange={(e) => setlocation(e.target.value)}
                    />
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                        <label>Position</label>
                        <Input
                        type="text"
                        id="employeeId"
                        value={position}
                        onChange={(e) => setposition(e.target.value)}
                    />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                <Col className="pr-md-1" md="6">
                    <FormGroup>
                        <label>Job Role Defination</label>
                        <Input
                        type="text"
                        id="employeeId"
                        value={jobroledefination}
                        onChange={(e) => setjobroledefination(e.target.value)}
                    />
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                        <label>Job Description ID</label>
                        <Input
                        type="text"
                        id="employeeId"
                        value={jdid}
                        onChange={(e) => setjdid(e.target.value)}
                    />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="justify-end">
                    <Button type="submit" className="btn btn-primary">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
    
  );
}

export default EmployeeAdd;





