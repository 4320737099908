import React, { useState, useEffect } from "react";
import classNames from "classnames";
import axios from "axios";
import HumacLogo from "./HumacLogo.png";
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  NavbarToggler,
  ModalHeader,
} from "reactstrap";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import AddTilesSidebar from "views/AddTilesSidebar.js";
import { APIURL, SECRET_KEY } from "../../views/Apiurl.js";
function AdminNavbar(props) {
  const [collapseOpen, setcollapseOpen] = React.useState(false);
  const [modalSearch, setmodalSearch] = React.useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [tenantlogin, settenantlogin] = useState(null);
  const [dashboardData, setDashboardData] = useState([]);
  const [tenantData, setTenantData] = useState([]);
  const [logintenantData, setloginTenantData] = useState([]);
  const [color, setcolor] = React.useState("navbar-transparent");

  const navigate = useNavigate();

  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  });
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setcolor("bg-white");
    } else {
      setcolor("navbar-transparent");
    }
  };
  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setcolor("navbar-transparent");
    } else {
      setcolor("bg-white");
    }
    setcollapseOpen(!collapseOpen);
  };
  // this function is to open the Search modal
  const toggleModalSearch = () => {
    setmodalSearch(!modalSearch);
  };
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  const clearSessionData = () => {
    sessionStorage.removeItem("selectedTenantId");
    window.location.href = "/admin/dashboard";
    // You can also perform any other necessary actions here
  };
  let checktenant = sessionStorage.getItem("selectedTenantId");
  if (checktenant !== null) {
    // settenantlogin(checktenant);
  } else {
    // settenantlogin(sessionStorage.getItem('selectedTenantId'));
  }
  const token = sessionStorage.getItem("token");
  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const roleId = decodedToken["sub"];
  let logintenantId = null;
  if (roleId == 1) {
    logintenantId = sessionStorage.getItem("selectedTenantId");
  } else {
    logintenantId =
      decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem("token");
        const response = await axios.post(
          APIURL,
          {
            query: `
              query TenantDashboards($tenantId: Int!) {
                tenant_dashboard_master(where: { tenantid: { _eq: $tenantId } }) {
                  tenant_dashboard_master_id
                  is_default
                  dashboard_name
                }
              }
            `,
            variables: {
              tenantId: logintenantId, // Convert tenantId to the appropriate type
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json", // Add your secret key here
            },
          }
        );

        setDashboardData(response.data.data.tenant_dashboard_master);
      } catch (error) {}
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData1 = async () => {
      try {
        const token = sessionStorage.getItem("token");
        const response = await axios.post(
          APIURL,
          {
            query: `
              query FetchByTnts($tenantId: Int!) {
                users(where: { tenantid: { _eq: $tenantId }, role_id: {_eq: 2} }) {
                  id
                  name
                  tenantid
                  contact
                }
              }
            `,
            variables: {
              tenantId: logintenantId,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json", // Add your secret key here
            },
          }
        );

        setTenantData(response.data.data.users);
      } catch (error) {}
    };

    fetchData1();
  }, []);
  useEffect(() => {
    const fetchData2 = async () => {
      try {
        const token = sessionStorage.getItem("token");
        const response = await axios.post(
          APIURL,
          {
            query: `
              query MyQuery($tenantId: Int!) {
                tenant_administration(where: { tenantid: { _eq: $tenantId }}) {
                  holding_company_name
                  tenantid
                }
              }
            `,
            variables: {
              tenantId: logintenantId,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json", // Add your secret key here
            },
          }
        );

        setloginTenantData(response.data.data.tenant_administration);
      } catch (error) {}
    };

    fetchData2();
  }, []);
  // Define the desired URL for showing the button
  const { id } = useParams();

  // Get the current location
  const currentPathname = window.location.pathname;

  // Define your desired URL pattern
  const desiredPattern = "/admin/custom-dashboard/";

  // Check if the current URL starts with the desired pattern
  const shouldShowButton = currentPathname.startsWith(desiredPattern);
  const handleLogout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("roleId");
    sessionStorage.removeItem("tenantid");
    sessionStorage.removeItem("selectedTenantId");
    navigate("/user/login");
  };

  return (
    <>
      <Navbar
        id="header"
        className={classNames("navbar-absolute", color)}
        expand="lg"
      >
        <Container fluid id="header-menu">
          <div className="navbar-wrapper">
            <div
              className={classNames("navbar-toggle d-inline", {
                toggled: props.sidebarOpened,
              })}
            >
              <NavbarToggler onClick={props.toggleSidebar}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </NavbarToggler>
            </div>
            <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
              <img
                src={HumacLogo}
                alt="Humac Logo"
                style={{ width: "auto", height: "80px" }}
              />
            </NavbarBrand>
          </div>
          <NavbarToggler onClick={toggleCollapse}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>

          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              <InputGroup className="search-bar"></InputGroup>
              <UncontrolledDropdown nav>
                {roleId === 1
                  ? tenantData.map((tenant) => (
                      <Button
                        className="btn-simple btn-icon-only"
                        color="primary"
                        style={{
                          height: "39px",
                          border: "none",
                          marginTop: "1px",
                          fontSize: "19px",
                          width: "400px",
                        }}
                      >
                        {tenant.name}
                      </Button>
                    ))
                  : logintenantData.map((logintenant) => (
                      <Button
                        className="btn-simple btn-icon-only"
                        color="primary"
                        style={{
                          height: "39px",
                          border: "none",
                          marginTop: "1px",
                          fontSize: "22px",
                          width: "500px",
                          margin: "auto",
                          color: "#1360ff",
                        }}
                      >
                        {logintenant.holding_company_name}
                      </Button>
                    ))}
              </UncontrolledDropdown>

              {roleId == 2 && (
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    caret
                    color="default"
                    nav
                    onClick={(e) => e.preventDefault()}
                  >
                    <div
                      className="photo"
                      id="dashboard-button"
                      style={{
                        borderRadius: "0px !important",
                        width: "100% !important",
                      }}
                    >
                      <Button
                        className="btn-simple btn-icon-only"
                        color="primary"
                        style={{
                          height: "39px",
                          border: "none",
                          marginTop: "-8px",
                          fontSize: "19px",
                        }}
                      >
                        My Dashboards
                      </Button>
                    </div>
                  </DropdownToggle>
                  {/* <DropdownMenu className="dropdown-navbar" right tag="ul">
                    {dashboardData.map((dashboard) => (
                      <NavLink
                        href={`/admin/custom-dashboard/${dashboard.tenant_dashboard_master_id}`}
                        key={dashboard.tenant_dashboard_master_id} // unique key
                        onClick={() => {
                          sessionStorage.setItem(
                            "selectedDashboardId",
                            dashboard.tenant_dashboard_master_id
                          );
                          sessionStorage.setItem(
                            "is_default",
                            dashboard.is_default
                          );
                        }}
                      >
                        <DropdownItem className="nav-item">
                          {dashboard.dashboard_name}{" "}
                          {dashboard.is_default === 1 ? "(Default)" : ""}
                        </DropdownItem>
                      </NavLink>
                    ))}
                  </DropdownMenu> */}
                </UncontrolledDropdown>
              )}

              <Collapse navbar isOpen={collapseOpen}>
                <Nav className="ml-auto" navbar>
                  {shouldShowButton && (
                    <Button
                      className="btn-simple btn-icon-only"
                      color="primary"
                      style={{
                        height: "39px",
                        display: "flex",
                        flexDirection: "row",
                        width: "140px",
                      }}
                      onClick={toggleSidebar}
                    >
                      Add Widgets
                    </Button>
                  )}
                </Nav>
                <AddTilesSidebar
                  isOpen={isSidebarOpen}
                  toggleSidebar={toggleSidebar}
                />
              </Collapse>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <div className="photo">{decodedToken["name"]}</div>
                  <b className="caret d-none d-lg-block d-xl-block" />
                  <p className="d-lg-none">Log out</p>
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown-navbar"
                  right
                  tag="ul"
                  style={{ marginRight: "40px" }}
                >
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">Profile</DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">Settings</DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem
                      className="nav-item"
                      onClick={clearSessionData}
                    >
                      Back To Home
                    </DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem className="nav-item" onClick={handleLogout}>
                      Log out
                    </DropdownItem>
                  </NavLink>
                </DropdownMenu>
              </UncontrolledDropdown>
              <li className="separator d-lg-none" />
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      <Modal
        modalClassName="modal-search"
        isOpen={modalSearch}
        toggle={toggleModalSearch}
      >
        <ModalHeader>
          <Input placeholder="SEARCH" type="text" />
          <button
            aria-label="Close"
            className="close"
            onClick={toggleModalSearch}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
        </ModalHeader>
      </Modal>
    </>
  );
}

export default AdminNavbar;
