import React, { useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';

const StackChart = (props) => {
    useEffect(() => {
        return () => {
        };
    }, []);

    const seriesData = props.barStyleData.map((datas) => {
        return {
            name: datas.barName,
            type: 'bar',
            stack: 'stack',
            emphasis: {
                focus: 'series'
            },
            data: datas.barData,
            color: datas.barColor
        }
    });

    const barName = props.barStyleData.map((data) => data.barName);

    const option = {
        title: {
            text: props.title,
            left: 'center',
            top: 0,
            textStyle: {
                color: 'white', // Change text color to white
                fontSize: 16
            }
        },
        legend: {
            data: barName,
            left: '10%',
            top: 30,
            textStyle: {
                color: 'white', // Change text color to white
            },
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            // textStyle: {
            //     color: 'white', // Change text color to white
            // }
        },
        xAxis: {
            type: 'category',
            data: props.xAxisData,
            name: 'X Axis',
            axisLine: { onZero: true },
            splitLine: { show: false },
            splitArea: { show: false },
            axisLabel: {
                color: 'white' // Change text color to white
            }
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                color: 'white' // Change text color to white
            }
        },
        grid: {
            bottom: 100,
        },
        series: seriesData
    };

    return (
        <ReactEcharts
            option={option}
            style={{ height: props.height, width: props.width }}
        />
    );
};

export default StackChart;


// import React, { useEffect } from 'react';
// import ReactEcharts from 'echarts-for-react';

// const StackChart = (props) => {
//     useEffect(() => {
//         return () => {
//         };
//     }, []);

//     const seriesData = props.barStyleData.map((datas) => {
//         return {
//             name: datas.barName,
//             type: 'bar',
//             stack: 'stack',
//             emphasis: {
//                 focus: 'series'
//             },
//             data: datas.barData,
//             color: datas.barColor
//         }
//     });

//     const barName = props.barStyleData.map((data) => data.barName);

//     const option = {
//         title: {
//             text: props.title,
//             left: 'center',
//             top: 0,
//             textStyle: {
//                 color: 'white',
//                 fontSize: 16
//             }
//         },
//         legend: {
//             data: barName,
//             left: '10%',
//             top: 30,
//             textStyle: {
//                 color: '#555865',
//             },
//         },
//         tooltip: {
//             trigger: 'axis',
//             axisPointer: {
//                 type: 'shadow'
//             }
//         },
//         xAxis: {
//             type: 'category',
//             data: props.xAxisData,
//             name: 'X Axis',
//             axisLine: { onZero: true },
//             splitLine: { show: false },
//             splitArea: { show: false },
//         },
//         yAxis: {
//             type: 'value'
//         },
//         grid: {
//             bottom: 100,
//         },
//         series: seriesData
//     };

//     return (
//         <ReactEcharts
//             option={option}
//             style={{ height: props.height, width: props.width }}
//         />
//     );
// };

// export default StackChart;
