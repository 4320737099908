import React, { useState } from "react";
import Select from "react-select";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import axios from "axios";
import  { APIURL } from './Apiurl.js';

const machineTypeOptions = [
  { value: "type1", label: "Type 1" },
  { value: "type2", label: "Type 2" },
  { value: "type3", label: "Type 3" },
];

function TenantAdd() {
  const [selectedMachineType, setSelectedMachineType] = useState(null);
  const [tenantclientname, settenantclientname] = useState("");
  const [tenantsubdomain, settenantsubdomain] = useState("");
  const handleMachineTypeChange = (selectedOption) => {
    setSelectedMachineType(selectedOption);
  };
  const handleFormSubmit = (event) => {
    event.preventDefault();
    // Create an object with the form data
    const formData = {
        tenantclientname: tenantclientname,
        tenantsubdomain: tenantsubdomain,

      // Add other form fields here
    };
    console.log(formData);    
    // Make a POST request to the Laravel backend
    axios
      .post(`${APIURL}/api/tenant/add`, formData)
      .then((response) => {
        console.log(response.data); // Handle the response data as needed
        // Reset the form or show a success message
        if (response.status === 201) {
          // Redirect to admin/machine-all
          window.location.href = "/admin/tenant/tenant-all";
        } else {
          // Reset the form or show a success message
        }
      })
      .catch((error) => {
        console.error(error); // Handle the error
        // Show an error message
      });
  };
  return (
    <div className="content">
      <h1>Tenant All</h1>
      <Breadcrumb>
        <BreadcrumbItem>Home</BreadcrumbItem>
        <BreadcrumbItem>Tenant All</BreadcrumbItem>
        <BreadcrumbItem active>Tenant Add</BreadcrumbItem>
      </Breadcrumb>
      <Row className="progress-row">
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Add New Tenant</h5>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleFormSubmit}>
              <Row>
                   
                    <Col className="pr-md-1" md="6">
                        <FormGroup>
                        <label htmlFor="exampleInputEmail1">Tenant Client Name</label>
                        <Input
                            placeholder="Tenant Client Name"
                            type="text"
                            value={tenantclientname}
                            onChange={(e) => settenantclientname(e.target.value)}
                        />
                        </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                        <FormGroup>
                        <label htmlFor="exampleInputEmail1">Tenant Sub-domain</label>
                        <Input
                            placeholder="Tenant Sub-domain"
                            type="text"
                            value={tenantsubdomain}
                            onChange={(e) => settenantsubdomain(e.target.value)}
                        />
                        </FormGroup>
                    </Col>
                    
                </Row>
                <Row>
                  <Col className="justify-end">
                    <Button type="submit" className="btn btn-primary">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default TenantAdd;
