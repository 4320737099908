import React, { useState } from "react";
import { TabContent, TabPane, Card, CardBody, Row, Col } from "reactstrap";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import BootstrapTable from "react-bootstrap-table-next";

function OLEGridByOperatorExperiance(props) {
  const [activeTab, setActiveTab] = useState(1);

  const columns = [
    {
      dataField: "serialNo",
      text: "Sr. No.",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
    },
    {
      dataField: "experience",
      text: "Experience (In Years)",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "time_efficiency_per",
      text: "Availability %",
      formatter: (data) => {
        return data !== null ? `${data.toFixed(3)}%` : "";
      },
      headerStyle: { width: "500px" },
    },
    {
      dataField: "production_efficiency_per",
      text: "Performance %",
      formatter: (data) => {
        return data !== null ? `${data.toFixed(3)}%` : "";
      },
      headerStyle: { width: "500px" },
    },
    {
      dataField: "quality_efficiency_per",
      text: "Quality %",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        return data !== null ? `${data.toFixed(3)}%` : "";
      },
    },
    {
      dataField: "overall_labor_effectiveness",
      text: "OLE %",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        return data !== null ? `${data.toFixed(3)}%` : "";
      },
    },
  ];

  return (
    <div className="content">
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={props.tableData}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <div>
                        <div
                          className="float-right"
                          style={{
                            display: "flex",
                            marginBottom: "18px",
                            gap: "12px",
                          }}
                        ></div>
                        <BootstrapTable
                          {...props.baseProps}
                          filter={filterFactory()}
                          pagination={paginationFactory()}
                          headerClasses="sorted-asc sorted-desc"
                          wrapperClasses="table-responsive"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default OLEGridByOperatorExperiance;
