import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Progress,
} from "reactstrap";

function WorkscheduleMaster() {
  const [activeStep, setActiveStep] = useState(1);
  const [checked, setChecked] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleNext = () => {
    if (activeStep === 1 && progress === 0) {
      setProgress(50);
    } else if (activeStep === 2 && progress === 50) {
      setProgress(100);
    }
    setActiveStep(activeStep + 1);
  };

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSubmit = () => {
    // Handle form submission
  };
  const renderStepContent = () => {
    switch (activeStep) {
      case 1:
        return (
          <>
            <Row>
              <Col className="pr-md-1" md="4">
                <FormGroup>
                  <label>Tenant ID</label>
                  <Input placeholder="Tenant ID" type="text" />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <label>Organization ID</label>
                  <Input placeholder="Organization ID" type="text" />
                </FormGroup>
              </Col>
              <Col className="pl-md-1" md="4">
                <FormGroup>
                  <label>Business Unit ID</label>
                  <Input placeholder="Business Unit ID" type="text" />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pr-md-1" md="4">
                <FormGroup>
                  <label>Shift ID</label>
                  <Input placeholder="Shift ID" type="text" />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <label>Shift Name</label>
                  <Input placeholder="Shift Name" type="text" />
                </FormGroup>
              </Col>
              <Col className="pl-md-1" md="4">
                <FormGroup>
                  <label>Shift Type</label>
                  <Input placeholder="Shift Type" type="text" />
                </FormGroup>
              </Col>
            </Row>
          </>
        );
      case 2:
        return (
          <>
          <Row>
            <Col className="pr-md-1" md="4">
              <FormGroup>
                <label>Break 1 Start Time</label>
                <Input placeholder="Break 1 Start Time" type="text" />
              </FormGroup>
            </Col>
            <Col className="px-md-1" md="4">
              <FormGroup>
                <label>Break 1 End Time</label>
                <Input placeholder="Break 1 End Time" type="text" />
              </FormGroup>
            </Col>
            <Col className="pl-md-1" md="4">
              <FormGroup>
                <label>Break 2 Start Time</label>
                <Input placeholder="Break 2 Start Time" type="text" />
              </FormGroup>
            </Col>
          </Row>
          </>
        );
      case 3:
        return (
          <>
            <Row>
              <Col className="pr-md-1" md="4">
                <FormGroup>
                  <label>Break 2 End Time</label>
                  <Input placeholder="Break 2 End Time" type="text" />
                </FormGroup>
              </Col>
              {/* Add more fields here */}
            </Row>
          </>
        );
      default:
        return null;
    }
  };
  const calculateProgress = () => {
    return Math.round(((activeStep - 1) / 2) * 100); // Update the denominator to match the total number of steps minus one
  };
  

  const renderProgressBarDestinations = () => {
    const step1Class = activeStep >= 1 ? "progress-destination-active" : "";
    const step2Class = activeStep >= 2 ? "progress-destination-active" : "";
    const step3Class = activeStep >= 3 ? "progress-destination-active" : "";
    const step4Class = activeStep >= 4 ? "progress-destination-active" : "";
  
    return (
      <div className="progress-destinations">
        <div className={`progress-destination ${step1Class}`}>
          <span>1</span>
        </div>
        <div className={`progress-destination ${step2Class}`}>
          <span>2</span>
        </div>
        <div className={`progress-destination ${step3Class}`}>
          <span>3</span>
        </div>
        <div className={`progress-destination ${step4Class}`}>
          <span>4</span>
        </div>
      </div>
    );
  };


  return (
    <div className="content">
      <Row>
        <Col md="8">
          <div className="progress-container">
            {renderProgressBarDestinations()}
            <Progress
              max="100"
              value={calculateProgress()}
              className="progress-bar-sm progress-bar-striped"
            />
          </div>
        </Col>
      </Row>
      <Row className="progress-row">
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Add New Machine</h5>
            </CardHeader>
            <CardBody>
              <Form>{renderStepContent()}</Form>
            </CardBody>
            <CardFooter>
              <div className="text-right">
                {activeStep > 1 && (
                  <Button color="primary" onClick={handlePrevious}>
                    Previous
                  </Button>
                )}
                {activeStep < 3 && (
                  <Button color="primary" onClick={handleNext}>
                    Next
                  </Button>
                )}
                {activeStep === 3 && (
                  <Button color="success" onClick={handleSubmit}>
                    Submit
                  </Button>
                )}
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default WorkscheduleMaster;
