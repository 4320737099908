import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';

const BarChart = (props) => {
  const chartRef = useRef(null);
  useEffect(() => {
    const myChart = echarts.init(chartRef.current);
    const info = props.yAxisData.map((data) => {
      return {
        itemStyle: { color: data.color },
        value: data.value
      }
    });

    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: props.xAxisData,
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: 'Direct',
          type: 'bar',
          barWidth: '60%',
          // data: [
          //   {
          //     value: 10,
          //     itemStyle: { color: 'red' }
          //   },
          //   {
          //     value: 52,
          //     itemStyle: { color: 'green' }
          //   },
          //   {
          // ]
          data: info
        }
      ]
    };
    option && myChart.setOption(option);
    return () => {
      myChart.dispose();
    };
  }, [props.xAxisData, props.yAxisData]);
  return <div ref={chartRef} style={{ width: props.width, height: props.height }} />
}

export default BarChart;
