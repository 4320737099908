import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Progress,
} from "reactstrap";
import axios from "axios";
import  { APIURL } from './Apiurl.js';
import { toast } from 'react-toastify';
import Papa from 'papaparse';
import exampleCSV from '../assets/csv/tenant_employees_csv.csv'; 
import exampleCSV1 from '../assets/csv/config_code_master_csv.csv'; 

function JobrolemasterAdd() {
  const [activeStep, setActiveStep] = useState(1);
  const [progress, setProgress] = useState(0);

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileData, setFileData] = useState(null);


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDownload = async () => {
    const fileName = 'tenant_employees_csv.csv';
    const filePath = exampleCSV;

    try {
      // Fetch the CSV file using the filePath
      const response = await fetch(filePath);

      if (!response.ok) {
        throw new Error('Failed to fetch the file');
      }

      const csvData = await response.text();

      // Create a Blob object from the CSV data
      const blob = new Blob([csvData], { type: 'text/csv' });

      // Generate a URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary anchor element and trigger the download
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();

      // Cleanup: Revoke the Blob URL and remove the temporary anchor element
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleDownloadConfig = async () => {
    const fileName = 'config_code_master.csv';
    const filePath = exampleCSV1;

    try {
      // Fetch the CSV file using the filePath
      const response = await fetch(filePath);

      if (!response.ok) {
        throw new Error('Failed to fetch the file');
      }

      const csvData = await response.text();

      // Create a Blob object from the CSV data
      const blob = new Blob([csvData], { type: 'text/csv' });

      // Generate a URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary anchor element and trigger the download
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();

      // Cleanup: Revoke the Blob URL and remove the temporary anchor element
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error:', error);
    }
  };
const handleFileSubmit = () => {
    if (!selectedFile) {
      toast.error("Please select a file to import.");
      return;
    }
  
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      try {
        const data = fileReader.result;
        // Parse the CSV data using papaparse library
        const parsedData = Papa.parse(data, {
          header: true, // Use the first row as headers
          skipEmptyLines: true, // Skip empty lines in CSV
        });
        const token = sessionStorage.getItem("token");
        // Assuming your CSV has the following headers:
        // org_code, org_node_type, org_node_name, country_id, city_name, parent_org, cost_center
        const formData = parsedData.data.map((row) => {
            // Define a function to handle null or empty values
            const handleValue = (value, defaultValue) => {
                if (value === null || value === undefined || value === "") {
                  return defaultValue;
                }
              console.log(value);
                return value;
              };
          
            return {
              tenantid: handleValue(row.tenant_id, null),
              organization_id: handleValue(row.organization_id, null),
              role_id: handleValue(row.role_id, null),
              job_role_id: handleValue(row.job_role_id, null),
              employee_code: handleValue(row.employee_code, null),
              global_id: handleValue(row.global_id, null),
              prefix: handleValue(row.prefix, null),
              first_name: handleValue(row.first_name, null),
              middle_name: handleValue(row.middle_name, null),
              last_name: handleValue(row.last_name, null),
              dob: handleValue(row.dob, null),
              birth_location: handleValue(row.birth_location, null),
              country: handleValue(row.country, null),
              doj: handleValue(row.doj, null),
              employee_category: handleValue(row.employee_category, null),
              employee_type: handleValue(row.employee_type, null),
              blood_group: handleValue(row.blood_group, null),
              gender: handleValue(row.gender, null),
              total_exp_yr: handleValue(row.total_exp_yr, null),
              primary_employer: handleValue(row.primary_employer, null),
              marital_status: handleValue(row.marital_status, null),
              tag_id: handleValue(row.tag_id, null),
              email_id: handleValue(row.email_id, null),
              contact_number: handleValue(row.contact_number, null),
              password: handleValue(row.password, null),
              reporting_manager_id: handleValue(row.reporting_manager_id, null),
              is_active: "1",
              plant: handleValue(row.plant, null),
              department: handleValue(row.department, null),
              education: handleValue(row.education, null),
              secondary_education: handleValue(row.secondary_education, null),
              total_exp_mn: handleValue(row.total_exp_mn, null),
              emp_position: handleValue(row.emp_position, null),
              certification: handleValue(row.certification, null),
              monthly_ctc: handleValue(row.monthly_ctc, null),
              created_by: handleValue(row.created_by, null),
            };
          });
          
        // Send the data to the Hasura API using axios
        if (formData.length > 0) {
            axios
              .post(
                APIURL,
                {
                  query: `
                    mutation Inserttenant_employees($formData: [tenant_employees_insert_input!]!) {
                      insert_tenant_employees(objects: $formData) {
                        affected_rows
                      }
                    }
                  `,
                  variables: {
                    formData: formData,
                  },
                },
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((response) => {
                console.log(response); // Handle the response data as needed
                if (
                  response.data.data &&
                  response.data.data.insert_tenant_employees.affected_rows > 0
                ) {
                  setFileData(formData);
                  toast.success("CSV data uploaded successfully!");
                } else {
                  toast.error("Failed to insert data.");
                }
              })
              .catch((error) => {
                console.error(error); // Handle the error
                toast.error("An error occurred while uploading data.");
              });
          } else {
            toast.warning("No data found in the CSV file.");
          }
        } catch (error) {
          console.error(error);
          toast.error("Error parsing the file. Please check the format.");
        }
      };
    
      fileReader.readAsText(selectedFile);
    };
  

  return (
    <div className="content">
      <Row className="progress-row">
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Import CSV File for Employees</h5>
              </CardHeader>
            <CardBody id="import-card">
              <Form>
                <Row>
                <Col className="px-md-1" md="6">
                    <FormGroup>
                    
                      <Label for="fileInput" className="custom-file-label">
                        {selectedFile ? selectedFile.name : "Choose CSV file"}
                      </Label>
                      <Input
                        type="file"
                        id="fileInput"
                        className="custom-file-input"
                        accept=".csv"
                        onChange={handleFileChange}
                      />
                    </FormGroup>
                  </Col>
                 
                </Row>
              
                <Row>
                 
                  <Col className="px-md-1" md="6">
                    <FormGroup>
                      <div className="">
                        <Button className="" style={{float: "right", marginTop: "-11px", marginRight: "-168px" }} color="success" onClick={handleFileSubmit}>
                          Submit CSV
                        </Button>
                       
                        
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
            <CardFooter>
              <div className="download-btn">
                <Button className="" color="success" onClick={handleDownload}>
                  Download Example CSV
                </Button>
                <Button className="" color="success" onClick={handleDownloadConfig}>
                  Download Config Master
                </Button>
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default JobrolemasterAdd;
