import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { QueryApi } from "QueryApi";
import redirectToLoginIfTokenMissing from "views/TokenUtility";
import JindalTableComponent from "../JindalTableComponent";

function ProductionGrids(props) {
  const [activeTab, setActiveTab] = useState("1");
  const storedTenantId = sessionStorage.getItem("selectedTenantId");
  useEffect(() => {
    redirectToLoginIfTokenMissing();
  }, []);
  ////////////////////////////////////////////////////////////
  const [apiData, setApiData] = useState([]);

  useEffect(() => {
    fetchAllCardData();
  }, []);
  const fetchAllCardData = async () => {
    try {
      const data = await QueryApi(`
         query MyQuery {
        bopp_fl0_jumbo_output(order_by: {date: desc, machine_id: asc}) {
            product_id
            machine_id
            shiftid
            jumbo_output_volume
            line_id
            tenantid
            timestamp
            date
            dept {
              organization_node_name
              organization_structure_id
            }
            org {
              organization_node_name
            }
            unit {
              organization_node_name
            }
          }
        }
        `);
      setApiData(data.bopp_fl0_jumbo_output);
    } catch (error) {
      setApiData([]);
    }
  };
  const [primarySlitter, setPrimarySlitter] = useState([]);
  const [secondarySlitter, setSecondarySlitter] = useState([]);
  useEffect(() => {
    primarySlitterData();
  }, []);
  const primarySlitterData = async () => {
    try {
      const data = await QueryApi(`
         query MyQuery {
         bopp_fl0_output_ps_slit_primary(order_by: {date: desc, machine_id: asc}) {
            date
            machine_id
            total_psslitter_output__volume
            product_id
            dept {
              organization_node_name
              organization_structure_id
            }
            department_id
            org {
              organization_node_name
            }
            plant {
              organization_node_name
            }
          }
          secondary_slitter : bopp_fl0_output_ss_slit_secondary(order_by: {date: desc, machine_id: asc}) {
              date
              machine_id
              total_ssslitter_output__volume
              dept {
                organization_node_name
                organization_structure_id
                tenantid
              }
              department_id
              org {
                organization_node_name
              }
              unit {
                organization_node_name
              }
            }
        }
        `);
      setPrimarySlitter(data.bopp_fl0_output_ps_slit_primary);
      setSecondarySlitter(data.secondary_slitter);
    } catch (error) {
      setPrimarySlitter([]);
    }
  };
  const [packedProduction, setPackedProduction] = useState([]);
  const [aGradeProduction, setAGradeProduction] = useState([]);
  useEffect(() => {
    fetchDataList();
  }, []);
  const fetchDataList = async () => {
    const data = await QueryApi(`
    query MyQuery {
      bopp_fl0_packed_production(order_by: {date: desc, org_id: asc}) {
        date
        org_id
        unit_id
        department_id
        machineid
        product_id
        egdeid
        line_id
        packed_production
        shiftid
        tenantid
        timestamp
        dept {
          organization_node_name
          organization_structure_id
        }
        org {
          organization_node_name
        }
        plant {
          organization_node_name
        }
      }
    a_grade_production : bopp_fl0_agrade_production(order_by: {date: desc, org_id: asc}) {
          date
          department_id
          egdeid
          line_id
          machineid
          org_id
          product_id
          agrade_production_output_percent
          agrade_production_output_volume
          shiftid
          tenantid
          timestamp
          unit_id
          dept {
            organization_node_name
            organization_structure_id
          }
          org {
            organization_node_name
          }
          plant {
            organization_node_name
          }
        }
    }
  `);
    setPackedProduction(data?.bopp_fl0_packed_production);
    setAGradeProduction(data?.a_grade_production);
  };
  const columnPackedProduction = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machineid",
      text: "machine",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "plant.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "department",
      headerStyle: { width: "500px" },
    },

    {
      dataField: "product_id",
      text: "product id",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "packed_production",
      text: "packed production",
      headerStyle: { width: "500px" },
    },
  ];
  const columns = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "Date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machine_id",
      text: "machine",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "unit.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "jumbo_output_volume",
      text: "Jumbo Output",
      headerStyle: { width: "500px" },
    },
  ];
  const columnsPrimarySlitter = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "Date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "plant.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "product_id",
      text: "product_id",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "total_psslitter_output__volume",
      text: "primary Slitter Output",
      headerStyle: { width: "500px" },
    },
  ];
  const columnSecondarySlitter = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "Date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "unit.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "total_ssslitter_output__volume",
      text: "Secondary Slitter Output",
      headerStyle: { width: "500px" },
    },
  ];
  const columnAgradeProduction = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "ORGANIZATION",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "plant.organization_node_name",
      text: "plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "department id",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machineid",
      text: "machine",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "product_id",
      text: "product id",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "agrade_production_output_percent",
      text: "agrade production output percent",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "agrade_production_output_volume",
      text: "agrade production output volume",
      headerStyle: { width: "500px" },
    },
  ];
  return (
    <>
      <div className="content">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab === "1" ? "active" : ""}
              onClick={() => setActiveTab("1")}
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor: activeTab === "1" ? "#5555e7" : "transparent",
                color: activeTab === "1" ? "white" : "white",
              }}
            >
              Jumbo
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "2" ? "active" : ""}
              onClick={() => setActiveTab("2")}
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor: activeTab === "2" ? "#5555e7" : "transparent",
                color: activeTab === "2" ? "white" : "white",
              }}
            >
              Primary Slitter
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "3" ? "active" : ""}
              onClick={() => setActiveTab("3")}
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor: activeTab === "3" ? "#5555e7" : "transparent",
                color: activeTab === "3" ? "white" : "white",
              }}
            >
              Secondary Slitter
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "4" ? "active" : ""}
              onClick={() => setActiveTab("4")}
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor: activeTab === "4" ? "#5555e7" : "transparent",
                color: activeTab === "4" ? "white" : "white",
              }}
            >
              Packed Production
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "5" ? "active" : ""}
              onClick={() => setActiveTab("5")}
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor: activeTab === "5" ? "#5555e7" : "transparent",
                color: activeTab === "5" ? "white" : "white",
              }}
            >
              A Grade Production
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col>
                <JindalTableComponent
                  columns={columns}
                  title={""}
                  apiData={apiData}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col>
                <JindalTableComponent
                  columns={columnsPrimarySlitter}
                  title={""}
                  apiData={primarySlitter}
                />
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="3">
            <Row>
              <Col>
                <JindalTableComponent
                  columns={columnSecondarySlitter}
                  title={""}
                  apiData={secondarySlitter}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="4">
            <Row>
              <Col>
                <JindalTableComponent
                  columns={columnPackedProduction}
                  title={""}
                  apiData={packedProduction}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="5">
            <Row>
              <Col>
                <JindalTableComponent
                  columns={columnAgradeProduction}
                  title={""}
                  apiData={aGradeProduction}
                />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </>
  );
}

export default ProductionGrids;
