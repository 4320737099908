import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Progress,
} from "reactstrap";
import axios from "axios";
import  { APIURL } from './Apiurl.js';
import { toast } from 'react-toastify';

function JobrolemasterAdd() {
  const [activeStep, setActiveStep] = useState(1);
  const [progress, setProgress] = useState(0);

  const [tenantId, setTenantId] = useState("");
  const [orgmachine, setorgmachine] = useState("");
  const [pname, setpname] = useState("");
  const [pdescript, setpdescript] = useState("");
  const [parameterCategory, setParameterCategory] = useState("");
  const [parameterType, setParameterType] = useState("");

  const [uom, setUOM] = useState("");
  const [rank, setRank] = useState("");
  const [mech, setmech] = useState("");
  const [createdBy, setCreatedBy] = useState("");

 
  const [tenants, setTenants] = useState([]);
 
  const [machines, setMachines] = useState([]);
  const [orgnames, setOrgnames] = useState([]);
  const [parameterCategories, setParameterCategories] = useState([]);
  const [parameterTypes, setParameterTypes] = useState([]);

  useEffect(() => {
    fetchTenants();
    fetchParameterCategories();
    fetchParameterTypes();
    fetchOrgEmpMachine();
    fetchMachine();
    
  }, []);

  const fetchOrgEmpMachine = async () => {
    try {
      const response = await axios.post(APIURL, {
        query: `
          query {
            org_employee_machines {
                org_machine_id
            }
              }
        `,
      });

      setOrgnames(response.data.data.org_employee_machines);
      console.log(response.data.data.org_employee_machines);
    } catch (error) {
      console.error(error);
    }
    
  };
  const fetchTenants = async () => {
    try {
      const response = await axios.post(APIURL, {
        query: `
          query {
            tenants {
              tenant_id
              tenant_name
            }
          }
        `,
      });

      setTenants(response.data.data.tenants);
      console.log(response.data.data.tenants);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchParameterCategories = async () => {
    try {
      const response = await axios.post(APIURL, {
        query: `
          query {
            parameter_categories {
              parameter_category_id
              parameter_category
            }
          }
        `,
      });
      setParameterCategories(response.data.data.parameter_categories);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchParameterTypes = async () => {
    try {
      const response = await axios.post(APIURL, {
        query: `
          query {
            parameter_types {
              parameter_type_id
              parameter_type
            }
          }
        `,
      });
      setParameterTypes(response.data.data.parameter_types);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchMachine = async () => {
    try {
      const response = await axios.post(APIURL, {
        query: `
        query MyQuery {
            machines {
                machine_id
                machine_name
            }
          }
        `,
      });
  
      setMachines(response.data.data.machines); // Make sure 'organization_category' is an array
    } catch (error) {
      console.error(error);
    }
  };
  
 



  const handleNext = () => {
    if (activeStep === 1 && progress === 0) {
      setProgress(50);
    } else if (activeStep === 2 && progress === 50) {
      setProgress(100);
    }
    setActiveStep(activeStep + 1);
  };

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    
    // Create an object with the form data
    const formData = {
        org_machine_id: orgmachine,
        tenant_id: tenantId,
        machine_id: mech,
        organization_id: 1,
        parameter_name: pname,
        parameter_description:pdescript,
        parameter_category:parameterCategory,
        parameter_type:parameterType,
        uom: uom,
        rank: rank,
        created_by: createdBy,
        is_active: "1",
    };
    console.log(formData);
    axios
    .post(APIURL, {
        query: `
            mutation InsertOrganizationMachineParameters($formData: org_machine_parameteres_insert_input!) {
                insert_org_machine_parameteres(objects: [$formData]) {
                affected_rows
                }
            }
        `,
        variables: {
          formData: formData,
        },
      })
      .then((response) => {
        console.log(response); // Handle the response data as needed
        if (response.data.data && response.data.data.insert_org_machine_parameteres.affected_rows > 0) {
            console.log("hello");
            setorgmachine("");
            setTenantId("");
            setmech("");
            setpname("");
            setpdescript("");
            setParameterCategory("");
            setParameterType("");
            setUOM("");
            setRank("");
            setCreatedBy("");
            setProgress(0);
            setActiveStep(1);
          toast.success('Data inserted successfully!');
        } else {
          toast.error('Failed to insert data.');
        }
      })
      .catch((error) => {
        console.error(error); // Handle the error
        toast.error('An error occurred while inserting data.');
      });
    };

  const renderStepContent = () => {
    switch (activeStep) {
        case 1:
          return (
            <>
              <Row>
              <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="orgnodeid">Org Employee Machine</Label>
                  <Input
                    type="select"
                    id="orgnodeid"
                    value={orgmachine}
                    onChange={(e) => {
                        setorgmachine(e.target.value);
                        // handleOrgNodeChange(e); // Call handleOrgNodeChange function here
                      }}    
                  >
                    <option value="">Select Org Employee Machine ID</option>
                    {orgnames.map((orgnode) => (
                      <option
                        key={orgnode.org_machine_id}
                        value={orgnode.org_machine_id}
                      >
                        {orgnode.org_machine_id}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
                <Col className="px-md-1" md="6">
                    <FormGroup>
                    <Label for="tenantId">Tenant ID</Label>
                    <Input
                        type="select"
                        id="tenantId"
                        value={tenantId}
                        onChange={(e) => setTenantId(e.target.value)}
                    >
                        <option value="">Select Tenant ID</option>
                        {tenants.map((tenant) => (
                        <option key={tenant.tenant_id} value={tenant.tenant_id}>
                            {tenant.tenant_name}
                        </option>
                        ))}
                    </Input>
                    </FormGroup>
                </Col>
                
              </Row>
              <Row>
              <Col className="px-md-1" md="6">
                    <FormGroup>
                    <Label for="machine">Machine ID</Label>
                    <Input
                        type="select"
                        id="machine"
                        value={mech}
                        onChange={(e) => setmech(e.target.value)}
                    >
                        <option value="">Select machine</option>
                        {machines.map((machine) => (
                        <option key={machine.machine_id} value={machine.machine_id}>
                            {machine.machine_name}
                        </option>
                        ))}
                    </Input>
                    </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                    <FormGroup>
                        <label>Organization ID</label>
                        <Input
                            type="select"
                            id="orgtype"
                            // value={prefix}
                            disabled
                            // onChange={(e) => setprefix(e.target.value)}
                        >
                            <option value="">Select Org</option>
                            <option value="Dr.">Dr.</option>
                            <option value="Mr.">Mr.</option>
                            <option value="Mrs.">Mrs.</option>
                            <option value="Ms.">Ms.</option>
                        </Input>
                    </FormGroup>
                    </Col>
              </Row>
             
            </>
          );
        case 2:
          return (
            <>
             <Row>
             <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="city_id">Parameter Name</Label>
                  <Input
                    placeholder="Parameter Name"
                    type="text"
                    value={pname}
                    onChange={(e) => setpname(e.target.value)}
                  />
                </FormGroup>
              </Col>
              
              <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="city_id">Parameter Description</Label>
                  <Input
                    placeholder="Parameter Description"
                    type="text"
                    value={pdescript}
                    onChange={(e) => setpdescript(e.target.value)}
                  />
                </FormGroup>
              </Col>
              
             </Row>
             <Row>
                <Col className="pr-md-1" md="6">
                <FormGroup>
                    <Label for="parameterCategory">Parameter Category</Label>
                    <Input
                    type="select"
                    name="parameterCategory"
                    id="parameterCategory"
                    value={parameterCategory}
                    onChange={(e) => setParameterCategory(e.target.value)}
                    >
                    <option value="">Select Parameter Category</option>
                    {parameterCategories.map((category) => (
                        <option key={category.parameter_category_id} value={category.parameter_category_id}>
                        {category.parameter_category}
                        </option>
                    ))}
                    </Input>
                </FormGroup>
                </Col>
                <Col className="pl-md-1" md="6">
                <FormGroup>
                    <label>Parameter Type</label>
                    <Input
                      type="select"
                      value={parameterType}
                      onChange={(e) => setParameterType(e.target.value)}
                    >
                      <option value="">Select Parameter Type</option>
                      {parameterTypes.map((type) => (
                        <option key={type.parameter_type_id} value={type.parameter_type_id}>
                          {type.parameter_type}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            
            </>
          );
        case 3:
          return (
            <>
            <Row>
              <Col className="px-md-1" md="6">
                <FormGroup>
                    <Label for="uom">UOM</Label>
                    <Input
                    type="number"
                    name="uom"
                    id="uom"
                    value={uom}
                    onChange={(e) => setUOM(e.target.value)}
                    />
                </FormGroup>
                </Col>
                <Col className="pr-md-1" md="6">
                  <FormGroup>
                  <Label for="rank">Rank</Label>
                    <Input
                    type="number"
                    name="rank"
                    id="rank"
                    value={rank}
                    onChange={(e) => setRank(e.target.value)}
                    />
                </FormGroup>
                </Col>
               
              </Row>
            <Row>
             
              <Col className="px-md-1" md="6">
                  <FormGroup>
                    <label>Created By</label>
                    <Input
                      placeholder="Created By"
                      type="text"
                      value={createdBy}
                      onChange={(e) => setCreatedBy(e.target.value)}
                    />
                  </FormGroup>
                </Col>
            </Row>
            </>
          );
        default:
          return null;
      }
  };
  const calculateProgress = () => {
    return Math.round(((activeStep - 1) / 2) * 100); // Update the denominator to match the total number of steps minus one
  };
  

  const renderProgressBarDestinations = () => {
    const step1Class = activeStep >= 1 ? "progress-destination-active" : "";
    const step2Class = activeStep >= 2 ? "progress-destination-active" : "";
    const step3Class = activeStep >= 3 ? "progress-destination-active" : "";
    const step4Class = activeStep >= 4 ? "progress-destination-active" : "";
  
    return (
      <div className="progress-destinations">
        <div className={`progress-destination ${step1Class}`}>
          <span>1</span>
        </div>
        <div className={`progress-destination ${step2Class}`}>
          <span>2</span>
        </div>
        <div className={`progress-destination ${step3Class}`}>
          <span>3</span>
        </div>
        <div className={`progress-destination ${step4Class}`}>
          <span>4</span>
        </div>
      </div>
    );
  };


  return (
    <div className="content">
      <Row>
        <Col md="8">
          <div className="progress-container">
            {renderProgressBarDestinations()}
            <Progress
              max="100"
              value={calculateProgress()}
              className="progress-bar-sm progress-bar-striped"
            />
          </div>
        </Col>
      </Row>
      <Row className="progress-row">
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Organization Machine Parameter</h5>
            </CardHeader>
            <CardBody>
              <Form>{renderStepContent()}</Form>
            </CardBody>
            <CardFooter>
              <div className="text-right">
                {activeStep > 1 && (
                  <Button color="primary" onClick={handlePrevious}>
                    Previous
                  </Button>
                )}
                {activeStep < 3 && (
                  <Button color="primary" onClick={handleNext}>
                    Next
                  </Button>
                )}
                {activeStep === 3 && (
                  <Button color="success" onClick={handleFormSubmit}>
                    Submit
                  </Button>
                )}
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );

}

export default JobrolemasterAdd;
