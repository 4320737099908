// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .grid-item {
    border: 2px solid #ccc; 
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    cursor: pointer;
  }
  
  .grid-item.active {
    border-color: #007bff; 
    background-color: #e3f2fd;
  }
  .nav-tabs {
    border-bottom: 0.0625rem solid #e7e1e1;
    border-block-color: white;
    width: 100%;
    border-top: 0.0625rem solid #e7e1e1;
    /* border-block: 58.0625rem solid #e7e1e1; */
    /* border-left: 0.0625rem solid #e7e1e1;
    border-right: 0.0625rem solid #e7e1e1; */
    /* width: fit-content; */

/* }   */
/* .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  border-block-start: unset;
  color: #525f7f;
  background-color: #1e1e2f;
  border-color: #e3e3e3 #e3e3e3 #1e1e2f;
} */ 

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  background-color: #1d253b;
  color: #edeff6 !important;
  cursor: not-allowed;
}
.card-user .avatar {
  width: 150px !important;
  height: 150px !important;
  border: 5px solid #2b3553;
  border-bottom-color: transparent;
  background-color: transparent;
  position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/components/EmployeeJD/profile.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;gDAiBgD;IAC5C;4CACwC;IACxC,wBAAwB;;AAE5B,QAAQ;AACR;;;;;GAKG;;AAEH;EACE,yBAAyB;EACzB,yBAAyB;EACzB,mBAAmB;AACrB;AACA;EACE,uBAAuB;EACvB,wBAAwB;EACxB,yBAAyB;EACzB,gCAAgC;EAChC,6BAA6B;EAC7B,kBAAkB;AACpB","sourcesContent":["/* .grid-item {\n    border: 2px solid #ccc; \n    border-radius: 5px;\n    padding: 10px;\n    margin: 5px;\n    cursor: pointer;\n  }\n  \n  .grid-item.active {\n    border-color: #007bff; \n    background-color: #e3f2fd;\n  }\n  .nav-tabs {\n    border-bottom: 0.0625rem solid #e7e1e1;\n    border-block-color: white;\n    width: 100%;\n    border-top: 0.0625rem solid #e7e1e1;\n    /* border-block: 58.0625rem solid #e7e1e1; */\n    /* border-left: 0.0625rem solid #e7e1e1;\n    border-right: 0.0625rem solid #e7e1e1; */\n    /* width: fit-content; */\n\n/* }   */\n/* .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {\n  border-block-start: unset;\n  color: #525f7f;\n  background-color: #1e1e2f;\n  border-color: #e3e3e3 #e3e3e3 #1e1e2f;\n} */ \n\n.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {\n  background-color: #1d253b;\n  color: #edeff6 !important;\n  cursor: not-allowed;\n}\n.card-user .avatar {\n  width: 150px !important;\n  height: 150px !important;\n  border: 5px solid #2b3553;\n  border-bottom-color: transparent;\n  background-color: transparent;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
