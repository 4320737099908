import React, { useState, useEffect } from "react";
// import "./style.css";
// import "./responsive.css";
// import "./progress.css";
// import "./gauge.css";
import {
  Card,
  Row,
  Col,
} from "reactstrap";
import { TabContent, TabPane, Nav, NavItem } from "reactstrap";
import "react-circular-progressbar/dist/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavLink } from "react-router-dom";
import redirectToLoginIfTokenMissing from "views/TokenUtility.js";
import axios from "axios";
import { APIURL } from "views/Apiurl";
import { QueryApi } from "QueryApi";
import classnames from "classnames";
import ProductivityTime from "components/New Productivity/Time";
import Production from "components/New Productivity/Production";
import Performance from "components/New Productivity/Performance";
import ProductivityWorkForce from "components/New Productivity/prodWorkforce";
// import WorkforceHome from "./WorkforceEfficiency";
import JindalProduction from "./JinalProduction";

function JindalProductivity(props) {
  const [activeTab, setActiveTab] = useState("1");
  const storedTenantId = sessionStorage.getItem("selectedTenantId");
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  useEffect(() => {
    redirectToLoginIfTokenMissing(); // Call the utility function to validate the token
    // Your component's logic...
  }, []);
  const [org, setorg] = useState("");
  const [orgnames, setOrgnames] = useState([]);

  const [unit, setunit] = useState("");

  const [dept, setdept] = useState("");
  const [depts, setdepts] = useState([]);

  const [plants, setplants] = useState([]);
  const [orgs, setorgs] = useState([]);
  const [otherData, setOtherData] = useState([]);
  const [selectedOrgData, setSelectedOrgData] = useState(null);
  const token = sessionStorage.getItem("token");
  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const tenantId =
    decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
  useEffect(() => {
    fetchplant();
  }, [org]);

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const currDay = day < 10 ? `0${day}` : day;
  const currMonth = month < 10 ? `0${month}` : month;
  let currentDate = JSON.stringify(`${year}-${currMonth}-${currDay}`);

  const dateData = new Date();
  dateData.setDate(dateData.getDate() - 7);
  var dayDataValue = dateData.getDate();
  var monthDataValue = dateData.getMonth() + 1;
  var yearDataValue = dateData.getFullYear();
  var formattedDay = dayDataValue < 10 ? `0${dayDataValue}` : dayDataValue;
  var formattedMonth =
    monthDataValue < 10 ? `0${monthDataValue}` : monthDataValue;
  var weekDataDate = JSON.stringify(
    `${yearDataValue}-${formattedMonth}-${formattedDay}`
  );

  const previousDate = new Date();
  previousDate.setDate(1);
  const previousDay = previousDate.getDate();
  const previousMonth = previousDate.getMonth() + 1;
  const previousYear = previousDate.getFullYear();
  const formattedPreviousDay =
    previousDay < 10 ? `0${previousDay}` : previousDay;
  const formattedPreviousMonth =
    previousMonth < 10 ? `0${previousMonth}` : previousMonth;
  const previousMonthDate = JSON.stringify(
    `${previousYear}-${formattedPreviousMonth}-${formattedPreviousDay}`
  );

  const yearCurrentDate = new Date();
  yearCurrentDate.setDate(1);
  yearCurrentDate.getMonth(1);
  const yearDay = yearCurrentDate.getDate();
  const yearMonth = yearCurrentDate.getMonth();
  const yearYear = yearCurrentDate.getFullYear();
  const formattedYearDay = yearDay < 10 ? `0${yearDay}` : yearDay;
  const formattedYearMonth = yearMonth < 10 ? `0${yearMonth}` : yearMonth;
  const yearPreviousMonthDate = JSON.stringify(
    `${yearYear}-${formattedYearMonth}-${formattedYearDay}`
  );

  const fetchorg = async (logintenantId) => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
          query MyQuery($logintenantId: Int!) {
            tnt_organization_structure(
              where: {
                parent_organization_id: { _eq: 0 },
                tenantid: { _eq: $logintenantId }
              }
            ) {
              organization_structure_id
              organization_node_name
            }
          }
        `,
          variables: {
            logintenantId: parseInt(logintenantId),
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      const fetchedOrgs = response.data.data.tnt_organization_structure;
      setorgs(fetchedOrgs);
      console.log(fetchedOrgs);
      const parentIds = fetchedOrgs.map((org) => org.organization_structure_id);
      console.log(parentIds);

      const childResponse = await axios.post(
        APIURL,
        {
          query: `
          query MyQuery($parentIds: [Int!]) {
            tnt_organization_structure(
              where: {
                parent_organization_id: { _in: $parentIds }
              }
            ) {
              organization_structure_id
              organization_node_name
            }
          }
        `,
          variables: {
            parentIds: parentIds,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      const childOrgs = childResponse.data.data.tnt_organization_structure;
      console.log(childOrgs);
      const updatedOtherData = otherData.map((row) => {
        const matchingOrg = childOrgs.find(
          (org) => org.organization_structure_id === row.parent_organization_id
        );

        if (matchingOrg) {
          return {
            ...row,
            parent_organization_id: matchingOrg.organization_structure_id,
          };
        }

        return row;
      });

      setOtherData(childOrgs);
      console.log(otherData);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["sub"];
    let logintenantId = null;

    if (roleId === 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
    }

    fetchorg(logintenantId);
  }, []);
  const fetchplant = async () => {
    if (org) {
      // Only fetch if org is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
            query MyQuery($p: Int!) {
              tnt_organization_structure(where: {parent_organization_id: {_eq: $p}}) {
                organization_structure_id
                organization_node_name
              }
            }
          `,
            variables: {
              p: org,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        // console.log(org);
        setplants(response.data.data.tnt_organization_structure);
        console.log(response.data.data.tnt_organization_structure);
      } catch (error) {
        console.error(error);
      }
    }
  };
  useEffect(() => {
    fetchdept();
  }, [unit]);
  const fetchdept = async () => {
    if (unit) {
      // Only fetch if org is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
            query MyQuery($d: Int!) {
              tnt_organization_structure(where: {parent_organization_id: {_eq: $d}}) {
                organization_structure_id
                organization_node_name
              }
            }
          `,
            variables: {
              d: unit,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        // console.log(org);
        setdepts(response.data.data.tnt_organization_structure);
        console.log(response.data.data.tnt_organization_structure);
      } catch (error) {
        console.error(error);
      }
    }
  };
  console.log(process.env.REACT_APP_X_API_SECRET_KEY);

  const handleOrgSelect = async (selectedOrgId) => {
    if (selectedOrgId) {
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
            query GetCostCenter($orgId: Int!) {
              tnt_organization_structure(where: { organization_structure_id: { _eq: $orgId } }) {
                cost_centre_code
              }
            }
          `,
            variables: {
              orgId: selectedOrgId,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );

        if (
          response.data &&
          response.data.data &&
          response.data.data.tnt_organization_structure &&
          response.data.data.tnt_organization_structure.length > 0
        ) {
          setSelectedOrgData(response.data.data.tnt_organization_structure[0]);
        } else {
          console.log(response);
          setSelectedOrgData(null);
        }
      } catch (error) {
        console.error("Error fetching organization data:", error);
      }
    } else {
      setSelectedOrgData(null);
    }

    setorg(selectedOrgId);
  };

  const darkColor = "rgba(0, 0, 0, 0.8)";
  const lightColor = "rgba(0, 0, 0, 0.1)";

  const gradientSteps = 10; // Adjust the number of steps for the shading effect
  const colorStops = [];
  for (let i = 0; i <= gradientSteps; i++) {
    const intensity = i / gradientSteps;
    const r = Math.round(
      intensity * parseInt(lightColor.substring(4, 7)) +
        (1 - intensity) * parseInt(darkColor.substring(4, 7))
    );
    const g = Math.round(
      intensity * parseInt(lightColor.substring(9, 12)) +
        (1 - intensity) * parseInt(darkColor.substring(9, 12))
    );
    const b = Math.round(
      intensity * parseInt(lightColor.substring(14, 17)) +
        (1 - intensity) * parseInt(darkColor.substring(14, 17))
    );
    const colorStop = `rgb(${r}, ${g}, ${b})`;
    colorStops.push(colorStop);
  }
  const gradientColor = `linear-gradient(to right, ${colorStops.join(", ")})`;

  //////Target Adherence ////////////////////////////////////////////////////////////////////////

  const [dayData, setDayData] = useState({
    details: "This Day",
    target: "No Data",
    achieved: "No Data",
    targetValue: "No Data",
  });
  useEffect(() => {
    targetAdherenceDay();
  }, []);
  const targetAdherenceDay = async () => {
    try {
      const data = await QueryApi(`
        query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
            dm_daily(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
            target_parts
            total_parts_produced
            target_adherence
            }
        }
        `);
      const targetPartsSum = data.dm_daily.reduce(
        (acc, curr) => acc + curr.target_parts,
        0
      );
      const partsProducedSum = data.dm_daily.reduce(
        (acc, curr) => acc + curr.total_parts_produced,
        0
      );
      const targetAdherenceSum = data.dm_daily.reduce(
        (acc, curr) => acc + curr.target_adherence,
        0
      );
      setDayData({
        details: "This Day",
        target: targetPartsSum,
        achieved: partsProducedSum,
        targetValue: targetAdherenceSum,
      });
    } catch (error) {
      setDayData({
        details: "This Day",
        target: "No Data",
        achieved: "No Data",
        targetValue: "No Data",
      });
    }
  };

  const [weekData, setWeekData] = useState({
    details: "This Week",
    target: "No Data",
    achieved: "No Data",
    targetValue: "No Data",
  });
  useEffect(() => {
    targetAdherenceWeek();
  }, []);
  const targetAdherenceWeek = async () => {
    try {
      const data = await QueryApi(`
        query MyQuery($start_date: date = ${weekDataDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
            dm_weekly(where: {start_date: {_eq: $start_date}, end_date: {_eq: $end_date}, tenantid: {_eq: $tenantid}}) {
                target_adherence
                target_parts
                total_parts_produced
            }
            }
        `);
      const targetPartsSumweekly = data.dm_weekly.reduce(
        (acc, curr) => acc + curr.target_parts,
        0
      );
      const partsProducedSumweekly = data.dm_weekly.reduce(
        (acc, curr) => acc + curr.total_parts_produced,
        0
      );
      const targetAdherenceSumweekly = data.dm_weekly.reduce(
        (acc, curr) => acc + curr.target_adherence,
        0
      );
      setWeekData({
        details: "This Week",
        target: targetPartsSumweekly,
        achieved: partsProducedSumweekly,
        targetValue: targetAdherenceSumweekly,
      });
    } catch (error) {
      setWeekData({
        details: "This Week",
        target: "No Data",
        achieved: "No Data",
        targetValue: "No Data",
      });
    }
  };

  const [monthData, setMonthData] = useState({
    details: "This Month",
    target: "No Data",
    achieved: "No Data",
    targetValue: "No Data",
  });
  useEffect(() => {
    targetAdherenceMonth();
  }, []);
  const targetAdherenceMonth = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${previousMonthDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
                dm_monthly(where: {start_date: {_eq: $start_date}, end_date: {_eq: $end_date}, tenantid: {_eq: $tenantid}}) {
                  total_parts_produced
                  target_adherence
                  target_parts
                }
              }
        `);
      const targetPartsSumMonthly = data.dm_monthly.reduce(
        (acc, curr) => acc + curr.target_parts,
        0
      );
      const partsProducedSumMonthly = data.dm_monthly.reduce(
        (acc, curr) => acc + curr.total_parts_produced,
        0
      );
      const targetAdherenceSumMonthly = data.dm_monthly.reduce(
        (acc, curr) => acc + curr.target_adherence,
        0
      );
      setMonthData({
        details: "This Month",
        target: targetPartsSumMonthly,
        achieved: partsProducedSumMonthly,
        targetValue: targetAdherenceSumMonthly,
      });
    } catch (error) {
      setMonthData({
        details: "This Month",
        target: "No Data",
        achieved: "No Data",
        targetValue: "No Data",
      });
    }
  };

  const [yearData, setYearData] = useState({
    details: "Year Till Date",
    target: "No Data",
    achieved: "No Data",
    targetValue: "No Data",
  });
  useEffect(() => {
    targetAdherenceYear();
  }, []);
  const targetAdherenceYear = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${yearPreviousMonthDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
                dm_yearly(where: {start_date: {_eq: $start_date}, end_date: {_eq: $end_date}, tenantid: {_eq: $tenantid}}) {
                  target_adherence
                  target_parts
                  total_parts_produced
                }
              }
        `);
      const targetPartsSumYearly = data.dm_yearly.reduce(
        (acc, curr) => acc + curr.target_parts,
        0
      );
      const partsProducedSumYearly = data.dm_yearly.reduce(
        (acc, curr) => acc + curr.total_parts_produced,
        0
      );
      const targetAdherenceSumYearly = data.dm_yearly.reduce(
        (acc, curr) => acc + curr.target_adherence,
        0
      );
      setYearData({
        details: "Year Till Date",
        target: targetPartsSumYearly,
        achieved: partsProducedSumYearly,
        targetValue: targetAdherenceSumYearly,
      });
    } catch (error) {
      setYearData({
        details: "Year Till Date",
        target: "No Data",
        achieved: "No Data",
        targetValue: "No Data",
      });
    }
  };

  /////////Plant Operating Time  //  Operating Time Deviation//////////////////////////////////////////////////////////////////

  const [PlantOperatingTimeShiftData, setPlantOperatingTimeShiftData] =
    useState([]);
  useEffect(() => {
    PlantOperatingTimeShift();
  }, []);
  const PlantOperatingTimeShift = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = 15, $end_date: date = ${currentDate}) {
                dm_shiftwise(where: {date: {_gte: $start_date, _lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                  plant_operating_time_percent
                  timestamp
                }
              }
        `);
      if (data === undefined || data.dm_shiftwise === null) {
        setPlantOperatingTimeShiftData([
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ]);
      } else {
        const PlantOperatingTimeShift = [
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ];

        data.dm_shiftwise.map((data) => {
          var shiftDate = new Date(data.timestamp);
          var HoursshiftDate = shiftDate.getHours();
          if (HoursshiftDate >= 8 && HoursshiftDate <= 16) {
            PlantOperatingTimeShift[0] = {
              name: "Shift 1",
              dataValue: data.plant_operating_time_percent,
            };
          } else if (HoursshiftDate >= 16 && HoursshiftDate <= 24) {
            PlantOperatingTimeShift[1] = {
              name: "Shift 2",
              dataValue: data.plant_operating_time_percent,
            };
          } else if (HoursshiftDate >= 0 && HoursshiftDate <= 8) {
            PlantOperatingTimeShift[2] = {
              name: "Shift 3",
              dataValue: data.plant_operating_time_percent,
            };
          }
        });
        setPlantOperatingTimeShiftData(PlantOperatingTimeShift);
      }
    } catch (error) {
      console.log(error);
      setPlantOperatingTimeShiftData([
        {
          name: "shift 1",
          dataValue: "0",
        },
        {
          name: "shift 2",
          dataValue: "0",
        },
        {
          name: "shift 3",
          dataValue: "0",
        },
      ]);
    }
  };

  const [shiftPercentageList, setShiftPercentageList] = useState([
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
  ]);

  const [OperatingTimeDeviationList, setOperatingTimeDeviationList] = useState([
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
  ]);

  const [plantOperatingTimeData, setPlantOperatingTimeData] = useState(0);
  useEffect(() => {
    plantOperatingTime();
  }, []);
  const plantOperatingTime = async () => {
    try {
      const data = await QueryApi(`
              query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                  aggregate {
                    avg {
                      plant_operating_time_percent
                    }
                  }
                }
              }
        `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent ===
          null
      ) {
        setPlantOperatingTimeData("No Data");
      } else {
        setPlantOperatingTimeData(
          data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent.toFixed(
            1
          )
        );
      }
    } catch (error) {
      console.log(error);
      setPlantOperatingTimeData("No Data");
    }
  };

  const [xAxisDataValue, setXAxisDataValue] = useState([]);
  const [plotPoint, setPlotPoint] = useState([]);
  const dataset = [
    {
      label: "Plant Operating Time",
      data: plotPoint,
      borderColor: "#1F8EF1",
    },
  ];
  useEffect(() => {
    GraphDataList();
  }, []);
  const GraphDataList = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
                dm_foundation(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                  plant_operating_time_percent
                  timestamp
                }
              }
        `);
      const plotAllData = data.dm_foundation.map((data) => {
        const objData = {
          point: data.plant_operating_time_percent,
          Xaxis: data.timestamp,
        };
        return objData;
      });
      const plotPointData = plotAllData.map((data) => data.point);
      setPlotPoint(plotPointData);

      const xAxisPointData = plotAllData.map((data) => {
        var d = new Date(data.Xaxis);
        var h = d.getHours();
        var m = "0" + d.getMinutes();
        var ampm = h >= 12 ? "PM" : "AM";
        h = h % 12;
        h = h ? h : 12;
        var fullTime = h + ":" + m.substr(-2) + " " + ampm;
        return fullTime;
      });
      setXAxisDataValue(xAxisPointData);
    } catch (error) {
      console.log(error);
    }
  };

  const [xAxisDataValueOperatingTime, setXAxisDataValueOperatingTime] =
    useState([]);
  const [plotPointOperatingTime, setPlotPointOperatingTime] = useState([]);
  const datasetOperatingTime = [
    {
      label: "Operating Time Deviation",
      data: plotPointOperatingTime,
      borderColor: "#1F8EF1",
    },
  ];
  useEffect(() => {
    GraphDataListOperatingTime();
  }, []);
  const GraphDataListOperatingTime = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
                dm_foundation(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                  plant_operating_time_percent
                  timestamp
                }
              }
        `);
      const plotAllDataOperatingTime = data.dm_foundation.map((data) => {
        const objData = {
          point: data.plant_operating_time_percent,
          Xaxis: data.timestamp,
        };
        return objData;
      });

      const plotPointDataOperatingTime = plotAllDataOperatingTime.map(
        (data) => 100 - data.point
      );
      setPlotPointOperatingTime(plotPointDataOperatingTime);

      const xAxisPointDataOperatingTime = plotAllDataOperatingTime.map(
        (data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
          var m = "0" + d.getMinutes();
          var ampm = h >= 12 ? "PM" : "AM";
          h = h % 12;
          h = h ? h : 12;
          var fullTime = h + ":" + m.substr(-2) + " " + ampm;
          return fullTime;
        }
      );
      setXAxisDataValueOperatingTime(xAxisPointDataOperatingTime);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    DailyDataFun();
  }, []);
  const DailyDataFun = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}) {
                dm_foundation_aggregate(where: {date: {_gte: $date}, tenantid: {_eq: $tenantid}}) {
                  aggregate {
                    avg {
                      plant_operating_time_percent
                    }
                    max {
                      plant_operating_time_percent
                    }
                    min {
                      plant_operating_time_percent
                    }
                  }
                }
              }
           `);

      if (data !== undefined) {
        setShiftPercentageList((prevData) => {
          const newarr = [...prevData];
          newarr[0] = {
            percentage:
              data.dm_foundation_aggregate.aggregate.min
                .plant_operating_time_percent !== null
                ? data.dm_foundation_aggregate.aggregate.min.plant_operating_time_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[4] = {
            percentage:
              data.dm_foundation_aggregate.aggregate.avg
                .plant_operating_time_percent !== null
                ? data.dm_foundation_aggregate.aggregate.avg.plant_operating_time_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[8] = {
            percentage:
              data.dm_foundation_aggregate.aggregate.max
                .plant_operating_time_percent !== null
                ? data.dm_foundation_aggregate.aggregate.max.plant_operating_time_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });

        setOperatingTimeDeviationList((prevData) => {
          const newarr = [...prevData];
          newarr[0] = {
            percentage:
              data.dm_foundation_aggregate.aggregate.min
                .plant_operating_time_percent !== null
                ? 100 -
                  data.dm_foundation_aggregate.aggregate.min.plant_operating_time_percent.toFixed(
                    0
                  )
                : "No Data",
          };
          newarr[4] = {
            percentage:
              data.dm_foundation_aggregate.aggregate.avg
                .plant_operating_time_percent !== null
                ? 100 -
                  data.dm_foundation_aggregate.aggregate.avg.plant_operating_time_percent.toFixed(
                    0
                  )
                : "No Data",
          };
          newarr[8] = {
            percentage:
              data.dm_foundation_aggregate.aggregate.max
                .plant_operating_time_percent !== null
                ? 100 -
                  data.dm_foundation_aggregate.aggregate.max.plant_operating_time_percent.toFixed(
                    0
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setShiftPercentageList((prevData) => {
        const newarr = [...prevData];
        newarr[0] = { percentage: "No Data" };
        newarr[4] = { percentage: "No Data" };
        newarr[8] = { percentage: "No Data" };
        return newarr;
      });
      setOperatingTimeDeviationList((prevData) => {
        const newarr = [...prevData];
        newarr[0] = { percentage: "No Data" };
        newarr[4] = { percentage: "No Data" };
        newarr[8] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    WeeklyDataFun();
  }, []);
  const WeeklyDataFun = async () => {
    try {
      const data = await QueryApi(`
              query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                  aggregate {
                    avg {
                      plant_operating_time_percent
                    }
                    max {
                      plant_operating_time_percent
                    }
                    min {
                      plant_operating_time_percent
                    }
                  }
                }
              }
        `);

      if (data !== undefined) {
        setShiftPercentageList((prevData) => {
          const newarr = [...prevData];
          newarr[1] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min
                .plant_operating_time_percent !== null
                ? data.dm_daily_aggregate.aggregate.min.plant_operating_time_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[5] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg
                .plant_operating_time_percent !== null
                ? data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[9] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max
                .plant_operating_time_percent !== null
                ? data.dm_daily_aggregate.aggregate.max.plant_operating_time_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
        setOperatingTimeDeviationList((prevData) => {
          const newarr = [...prevData];
          newarr[1] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min
                .plant_operating_time_percent !== null
                ? 100 -
                  data.dm_daily_aggregate.aggregate.min.plant_operating_time_percent.toFixed(
                    0
                  )
                : "No Data",
          };
          newarr[5] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg
                .plant_operating_time_percent !== null
                ? 100 -
                  data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent.toFixed(
                    0
                  )
                : "No Data",
          };
          newarr[9] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max
                .plant_operating_time_percent !== null
                ? 100 -
                  data.dm_daily_aggregate.aggregate.max.plant_operating_time_percent.toFixed(
                    0
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setShiftPercentageList((prevData) => {
        const newarr = [...prevData];
        newarr[1] = { percentage: "No Data" };
        newarr[5] = { percentage: "No Data" };
        newarr[9] = { percentage: "No Data" };
        return newarr;
      });
      setOperatingTimeDeviationList((prevData) => {
        const newarr = [...prevData];
        newarr[1] = { percentage: "No Data" };
        newarr[5] = { percentage: "No Data" };
        newarr[9] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    MonthlyDataFun();
  }, []);
  const MonthlyDataFun = async () => {
    try {
      const data = await QueryApi(`
              query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                  aggregate {
                    avg {
                      plant_operating_time_percent
                    }
                    max {
                      plant_operating_time_percent
                    }
                    min {
                      plant_operating_time_percent
                    }
                  }
                }
              }
        `);
      if (data !== undefined) {
        setShiftPercentageList((prevData) => {
          const newarr = [...prevData];
          newarr[2] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min
                .plant_operating_time_percent !== null
                ? data.dm_daily_aggregate.aggregate.min.plant_operating_time_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[6] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg
                .plant_operating_time_percent !== null
                ? data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[10] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max
                .plant_operating_time_percent !== null
                ? data.dm_daily_aggregate.aggregate.max.plant_operating_time_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
        setOperatingTimeDeviationList((prevData) => {
          const newarr = [...prevData];
          newarr[2] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min
                .plant_operating_time_percent !== null
                ? 100 -
                  data.dm_daily_aggregate.aggregate.min.plant_operating_time_percent.toFixed(
                    0
                  )
                : "No Data",
          };
          newarr[6] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg
                .plant_operating_time_percent !== null
                ? 100 -
                  data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent.toFixed(
                    0
                  )
                : "No Data",
          };
          newarr[10] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max
                .plant_operating_time_percent !== null
                ? 100 -
                  data.dm_daily_aggregate.aggregate.max.plant_operating_time_percent.toFixed(
                    0
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setShiftPercentageList((prevData) => {
        const newarr = [...prevData];
        newarr[2] = { percentage: "No Data" };
        newarr[6] = { percentage: "No Data" };
        newarr[10] = { percentage: "No Data" };
        return newarr;
      });
      setOperatingTimeDeviationList((prevData) => {
        const newarr = [...prevData];
        newarr[2] = { percentage: "No Data" };
        newarr[6] = { percentage: "No Data" };
        newarr[10] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    YearlyDataFun();
  }, []);
  const YearlyDataFun = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                  aggregate {
                    avg {
                      plant_operating_time_percent
                    }
                    max {
                      plant_operating_time_percent
                    }
                    min {
                      plant_operating_time_percent
                    }
                  }
                }
              }
        `);
      if (data !== undefined) {
        setShiftPercentageList((prevData) => {
          const newarr = [...prevData];
          newarr[3] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min
                .plant_operating_time_percent !== null
                ? data.dm_daily_aggregate.aggregate.min.plant_operating_time_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[7] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg
                .plant_operating_time_percent !== null
                ? data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[11] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max
                .plant_operating_time_percent !== null
                ? data.dm_daily_aggregate.aggregate.max.plant_operating_time_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
        setOperatingTimeDeviationList((prevData) => {
          const newarr = [...prevData];
          newarr[3] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min
                .plant_operating_time_percent !== null
                ? 100 -
                  data.dm_daily_aggregate.aggregate.min.plant_operating_time_percent.toFixed(
                    0
                  )
                : "No Data",
          };
          newarr[7] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg
                .plant_operating_time_percent !== null
                ? 100 -
                  data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent.toFixed(
                    0
                  )
                : "No Data",
          };
          newarr[11] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max
                .plant_operating_time_percent !== null
                ? 100 -
                  data.dm_daily_aggregate.aggregate.max.plant_operating_time_percent.toFixed(
                    0
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setShiftPercentageList((prevData) => {
        const newarr = [...prevData];
        newarr[3] = { percentage: "No Data" };
        newarr[7] = { percentage: "No Data" };
        newarr[11] = { percentage: "No Data" };
        return newarr;
      });
      setOperatingTimeDeviationList((prevData) => {
        const newarr = [...prevData];
        newarr[3] = { percentage: "No Data" };
        newarr[7] = { percentage: "No Data" };
        newarr[11] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  /////// Production Time // Avaliability ///////////////////////////////////////////////////////////

  const [productionTimeShiftData, setproductionTimeShiftData] = useState([]);
  useEffect(() => {
    productionTimeShift();
  }, []);
  const productionTimeShift = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = 15, $end_date: date = ${currentDate}) {
                dm_shiftwise(where: {date: {_gte: $start_date, _lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                  machine_availability_percent
                  timestamp
                }
              }
        `);
      if (data === undefined || data.dm_shiftwise === null) {
        setproductionTimeShiftData([
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ]);
      } else {
        const productionTimeShift = [
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ];

        data.dm_shiftwise.map((data) => {
          var shiftDate = new Date(data.timestamp);
          var HoursshiftDate = shiftDate.getHours();
          if (HoursshiftDate >= 8 && HoursshiftDate <= 16) {
            productionTimeShift[0] = {
              name: "Shift 1",
              dataValue: data.machine_availability_percent,
            };
          } else if (HoursshiftDate >= 16 && HoursshiftDate <= 24) {
            productionTimeShift[1] = {
              name: "Shift 2",
              dataValue: data.machine_availability_percent,
            };
          } else if (HoursshiftDate >= 0 && HoursshiftDate <= 8) {
            productionTimeShift[2] = {
              name: "Shift 3",
              dataValue: data.machine_availability_percent,
            };
          }
        });
        setproductionTimeShiftData(productionTimeShift);
      }
    } catch (error) {
      console.log(error);
      setproductionTimeShiftData([
        {
          name: "shift 1",
          dataValue: "0",
        },
        {
          name: "shift 2",
          dataValue: "0",
        },
        {
          name: "shift 3",
          dataValue: "0",
        },
      ]);
    }
  };

  const [productionTimeData, setProductionTimeData] = useState(0);
  useEffect(() => {
    productionTime();
  }, []);
  const productionTime = async () => {
    try {
      const data = await QueryApi(`
              query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                  aggregate {
                    avg {
                        machine_availability_percent
                    }
                  }
                }
              }
        `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.avg.machine_availability_percent ===
          null
      ) {
        setProductionTimeData("No Data");
      } else {
        setProductionTimeData(
          data.dm_daily_aggregate.aggregate.avg.machine_availability_percent.toFixed(
            1
          )
        );
      }
      // if (data != undefined) {
      //     const valueData = data.dm_daily_aggregate.aggregate.avg.machine_availability_percent !== null ? data.dm_daily_aggregate.aggregate.avg.machine_availability_percent.toFixed(1) : "No Data"
      //     setProductionTimeData(valueData);
      // }
    } catch (error) {
      console.log(error);
      setProductionTimeData("No Data");
    }
  };

  const [xAxisDataValueProductionTime, setXAxisDataValueProductionTime] =
    useState([]);
  const [plotPointProductionTime, setPlotPointProductionTime] = useState([]);
  const datasetProductionTime = [
    {
      label: "Production Time",
      data: plotPointProductionTime,
      borderColor: "#1F8EF1",
    },
  ];

  useEffect(() => {
    GraphDataListProductionTime();
  }, []);
  const GraphDataListProductionTime = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
                dm_foundation(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                    machine_availability_percent
                  timestamp
                }
              }
        `);
      const plotAllDataProductionTime = data.dm_foundation.map((data) => {
        const objData = {
          point: data.machine_availability_percent,
          Xaxis: data.timestamp,
        };
        return objData;
      });

      const plotPointDataProductionTime = plotAllDataProductionTime.map(
        (data) => data.point
      );
      setPlotPointProductionTime(plotPointDataProductionTime);

      const xAxisPointDataProductionTime = plotAllDataProductionTime.map(
        (data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
          var m = "0" + d.getMinutes();
          var ampm = h >= 12 ? "PM" : "AM";
          h = h % 12;
          h = h ? h : 12;
          var fullTime = h + ":" + m.substr(-2) + " " + ampm;
          return fullTime;
        }
      );
      setXAxisDataValueProductionTime(xAxisPointDataProductionTime);
    } catch (error) {
      console.log(error);
    }
  };

  const [ProductionTimeList, setProductionTimeList] = useState([
    {
      percentage: "0",
    },
    {
      percentage: "0",
    },
    {
      percentage: "0",
    },
    {
      percentage: "0",
    },
    {
      percentage: "0",
    },
    {
      percentage: "0",
    },
    {
      percentage: "0",
    },
    {
      percentage: "0",
    },
    {
      percentage: "0",
    },
    {
      percentage: "0",
    },
    {
      percentage: "0",
    },
    {
      percentage: "0",
    },
  ]);

  useEffect(() => {
    DailyDataFunProductionTime();
  }, []);
  const DailyDataFunProductionTime = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}) {
            dm_foundation_aggregate(where: {date: {_gte: $date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                    machine_availability_percent
                }
                max {
                    machine_availability_percent
                }
                min {
                    machine_availability_percent
                }
              }
            }
          }
    `);
      if (data !== undefined) {
        setProductionTimeList((prevData) => {
          const newarr = [...prevData];
          newarr[0] = {
            percentage:
              data.dm_foundation_aggregate.aggregate.min
                .machine_availability_percent !== null
                ? data.dm_foundation_aggregate.aggregate.min.machine_availability_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[4] = {
            percentage:
              data.dm_foundation_aggregate.aggregate.avg
                .machine_availability_percent !== null
                ? data.dm_foundation_aggregate.aggregate.avg.machine_availability_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[8] = {
            percentage:
              data.dm_foundation_aggregate.aggregate.max
                .machine_availability_percent !== null
                ? data.dm_foundation_aggregate.aggregate.max.machine_availability_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setProductionTimeList((prevData) => {
        const newarr = [...prevData];
        newarr[0] = { percentage: "No Data" };
        newarr[4] = { percentage: "No Data" };
        newarr[8] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    WeeklyDataFunProductionTime();
  }, []);
  const WeeklyDataFunProductionTime = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                  machine_availability_percent
                }
                max {
                  machine_availability_percent
                }
                min {
                  machine_availability_percent
                }
              }
            }
          } 
    `);
      if (data !== undefined) {
        setProductionTimeList((prevData) => {
          const newarr = [...prevData];
          newarr[1] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min
                .machine_availability_percent !== null
                ? data.dm_daily_aggregate.aggregate.min.machine_availability_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[5] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg
                .machine_availability_percent !== null
                ? data.dm_daily_aggregate.aggregate.avg.machine_availability_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[9] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max
                .machine_availability_percent !== null
                ? data.dm_daily_aggregate.aggregate.max.machine_availability_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setProductionTimeList((prevData) => {
        const newarr = [...prevData];
        newarr[1] = { percentage: "No Data" };
        newarr[5] = { percentage: "No Data" };
        newarr[9] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    MonthlyDataFunProductionTime();
  }, []);
  const MonthlyDataFunProductionTime = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                  machine_availability_percent
                }
                max {
                  machine_availability_percent
                }
                min {
                  machine_availability_percent
                }
              }
            }
          }
    `);
      if (data !== undefined) {
        setProductionTimeList((prevData) => {
          const newarr = [...prevData];
          newarr[2] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min
                .machine_availability_percent !== null
                ? data.dm_daily_aggregate.aggregate.min.machine_availability_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[6] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg
                .machine_availability_percent !== null
                ? data.dm_daily_aggregate.aggregate.avg.machine_availability_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[10] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max
                .machine_availability_percent !== null
                ? data.dm_daily_aggregate.aggregate.max.machine_availability_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setProductionTimeList((prevData) => {
        const newarr = [...prevData];
        newarr[2] = { percentage: "No Data" };
        newarr[6] = { percentage: "No Data" };
        newarr[10] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    YearlyDataFunProductionTime();
  }, []);
  const YearlyDataFunProductionTime = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                  machine_availability_percent
                }
                max {
                  machine_availability_percent
                }
                min {
                  machine_availability_percent
                }
              }
            }
          }
    `);
      if (data !== undefined) {
        setProductionTimeList((prevData) => {
          const newarr = [...prevData];
          newarr[3] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min
                .machine_availability_percent !== null
                ? data.dm_daily_aggregate.aggregate.min.machine_availability_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[7] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg
                .machine_availability_percent !== null
                ? data.dm_daily_aggregate.aggregate.avg.machine_availability_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[11] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max
                .machine_availability_percent !== null
                ? data.dm_daily_aggregate.aggregate.max.machine_availability_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setProductionTimeList((prevData) => {
        const newarr = [...prevData];
        newarr[3] = { percentage: "No Data" };
        newarr[7] = { percentage: "No Data" };
        newarr[11] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  ///////Total Parts Produced//////////////////////////////////////////////////////////////

  const [TotalPartsProducedShiftData, setTotalPartsProducedShiftData] =
    useState([]);
  useEffect(() => {
    TotalPartsProducedShift();
  }, []);
  const TotalPartsProducedShift = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = 15, $end_date: date = ${currentDate}) {
                dm_shiftwise(where: {date: {_gte: $start_date, _lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                    total_parts_produced
                  timestamp
                }
              }
        `);
      if (data === undefined || data.dm_shiftwise === null) {
        setTotalPartsProducedShiftData([
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ]);
      } else {
        const TotalPartsProducedShift = [
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ];

        data.dm_shiftwise.map((data) => {
          var shiftDate = new Date(data.timestamp);
          var HoursshiftDate = shiftDate.getHours();
          if (HoursshiftDate >= 8 && HoursshiftDate <= 16) {
            TotalPartsProducedShift[0] = {
              name: "Shift 1",
              dataValue: data.total_parts_produced,
            };
          } else if (HoursshiftDate >= 16 && HoursshiftDate <= 24) {
            TotalPartsProducedShift[1] = {
              name: "Shift 2",
              dataValue: data.total_parts_produced,
            };
          } else if (HoursshiftDate >= 0 && HoursshiftDate <= 8) {
            TotalPartsProducedShift[2] = {
              name: "Shift 3",
              dataValue: data.total_parts_produced,
            };
          }
        });
        setTotalPartsProducedShiftData(TotalPartsProducedShift);
      }
    } catch (error) {
      console.log(error);
      setTotalPartsProducedShiftData([
        {
          name: "shift 1",
          dataValue: "0",
        },
        {
          name: "shift 2",
          dataValue: "0",
        },
        {
          name: "shift 3",
          dataValue: "0",
        },
      ]);
    }
  };

  const [totalPartsProducedData, settotalPartsProducedData] = useState(0);
  useEffect(() => {
    totalPartsProduced();
  }, []);
  const totalPartsProduced = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                    total_parts_produced
                }
              }
            }
          }
       `);

      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.avg.total_parts_produced === null
      ) {
        settotalPartsProducedData("No Data");
      } else {
        settotalPartsProducedData(
          data.dm_daily_aggregate.aggregate.avg.total_parts_produced
        );
      }
      // if (data != undefined) {
      //     const valueData = data.dm_daily_aggregate.aggregate.avg.total_parts_produced !== null ? data.dm_daily_aggregate.aggregate.avg.total_parts_produced.toFixed(0) : "No Data"
      //     settotalPartsProducedData(valueData);
      // }
    } catch (error) {
      console.log(error);
      settotalPartsProducedData("No Data");
    }
  };

  const [
    xAxisDataValueTotalPartsProduced,
    setXAxisDataValueTotalPartsProduced,
  ] = useState([]);
  const [plotPointTotalPartsProduced, setPlotPointTotalPartsProduced] =
    useState([]);
  const datasetTotalPartsProduced = [
    {
      label: "Production Time",
      data: plotPointTotalPartsProduced,
      borderColor: "#1F8EF1",
    },
  ];

  useEffect(() => {
    GraphDataListTotalPartsProduced();
  }, []);
  const GraphDataListTotalPartsProduced = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
                dm_foundation(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                total_parts_produced
                  timestamp
                }
              }
        `);
      const plotAllDataTotalPartsProduced = data.dm_foundation.map((data) => {
        const objData = {
          point: data.total_parts_produced,
          Xaxis: data.timestamp,
        };
        return objData;
      });

      const plotPointDataTotalPartsProduced = plotAllDataTotalPartsProduced.map(
        (data) => data.point
      );
      setPlotPointTotalPartsProduced(plotPointDataTotalPartsProduced);

      const xAxisPointDataTotalPartsProduced =
        plotAllDataTotalPartsProduced.map((data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
          var m = "0" + d.getMinutes();
          var ampm = h >= 12 ? "PM" : "AM";
          h = h % 12;
          h = h ? h : 12;
          var fullTime = h + ":" + m.substr(-2) + " " + ampm;
          return fullTime;
        });
      setXAxisDataValueTotalPartsProduced(xAxisPointDataTotalPartsProduced);
    } catch (error) {
      console.log(error);
    }
  };

  const [TotalPartsProducedList, setTotalPartsProducedList] = useState([
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
  ]);

  useEffect(() => {
    TotalPartsProducedDailyDataFun();
  }, []);
  const TotalPartsProducedDailyDataFun = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}) {
            dm_foundation_aggregate(where: {date: {_gte: $date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                  total_parts_produced
                }
                max {
                  total_parts_produced
                }
                min {
                  total_parts_produced
                }
              }
            }
          }
    `);
      if (data !== undefined) {
        setTotalPartsProducedList((prevData) => {
          const newarr = [...prevData];
          newarr[0] = {
            percentage:
              data.dm_foundation_aggregate.aggregate.min
                .total_parts_produced !== null
                ? data.dm_foundation_aggregate.aggregate.min.total_parts_produced.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[4] = {
            percentage:
              data.dm_foundation_aggregate.aggregate.avg
                .total_parts_produced !== null
                ? data.dm_foundation_aggregate.aggregate.avg.total_parts_produced.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[8] = {
            percentage:
              data.dm_foundation_aggregate.aggregate.max
                .total_parts_produced !== null
                ? data.dm_foundation_aggregate.aggregate.max.total_parts_produced.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setTotalPartsProducedList((prevData) => {
        const newarr = [...prevData];
        newarr[0] = { percentage: "No Data" };
        newarr[4] = { percentage: "No Data" };
        newarr[8] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    TotalPartsProducedWeeklyDataFun();
  }, []);
  const TotalPartsProducedWeeklyDataFun = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                  total_parts_produced
                }
                max {
                  total_parts_produced
                }
                min {
                  total_parts_produced
                }
              }
            }
          }
    `);
      if (data !== undefined) {
        setTotalPartsProducedList((prevData) => {
          const newarr = [...prevData];
          newarr[1] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min.total_parts_produced !==
              null
                ? data.dm_daily_aggregate.aggregate.min.total_parts_produced.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[5] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg.total_parts_produced !==
              null
                ? data.dm_daily_aggregate.aggregate.avg.total_parts_produced.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[9] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max.total_parts_produced !==
              null
                ? data.dm_daily_aggregate.aggregate.max.total_parts_produced.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setTotalPartsProducedList((prevData) => {
        const newarr = [...prevData];
        newarr[1] = { percentage: "No Data" };
        newarr[5] = { percentage: "No Data" };
        newarr[9] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    TotalPartsProducedMonthlyDataFun();
  }, []);
  const TotalPartsProducedMonthlyDataFun = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                  total_parts_produced
                }
                max {
                  total_parts_produced
                }
                min {
                  total_parts_produced
                }
              }
            }
          }
    `);
      if (data !== undefined) {
        setTotalPartsProducedList((prevData) => {
          const newarr = [...prevData];
          newarr[2] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min.total_parts_produced !==
              null
                ? data.dm_daily_aggregate.aggregate.min.total_parts_produced.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[6] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg.total_parts_produced !==
              null
                ? data.dm_daily_aggregate.aggregate.avg.total_parts_produced.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[10] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max.total_parts_produced !==
              null
                ? data.dm_daily_aggregate.aggregate.max.total_parts_produced.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setTotalPartsProducedList((prevData) => {
        const newarr = [...prevData];
        newarr[2] = { percentage: "No Data" };
        newarr[6] = { percentage: "No Data" };
        newarr[10] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    TotalPartsProducedYearlyDataFun();
  }, []);
  const TotalPartsProducedYearlyDataFun = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                  total_parts_produced
                }
                max {
                  total_parts_produced
                }
                min {
                  total_parts_produced
                }
              }
            }
          }
    `);
      if (data !== undefined) {
        setTotalPartsProducedList((prevData) => {
          const newarr = [...prevData];
          newarr[3] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min.total_parts_produced !==
              null
                ? data.dm_daily_aggregate.aggregate.min.total_parts_produced.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[7] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg.total_parts_produced !==
              null
                ? data.dm_daily_aggregate.aggregate.avg.total_parts_produced.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[11] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max.total_parts_produced !==
              null
                ? data.dm_daily_aggregate.aggregate.max.total_parts_produced.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setTotalPartsProducedList((prevData) => {
        const newarr = [...prevData];
        newarr[3] = { percentage: "No Data" };
        newarr[7] = { percentage: "No Data" };
        newarr[11] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  /////// Avg (YTD) /////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [AvgData, setAvgData] = useState("No data");
  useEffect(() => {
    Avg();
  }, []);
  const Avg = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                    plant_operating_time_percent
                }
              }
            }
          }
    `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent ===
          null
      ) {
        setAvgData("No Data");
      } else {
        setAvgData(
          data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent.toFixed(
            1
          )
        );
      }
    } catch (error) {
      console.log(error);
      setAvgData("No Data");
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="3">
            <Card
              className="production-volume-header"
              style={{
                height: "40px",
                fontSize: "16px",
                fontWeight: "bold",
                color: "white",
                marginTop: "30px",
                justifyContent: "center",
              }}
            >
              <span style={{ paddingLeft: "20px" }}>Productivity Analysis</span>
            </Card>
          </Col>
          <Col lg="9">
            <Nav
              className="d-flex flex-row"
              tabs
              style={{
                borderBottom: "none",
                marginTop: "30px",
                // marginBottom:'30px'
              }}
            >
              <NavItem
                className="flex-grow-1 text-center"
                style={{ margin: "0px 5px" }}
              >
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => toggleTab("1")}
                  style={{
                    display: "block",
                    width: "200px",
                    height: "40px",
                    backgroundColor:
                      activeTab === "1" ? "#BB86FC" : "transparent",
                    color: activeTab === "1" ? "white" : "white",
                    borderRadius: "5px",
                    border: "1px solid #D3D3D3",
                    padding: "10px",
                  }}
                >
                  Time
                </NavLink>
              </NavItem>
              <NavItem
                className="flex-grow-1 text-center"
                style={{ margin: "0px 5px" }}
              >
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => toggleTab("2")}
                  style={{
                    display: "block",
                    width: "150px", // Set a specific width
                    height: "40px",
                    backgroundColor:
                      activeTab === "2" ? "#BB86FC" : "transparent",
                    color: activeTab === "2" ? "white" : "white",
                    borderRadius: "5px",
                    border: "1px solid #D3D3D3",
                    padding: "10px",
                  }}
                >
                  Production
                </NavLink>
              </NavItem>
              <NavItem
                className="flex-grow-1 text-center"
                style={{ margin: "0px 5px" }}
              >
                <NavLink
                  className={classnames({ active: activeTab === "3" })}
                  onClick={() => toggleTab("3")}
                  style={{
                    display: "block",
                    width: "150px", // Set a specific width
                    height: "40px",
                    backgroundColor:
                      activeTab === "3" ? "#BB86FC" : "transparent",
                    color: activeTab === "3" ? "white" : "white",
                    borderRadius: "5px",
                    border: "1px solid #D3D3D3",
                    padding: "10px",
                  }}
                >
                  Performance
                </NavLink>
              </NavItem>
              <NavItem
                className="flex-grow-1 text-center"
                style={{ margin: "0px 5px" }}
              >
                <NavLink
                  className={classnames({ active: activeTab === "6" })}
                  onClick={() => toggleTab("6")}
                  style={{
                    display: "block",
                    width: "150px", // Set a specific width
                    height: "40px",
                    backgroundColor:
                      activeTab === "6" ? "#BB86FC" : "transparent",
                    color: activeTab === "6" ? "white" : "white",
                    borderRadius: "5px",
                    border: "1px solid #D3D3D3",
                    padding: "10px",
                  }}
                >
                  Workforce
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col>
                {/* <ProductivityTime /> */}
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="2">
            <Row>
              <Col>
                <JindalProduction />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col>
                {/* <Performance /> */}
              </Col>
            </Row>
          </TabPane>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="4">
              <Row>
                <Col>{/* <Losses /> */}</Col>
              </Row>
            </TabPane>

            <TabPane tabId="5">
              <Row>
                <Col>{/* <Deviation /> */}</Col>
              </Row>
            </TabPane>
            <TabPane tabId="6">
              <Row>
                <Col>
                  {/* <WorkforceHome /> */}
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </TabContent>
      </div>
    </>
  );
}

export default JindalProductivity;
