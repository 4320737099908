import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Progress,
} from "reactstrap";
import axios from "axios";
import { APIURL } from "./Apiurl.js";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";


function ToolAndDieEdit({handleCloseEditModal,selectedRow,fetchData}) {
const [ id,setId ] = useState(selectedRow.toolordie_id);
  const [activeStep, setActiveStep] = useState(1);
  const [progress, setProgress] = useState(0);
  const [showEdit, setShowEdit] = useState(true);
  const [tenantId, setTenantId] = useState("");
  const [tagNumber, setTagNumber] = useState(selectedRow.tag_number);
  const [toolLocationNo, setToolLocationNo] = useState(selectedRow.tool_location_no);
  const [opNo, setOpNo] = useState(selectedRow.op_no);
  const [opName, setOpName] = useState(selectedRow.op_name);
  const [partNo, setPartNo] = useState(selectedRow.part_no);
  const [partName, setPartName] = useState(selectedRow.part_name);
  const [dieNumber, setDieNumber] = useState(selectedRow.die_number);
  const [actualPartId, setActualPartId] = useState(selectedRow.actual_part_id);
  const [isLastStep, setIsLastStep] = useState(false);
  const [toolOrDieId, setToolOrDieId] = useState("");
  const [tagId, setTagId] = useState("");

  const handleNext = () => {
    if (activeStep === 1) {
      setProgress(100);
    }
    setActiveStep(activeStep + 1);
    if (activeStep + 1 === 3) {
      setIsLastStep(true);
    }
  };

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
    setIsLastStep(false);
  };

  const handleClosePage = () => {
    setShowEdit(false);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const token = sessionStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["role_id"];
    let logintenantId = null;
    if (roleId === 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-tenant-id"];
    }

    const formData = {
      tenantid: logintenantId,
      tag_number: tagNumber,
      tool_location_no: toolLocationNo,
      op_no: opNo,
      op_name: opName,
      part_no: partNo,
      part_name: partName,
      die_number: dieNumber,
      actual_part_id: actualPartId,
   
    };
    axios
      .post(
        APIURL,
        {
          query: `
              mutation UpdatePartAndCustomer($id: Int!, $formData: tnt_tool_and_die_master_set_input!) {
                update_tnt_tool_and_die_master_by_pk(pk_columns: { toolordie_id: $id }, _set: $formData) {
                    toolordie_id
              }
            }
          `,
          variables: {
            id: parseInt(id),
            formData: formData,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-hasura-admin-secret": process.env.REACT_APP_X_API_SECRET_KEY, // Replace with your Hasura admin secret
          },
        }
      )
      .then((response) => {
        console.log(response); // Handle the response data as needed
        console.log(response.data);
        const updatedToolAndDieData =
          response.data?.data
            ?.update_tnt_tool_and_die_master_by_pk;
        if (updatedToolAndDieData) {
          
          setProgress(0);
          setActiveStep(1);
          toast.success("Data Updated successfully!");
          fetchData();

          setTagNumber("");
          setToolLocationNo("");
          setOpNo("");
          setOpName("");
          setPartNo("");
          setPartName("");
          setDieNumber("");
          setActualPartId("");    
        } else {
          toast.error("Failed to insert data.");
        }
      })
      .catch((error) => {
        console.error(error); // Handle the error
        toast.error("An error occurred while inserting data.");
      });
  };
  
  const renderStepContent = () => {
    switch (activeStep) {
      case 1:
        return (
          <>
            <Row>
              <Col className="px-md-1" md="3">
                <FormGroup>
                  <Label for="tagNumber" style={{color:'#ffffff'}}>TAG NUMBER</Label>
                  <Input
                    type="text"
                    value={tagNumber}
                    onChange={(e) => setTagNumber(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="3">
                <FormGroup>
                  <Label for="toolLocationNo" style={{color:'#ffffff'}}>
                  TOOL LOCATION NO
                  </Label>
                  <Input
                    placeholder=""
                    type="text"
                    value={toolLocationNo}
                    onChange={(e) => setToolLocationNo(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="3">
                <FormGroup>
                  <Label for="partno" style={{color:'#ffffff'}}>OP NO</Label>
                  <Input
                    type="text"
                    value={opNo}
                    onChange={(e) => setOpNo(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="3">
                <FormGroup>
                  <FormGroup>
                    <Label for="opName" style={{color:'#ffffff'}}>OP NAME</Label>
                    <Input
                      type="text"
                      value={opName}
                      onChange={(e) => setOpName(e.target.value)}
                    />
                  </FormGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="px-md-1" md="3">
                <FormGroup>
                  <Label for="orgtype" style={{color:'#ffffff'}}>PART NO</Label>
                  <Input
                    type="text"
                    value={partNo}
                    onChange={(e) => setPartNo(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="3">
                <FormGroup>
                  <Label for="tenantId" style={{color:'#ffffff'}}>PART NAME</Label>
                  <Input
                    type="text"
                    value={partName}
                    onChange={(e) => setPartName(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="3">
                <FormGroup>
                  <Label for="" style={{color:'#ffffff'}}>DIE NUMBER</Label>
                  <Input
                    type="text"
                    value={dieNumber}
                    onChange={(e) => setDieNumber(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="3">
                <FormGroup>
                  <Label for="actualPartId" style={{color:'#ffffff'}}>ACTUAL PART ID</Label>
                  <Input
                    type="text"
                    value={actualPartId}
                    onChange={(e) => setActualPartId(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        );
      default:
        return null;
    }
  };

  const renderStepButtons = () => {
    if (activeStep === 1) {
      return (
        <>
          <Link to="/admin/planing-administration">
            <Button color="danger" style={{ marginRight: "5px" }}  onClick={handleCloseEditModal}>
              {" "}
              Cancel
            </Button>
          </Link>
          <Button color="success" onClick={handleFormSubmit}>
            Save
          </Button>
        </>
      );
    } 
  };
  return (
   
      <div className="content" >
       
        {showEdit && (
          
            <Card style={{ border: 'none' }}>
              <CardHeader>
                <h5 className="title" style={{color:'#ffffff',fontSize:'16px'}}>Update Tool And Die ID
                <button type="button" className="close" aria-label="Close" 
                    style={{
                    // float: "right", 
                    background: "transparent",
                    border: "none",
                    color: "#ffffff" ,
                    fontSize: "35px",
                    cursor: "pointer",
                    marginTop: "-40px",
                    }}
                    onClick={handleCloseEditModal}
                    >
                    &times;
                    </button>
                </h5>
              </CardHeader>
              <CardBody>
                <Form>{renderStepContent()}</Form>
              </CardBody>
              <CardFooter>
                <div className="text-right">{renderStepButtons()}</div>
              </CardFooter>
            </Card>
         
          )}
        
        
      </div>
    
  );
}
export default ToolAndDieEdit;
