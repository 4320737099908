import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import axios from "axios";
import { APIURL } from "./Apiurl.js";
import { toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
const { SearchBar } = Search;

const AssignParameters = ({
  machineType,
  machineSubType,
  machineTypename,
  machineSubTypename,
  machineid,
  edgeid,
}) => {
  const [parameters, setParameters] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items per page
  const columns = 3;
  const rowsPerColumn = 10;

  useEffect(() => {
    fetchSubMachineType();
  }, []);
  console.log(machineid + " machine-edge " + edgeid);
  const fetchSubMachineType = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await axios.post(
        APIURL,
        {
          query: `
          query AssignParameters($machinesubtypename: String!, $machinetypename: String!) {
            universal_parametric_library(
              where: {
                machine_sub_type: { _eq: $machinesubtypename },
                machine_type: { _eq: $machinetypename },
                is_active: {_eq: "1"}
              }
            ) {
              parameter_name
              parameter_id
              parameter_type
              parameter_category
              unit_of_measure
              rank
            }
          }
        `,
          variables: {
            machinetypename: machineTypename,
            machinesubtypename: machineSubTypename,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      setParameters(response.data.data.universal_parametric_library);
      console.log(response.data.data.universal_parametric_library);
    } catch (error) {
      console.error(error);
    }
  };
  const assignParameters = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const roleId = decodedToken["sub"];
      console.log(roleId);
      let logintenantId = null;
      if (roleId === 1) {
        logintenantId = sessionStorage.getItem("selectedTenantId");
      } else {
        logintenantId =
          decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
      }

      // Create an array to store the parameter mapping data
      const parameterMappings = parameters.map((paramName) => ({
        tenantid: logintenantId, // Replace with your tenant ID
        machineid: machineid, // Replace with your machine ID
        edgeid: edgeid, // Replace with your edge ID
        machine_type: machineType,
        machine_sub_type: machineSubType,
        parameter_id: paramName.parameter_id, // Replace with your parameter ID
        parameter_name: paramName.parameter_name,
        parameter_type: paramName.parameter_type, // Replace with your parameter type
        unit_of_measure: paramName.unit_of_measure,
        rank: paramName.rank,
      }));

      // Send the parameter mapping data to the Hasura API
      const response = await axios.post(
        APIURL,
        {
          query: `
          mutation InsertParameterMappings($parameterMappings: [tnt_machine_parameter_mapping_insert_input!]!) {
            insert_tnt_machine_parameter_mapping(objects: $parameterMappings) {
              affected_rows
            }
          }
        `,
          variables: {
            parameterMappings,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (
        response.data.data.insert_tnt_machine_parameter_mapping.affected_rows >
        0
      ) {
        toast.success("Parameters assigned successfully!");
      } else {
        toast.error("Failed to assign parameters.");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while assigning parameters.");
    }
  };
  const columns1 = [
    {
      dataField: "parameter_id",
      text: "Parameter Id",
    },
    {
      dataField: "parameter_name",
      text: "Parameter Name",
    },
    {
      dataField: "parameter_type",
      text: "Paramter Type",
    },
    {
      dataField: "parameter_category",
      text: "Parameter Category",
    },
  ];
  // Get current items
  const startIndex = (currentPage - 1) * (columns * rowsPerColumn);
  const endIndex = startIndex + columns * rowsPerColumn;
  const currentParameters = parameters.slice(startIndex, endIndex);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4" className="table-card-header">
                Assign Machine Parameters
              </CardTitle>
              <Row style={{ textAlign: "center", fontWeight: "bold" }}>
                <Col className="pr-md-1" md="4">
                  <p>Machine Type Name: {machineTypename}</p>
                </Col>
                <Col className="pr-md-1" md="6">
                  <p>Machine Sub Type Name: {machineSubTypename}</p>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                data={parameters}
                columns={columns1}
                search
              >
                {(props) => (
                  <div>
                    <div
                      className="float-right"
                      style={{
                        display: "flex",
                        marginBottom: "18px",
                        gap: "12px",
                      }}
                    >
                      <div className="float-left">
                        <SearchBar {...props.searchProps} />
                      </div>
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      keyField="id"
                      data={parameters}
                      columns={columns1}
                      filter={filterFactory()}
                      pagination={paginationFactory({
                        bootstrap4: true,
                      })}
                      headerClasses="sorted-asc sorted-desc"
                    />
                  </div>
                )}
              </ToolkitProvider>
              <Button
                color="success"
                style={{ float: "right" }}
                onClick={assignParameters}
              >
                Assign Parameters
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AssignParameters;
