import React, { useState, useEffect } from "react";
import redirectToLoginIfTokenMissing from "views/TokenUtility";
import axios from "axios";
import { APIURL } from "views/Apiurl";
import {
  TabContent,
  TabPane,
  Card,
  CardBody,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { QueryApi } from "QueryApi.js";

function TableComponentWithoutFilter({ apiData, columns, title ,sizePerPage}) {
  const [activeTab, setActiveTab] = useState(1);
  const [displayData, setDisplayData] = useState(apiData);
  useEffect(() => {
    setDisplayData(apiData);
  }, [apiData]);

  useEffect(() => {
    redirectToLoginIfTokenMissing();
  }, []);

  ///////////////////////////////////////////
 


  return (
    <div className="content">
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          {/* <Row>
            <Col lg="2" md="3" sm="4" xs="6" className="ml-3">
              <h3>{title}</h3>
            </Col>
            <Col lg="2" md="3" sm="4" xs="6">
              <FormGroup>
                <Input
                  type="select"
                  value={org}
                  onChange={(e) => handleOrgSelect(e.target.value)}
                >
                  <option value="">Select Organization</option>
                  {otherData.map((org) => (
                    <option
                      key={org.organization_structure_id}
                      value={org.organization_structure_id}
                    >
                      {org.organization_node_name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col lg="2" md="3" sm="4" xs="6">
              <FormGroup>
                <Input
                  type="select"
                  value={unit}
                  onChange={(e) => setunit(e.target.value)}
                >
                  <option value="">Select Unit/Plant</option>
                  {plants.map((plant) => (
                    <option
                      key={plant.organization_structure_id}
                      value={plant.organization_structure_id}
                    >
                      {plant.organization_node_name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col lg="2" md="3" sm="4" xs="6">
              <FormGroup>
                <Input
                  type="select"
                  value={dept}
                  onChange={(e) => setdept(e.target.value)}
                >
                  <option value="">Select department</option>
                  {depts.map((dpt) => (
                    <option
                      key={dpt.organization_structure_id}
                      value={dpt.organization_structure_id}
                    >
                      {dpt.organization_node_name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col lg="2" md="3" sm="4" xs="6">
              <FormGroup>
                <Input
                  type="select"
                  value={machine}
                  onChange={(e) => setMachine(e.target.value)}
                >
                  <option value="">Select Machine</option>
                  {machines.map((mc) => (
                    <option
                      key={mc.organization_structure_id}
                      value={mc.organization_structure_id}
                    >
                      {mc.machine_name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col lg="1" md="1" sm="1" xs="1">
              <Col lg="2">
                <Button
                  lg="2"
                  className="btn btn-secondary"
                  onClick={() => setDisplayData(filteredApiData)}
                >
                  Submit
                </Button>
              </Col>
            </Col>
          </Row> */}
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={apiData}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <div>
                        <BootstrapTable
                          {...(props.baseProps && props.baseProps)}
                          filter={filterFactory()}
                          pagination={paginationFactory({ sizePerPage })}
                          data={displayData || []}
                          // data={filteredApiData || []}
                          headerClasses="sorted-asc sorted-desc"
                          wrapperClasses="table-responsive"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}
export default TableComponentWithoutFilter;
