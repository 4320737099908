import React, { useState, useEffect } from "react";
import axios from "axios";
import  { APIURL } from './Apiurl.js';
import {
  TabContent,
  TabPane,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col, 
} from "reactstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
const { SearchBar } = Search;

function RoleAll() {
  const [activeTab, setActiveTab] = useState(1);
  const [role, setRole] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await axios.post(APIURL, {
        query: `
          query {
            role {
              role_id
              role
              created_at
              updated_at
            }
          }
        `,
      });

      setRole(response.data.data.role);
      console.log(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  function actionFormatter(cell, row) {
    return (
      <div>
        <Col
          className="font-icon-list col-xs-6 col-xs-6"
          lg="2"
          md="3"
          sm="4"
          onClick={() => handleEdit(row)}
        ></Col>
       <Link to={`/admin/role/${row.role_id}`}>
          <i className="btn btn-primary tim-icons icon-pencil" />
        </Link>
      </div>
    );
  }

  function handleEdit(row) {
    // Logic for handling the edit action
    console.log("Editing row:", row);
  }

  const columns = [
    {
      dataField: "role_id",
      text: "Role Id",
      headerStyle: { width: '500px' }
    },
    {
      dataField: "role",
      text: "Role",
      headerStyle: { width: '500px' }
    },
    {
      dataField: "created_at",
      text: "Created At",
      formatter: (cell, row) => new Date(cell).toLocaleString(),
      headerStyle: { width: '500px' }
    },
    {
      dataField: "updated_at",
      text: "Updated At",
      formatter: (cell, row) => new Date(cell).toLocaleString(),
      headerStyle: { width: '500px' }
    },
    {
      dataField: "action",
      text: "Action",
      formatter: actionFormatter,
      headerStyle: { width: '500px' }
    },
  ];
  

  return (
    <div className="content">
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4" className="table-card-header">
                    Manage People
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={role}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <div>
                        <div
                          className="float-right"
                          style={{
                            display: "flex",
                            marginBottom: "18px",
                            gap: "12px",
                          }}
                        >
                          <div className="float-left">
                            <SearchBar {...props.searchProps} />
                          </div>
                          <div className="float-right">
                            {/* <NavLink
                              href="/admin/machine/machine-add"
                              className="btn btn-secondary"
                            >
                              <FontAwesomeIcon
                                icon={faPlus}
                                className="mr-2"
                              />
                              Add new machine/equipment
                            </NavLink> */}
                          </div>
                        </div>
                        <BootstrapTable
                          {...props.baseProps}
                          filter={filterFactory()}
                          pagination={paginationFactory()}
                          headerClasses="sorted-asc sorted-desc"
                          wrapperClasses="table-responsive"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default RoleAll;
