import React, { useState, useEffect } from "react";
import axios from "axios";
import  { APIURL } from './Apiurl.js';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Input,
  Col,
  Row,
  Label,
} from "reactstrap";
import { toast } from 'react-toastify';

function ParameterForm() {
  const [parameterId, setParameterId] = useState("");
  const [machineId, setMachineId] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [machines, setMachines] = useState([]);
  const [parameters, setParameters] = useState([]);
  useEffect(() => {
    fetchMachines();
    fetchParameters();
  }, []);

  const fetchMachines = async () => {
    try {
      const response = await axios.post(APIURL, {
        query: `
          query {
            machines {
              machine_id
              machine_name
            }
          }
        `,
      });

      setMachines(response.data.data.machines);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchParameters = async () => {
    try {
      const response = await axios.post(APIURL, {
        query: `
          query {
            parameters {
              parameter_id
              parameter_name
            }
          }
        `,
      });

      setParameters(response.data.data.parameters);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    // Create an object with the form data
    const formData = {
      parameter_id: parameterId,
      machine_id: machineId,
      created_by: createdBy,
    };

    // Use Axios or your preferred library to send the data to the server
    axios
      .post(APIURL, {
        query: `
          mutation InsertMachineParameter($formData: machine_parameters_insert_input!) {
            insert_machine_parameters(objects: [$formData]) {
              affected_rows
            }
          }
        `,
        variables: {
          formData: formData,
        },
      })
      .then((response) => {
        console.log(response.data); // Handle the response data as needed
        if (response.data.data && response.data.data.insert_machine_parameters.affected_rows > 0) {
            toast.success('Machine Parameter inserted successfully!');
          } else {
            toast.error('Failed to insert machine parameter.');
          }
      })
      .catch((error) => {
        console.error(error); // Handle the error
        toast.error('An error occurred while inserting machine parameter.');
      });
  };

  return (
    <div className="content">
        <Row>
            <Card>
                <CardHeader>
                <h5 className="title">Parameter Form</h5>
                </CardHeader>
                <CardBody>
                <Form onSubmit={handleFormSubmit}>
                    <Col className="px-md-1" md="4">
                        <FormGroup>
                        <Label for="machineId">Machine ID</Label>
                        <Input
                            type="select"
                            id="machineId"
                            value={machineId}
                            onChange={(e) => setMachineId(e.target.value)}
                        >
                            <option value="">Select Machine ID</option>
                            {machines.map((machine) => (
                            <option key={machine.machine_id} value={machine.machine_id}>
                                {machine.machine_name}
                            </option>
                            ))}
                        </Input>
                        </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="4">
                        <FormGroup>
                        <Label for="parameterId">Parameter ID</Label>
                        <Input
                            type="select"
                            id="parameterId"
                            value={parameterId}
                            onChange={(e) => setParameterId(e.target.value)}
                        >
                            <option value="">Select Parameter ID</option>
                            {parameters.map((parameter) => (
                            <option key={parameter.parameter_id} value={parameter.parameter_id}>
                                {parameter.parameter_name}
                            </option>
                            ))}
                        </Input>
                        </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="4">
                        <FormGroup>
                        <Label for="createdBy">Created By</Label>
                        <Input
                            type="text"
                            id="createdBy"
                            value={createdBy}
                            onChange={(e) => setCreatedBy(e.target.value)}
                        />
                        </FormGroup>
                    </Col>
                    
                    <Button color="primary" type="submit">
                    Submit
                    </Button>
                </Form>
                </CardBody>
            </Card>
      </Row>
    </div>
  );
}

export default ParameterForm;
