import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import axios from "axios";
import  { APIURL } from './Apiurl.js';

const operationsDoc = `
  mutation MyMutation($role: String!) {
    insert_role(objects: { role: $role }) {
      affected_rows
    }
  }
`;
function RoleAdd() {
  const [role, setRole] = useState("");
  const [response, setResponse] = useState(null);

  const handleInputChange = (event) => {
    setRole(event.target.value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(APIURL, {
        query: operationsDoc,
        variables: { role },
        operationName: "MyMutation",
      });
      setResponse(response.data);
    } catch (error) {
      console.error(error);
      setResponse({ error: "An error occurred while processing the mutation." });
    }
  };
  return (
    <div className="content">
      
      <Row className="progress-row">
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Add New Role</h5>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col className="pr-md-1" md="5">
                    <FormGroup>
                      <label>Role Name</label>
                      <Input
                        placeholder="Role Name"
                        type="text"
                        value={role}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                 
                </Row>
                <Row>
                  <Col className="justify-end">
                    <Button type="submit" className="btn btn-primary">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default RoleAdd;
