import React, { useState, useEffect } from "react";
import AddIcon from "@material-ui/icons/Add";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import axios from "axios"; // Import axios for making API requests
import { APIURL } from './Apiurl.js';
import { toast } from 'react-toastify';

const OrganizationTree = ({ parentId = 0 }) => {
  const [orgs, setOrgs] = useState([]);
  const [newChildName, setNewChildName] = useState("");
  const fetchOrgs = async (parentId) => {
    try {
      const token = sessionStorage.getItem('token');
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      const tenantId = decodedToken['https://hasura.io/jwt/claims']['x-hasura-org-id'];
      console.log(decodedToken);
      const roleId = decodedToken['sub'];
      const storedTenantId = sessionStorage.getItem('selectedTenantId');
      console.log(roleId === 1);
      
      let query = ''; // Define the query variable outside the if-else block
      let variables = {}; // Define the variables object
      
      if (roleId == 1) {
        if(storedTenantId !==null){
          query = `
          query TntOrganizationTree($parentId: Int!, $tenantId: Int!) {
            tnt_organization_structure(
              where: {
                parent_organization_id: { _eq: $parentId },
                tenantid: { _eq: $tenantId }
              }
            ) {
              organization_structure_id
              organization_node_name
            }
          }
        `;
        
        variables = {
          parentId: parentId,
          tenantId: storedTenantId,
        };
        }else{
          query = `
          query TntOrganizationTree($parentId: Int!) {
            tnt_organization_structure(where: { parent_organization_id: { _eq: $parentId } }) {
              organization_structure_id
              organization_node_name
            }
          }
          
          `;
          variables = {
            parentId: parentId,
          };
        }
      
      } else {
       
        query = `
          query TntOrganizationTree($parentId: Int!) {
            tnt_organization_structure(where: { parent_organization_id: { _eq: $parentId } }) {
              organization_structure_id
              organization_node_name
            }
          }
        `;
        
        variables = {
          parentId: parentId,
        };
      }
  
      const response = await axios.post(APIURL, {
        query: query,
        variables: variables,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
  
      return response.data.data.tnt_organization_structure;
    } catch (error) {
      console.error(error);
      return [];
    }
  };
 

  const handleCreateChild = async () => {
    if (!newChildName.trim()) {
      // Display an error message if the input is empty or contains only whitespace
      toast.error("Please enter a valid child node name or It cannot be empty.");
      return;
    }
  
    try {
      const token = sessionStorage.getItem('token');
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
     
      const roleId = decodedToken['sub'];
      let logintenantId = null;
      if(roleId == 1){
         logintenantId = sessionStorage.getItem('selectedTenantId');
      }else{
         logintenantId = decodedToken['https://hasura.io/jwt/claims']['x-hasura-org-id'];
      }
  
      
      // Make an API call to create the child node
      const response = await axios.post(APIURL, {
        query: `
          mutation CreateChild($parentId: Int!, $nodeName: String!, $logintenantId: Int!) {
            insert_tnt_organization_structure(objects: {
              parent_organization_id: $parentId,
              organization_node_name: $nodeName,
              tenantid: $logintenantId
            }) {
              affected_rows
            }
          }
        `,
        variables: {
          parentId: parentId,
          nodeName: newChildName,
          logintenantId: logintenantId
        },
      }, {
        headers: {
          Authorization: `Bearer ${decodedToken}`,
        },
      });

      if (response.data.data.insert_tnt_organization_structure.affected_rows > 0) {
        // Child node created successfully
        toast.success("Child node created successfully.");
        
        // Fetch the updated organization data and update the state
        const updatedChildren = await fetchOrgs(parentId);
        setOrgs(updatedChildren);

        // Clear the input field
        setNewChildName("");
      } else {
        toast.error("Failed to create child node.");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while creating the child node.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const children = await fetchOrgs(parentId);
      setOrgs(children);
    };
    fetchData();
  }, [parentId]);

  return (
    <div>
    {orgs.map((org) => (
      <Accordion
        key={org.organization_structure_id}
        style={{ width: "100%", marginBottom: "8px" }}
      >
        <AccordionSummary
          expandIcon={<AddIcon style={{ color: "white" }} />}
          aria-controls={`panel-${org.organization_structure_id}-content`}
          style={{ backgroundColor: "#252424" }}
        >
          <Typography
            style={{
              fontWeight: 10,
              color: "white",
            }}
          >
            {org.organization_node_name}
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: "#1e1e28" }}>
          <div style={{ width: "100%" }}>
            <OrganizationTree parentId={org.organization_structure_id} />
          </div>
        </AccordionDetails>
        {/* Place the following inside the current map loop */}
            
      </Accordion>
    ))}
    </div>
   

  );
};

export default OrganizationTree;
