import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Progress,
} from "reactstrap";
import axios from "axios";
import  { APIURL } from './Apiurl.js';
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

function WorkscheduleMaster() {
    const [activeStep, setActiveStep] = useState(1);
    const [checked, setChecked] = useState(false);
    const [progress, setProgress] = useState(0);
    const [udpedit, setudpedit] = useState({
        tenantId: "",
        orgId: "",
        buId: "",
        shiftId: "",
        shiftName: "",
        shiftType: "",
        break1StartTime: "",
        break1EndTime: "",
        break2StartTime: "",
        break2EndTime: "",
       
    
      });
    
      const { id } = useParams();
      const navigate = useNavigate();
    
      useEffect(() => {
        axios
          .get(`${APIURL}/api/wsm/${id}`)
          .then((response) => {
            setudpedit((prevState) => ({
              ...prevState,
              tenantId: response.data.tenant_id,
              orgId: response.data.org_id,
              buId: response.data.business_unit_id,
              shiftId: response.data.shift_id, 
              shiftName: response.data.shift_name,
              shiftType: response.data.shift_type,
              break1StartTime: response.data.break1_starttime,
              break1EndTime: response.data.break1_endtime,
              break2StartTime: response.data.break2_starttime,
              break2EndTime: response.data.break2_endtime,
             
            }));
           
          })
          .catch((error) => {
            // console.error(error);
          });
      }, [id]);
    
      const handleSubmit = (e) => {
        e.preventDefault();
    
        // Check if the machine name is provided
       
        axios
          .post(`${APIURL}/api/wsm/${id}`, udpedit)
          .then((response) => {
            // console.log(response);
            navigate(`/mtoeassign/${id}`);
            if (response.status === 201) {
              window.location.href = "/admin/workschedulemaster/wsm-all";
            } else {
              // Reset the form or show a success message
            }
          })
          .catch((error) => {
            // console.error(error);
          });
      };
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setudpedit((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      };
    

  
  const handleNext = () => {
    if (activeStep === 1 && progress === 0) {
      setProgress(50);
    } else if (activeStep === 2 && progress === 50) {
      setProgress(100);
    }
    setActiveStep(activeStep + 1);
  };

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };

 
  const renderStepContent = () => {
    switch (activeStep) {
      case 1:
        return (
          <>
            <Row>
              <Col className="pr-md-1" md="4">
                <FormGroup>
                  <label>Tenant ID</label>
                  <Input placeholder="Tenant ID" type="text" 
                  name="tenantId"
                  value={udpedit.tenantId}
                  onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <label>Organization ID</label>
                  <Input placeholder="Organization ID" type="text" 
                  name="orgId"
                  value={udpedit.orgId}
                  onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col className="pl-md-1" md="4">
                <FormGroup>
                  <label>Business Unit ID</label>
                  <Input placeholder="Business Unit ID" type="text" 
                  name="buId"
                  value={udpedit.buId}
                  onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pr-md-1" md="4">
                <FormGroup>
                  <label>Shift ID</label>
                  <Input placeholder="Shift ID" type="text" 
                  name="shiftId"
                  value={udpedit.shiftId}
                  onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <label>Shift Name</label>
                  <Input placeholder="Shift Name" type="text" 
                  name="shiftName"
                  value={udpedit.shiftName}
                  onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col className="pl-md-1" md="4">
                <FormGroup>
                  <label>Shift Type</label>
                  <Input placeholder="Shift Type" type="text" 
                  name="shiftType"
                  value={udpedit.shiftType}
                  onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        );
      case 2:
        return (
          <>
          <Row>
            <Col className="pr-md-1" md="4">
              <FormGroup>
                <label>Break 1 Start Time</label>
                <Input placeholder="Break 1 Start Time" type="date"
                name="break1StartTime" 
                value={udpedit.break1StartTime}
                onChange={handleChange}
                />
              </FormGroup>
            </Col>
            <Col className="px-md-1" md="4">
              <FormGroup>
                <label>Break 1 End Time</label>
                <Input placeholder="Break 1 End Time" type="date" 
                name="break1EndTime"

                value={udpedit.break1EndTime}
                onChange={handleChange}
                />
              </FormGroup>
            </Col>
            <Col className="pl-md-1" md="4">
              <FormGroup>
                <label>Break 2 Start Time</label>
                <Input placeholder="Break 2 Start Time" type="date" 
                name="break2StartTime"
                value={udpedit.break2StartTime}
                onChange={handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
          </>
        );
      case 3:
        return (
          <>
            <Row>
              <Col className="pr-md-1" md="4">
                <FormGroup>
                  <label>Break 2 End Time</label>
                  <Input placeholder="Break 2 End Time" type="date" 
                  name="break2EndTime"
                  value={udpedit.break2EndTime}
                  onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              {/* Add more fields here */}
            </Row>
          </>
        );
      default:
        return null;
    }
  };
  const calculateProgress = () => {
    return Math.round(((activeStep - 1) / 2) * 100); // Update the denominator to match the total number of steps minus one
  };
  

  const renderProgressBarDestinations = () => {
    const step1Class = activeStep >= 1 ? "progress-destination-active" : "";
    const step2Class = activeStep >= 2 ? "progress-destination-active" : "";
    const step3Class = activeStep >= 3 ? "progress-destination-active" : "";
    const step4Class = activeStep >= 4 ? "progress-destination-active" : "";
  
    return (
      <div className="progress-destinations">
        <div className={`progress-destination ${step1Class}`}>
          <span>1</span>
        </div>
        <div className={`progress-destination ${step2Class}`}>
          <span>2</span>
        </div>
        <div className={`progress-destination ${step3Class}`}>
          <span>3</span>
        </div>
        <div className={`progress-destination ${step4Class}`}>
          <span>4</span>
        </div>
      </div>
    );
  };


  return (
    <div className="content">
      <Row>
        <Col md="8">
          <div className="progress-container">
            {renderProgressBarDestinations()}
            <Progress
              max="100"
              value={calculateProgress()}
              className="progress-bar-sm progress-bar-striped"
            />
          </div>
        </Col>
      </Row>
      <Row className="progress-row">
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Add New Machine</h5>
            </CardHeader>
            <CardBody>
              <Form>{renderStepContent()}</Form>
            </CardBody>
            <CardFooter>
              <div className="text-right">
                {activeStep > 1 && (
                  <Button color="primary" onClick={handlePrevious}>
                    Previous
                  </Button>
                )}
                {activeStep < 3 && (
                  <Button color="primary" onClick={handleNext}>
                    Next
                  </Button>
                )}
                {activeStep === 3 && (
                  <Button color="success" onClick={handleSubmit}>
                    Submit
                  </Button>
                )}
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default WorkscheduleMaster;
