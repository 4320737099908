import React from "react";
import { useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Progress,
} from "reactstrap";

function ConfigCodeMaster() {
  const [activeStep, setActiveStep] = useState(1);
  const [checked, setChecked] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleNext = () => {
    if (activeStep === 1 && progress === 0) {
      setProgress(50);
    } else if (activeStep === 2 && progress === 50) {
      setProgress(100);
    }
    setActiveStep(activeStep + 1);
  };

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSubmit = () => {
    // Handle form submission
    console.log("Form submitted");
  };
  const renderStepContent = () => {
    switch (activeStep) {
      case 1:
        return (
          <>
        <Row>
                    <Col className="pr-md-1" md="5">
                      <FormGroup>
                        <label>Assignment ID</label>
                        <Input
                          placeholder="Assignment ID"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="3">
                      <FormGroup>
                        <label>Tenant ID</label>
                        <Input
                          placeholder="Tenant ID"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                        Parameter ID
                        </label>
                        <Input placeholder="Parameter ID" type="text" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Widget ID</label>
                        <Input
                          placeholder="Widget ID"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Parameter Name</label>
                        <Input
                          
                          placeholder="Parameter Name"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  
                  <Row>
                  <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Parameter Category</label>
                        <Input
                          placeholder="Parameter Category"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Parameter Type</label>
                        <Input
                          
                          placeholder="Parameter Type"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
          </>
        );
      case 2:
        return (
          <>
       <Row>
                  <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Machine Type</label>
                        <Input
                          placeholder="Machine Type"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Parent Parameter ID</label>
                        <Input
                          
                          placeholder="Parent Parameter ID"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Primary Driver Category</label>
                        <Input
                          placeholder="Primary Driver Category"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Interlink 1</label>
                        <Input
                          
                          placeholder="Interlink 1"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                  <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Interlink 2</label>
                        <Input
                          placeholder="Interlink 2"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Actionability catergory</label>
                        <Input
                          placeholder="Actionability catergory"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
          </>
        );
      case 3:
        return (
          <>
           <Row>
                  <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Output Driver</label>
                        <Input
                          placeholder="Output Driver"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Unit of Measure</label>
                        <Input
                          placeholder="Unit of Measure"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                  <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Direction</label>
                        <Input
                          placeholder="Direction"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Visualization</label>
                        <Input
                          placeholder="Visualization"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
          </>
        );
      default:
        return null;
    }
  };
  const calculateProgress = () => {
    return Math.round(((activeStep - 1) / 2) * 100); // Update the denominator to match the total number of steps minus one
  };
  

  const renderProgressBarDestinations = () => {
    const step1Class = activeStep >= 1 ? "progress-destination-active" : "";
    const step2Class = activeStep >= 2 ? "progress-destination-active" : "";
    const step3Class = activeStep >= 3 ? "progress-destination-active" : "";
    const step4Class = activeStep >= 4 ? "progress-destination-active" : "";
  
    return (
      <div className="progress-destinations">
        <div className={`progress-destination ${step1Class}`}>
          <span>1</span>
        </div>
        <div className={`progress-destination ${step2Class}`}>
          <span>2</span>
        </div>
        <div className={`progress-destination ${step3Class}`}>
          <span>3</span>
        </div>
        <div className={`progress-destination ${step4Class}`}>
          <span>4</span>
        </div>
      </div>
    );
  };

  
  
  return (
    <div className="content">
      <Row>
        <Col md="8">
          <div className="progress-container">
            {renderProgressBarDestinations()}
            <Progress
              max="100"
              value={calculateProgress()}
              className="progress-bar-sm progress-bar-striped"
            />
          </div>
        </Col>
      </Row>
      <Row className="progress-row">
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Add New Machine</h5>
            </CardHeader>
            <CardBody>
              <Form>{renderStepContent()}</Form>
            </CardBody>
            <CardFooter>
              <div className="text-right">
                {activeStep > 1 && (
                  <Button color="primary" onClick={handlePrevious}>
                    Previous
                  </Button>
                )}
                {activeStep < 3 && (
                  <Button color="primary" onClick={handleNext}>
                    Next
                  </Button>
                )}
                {activeStep === 3 && (
                  <Button color="success" onClick={handleSubmit}>
                    Submit
                  </Button>
                )}
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );

}

export default ConfigCodeMaster;
