import React from "react";
import { Card, Row, Col, CardHeader, CardBody } from "reactstrap";
import OeeScoreMachineWise from "./OeeScoreMachineWise";
import OeeScoreOperatorWise from "./OeeOperatorWise";
import OeeScorePartWise from "./OeeScorePartWise";

function OeeScorecard(props) {
  return (
    <>
      <div className="content">
        <Row>
          <Col lg="8">
            <Card className="card-workforce">
              <CardHeader>
                <h5 className="card-category">Machine Wise OEE Dashboard</h5>
              </CardHeader>
            </Card>
            <Card className="card-chart">
              <CardBody>
                <div className="workforce-by-machine">
                  <OeeScoreMachineWise />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card className="card-workforce">
              <CardHeader>
                <h5 className="card-category">Key Trends</h5>
              </CardHeader>
            </Card>
            <Card className="card-chart">
              <CardBody>
                <div className="workforce-by-machine">
                  <input
                    type="text"
                    style={{
                      backgroundColor: "blue",
                      width: "100%",
                      height: "100%",
                      color: "white", // You can set the text color as well
                      padding: "10px", // Adjust padding as needed
                      border: "none", // Optionally remove border
                    }}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="8">
            <Card className="card-workforce">
              <CardHeader>
                <h5 className="card-category">Operator Wise OEE Dashboard</h5>
              </CardHeader>
            </Card>
            <Card className="card-chart">
              <CardBody>
                <div className="workforce-by-machine">
                  <OeeScoreOperatorWise />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card className="card-workforce">
              <CardHeader>
                <h5 className="card-category">Key Trends</h5>
              </CardHeader>
            </Card>
            <Card className="card-chart">
              <CardBody>
                <div className="workforce-by-machine">
                  <input
                    type="text"
                    style={{
                      backgroundColor: "blue",
                      width: "100%",
                      height: "100%",
                      color: "white", // You can set the text color as well
                      padding: "10px", // Adjust padding as needed
                      border: "none", // Optionally remove border
                    }}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="8">
            <Card className="card-workforce">
              <CardHeader>
                <h5 className="card-category">Part Wise OEE Dashboard</h5>
              </CardHeader>
            </Card>
            <Card className="card-chart">
              <CardBody>
                <div className="workforce-by-machine">
                  <OeeScorePartWise />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card className="card-workforce">
              <CardHeader>
                <h5 className="card-category">Key Trends</h5>
              </CardHeader>
            </Card>
            <Card className="card-chart">
              <CardBody>
                <div className="workforce-by-machine">
                  <input
                    type="text"
                    style={{
                      backgroundColor: "blue",
                      width: "100%",
                      height: "100%",
                      color: "white", // You can set the text color as well
                      padding: "10px", // Adjust padding as needed
                      border: "none", // Optionally remove border
                    }}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default OeeScorecard;
