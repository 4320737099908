import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Progress,
  Label,
} from "reactstrap";
import axios from "axios";
import  { APIURL } from './Apiurl.js';
import { toast } from 'react-toastify';

function JobrolemasterAdd() {
  const [activeStep, setActiveStep] = useState(1);
  const [progress, setProgress] = useState(0);

  const [parameterName, setParameterName] = useState("");
  const [parameterDescription, setParameterDescription] = useState("");
  const [parameterCategory, setParameterCategory] = useState("");
  const [parameterType, setParameterType] = useState("");
  const [uom, setUOM] = useState("");
  const [rank, setRank] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [parameterCategories, setParameterCategories] = useState([]);
  const [parameterTypes, setParameterTypes] = useState([]);

  useEffect(() => {
    fetchParameterCategories();
    fetchParameterTypes();
  }, []);

  const fetchParameterCategories = async () => {
    try {
      const response = await axios.post(APIURL, {
        query: `
          query {
            parameter_categories {
              parameter_category_id
              parameter_category
            }
          }
        `,
      });
      setParameterCategories(response.data.data.parameter_categories);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchParameterTypes = async () => {
    try {
      const response = await axios.post(APIURL, {
        query: `
          query {
            parameter_types {
              parameter_type_id
              parameter_type
            }
          }
        `,
      });
      setParameterTypes(response.data.data.parameter_types);
    } catch (error) {
      console.error(error);
    }
  };

  const handleNext = () => {
    if (activeStep === 1 && progress === 0) {
      setProgress(50);
    } else if (activeStep === 2 && progress === 50) {
      setProgress(100);
    }
    setActiveStep(activeStep + 1);
  };

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    
    // Create an object with the form data
    const formData = {
        parameter_name: parameterName,
        parameter_description: parameterDescription,
        parameter_category: parameterCategory,
        parameter_type: parameterType,
        uom: uom,
        rank: rank,
        created_by: createdBy,
        is_active: "1",
    };

    axios
    .post(APIURL, {
        query: `
          mutation InsertParameters($formData: parameters_insert_input!) {
            insert_parameters(objects: [$formData]) {
              affected_rows
            }
          }
        `,
        variables: {
          formData: formData,
        },
      })
      .then((response) => {
        console.log(response.data); // Handle the response data as needed
        if (response.data.data && response.data.data.insert_parameters.affected_rows > 0) {
          toast.success('Parameter inserted successfully!');
        } else {
          toast.error('Failed to insert parameter.');
        }
      })
      .catch((error) => {
        console.error(error); // Handle the error
        toast.error('An error occurred while inserting parameter.');
      });
    };

  const renderStepContent = () => {
    switch (activeStep) {
        case 1:
          return (
            <>
              <Row>
                <Col className="px-md-1" md="6">
                <FormGroup>
                    <Label for="parameterName">Parameter Name</Label>
                    <Input
                    type="text"
                    name="parameterName"
                    id="parameterName"
                    value={parameterName}
                    onChange={(e) => setParameterName(e.target.value)}
                    />
                </FormGroup>
                </Col>
                <Col className="pl-md-1" md="6">
                <FormGroup>
                    <label>Parameter Type</label>
                    <Input
                      type="select"
                      value={parameterType}
                      onChange={(e) => setParameterType(e.target.value)}
                    >
                      <option value="">Select Parameter Type</option>
                      {parameterTypes.map((type) => (
                        <option key={type.parameter_type_id} value={type.parameter_type_id}>
                          {type.parameter_type}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </>
          );
        case 2:
          return (
            <>
              <Row>
                <Col className="pr-md-1" md="6">
                <FormGroup>
                    <Label for="parameterCategory">Parameter Category</Label>
                    <Input
                    type="select"
                    name="parameterCategory"
                    id="parameterCategory"
                    value={parameterCategory}
                    onChange={(e) => setParameterCategory(e.target.value)}
                    >
                    <option value="">Select Parameter Category</option>
                    {parameterCategories.map((category) => (
                        <option key={category.parameter_category_id} value={category.parameter_category_id}>
                        {category.parameter_category}
                        </option>
                    ))}
                    </Input>
                </FormGroup>
                </Col>
                <Col className="px-md-1" md="6">
                <FormGroup>
                    <Label for="parameterDescription">Parameter Description</Label>
                    <Input
                    type="text"
                    name="parameterDescription"
                    id="parameterDescription"
                    value={parameterDescription}
                    onChange={(e) => setParameterDescription(e.target.value)}
                    />
                </FormGroup>
                </Col>
              </Row>
            </>
          );
        case 3:
          return (
            <>
              <Row>
              <Col className="px-md-1" md="4">
                <FormGroup>
                    <Label for="uom">UOM</Label>
                    <Input
                    type="number"
                    name="uom"
                    id="uom"
                    value={uom}
                    onChange={(e) => setUOM(e.target.value)}
                    />
                </FormGroup>
                </Col>
                <Col className="pr-md-1" md="4">
                  <FormGroup>
                  <Label for="rank">Rank</Label>
                    <Input
                    type="number"
                    name="rank"
                    id="rank"
                    value={rank}
                    onChange={(e) => setRank(e.target.value)}
                    />
                </FormGroup>
                </Col>
                <Col className="px-md-1" md="4">
                  <FormGroup>
                    <label>Created By</label>
                    <Input
                      placeholder="Created By"
                      type="text"
                      value={createdBy}
                      onChange={(e) => setCreatedBy(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </>
          );
        default:
          return null;
      }
  };
  const calculateProgress = () => {
    return Math.round(((activeStep - 1) / 2) * 100); // Update the denominator to match the total number of steps minus one
  };
  

  const renderProgressBarDestinations = () => {
    const step1Class = activeStep >= 1 ? "progress-destination-active" : "";
    const step2Class = activeStep >= 2 ? "progress-destination-active" : "";
    const step3Class = activeStep >= 3 ? "progress-destination-active" : "";
    const step4Class = activeStep >= 4 ? "progress-destination-active" : "";
  
    return (
      <div className="progress-destinations">
        <div className={`progress-destination ${step1Class}`}>
          <span>1</span>
        </div>
        <div className={`progress-destination ${step2Class}`}>
          <span>2</span>
        </div>
        <div className={`progress-destination ${step3Class}`}>
          <span>3</span>
        </div>
        <div className={`progress-destination ${step4Class}`}>
          <span>4</span>
        </div>
      </div>
    );
  };


  return (
    <div className="content">
      <Row>
        <Col md="8">
          <div className="progress-container">
            {renderProgressBarDestinations()}
            <Progress
              max="100"
              value={calculateProgress()}
              className="progress-bar-sm progress-bar-striped"
            />
          </div>
        </Col>
      </Row>
      <Row className="progress-row">
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Job Role Master</h5>
            </CardHeader>
            <CardBody>
              <Form>{renderStepContent()}</Form>
            </CardBody>
            <CardFooter>
              <div className="text-right">
                {activeStep > 1 && (
                  <Button color="primary" onClick={handlePrevious}>
                    Previous
                  </Button>
                )}
                {activeStep < 3 && (
                  <Button color="primary" onClick={handleNext}>
                    Next
                  </Button>
                )}
                {activeStep === 3 && (
                  <Button color="success" onClick={handleFormSubmit}>
                    Submit
                  </Button>
                )}
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );

}

export default JobrolemasterAdd;
