/*!
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import ManageTenants from "views/ManageTenants.js";
import Icons from "views/Icons.js";
import Map from "views/Map.js";
import Notifications from "views/Notifications.js";
import Rtl from "views/Rtl.js";
import Typography from "views/Typography.js";
import UserProfile from "views/UserProfile.js";
import ProfileMaster from "views/ProfileMaster";
import UdpParameterMapping from "views/UdpParameterMapping";
import UdpAssignmentMaster from "views/UdpAssignmentMaster";
import TenantMaster from "views/TenantMaster";
import TenantAdministration from "views/TenantAdministration";
import TenantOrganizationMaster from "views/TenantOrganizationMaster";
import TenantMachinesMaster from "views/TenantMachinesMaster";
import WorkscheduleMaster from "views/WorkscheduleMaster";
// import MachineAll from "views/MachineAll";
import MachineAdd from "views/MachineAdd";
import EmployeeAdd from "views/EmployeeAdd";
import TenantMasterall from "views/TenantMasterall";
import TenantAdd from "views/TenantAdd";
import UdpMasterAll from "views/UdpMasterAll";
import UdpMasterAdd from "views/UdpMasterAdd";
import UdpMasterEdit from "views/UdpMasterEdit";
import TomAll from "views/TomAll";
import TomAdd from "views/TomAdd";

import WorkScheduleAll from "views/WorkScheduleAll";
import WorkScheduleAdd from "views/WorkScheduleAdd";
import UdpParameterAll from "views/UdpParameterAll";
import UdpParameterAdd from "views/UdpParameterAdd";
import UdpParameterEdit from "views/UdpParameterEdit";

import UdpAssignmentAdd from "views/UdpAssignmentAdd";
import UdpAssignmentAll from "views/UdpAssignmentAll";
import TenantAdministrationAll from "views/TenantAdministrationAll";
import TenantAdministrationAdd from "views/TenantAdministrationAdd";
import EmpJobAll from "views/EmpJobAll";
import UdpAssignmentEdit from "views/UdpAssignmentEdit";
import TenantEdit from "views/TenantEdit";
import TenantAdministrationEdit from "views/TenantAdministrationEdit";
import WorkScheduleEdit from "views/WorkScheduleEdit";
import TomEdit from "views/TomEdit";
import TenantMachineMasterEdit from "views/TenantMachineMasterEdit";
import TenantMachineMasterAll from "views/TenantMachineMasterAll";
import ConfigurationManager from "views/ConfigurationManager";
import ManageOrg from "views/ManageOrg";
import ManagePeople from "views/ManagePeople";
import DeviceManagement from "views/DeviceManagement";
import ManageRole from "views/ManageRole";
import ParameterAdd from "views/ParameterAdd";
import ManchineParameterAdd from "views/ManchineParameterAdd";

import OrgStructureAdd from "views/OrgStructureAdd";
import OrgStructureEdit from "views/OrgStructureEdit";

import OrgMachineParameterAdd from "views/OrgMachineParameterAdd";
import TenantEmpJobDescriptionAdd from "views/TenantEmpJobDescriptionAdd";
import OrgMachineParameterEdit from "views/OrgMachineParameterEdit";
import Login from "views/Login";
import ChartDashboard from "views/ChartDashboard";
import ManageDashboard from "views/ManageDashboard";
import Productivity from "views/Productivity";
import ProductionVolume from "views/ProductionVolume";
import WorkforceEfficiency from "views/WorkforceEfficiency";
import IdleTimeLoss from "views/IdleTimeLoss";
import Mindmap from "views/Mindmap";
import CheckChart from "views/CheckChart";
import MachineEquipment from "views/Machine-equipment";
import MachineInsights from "views/MachineInsights";

import CycleTimeAnalysis from "views/CycleTimeAnalysis";
import PlanAdmin from "views/PlanAdmin";
import ProductionAdd from "views/ProductionAdd";
import PartAndCustomerEdit from "views/PartAndCustomerEdit.js";
import ProductionPlanEdit from "views/ProductionPlanEdit.js";
import PartandCustomers from "views/PartandCustomers";
import ProductionPlanOperator from "views/ProductionPlanOperator.js";
import ProductionPlan from "views/ProductionPlan";
import WorkForceAvailability from "views/WorkForceAvailability";
import WorkForceEfficiencyLevel3 from "components/WorkForceAvailability/WorkForceEffLevel3";
import WorkForceUtilization from "components/WorkForceAvailability/WorkForceUtilization";
import EmpJobDescription from "components/EmployeeJD/EmpJobDescription";
import OeeLevelOne from "components/OEE/OeeLevelOne";
import IfaAnalysis from "components/OEE/IfaAnalysis";
import NewDashboard from "views/NewDashboard";
import OeeMachinesLevelOne from "components/OEE/OeeMachinesLevelone";
import IFACycleTime from "components/New Productivity/IFACycleTime";
import IFADownTime from "components/New Productivity/IFADownTime";
import BreakdownDiagnostics from "../src/views/BreakdownDiagnostics";
import IFAWorkForceAvailability from "components/New WorkForce Efficiency/IFAWorkforceAvailability";
import IFAWorkForceAEfficiency from "components/New WorkForce Efficiency/IFAWorkForceEfficiency";
import IFALosses from "components/IFA Losses/IFALosses";
import IFATargetAdherance from "components/IFA Target Adherance/IFATargetAdherance";
import IFAAnomalyAndDeviation from "components/IFA Anomaly and Deviation/IFAAnomalyAndDeviation";
import WorkforceHome from "views/WorkforceEfficiency";
import IFAAvailability from "components/Availability/IFAAvailability";
import IFAWorkforceProductivity from "components/IFA Workforce/IFAWorkforceProductivity";
import IFAOperatorMachineProximity from "components/IFA Workforce/IFAOperatorMachineProxi";
import JindalDashBoard from "views/Jindal/JindalDashBoard";
import OeeLevelOneJindal from "views/Jindal/OEE/OeeLevelOne";
import WorkforceEfficiencyJindal from "views/Jindal/WorkforceEfficiency";
import JindalManagePeople from "views/Jindal/JindalMachineEquipment";
import JindalIFATargetAdherance from "./views/Jindal/JindalIFATargetAdherance";
import JindalWorkForceAvailability from "../src/views/Jindal/JindalWorkForceAvailability";
import JindalLosses from "../src/views/Jindal/JindalLosses";
import JindalIFAProductionVolume from "../src/views/Jindal/JindalProductionVolume";
import JindalIFADownTime from "./views/Jindal/downtime/JindalIFADownTime";
import IFAJumbo from "views/Jindal/IFAJumbo";
import IfaPrimarySliter from "views/Jindal/IfaPrimarySliter";
import SlitEfficiencySum from "views/Jindal/primarySliterInformation/SlitEfficiencySum";
import SlitEfficiencyPlant from "views/Jindal/primarySliterInformation/SlitEfficiencyPlant";
import SlitEfficiencyDaySum from "views/Jindal/primarySliterInformation/SlitEfficiencyDaySum";
import SlitEfficiencyTSlitDay from "views/Jindal/primarySliterInformation/SlitEfficiencyTSlitDay";
import IFAJumboEfficiency from "views/Jindal/IFAJumboEfficiency";
import IFASecondarySliter from "views/Jindal/IFASecondarySliter";
import IFASecondarySlitterEfficiency from "views/Jindal/IFASecondarySlitterEfficiency";
import IFASecondarySlitterProduction from "views/Jindal/IFASecondarySlitterPrduction";
import JindalCycleTime from "views/Jindal/JindalCycleTime";
import JindalProductivity from "views/Jindal/Jindal Productivity/JindalProductivityHome";
import IFAPowerConsumption from "views/Jindal/PowerConsumption/IFAPowerConsumption";
import JindalMachineInsights from "views/Jindal/MachineInsights/JindalMachineInsight"; // "views/Jindal/MachineInsights/JindalMachineInsights";
var routes = [
  {
    path: "/Extrusion/Machine-Insights",
    name: "/Extrusion/Machine-Insights",
    icon: "tim-icons icon-chart-pie-36",
    component: <JindalMachineInsights />,
    layout: "/admin",
  },
  {
    path: "/Extrusion/IFA-Secondary-Slitter-efficiency",
    name: "/Extrusion/IFA-Secondary-Slitter-efficiency",
    icon: "tim-icons icon-chart-pie-36",
    component: <IFASecondarySlitterEfficiency />,
    layout: "/admin",
  },
  {
    path: "/Extrusion/IFA-Secondary-Slitter-production",
    name: "/Extrusion/IFA-Secondary-Slitter-production",
    icon: "tim-icons icon-chart-pie-36",
    component: <IFASecondarySlitterProduction />,
  },
  {
    path: "/Extrusion/Productivity",
    name: "/Extrusion/Productivity",
    icon: "tim-icons icon-chart-pie-36",
    component: <JindalProductivity />,
    layout: "/admin",
  },
  {
    path: "/Extrusion/IFA-Jumbo-efficiency",
    name: "/Extrusion/IFA-Jumbo-efficiency",
    icon: "tim-icons icon-chart-pie-36",
    component: <IFAJumboEfficiency />,
    layout: "/admin",
  },
  {
    path: "/Extrusion/IFA-Seconary-sliter/Efficiency-TSlitDay", ///////555
    name: "/Extrusion/IFA-primary-sliter-TSlitDay",
    icon: "tim-icons icon-chart-pie-36",
    component: <SlitEfficiencyTSlitDay />,
    layout: "/admin",
  },
  {
    path: "/Extrusion/IFA-Primary-sliter-plant-daySum", ///////444
    name: "/Extrusion/IFA-primary-sliter-plant-daySum",
    icon: "tim-icons icon-chart-pie-36",
    component: <SlitEfficiencyDaySum />,
    layout: "/admin",
  },
  {
    path: "/Extrusion/IFA-Primary-sliter-plant", ///////333
    name: "/Extrusion/IFA-primary-sliter-plant",
    icon: "tim-icons icon-chart-pie-36",
    component: <SlitEfficiencyPlant />,
    layout: "/admin",
  },
  {
    path: "/Extrusion/IFA-Primary-sliter-sum", ///////222
    name: "/Extrusion/IFA-primary-sliter-sum",
    icon: "tim-icons icon-chart-pie-36",
    component: <SlitEfficiencySum />,
    layout: "/admin",
  },
  {
    path: "/Extrusion/IFA-Primary-sliter", ///////111
    name: "/Extrusion/IFA-Seconary-sliter/Efficiency",
    icon: "tim-icons icon-chart-pie-36",
    component: <IfaPrimarySliter />,
    layout: "/admin",
  },
  {
    path: "/Extrusion/IFA-Jumbo-Production",
    name: "/Extrusion/IFA-Jumbo",
    icon: "tim-icons icon-chart-pie-36",
    component: <IFAJumbo />,
    layout: "/admin",
  },
  {
    path: "/Extrusion/production-volume",
    name: "/Extrusion/production-volume",
    icon: "tim-icons icon-chart-pie-36",
    component: <JindalIFAProductionVolume />,
    layout: "/admin",
  },
  {
    path: "/Extrusion/WorkforceEfficiency",
    name: "/Extrusion/WorkforceEfficiency",
    icon: "tim-icons icon-chart-pie-36",
    component: <WorkforceEfficiencyJindal />,
    layout: "/admin",
  },

  {
    path: "/Extrusion/oee",
    name: "/Extrusion/oee",
    icon: "tim-icons icon-chart-pie-36",
    component: <OeeLevelOneJindal />,
    layout: "/admin",
  },
  {
    path: "/Extrusion",
    name: "/Extrusion",
    icon: "tim-icons icon-chart-pie-36",
    component: <JindalDashBoard />,
    layout: "/admin",
  },
  {
    path: "/Extrusion/machineEquipment",
    name: "/Extrusion/machineEquipment",
    icon: "tim-icons icon-chart-pie-36",
    component: <JindalManagePeople />,
    layout: "/admin",
  },
  {
    path: "/Extrusion/targetAdherence",
    name: "/Extrusion/targetAdherence",
    icon: "tim-icons icon-chart-pie-36",
    component: <JindalIFATargetAdherance />,
    layout: "/admin",
  },
  {
    path: "/Extrusion/downtime",
    name: "/Extrusion/downtime",
    icon: "tim-icons icon-chart-pie-36",
    component: <JindalIFADownTime />,
    layout: "/admin",
  },
  {
    path: "/Extrusion/losses",
    name: "/Extrusion/losses",
    icon: "tim-icons icon-chart-pie-36",
    component: <JindalLosses />,
    layout: "/admin",
  },
  {
    path: "/Extrusion/workforceAvailability",
    name: "/Extrusion/workforceAvailability",
    icon: "tim-icons icon-chart-pie-36",
    component: <JindalWorkForceAvailability />,
    layout: "/admin",
  },
  {
    path: "/Extrusion/power-consumption",
    name: "/Extrusion/power-consumption",
    icon: "tim-icons icon-chart-pie-36",
    component: <IFAPowerConsumption />,
    layout: "/admin",
  },
  {
    path: "/IFAOperatorMachineProximity",
    name: "IFAOperatorMachineProximity",
    icon: "tim-icons icon-chart-pie-36",
    component: <IFAOperatorMachineProximity />,
    layout: "/admin",
  },
  {
    path: "/IFAWorkforceProductivity",
    name: "IFAWorkforceProductivity",
    icon: "tim-icons icon-chart-pie-36",
    component: <IFAWorkforceProductivity />,
    layout: "/admin",
  },
  {
    path: "/IFAAvailability",
    name: "IFAAvailability",
    icon: "tim-icons icon-chart-pie-36",
    component: <IFAAvailability />,
    layout: "/admin",
  },
  {
    path: "/WorkforceHome",
    name: "WorkforceHome",
    icon: "tim-icons icon-chart-pie-36",
    component: <WorkforceHome />,
    layout: "/admin",
  },
  {
    path: "/IFAAnomalyAndDeviation",
    name: "IFAAnomalyAndDeviation",
    icon: "tim-icons icon-chart-pie-36",
    component: <IFAAnomalyAndDeviation />,
    layout: "/admin",
  },
  {
    path: "/IFATargetAdherance",
    name: "IFATargetAdherance",
    icon: "tim-icons icon-chart-pie-36",
    component: <IFATargetAdherance />,
    layout: "/admin",
  },
  {
    path: "/IFALosses",
    name: "IFALosses",
    icon: "tim-icons icon-chart-pie-36",
    component: <IFALosses />,
    layout: "/admin",
  },
  {
    path: "/Downtime",
    name: "Downtime",
    icon: "tim-icons icon-chart-pie-36",
    component: <BreakdownDiagnostics />,
    layout: "/admin",
  },
  {
    path: "/IFAWorkForceAEfficiency",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: <IFAWorkForceAEfficiency />,
    layout: "/admin",
  },
  {
    path: "/IFAWorkForceAvailability",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: <IFAWorkForceAvailability />,
    layout: "/admin",
  },
  {
    path: "/IFADownTime",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: <IFADownTime />,
    layout: "/admin",
  },
  {
    path: "/IFACycleTime",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: <IFACycleTime />,
    layout: "/admin",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: <NewDashboard />,
    layout: "/admin",
  },
  {
    path: "/ifanalysis",
    name: "OEE Level one",
    icon: "tim-icons icon-chart-pie-36",
    component: <IfaAnalysis />,
    layout: "/admin",
  },
  {
    path: "/oee",
    name: "OEE Level one",
    icon: "tim-icons icon-chart-pie-36",
    component: <OeeLevelOne />,
    layout: "/admin",
  },
  {
    path: "/oee-machines",
    name: "OEE Machines Level one",
    icon: "tim-icons icon-chart-pie-36",
    component: <OeeMachinesLevelOne />,
    layout: "/admin",
  },
  {
    path: "/custom-dashboard",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: <ManageDashboard />,
    layout: "/admin",
  },
  {
    path: "/dashboard/productivity",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: <Productivity />,
    layout: "/admin",
  },
  {
    path: "/dashboard/production-volume",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: <ProductionVolume />,
    layout: "/admin",
  },
  {
    path: "/olddashboard",
    name: "oldDashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: <Dashboard />,
    layout: "/admin",
  },
  {
    path: "/dashboard/workforce-efficiency",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: <WorkforceEfficiency />,
    layout: "/admin",
  },
  {
    path: "/dashboard/workforce-efficiency-level3",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: <WorkForceEfficiencyLevel3 />,
    layout: "/admin",
  },
  {
    path: "/dashboard/workforce-utilization",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: <WorkForceUtilization />,
    layout: "/admin",
  },
  {
    path: "/dashboard/workforce-availability",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: <WorkForceAvailability />,
    layout: "/admin",
  },
  {
    path: "/dashboard/idle-time-loss",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: <IdleTimeLoss />,
    layout: "/admin",
  },
  {
    path: "/dashboard/machine-equipment",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: <MachineEquipment />,
    layout: "/admin",
  },
  {
    path: "/dashboard/machine-equipment/Analysis",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: <MachineInsights />,
    layout: "/admin",
  },
  {
    path: "/dashboard/cycle-time-analysis",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: <CycleTimeAnalysis />,
    layout: "/admin",
  },
  {
    path: "/prof-job/:employee_id",
    name: "Production Add",
    icon: "tim-icons icon-atom",
    component: <EmpJobDescription />,
    layout: "/admin",
  },
  {
    path: "/mindmap",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: <Mindmap />,
    layout: "/admin",
  },
  {
    path: "/planing-administration",
    name: "PlanAdmin",
    icon: "tim-icons icon-chart-pie-36",
    component: <PlanAdmin />,
    layout: "/admin",
  },
  {
    path: "/production-plan/home",
    name: "Production Plan home",
    icon: "tim-icons icon-atom",
    component: <ProductionPlan />,
    layout: "/admin",
  },
  {
    path: "/production-plan/:id",
    name: "Production Add",
    icon: "tim-icons icon-atom",
    component: <ProductionAdd />,
    layout: "/admin",
  },
  {
    path: "/production-plan-edit/:id",
    name: "ProductionPlan Edit",
    icon: "tim-icons icon-atom",
    component: <ProductionPlanEdit />,
    layout: "/admin",
  },
  {
    path: "/part-and-customer-home",
    name: "Partandcustomer",
    icon: "tim-icons icon-atom",
    component: <PartandCustomers />,
    layout: "/admin",
  },
  {
    path: "/production-plan-operator-home",
    name: "ProductionPlanOperator",
    icon: "tim-icons icon-atom",
    component: <ProductionPlanOperator />,
    layout: "/admin",
  },
  {
    path: "/part-and-customer/:id",
    name: "Partandcustomer Add",
    icon: "tim-icons icon-atom",
    component: <PartAndCustomerEdit />,
    layout: "/admin",
  },
  {
    path: "/custom-dashboard/:dashboard_id",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: <ChartDashboard />,
    layout: "/admin",
  },
  {
    path: "/tenants",
    name: "Tenants",
    icon: "tim-icons icon-chart-pie-36",
    component: <ManageTenants />,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    component: <Login />,
    layout: "/user",
  },
  {
    path: "/Configuration-Manager",
    name: "Configuration Manager",
    icon: "tim-icons icon-atom",
    component: <ConfigurationManager />,
    layout: "/admin",
  },
  {
    path: "/chart-dashboard",
    name: "Chart Dashboard",
    icon: "tim-icons icon-atom",
    component: <ChartDashboard />,
    layout: "/admin",
  },
  {
    path: "/chart-dashboard",
    name: "Chart Dashboard",
    icon: "tim-icons icon-atom",
    component: <ChartDashboard />,
    layout: "/admin",
  },
  {
    path: "/check-dashboard",
    name: "Chart Dashboard",
    icon: "tim-icons icon-atom",
    component: <CheckChart />,
    layout: "/admin",
  },
  {
    path: "/manage-organization",
    name: "Config Code Master",
    icon: "tim-icons icon-atom",
    component: <ManageOrg />,
    layout: "/admin",
  },
  {
    path: "/manage-people",
    name: "Manage People",
    icon: "tim-icons icon-atom",
    component: <ManagePeople />,
    layout: "/admin",
  },
  {
    path: "/device-management",
    name: "Device Management",
    icon: "tim-icons icon-atom",
    component: <DeviceManagement />,
    layout: "/admin",
  },
  {
    path: "/org-structure/add",
    name: "UDP Add",
    icon: "tim-icons icon-atom",
    component: <OrgStructureAdd />,
    layout: "/admin",
  },
  {
    path: "/org-structure/:id",
    name: "UDP Add",
    icon: "tim-icons icon-atom",
    component: <OrgStructureEdit />,
    layout: "/admin",
  },
  {
    path: "/org-machine-parameter/add",
    name: "UDP Add",
    icon: "tim-icons icon-atom",
    component: <OrgMachineParameterAdd />,
    layout: "/admin",
  },
  {
    path: "/org-machine-parameter/:id",
    name: "UDP Add",
    icon: "tim-icons icon-atom",
    component: <OrgMachineParameterEdit />,
    layout: "/admin",
  },
  {
    path: "/manage-role",
    name: "Manage Role",
    icon: "tim-icons icon-atom",
    component: <ManageRole />,
    layout: "/admin",
  },

  {
    path: "/parameter/parameter-add",
    name: "Parameters",
    icon: "tim-icons icon-atom",
    component: <ParameterAdd />,
    layout: "/admin",
  },
  {
    path: "/machine-parameter/add",
    name: "Machine Parameters",
    icon: "tim-icons icon-atom",
    component: <ManchineParameterAdd />,
    layout: "/admin",
  },
  {
    path: "/udp/udp-all",
    name: "UDP Master",
    icon: "tim-icons icon-atom",
    component: <UdpMasterAll />,
    layout: "/admin",
  },
  {
    path: "/udp/udp-add",
    name: "UDP Add",
    icon: "tim-icons icon-atom",
    component: <UdpMasterAdd />,
    layout: "/admin",
  },

  {
    path: "/tenant-emp-job-description/add",
    name: "UDP Add",
    icon: "tim-icons icon-atom",
    component: <TenantEmpJobDescriptionAdd />,
    layout: "/admin",
  },

  {
    path: "/udp/:id",
    name: "Edit UDP",
    icon: "tim-icons icon-atom",
    component: <UdpMasterEdit />,
    layout: "/admin",
  },
  {
    path: "/tenant/tenant-all",
    name: "Tenant Master",
    icon: "tim-icons icon-atom",
    component: <TenantMasterall />,
    layout: "/admin",
  },
  {
    path: "/tenant-organization-master/tom-all",
    name: "Tenant Organization Master",
    icon: "tim-icons icon-atom",
    component: <TomAll />,
    layout: "/admin",
  },
  {
    path: "/tenant-organization-master/tom-add",
    name: "Tenant Organization Master Add",
    icon: "tim-icons icon-atom",
    component: <TomAdd />,
    layout: "/admin",
  },
  {
    path: "/tenant-organization-master/:id",
    name: "Tenant Organization Master Edit",
    icon: "tim-icons icon-atom",
    component: <TomEdit />,
    layout: "/admin",
  },
  {
    path: "/workschedulemaster/wsm-all",
    name: "WorkScheduleAll",
    icon: "tim-icons icon-atom",
    component: <WorkScheduleAll />,
    layout: "/admin",
  },
  {
    path: "/udp-parameter/pm-all",
    name: "UDP Parameter All",
    icon: "tim-icons icon-atom",
    component: <UdpParameterAll />,
    layout: "/admin",
  },

  {
    path: "/tenant-administration/all",
    name: "Tenant Administration All",
    icon: "tim-icons icon-atom",
    component: <TenantAdministrationAll />,
    layout: "/admin",
  },
  {
    path: "/udp-assignment/all",
    name: "UDP Assignment All",
    icon: "tim-icons icon-atom",
    component: <UdpAssignmentAll />,
    layout: "/admin",
  },
  {
    path: "/employee-job-details/all",
    name: "Employee Job Details All",
    icon: "tim-icons icon-single-02",
    component: <EmpJobAll />,
    layout: "/admin",
  },
  {
    path: "/udp-parameter/pm-add",
    name: "UDP Parameter Add",
    icon: "tim-icons icon-atom",
    component: <UdpParameterAdd />,
    layout: "/admin",
  },
  {
    path: "/udp-parameter/:id",
    name: "UDP Parameter Edit",
    icon: "tim-icons icon-atom",
    component: <UdpParameterEdit />,
    layout: "/admin",
  },
  {
    path: "/tenant-administration/add",
    name: "Tenant Administration Add",
    icon: "tim-icons icon-atom",
    component: <TenantAdministrationAdd />,
    layout: "/admin",
  },
  {
    path: "/tenant-administration/:id",
    name: "Tenant Administration Add",
    icon: "tim-icons icon-atom",
    component: <TenantAdministrationEdit />,
    layout: "/admin",
  },
  {
    path: "/udp-assignment/add",
    name: "UDP Assignment Add",
    icon: "tim-icons icon-atom",
    component: <UdpAssignmentAdd />,
    layout: "/admin",
  },
  {
    path: "/udp-assignment/:id",
    name: "UDP Assignment Edit",
    icon: "tim-icons icon-atom",
    component: <UdpAssignmentEdit />,
    layout: "/admin",
  },
  {
    path: "/workschedulemaster/wsm-add",
    name: "Work Schedule Add",
    icon: "tim-icons icon-atom",
    component: <WorkScheduleAdd />,
    layout: "/admin",
  },
  {
    path: "/workschedulemaster/:id",
    name: "Work Schedule Edit",
    icon: "tim-icons icon-atom",
    component: <WorkScheduleEdit />,
    layout: "/admin",
  },
  {
    path: "/tenant/tenant-add",
    name: "Tenant Add",
    icon: "tim-icons icon-atom",
    component: <TenantAdd />,
    layout: "/admin",
  },
  {
    path: "/tenant/:id",
    name: "Tenant Add",
    icon: "tim-icons icon-atom",
    component: <TenantEdit />,
    layout: "/admin",
  },
  {
    path: "/machine/machine-add",
    name: "Add Machine",
    icon: "tim-icons icon-atom",
    component: <MachineAdd />,
    layout: "/admin",
  },

  {
    path: "/employee/employee-add",
    name: "Add Machine",
    icon: "tim-icons icon-atom",
    component: <EmployeeAdd />,
    layout: "/admin",
  },

  {
    path: "/Udp-Assignment-Master",
    name: "UDP Assignment Master",
    icon: "tim-icons icon-pin",
    component: <UdpAssignmentMaster />,
    layout: "/admin",
  },
  {
    path: "/TenantAdministration",
    name: "Tenant Administration",
    icon: "tim-icons icon-pin",
    component: <TenantAdministration />,
    layout: "/admin",
  },
  {
    path: "/Tenant-Master",
    name: "Tenant Master",
    icon: "tim-icons icon-bell-55",
    component: <TenantMaster />,
    layout: "/admin",
  },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   icon: "tim-icons icon-single-02",
  //   component: <UserProfile />,
  //   layout: "/admin",
  // },
  {
    path: "/Tenant-Organization-Master",
    name: "Tenant Organization Master",
    icon: "tim-icons icon-single-02",
    component: <TenantOrganizationMaster />,
    layout: "/admin",
  },

  // {
  //   path: "/profile-master",
  //   name: "Profile Master",
  //   icon: "tim-icons icon-single-02",
  //   component: <ProfileMaster />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/tables",
  //   name: "Table List",
  //   icon: "tim-icons icon-puzzle-10",
  //   component: <TableList />,
  //   layout: "/admin",
  // },
  {
    path: "/tenant-machines-master/all",
    name: "Tenant Machines Master All",
    icon: "tim-icons icon-align-center",
    component: <TenantMachineMasterAll />,
    layout: "/admin",
  },
  {
    path: "/tenant-machines-master/:id",
    name: "Tenant Machines Master Edit",
    icon: "tim-icons icon-align-center",
    component: <TenantMachineMasterEdit />,
    layout: "/admin",
  },
  {
    path: "/tenant-machines-master/add",
    name: "Tenant Machines Master Add",
    icon: "tim-icons icon-align-center",
    component: <TenantMachinesMaster />,
    layout: "/admin",
  },

  // {
  //   path: "/Work-schedule-Master",
  //   name: "Work schedule Master",
  //   icon: "tim-icons icon-align-center",
  //   component: <WorkscheduleMaster />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   icon: "tim-icons icon-align-center",
  //   component: <Typography />,
  //   layout: "/admin",
  // },
  {
    path: "/Udp-Parameter-Mapping",
    name: "Udp Parameter Mapping",

    icon: "tim-icons icon-world",
    component: <UdpParameterMapping />,
    layout: "/admin",
  },
];
export default routes;
