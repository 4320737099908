// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tbl-row {
  background: #3e4264;
  height: 5px !important;
}

.tbl-cell {
  height: 5px;
  color: aliceblue;
}

.tbl-row1 {
  background: #3e4264;
}

.dynamic-tbl {
  width: 300%;
  margin: 0 auto;
}

.dy-txt-fld {
  height: 0%;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
  color: antiquewhite;
}
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
  color: aliceblue;
}

.subbtnopr{
  margin-left: 570px !important;
  width: 25px;
}
.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-select.MuiSelect-selectMenu {
  color: aliceblue !important;
}
.container {
  max-width: 1340px !important;
}
.card {
  width: 100% !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/DynamicTable/Dynamictable.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;EAC7B,WAAW;AACb;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,4BAA4B;AAC9B;AACA;EACE,sBAAsB;AACxB","sourcesContent":[".tbl-row {\n  background: #3e4264;\n  height: 5px !important;\n}\n\n.tbl-cell {\n  height: 5px;\n  color: aliceblue;\n}\n\n.tbl-row1 {\n  background: #3e4264;\n}\n\n.dynamic-tbl {\n  width: 300%;\n  margin: 0 auto;\n}\n\n.dy-txt-fld {\n  height: 0%;\n}\n\n.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{\n  color: antiquewhite;\n}\n.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{\n  color: aliceblue;\n}\n\n.subbtnopr{\n  margin-left: 570px !important;\n  width: 25px;\n}\n.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-select.MuiSelect-selectMenu {\n  color: aliceblue !important;\n}\n.container {\n  max-width: 1340px !important;\n}\n.card {\n  width: 100% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
