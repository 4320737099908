import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Button, Card, CardHeader, CardBody, ButtonGroup } from "reactstrap";
import OEEGridByMachine from "./OeeGridByMachine";
import OEEGridByShift from "./OeeGridByShift";
import OLEGridByOperator from "./OleGridByOperator";
import OLEGridByShift from "./OleGridByShift";
import OLEGridByOperatorExperiance from "./OleGridByOperatorExperiance";
import OLEGridByOperatorEducation from "./OleGridOperatorByEducation";
import "./Oee.css";
import classNames from "classnames";
import { QueryApi } from "QueryApi";

function OeeGrids() {
  const [firstChart, setFirstChart] = useState("data1");
  const [SecondChart, setSecondChart] = useState("data1");
  const [ThirdChart, setThirdChart] = useState("data1");
  const [FourthChart, setFourthChart] = useState("data1");
  const [FifthChart, setFifthChart] = useState("data1");
  const [SixChart, setSixChart] = useState("data1");
  const [SevenChart, setSevenChart] = useState("data1");
  const [EightChart, setEightChart] = useState("data1");
  const [OeeMachineDate, setOeeMachineDate] = useState([]);
  const [OeeShiftDataByData, setOeeShiftDataByData] = useState([]);
  const [OleOperatorData, setOleOperatorData] = useState([]);
  const [OleShiftData, setOleShiftData] = useState([]);
  const [OleOperatorExperiance, setOleOperatorExperiance] = useState([]);
  const [OleOperatorEducation, setOleOperatorEducation] = useState([]);

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const currDay = day < 10 ? `0${day}` : day;
  const currMonth = month < 10 ? `0${month}` : month;
  let currentDate = JSON.stringify(`${year}-${currMonth}-${currDay}`);

  const dateData = new Date();
  dateData.setDate(dateData.getDate() - 7);
  var dayDataValue = dateData.getDate();
  var monthDataValue = dateData.getMonth() + 1;
  var yearDataValue = dateData.getFullYear();
  var formattedDay = dayDataValue < 10 ? `0${dayDataValue}` : dayDataValue;
  var formattedMonth =
    monthDataValue < 10 ? `0${monthDataValue}` : monthDataValue;
  var weekDataDate = JSON.stringify(
    `${yearDataValue}-${formattedMonth}-${formattedDay}`
  );

  const previousDate = new Date();
  previousDate.setDate(1);
  const previousDay = previousDate.getDate();
  const previousMonth = previousDate.getMonth() + 1;
  const previousYear = previousDate.getFullYear();
  const formattedPreviousDay =
    previousDay < 10 ? `0${previousDay}` : previousDay;
  const formattedPreviousMonth =
    previousMonth < 10 ? `0${previousMonth}` : previousMonth;
  const previousMonthDate = JSON.stringify(
    `${previousYear}-${formattedPreviousMonth}-${formattedPreviousDay}`
  );

  const yearCurrentDate = new Date();
  yearCurrentDate.setDate(1);
  yearCurrentDate.getMonth(1);
  const yearDay = yearCurrentDate.getDate();
  const yearMonth = yearCurrentDate.getMonth();
  const yearYear = yearCurrentDate.getFullYear();
  const formattedYearDay = yearDay < 10 ? `0${yearDay}` : yearDay;
  const formattedYearMonth = yearMonth < 10 ? `0${yearMonth}` : yearMonth;
  const yearPreviousMonthDate = JSON.stringify(
    `${yearYear}-${formattedYearMonth}-${formattedYearDay}`
  );

  ///// OEE By Machine ///////////////////////////////////////////////////////////

  useEffect(() => {
    FirstChartData();
  }, [firstChart]);
  const FirstChartData = async () => {
    if (firstChart === "data1") {
      try {
        const data = await QueryApi(`
        query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
          fl2_oee(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
            machineid
            machine_availability_percent
            machine_performance_percent
            oee
            quality_percent
          }
        } `);
        const groupedData = groupBy(data.fl2_oee, "machineid");
        const combinedData = Object.keys(groupedData).map((machineid) => {
          const items = groupedData[machineid];
          const machine_availability_percent_avg = average(
            items,
            "machine_availability_percent"
          );
          const machine_performance_percent_avg = average(
            items,
            "machine_performance_percent"
          );
          const quality_percent_avg = average(items, "quality_percent");
          const product =
            (machine_availability_percent_avg *
              machine_performance_percent_avg *
              quality_percent_avg) /
            10000;
          return {
            machineid,
            machine_availability_percent: machine_availability_percent_avg,
            machine_performance_percent: machine_performance_percent_avg,
            oee: product,
            // oee: average(items, 'oee'),
            quality_percent: quality_percent_avg,
          };
        });
        setOeeMachineDate(combinedData);
      } catch (error) {}

      function groupBy(array, key) {
        return array.reduce((result, currentItem) => {
          (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
          );
          return result;
        }, {});
      }

      function average(array, key) {
        return (
          array.reduce((result, currentItem) => result + currentItem[key], 0) /
          array.length
        );
      }
    } else if (firstChart === "data2") {
      // week data API
      try {
        const data = await QueryApi(`
          query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            fl2_oee(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              machineid
              machine_availability_percent
              machine_performance_percent
              oee
              quality_percent
            }
          } `);
        const groupedData = groupBy(data.fl2_oee, "machineid");
        const combinedData = Object.keys(groupedData).map((machineid) => {
          const items = groupedData[machineid];
          const machine_availability_percent_avg = average(
            items,
            "machine_availability_percent"
          );
          const machine_performance_percent_avg = average(
            items,
            "machine_performance_percent"
          );
          const quality_percent_avg = average(items, "quality_percent");
          const product =
            (machine_availability_percent_avg *
              machine_performance_percent_avg *
              quality_percent_avg) /
            10000;
          return {
            machineid,
            machine_availability_percent: machine_availability_percent_avg,
            machine_performance_percent: machine_performance_percent_avg,
            oee: product,
            // oee: average(items, 'oee'),
            quality_percent: quality_percent_avg,
          };
        });
        setOeeMachineDate(combinedData);
      } catch (error) {}

      function groupBy(array, key) {
        return array.reduce((result, currentItem) => {
          (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
          );
          return result;
        }, {});
      }

      function average(array, key) {
        return (
          array.reduce((result, currentItem) => result + currentItem[key], 0) /
          array.length
        );
      }
    } else if (firstChart === "data3") {
      // month data API
      try {
        const data = await QueryApi(`
          query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            fl2_oee(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              machineid
              machine_availability_percent
              machine_performance_percent
              oee
              quality_percent
            }
          } `);
        const groupedData = groupBy(data.fl2_oee, "machineid");
        const combinedData = Object.keys(groupedData).map((machineid) => {
          const items = groupedData[machineid];
          const machine_availability_percent_avg = average(
            items,
            "machine_availability_percent"
          );
          const machine_performance_percent_avg = average(
            items,
            "machine_performance_percent"
          );
          const quality_percent_avg = average(items, "quality_percent");
          const product =
            (machine_availability_percent_avg *
              machine_performance_percent_avg *
              quality_percent_avg) /
            10000;
          return {
            machineid,
            machine_availability_percent: machine_availability_percent_avg,
            machine_performance_percent: machine_performance_percent_avg,
            oee: product,
            // oee: average(items, 'oee'),
            quality_percent: quality_percent_avg,
          };
        });
        setOeeMachineDate(combinedData);
      } catch (error) {}

      function groupBy(array, key) {
        return array.reduce((result, currentItem) => {
          (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
          );
          return result;
        }, {});
      }

      function average(array, key) {
        return (
          array.reduce((result, currentItem) => result + currentItem[key], 0) /
          array.length
        );
      }
    } else if (firstChart === "data4") {
      // year data API
      try {
        const data = await QueryApi(`
          query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            fl2_oee(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              machineid
              machine_availability_percent
              machine_performance_percent
              oee
              quality_percent
            }
          } `);
        const groupedData = groupBy(data.fl2_oee, "machineid");
        const combinedData = Object.keys(groupedData).map((machineid) => {
          const items = groupedData[machineid];
          const machine_availability_percent_avg = average(
            items,
            "machine_availability_percent"
          );
          const machine_performance_percent_avg = average(
            items,
            "machine_performance_percent"
          );
          const quality_percent_avg = average(items, "quality_percent");
          const product =
            (machine_availability_percent_avg *
              machine_performance_percent_avg *
              quality_percent_avg) /
            10000;
          return {
            machineid,
            machine_availability_percent: machine_availability_percent_avg,
            machine_performance_percent: machine_performance_percent_avg,
            oee: product,
            // oee: average(items, 'oee'),
            quality_percent: quality_percent_avg,
          };
        });
        setOeeMachineDate(combinedData);
      } catch (error) {}

      function groupBy(array, key) {
        return array.reduce((result, currentItem) => {
          (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
          );
          return result;
        }, {});
      }

      function average(array, key) {
        return (
          array.reduce((result, currentItem) => result + currentItem[key], 0) /
          array.length
        );
      }
    } else {
      setFirstChart("data1");
    }
  };

  ///// OEE By Shift ///////////////////////////////////////////////////////////

  useEffect(() => {
    SecondChartData();
  }, [SecondChart]);
  const SecondChartData = async () => {
    if (SecondChart === "data1") {
      //  day data API
      try {
        const data = await QueryApi(`
      query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        fl2_oee_by_shift(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
          date
          machineid
          shift
          machine_availability_percent
          machine_performance_percent
          quality_percent
          oee
        }
      } `);
        const groupedData = groupBy(data.fl2_oee_by_shift, "shift");
        const combinedData = Object.keys(groupedData).map((shift) => {
          const items = groupedData[shift];
          const machine_availability_percent_avg = average(
            items,
            "machine_availability_percent"
          );
          const machine_performance_percent_avg = average(
            items,
            "machine_performance_percent"
          );
          const quality_percent_avg = average(items, "quality_percent");
          const product =
            (machine_availability_percent_avg *
              machine_performance_percent_avg *
              quality_percent_avg) /
            10000;
          return {
            shift,
            machine_availability_percent: machine_availability_percent_avg,
            machine_performance_percent: machine_performance_percent_avg,
            oee: product,
            // oee: average(items, 'oee'),
            quality_percent: quality_percent_avg,
          };
        });
        setOeeShiftDataByData(combinedData);
      } catch (error) {}

      function groupBy(array, key) {
        return array.reduce((result, currentItem) => {
          (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
          );
          return result;
        }, {});
      }

      function average(array, key) {
        return (
          array.reduce((result, currentItem) => result + currentItem[key], 0) /
          array.length
        );
      }
    } else if (SecondChart === "data2") {
      // shift weekly
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        fl2_oee_by_shift(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
          date
          machineid
          shift
          machine_availability_percent
          machine_performance_percent
          quality_percent
          oee
        }
      } `);
        const groupedData = groupBy(data.fl2_oee_by_shift, "shift");
        const combinedData = Object.keys(groupedData).map((shift) => {
          const items = groupedData[shift];
          const machine_availability_percent_avg = average(
            items,
            "machine_availability_percent"
          );
          const machine_performance_percent_avg = average(
            items,
            "machine_performance_percent"
          );
          const quality_percent_avg = average(items, "quality_percent");
          const product =
            (machine_availability_percent_avg *
              machine_performance_percent_avg *
              quality_percent_avg) /
            10000;
          return {
            shift,
            machine_availability_percent: machine_availability_percent_avg,
            machine_performance_percent: machine_performance_percent_avg,
            oee: product,
            // oee: average(items, 'oee'),
            quality_percent: quality_percent_avg,
          };
        });
        setOeeShiftDataByData(combinedData);
      } catch (error) {}

      function groupBy(array, key) {
        return array.reduce((result, currentItem) => {
          (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
          );
          return result;
        }, {});
      }

      function average(array, key) {
        return (
          array.reduce((result, currentItem) => result + currentItem[key], 0) /
          array.length
        );
      }
    } else if (SecondChart === "data3") {
      // month data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        fl2_oee_by_shift(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
          date
          machineid
          shift
          machine_availability_percent
          machine_performance_percent
          quality_percent
          oee
        }
      } `);
        const groupedData = groupBy(data.fl2_oee_by_shift, "shift");
        const combinedData = Object.keys(groupedData).map((shift) => {
          const items = groupedData[shift];
          const machine_availability_percent_avg = average(
            items,
            "machine_availability_percent"
          );
          const machine_performance_percent_avg = average(
            items,
            "machine_performance_percent"
          );
          const quality_percent_avg = average(items, "quality_percent");
          const product =
            (machine_availability_percent_avg *
              machine_performance_percent_avg *
              quality_percent_avg) /
            10000;
          return {
            shift,
            machine_availability_percent: machine_availability_percent_avg,
            machine_performance_percent: machine_performance_percent_avg,
            oee: product,
            // oee: average(items, 'oee'),
            quality_percent: quality_percent_avg,
          };
        });
        setOeeShiftDataByData(combinedData);
      } catch (error) {}

      function groupBy(array, key) {
        return array.reduce((result, currentItem) => {
          (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
          );
          return result;
        }, {});
      }

      function average(array, key) {
        return (
          array.reduce((result, currentItem) => result + currentItem[key], 0) /
          array.length
        );
      }
    } else if (SecondChart === "data4") {
      // year data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        fl2_oee_by_shift(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
          date
          machineid
          shift
          machine_availability_percent
          machine_performance_percent
          quality_percent
          oee
        }
      } `);
        const groupedData = groupBy(data.fl2_oee_by_shift, "shift");
        const combinedData = Object.keys(groupedData).map((shift) => {
          const items = groupedData[shift];
          const machine_availability_percent_avg = average(
            items,
            "machine_availability_percent"
          );
          const machine_performance_percent_avg = average(
            items,
            "machine_performance_percent"
          );
          const quality_percent_avg = average(items, "quality_percent");
          const product =
            (machine_availability_percent_avg *
              machine_performance_percent_avg *
              quality_percent_avg) /
            10000;
          return {
            shift,
            machine_availability_percent: machine_availability_percent_avg,
            machine_performance_percent: machine_performance_percent_avg,
            oee: product,
            // oee: average(items, 'oee'),
            quality_percent: quality_percent_avg,
          };
        });
        setOeeShiftDataByData(combinedData);
      } catch (error) {}

      function groupBy(array, key) {
        return array.reduce((result, currentItem) => {
          (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
          );
          return result;
        }, {});
      }

      function average(array, key) {
        return (
          array.reduce((result, currentItem) => result + currentItem[key], 0) /
          array.length
        );
      }
    } else {
      setSecondChart("data1");
    }
  };

  ///// OEE By Part ///////////////////////////////////////////////////////////

  useEffect(() => {
    ThirdChartData();
  }, [ThirdChart]);
  const ThirdChartData = async () => {
    if (ThirdChart === "data1") {
      //  day data API
    } else if (ThirdChart === "data2") {
      // week data API
    } else if (ThirdChart === "data3") {
      // month data API
    } else if (ThirdChart === "data4") {
      // year data API
    } else {
      setThirdChart("data1");
    }
  };

  ///// OLE By Operator ///////////////////////////////////////////////////////////

  useEffect(() => {
    FourthChartData();
  }, [FourthChart]);
  const FourthChartData = async () => {
    if (FourthChart === "data1") {
      //  day data API
      try {
        const data = await QueryApi(`
      query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        wfl0_overall_labor_effectiveness(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
          date
          operator_id
          machineid
          time_efficiency_per
          production_efficiency_per
          quality_efficiency_per
          overall_labor_effectiveness
          operator {
            employee_id
            first_name
            last_name
          }
        }
      } `);
        const groupedData = groupBy(
          data.wfl0_overall_labor_effectiveness,
          "operator_id"
        );
        const combinedData = Object.keys(groupedData).map((operator_id) => {
          const items = groupedData[operator_id];
          const time_efficiency_per_avg = average(items, "time_efficiency_per");
          const production_efficiency_per_avg = average(
            items,
            "production_efficiency_per"
          );
          const quality_efficiency_per_avg = average(
            items,
            "quality_efficiency_per"
          );
          const product =
            (time_efficiency_per_avg *
              production_efficiency_per_avg *
              quality_efficiency_per_avg) /
            10000;
          const operator_name =
            items[0].operator.first_name + " " + items[0].operator.last_name;
          return {
            operator_id,
            operator_name,
            time_efficiency_per: time_efficiency_per_avg,
            production_efficiency_per: production_efficiency_per_avg,
            overall_labor_effectiveness: product,
            quality_efficiency_per: quality_efficiency_per_avg,
          };
        });
        setOleOperatorData(combinedData);
      } catch (error) {}

      function groupBy(array, key) {
        return array.reduce((result, currentItem) => {
          (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
          );
          return result;
        }, {});
      }

      function average(array, key) {
        return (
          array.reduce((result, currentItem) => result + currentItem[key], 0) /
          array.length
        );
      }
    } else if (FourthChart === "data2") {
      // week data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        wfl0_overall_labor_effectiveness(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
          date
          operator_id
          machineid
          time_efficiency_per
          production_efficiency_per
          quality_efficiency_per
          overall_labor_effectiveness
          operator {
            employee_id
            first_name
            last_name
          }
        }
      } `);
        const groupedData = groupBy(
          data.wfl0_overall_labor_effectiveness,
          "operator_id"
        );
        const combinedData = Object.keys(groupedData).map((operator_id) => {
          const items = groupedData[operator_id];
          const time_efficiency_per_avg = average(items, "time_efficiency_per");
          const production_efficiency_per_avg = average(
            items,
            "production_efficiency_per"
          );
          const quality_efficiency_per_avg = average(
            items,
            "quality_efficiency_per"
          );
          const product =
            (time_efficiency_per_avg *
              production_efficiency_per_avg *
              quality_efficiency_per_avg) /
            10000;
          const operator_name =
            items[0].operator.first_name + " " + items[0].operator.last_name;
          return {
            operator_id,
            operator_name,
            time_efficiency_per: time_efficiency_per_avg,
            production_efficiency_per: production_efficiency_per_avg,
            overall_labor_effectiveness: product,
            quality_efficiency_per: quality_efficiency_per_avg,
          };
        });
        setOleOperatorData(combinedData);
      } catch (error) {}

      function groupBy(array, key) {
        return array.reduce((result, currentItem) => {
          (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
          );
          return result;
        }, {});
      }

      function average(array, key) {
        return (
          array.reduce((result, currentItem) => result + currentItem[key], 0) /
          array.length
        );
      }
    } else if (FourthChart === "data3") {
      // month data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        wfl0_overall_labor_effectiveness(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
          date
          operator_id
          machineid
          time_efficiency_per
          production_efficiency_per
          quality_efficiency_per
          overall_labor_effectiveness
          operator {
            employee_id
            first_name
            last_name
          }
        }
      } `);
        const groupedData = groupBy(
          data.wfl0_overall_labor_effectiveness,
          "operator_id"
        );
        const combinedData = Object.keys(groupedData).map((operator_id) => {
          const items = groupedData[operator_id];
          const time_efficiency_per_avg = average(items, "time_efficiency_per");
          const production_efficiency_per_avg = average(
            items,
            "production_efficiency_per"
          );
          const quality_efficiency_per_avg = average(
            items,
            "quality_efficiency_per"
          );
          const product =
            (time_efficiency_per_avg *
              production_efficiency_per_avg *
              quality_efficiency_per_avg) /
            10000;
          const operator_name =
            items[0].operator.first_name + " " + items[0].operator.last_name;
          return {
            operator_id,
            operator_name,
            time_efficiency_per: time_efficiency_per_avg,
            production_efficiency_per: production_efficiency_per_avg,
            overall_labor_effectiveness: product,
            quality_efficiency_per: quality_efficiency_per_avg,
          };
        });
        setOleOperatorData(combinedData);
      } catch (error) {}

      function groupBy(array, key) {
        return array.reduce((result, currentItem) => {
          (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
          );
          return result;
        }, {});
      }

      function average(array, key) {
        return (
          array.reduce((result, currentItem) => result + currentItem[key], 0) /
          array.length
        );
      }
    } else if (FourthChart === "data4") {
      // year data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        wfl0_overall_labor_effectiveness(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
          date
          operator_id
          machineid
          time_efficiency_per
          production_efficiency_per
          quality_efficiency_per
          overall_labor_effectiveness
          operator {
            employee_id
            first_name
            last_name
          }
        }
      } `);
        const groupedData = groupBy(
          data.wfl0_overall_labor_effectiveness,
          "operator_id"
        );
        const combinedData = Object.keys(groupedData).map((operator_id) => {
          const items = groupedData[operator_id];
          const time_efficiency_per_avg = average(items, "time_efficiency_per");
          const production_efficiency_per_avg = average(
            items,
            "production_efficiency_per"
          );
          const quality_efficiency_per_avg = average(
            items,
            "quality_efficiency_per"
          );
          const product =
            (time_efficiency_per_avg *
              production_efficiency_per_avg *
              quality_efficiency_per_avg) /
            10000;
          const operator_name = items[0].operator
            ? items[0].operator.first_name + " " + items[0].operator.last_name
            : "Name Not Found";
          // const operator_name = items[0].operator.first_name + ' ' + items[0].operator.last_name;
          return {
            operator_id,
            operator_name,
            time_efficiency_per: time_efficiency_per_avg,
            production_efficiency_per: production_efficiency_per_avg,
            overall_labor_effectiveness: product,
            quality_efficiency_per: quality_efficiency_per_avg,
          };
        });
        setOleOperatorData(combinedData);
      } catch (error) {}

      function groupBy(array, key) {
        return array.reduce((result, currentItem) => {
          (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
          );
          return result;
        }, {});
      }

      function average(array, key) {
        return (
          array.reduce((result, currentItem) => result + currentItem[key], 0) /
          array.length
        );
      }
    } else {
      setFourthChart("data1");
    }
  };

  ///// OLE By Shift ///////////////////////////////////////////////////////////

  useEffect(() => {
    FifthChartData();
  }, [FifthChart]);
  const FifthChartData = async () => {
    if (FifthChart === "data1") {
      //  day data API
      try {
        const data = await QueryApi(`
      query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        wfl0_workforce_efficiency_shiftwise(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
          date
          shift
          time_efficiency_per
          production_efficiency_per
          quality_efficiency_per
          overall_labor_effectiveness
        }
      } `);
        const groupedData = groupBy(
          data.wfl0_workforce_efficiency_shiftwise,
          "shift"
        );
        const combinedData = Object.keys(groupedData).map((shift) => {
          const items = groupedData[shift];
          const time_efficiency_per_avg = average(items, "time_efficiency_per");
          const production_efficiency_per_avg = average(
            items,
            "production_efficiency_per"
          );
          const quality_efficiency_per_avg = average(
            items,
            "quality_efficiency_per"
          );
          const product =
            (time_efficiency_per_avg *
              production_efficiency_per_avg *
              quality_efficiency_per_avg) /
            10000;

          return {
            shift,
            time_efficiency_per: time_efficiency_per_avg,
            production_efficiency_per: production_efficiency_per_avg,
            overall_labor_effectiveness: product,
            quality_efficiency_per: quality_efficiency_per_avg,
          };
        });
        setOleShiftData(combinedData);
      } catch (error) {}

      function groupBy(array, key) {
        return array.reduce((result, currentItem) => {
          (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
          );
          return result;
        }, {});
      }

      function average(array, key) {
        return (
          array.reduce((result, currentItem) => result + currentItem[key], 0) /
          array.length
        );
      }
    } else if (FifthChart === "data2") {
      // week data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        wfl0_workforce_efficiency_shiftwise(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
          date
          shift
          time_efficiency_per
          production_efficiency_per
          quality_efficiency_per
          overall_labor_effectiveness
        }
      } `);
        const groupedData = groupBy(
          data.wfl0_workforce_efficiency_shiftwise,
          "shift"
        );
        const combinedData = Object.keys(groupedData).map((shift) => {
          const items = groupedData[shift];
          const time_efficiency_per_avg = average(items, "time_efficiency_per");
          const production_efficiency_per_avg = average(
            items,
            "production_efficiency_per"
          );
          const quality_efficiency_per_avg = average(
            items,
            "quality_efficiency_per"
          );
          const product =
            (time_efficiency_per_avg *
              production_efficiency_per_avg *
              quality_efficiency_per_avg) /
            10000;

          return {
            shift,
            time_efficiency_per: time_efficiency_per_avg,
            production_efficiency_per: production_efficiency_per_avg,
            overall_labor_effectiveness: product,
            quality_efficiency_per: quality_efficiency_per_avg,
          };
        });
        setOleShiftData(combinedData);
      } catch (error) {}

      function groupBy(array, key) {
        return array.reduce((result, currentItem) => {
          (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
          );
          return result;
        }, {});
      }

      function average(array, key) {
        return (
          array.reduce((result, currentItem) => result + currentItem[key], 0) /
          array.length
        );
      }
    } else if (FifthChart === "data3") {
      // month data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        wfl0_workforce_efficiency_shiftwise(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
          date
          shift
          time_efficiency_per
          production_efficiency_per
          quality_efficiency_per
          overall_labor_effectiveness
        }
      } `);
        const groupedData = groupBy(
          data.wfl0_workforce_efficiency_shiftwise,
          "shift"
        );
        const combinedData = Object.keys(groupedData).map((shift) => {
          const items = groupedData[shift];
          const time_efficiency_per_avg = average(items, "time_efficiency_per");
          const production_efficiency_per_avg = average(
            items,
            "production_efficiency_per"
          );
          const quality_efficiency_per_avg = average(
            items,
            "quality_efficiency_per"
          );
          const product =
            (time_efficiency_per_avg *
              production_efficiency_per_avg *
              quality_efficiency_per_avg) /
            10000;

          return {
            shift,
            time_efficiency_per: time_efficiency_per_avg,
            production_efficiency_per: production_efficiency_per_avg,
            overall_labor_effectiveness: product,
            quality_efficiency_per: quality_efficiency_per_avg,
          };
        });
        setOleShiftData(combinedData);
      } catch (error) {}

      function groupBy(array, key) {
        return array.reduce((result, currentItem) => {
          (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
          );
          return result;
        }, {});
      }

      function average(array, key) {
        return (
          array.reduce((result, currentItem) => result + currentItem[key], 0) /
          array.length
        );
      }
    } else if (FifthChart === "data4") {
      // year data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        wfl0_workforce_efficiency_shiftwise(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
          date
          shift
          time_efficiency_per
          production_efficiency_per
          quality_efficiency_per
          overall_labor_effectiveness
        }
      } `);
        const groupedData = groupBy(
          data.wfl0_workforce_efficiency_shiftwise,
          "shift"
        );
        const combinedData = Object.keys(groupedData).map((shift) => {
          const items = groupedData[shift];
          const time_efficiency_per_avg = average(items, "time_efficiency_per");
          const production_efficiency_per_avg = average(
            items,
            "production_efficiency_per"
          );
          const quality_efficiency_per_avg = average(
            items,
            "quality_efficiency_per"
          );
          const product =
            (time_efficiency_per_avg *
              production_efficiency_per_avg *
              quality_efficiency_per_avg) /
            10000;

          return {
            shift,
            time_efficiency_per: time_efficiency_per_avg,
            production_efficiency_per: production_efficiency_per_avg,
            overall_labor_effectiveness: product,
            quality_efficiency_per: quality_efficiency_per_avg,
          };
        });
        setOleShiftData(combinedData);
      } catch (error) {}

      function groupBy(array, key) {
        return array.reduce((result, currentItem) => {
          (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
          );
          return result;
        }, {});
      }

      function average(array, key) {
        return (
          array.reduce((result, currentItem) => result + currentItem[key], 0) /
          array.length
        );
      }
    } else {
      setFifthChart("data1");
    }
  };

  ///// OLE By Part ///////////////////////////////////////////////////////////

  useEffect(() => {
    SixChartData();
  }, [SixChart]);
  const SixChartData = async () => {
    if (SixChart === "data1") {
      //  day data API
    } else if (SixChart === "data2") {
      // week data API
    } else if (SixChart === "data3") {
      // month data API
    } else if (SixChart === "data4") {
      // year data API
    } else {
      setSixChart("data1");
    }
  };

  ///// OLE By Experiance ///////////////////////////////////////////////////////////

  useEffect(() => {
    SevenChartData();
  }, [SevenChart]);
  const SevenChartData = async () => {
    if (SevenChart === "data1") {
      //  day data API
      try {
        const data = await QueryApi(`
      query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        wfl0_ole_by_experience(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                date
                experience
                machineid
                time_efficiency_per
                production_efficiency_per
                quality_efficiency_per
                overall_labor_effectiveness
        }
      } `);
        const groupedData = groupBy(data.wfl0_ole_by_experience, "experience");
        const combinedData = Object.keys(groupedData).map((experience) => {
          const items = groupedData[experience];
          const time_efficiency_per_avg = average(items, "time_efficiency_per");
          const production_efficiency_per_avg = average(
            items,
            "production_efficiency_per"
          );
          const quality_efficiency_per_avg = average(
            items,
            "quality_efficiency_per"
          );
          const product =
            (time_efficiency_per_avg *
              production_efficiency_per_avg *
              quality_efficiency_per_avg) /
            10000;

          return {
            experience,
            time_efficiency_per: time_efficiency_per_avg,
            production_efficiency_per: production_efficiency_per_avg,
            overall_labor_effectiveness: product,
            quality_efficiency_per: quality_efficiency_per_avg,
          };
        });
        setOleOperatorExperiance(combinedData);
      } catch (error) {}

      function groupBy(array, key) {
        return array.reduce((result, currentItem) => {
          (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
          );
          return result;
        }, {});
      }

      function average(array, key) {
        return (
          array.reduce((result, currentItem) => result + currentItem[key], 0) /
          array.length
        );
      }
    } else if (SevenChart === "data2") {
      // week data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        wfl0_ole_by_experience(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                date
                experience
                machineid
                time_efficiency_per
                production_efficiency_per
                quality_efficiency_per
                overall_labor_effectiveness
        }
      } `);
        const groupedData = groupBy(data.wfl0_ole_by_experience, "experience");
        const combinedData = Object.keys(groupedData).map((experience) => {
          const items = groupedData[experience];
          const time_efficiency_per_avg = average(items, "time_efficiency_per");
          const production_efficiency_per_avg = average(
            items,
            "production_efficiency_per"
          );
          const quality_efficiency_per_avg = average(
            items,
            "quality_efficiency_per"
          );
          const product =
            (time_efficiency_per_avg *
              production_efficiency_per_avg *
              quality_efficiency_per_avg) /
            10000;

          return {
            experience,
            time_efficiency_per: time_efficiency_per_avg,
            production_efficiency_per: production_efficiency_per_avg,
            overall_labor_effectiveness: product,
            quality_efficiency_per: quality_efficiency_per_avg,
          };
        });
        setOleOperatorExperiance(combinedData);
      } catch (error) {}

      function groupBy(array, key) {
        return array.reduce((result, currentItem) => {
          (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
          );
          return result;
        }, {});
      }

      function average(array, key) {
        return (
          array.reduce((result, currentItem) => result + currentItem[key], 0) /
          array.length
        );
      }
    } else if (SevenChart === "data3") {
      // month data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        wfl0_ole_by_experience(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                date
                experience
                machineid
                time_efficiency_per
                production_efficiency_per
                quality_efficiency_per
                overall_labor_effectiveness
        }
      } `);
        const groupedData = groupBy(data.wfl0_ole_by_experience, "experience");
        const combinedData = Object.keys(groupedData).map((experience) => {
          const items = groupedData[experience];
          const time_efficiency_per_avg = average(items, "time_efficiency_per");
          const production_efficiency_per_avg = average(
            items,
            "production_efficiency_per"
          );
          const quality_efficiency_per_avg = average(
            items,
            "quality_efficiency_per"
          );
          const product =
            (time_efficiency_per_avg *
              production_efficiency_per_avg *
              quality_efficiency_per_avg) /
            10000;

          return {
            experience,
            time_efficiency_per: time_efficiency_per_avg,
            production_efficiency_per: production_efficiency_per_avg,
            overall_labor_effectiveness: product,
            quality_efficiency_per: quality_efficiency_per_avg,
          };
        });
        setOleOperatorExperiance(combinedData);
      } catch (error) {}

      function groupBy(array, key) {
        return array.reduce((result, currentItem) => {
          (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
          );
          return result;
        }, {});
      }

      function average(array, key) {
        return (
          array.reduce((result, currentItem) => result + currentItem[key], 0) /
          array.length
        );
      }
    } else if (SevenChart === "data4") {
      // year data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        wfl0_ole_by_experience(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                date
                experience
                machineid
                time_efficiency_per
                production_efficiency_per
                quality_efficiency_per
                overall_labor_effectiveness
        }
      } `);
        const groupedData = groupBy(data.wfl0_ole_by_experience, "experience");
        const combinedData = Object.keys(groupedData).map((experience) => {
          const items = groupedData[experience];
          const time_efficiency_per_avg = average(items, "time_efficiency_per");
          const production_efficiency_per_avg = average(
            items,
            "production_efficiency_per"
          );
          const quality_efficiency_per_avg = average(
            items,
            "quality_efficiency_per"
          );
          const product =
            (time_efficiency_per_avg *
              production_efficiency_per_avg *
              quality_efficiency_per_avg) /
            10000;

          return {
            experience,
            time_efficiency_per: time_efficiency_per_avg,
            production_efficiency_per: production_efficiency_per_avg,
            overall_labor_effectiveness: product,
            quality_efficiency_per: quality_efficiency_per_avg,
          };
        });
        setOleOperatorExperiance(combinedData);
      } catch (error) {}

      function groupBy(array, key) {
        return array.reduce((result, currentItem) => {
          (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
          );
          return result;
        }, {});
      }

      function average(array, key) {
        return (
          array.reduce((result, currentItem) => result + currentItem[key], 0) /
          array.length
        );
      }
    } else {
      setSevenChart("data1");
    }
  };
  ///// OLE By Education ///////////////////////////////////////////////////////////

  useEffect(() => {
    EightChartData();
  }, [EightChart]);
  const EightChartData = async () => {
    if (EightChart === "data1") {
      //  day data API
      try {
        const data = await QueryApi(`
      query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        wfl0_ole_by_education(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                    date
                    education
                    machineid
                    time_efficiency_per
                    production_efficiency_per
                    quality_efficiency_per
                    overall_labor_effectiveness
        }
      } `);
        const groupedData = groupBy(data.wfl0_ole_by_education, "education");
        const combinedData = Object.keys(groupedData).map((education) => {
          const items = groupedData[education];
          const time_efficiency_per_avg = average(items, "time_efficiency_per");
          const production_efficiency_per_avg = average(
            items,
            "production_efficiency_per"
          );
          const quality_efficiency_per_avg = average(
            items,
            "quality_efficiency_per"
          );
          const product =
            (time_efficiency_per_avg *
              production_efficiency_per_avg *
              quality_efficiency_per_avg) /
            10000;

          return {
            education,
            time_efficiency_per: time_efficiency_per_avg,
            production_efficiency_per: production_efficiency_per_avg,
            overall_labor_effectiveness: product,
            quality_efficiency_per: quality_efficiency_per_avg,
          };
        });
        setOleOperatorEducation(combinedData);
      } catch (error) {}

      function groupBy(array, key) {
        return array.reduce((result, currentItem) => {
          (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
          );
          return result;
        }, {});
      }

      function average(array, key) {
        return (
          array.reduce((result, currentItem) => result + currentItem[key], 0) /
          array.length
        );
      }
    } else if (EightChart === "data2") {
      // week data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        wfl0_ole_by_education(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                    date
                    education
                    machineid
                    time_efficiency_per
                    production_efficiency_per
                    quality_efficiency_per
                    overall_labor_effectiveness
        }
      } `);
        const groupedData = groupBy(data.wfl0_ole_by_education, "education");
        const combinedData = Object.keys(groupedData).map((education) => {
          const items = groupedData[education];
          const time_efficiency_per_avg = average(items, "time_efficiency_per");
          const production_efficiency_per_avg = average(
            items,
            "production_efficiency_per"
          );
          const quality_efficiency_per_avg = average(
            items,
            "quality_efficiency_per"
          );
          const product =
            (time_efficiency_per_avg *
              production_efficiency_per_avg *
              quality_efficiency_per_avg) /
            10000;

          return {
            education,
            time_efficiency_per: time_efficiency_per_avg,
            production_efficiency_per: production_efficiency_per_avg,
            overall_labor_effectiveness: product,
            quality_efficiency_per: quality_efficiency_per_avg,
          };
        });
        setOleOperatorEducation(combinedData);
      } catch (error) {}

      function groupBy(array, key) {
        return array.reduce((result, currentItem) => {
          (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
          );
          return result;
        }, {});
      }

      function average(array, key) {
        return (
          array.reduce((result, currentItem) => result + currentItem[key], 0) /
          array.length
        );
      }
    } else if (EightChart === "data3") {
      // month data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        wfl0_ole_by_education(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                    date
                    education
                    machineid
                    time_efficiency_per
                    production_efficiency_per
                    quality_efficiency_per
                    overall_labor_effectiveness
        }
      } `);
        const groupedData = groupBy(data.wfl0_ole_by_education, "education");
        const combinedData = Object.keys(groupedData).map((education) => {
          const items = groupedData[education];
          const time_efficiency_per_avg = average(items, "time_efficiency_per");
          const production_efficiency_per_avg = average(
            items,
            "production_efficiency_per"
          );
          const quality_efficiency_per_avg = average(
            items,
            "quality_efficiency_per"
          );
          const product =
            (time_efficiency_per_avg *
              production_efficiency_per_avg *
              quality_efficiency_per_avg) /
            10000;

          return {
            education,
            time_efficiency_per: time_efficiency_per_avg,
            production_efficiency_per: production_efficiency_per_avg,
            overall_labor_effectiveness: product,
            quality_efficiency_per: quality_efficiency_per_avg,
          };
        });
        setOleOperatorEducation(combinedData);
      } catch (error) {}

      function groupBy(array, key) {
        return array.reduce((result, currentItem) => {
          (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
          );
          return result;
        }, {});
      }

      function average(array, key) {
        return (
          array.reduce((result, currentItem) => result + currentItem[key], 0) /
          array.length
        );
      }
    } else if (EightChart === "data4") {
      // year data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        wfl0_ole_by_education(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                    date
                    education
                    machineid
                    time_efficiency_per
                    production_efficiency_per
                    quality_efficiency_per
                    overall_labor_effectiveness
        }
      } `);
        const groupedData = groupBy(data.wfl0_ole_by_education, "education");
        const combinedData = Object.keys(groupedData).map((education) => {
          const items = groupedData[education];
          const time_efficiency_per_avg = average(items, "time_efficiency_per");
          const production_efficiency_per_avg = average(
            items,
            "production_efficiency_per"
          );
          const quality_efficiency_per_avg = average(
            items,
            "quality_efficiency_per"
          );
          const product =
            (time_efficiency_per_avg *
              production_efficiency_per_avg *
              quality_efficiency_per_avg) /
            10000;

          return {
            education,
            time_efficiency_per: time_efficiency_per_avg,
            production_efficiency_per: production_efficiency_per_avg,
            overall_labor_effectiveness: product,
            quality_efficiency_per: quality_efficiency_per_avg,
          };
        });
        setOleOperatorEducation(combinedData);
      } catch (error) {}

      function groupBy(array, key) {
        return array.reduce((result, currentItem) => {
          (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
          );
          return result;
        }, {});
      }

      function average(array, key) {
        return (
          array.reduce((result, currentItem) => result + currentItem[key], 0) /
          array.length
        );
      }
    } else {
      setEightChart("data1");
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="6">
            <Card className="Workforce-availability">
              <CardHeader className="oee-ole-header">
                <h4 className="card-category">OEE</h4>
              </CardHeader>
            </Card>
            <Row>
              <Col lg="12">
                <Card className="card-workforce" style={{ height: "336px" }}>
                  <CardHeader>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Col>
                          <h5 className="card-category">OEE By Machine</h5>
                        </Col>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ paddingRight: "5px" }}>
                          <ButtonGroup
                            className="btn-group-toggle float-right"
                            data-toggle="buttons"
                            // style={{ marginLeft: '50px' }}
                          >
                            <Button
                              tag="label"
                              className={classNames("btn-simple", {
                                active: firstChart === "data1",
                              })}
                              color="info"
                              id="0"
                              size="sm"
                              onClick={() => {
                                setFirstChart("data1");
                              }}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Day
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-single-02" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="1"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: firstChart === "data2",
                              })}
                              onClick={() => setFirstChart("data2")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Week
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-gift-2" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="2"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: firstChart === "data3",
                              })}
                              onClick={() => setFirstChart("data3")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Month
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-tap-02" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="2"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: firstChart === "data4",
                              })}
                              onClick={() => setFirstChart("data4")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Year
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-tap-02" />
                              </span>
                            </Button>
                          </ButtonGroup>
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <OEEGridByMachine tableData={OeeMachineDate} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card className="card-workforce" style={{ height: "336px" }}>
                  <CardHeader>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Col>
                          <h5 className="card-category">OEE By Shift</h5>
                        </Col>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ paddingRight: "5px" }}>
                          <ButtonGroup
                            className="btn-group-toggle float-right"
                            data-toggle="buttons"
                            // style={{ marginLeft: '50px' }}
                          >
                            <Button
                              tag="label"
                              className={classNames("btn-simple", {
                                active: SecondChart === "data1",
                              })}
                              color="info"
                              id="0"
                              size="sm"
                              onClick={() => setSecondChart("data1")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Day
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-single-02" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="1"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: SecondChart === "data2",
                              })}
                              onClick={() => setSecondChart("data2")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Week
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-gift-2" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="2"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: SecondChart === "data3",
                              })}
                              onClick={() => setSecondChart("data3")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Month
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-tap-02" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="2"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: SecondChart === "data4",
                              })}
                              onClick={() => setSecondChart("data4")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Year
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-tap-02" />
                              </span>
                            </Button>
                          </ButtonGroup>
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <OEEGridByShift tableData={OeeShiftDataByData} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
          {/* ///////////////////////////// OLE ////////////////////////////////////////////////// */}
          <Col lg="6">
            <Card className="Workforce-availability">
              <CardHeader className="oee-ole-header">
                <h4 className="card-category">OLE</h4>
              </CardHeader>
            </Card>
            <Row>
              <Col lg="12">
                <Card className="card-workforce" style={{ height: "336px" }}>
                  <CardHeader>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Col>
                          <h5 className="card-category">OLE By Operator </h5>
                        </Col>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ paddingRight: "5px" }}>
                          <ButtonGroup
                            className="btn-group-toggle float-right"
                            data-toggle="buttons"
                            // style={{ marginLeft: '50px' }}
                          >
                            <Button
                              tag="label"
                              className={classNames("btn-simple", {
                                active: FourthChart === "data1",
                              })}
                              color="info"
                              id="0"
                              size="sm"
                              onClick={() => setFourthChart("data1")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Day
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-single-02" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="1"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: FourthChart === "data2",
                              })}
                              onClick={() => setFourthChart("data2")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Week
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-gift-2" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="2"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: FourthChart === "data3",
                              })}
                              onClick={() => setFourthChart("data3")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Month
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-tap-02" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="2"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: FourthChart === "data4",
                              })}
                              onClick={() => setFourthChart("data4")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Year
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-tap-02" />
                              </span>
                            </Button>
                          </ButtonGroup>
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <OLEGridByOperator tableData={OleOperatorData} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card className="card-workforce" style={{ height: "336px" }}>
                  <CardHeader>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Col>
                          <h5 className="card-category">OLE By Shift </h5>
                        </Col>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ paddingRight: "5px" }}>
                          <ButtonGroup
                            className="btn-group-toggle float-right"
                            data-toggle="buttons"
                            // style={{ marginLeft: '50px' }}
                          >
                            <Button
                              tag="label"
                              className={classNames("btn-simple", {
                                active: FifthChart === "data1",
                              })}
                              color="info"
                              id="0"
                              size="sm"
                              onClick={() => setFifthChart("data1")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Day
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-single-02" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="1"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: FifthChart === "data2",
                              })}
                              onClick={() => setFifthChart("data2")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Week
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-gift-2" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="2"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: FifthChart === "data3",
                              })}
                              onClick={() => setFifthChart("data3")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Month
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-tap-02" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="2"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: FifthChart === "data4",
                              })}
                              onClick={() => setFifthChart("data4")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Year
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-tap-02" />
                              </span>
                            </Button>
                          </ButtonGroup>
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <OLEGridByShift tableData={OleShiftData} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card className="card-workforce" style={{ height: "336px" }}>
                  <CardHeader>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Col>
                          <h5 className="card-category">OLE By Experiance </h5>
                        </Col>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ paddingRight: "5px" }}>
                          <ButtonGroup
                            className="btn-group-toggle float-right"
                            data-toggle="buttons"
                            // style={{ marginLeft: '50px' }}
                          >
                            <Button
                              tag="label"
                              className={classNames("btn-simple", {
                                active: SevenChart === "data1",
                              })}
                              color="info"
                              id="0"
                              size="sm"
                              onClick={() => setSevenChart("data1")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Day
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-single-02" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="1"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: SevenChart === "data2",
                              })}
                              onClick={() => setSevenChart("data2")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Week
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-gift-2" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="2"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: SevenChart === "data3",
                              })}
                              onClick={() => setSevenChart("data3")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Month
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-tap-02" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="2"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: SevenChart === "data4",
                              })}
                              onClick={() => setSevenChart("data4")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Year
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-tap-02" />
                              </span>
                            </Button>
                          </ButtonGroup>
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <OLEGridByOperatorExperiance
                      tableData={OleOperatorExperiance}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card className="card-workforce" style={{ height: "336px" }}>
                  <CardHeader>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Col>
                          <h5 className="card-category">OLE By Education </h5>
                        </Col>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ paddingRight: "5px" }}>
                          <ButtonGroup
                            className="btn-group-toggle float-right"
                            data-toggle="buttons"
                            // style={{ marginLeft: '50px' }}
                          >
                            <Button
                              tag="label"
                              className={classNames("btn-simple", {
                                active: EightChart === "data1",
                              })}
                              color="info"
                              id="0"
                              size="sm"
                              onClick={() => setEightChart("data1")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Day
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-single-02" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="1"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: EightChart === "data2",
                              })}
                              onClick={() => setEightChart("data2")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Week
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-gift-2" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="2"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: EightChart === "data3",
                              })}
                              onClick={() => setEightChart("data3")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Month
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-tap-02" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="2"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: EightChart === "data4",
                              })}
                              onClick={() => setEightChart("data4")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Year
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-tap-02" />
                              </span>
                            </Button>
                          </ButtonGroup>
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <OLEGridByOperatorEducation
                      tableData={OleOperatorEducation}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default OeeGrids;
