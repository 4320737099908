import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "./Apiurl.js";
import redirectToLoginIfTokenMissing from "./TokenUtility";
import {
  TabContent,
  TabPane,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserLarge } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import Modal from "@material-ui/core/Modal";
import EmployeeAdd from "./EmployeeAdd";
import TenantEmployeeImport from "./TenantEmployeeImport";
import { Badge } from "reactstrap";
import EmpJobDescription from "components/EmployeeJD/EmpJobDescription.js";
const { SearchBar } = Search;

function JobrolemasterAll() {
  const [activeTab, setActiveTab] = useState(1);
  const [employees, setEmployees] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    fetchData();
    redirectToLoginIfTokenMissing();
  }, []);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleOpen1 = () => {
    setOpen1(true);
  };

  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const roleId = decodedToken["sub"];
      const logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
      const storedTenantId = sessionStorage.getItem("selectedTenantId");
      console.log(roleId === 1);

      let query = ""; // Define the query variable outside the if-else block
      let variables = {}; // Define the variables object

      if (roleId == 1) {
        if (storedTenantId !== null) {
          query = `
        query MyQuery($tenantId: Int!) {
          tenant_employees(where: { tenantid: { _eq: $tenantId } }) {
            employee_id
          first_name
          middle_name
          employee_code
          is_active
          last_name
          unit {
            organization_node_name
          }
          organization {
            organization_node_name
          }
          dept {
            organization_node_name
          }
          empcategory {
            code
          }
          emptype {
            code
          }
          jobrole {
            job_role
          }
          reportingmanager {
            first_name
            middle_name
            last_name
          }
          created_at
          contact_number
          updated_at
          }
        }
      `;

          variables = {
            tenantId: storedTenantId,
          };
        } else {
          query = `
        query {
          tenant_employees {
            employee_id
            first_name
            middle_name
            employee_code
            is_active
            last_name
            unit {
              organization_node_name
            }
            organization {
              organization_node_name
            }
            dept {
              organization_node_name
            }
            empcategory {
              code
            }
            emptype {
              code
            }
            jobrole {
              job_role
            }
            reportingmanager {
              first_name
              middle_name
              last_name
            }
            created_at
            contact_number
            updated_at
          }
        }
        `;
        }
      } else {
        query = `
      query {
        tenant_employees {
          employee_id
          first_name
          middle_name
          employee_code
          is_active
          last_name
          unit {
            organization_node_name
          }
          organization {
            organization_node_name
          }
          dept {
            organization_node_name
          }
          empcategory {
            code
          }
          emptype {
            code
          }
          jobrole {
            job_role
          }
          reportingmanager {
            first_name
            middle_name
            last_name
          }
          created_at
          contact_number
          updated_at
        }
      }
      `;
      }

      const response = await axios.post(
        APIURL,
        {
          query: query,
          variables: variables,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      setEmployees(response.data.data.tenant_employees);
      console.log(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  function actionFormatter(cell, row) {
    return (
      <div>
        <Col
          className="font-icon-list col-xs-6 col-xs-6"
          lg="2"
          md="3"
          sm="4"
          onClick={() => handleEdit(row)}
        ></Col>
        <FontAwesomeIcon
          // icon={faPersonCircleCheck}
          className="btn btn-primary tim-icons icon-pencil"
          style={{
            fontSize: "70%",
            background: "none",
            marginRight: "-46px",
            pointerEvents: "none",
          }}
        />
        <Link to={`/admin/prof-job/${row.employee_id}`}>
          {" "}
          {/* Use Link instead of onClick */}
          <FontAwesomeIcon
            // icon={faPersonCircleCheck}
            // icon={faUsersCog}

            icon={faUserLarge}
            className="btn btn-primary tim-icons icon-pencil"
            style={{
              fontSize: "70%",
              background: "none",
              pointerEvents: "none",
            }}
          />
        </Link>
      </div>
    );
  }

  function handleEdit(row) {
    console.log("Editing row:", row);
  }
  const activeFormatter = (cell, row) => {
    const isActive = row.is_active === "1";

    return (
      <Badge color={isActive ? "info" : "danger"}>
        {isActive ? "Active" : "Inactive"}
      </Badge>
    );
  };
  const columns = [
    {
      dataField: "employee_id",
      text: "employee_id",
    },
    {
      dataField: "employee_code",
      text: "Employee Code",
    },
    {
      dataField: "full_name", // New dataField for the merged column
      text: "Full Name", // New text for the merged column
      formatter: (cell, row) =>
        `${row.first_name} ${row.middle_name} ${row.last_name}`,
    },
    {
      dataField: "organization.organization_node_name",
      text: "Organization",
    },
    {
      dataField: "unit.organization_node_name",
      text: "Plant / Unit Name",
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
    },
    {
      dataField: "empcategory.code",
      text: "Category",
    },
    {
      dataField: "emptype.code",
      text: "Employee Type",
    },
    {
      dataField: "jobrole.job_role",
      text: "Job Role",
    },
    {
      dataField: "reporting_manager",
      text: "Reporting Manager Id",
      formatter: (cell, row) => {
        if (row.reportingmanager) {
          return `${row.reportingmanager.first_name || ""} ${row.reportingmanager.middle_name || ""} ${row.reportingmanager.last_name || ""}`;
        } else {
          return "N/A"; // or any other default value
        }
      },
    },
    {
      dataField: "is_active",
      text: "Status",
      formatter: activeFormatter,
    },
    {
      dataField: "action",
      text: "Job Description",
      formatter: actionFormatter,
    },
  ];

  return (
    <div className="content">
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4" className="table-card-header">
                    Employee Master
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={employees}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <div>
                        <div
                          className="float-right"
                          style={{
                            display: "flex",
                            marginBottom: "18px",
                            gap: "12px",
                          }}
                        >
                          <div className="float-left">
                            <SearchBar {...props.searchProps} />
                          </div>
                          <div className="float-right">
                            <button
                              className="btn btn-secondary"
                              onClick={handleOpen}
                            >
                              Add new Employee
                            </button>
                            <button
                              className="btn btn-secondary"
                              onClick={handleOpen1}
                            >
                              Import CSV file
                            </button>
                          </div>
                        </div>
                        <BootstrapTable
                          {...props.baseProps}
                          keyField="id"
                          data={employees}
                          columns={columns}
                          filter={filterFactory()}
                          pagination={paginationFactory()}
                          headerClasses="sorted-asc sorted-desc"
                          wrapperClasses="table-responsive"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId={2}>{/* Content for Tab 2 */}</TabPane>
        <TabPane tabId={3}>{/* Content for Tab 3 */}</TabPane>
      </TabContent>
      <Modal
        onClose={handleClose}
        open={open}
        style={{
          boxShadow: "2px solid black",
          width: "100%",
          top: "15%",
          left: "15%",
        }}
      >
        <div>
          <button
            onClick={handleClose}
            style={{
              position: "fixed",
              top: "112px",
              right: "399px",
              background: "transparent",
              border: "none",
              color: "white",
              fontSize: "40px",
              /* margin-top: -85px; */
              cursor: "pointer",
              float: "right",
              zIndex: 1,
            }}
          >
            &times; {/* This is the 'x' character for the close button */}
          </button>
          <EmployeeAdd onInsertSuccess={fetchData} />
        </div>
      </Modal>
      <Modal
        onClose={handleClose1}
        open={open1}
        style={{
          boxShadow: "2px solid black",
          width: "100%",
          top: "15%",
          left: "15%",
        }}
      >
        <div>
          <button
            onClick={handleClose1}
            style={{
              position: "fixed",
              top: "112px",
              right: "399px",
              background: "transparent",
              border: "none",
              color: "white",
              fontSize: "40px",
              /* margin-top: -85px; */
              cursor: "pointer",
              float: "right",
              zIndex: 1,
            }}
          >
            &times; {/* This is the 'x' character for the close button */}
          </button>
          <TenantEmployeeImport />
        </div>
      </Modal>
      <Modal>
        <div className="content">
          {selectedRow && (
            <EmpJobDescription selectedRow={selectedRow} id={id} />
          )}
        </div>
      </Modal>
    </div>
  );
}

export default JobrolemasterAll;
