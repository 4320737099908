import React, { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import "react-circular-progressbar/dist/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import LineChart from "assets/charts/LineChart";
import { QueryApi } from "QueryApi";
import PieChart from "assets/charts/PieChart";

const ProductivityFocusLineChart = () => {
  const [
    xAxisDataValuePerformanceInsights,
    setXAxisDataValuePerformanceInsights,
  ] = useState([]);
  const [plotPointPerformanceInsights, setPlotPointPerformanceInsights] =
    useState([]);
  const [DowntimebigChartData, setDowntimebigChartData] = useState("data1");
  const [DowntimebigChartDataPieChart, setDowntimebigChartDataPieChart] =
    useState("data2");
  const [DowntimebigChartDataPieChartOne, setDowntimebigChartDataPieChartOne] =
    useState("data2");

  const componentName = "";
 

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const currDay = day < 10 ? `0${day}` : day;
  const currMonth = month < 10 ? `0${month}` : month;
  let currentDate = JSON.stringify(`${year}-${currMonth}-${currDay}`);

  const dateData = new Date();
  dateData.setDate(dateData.getDate() - 7);
  var dayDataValue = dateData.getDate();
  var monthDataValue = dateData.getMonth() + 1;
  var yearDataValue = dateData.getFullYear();
  var formattedDay = dayDataValue < 10 ? `0${dayDataValue}` : dayDataValue;
  var formattedMonth =
    monthDataValue < 10 ? `0${monthDataValue}` : monthDataValue;
  var weekDataDate = JSON.stringify(
    `${yearDataValue}-${formattedMonth}-${formattedDay}`
  );

  const previousDate = new Date();
  previousDate.setDate(1);
  const previousDay = previousDate.getDate();
  const previousMonth = previousDate.getMonth() + 1;
  const previousYear = previousDate.getFullYear();
  const formattedPreviousDay =
    previousDay < 10 ? `0${previousDay}` : previousDay;
  const formattedPreviousMonth =
    previousMonth < 10 ? `0${previousMonth}` : previousMonth;
  const previousMonthDate = JSON.stringify(
    `${previousYear}-${formattedPreviousMonth}-${formattedPreviousDay}`
  );

  const yearCurrentDate = new Date();
  yearCurrentDate.setDate(1);
  yearCurrentDate.setMonth(1);
  const yearDay = yearCurrentDate.getDate();
  const yearMonth = yearCurrentDate.getMonth();
  const yearYear = yearCurrentDate.getFullYear();
  const formattedYearDay = yearDay < 10 ? `0${yearDay}` : yearDay;
  const formattedYearMonth = yearMonth < 10 ? `0${yearMonth}` : yearMonth;
  const yearPreviousMonthDate = JSON.stringify(
    `${yearYear}-${formattedYearMonth}-${formattedYearDay}`
  );

  /////////////////////// downtime trendline ///////////////////////////////////////////////////

  const datasetPerformanceInsights = [
    {
      label: "Down Time Trendline",
      data: plotPointPerformanceInsights,
      borderColor: "#1F8EF1",
    },
  ];

  useEffect(() => {
    GraphDataListPerformanceInsights();
  }, [DowntimebigChartData]);

  const GraphDataListPerformanceInsights = async () => {
    if (DowntimebigChartData === "data4") {
      try { 
  
        const data = await QueryApi(`
        query MyQuery {
          bopp_fl0_total_downtime(where: {date: {_gte: "2023-07-31", _lte: "2023-08-31"}}) {
            total_downtime
            date
          }
        }
        `);
        console.log("lineeCartmndj",data);
        const plotAllDataInsights = data.bopp_fl0_total_downtime.map((data) => {
          const objData = {
            point: (data.total_downtime / (1000 * 60 * 60)).toFixed(2),
            Xaxis: data.timestamp,
          };
          return objData;
        });
  
        const plotPointDataInsights = plotAllDataInsights.map(
          (data) => data.point
        );
        setPlotPointPerformanceInsights(plotPointDataInsights);
        setXAxisDataValuePerformanceInsights([
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ]);
  
      } catch (error) {
        // Handle errors
        console.error('Error fetching data:', error);
      }
    } else {
      // Handle other cases
      // ...
    }
  };
  
  /////////////////////// Count Of Downtime By Reason ///////////////////////////////////////////////////

 

  return (
     <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          height: "400px",
        }}
      >
        <Col lg="9" md="9" xs="9">
          <div style={{ display: "flex", flexWrap: "wrap", height: "100%" }}>
            <Card className="card-chart" style={{ height: "97%" }}>
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5 className="card-category">Downtime Trendline</h5>
                  </Col>
                  <Col sm="6">
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <Button
                        tag="label"
                        className={classNames("btn-simple", {
                          active: DowntimebigChartData === "data1",
                        })}
                        color="info"
                        id="0"
                        size="sm"
                        onClick={() => setDowntimebigChartData("data1")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Day
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-single-02" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: DowntimebigChartData === "data2",
                        })}
                        onClick={() => setDowntimebigChartData("data2")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Week
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-gift-2" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: DowntimebigChartData === "data3",
                        })}
                        onClick={() => setDowntimebigChartData("data3")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Month
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-tap-02" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: DowntimebigChartData === "data4",
                        })}
                        onClick={() => setDowntimebigChartData("data4")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Year
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-tap-02" />
                        </span>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <LineChart
                  xAxisData={xAxisDataValuePerformanceInsights}
                  dataset={plotPointPerformanceInsights}
                  height="100%"
                  width="100%"
                />
              </CardBody>
            </Card>
          </div>
        </Col>
      </div>
    </div>
  );
};

export default ProductivityFocusLineChart;
