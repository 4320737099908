import React, { useState, useEffect } from "react";
import axios from "axios";
import  { APIURL } from './Apiurl.js';
import { Line, Bar } from "react-chartjs-2";
import './style.css';
import './responsive.css';
import './progress.css';
import './gauge.css'
import { toast } from 'react-toastify';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap";
import {
  chartExample3,
} from "variables/charts.js";
import PercentageCircle from './PercentageCircle';
import { RadialGauge } from 'react-canvas-gauges';
import 'react-circular-progressbar/dist/styles.css';
import "bootstrap/dist/css/bootstrap.min.css";
import redirectToLoginIfTokenMissing from './TokenUtility'; 

function Dashboard(props) {
  const storedTenantId = sessionStorage.getItem('selectedTenantId');
  const [widgetdashboard, setwidgetdashboard] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
    console.log("selected Tenant ID:", storedTenantId);
  useEffect(() => {
    redirectToLoginIfTokenMissing();
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      const roleId = decodedToken['sub'];
      const logintenantId = decodedToken['https://hasura.io/jwt/claims']['x-hasura-org-id'];
      const storedTenantId = sessionStorage.getItem('selectedTenantId');
      const storedisdefault = sessionStorage.getItem('is_default');
      const storeddashboardid = sessionStorage.getItem('selectedDashboardId');

      console.log(storedisdefault, storeddashboardid);
      
      let query = ''; // Define the query variable outside the if-else block
      let variables = {}; // Define the variables object
      
      if (roleId == 1) {
        if(storedTenantId !==null){
          query = `
          query MyQuery($tenantId: Int!, $default: Int!, $dashboard: Int!) {
            widget_dashboard_assignment(where: {dashboard: {is_default: {_eq: $default}, tenantid: {_eq: $tenantId} , tenant_dashboard_master_id: { _eq: $dashboard }}}) {
              created_at
              widget_dashboard_assignment_id
              dashboard_id
              tenantid
              updated_at
              widget_feather_mapping_master_id
              widget_id
              dashboard {
                is_default
                is_active
                dashboard_name
                purpose
                tenantid
                tenant_dashboard_master_id
              }
              widget {
                feature_name
                feature_id
                machine
                monthly
                organization
                plant
                primary_driver_category
                unit_of_measure
                updated_at
                visualization
                weekly
                widget_feather_mapping_master_id
                widget_id
                yearly
                is_active
                daily
                dept
              }
            }
          }
        `;
        
        variables = {
          tenantId: storedTenantId,
          default: storedisdefault,
          dashboard: storeddashboardid,
        };
        }else{
          query = `
            query MyQuery($tenantId: Int!, $default: Int!, $dashboard: Int!) {
              widget_dashboard_assignment(where: {dashboard: {is_default: {_eq: $default}, tenantid: {_eq: $tenantId} , tenant_dashboard_master_id: { _eq: $dashboard }}}) {
              created_at
              widget_dashboard_assignment_id
              dashboard_id
              tenantid
              updated_at
              widget_feather_mapping_master_id
              widget_id
              dashboard {
                is_default
                is_active
                dashboard_name
                purpose
                tenantid
                tenant_dashboard_master_id
              }
              widget {
                feature_name
                feature_id
                machine
                monthly
                organization
                plant
                primary_driver_category
                unit_of_measure
                updated_at
                visualization
                weekly
                widget_feather_mapping_master_id
                widget_id
                yearly
                is_active
                daily
                dept
              }
            }
          }          
          `;
        }
      
      } else {
        query = `
        query MyQuery($tenantId: Int!, $default: Int!, $dashboard: Int!) {
          widget_dashboard_assignment(where: {dashboard: {is_default: {_eq: $default}, tenantid: {_eq: $tenantId} , tenant_dashboard_master_id: { _eq: $dashboard }}}) {
              created_at
              widget_dashboard_assignment_id
              dashboard_id
              tenantid
              updated_at
              widget_feather_mapping_master_id
              widget_id
              dashboard {
                is_default
                is_active
                dashboard_name
                purpose
                tenantid
                tenant_dashboard_master_id
              }
              widget {
                feature_name
                feature_id
                machine
                monthly
                organization
                plant
                primary_driver_category
                unit_of_measure
                updated_at
                visualization
                weekly
                widget_feather_mapping_master_id
                widget_id
                yearly
                is_active
                daily
                dept
              }
            }
          }
        `;
        
        variables = {
          tenantId: parseInt(decodedToken['https://hasura.io/jwt/claims']['x-hasura-org-id']),
          default: storedisdefault,
          dashboard: storeddashboardid,
        };
      }

      const response = await axios.post(APIURL, {
        query: `
        query TntDashboards($default: Int!, $dashboard: Int!) {
          widget_dashboard_assignment(where: {dashboard: {is_default: {_eq: $default}, tenant_dashboard_master_id: { _eq: $dashboard }}}) {
          created_at
          widget_dashboard_assignment_id
          dashboard_id
          tenantid
          updated_at
          widget_feather_mapping_master_id
          widget_id
          dashboard {
            is_default
            is_active
            dashboard_name
            purpose
            tenantid
            tenant_dashboard_master_id
          }
          widget {
            feature_name
            feature_id
            machine
            monthly
            organization
            plant
            primary_driver_category
            unit_of_measure
            updated_at
            visualization
            weekly
            widget_feather_mapping_master_id
            widget_id
            yearly
            is_active
            daily
            dept
          }
        }
      }          
      `,
        variables: {
          default: storedisdefault,
          dashboard: storeddashboardid,
        },
      }, {
        headers: {
          'Content-Type': 'application/json',
           Authorization: `Bearer ${token}`, 
        },
      });


      setwidgetdashboard(response.data.data.widget_dashboard_assignment);
        console.log(response.data.data);
      } catch (error) {
        console.error(error);
      }
  };
 
  const stackedBarData = {
    labels: ["Category 1", "Category 2", "Category 3", "Category 4", "Category 5"],
    datasets: [
      {
        label: "Dataset 1",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        data: [10, 20, 30, 40, 50],
      },
      {
        label: "Dataset 2",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
        data: [20, 30, 10, 25, 35],
      },
      // Add more datasets for additional categories if needed
    ],
  };
  const stackedBarOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
 
  const highlights = [
    { from: 0, to: 20, color: '#e63946' }, // Red
    { from: 20, to: 40, color: '#f1a9a0' }, // Orange
    { from: 40, to: 60, color: '#feca57' }, // Yellow
    { from: 60, to: 80, color: '#00b4d8' }, // Blue
    { from: 80, to: 100, color: '#90e0ef' }, // Light Blue
  ];
  const lineData = {
    labels: ['January', 'February', 'March', 'April', 'May'],
    datasets: [
      {
        label: 'Line Data',
        fill: false,
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 2,
        data: [30, 45, 60, 75, 90],
      },
    ],
  };
  const barData = {
    labels: ['January', 'February', 'March', 'April', 'May'],
    datasets: [
      {
        label: 'Bar Data',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
        data: [65, 59, 80, 81, 56],
      },
    ],
  };
  const compositeChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };
  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
    elements: {
      line: {
        tension: 0.4, // Adjust the curve tension here (0.4 is just an example)
        borderWidth: 2,
        borderCapStyle: "round", // Use "round" to make the line curves circular
        borderColor: 'rgba(255, 99, 132, 1)',
      },
    },
  };
  const mixedChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };
  const mixedChartData = {
    labels: ['January', 'February', 'March', 'April', 'May'],
    datasets: [
      {
        label: 'Bar Data',
        type: 'bar', // Specify bar type
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
        data: [65, 100, 80, 81, 56],
      },
      {
        label: 'Line Data',
        type: 'line', // Specify line type
        fill: false,
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 2,
        data: [30, 90, 10, 75, 20],
      },
    ],
  };
  const numericGridData = [
    {
      id: 1,
      name: "John Doe",
      age: 30,
      city: "New York",
      country: "USA",
    },
    {
      id: 2,
      name: "Jane Smith",
      age: 28,
      city: "Los Angeles",
      country: "USA",
    },
    {
      id: 3,
      name: "Alice Johnson",
      age: 35,
      city: "London",
      country: "UK",
    },
    // Add more data as needed
  ];
  const waterfalldata = {
    labels: ["Start", "Step 1", "Step 2", "Step 3", "End"],
    datasets: [
      {
        data: [100, -20, -30, 50, 0],
        backgroundColor: [
          "rgba(0, 0, 0, 0)", // Transparent for the Start
          "rgba(255, 0, 0, 0.6)", // Red for Step 1 (Negative)
          "rgba(255, 0, 0, 0.6)", // Red for Step 2 (Negative)
          "rgba(0, 128, 0, 0.6)", // Green for Step 3 (Positive)
          "rgba(0, 0, 0, 0)", // Transparent for the End
        ],
        borderColor: [
          "rgba(0, 0, 0, 0)", // Transparent for the Start
          "rgba(255, 0, 0, 1)", // Red for Step 1 (Negative)
          "rgba(255, 0, 0, 1)", // Red for Step 2 (Negative)
          "rgba(0, 128, 0, 1)", // Green for Step 3 (Positive)
          "rgba(0, 0, 0, 0)", // Transparent for the End
        ],
        borderWidth: 1,
      },
    ],
  };
  const waterfalloptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        beginAtZero: true,
        ticks: {
          callback: (value) => (value >= 0 ? value : -value),
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  const handleCrossButtonClick= async (widgetId) => {
    console.log(widgetId);
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.post(
        APIURL,
        {
          query: `
            mutation DeleteWidgetDashboardAssignment($widgetDashboardAssignmentId: Int!) {
              delete_widget_dashboard_assignment(
                where: { widget_dashboard_assignment_id: { _eq: $widgetDashboardAssignmentId } }
              ) {
                affected_rows
              }
            }
          `,
          variables: {
            widgetDashboardAssignmentId: widgetId,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
             Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );
  
      if (response.data.errors) {
        throw new Error(response.data.errors[0].message);
      }
  
      // Check if the record was successfully deleted
      if (response.data.data.delete_widget_dashboard_assignment.affected_rows === 1) {
        toast.success('Widget deleted successfully.');
        fetchData();
        // window.location.reload();
      } else {
        toast.error('Record not found or not deleted.');
      }
    } catch (error) {
      toast.error('Error:', error.message);
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          
        {widgetdashboard.map((widget) => (
          widget.widget.visualization === "Circular Gauge w numeric modal" ? (
            <Col lg="4" key={widgetdashboard.widget_dashboard_assignment_id} id={widget.widget_dashboard_assignment_id}>
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Gauge Chart {widget.widget_dashboard_assignment_id}</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-chart-pie text-info" /> Circular Gauge w numeric modal
                </CardTitle>
                <button className="close-icon" onClick={handleCrossButtonClick.bind(null, widget.widget_dashboard_assignment_id)}>
                  <i className="fas fa-times"></i>
                </button>
              </CardHeader>
              <CardBody>
              <div style={{ width: 'auto', height: 'auto' }}>
              <div className="gauge-container">
                <RadialGauge
                  width={300}
                  height={300}
                  style={{ marginLeft: '89px' }}
                  units="%"
                  title="Gauge Chart"
                  startAngle={90}
                  ticksAngle={180}
                  value={68}
                  minValue={0}
                  maxValue={100}
                  majorTicks={['', '', '', '', '', '', '', '', '', '']}
                  minorTicks={2}
                  strokeTicks={false}
                  highlights={highlights} // Pass the highlights array with rainbow colors
                  colorPlate="transparent"
                  borderShadowWidth={0}
                  borders={false}
                  needleType="arrow"
                  needleWidth={2}
                  needleCircleSize={7}
                  needleCircleOuter={true}
                  needleCircleInner={true}
                  animationDuration={150}
                  animationRule="linear"
                  animationTarget="needle"
                  barWidth={15}
                  barShadow=""
                  needleShadow="rgba(0, 0, 0, 1)"
                />
              </div>
              </div>
                <div className="percentage-circles-container" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                  <div className="percentage-circle-wrapper">
                    <div className="percentage-circle-line"></div>
                    <PercentageCircle percentage={26} color="green" size="30px"  />
                    <div className="percentage-circle-label">Daily Uptime</div>
                  </div>
                  <div style={{ width: '10%' }}></div>
                  <div className="percentage-circle-wrapper" style={{ textAlign: 'center' }}>
                  <div className="percentage-circle-line"></div>
                    <PercentageCircle percentage={65} color="skyblue" size="30px"  />
                  {/* <div style={{ width: '90px' }}>
                  <CircularProgressbar
                    value={percentage}
                    text={`${percentage}%`}
                    strokeWidth={6}
                    styles={buildStyles({
                      pathColor: gradientColor,
                      textColor: '#000000',
                      trailColor: 'transparent',
                    })}
                  />

              </div> */}
                    <div className="percentage-circle-label">Productivity Goal</div>
                  </div>
                </div>
                
              <div className="card-container" style={{ marginTop: '25px', display: 'flex', justifyContent: 'center' }}>
                <div className="col-4">
                  <div className="card card-stats card-primary" style={{ filter: 'drop-shadow(-4px 10px 7px 11px rgba(0, 0, 0, 0.1))' }}>
                    <div className="card-body" style={{ background: 'red', borderRadius: '13px', textAlign: 'center' }}>
                      <h5 className="card-title" style={{ fontSize: '27px', fontWeight: 'bold' }}>10</h5>
                      <p className="card-text">Unavailable Machines</p>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="card card-stats card-secondary" style={{ filter: 'drop-shadow(-4px 10px 7px 11px rgba(0, 0, 0, 0.1))' }}>
                    <div className="card-body" style={{ background: '#B8860B', borderRadius: '13px', textAlign: 'center' }}>
                      <h5 className="card-title" style={{ fontSize: '27px', fontWeight: 'bold' }}>10</h5>
                      <p className="card-text">Under Maintenance</p>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="card card-stats card-tertiary" style={{ filter: 'drop-shadow(-4px 10px 7px 11px rgba(0, 0, 0, 0.1))' }}>
                    <div className="card-body" style={{ background: 'green', borderRadius: '13px', textAlign: 'center' }}>
                      <h5 className="card-title" style={{ fontSize: '27px', fontWeight: 'bold' }}>10</h5>
                      <p className="card-text">Running Machines</p>
                    </div>
                  </div>
                </div>
              </div>
              </CardBody>
            </Card>
            </Col>
          ) : widget.widget.visualization === "Numeric Grid with Gauge (percentage)" ? (
            <Col lg="4" key={widgetdashboard.widget_dashboard_assignment_id} id={widget.widget_dashboard_assignment_id}>
              <Card className="card-chart">
              <CardHeader>
              <h5 className="card-category">Gauge Chart {widget.widget_dashboard_assignment_id}</h5>
              <CardTitle tag="h3">
                  <i className="tim-icons icon-chart-pie text-info" /> Numeric Grid with Gauge (percentage) 
              </CardTitle>
              <button className="close-icon" onClick={handleCrossButtonClick.bind(null, widget.widget_dashboard_assignment_id)}>
                <i className="fas fa-times"></i>
              </button>
              </CardHeader>
              <CardBody>
              <div className="gauge-container">
              <RadialGauge
                  width={300}
                  height={300}
                  style={{ marginLeft: '89px' }}
                  units="%"
                  title="Gauge Chart"
                  startAngle={90}
                  ticksAngle={180}
                  value={31}
                  minValue={0}
                  maxValue={100}
                  majorTicks={['', '', '', '', '', '', '', '', '', '']}
                  minorTicks={2}
                  strokeTicks={false}
                  highlights={highlights} // Pass the highlights array with rainbow colors
                  colorPlate="transparent"
                  borderShadowWidth={0}
                  borders={false}
                  needleType="arrow"
                  needleWidth={2}
                  needleCircleSize={7}
                  needleCircleOuter={true}
                  needleCircleInner={false}
                  animationDuration={1500}
                  animationRule="linear"
                  animationTarget="needle"
                  barWidth={15}
                  barShadow="rgba(51, 204, 102, 0.8)"
                  needleShadow="rgba(0, 0, 0, 0.7)"
              />
              </div>
             
              </CardBody>
              </Card>
            </Col>
          ) : null
        ))}
        </Row>
        <Row>
        {widgetdashboard.map((widget) => (
          widget.widget.visualization === "Line Bar Graph( Current, Average, Best cycle time)" ? (
            <Col lg="6" key={widgetdashboard.widget_dashboard_assignment_id} id={widget.widget_dashboard_assignment_id}>
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Total Shipments {widget.widget_dashboard_assignment_id}</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-bell-55 text-info" /> Line Bar Graph( Current, Average, Best cycle time)
                </CardTitle>
                <button className="close-icon" onClick={handleCrossButtonClick.bind(null, widget.widget_dashboard_assignment_id)}>
                  <i className="fas fa-times"></i>
                </button>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Bar
                    data={mixedChartData} // Use mixedChartData here
                    options={mixedChartOptions}
                  />
                </div>
              </CardBody>
            </Card>
            </Col>
          ) : widget.widget.visualization === "Numeric Grid" ? (
            <Col lg="6" key={widgetdashboard.widget_dashboard_assignment_id} id={widget.widget_dashboard_assignment_id}>
             <Card>
                <CardHeader>
                  <CardTitle tag="h4">Numeric Grid {widget.widget_dashboard_assignment_id}</CardTitle>
                  <button className="close-icon" onClick={handleCrossButtonClick.bind(null, widget.widget_dashboard_assignment_id)}>
                    <i className="fas fa-times"></i>
                  </button>
                </CardHeader>
                <CardBody>
                  <Table className="tablesorter" responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Name</th>
                        <th>Country</th>
                        <th>City</th>
                        <th>Salary</th>
                      </tr>
                    </thead>
                    <tbody>
                      {numericGridData.map((item) => (
                          <tr key={item.id}>
                            <td>{item.name}</td>
                            <td>{item.age}</td>
                            <td>{item.city}</td>
                            <td>{item.country}</td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          ) : widget.widget.visualization === "Line chart / Spark Line" ? (
            <Col lg="6">
            <Card className="card-chart" key={widgetdashboard.widget_dashboard_assignment_id} id={widget.widget_dashboard_assignment_id}>
                <CardHeader>
                <h5 className="card-category">Total Shipments {widget.widget_dashboard_assignment_id}</h5>
                <CardTitle tag="h3">
                    <i className="tim-icons icon-bell-55 text-info" /> Line Chart
                </CardTitle>
                <button className="close-icon" onClick={handleCrossButtonClick.bind(null, widget.widget_dashboard_assignment_id)}>
                  <i className="fas fa-times"></i>
                </button>
                </CardHeader>
                <CardBody>
                <div className="chart-area">
                    <Line
                    data={chartExample3.data}
                    options={chartExample3.options}
                    />
                    
                </div>
                </CardBody>
            </Card>
            </Col>
          ) : widget.widget.visualization === "Composite Curve (representing expected and actual)" ? (
            <Col lg="6" key={widgetdashboard.widget_dashboard_assignment_id} id={widget.widget_dashboard_assignment_id}>
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Composite Curve Chart {widget.widget_dashboard_assignment_id}</h5>
                  <CardTitle tag="h3">
                    <i className="tim-icons icon-bell-55 text-info" /> Composite Curve (representing expected and actual)
                  </CardTitle>
                  <button className="close-icon" onClick={handleCrossButtonClick.bind(null, widget.widget_dashboard_assignment_id)}>
                    <i className="fas fa-times"></i>
                  </button>
                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                    <Line
                      data={lineData}
                      options={compositeChartOptions}
                    />
                    <Bar
                      data={barData}
                      options={compositeChartOptions}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          ) : widget.widget.visualization === "Stacked Bar Graph" ? (
            <Col lg="6" key={widgetdashboard.widget_dashboard_assignment_id} id={widget.widget_dashboard_assignment_id}>
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Stacked Bar Chart {widget.widget_dashboard_assignment_id}</h5>
                  <CardTitle tag="h3">
                    <i className="tim-icons icon-bell-55 text-info" /> Stacked Bar Chart
                  </CardTitle>
                  <button className="close-icon" onClick={handleCrossButtonClick.bind(null, widget.widget_dashboard_assignment_id)}>
                    <i className="fas fa-times"></i>
                  </button>
                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                    <Bar
                      data={stackedBarData}
                      options={stackedBarOptions}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          ) : widget.widget.visualization === "Waterfall Chart" ? (
            <Col lg="6" key={widgetdashboard.widget_dashboard_assignment_id} id={widget.widget_dashboard_assignment_id}>
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Total Shipments {widget.widget_dashboard_assignment_id}</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-bell-55 text-info" /> Waterfall Chart
                </CardTitle>
                <button className="close-icon" onClick={handleCrossButtonClick.bind(null, widget.widget_dashboard_assignment_id)}>
                  <i className="fas fa-times"></i>
                </button>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Bar
                    data={waterfalldata} // Use mixedChartData here
                    options={waterfalloptions}
                  />
                </div>
              </CardBody>
            </Card>
            </Col>

          ) : null
        ))}
        </Row>
        
        <Row>
        {widgetdashboard.map((widget) => (
          widget.widget.visualization === "Numeric Modal (Single value chart w indicator)" ? (
            <Col lg="4" key={widgetdashboard.widget_dashboard_assignment_id} id={widget.widget_dashboard_assignment_id}>
            <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Widget Id - {widget.widget_dashboard_assignment_id}</h5>
                  <CardTitle tag="h3">
                    <i className="tim-icons icon-chart-pie text-info" />Numeric Modal (Single value chart w indicator)
                  </CardTitle>
                  <button className="close-icon" onClick={handleCrossButtonClick.bind(null, widget.widget_dashboard_assignment_id)}>
                    <i className="fas fa-times"></i>
                  </button>
                </CardHeader>
                <CardBody>
              <div className="card-container" style={{ marginTop: '25px', display: 'flex', justifyContent: 'center' }}>
                <div className="col-4">
                  <div className="card card-stats card-primary" style={{ filter: 'drop-shadow(-4px 10px 7px 11px rgba(0, 0, 0, 0.1))' }}>
                    <div className="card-body" style={{ background: 'red', borderRadius: '13px', textAlign: 'center' }}>
                      <h5 className="card-title" style={{ fontSize: '27px', fontWeight: 'bold' }}>10</h5>
                      <p className="card-text">Unavailable Machines</p>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="card card-stats card-secondary" style={{ filter: 'drop-shadow(-4px 10px 7px 11px rgba(0, 0, 0, 0.1))' }}>
                    <div className="card-body" style={{ background: '#B8860B', borderRadius: '13px', textAlign: 'center' }}>
                      <h5 className="card-title" style={{ fontSize: '27px', fontWeight: 'bold' }}>10</h5>
                      <p className="card-text">Under Maintenance</p>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="card card-stats card-tertiary" style={{ filter: 'drop-shadow(-4px 10px 7px 11px rgba(0, 0, 0, 0.1))' }}>
                    <div className="card-body" style={{ background: 'green', borderRadius: '13px', textAlign: 'center' }}>
                      <h5 className="card-title" style={{ fontSize: '27px', fontWeight: 'bold' }}>10</h5>
                      <p className="card-text">Running Machines</p>
                    </div>
                  </div>
                </div>
              </div>
              </CardBody>
              </Card>
            </Col>
            ) : widget.widget.visualization === "Circular Gauge w percentage" ? (
              <Col lg="4">
              <Card className="card-chart"  key={widgetdashboard.widget_dashboard_assignment_id} id={widget.widget_dashboard_assignment_id}>
                <CardHeader>
                  <h5 className="card-category">Gauge Chart {widget.widget_dashboard_assignment_id}</h5>
                  <CardTitle tag="h3">
                    <i className="tim-icons icon-chart-pie text-info" /> Circular Gauge w percentage
                  </CardTitle>
                  <button className="close-icon" onClick={handleCrossButtonClick.bind(null, widget.widget_dashboard_assignment_id)}>
                    <i className="fas fa-times"></i>
                  </button>
                </CardHeader>
                <CardBody>
                  <div className="percentage-circles-container" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <div className="percentage-circle-wrapper">
                      <div className="percentage-circle-line"></div>
                      <PercentageCircle percentage={100} color="green" size="50px" />
                      <div className="percentage-circle-label">Performance</div>
                    </div>
                    <div className="percentage-circle-separator"></div>
                    <div id="numeric-chart" className="percentage-circle-wrapper" style={{ textAlign: 'center' }}>
                      <div className="percentage-circle-line"></div>
                      <div className="percentage-circle-label">Target - XXXX</div>
                      <div className="percentage-circle-label">Achieved - XXXX</div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              </Col>
            ) : widget.widget.visualization === "Numeric Modal w Value (ratio x : y )" ? (
              <Col lg="4" key={widgetdashboard.widget_dashboard_assignment_id} id={widget.widget_dashboard_assignment_id}>
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Gauge Chart {widget.widget_dashboard_assignment_id}</h5>
                  <CardTitle tag="h3">
                    <i className="tim-icons icon-chart-pie text-info" /> Numeric Modal w Value (ratio x : y )
                  </CardTitle>
                  <button className="close-icon" onClick={handleCrossButtonClick.bind(null, widget.widget_dashboard_assignment_id)}>
                    <i className="fas fa-times"></i>
                  </button>
                </CardHeader>
                <CardBody>
                  <div className="percentage-circles-container" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <div className="percentage-circle-separator"></div>
                    <div id="ratio-chart" className="percentage-circle-wrapper" style={{ textAlign: 'center' }}>
                      <div className="percentage-circle-line"></div>
                      <div className="percentage-circle-label">X : Y</div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              </Col>
            ) : null
        ))}
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
