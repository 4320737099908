import React from "react";
import { useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Progress,
} from "reactstrap";
import axios from "axios";
import  { APIURL } from './Apiurl.js';

function UdpParameterMapping() {
  const [activeStep, setActiveStep] = useState(1);
  const [checked, setChecked] = useState(false);
  const [progress, setProgress] = useState(0);

  const [machinetype, setmachinetype] = useState("");
  const [udpid, setudpid] = useState("");
  const [machinesubtype, setmachinesubtype] = useState("");
  const [pname, setpname] = useState("");
  const [pcategory, setpcategory] = useState("");
  const [ptype, setptype] = useState("");
  const [unitofmeasure, setunitofmeasure] = useState("");
  const [rank, setrank] = useState("");
  
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleNext = () => {
    if (activeStep === 1 && progress === 0) {
      setProgress(50);
    } else if (activeStep === 2 && progress === 50) {
      setProgress(100);
    }
    setActiveStep(activeStep + 1);
  };

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    // Create an object with the form data
    const formData = {
        machinetype: machinetype,
        udpid: udpid,
        machinesubtype: machinesubtype,
        pname: pname,
        pcategory: pcategory,
        ptype: ptype,
        unitofmeasure: unitofmeasure,
        rank: rank
      
   
    };
    console.log(formData);    
    // Make a POST request to the Laravel backend
    axios
      .post(`${APIURL}/api/udp-parameter/add`, formData)
      .then((response) => {
        console.log(response.data); // Handle the response data as needed
        // Reset the form or show a success message
        if (response.status === 201) {
          // Redirect to admin/machine-all
          window.location.href = "/admin/udp-parameter/pm-all";
        } else {
          // Reset the form or show a success message
        }
      })
      .catch((error) => {
        console.error(error); // Handle the error
        // Show an error message
      });
  };
  const renderStepContent = () => {
    switch (activeStep) {
      case 1:
        return (
          <>
              <Row>
                    
                    <Col className="px-md-1" md="6">
                      <FormGroup>
                        <label>Machine Type</label>
                        <Input
                          placeholder="Machine Type"
                          type="text"
                          value={machinetype}
                          onChange={(e) => setmachinetype(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                        UDPID
                        </label>
                        <Input placeholder="UDPID" type="text" 
                        value={udpid}
                        onChange={(e) => setudpid(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Machine SubType</label>
                        <Input
                          placeholder="Machine SubType"
                          type="text"
                          value={machinesubtype}
                          onChange={(e) => setmachinesubtype(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Parameter Name</label>
                        <Input
                          
                          placeholder="Parameter Name"
                          type="text"
                          value={pname}
                          onChange={(e) => setpname(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  
          </>
        );
      case 2:
        return (
          <>
         <Row>
            <Col className="pr-md-1" md="6">
                
                <FormGroup>
                <label>Parameter Category</label>
                <Input
                    placeholder="Parameter Category"
                    type="text"
                    value={pcategory}
                    onChange={(e) => setpcategory(e.target.value)}
                />
                </FormGroup>
            </Col>
            <Col className="pl-md-1" md="6">
                <FormGroup>
                <label>Parameter Type</label>
                <Input
                    
                    placeholder="Parameter Type"
                    type="text"
                    value={ptype}
                    onChange={(e) => setptype(e.target.value)}
                />
                </FormGroup>
            </Col>
            </Row>
          </>
        );
      case 3:
        return (
          <>
      <Row>
                  <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Unit of Measure</label>
                        <Input
                          placeholder="Unit of Measure"
                          type="text"
                          value={unitofmeasure}
                          onChange={(e) => setunitofmeasure(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Rank</label>
                        <Input
                          
                          placeholder="Rank"
                          type="number"
                          value={rank}
                          onChange={(e) => setrank(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
          </>
        );
      default:
        return null;
    }
  };
  const calculateProgress = () => {
    return Math.round(((activeStep - 1) / 2) * 100); // Update the denominator to match the total number of steps minus one
  };
  

  const renderProgressBarDestinations = () => {
    const step1Class = activeStep >= 1 ? "progress-destination-active" : "";
    const step2Class = activeStep >= 2 ? "progress-destination-active" : "";
    const step3Class = activeStep >= 3 ? "progress-destination-active" : "";
    const step4Class = activeStep >= 4 ? "progress-destination-active" : "";
  
    return (
      <div className="progress-destinations">
        <div className={`progress-destination ${step1Class}`}>
          <span>1</span>
        </div>
        <div className={`progress-destination ${step2Class}`}>
          <span>2</span>
        </div>
        <div className={`progress-destination ${step3Class}`}>
          <span>3</span>
        </div>
        <div className={`progress-destination ${step4Class}`}>
          <span>4</span>
        </div>
      </div>
    );
  };

  return (
    <div className="content">
      <Row>
        <Col md="8">
          <div className="progress-container">
            {renderProgressBarDestinations()}
            <Progress
              max="100"
              value={calculateProgress()}
              className="progress-bar-sm progress-bar-striped"
            />
          </div>
        </Col>
      </Row>
      <Row className="progress-row">
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Add New Machine</h5>
            </CardHeader>
            <CardBody>
              <Form>{renderStepContent()}</Form>
            </CardBody>
            <CardFooter>
              <div className="text-right">
                {activeStep > 1 && (
                  <Button color="primary" onClick={handlePrevious}>
                    Previous
                  </Button>
                )}
                {activeStep < 3 && (
                  <Button color="primary" onClick={handleNext}>
                    Next
                  </Button>
                )}
                {activeStep === 3 && (
                  <Button color="success" onClick={handleFormSubmit}>
                    Submit
                  </Button>
                )}
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default UdpParameterMapping;
