// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-page .progress {
  display: flex;
  height: 1rem !important;
  /* margin: 0px 100px 0px 100px !important; */
  padding: -2px;
  overflow: hidden;
  line-height: 0;
  font-size: 0.65625rem;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0.875rem;
  box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.3);
}
#progress-container{
    margin-left: -50px;
    width: 100%;
    padding-right: 29px;
    margin-top: -33px !important;
}
.progress-container-footer{
  font-weight: bold;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/views/progress.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,4CAA4C;EAC5C,aAAa;EACb,gBAAgB;EAChB,cAAc;EACd,qBAAqB;EACrB,oCAAoC;EACpC,uBAAuB;EACvB,8CAA8C;AAChD;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,mBAAmB;IACnB,4BAA4B;AAChC;AACA;EACE,iBAAiB;IACf,YAAY;AAChB","sourcesContent":[".dashboard-page .progress {\n  display: flex;\n  height: 1rem !important;\n  /* margin: 0px 100px 0px 100px !important; */\n  padding: -2px;\n  overflow: hidden;\n  line-height: 0;\n  font-size: 0.65625rem;\n  background-color: rgba(0, 0, 0, 0.3);\n  border-radius: 0.875rem;\n  box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.3);\n}\n#progress-container{\n    margin-left: -50px;\n    width: 100%;\n    padding-right: 29px;\n    margin-top: -33px !important;\n}\n.progress-container-footer{\n  font-weight: bold;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
