import React, { useState, useEffect } from "react";
import redirectToLoginIfTokenMissing from "views/TokenUtility";
import classnames from "classnames";
import classNames from "classnames";
import timeLoss from "assets/img/timeLoss.svg";
import { QueryApi } from "QueryApi";
import PopUpTotalPartsProduced from "components/New Productivity/PopUpTotalPartsProduced";
import TableComponent from "components/New Productivity/Productivity Grids/TableComponent.js";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import JindalPackedProduction from "../JindalPackedProduction";
import PackedProductionSKUWise from "../PackedProductionSKUWise";
import AgradeProduction from "../AgradeProduction";
import AgradeProductionSKUWise from "../AgradeProductionSKUWise";
import ExtrudedMaterial from "./ExtrudedMaterial";
import ReusableWaseGeneration from "./ReusableWaseGeneration";

function JindalProduction(props) {
  const storedTenantId = sessionStorage.getItem("selectedTenantId");
  const [isOpen, setIsOpen] = useState(false);
  const [openCard, setOpenCard] = useState("totalPartsProducedGridCard");
  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const currDay = day < 10 ? `0${day}` : day;
  const currMonth = month < 10 ? `0${month}` : month;
  let currentDate = JSON.stringify(`${year}-${currMonth}-${currDay}`);

  const dateData = new Date();
  dateData.setDate(dateData.getDate() - 7);
  var dayDataValue = dateData.getDate();
  var monthDataValue = dateData.getMonth() + 1;
  var yearDataValue = dateData.getFullYear();
  var formattedDay = dayDataValue < 10 ? `0${dayDataValue}` : dayDataValue;
  var formattedMonth =
    monthDataValue < 10 ? `0${monthDataValue}` : monthDataValue;
  var weekDataDate = JSON.stringify(
    `${yearDataValue}-${formattedMonth}-${formattedDay}`
  );

  const previousDate = new Date();
  previousDate.setDate(1);
  const previousDay = previousDate.getDate();
  const previousMonth = previousDate.getMonth() + 1;
  const previousYear = previousDate.getFullYear();
  const formattedPreviousDay =
    previousDay < 10 ? `0${previousDay}` : previousDay;
  const formattedPreviousMonth =
    previousMonth < 10 ? `0${previousMonth}` : previousMonth;
  const previousMonthDate = JSON.stringify(
    `${previousYear}-${formattedPreviousMonth}-${formattedPreviousDay}`
  );

  const yearCurrentDate = new Date();
  yearCurrentDate.setDate(1);
  yearCurrentDate.setMonth(1);
  const yearDay = yearCurrentDate.getDate();
  const yearMonth = yearCurrentDate.getMonth();
  const yearYear = yearCurrentDate.getFullYear();
  const formattedYearDay = yearDay < 10 ? `0${yearDay}` : yearDay;
  const formattedYearMonth = yearMonth < 10 ? `0${yearMonth}` : yearMonth;
  const yearPreviousMonthDate = JSON.stringify(
    `${yearYear}-${formattedYearMonth}-${formattedYearDay}`
  );

  useEffect(() => {
    redirectToLoginIfTokenMissing();
  }, []);
  ////////////////////////////////////////////////////////////
  const [apiData, setApiData] = useState([]);
  const [bigChartData, setBgChartData] = React.useState("data1");
  const [dayDataProductivityAnalysis, setDayDataProductivityAnalysis] =
    useState([]);
  const [aggregateData, setAggregateData] = useState(null);
  const [aggrigateJumboEfficiency, setaggrigateJumboEfficiency] =
    useState(null);
  const [aggrigatePrimarySlitter, setaggrigatePrimarySlitter] = useState(null);
  const [aggrigateSlitterEfficiency, setAggrigateSlitterEfficiency] =
    useState(null);
  const [aggrigateSecondarySlitter, setAggrigateSecondarySlitter] =
    useState(null);
  const [aggrigateAgrade, setAggrigateAgrade] = useState(null);

  console.log("aggregateData====>", aggregateData);
  useEffect(() => {
    fetchAllCardData();
  }, [bigChartData]);
  const fetchAllCardData = async () => {
    if (bigChartData === "data1") {
      try {
        const data = await QueryApi(`
      query MyQuery {
        bopp_fl0_jumbo_output_aggregate {
          aggregate {
            sum {
              jumbo_output_volume
            }
          }
        }
        bopp_fl0_jumbo_efficiency_aggregate {
            aggregate {
              avg {
                jumbo_efficiency
              }
            }
          }
          bopp_fl0_output_ps_slit_primary_aggregate {
            aggregate {
              sum {
                total_psslitter_output__volume
              }
            }
          }
          bopp_fl0_slit_efficiency_aggregate {
            aggregate {
              avg {
                slit_efficiency
              }
            }
          }
          bopp_fl0_output_ss_slit_secondary_aggregate {
            aggregate {
              sum {
                total_ssslitter_output__volume
              }
            }
          }
          bopp_fl0_agrade_production_aggregate {
            aggregate {
              sum {
                agrade_production_output_volume
              }
              avg {
                agrade_production_output_percent
              }
            }
          }
        }
      `);
        setAggregateData(data.bopp_fl0_jumbo_output_aggregate.aggregate);
        setaggrigateJumboEfficiency(
          data.bopp_fl0_jumbo_efficiency_aggregate.aggregate
        );
        setaggrigatePrimarySlitter(
          data.bopp_fl0_output_ps_slit_primary_aggregate.aggregate
        );
        setAggrigateSlitterEfficiency(
          data.bopp_fl0_slit_efficiency_aggregate.aggregate
        );
        setAggrigateSecondarySlitter(
          data.bopp_fl0_output_ss_slit_secondary_aggregate.aggregate
        );
        setAggrigateAgrade(data.bopp_fl0_agrade_production_aggregate.aggregate);
      } catch (error) {
        setAggregateData([]);
      }
    } else if (bigChartData === "data2") {
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! =  ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        dm_org_tenant_weekly(where: {start_date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
            plant_operating_time_percent
          }
          dm_weekly_data:  dm_daily(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}},order_by: {date: desc}) {
            org {
              organization_node_name
            }
            unit {
              organization_node_name
            }
            dept {
              organization_node_name
            }
            machineid
            date
            total_parts_produced
            parts_per_minute
            machine_availability_percent
            machine_performance_percent
          }
          bopp_fl0_jumbo_efficiency_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
            aggregate {
              avg {
                machine_availability_percent
                machine_performance_percent
                total_parts_produced
                parts_per_minute
              }
            }
          }
        }
      `);
        setDayDataProductivityAnalysis(data.dm_org_tenant_weekly[0]);
        setApiData(data.dm_weekly_data);
        setAggregateData(data.bopp_fl0_jumbo_efficiency_aggregate.aggregate);
      } catch (error) {
        setDayDataProductivityAnalysis([]);
      }
    } else if (bigChartData === "data3") {
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! =  ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        dm_org_tenant_monthly(where: {start_date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
            plant_operating_time_percent
          }
          dm_montly_data: dm_daily(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}},order_by: {date: desc}) {
            org {
              organization_node_name
            }
            unit {
              organization_node_name
            }
            dept {
              organization_node_name
            }
            machineid
            date
            total_parts_produced
            parts_per_minute
            machine_availability_percent
            machine_performance_percent
          }
          bopp_fl0_jumbo_efficiency_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
            aggregate {
              avg {
                machine_availability_percent
                machine_performance_percent
                total_parts_produced
                parts_per_minute
              }
            }
          }
        }
      `);
        setAggregateData(data.bopp_fl0_jumbo_efficiency_aggregate.aggregate);
        setDayDataProductivityAnalysis(data.dm_org_tenant_monthly[0]);
        setApiData(data.dm_montly_data);
      } catch (error) {
        setDayDataProductivityAnalysis([]);
      }
    } else if (bigChartData === "data4") {
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        dm_org_tenant_yearly(where: {start_date: {_eq: $start_date}, tenantid: {_eq: $tenantid}}) {
            plant_operating_time_percent
          }
          dm_yearly_data: dm_daily( order_by: {date: desc}) {
            org {
              organization_node_name
            }
            unit {
              organization_node_name
            }
            dept {
              organization_node_name
            } 
            machineid
            date
            total_parts_produced
            parts_per_minute
            machine_availability_percent
            machine_performance_percent
          }
          bopp_fl0_jumbo_efficiency_aggregate {
            aggregate {
              avg {
                machine_availability_percent
                machine_performance_percent
                total_parts_produced
                parts_per_minute
              }
            }
          }
        }
      `);
        setAggregateData(data.bopp_fl0_jumbo_efficiency_aggregate.aggregate);
        setApiData(data.dm_yearly_data);
        setDayDataProductivityAnalysis(data.dm_org_tenant_yearly[0]);
      } catch (error) {
        setDayDataProductivityAnalysis([]);
      }
    }
  };

  /////////////// total parts produced ////////////////
  const columnsPlantOperatingTime = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "Date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "unit.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machineid",
      text: "Machine Name",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "total_parts_produced",
      text: "Total Parts Produced",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        return cellContent + " Parts";
      },
    },
  ];
  ///////////////parts per hour ///////////////////
  const columnsPartsPerHour = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "Date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "unit.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machineid",
      text: "Machine Name",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "parts_per_minute",
      text: "Parts Per Hour",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        return cellContent ? cellContent * 60 + " Parts" : "No Data";
      },
    },
  ];
  //////////// parts per minutes ////////////////////
  const columnsPartsPerMinutes = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "Date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "unit.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machineid",
      text: "Machine Name",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "parts_per_minute",
      text: "Parts Per Minute",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        return cellContent + " Parts";
      },
    },
  ];
  //////////// Parts Yeild /////////////////////
  const columnsPartsYield = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "Date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "unit.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machineid",
      text: "Machine Name",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "parts_per_min",
      text: "Parts yield",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        return cellContent ? cellContent + " Parts" : "No Data";
      },
    },
  ];
  ////////// Equipment Performance ////////////
  const columnsEquipmentPeerformance = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "Date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "unit.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machineid",
      text: "Machine Name",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machine_performance_percent",
      text: "Equipment Performance",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        return cellContent + "%";
      },
    },
  ];
  ////////Equipment Availability /////////////
  const columnsEquipmentAvailability = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "Date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "unit.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machineid",
      text: "Machine Name",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machine_availability_percent",
      text: "Equipment Availability",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        return cellContent + "%";
      },
    },
  ];
  ////////// Maximum Machine Capacity//////////
  const columnsMaximumMachineCapacity = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "Date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "unit.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machineid",
      text: "Machine Name",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "parts_per_min",
      text: "Maximum Machine Capacity ",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        return cellContent ? cellContent + " Parts" : "No Data";
      },
    },
  ];
  ///////// ThroughPut /////////////////
  const columnsThroughPut = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "Date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "unit.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machineid",
      text: "Machine Name",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "parts_per_min",
      text: "Throughput - Ideal vs Actual",
      headerStyle: { width: "500px" },
      formatter: (cellContent, row) => {
        return cellContent + " Parts";
      },
    },
  ];
  return (
    <>
      <div className="content">
        <Card
          style={{
            background: "transparent",
            boxShadow: "0px 10px 20px 2px rgba(0,0,0,0.75)",
            height: "18rem",
          }}
        >
          <CardHeader>
            <div>
              <div className="d-flex justify-content-end">
                <ButtonGroup
                  className="btn-group-toggle float-right"
                  data-toggle="buttons"
                >
                  <Button
                    tag="label"
                    className={classNames("btn-simple", {
                      active: bigChartData === "data1",
                    })}
                    color="info"
                    id="0"
                    size="sm"
                    onClick={() => setBgChartData("data1")}
                  >
                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      Day
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="tim-icons icon-single-02" />
                    </span>
                  </Button>
                  <Button
                    color="info"
                    id="1"
                    size="sm"
                    tag="label"
                    className={classNames("btn-simple", {
                      active: bigChartData === "data2",
                    })}
                    onClick={() => setBgChartData("data2")}
                  >
                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      Week
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="tim-icons icon-gift-2" />
                    </span>
                  </Button>
                  <Button
                    color="info"
                    id="2"
                    size="sm"
                    tag="label"
                    className={classNames("btn-simple", {
                      active: bigChartData === "data3",
                    })}
                    onClick={() => setBgChartData("data3")}
                  >
                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      Month
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="tim-icons icon-tap-02" />
                    </span>
                  </Button>
                  <Button
                    color="info"
                    id="2"
                    size="sm"
                    tag="label"
                    className={classNames("btn-simple", {
                      active: bigChartData === "data4",
                    })}
                    onClick={() => setBgChartData("data4")}
                  >
                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      Year
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="tim-icons icon-tap-02" />
                    </span>
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "0.8rem",
                marginLeft: "0.5rem",
                height: "13rem",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                    gap: "0.8rem",
                  }}
                >
                  {/* ////////////////card 1////////////////////// */}
                  <div
                    // onClick={() => setOpenCard("totalPartsProducedGridCard")}
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                      //   cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        Jumbo Production (All Time)
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        {aggregateData &&
                          aggregateData.sum &&
                          Math.floor(
                            aggregateData.sum.jumbo_output_volume
                          )}{" "}
                        {"MT"}
                        {/* {totalPartsProducedData} */}
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "100%",
                      }}
                    >
                      {/* <img
                        src={timeLoss}
                        style={{
                          height: "2rem",
                          width: "2rem",
                          position: "absolute",
                          top: "10px",
                          right: "2.5rem",
                          cursor: "pointer",
                        }}
                        onClick={() => setIsOpen(true)}
                        title="Trend Line"
                      /> */}
                      <div
                        className="icon-plus"
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: 0,
                        }}
                      ></div>
                    </div>
                    {isOpen && (
                      <>
                        <div
                          style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100vw",
                            height: "100vh",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            backdropFilter: "blur(3px)",
                            zIndex: 999,
                          }}
                        />
                        <div
                          style={{
                            position: "fixed",
                            top: "150px",
                            left: "70%",
                            transform: "translate(-50%, 0)",
                            zIndex: 1000,
                            width: "100%",
                            height: "100vh",
                            padding: "1rem",
                            boxSizing: "border-box",
                          }}
                        >
                          <div style={{ position: "relative", height: "100%" }}>
                            <PopUpTotalPartsProduced closePopup={setIsOpen} />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {/* /////////////////card 2///////////////////////// */}
                  <div
                    // onClick={() => setOpenCard("PartsPerHourProducedGridCard")}
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                      //   cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        Primary Slitter Production (All Time)
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        {/* {aggrigatePrimarySlitter && aggrigatePrimarySlitter.sum && Math.floor(aggrigatePrimarySlitter.sum.total_psslitter_output__volume)} {"MT"} */}
                        70692 MT
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "100%",
                      }}
                    >
                      <div
                        className="icon-plus"
                        style={{
                          position: "absolute", // Position the icon absolutely
                          top: "10px", // Align it to the top
                          right: 0, // Align it to the right of the image
                        }}
                      ></div>
                    </div>
                    {/* popup is here */}
                  </div>
                  {/* ///////////////card 3 ////////////////////////////// */}
                  <div
                    //    onClick={() => setOpenCard("PartsPerMinutesProducedGridCard")}
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                      //   cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        Secondary Slitter Production (All Time)
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        {/* {aggrigateSecondarySlitter && aggrigateSecondarySlitter.sum && Math.floor(aggrigateSecondarySlitter.sum.total_ssslitter_output__volume)} {"MT"} */}
                        1348 MT
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "100%",
                      }}
                    >
                      <div
                        className="icon-plus"
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: 0,
                        }}
                      ></div>
                    </div>
                    {/* popup is here */}
                  </div>
                  {/* ///////////////// card 4 ////////////////////// */}
                  <div
                    // onClick={() => setOpenCard("PartsYeildProducedGridCard")}
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        A-Grade Production Volume (All Time)
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        {aggrigateAgrade &&
                          aggrigateAgrade.sum &&
                          Math.floor(
                            aggrigateAgrade.sum.agrade_production_output_volume
                          )}{" "}
                        {"MT"}
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "100%",
                      }}
                    >
                      <div
                        className="icon-plus"
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: 0,
                        }}
                      ></div>
                    </div>
                    {/* popup is here */}
                  </div>
                  {/* /////////////////// card 5 /////////////////////////// */}
                  <div
                    // onClick={() => setOpenCard("EquipmentPerformanceGridCard")}
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                      //   cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        A-Grade Production Percent (All Time)
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        {aggrigateAgrade &&
                          aggrigateAgrade.avg &&
                          Math.floor(
                            aggrigateAgrade.avg.agrade_production_output_percent
                          )}{" "}
                        {"MT"}
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "100%",
                      }}
                    >
                      <div
                        className="icon-plus"
                        style={{
                          position: "absolute", // Position the icon absolutely
                          top: "10px", // Align it to the top
                          right: 0, // Align it to the right of the image
                        }}
                      ></div>
                    </div>
                    {/* popup is here */}
                  </div>
                  {/* //////////////////////// card 6 ////////////////////////// */}
                  {/* <div
                    onClick={() => setOpenCard("EquipmentAvailabilityGridCard")}
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        Equipment Availability %
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        {aggregateData &&
                          aggregateData.avg &&
                          Math.floor(
                            aggregateData.avg.machine_availability_percent
                          ) + "%"}
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "100%",
                      }}
                    >
                      <div
                        className="icon-plus"
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: 0,
                        }}
                      ></div>
                    </div>
                   
                  </div> */}
                </div>

                {/* ////////////////////////////////////////////////// */}
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                    gap: "0.8rem",
                    marginBottom: "0.6rem",
                    marginTop: "0.8rem",
                  }}
                >
                  {/* /////// card 7////////////// */}
                  <div
                    //   onClick={() => setOpenCard("MaximumMachineCapacityGridCard")}
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                      //   cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        Jumbo Efficiency (All Time)
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        {aggrigateJumboEfficiency &&
                          aggrigateJumboEfficiency.avg &&
                          Math.floor(
                            aggrigateJumboEfficiency.avg.jumbo_efficiency
                          )}
                        {"%"}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "5.5rem",
                        width: "3rem",
                      }}
                    >
                      <img
                      // src={runTime}
                      />
                    </div>
                  </div>
                  {/* //////// card 8 //////////////// */}
                  <div
                    // onClick={() => setOpenCard("ThroughPutGridCard")}
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                      //   cursor: "pointer"
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        Slitter Efficiency (All Time)
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        {aggrigateSlitterEfficiency &&
                          aggrigateSlitterEfficiency.avg &&
                          Math.floor(
                            aggrigateSlitterEfficiency.avg.slit_efficiency
                          )}
                        {"%"}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "3rem",
                      }}
                    >
                      <img
                      // src={timeLoss}
                      />
                    </div>
                  </div>
                  {/* ////////// card 9 //////////////// */}
                  <div
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        Packed Production (All Time)
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        78521 MT
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    ></div>
                  </div>
                  {/* ////////////////// card 10 ////////////////// */}
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        No Data
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        No Data
                        {/* {dayDataProductivityAnalysis.unplanned_downtime ? (dayDataProductivityAnalysis.unplanned_downtime / (1000 * 60 * 60)).toFixed(2) + " Hours" : "No Data"} */}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    ></div>
                  </div>
                  {/* ///////////////// card 11 //////////////// */}
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        No Data
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        No Data
                        {/* {dayDataProductivityAnalysis.cycletime_deviation ? (dayDataProductivityAnalysis.cycletime_deviation / (1000 * 60 * 60)).toFixed(2) + " Hours" : "No Data"} */}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    ></div>
                  </div>
                  {/* //////////////////// card 12 /////////////////// */}
                  {/* <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        No Data
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        No Data
                        
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    ></div>
                  </div> */}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => toggleTab("1")}
              style={{
                backgroundColor: activeTab === "1" ? "#5555e7" : "transparent",
                color: activeTab === "1" ? "white" : "white",
              }}
            >
              Packed Production
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => toggleTab("2")}
              style={{
                backgroundColor: activeTab === "2" ? "#5555e7" : "transparent",
                color: activeTab === "2" ? "white" : "white",
              }}
            >
              Packed Production - SKU Wise
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => toggleTab("3")}
              style={{
                backgroundColor: activeTab === "3" ? "#5555e7" : "transparent",
                color: activeTab === "3" ? "white" : "white",
              }}
            >
              A grade Production
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "4" })}
              onClick={() => toggleTab("4")}
              style={{
                backgroundColor: activeTab === "4" ? "#5555e7" : "transparent",
                color: activeTab === "4" ? "white" : "white",
              }}
            >
              A grade Production - SKU Wise
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "5" })}
              onClick={() => toggleTab("5")}
              style={{
                backgroundColor: activeTab === "5" ? "#5555e7" : "transparent",
                color: activeTab === "5" ? "white" : "white",
              }}
            >
              Extruded Material
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "6" })}
              onClick={() => toggleTab("6")}
              style={{
                backgroundColor: activeTab === "6" ? "#5555e7" : "transparent",
                color: activeTab === "6" ? "white" : "white",
              }}
            >
              Reusable Wase Generation
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col>
                <JindalPackedProduction />
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="2">
            <Row>
              <Col>
                <PackedProductionSKUWise />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col>
                <AgradeProduction />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="4">
            <Row>
              <Col>
                <AgradeProductionSKUWise />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="5">
            <Row>
              <Col>
                <ExtrudedMaterial />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="6">
            <Row>
              <Col>
                <ReusableWaseGeneration />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </>
  );
}

export default JindalProduction;
