import React, { useState, useEffect } from "react";
import redirectToLoginIfTokenMissing from "views/TokenUtility";
import { TabContent, TabPane, Card, CardBody, Row, Col } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { Badge } from "reactstrap";

function OeeScorePartWise() {
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const currDay = day < 10 ? `0${day}` : day;
  const currMonth = month < 10 ? `0${month}` : month;
  let currentDate = JSON.stringify(`${year}-${currMonth}-${currDay}`);

  const [activeTab, setActiveTab] = useState(1);
  const [employees, setEmployees] = useState([]);
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    redirectToLoginIfTokenMissing();
  }, []);

  function handleEdit(row) {}
  const activeFormatter = (cell, row) => {
    const isActive = row.is_active === "1";

    return (
      <Badge color={isActive ? "info" : "danger"}>
        {isActive ? "Active" : "Inactive"}
      </Badge>
    );
  };
  const columns = [
    {
      dataField: "",
      text: "Sr.No",
      headerStyle: { border: "1px solid #ddd" },
      formatter: (cell, row, rowIndex) => rowIndex + 1,
    },
    {
      dataField: "",
      text: "End Customer",
      headerStyle: { width: "500px", border: "1px solid #ddd" },
    },
    {
      dataField: "",
      text: "Part No",
      headerStyle: { width: "500px", border: "1px solid #ddd" },
    },
    {
      dataField: "",
      text: "Machine",
      headerStyle: { width: "500px", border: "1px solid #ddd" },
    },
    {
      dataField: "ideal_oee",
      text: "Idle OEE %",
      headerStyle: { width: "500px", border: "1px solid #ddd" },
    },
    {
      dataField: "oee",
      text: "Actual OEE %",
      headerStyle: { width: "500px", border: "1px solid #ddd" },
    },
    {
      dataField: "oee_deviation",
      text: "Deviation",
      headerStyle: { width: "500px", border: "1px solid #ddd" },
    },
    {
      dataField: "",
      text: "Classification",
      headerStyle: { width: "500px", border: "1px solid #ddd" },
    },
  ];

  const [apiData, setApiData] = useState([]);

  useEffect(() => {
    fetchDataList();
  }, []);

  const fetchDataList = async () => {};

  return (
    <div className="content">
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={employees}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <div>
                        <BootstrapTable
                          {...props.baseProps}
                          filter={filterFactory()}
                          pagination={paginationFactory()}
                          data={apiData || []}
                          headerClasses="sorted-asc sorted-desc"
                          wrapperClasses="table-responsive"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default OeeScorePartWise;
