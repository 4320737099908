// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gauge-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -37px;
}

.gauge-container canvas {
  position: relative;
}

.gauge-container .outer-circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #ccc; /* Adjust the border properties as desired */
  border-radius: 50%;
}

.gauge-container .bar {
  stroke-linecap: round;
  animation: bar-animation 3s infinite linear; /* Adjust the animation properties as desired */
}

@keyframes bar-animation {
  0% {
    stroke: #ff0000; /* Initial color for the animation */
  }
  50% {
    stroke: #00ff00; /* Intermediate color for the animation */
  }
  100% {
    stroke: #ff0000; /* Final color for the animation */
  }
}

/* Optional: Additional styling for the gauge chart title */
.gauge-container .canvas-gauges-title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/views/gauge.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,sBAAsB,EAAE,4CAA4C;EACpE,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,2CAA2C,EAAE,+CAA+C;AAC9F;;AAEA;EACE;IACE,eAAe,EAAE,oCAAoC;EACvD;EACA;IACE,eAAe,EAAE,yCAAyC;EAC5D;EACA;IACE,eAAe,EAAE,kCAAkC;EACrD;AACF;;AAEA,2DAA2D;AAC3D;EACE,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[".gauge-container {\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: -37px;\n}\n\n.gauge-container canvas {\n  position: relative;\n}\n\n.gauge-container .outer-circle {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  border: 2px solid #ccc; /* Adjust the border properties as desired */\n  border-radius: 50%;\n}\n\n.gauge-container .bar {\n  stroke-linecap: round;\n  animation: bar-animation 3s infinite linear; /* Adjust the animation properties as desired */\n}\n\n@keyframes bar-animation {\n  0% {\n    stroke: #ff0000; /* Initial color for the animation */\n  }\n  50% {\n    stroke: #00ff00; /* Intermediate color for the animation */\n  }\n  100% {\n    stroke: #ff0000; /* Final color for the animation */\n  }\n}\n\n/* Optional: Additional styling for the gauge chart title */\n.gauge-container .canvas-gauges-title {\n  font-size: 20px;\n  font-weight: bold;\n  text-align: center;\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
