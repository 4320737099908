import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "./Apiurl.js";
import redirectToLoginIfTokenMissing from "./TokenUtility";
import { TabContent, TabPane, Card, CardBody, Row, Col } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons";

function JobrolemasterAll() {
  const [activeTab, setActiveTab] = useState(1);
  const [machines, setmachines] = useState([]);
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    fetchData();
    redirectToLoginIfTokenMissing();
  }, []);

  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const roleId = decodedToken["sub"];
      const logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
      const storedTenantId = sessionStorage.getItem("selectedTenantId");
      console.log(roleId === 1);

      const response = await axios.post(
        APIURL,
        {
          query: `
      query MyQuery {
          tnt_org_machine_assignment {
            machine_auto_id
            machineid
            machine_name
            machine_type
            machine_sub_type
            organization {
              organization_node_name
              organization_structure_id
            }
          plant{
            organization_node_name
              organization_structure_id
          }
            dept{
              organization_node_name
              organization_structure_id
            }
            machinetype{
              machine_profile
            }
            machinesubtype{
              machine_profile
            }
            org_id
            edgeid
            machineid
            is_active
            dmfoundation {
              machine_idle_time
              planned_production_time
              capacity_utilized_percent
              planned_downtime_percent
              department_id
            }
          }
        }
      `,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );
      const MachinesMaster = response.data.data.tnt_org_machine_assignment.map(
        (structure, index) => ({
          ...structure,
          serialNo: index + 1,
        })
      );

      setmachines(MachinesMaster);
      console.log(MachinesMaster);
    } catch (error) {
      console.error(error);
    }
  };

  const convertMillisecondsToMinutes = (milliseconds) => {
    const minutes = milliseconds / (1000 * 60);
    return minutes?.toFixed(2);
  };

  // const handleMachineId = (machineid) => {
  //   // setSelectedTenantId(tenantId);
  //   console.log(machineid);
  //   sessionStorage.setItem('selectedMachineId', machineid);
  //   window.location.href = `/admin/dashboard/machine-equipment/Analysis`;
  // };
  function actionFormatter(cell, row) {
    return (
      <div>
        {/* <Link to={`/admin/dashboard/machine-equipment/${row.machine_auto_id}`}> */}
        <FontAwesomeIcon
          icon={faArrowRightToBracket} // Use the same icon here
          className="btn btn-primary btn-go-deep"
          style={{
            fontSize: "30px !important",
            background: "none",
            pointerEvents: "auto",
          }}
          onClick={() =>
            (window.location.href = `/admin/dashboard/machine-equipment/Analysis`)
          }
        />
        {/* </Link> */}
      </div>
    );
  }

  const columns = [
    {
      dataField: "serialNo",
      text: "Sr No",
    },
    {
      dataField: "machineid", // New dataField for the merged column
      text: "Machine No.", // New text for the merged column
      headerStyle: { width: "500px" },
    },
    {
      dataField: "organization.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dmfoundation.machine_idle_time",
      text: "Total Idle Time(in minutes)",
      formatter: (cell, row) => convertMillisecondsToMinutes(cell),
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dmfoundation.planned_production_time",
      text: "Planned Production Time(in minutes)",
      formatter: (cell, row) => convertMillisecondsToMinutes(cell),
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dmfoundation.planned_downtime_percent",
      text: "% Time Loss(Idle Time)",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dmfoundation.capacity_utilized_percent",
      text: "Capacity Utilization(%)",
      formatter: (cell, row) => parseFloat(cell).toFixed(3),
      headerStyle: { width: "500px" },
      formatter: (data) => {
        return `${data?.toFixed(3)}%`;
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: actionFormatter,
      headerStyle: { width: "500px" },
    },
  ];

  return (
    <div className="content">
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={machines}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <div>
                        {/* <div
                          className="float-right"
                          style={{
                            display: "flex",
                            marginBottom: "18px",
                            gap: "12px",
                          }}
                        >
                          <div className="float-left">
                            <SearchBar {...props.searchProps} />
                          </div>
                        </div> */}
                        <BootstrapTable
                          {...props.baseProps}
                          filter={filterFactory()}
                          pagination={paginationFactory()}
                          headerClasses="sorted-asc sorted-desc"
                          wrapperClasses="table-responsive"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}
export default JobrolemasterAll;
