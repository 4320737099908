import React, { useState, useEffect } from "react";
import ReactTable from "react-table-6";
import axios from "axios";
import AssignAttributeNew from "./AssignAttributeNew";
import "react-table-6/react-table.css";
import { APIURL } from "./Apiurl.js";
import "./style.css";
import {
  TabContent,
  TabPane,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import { toast } from "react-toastify";
import Modal from "@material-ui/core/Modal";

const DirectionDropdown = ({ value, onChange }) => {
  return (
    <select value={value} onChange={onChange} className="custom-dropdown">
      <option value="">Select</option>
      <option value="Maximize">Maximize</option>
      <option value="Minimize">Minimize</option>
    </select>
  );
};
const YesNoDropdown = ({ value, onChange }) => {
  return (
    <select value={value} onChange={onChange} className="custom-dropdown">
      <option value="">Select</option>
      <option value="Yes">Yes</option>
      <option value="No">No</option>
    </select>
  );
};
const AlertActionDropdown = ({ value, onChange }) => {
  return (
    <select value={value} onChange={onChange} className="custom-dropdown">
      <option value="">Select</option>
      <option value="yes">Yes</option>
      <option value="no">No</option>
    </select>
  );
};
const AssignAttribute = ({
  machineType,
  machineSubType,
  machineTypename,
  machineSubTypename,
  machineid,
  edgeid,
}) => {
  const [attributes, setAttributes] = useState([]);
  const [edit, setEdit] = useState({});
  const [open, setOpen] = React.useState(false);
  const [editedRowIndex, setEditedRowIndex] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleSave = async (editedAttribute) => {
    try {
      console.log("Edited Attribute:", edit);
      const token = sessionStorage.getItem("token");
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const roleId = decodedToken["sub"];

      let logintenantId = null;

      if (roleId === 1) {
        logintenantId = sessionStorage.getItem("selectedTenantId");
      } else {
        logintenantId =
          decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
      }

      const formData = {
        tenantid: logintenantId,
        machineid: machineid,
        edgeid: edgeid,
        machine_type: machineType,
        machine_sub_type: machineSubType,
        machine_type_name: machineTypename,
        machine_sub_type_name: machineSubTypename,
        machine_attribute_name: editedAttribute.machine_attribute_name,
        unit_of_measure: editedAttribute.unitOfMeasure,
        direction: editedAttribute.direction,
        idea_value: editedAttribute.ideaValue,
        min_value: editedAttribute.minValue.toString(),
        max_value: editedAttribute.maxValue,
        required_trigger: editedAttribute.requiredTrigger,
        alert_action: editedAttribute.alertAction,
        attribute_category: editedAttribute.attribute_category,
        attribute_type: editedAttribute.attribute_type,
        warning_threshold: editedAttribute.warningThreshold,
      };

      // Make an API call to update the attribute data
      const response = await axios.post(
        APIURL,
        {
          query: `
          mutation UpdateAttribute($attributeId: Int!, $formData: tnt_machine_attributes_master_set_input!) {
            update_tnt_machine_attributes_master_by_pk(
              pk_columns: { machine_attribute_master_id: $attributeId }
              _set: $formData
            ) {
              machine_attribute_master_id
            }
          }
        `,
          variables: {
            attributeId: edit.machine_attribute_master_id,
            formData: formData,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );
      console.log("FormData:", formData);
      if (response.data.data.update_tnt_machine_attributes_master_by_pk) {
        // Refetch the data to update the UI with the updated record
        fetchData();
        toast.success("Attribute updated successfully!");
      } else {
        toast.error("Failed to update attribute.");
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await axios.post(
        APIURL,
        {
          query: `
          query TntMachineAttrMaster($machineid: Int!) {
            tnt_machine_attributes_master(where: { machineid: { _eq: $machineid } }) {
              machine_attribute_master_id
              machine_attribute_name
              unit_of_measure
              attribute_category
              attribute_type
              direction
              idea_value
              max_value
              min_value
              warning_threshold
              required_trigger
              alert_action
            }
          }
        `,
          variables: {
            machineid: machineid,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      setAttributes(response.data.data.tnt_machine_attributes_master);
      console.log(response.data.data.tnt_machine_attributes_master);
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    {
      Header: "Attribute Name",
      accessor: "machine_attribute_name",
      Cell: ({ value }) => <div className="attribute-cell">{value}</div>,
    },
    {
      Header: "Unit Of Measure",
      accessor: "unit_of_measure",
      Cell: ({ value, ...props }) => (
        <div
          className={
            edit.machine_attribute_master_id ===
            props.original.machine_attribute_master_id
              ? "transparent-edit"
              : ""
          }
          contentEditable={
            edit.machine_attribute_master_id ===
            props.original.machine_attribute_master_id
          }
          suppressContentEditableWarning
          onBlur={(e) => {
            setEdit({
              ...edit,
              unitOfMeasure: e.target.innerHTML,
            });
          }}
          dangerouslySetInnerHTML={{
            __html:
              edit.machine_attribute_master_id ===
              props.original.machine_attribute_master_id
                ? edit.unitOfMeasure
                : value,
          }}
        />
      ),
    },
    {
      Header: "Direction",
      accessor: "direction",
      Cell: ({ value, ...props }) =>
        edit.machine_attribute_master_id ===
        props.original.machine_attribute_master_id ? (
          <DirectionDropdown
            value={edit.direction}
            onChange={(e) =>
              setEdit({
                ...edit,
                direction: e.target.value,
              })
            }
          />
        ) : (
          value
        ),
    },
    {
      Header: "Idea Value",
      accessor: "idea_value",
      Cell: ({ value, ...props }) => (
        <div
          className={
            edit.machine_attribute_master_id ===
            props.original.machine_attribute_master_id
              ? "transparent-edit"
              : ""
          }
          contentEditable={
            edit.machine_attribute_master_id ===
            props.original.machine_attribute_master_id
          }
          suppressContentEditableWarning
          onBlur={(e) => {
            setEdit({
              ...edit,
              ideaValue: e.target.innerHTML,
            });
          }}
          dangerouslySetInnerHTML={{
            __html:
              edit.machine_attribute_master_id ===
              props.original.machine_attribute_master_id
                ? edit.machine_attribute_master_ideaValue
                : value,
          }}
        />
      ),
    },
    {
      Header: "Min Value",
      accessor: "min_value",
      Cell: ({ value, ...props }) => (
        <div
          className={
            edit.machine_attribute_master_id ===
            props.original.machine_attribute_master_id
              ? "transparent-edit"
              : ""
          }
          contentEditable={
            edit.machine_attribute_master_id ===
            props.original.machine_attribute_master_id
          }
          suppressContentEditableWarning
          onBlur={(e) => {
            setEdit({
              ...edit,
              minValue: e.target.innerHTML,
            });
          }}
          dangerouslySetInnerHTML={{
            __html:
              edit.machine_attribute_master_id ===
              props.original.machine_attribute_master_id
                ? edit.minValue
                : value,
          }}
        />
      ),
    },
    {
      Header: "Max Value",
      accessor: "max_value",
      Cell: ({ value, ...props }) => (
        <div
          className={
            edit.machine_attribute_master_id ===
            props.original.machine_attribute_master_id
              ? "transparent-edit"
              : ""
          }
          contentEditable={
            edit.machine_attribute_master_id ===
            props.original.machine_attribute_master_id
          }
          suppressContentEditableWarning
          onBlur={(e) => {
            setEdit({
              ...edit,
              maxValue: e.target.innerHTML,
            });
          }}
          dangerouslySetInnerHTML={{
            __html:
              edit.machine_attribute_master_id ===
              props.original.machine_attribute_master_id
                ? edit.maxValue
                : value,
          }}
        />
      ),
    },
    {
      Header: "Warning Threshold",
      accessor: "warning_threshold",
      Cell: ({ value, ...props }) => (
        <div
          className={
            edit.machine_attribute_master_id ===
            props.original.machine_attribute_master_id
              ? "transparent-edit"
              : ""
          }
          contentEditable={
            edit.machine_attribute_master_id ===
            props.original.machine_attribute_master_id
          }
          suppressContentEditableWarning
          onBlur={(e) => {
            setEdit({
              ...edit,
              warningThreshold: e.target.innerHTML,
            });
          }}
          dangerouslySetInnerHTML={{
            __html:
              edit.machine_attribute_master_id ===
              props.original.machine_attribute_master_id
                ? edit.minValue
                : value,
          }}
        />
      ),
    },
    {
      Header: "Required Trigger",
      accessor: "required_trigger",
      Cell: ({ value, ...props }) =>
        edit.machine_attribute_master_id ===
        props.original.machine_attribute_master_id ? (
          <YesNoDropdown
            value={edit.requiredTrigger}
            onChange={(e) =>
              setEdit({
                ...edit,
                requiredTrigger: e.target.value,
              })
            }
          />
        ) : (
          value
        ),
    },
    {
      Header: "Alert Action",
      accessor: "alert_action",
      Cell: ({ value, ...props }) =>
        edit.machine_attribute_master_id ===
        props.original.machine_attribute_master_id ? (
          <AlertActionDropdown
            value={edit.alertAction}
            onChange={(e) => {
              console.log("Selected value:", e.target.value);
              setEdit({
                ...edit,
                alertAction: e.target.value,
              });
            }}
          />
        ) : (
          value
        ),
    },
    {
      Header: "Action",
      Cell: ({ value, ...props }) => (
        <div className={editedRowIndex === props.index ? "editing-row" : ""}>
          {editedRowIndex === props.index ? (
            <button onClick={() => handleSave(edit)}>Update</button>
          ) : (
            <button
              onClick={() => {
                if (editedRowIndex === props.index) {
                  // Clicking on the same row's "Edit" button again
                  setEdit({});
                  setEditedRowIndex(-1);
                } else {
                  // Clicking on a different row's "Edit" button
                  setEdit({
                    machine_attribute_master_id:
                      props.original.machine_attribute_master_id,
                    machine_attribute_name:
                      props.original.machine_attribute_name,
                    unitOfMeasure: props.original.unit_of_measure,
                    direction: props.original.direction,
                    ideaValue: props.original.idea_value,
                    minValue: props.original.min_value,
                    maxValue: props.original.max_value,
                    requiredTrigger: props.original.required_trigger,
                    alertAction: props.original.alert_action,
                    attribute_category: props.original.attribute_category,
                    attribute_type: props.original.attribute_type,
                    warningThreshold: props.original.warning_threshold,
                  });
                  setEditedRowIndex(props.index); // Set the edited row index
                  console.log("index " + props.index);
                  console.log("editrowno " + editedRowIndex);
                }
              }}
            >
              Edit
            </button>
          )}
        </div>
      ),
    },
  ];
  const CustomPagination = ({ pages, currentPage, onPageChange }) => {
    const pageNumbers = Array.from({ length: pages }, (_, index) => index + 1);

    return (
      <div className="pagination1">
        {pageNumbers.map((pageNumber) => (
          <button
            key={pageNumber}
            className={currentPage === pageNumber ? "active" : "pagn"}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className="content">
      <TabContent activeTab={1}>
        <TabPane tabId={1}>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4" className="table-card-header">
                    Assign Attributes
                  </CardTitle>
                  <div className="float-right">
                    <button className="btn btn-secondary" onClick={handleOpen}>
                      Add new Attribute
                    </button>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="table-content">
                    <ReactTable
                      data={attributes.filter((attribute) =>
                        Object.values(attribute).some(
                          (value) =>
                            typeof value === "string" && value.trim() !== ""
                        )
                      )}
                      columns={columns}
                      pageSize={10} // Set the initial page size
                      showPagination={true} // Display pagination
                      showPageSizeOptions={false} // Hide page size options
                      className="-striped"
                      PaginationComponent={CustomPagination} // Use custom pagination component
                      getTrProps={(state, rowInfo) => {
                        console.log("Row Info:", rowInfo);
                        console.log("Edited Row Index:", editedRowIndex);

                        return {
                          className:
                            rowInfo && editedRowIndex === rowInfo.index
                              ? "highlighted-row"
                              : "",
                        };
                      }}
                      striped={false} // Set to true to apply the styling
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
      <Modal
        onClose={handleClose}
        open={open}
        style={{
          boxShadow: "2px solid black",
          width: "100%",
          top: "15%",
          left: "15%",
        }}
      >
        <AssignAttributeNew
          machineType={machineType}
          machineSubType={machineSubType}
          machineTypename={machineTypename}
          machineSubTypename={machineSubTypename}
          machineId={machineid}
          edgeid={edgeid}
        />
      </Modal>
    </div>
  );
};

export default AssignAttribute;
