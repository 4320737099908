import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Progress,
} from "reactstrap";
import axios from "axios";
import { APIURL } from "./Apiurl.js";
import { toast } from "react-toastify";
import DynamicTable from "../components/DynamicTable/Dynamictable.js";
import "../components/DynamicTable/Dynamictable.css";

function ToolAndDieAdd({ handleClose, fetchData }) {
 
  const dynamicTableRef = useRef();
  const [activeStep, setActiveStep] = useState(1);
  const [progress, setProgress] = useState(0);
  const [tenantId, setTenantId] = useState("");
 
  const [tagNumber, setTagNumber] = useState("");
  const [production_part_no, setProductionPartNo] = useState([]);
//   const [part_no, setPartNo] = useState("");
  const [opNo, setOpNo] = useState("");
  const [opName, setOpName] =
    useState("");
  const [production_mahine_no, setProductionMachineNo] = useState([]);
//   const [partNo, setMachineNo] = useState("");
  const [partNo, setPartNo] = useState("");
  const [shift_name, setShiftName] = useState([]);

  const [toolLocationNo, setToolLocationNo] = useState("");

  const [partName, setPartName] = useState("");
  const [dieNo, setDieNo] = useState("");
  const [actualPartId, setActualPartId] = useState("");
  const [tenants, setTenants] = useState([]);

  const [isLastStep, setIsLastStep] = useState(false);
  const [isCardOpen, setIsCardOpen] = useState(true);

  useEffect(() => {
  }, []);

  const token = sessionStorage.getItem("token");
  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["sub"];
    let logintenantId = null;

    if (roleId == 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
    }
  }, []);
  const handleOrgNodeChange = (e) => {
    const selectedNodeName = e.target.options[e.target.selectedIndex].text;
    setTagNumber(selectedNodeName);
  };
  const handleNext = () => {
    if (activeStep === 1) {
      setProgress(100);
    }
    setActiveStep(activeStep + 1);
    if (activeStep + 1 === 4) {
      setIsLastStep(true);
    }
  };
  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
    setIsLastStep(false);
  };
  const handlesaveandClose = () => {
    setIsCardOpen(false);
  };

  const convertIstToUtc = (istTime) => {
    if (!istTime) {
      return "";
    }
    const [hours, minutes] = istTime.split(":");
    const utcdate = new Date();
    utcdate.setUTCHours(hours - 5, minutes - 30, 0, 0);
    const utcTime = utcdate.toISOString().split("T")[1].substring(0, 8);
    return utcTime;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const token = sessionStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["sub"];
    console.log(roleId);
    let logintenantId = null;
    if (roleId === 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
    }

    const formData = {
      tenantid: logintenantId,
      tag_number: tagNumber,
      op_no: opNo,
      op_name: opName,
      tool_location_no: toolLocationNo,
      part_name: partName,
      part_no: partNo,
      die_number: dieNo,
      actual_part_id: actualPartId,
    };
    axios
      .post(
        APIURL,
        {
          query: `
            mutation InsertProductionPlan($formData: tnt_tool_and_die_master_insert_input!) {
                insert_tnt_tool_and_die_master(objects: [$formData]) {
                affected_rows
                }
            }
        `,
          variables: {
            formData: formData,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-hasura-admin-secret": process.env.REACT_APP_X_API_SECRET_KEY, // Replace with your Hasura admin secret
          },
        }
      )
      .then((response) => {
        console.log(response); // Handle the response data as needed
        if (
          response.data.data &&
          response.data.data.insert_tnt_tool_and_die_master.affected_rows > 0
        ) {
          setTagNumber("");
          setPartNo("");
          setOpNo("");
          setOpName("");
          setToolLocationNo("");
          setPartName("");
          setDieNo("");
          setActualPartId("");
          toast.success("Data inserted successfully!");
          fetchData();
        } else {
          toast.error("Failed to insert data.");
        }
      })
      .catch((error) => {
        console.error(error); // Handle the error
        // toast.error("An error occurred while inserting data.");
      });
  };
  const renderStepContent = () => {
    switch (activeStep) {
      case 1:
        return (
          <>
            <Row className="flex-nowrap">
              <Col className="px-md-1" sm="3" md="3">
                <FormGroup className="flex-nowrap">
                  <Label for="tagNumber" style={{ color: "#ffffff" }}>
                  TAG NUMBER
                  </Label>
                  <Input
                    type="text"
                    value={tagNumber}
                    onChange={(e) => setTagNumber(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" sm="3" md="3">
                <FormGroup className="flex-nowrap">
                  <Label
                    for="toolLocationNo"
                    style={{ color: "#ffffff" }}
                  >
                   TOOL LOCATION NO
                  </Label>
                  <Input
                    type="text"
                    id="toolLocationNo"
                    value={toolLocationNo}
                    onChange={(e) => setToolLocationNo(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" sm="3" md="3">
                <FormGroup className="flex-nowrap">
                  <Label
                    for="production_end_customer_name"
                    style={{ color: "#ffffff" }}
                  >
                    OP NO
                  </Label>
                  <Input
                    type="text"
                    id="production_end_customer_name"
                    value={opNo}
                    onChange={(e) => setOpNo(e.target.value)}
                    style={{
                      textShadow: "1px 1px 1px rgba(0,0,0,0.5)",
                      color: "rgba(255, 255, 255, 0.8)",
                    }}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" sm="3" md="3">
                <FormGroup className="flex-nowrap">
                  <Label
                    for="opName"
                    style={{ color: "#ffffff" }}
                  >
                    OP NAME
                  </Label>
                  <Input
                    placeholder=""
                    type="text"
                    value={opName}
                    onChange={(e) =>
                      setOpName(e.target.value)
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="flex-nowrap">
             
              <Col className="px-md-1" sm="3" md="3">
                <FormGroup className="flex-nowrap">
                  <Label
                    for="production_machine_name"
                    style={{ color: "#ffffff" }}
                  >
                    PART NO
                  </Label>
                  <Input
                    type="text"
                    value={partNo}
                    onChange={(e) => setPartNo(e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col className="px-md-1" sm="3" md="3">
                <FormGroup className="flex-nowrap">
                  <Label for="partName" style={{ color: "#ffffff" }}>
                  PART NAME
                  </Label>
                  <Input
                    placeholder=""
                    type="text"
                    value={partName}
                    onChange={(e) => setPartName(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" sm="3" md="3">
                <FormGroup className="flex-nowrap">
                  <Label for="dieNo" style={{ color: "#ffffff" }}>
                  DIE NUMBER
                  </Label>
                  <Input
                    type="text"
                    value={dieNo}
                    onChange={(e) => setDieNo(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" sm="3" md="3">
                <FormGroup className="flex-nowrap">
                  <Label for="actualPartId" style={{ color: "#ffffff" }}>
                  ACTUAL PART ID
                  </Label>
                  <Input
                    placeholder=""
                    type="text"
                    value={actualPartId}
                    onChange={(e) => setActualPartId(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        );
      default:
        return null;
    }
  };
  
  const renderStepButtons = () => {
    if (activeStep === 1) {
      return (
        <>
          <Button color="danger" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color="success"
            onClick={(event) => {
              handleFormSubmit(event);
              handleClose(event);
            }}
          >
            Save
          </Button>
        </>
      );
     
    }
  };
  return (
    <div className="content">
     
      <Row className="progress-row">
        {isCardOpen && (
          <Col md="8">
            <Card>
              <CardHeader>
                <h4 className="title" style={{ color: "#ffffff" }}>
                  Add Tool and Die
                  <button type="button" className="close" aria-label="Close" 
                    style={{
                    // float: "right", 
                    background: "transparent",
                    border: "none",
                    color: "#ffffff" ,
                    fontSize: "35px",
                    cursor: "pointer",
                    marginTop: "-40px",
                    }}
                    onClick={handleClose}
                    >
                    &times;
                    </button>
                </h4>
              </CardHeader>
              <CardBody>
                <Form>{renderStepContent()}</Form>
              </CardBody>
              <CardFooter>
                <div className="text-right">{renderStepButtons()}</div>
              </CardFooter>
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
}
export default ToolAndDieAdd;
