import React, { useState, useEffect } from "react";
import "./style.css";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import redirectToLoginIfTokenMissing from "./TokenUtility";
import classnames from "classnames";
import DateWishCycleTime from "./DateWishCycleTime";
import ShiftWishCycleTime from "./ShiftWishCycleTime";
import CycleTimeDeviation from "./CycleTimeDeviation";
import OperatorWiseCycleTime from "./OperatorWiseCycleTime";
import classNames from "classnames";
import axios from "axios";
import ReactSpeedometer from "react-d3-speedometer";
import { APIURL } from "./Apiurl.js";
import MachineWiseCycleTime from "components/CycleTime/MachineWiseCycleTime";
import PartWiseCycleTime from "components/CycleTime/PartWiseCycleTime";
import { QueryApi } from "QueryApi";
import LineChart from "assets/charts/LineChart";

function ManagePeople(props) {
  const [DowntimebigChartData, setDowntimebigChartData] =
    React.useState("data1");
  const [activeTab, setActiveTab] = useState("1");
  const storedTenantId = sessionStorage.getItem("selectedTenantId");
  console.log("selected Tenant ID:", storedTenantId);
  useEffect(() => {
    redirectToLoginIfTokenMissing(); // Call the utility function to validate the token
  }, []);
  const [org, setorg] = useState("");
  const [unit, setunit] = useState("");
  const [dept, setdept] = useState("");
  const [depts, setdepts] = useState([]);
  const [machine, setMachine] = useState("");
  const [machines, setMachines] = useState([]);
  const [plants, setplants] = useState([]);
  const [orgs, setorgs] = useState([]);
  const [otherData, setOtherData] = useState([]);
  const [selectedOrgData, setSelectedOrgData] = useState(null);
  const [apiData, setApiData] = useState([]);
  console.log("apiData------>", apiData);
  // useEffect(() => {
  //   fetchData();
  // }, []);

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const currDay = day < 10 ? `0${day}` : day;
  const currMonth = month < 10 ? `0${month}` : month;
  let currentDate = JSON.stringify(`${year}-${currMonth}-${currDay}`);

  const dateData = new Date();
  dateData.setDate(dateData.getDate() - 6);
  var dayDataValue = dateData.getDate();
  var monthDataValue = dateData.getMonth() + 1;
  var yearDataValue = dateData.getFullYear();
  var formattedDay = dayDataValue < 10 ? `0${dayDataValue}` : dayDataValue;
  var formattedMonth =
    monthDataValue < 10 ? `0${monthDataValue}` : monthDataValue;
  var weekDataDate = JSON.stringify(
    `${yearDataValue}-${formattedMonth}-${formattedDay}`
  );

  const previousDate = new Date();
  previousDate.setDate(1);
  const previousDay = previousDate.getDate();
  const previousMonth = previousDate.getMonth() + 1;
  const previousYear = previousDate.getFullYear();
  const formattedPreviousDay =
    previousDay < 10 ? `0${previousDay}` : previousDay;
  const formattedPreviousMonth =
    previousMonth < 10 ? `0${previousMonth}` : previousMonth;
  const previousMonthDate = JSON.stringify(
    `${previousYear}-${formattedPreviousMonth}-${formattedPreviousDay}`
  );

  const yearCurrentDate = new Date();
  yearCurrentDate.setDate(1);
  yearCurrentDate.getMonth(1);
  const yearDay = yearCurrentDate.getDate();
  const yearMonth = yearCurrentDate.getMonth();
  const yearYear = yearCurrentDate.getFullYear();
  const formattedYearDay = yearDay < 10 ? `0${yearDay}` : yearDay;
  const formattedYearMonth = yearMonth < 10 ? `0${yearMonth}` : yearMonth;
  const yearPreviousMonthDate = JSON.stringify(
    `${yearYear}-${formattedYearMonth}-${formattedYearDay}`
  );

  const token = sessionStorage.getItem("token");
  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const tenantId =
    decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
  console.log(tenantId, "is a tenantId for cycle time");
  useEffect(() => {
    fetchplant();
  }, [org]);
  const fetchorg = async (logintenantId) => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery($logintenantId: Int!) {
          tnt_organization_structure(
            where: {
              parent_organization_id: { _eq: 0 },
              tenantid: { _eq: $logintenantId }
            }
          ) {
            organization_structure_id
            organization_node_name
          }
        }
      `,
          variables: {
            logintenantId: parseInt(logintenantId),
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );
      const fetchedOrgs = response.data.data.tnt_organization_structure;
      setorgs(fetchedOrgs);
      console.log(fetchedOrgs); //PsplDemoTent
      const parentIds = fetchedOrgs.map((org) => org.organization_structure_id);
      console.log(parentIds); //parentID == 41

      const childResponse = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery($parentIds: [Int!]) {
          tnt_organization_structure(
            where: {
              parent_organization_id: { _in: $parentIds }
            }
          ) {
            organization_structure_id
            organization_node_name
          }
        }
      `,
          variables: {
            parentIds: parentIds,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      const childOrgs = childResponse.data.data.tnt_organization_structure;
      console.log(childOrgs); // select organization == psplDemoTent_Org
      const updatedOtherData = otherData.map((row) => {
        const matchingOrg = childOrgs.find(
          (org) => org.organization_structure_id === row.parent_organization_id
        );

        if (matchingOrg) {
          return {
            ...row,
            parent_organization_id: matchingOrg.organization_structure_id,
          };
        }

        return row;
      });

      setOtherData(childOrgs);
      console.log(otherData);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["sub"];
    let logintenantId = null;

    if (roleId == 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
    }

    fetchorg(logintenantId);
  }, []);
  const fetchplant = async () => {
    if (org) {
      // Only fetch if org is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
          query MyQuery($p: Int!) {
            tnt_organization_structure(where: {parent_organization_id: {_eq: $p}}) {
              organization_structure_id
              organization_node_name
            }
          }
        `,
            variables: {
              p: org,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        // console.log(org);
        setplants(response.data.data.tnt_organization_structure);
        console.log(response.data.data.tnt_organization_structure);
      } catch (error) {
        console.error(error);
      }
    }
  };
  useEffect(() => {
    fetchdept();
  }, [unit]);
  const fetchdept = async () => {
    if (unit) {
      // Only fetch if org is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
          query MyQuery($d: Int!) {
            tnt_organization_structure(where: {parent_organization_id: {_eq: $d}}) {
              organization_structure_id
              organization_node_name
            }
          }
        `,
            variables: {
              d: unit,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        // console.log(org);
        setdepts(response.data.data.tnt_organization_structure);
        console.log(response.data.data.tnt_organization_structure);
      } catch (error) {
        console.error(error);
      }
    }
  };
  console.log(process.env.REACT_APP_X_API_SECRET_KEY);

  useEffect(() => {
    fetchMachine();
  }, [dept]);
  const fetchMachine = async () => {
    if (dept) {
      // Only fetch if dept is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
          query MyQuery($m: Int!) {
           tnt_org_machine_assignment(where: {dept: {organization_structure_id: {_eq: $m}}} ) {
              machine_name
            }
          }
        `,
            variables: {
              m: dept,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        // console.log(org);
        setMachines(response.data.data.tnt_org_machine_assignment || []);
        console.log(response.data.data.tnt_organization_structure);
      } catch (error) {
        console.error(error);
      }
    }
  };

  console.log(process.env.REACT_APP_X_API_SECRET_KEY);

  const handleOrgSelect = async (selectedOrgId) => {
    if (selectedOrgId) {
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
          query GetCostCenter($orgId: Int!) {
            tnt_organization_structure(where: { organization_structure_id: { _eq: $orgId } }) {
              cost_centre_code
            }
          }
        `,
            variables: {
              orgId: selectedOrgId,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );

        if (
          response.data &&
          response.data.data &&
          response.data.data.tnt_organization_structure &&
          response.data.data.tnt_organization_structure.length > 0
        ) {
          setSelectedOrgData(response.data.data.tnt_organization_structure[0]);
        } else {
          console.log(response);
          setSelectedOrgData(null);
        }
      } catch (error) {
        console.error("Error fetching organization data:", error);
      }
    } else {
      setSelectedOrgData(null);
    }

    setorg(selectedOrgId);
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const [bigChartData, setbigChartData] = React.useState("data1");
  const setBgChartData = (name) => {
    setbigChartData(name);
  };

  // const fetchData = async () => {
  //   try {
  //     const token = sessionStorage.getItem("token");
  //     const decodedToken = JSON.parse(atob(token.split(".")[1]));
  //     const roleId = decodedToken["sub"];
  //     const tenantId =
  //       decodedToken["https://hasura.io/jwt/claims"]["x-hasura-tenant-id"];
  //     const storedTenantId = sessionStorage.getItem("selectedTenantId");
  //     let variables = {};
  //     if (roleId === 1) {
  //       if (storedTenantId !== null) {
  //         variables = { tenantId: storedTenantId };
  //       }
  //     } else {
  //       variables = { tenantId: tenantId };
  //     }
  //     let query = `
  //     query MyQuery {
  //       dm_foundation(order_by: {date: desc}) {
  //         actual_cycletime
  //         actual_production_time
  //         all_available_time
  //         asset_utilization_percent
  //         availability_loss_percent
  //         availability_loss_time
  //         batch_id
  //         capacity_utilized_percent
  //         cycletime_deviation
  //         date
  //         department_id
  //         edgeid
  //         machine_availability_percent
  //         machine_idle_time
  //         machine_performance_percent
  //         machine_up_time
  //         machineid
  //         net_available_time
  //         no_of_parts_rejected
  //         operator_id
  //         org_id
  //         parts_per_minute
  //         planned_downtime_percent
  //         planned_production_time
  //         plant_operating_time_percent
  //         quality_percent
  //         shift_id
  //         supervisor_id
  //         target_adherence
  //         target_parts
  //         tenantid
  //         time_between_job_parts
  //         timestamp
  //         total_machine_downtime
  //         total_machine_runtime
  //         total_parts_produced
  //         total_planned_downtime
  //         total_production_per_batch
  //         unit_id
  //         unplanned_downtime
  //         org {
  //           organization_node_name
  //         }
  //         dept {
  //           organization_node_name
  //         }
  //         unit {
  //           organization_node_name
  //         }
  //       }
  //     }
  //     `;
  //     const response = await axios.post(
  //       APIURL,
  //       {
  //         query,
  //         variables,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           // "x-hasura-admin-secret": process.env.REACT_APP_X_API_SECRET_KEY,
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     setApiData(response.data.data.dm_foundation);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const [meterDataList, setMeterDataList] = useState();

  useEffect(() => {
    meterData();
  }, []);

  const meterData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const roleId = decodedToken["sub"];
      const tenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-tenant-id"];
      const storedTenantId = sessionStorage.getItem("selectedTenantId");
      let variables = {};
      if (roleId === 1) {
        if (storedTenantId !== null) {
          variables = { tenantId: storedTenantId };
        }
      } else {
        variables = { tenantId: tenantId };
      }
      let query = `
      query MyQuery($date: date = "", $start_date: date = "2024-01-20", $end_date: date = "2024-01-20") {
        dm_foundation_aggregate(where: {date: {_gte: $start_date, _lte: $end_date}}) {
          aggregate {
            avg {
              machine_availability_percent
              total_machine_downtime
              cycletime_deviation
            }
          }
        }
      }      
      `;
      const response = await axios.post(
        APIURL,
        {
          query,
          variables,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-hasura-admin-secret": process.env.REACT_APP_X_API_SECRET_KEY,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMeterDataList(
        response.data.data.dm_foundation_aggregate.aggregate.avg
      );
    } catch (error) {
      console.error(error);
    }
  };

  //// Availability ////////////////////////////////////////////////////////////////////////

  const [AvailabilityDataList, setAvailabilityDataList] = useState({
    machine_availability_percent: "No Data",
  });
  useEffect(() => {
    Availability();
  }, []);
  const Availability = async () => {
    try {
      const data = await QueryApi(`
        query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
          dm_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
            aggregate {
              avg {
                machine_availability_percent
              }
            }
          }
        } `);
      setAvailabilityDataList({
        machine_availability_percent:
          data.dm_daily_aggregate.aggregate.avg.machine_availability_percent,
      });
    } catch (error) {
      setAvailabilityDataList({
        machine_availability_percent: "No Data",
      });
    }
  };

  ///// Average Downtime ////////////////////////////////////////////

  const [AverageDowntimeDataList, setAverageDowntimeDataList] = useState({
    total_machine_downtime: "No Data",
  });
  useEffect(() => {
    AverageDowntime();
  }, []);
  const AverageDowntime = async () => {
    try {
      const data = await QueryApi(`
        query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
          dm_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
            aggregate {
              sum {
                total_machine_downtime
              }
            }
          }
        } `);
      const hoursDowntime =
        data.dm_daily_aggregate.aggregate.sum.total_machine_downtime /
        (1000 * 60 * 60);
      setAverageDowntimeDataList({
        total_machine_downtime: hoursDowntime.toFixed(0),
      });
    } catch (error) {
      setAverageDowntimeDataList({
        total_machine_downtime: "No Data",
      });
    }
  };

  //// Downtime Trendline ///////////////////////////////////////

  const [
    xAxisDataValuePerformanceInsights,
    setXAxisDataValuePerformanceInsights,
  ] = useState([]);
  const [plotPointPerformanceInsights, setPlotPointPerformanceInsights] =
    useState([]);
  const datasetPerformanceInsights = [
    {
      label: "Downtime Trendline",
      data: plotPointPerformanceInsights,
      borderColor: "#1F8EF1",
    },
  ];

  useEffect(() => {
    GraphDataListPerformanceInsights();
  }, [DowntimebigChartData]);
  const GraphDataListPerformanceInsights = async () => {
    if (DowntimebigChartData === "data1") {
      try {
        const data = await QueryApi(`
            query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
             dm_org_tenant_hourly(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                   total_machine_downtime
                    timestamp
                }
            }
          `);
        setXAxisDataValuePerformanceInsights([
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
          20, 21, 22, 23, 24,
        ]);
        const plotAllDataPerformanceInsights = data.dm_org_tenant_hourly.map(
          (data) => {
            const objData = {
              point: (data.total_machine_downtime / (1000 * 60 * 60)).toFixed(
                2
              ),
              Xaxis: data.timestamp,
            };
            return objData;
          }
        );
        const pointDate = new Date();
        const resPointDate = pointDate.getHours();
        let plotValue = [];
        for (var i = 0; i <= resPointDate; i++) {
          plotValue[i] = 0;
        }
        plotAllDataPerformanceInsights.forEach((data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
          if (h >= 0 && h <= 1) {
            plotValue[0] = data.point;
          } else if (h >= 1 && h <= 2) {
            plotValue[1] = data.point;
          } else if (h >= 2 && h <= 3) {
            plotValue[2] = data.point;
          } else if (h >= 3 && h <= 4) {
            plotValue[3] = data.point;
          } else if (h >= 4 && h <= 5) {
            plotValue[4] = data.point;
          } else if (h >= 5 && h <= 6) {
            plotValue[5] = data.point;
          } else if (h >= 6 && h <= 7) {
            plotValue[6] = data.point;
          } else if (h >= 7 && h <= 8) {
            plotValue[7] = data.point;
          } else if (h >= 8 && h <= 9) {
            plotValue[8] = data.point;
          } else if (h >= 9 && h <= 10) {
            plotValue[9] = data.point;
          } else if (h >= 10 && h <= 11) {
            plotValue[10] = data.point;
          } else if (h >= 11 && h <= 12) {
            plotValue[11] = data.point;
          } else if (h >= 12 && h <= 13) {
            plotValue[12] = data.point;
          } else if (h >= 13 && h <= 14) {
            plotValue[13] = data.point;
          } else if (h >= 14 && h <= 15) {
            plotValue[14] = data.point;
          } else if (h >= 15 && h <= 16) {
            plotValue[15] = data.point;
          } else if (h >= 16 && h <= 17) {
            plotValue[16] = data.point;
          } else if (h >= 17 && h <= 18) {
            plotValue[17] = data.point;
          } else if (h >= 18 && h <= 19) {
            plotValue[18] = data.point;
          } else if (h >= 19 && h <= 20) {
            plotValue[19] = data.point;
          } else if (h >= 20 && h <= 21) {
            plotValue[20] = data.point;
          } else if (h >= 21 && h <= 22) {
            plotValue[21] = data.point;
          } else if (h >= 22 && h <= 23) {
            plotValue[22] = data.point;
          } else if (h >= 23 && h <= 24) {
            plotValue[23] = data.point;
          } else {
            return null;
          }
        });
        setPlotPointPerformanceInsights(plotValue);
      } catch (error) {
        console.log(error);
        setXAxisDataValuePerformanceInsights([]);
        setPlotPointPerformanceInsights([]);
      }
    } else if (DowntimebigChartData === "data2") {
      try {
        const data = await QueryApi(`
            query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                dm_org_tenant_daily(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                    total_machine_downtime
                    timestamp
                }
            }
          `);
        const plotAllDataPerformanceInsights = data.dm_org_tenant_daily.map(
          (data) => {
            const objData = {
              point: data.total_machine_downtime,
              Xaxis: data.timestamp,
            };
            return objData;
          }
        );

        function getPlotAllDaysBetween(startDate, endDate) {
          var newDate = new Date(startDate);
          var weekDATE = new Date(endDate);
          var days = [];
          while (newDate <= weekDATE) {
            const dd = newDate.getDate();
            const mm = newDate.getMonth() + 1;
            const yy = newDate.getFullYear();
            const formattedd = dd < 10 ? `0${dd}` : dd;
            const formattedmm = mm < 10 ? `0${mm}` : mm;
            const fullDATE = `${yearYear}-${formattedmm}-${formattedd}`;
            days.push(fullDATE);
            newDate.setDate(newDate.getDate() + 1);
          }
          return days;
        }
        var daysBetween = getPlotAllDaysBetween(weekDataDate, currentDate);
        var pointArr = [];
        daysBetween.map((data) => {
          var arrlet = plotAllDataPerformanceInsights.map((data) => {
            var arrDate = new Date(data.Xaxis);
            let nday = arrDate.getUTCDate();
            let nmonth = arrDate.getMonth() + 1;
            let nyear = arrDate.getFullYear();
            const ncurrDay = nday < 10 ? `0${nday}` : nday;
            const ncurrMonth = nmonth < 10 ? `0${nmonth}` : nmonth;
            let nDateList = `${nyear}-${ncurrMonth}-${ncurrDay}`;
            return nDateList;
          });
          const res = arrlet.includes(data);
          const resindex = arrlet.indexOf(data);
          if (res === true) {
            pointArr.push(plotAllDataPerformanceInsights[resindex].point);
          } else {
            pointArr.push(0);
          }
        });
        setPlotPointPerformanceInsights(pointArr);

        function getAllDaysBetween(startDate, endDate) {
          var currentDate = new Date(startDate);
          var days = [];
          while (currentDate <= endDate) {
            days.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
          }
          return days;
        }
        function getDayOfWeek(date) {
          var days = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];
          return days[date.getDay()];
        }
        function getDaysOfWeekBetween(startDate, endDate) {
          var daysBetween = getAllDaysBetween(startDate, endDate);
          var daysOfWeek = [];
          for (var i = 0; i < daysBetween.length; i++) {
            var dayOfWeek = getDayOfWeek(daysBetween[i]);
            daysOfWeek.push(dayOfWeek);
          }
          return daysOfWeek;
        }
        var daysOfWeekBetween = getDaysOfWeekBetween(
          new Date(weekDataDate),
          new Date(currentDate)
        );
        setXAxisDataValuePerformanceInsights(daysOfWeekBetween);
      } catch (error) {
        console.log(error);
        setXAxisDataValuePerformanceInsights([]);
        setPlotPointPerformanceInsights([]);
      }
    } else if (DowntimebigChartData === "data3") {
      try {
        const data = await QueryApi(`
        query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_org_tenant_daily(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                total_machine_downtime
                date
            }
        }
      `);
        const plotAllDataPerformanceInsights = data.dm_org_tenant_daily.map(
          (data) => {
            const objData = {
              point: (data.total_machine_downtime / (1000 * 60 * 60)).toFixed(
                2
              ),
              Xaxis: data.date,
            };
            return objData;
          }
        );

        const bothAxisPoint = [];
        for (var i = 1; i <= 31; i++) {
          bothAxisPoint[i] = { pointValue: 0, XaxisValue: i };
        }
        plotAllDataPerformanceInsights.forEach((data) => {
          const allDate = new Date(data.Xaxis);
          const dayss = allDate.getDate();
          bothAxisPoint[dayss] = { pointValue: data.point, XaxisValue: dayss };
        });
        const NewPointData = bothAxisPoint.map((data) => data.pointValue);
        const NewXaxisData = bothAxisPoint.map((data) => data.XaxisValue);
        setPlotPointPerformanceInsights(NewPointData);
        setXAxisDataValuePerformanceInsights(NewXaxisData);
      } catch (error) {
        console.log(error);
        setXAxisDataValuePerformanceInsights([]);
        setPlotPointPerformanceInsights([]);
      }
    } else if (DowntimebigChartData === "data4") {
      try {
        const data = await QueryApi(`
            query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
              dm_org_tenant_monthly(where: {start_date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                total_machine_downtime
                 start_date
            }
          }
          `);
        const plotAllDataPerformanceInsights = data.dm_org_tenant_monthly.map(
          (data) => {
            const objData = {
              point: data.total_machine_downtime,
              Xaxis: data.start_date,
            };
            return objData;
          }
        );
        const plotPointDataPerformanceInsights =
          plotAllDataPerformanceInsights.map((data) =>
            (data.point / (1000 * 60 * 60)).toFixed(2)
          );
        setPlotPointPerformanceInsights(plotPointDataPerformanceInsights);
        setXAxisDataValuePerformanceInsights([
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ]);
      } catch (error) {
        console.log(error);
        setXAxisDataValuePerformanceInsights([]);
        setPlotPointPerformanceInsights([]);
      }
    } else {
      setDowntimebigChartData("data1");
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="3">
            <Card
              style={{ fontSize: "16px", fontWeight: "bold", color: "white" }}
            >
              Cycle Time Analysis Deep down - Level 1
            </Card>
          </Col>
        </Row>
        {/* <Row>
          <Col lg="2">
            <FormGroup>
              <Input
                type="select"
                value={org}
                onChange={(e) => handleOrgSelect(e.target.value)}
              >
                <option value="">Select Organization</option>
                {otherData.map((org) => (
                  <option
                    key={org.organization_structure_id}
                    value={org.organization_structure_id}
                  >
                    {org.organization_node_name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <Input
                type="select"
                value={unit}
                onChange={(e) => setunit(e.target.value)}
              >
                <option value="">Select Unit/Plant</option>
                {plants.map((plant) => (
                  <option
                    key={plant.organization_structure_id}
                    value={plant.organization_structure_id}
                  >
                    {plant.organization_node_name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <Input
                type="select"
                value={dept}
                onChange={(e) => setdept(e.target.value)}
              >
                <option value="">Select department</option>
                {depts.map((dpt) => (
                  <option
                    key={dpt.organization_structure_id}
                    value={dpt.organization_structure_id}
                  >
                    {dpt.organization_node_name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <Input
                type="select"
                value={machine}
                onChange={(e) => setMachine(e.target.value)}
              >
                <option value="">Select Machine</option>
                {machines.map((mc) => (
                  <option
                    key={mc.organization_structure_id}
                    value={mc.organization_structure_id}
                  >
                    {mc.machine_name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <Input type="date" />
            </FormGroup>
          </Col>
          <Col lg="2">
            <Button lg="2" className="btn btn-secondary">
              Submit
            </Button>
            <Button lg="2" className="btn btn-secondary">
              Clear
            </Button>
          </Col>
        </Row> */}
        <Row>
          <Col lg="2">
            <Card className="card-chart" style={{ height: "336px" }}>
              <CardHeader>
                <h5 className="card-category">Availability</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-chart-pie text-info" />{" "}
                  {AvailabilityDataList.machine_availability_percent}
                </CardTitle>
              </CardHeader>
              <CardBody
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  className="gauge-container"
                  style={{
                    height: "100%",
                    width: "100%",
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ReactSpeedometer
                    value={AvailabilityDataList.machine_availability_percent} // Change this to your desired value
                    minValue={0}
                    maxValue={100}
                    width={250}
                    height={250}
                    needleColor="wheat" // Customize the needle color
                    startColor="#355e3b" // Customize the start color of the gauge
                    segments={5} // Number of segments on the gauge
                    endColor="#39ff14" // Customize the end color of the gauge
                    textColor="white" // Text color
                    ringWidth={20}
                    currentValueText="${value} %" // Text shown in the center
                  />
                </div>
                {/* <div
                  className="button-groups-container"
                  style={{ marginTop: "20px" }}
                >
                  <div className="button-group">
                    <button className="btn btn-transparent btn-sm active">
                      Day
                    </button>
                    <button className="btn btn-transparent btn-sm">Week</button>
                    <button className="btn btn-transparent btn-sm">
                      Month
                    </button>
                    <button className="btn btn-transparent btn-sm">Year</button>
                  </div>
                </div> */}
              </CardBody>
            </Card>
          </Col>
          <Col lg="2">
            <Card className="card-chart" style={{ height: "336px" }}>
              <CardHeader>
                <h5 className="card-category">Average Downtime</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-chart-pie text-info" />{" "}
                  {AverageDowntimeDataList.total_machine_downtime}
                </CardTitle>
              </CardHeader>
              <CardBody
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  className="gauge-container"
                  style={{
                    height: "100%",
                    width: "100%",
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ReactSpeedometer
                    value={AverageDowntimeDataList.total_machine_downtime} // Change this to your desired value
                    minValue={0}
                    maxValue={100}
                    width={250}
                    height={250}
                    needleColor="wheat" // Customize the needle color
                    startColor="#FF4D01" // Customize the start color of the gauge
                    segments={5} // Number of segments on the gauge
                    endColor="#ffef00" // Customize the end color of the gauge
                    textColor="white" // Text color
                    ringWidth={20}
                    currentValueText="${value} %" // Text shown in the center
                  />
                </div>
                {/* <div
                  className="button-groups-container"
                  style={{ marginTop: "20px" }}
                >
                  <div className="button-group">
                    <button className="btn btn-transparent btn-sm active">
                      Day
                    </button>
                    <button className="btn btn-transparent btn-sm">Week</button>
                    <button className="btn btn-transparent btn-sm">
                      Month
                    </button>
                    <button className="btn btn-transparent btn-sm">Year</button>
                  </div>
                </div> */}
              </CardBody>
            </Card>
          </Col>
          <Col lg="2">
            <Card className="card-chart" style={{ height: "336px" }}>
              <CardHeader>
                <h5 className="card-category">Cycle Time loss</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-chart-pie text-info" /> 31%
                </CardTitle>
              </CardHeader>
              <CardBody
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  className="gauge-container"
                  style={{
                    height: "100%",
                    width: "100%",
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ReactSpeedometer
                    value={31} // Change this to your desired value
                    minValue={0}
                    maxValue={100}
                    width={250}
                    height={250}
                    needleColor="wheat" // Customize the needle color
                    startColor="#990000" // Customize the start color of the gauge
                    segments={5} // Number of segments on the gauge
                    endColor="#ff0000" // Customize the end color of the gauge
                    textColor="white" // Text color
                    ringWidth={20}
                    currentValueText="${value} %" // Text shown in the center
                  />
                </div>
                {/* <div
                  className="button-groups-container"
                  style={{ marginTop: "20px" }}
                >
                  <div className="button-group">
                    <button className="btn btn-transparent btn-sm active">
                      Day
                    </button>
                    <button className="btn btn-transparent btn-sm">Week</button>
                    <button className="btn btn-transparent btn-sm">
                      Month
                    </button>
                    <button className="btn btn-transparent btn-sm">Year</button>
                  </div>
                </div> */}
              </CardBody>
            </Card>
          </Col>
          <Col lg="2">
            <Card className="card-chart" style={{ height: "336px" }}>
              <CardHeader>
                <h5 className="card-category">Cycle Time Deviation</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-chart-pie text-info" /> 31%
                </CardTitle>
              </CardHeader>
              <CardBody
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  className="gauge-container"
                  style={{
                    height: "100%",
                    width: "100%",
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ReactSpeedometer
                    value={31} // Change this to your desired value
                    minValue={0}
                    maxValue={100}
                    width={250}
                    height={250}
                    needleColor="wheat" // Customize the needle color
                    startColor="green" // Customize the start color of the gauge
                    segments={5} // Number of segments on the gauge
                    endColor="blue" // Customize the end color of the gauge
                    textColor="white" // Text color
                    ringWidth={20}
                    currentValueText="${value} %" // Text shown in the center
                  />
                </div>
                {/* <div
                  className="button-groups-container"
                  style={{ marginTop: "20px" }}
                >
                  <div className="button-group">
                    <button className="btn btn-transparent btn-sm active">
                      Day
                    </button>
                    <button className="btn btn-transparent btn-sm">Week</button>
                    <button className="btn btn-transparent btn-sm">
                      Month
                    </button>
                    <button className="btn btn-transparent btn-sm">Year</button>
                  </div>
                </div> */}
              </CardBody>
            </Card>
          </Col>
          <Col xs="4">
            <Card className="card-chart" style={{ height: "336px" }}>
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5 className="card-category">Downtime Trendline</h5>
                  </Col>
                  <Col sm="6">
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <Button
                        tag="label"
                        className={classNames("btn-simple", {
                          active: DowntimebigChartData === "data1",
                        })}
                        color="info"
                        id="0"
                        size="sm"
                        onClick={() => setDowntimebigChartData("data1")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Day
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-single-02" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: DowntimebigChartData === "data2",
                        })}
                        onClick={() => setDowntimebigChartData("data2")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Week
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-gift-2" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: DowntimebigChartData === "data3",
                        })}
                        onClick={() => setDowntimebigChartData("data3")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Month
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-tap-02" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: DowntimebigChartData === "data4",
                        })}
                        onClick={() => setDowntimebigChartData("data4")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Year
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-tap-02" />
                        </span>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <LineChart
                  xAxisData={xAxisDataValuePerformanceInsights}
                  dataset={datasetPerformanceInsights}
                  height="100%"
                  width="100%"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => toggleTab("1")}
              style={{
                backgroundColor: activeTab === "1" ? "#5555e7" : "transparent",
                color: activeTab === "1" ? "white" : "white",
              }}
            >
              Date Wise Cycle Time
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => toggleTab("2")}
              style={{
                backgroundColor: activeTab === "2" ? "#5555e7" : "transparent",
                color: activeTab === "2" ? "white" : "white",
              }}
            >
              Shift Wise Cycle Time
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => toggleTab("3")}
              style={{
                backgroundColor: activeTab === "3" ? "#5555e7" : "transparent",
                color: activeTab === "3" ? "white" : "white",
              }}
            >
              Operator Wise Cycle Time
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "4" })}
              onClick={() => toggleTab("4")}
              style={{
                backgroundColor: activeTab === "4" ? "#5555e7" : "transparent",
                color: activeTab === "4" ? "white" : "white",
              }}
            >
              Cycle Time Deviation
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "5" })}
              onClick={() => toggleTab("5")}
              style={{
                backgroundColor: activeTab === "5" ? "#5555e7" : "transparent",
                color: activeTab === "5" ? "white" : "white",
              }}
            >
              Machine Wise Cycle Time
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "6" })}
              onClick={() => toggleTab("6")}
              style={{
                backgroundColor: activeTab === "6" ? "#5555e7" : "transparent",
                color: activeTab === "6" ? "white" : "white",
              }}
            >
              Part Wise Cycle Time
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col>
                <DateWishCycleTime apiData={apiData} />
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="2">
            <Row>
              <Col>
                <ShiftWishCycleTime apiData={apiData} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col>
                <OperatorWiseCycleTime apiData={apiData} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="4">
            <Row>
              <Col>
                <CycleTimeDeviation apiData={apiData} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="5">
            <Row>
              <Col>
                <MachineWiseCycleTime apiData={apiData} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="6">
            <Row>
              <Col>
                <PartWiseCycleTime apiData={apiData} />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </>
  );
}

export default ManagePeople;
