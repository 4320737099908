import React, { useState, useEffect } from "react";
import "./style.css";
import "./responsive.css";
import { Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import ManageMachine from "./ManageMachine";

function ManageOrg(props) {
  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      sessionStorage.setItem("activeTab", tab);
    }
  };
  useEffect(() => {
    // Retrieve the active tab from sessionStorage
    const storedActiveTab = sessionStorage.getItem("activeTab");
    if (storedActiveTab) {
      setActiveTab(storedActiveTab);
    }
  }, []);
  const getBreadcrumbItemText = (tabId) => {
    switch (tabId) {
      case "1":
        return "Machine Organization";
      case "2":
        return "Machine/Equipment";
      case "3":
        return "Machine Parameters";
      default:
        return "Machine Organization";
    }
  };
  return (
    <>
      <div className="content">
        <div class="container1">
          <h1 className="main-heading">Device Management</h1>
          <div class="breadcrumb-container">
            <Breadcrumb>
              <BreadcrumbItem>
                <FontAwesomeIcon icon={faHouse} />
              </BreadcrumbItem>
              <BreadcrumbItem>Device Management</BreadcrumbItem>
              <BreadcrumbItem active>
                {getBreadcrumbItemText(activeTab)}
              </BreadcrumbItem>
            </Breadcrumb>
          </div>
        </div>
        <Nav tabs>
          {/* <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => toggleTab("1")}
          >
            Machine Organization
          </NavLink>
        </NavItem> */}
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => toggleTab("1")}
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor: activeTab === "1" ? "#5555e7" : "transparent",
                color: activeTab === "1" ? "white" : "white",
              }}
            >
              Machine/Equipment
            </NavLink>
          </NavItem>
          {/* <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => toggleTab("2")}
          >
            Machine Parameters
          </NavLink>
        </NavItem> */}
        </Nav>

        <TabContent activeTab={activeTab}>
          {/* <TabPane tabId="1">
          <Row>
            <Col>
              <MachineOrganization />
            </Col>
          </Row>
        </TabPane> */}
          <TabPane tabId="1">
            <Row>
              <Col>
                <ManageMachine />
              </Col>
            </Row>
          </TabPane>
          {/* <TabPane tabId="2">
          <Row>
            <Col>
              <ManageMachineParameter />
            </Col>
          </Row>
        </TabPane>
        */}
        </TabContent>
      </div>
    </>
  );
}

export default ManageOrg;
