async function fetchGraphQL(operationsDoc, operationName, variables) {
  const token = sessionStorage.getItem('token');
  var headersData = {};
  if (sessionStorage.getItem('roleId') === 1) {
    headersData = {
      "Content-Type": "application/json",
      "x-hasura-admin-secret": process.env.REACT_APP_X_API_SECRET_KEY,
      Authorization: `Bearer ${token}`,
    }
  } else {
    headersData = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
  }

  const result = await fetch(
    process.env.REACT_APP_HASURA_API,
    {
      method: "POST",
      headers: headersData,
      body: JSON.stringify({
        query: operationsDoc,
        variables: variables,
        operationName: operationName
      })
    }
  );
  return await result.json();
}

function fetchMyQuery(queryData) {
  return fetchGraphQL(
    queryData,
    "MyQuery",
  );
}

export async function QueryApi(queryData) {
  const { errors, data } = await fetchMyQuery(queryData);
  if (errors) {
    console.error(errors);
  }
  return data
}




// import React from 'react'
// import axios from "axios";

// export const QueryApi = async (queryData) => {

//     console.log('this is query api data ===================> ', queryData)
//     let headersData = {};
//     const token = sessionStorage.getItem('token');

//     if (sessionStorage.getItem('roleId') === 1) {
//         headersData = {
//             "Content-Type": "application/json",
//             "x-hasura-admin-secret": process.env.REACT_APP_X_API_SECRET_KEY,
//             Authorization: `Bearer ${token}`,
//         }
//     } else {
//         headersData = {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`,
//         }
//     }

//     axios({
//         url: process.env.REACT_APP_HASURA_API,
//         method: 'post',
//         data: {
//             query: queryData
//         },
//         headers: headersData,
//     })
//         .then((result) => {
//             console.log(result.data);
//         })
//         .catch((error) => {
//             console.error(error);
//         });
// }



