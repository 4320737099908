import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Progress,
} from "reactstrap";
import axios from "axios";
import  { APIURL } from './Apiurl.js';
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";


function ConfigCodeMaster() {
  const [activeStep, setActiveStep] = useState(1);
  const [checked, setChecked] = useState(false);
  const [progress, setProgress] = useState(0);
 

  const [udpedit, setudpedit] = useState({
    tenantid: "",
    pid: "",
    widgetid: "",
    pname: "",
    ptype: "",
    machinetype: "",
    parentparameterid: "",
    pdrivercategory: "",
    interlink1: "",
    interlink2: "",
    actioncat: "",
    outputdriver: "",
    unitofmeasure: "",
    direction: "",
    visualization: "",
  });

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${APIURL}/api/udp-assignment/${id}`)
      .then((response) => {
        setudpedit((prevState) => ({
          ...prevState,
          tenantid: response.data.tenant_id,
          pid: response.data.parameter_id,
          widgetid: response.data.widget_id,
          pname: response.data.parameter_name, 
          ptype: response.data.parameter_type,
          machinetype: response.data.machine_type,
          parentparameterid: response.data.parent_parameter_id,
          pdrivercategory: response.data.primary_driver_category,
          interlink1: response.data.interline1,
          interlink2: response.data.interline2,
          actioncat: response.data.actionability_category,
          outputdriver: response.data.output_driver,
          unitofmeasure: response.data.unit_of_measure,
          direction: response.data.direction,
          visualization: response.data.visualization,


        }));
       
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if the machine name is provided
   
    axios
      .post(`${APIURL}/api/udp-assignment/${id}`, udpedit)
      .then((response) => {
        console.log(response);
        navigate(`/udp/${id}`);
        if (response.status === 201) {
          window.location.href = "/admin/udp-assignment/all";
        } else {
          // Reset the form or show a success message
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setudpedit((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };



  

  const handleNext = () => {
    if (activeStep === 1 && progress === 0) {
      setProgress(50);
    } else if (activeStep === 2 && progress === 50) {
      setProgress(100);
    }
    setActiveStep(activeStep + 1);
  };

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };

  
  const renderStepContent = () => {
    switch (activeStep) {
      case 1:
        return (
          <>
        <Row>
                    
                    <Col className="px-md-1" md="6">
                      <FormGroup>
                        <label>Tenant ID</label>
                        <Input
                          placeholder="Tenant ID"
                          type="text"
                          name="tenantid"
                          value={udpedit.tenantid}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                        Parameter ID
                        </label>
                        <Input placeholder="Parameter ID" type="text" 
                          name="pid"
                        value={udpedit.pid}
                        onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Widget ID</label>
                        <Input
                          placeholder="Widget ID"
                          type="text"
                          name="widgetid"

                          value={udpedit.widgetid}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Parameter Name</label>
                        <Input
                          
                          placeholder="Parameter Name"
                          type="text"
                          name="pname"

                          value={udpedit.pname}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  
                  <Row>
                  <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Parameter Category</label>
                        <Input
                          placeholder="Parameter Category"
                          type="text"
                          name="pcategory"

                          value={udpedit.pcategory}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Parameter Type</label>
                        <Input
                          
                          placeholder="Parameter Type"
                          type="text"
                          name="ptype"

                          value={udpedit.ptype}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
          </>
        );
      case 2:
        return (
          <>
       <Row>
                  <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Machine Type</label>
                        <Input
                          placeholder="Machine Type"
                          type="text"
                          name="machinetype"

                          value={udpedit.machinetype}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Parent Parameter ID</label>
                        <Input
                          
                          placeholder="Parent Parameter ID"
                          type="text"
                          name="parentparameterid"

                          value={udpedit.parentparameterid}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Primary Driver Category</label>
                        <Input
                          placeholder="Primary Driver Category"
                          type="text"
                          name="pdrivercategory"

                          value={udpedit.pdrivercategory}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Interlink 1</label>
                        <Input
                          
                          placeholder="Interlink 1"
                          type="text"
                          name="interlink1"

                          value={udpedit.interlink1}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                  <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Interlink 2</label>
                        <Input
                          placeholder="Interlink 2"
                          type="text"
                          name="interlink2"

                          value={udpedit.interlink2}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Actionability catergory</label>
                        <Input
                          placeholder="Actionability catergory"
                          type="text"
                          name="actioncat"

                          value={udpedit.actioncat}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
          </>
        );
      case 3:
        return (
          <>
           <Row>
                  <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Output Driver</label>
                        <Input
                          placeholder="Output Driver"
                          type="text"
                          name="outputdriver"

                          value={udpedit.outputdriver}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Unit of Measure</label>
                        <Input
                          placeholder="Unit of Measure"
                          type="text"
                          name="unitofmeasure"

                          value={udpedit.unitofmeasure}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                  <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Direction</label>
                        <Input
                          placeholder="Direction"
                          type="text"
                          name="direction"

                          value={udpedit.direction}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Visualization</label>
                        <Input
                          placeholder="Visualization"
                          type="text"
                          name="visualization"

                          value={udpedit.visualization}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
          </>
        );
      default:
        return null;
    }
  };
  const calculateProgress = () => {
    return Math.round(((activeStep - 1) / 2) * 100); // Update the denominator to match the total number of steps minus one
  };
  

  const renderProgressBarDestinations = () => {
    const step1Class = activeStep >= 1 ? "progress-destination-active" : "";
    const step2Class = activeStep >= 2 ? "progress-destination-active" : "";
    const step3Class = activeStep >= 3 ? "progress-destination-active" : "";
    const step4Class = activeStep >= 4 ? "progress-destination-active" : "";
  
    return (
      <div className="progress-destinations">
        <div className={`progress-destination ${step1Class}`}>
          <span>1</span>
        </div>
        <div className={`progress-destination ${step2Class}`}>
          <span>2</span>
        </div>
        <div className={`progress-destination ${step3Class}`}>
          <span>3</span>
        </div>
        <div className={`progress-destination ${step4Class}`}>
          <span>4</span>
        </div>
      </div>
    );
  };

  
  
  return (
    <div className="content">
      <Row>
        <Col md="8">
          <div className="progress-container">
            {renderProgressBarDestinations()}
            <Progress
              max="100"
              value={calculateProgress()}
              className="progress-bar-sm progress-bar-striped"
            />
          </div>
        </Col>
      </Row>
      <Row className="progress-row">
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Add New Machine</h5>
            </CardHeader>
            <CardBody>
              <Form>{renderStepContent()}</Form>
            </CardBody>
            <CardFooter>
              <div className="text-right">
                {activeStep > 1 && (
                  <Button color="primary" onClick={handlePrevious}>
                    Previous
                  </Button>
                )}
                {activeStep < 3 && (
                  <Button color="primary" onClick={handleNext}>
                    Next
                  </Button>
                )}
                {activeStep === 3 && (
                  <Button color="success" onClick={handleSubmit}>
                    Submit
                  </Button>
                )}
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );

}

export default ConfigCodeMaster;
