import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Progress,
} from "reactstrap";
import axios from "axios";
import  { APIURL } from './Apiurl.js';
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

function TenantOrganizationMaster() {
    const [activeStep, setActiveStep] = useState(1);
    const [checked, setChecked] = useState(false);
    const [progress, setProgress] = useState(0);
    const [udpedit, setudpedit] = useState({
        orgnodeid: "",
        orgnodename: "",
        orgshortname: "",
        orgcode: "",
        description: "",
        orgtype: "",
        orgcodeid: "",
        orgcategory: "",
        orgprocesscat: "",
        countryid: "",
        stateid: "",
        cityid: "",
        parentorgid: "",
        costcentercode: "",
        orgnodeleader: "",
        plant: "",
        createdby: "",
        modifiedby: "",
        modificationreason: "",
    
      });
    
      const { id } = useParams();
      const navigate = useNavigate();
    
      useEffect(() => {
        axios
          .get(`${APIURL}/api/tom/${id}`)
          .then((response) => {
            setudpedit((prevState) => ({
              ...prevState,
              orgnodeid: response.data.org_node_id,
              orgnodename: response.data.org_node_name,
              orgshortname: response.data.org_short_name,
              orgcode: response.data.org_code, 
              description: response.data.description,
              orgtype: response.data.org_type,
              orgcodeid: response.data.org_code_id,
              orgcategory: response.data.org_category,
              orgprocesscat: response.data.prod_process_category,
              countryid: response.data.country_id,
              stateid: response.data.state_id,
              cityid: response.data.city_id,
              parentorgid: response.data.parent_org_id,
              costcentercode: response.data.cost_center_code, 
              orgnodeleader: response.data.org_node_leader,
              plant: response.data.plant,
              createdby: response.data.created_by,
              modifiedby: response.data.modified_by,
              modificationreason: response.data.modification_reason,
            }));
           
          })
          .catch((error) => {
            console.error(error);
          });
      }, [id]);
    
      const handleSubmit = (e) => {
        e.preventDefault();
    
        // Check if the machine name is provided
       
        axios
          .post(`${APIURL}/api/tom/${id}`, udpedit)
          .then((response) => {
            console.log(response);
            navigate(`/tom/${id}`);
            if (response.status === 201) {
              window.location.href = "/admin/tenant-organization-master/tom-all";
            } else {
              // Reset the form or show a success message
            }
          })
          .catch((error) => {
            console.error(error);
          });
      };
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setudpedit((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      };
  
    const handleNext = () => {
      if (activeStep === 1 && progress === 0) {
        setProgress(50);
      } else if (activeStep === 2 && progress === 50) {
        setProgress(100);
      }
      setActiveStep(activeStep + 1);
    };
  
    const handlePrevious = () => {
      setActiveStep(activeStep - 1);
    };
  
  
    const renderStepContent = () => {
      switch (activeStep) {
        case 1:
          return (
            <>
               
               <Row>
            <Col className="pr-md-1" md="6">
              <FormGroup>
                <label>Organization Node Name</label>
                <Input
                  placeholder="Organization Node Name"
                  type="text"
                  name="orgnodename"
                  value={udpedit.orgnodename}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col className="pl-md-1" md="6">
              <FormGroup>
                <label>Organization Short Name</label>
                <Input
                  placeholder="Organization Short Name"
                  type="text"
                  name="orgshortname"
                  value={udpedit.orgshortname}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="pr-md-1" md="6">
              <FormGroup>
                <label>Organization Code</label>
                <Input
                  placeholder="Organization Code"
                  type="text"
                  name="orgcode"
                  value={udpedit.orgcode}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col className="pl-md-1" md="6">
              <FormGroup>
                <label>Description</label>
                <Input
                  placeholder="Description"
                  type="text"
                  name="description"
                  value={udpedit.description}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="pr-md-1" md="6">
              <FormGroup>
                <label>Organization Type</label>
                <Input
                  placeholder="Organization Type"
                  type="text"
                  name="orgtype"
                  value={udpedit.orgtype}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col className="pl-md-1" md="6">
              <FormGroup>
                <label>Organization Code ID</label>
                <Input
                  placeholder="Organization Code ID"
                  type="text"
                  name="orgcodeid"
                  value={udpedit.orgcodeid}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
                <Row>
                    <Col className="pr-md-1" md="6">
                        <FormGroup>
                            <label>Organization Category</label>
                            <Input
                                placeholder="Organization Category"
                                type="text"
                                name="orgcategory"
                                value={udpedit.orgcategory}
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                        <FormGroup>
                            <label>Production Process Category</label>
                            <Input
                                placeholder="Production Process Category"
                                type="text"
                                name="orgprocesscat"
                                value={udpedit.orgprocesscat}
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </>
          );
        case 2:
          return (
            <>
            <Row>
                <Col className="pr-md-1" md="6">
                    <FormGroup>
                        <label>Country ID</label>
                        <Input
                            placeholder="Country ID"
                            type="text"
                            name="countryid"
                            value={udpedit.countryid}
                            onChange={handleChange}
                        />
                    </FormGroup>
                </Col>
                <Col className="pl-md-1" md="6">
                    <FormGroup>
                        <label>State ID</label>
                        <Input
                            placeholder="State ID"
                            type="text"
                            name="stateid"
                            value={udpedit.stateid}
                            onChange={handleChange}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col className="pr-md-1" md="6">
                    <FormGroup>
                        <label>City ID</label>
                        <Input
                            placeholder="City ID"
                            type="text"
                            name="cityid"
                            value={udpedit.cityid}
                            onChange={handleChange}
                        />
                    </FormGroup>
                </Col>
                <Col className="pl-md-1" md="6">
                    <FormGroup>
                        <label>Parent Org ID</label>
                        <Input
                            placeholder="Parent Org ID"
                            type="text"
                            name="parentorgid"
                            value={udpedit.parentorgid}
                            onChange={handleChange}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col className="pr-md-1" md="6">
                    <FormGroup>
                        <label>Cost Center Code</label>
                        <Input
                            placeholder="Cost Center Code"
                            type="text"
                            name="costcentercode"
                            value={udpedit.costcentercode}
                            onChange={handleChange}
                        />
                    </FormGroup>
                </Col>
                <Col className="pl-md-1" md="6">
                    <FormGroup className="check-box">
                    <label>Is Root Node</label>
                    <Input
                        className="check-box1"
                        placeholder="Is Root Node"
                        type="checkbox"
                        checked={checked}
                        onChange={handleChange}
                    />
                    </FormGroup>
                </Col>
            </Row>
            
            </>
          );
        case 3:
          return (
            <>
        <Row>
                <Col className="pr-md-1" md="6">
                    <FormGroup>
                        <label>Organization Node Leader</label>
                        <Input
                            placeholder="Organization Node Leader"
                            type="text"
                            name="orgnodeleader"
                            value={udpedit.orgnodeleader}
                            onChange={handleChange}
                        />
                    </FormGroup>
                </Col>
                <Col className="pl-md-1" md="6">
                    <FormGroup>
                        <label>Plant/BUID</label>
                        <Input
                            placeholder="Plant/BUID"
                            type="text"
                            name="plant"
                            value={udpedit.plant}
                            onChange={handleChange}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
               
                <Col className="pl-md-1" md="6">
                    <FormGroup>
                        <label>Created By</label>
                        <Input
                            placeholder="Created By"
                            type="text"
                            name="createdby"
                            value={udpedit.createdby}
                            onChange={handleChange}
                        />
                    </FormGroup>
                </Col>
           
                <Col className="pl-md-1" md="6">
                    <FormGroup>
                        <label>Modified By</label>
                        <Input
                            placeholder="Modified By"
                            type="text"
                            name="modifiedby"
                            value={udpedit.modifiedby}
                            onChange={handleChange}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col className="pl-md-1" md="6">
                        <FormGroup>
                            <label>Org Node ID</label>
                            <Input
                                placeholder="Org Node ID"
                                type="text"
                                name="orgnodeid"
                                value={udpedit.orgnodeid}
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Col>
                <Col className="pr-md-1" md="6">
                    <FormGroup>
                        <label>Modification Reason</label>
                        <Input
                            placeholder="Modification Reason"
                            type="text"
                            name="modificationreason"
                            value={udpedit.modificationreason}
                            onChange={handleChange}
                        />
                    </FormGroup>
                </Col>
            </Row>
          
            </>
          );
        default:
          return null;
      }
    };
    const calculateProgress = () => {
      return Math.round(((activeStep - 1) / 2) * 100); // Update the denominator to match the total number of steps minus one
    };
    
  
    const renderProgressBarDestinations = () => {
      const step1Class = activeStep >= 1 ? "progress-destination-active" : "";
      const step2Class = activeStep >= 2 ? "progress-destination-active" : "";
      const step3Class = activeStep >= 3 ? "progress-destination-active" : "";
      const step4Class = activeStep >= 4 ? "progress-destination-active" : "";
    
      return (
        <div className="progress-destinations">
          <div className={`progress-destination ${step1Class}`}>
            <span>1</span>
          </div>
          <div className={`progress-destination ${step2Class}`}>
            <span>2</span>
          </div>
          <div className={`progress-destination ${step3Class}`}>
            <span>3</span>
          </div>
          <div className={`progress-destination ${step4Class}`}>
            <span>4</span>
          </div>
        </div>
      );
    };

    return (
        <div className="content">
          <Row>
            <Col md="8">
              <div className="progress-container">
                {renderProgressBarDestinations()}
                <Progress
                  max="100"
                  value={calculateProgress()}
                  className="progress-bar-sm progress-bar-striped"
                />
              </div>
            </Col>
          </Row>
          <Row className="progress-row">
            <Col md="8">
              <Card>
                <CardHeader>
                  <h5 className="title">Add New Machine</h5>
                </CardHeader>
                <CardBody>
                  <Form>{renderStepContent()}</Form>
                </CardBody>
                <CardFooter>
                  <div className="text-right">
                    {activeStep > 1 && (
                      <Button color="primary" onClick={handlePrevious}>
                        Previous
                      </Button>
                    )}
                    {activeStep < 3 && (
                      <Button color="primary" onClick={handleNext}>
                        Next
                      </Button>
                    )}
                    {activeStep === 3 && (
                      <Button color="success" onClick={handleSubmit}>
                        Submit
                      </Button>
                    )}
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      );
}

export default TenantOrganizationMaster;
