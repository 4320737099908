import React, { useState, useEffect } from "react";
import { Card, Row, CardHeader, CardBody } from "reactstrap";
import redirectToLoginIfTokenMissing from "views/TokenUtility";
import axios from "axios";
import { APIURL } from "views/Apiurl";
import ComboBarLineChart from "assets/charts/ComboBarLineChart";
import SmoothLineChart from "assets/charts/SmoothLineChart";
import ReactSpeedometer from "react-d3-speedometer";
import { QueryApi } from "QueryApi";

function FocusWorkForceAvailability(props) {
  const [activeTab, setActiveTab] = useState("1");
  const storedTenantId = sessionStorage.getItem("selectedTenantId");

  useEffect(() => {
    redirectToLoginIfTokenMissing();
  }, []);
  const [org, setorg] = useState("");
  const [unit, setunit] = useState("");
  const [dept, setdept] = useState("");
  const [depts, setdepts] = useState([]);
  const [machine, setMachine] = useState("");
  const [machines, setMachines] = useState([]);
  const [plants, setplants] = useState([]);
  const [orgs, setorgs] = useState([]);
  const [otherData, setOtherData] = useState([]);
  const [selectedOrgData, setSelectedOrgData] = useState(null);

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const currDay = day < 10 ? `0${day}` : day;
  const currMonth = month < 10 ? `0${month}` : month;
  let currentDate = JSON.stringify(`${year}-${currMonth}-${currDay}`);

  const allValues = [
    { xAxisData: "Jan", lineData: 2.3, barData: 162000 },
    { xAxisData: "Mar", lineData: 6.3, barData: 302000 },
    { xAxisData: "May", lineData: 16.2, barData: 800000 },
    { xAxisData: "Jul", lineData: 22.8, barData: 1254000 },
    { xAxisData: "Sep", lineData: 14.5, barData: 950000 },
    { xAxisData: "Nov", lineData: 8.9, barData: 200000 },
  ];
  const title = "";
  const barChartName = "Month data";
  const lineChartName = "Average data";

  const xAxisData = ["JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  const dataset = [
    {
      label: "data 1",
      data: [80, 100, 70, 80, 120, 80],
      borderColor: "#5090DC",
    },
    {
      label: "data 2",
      data: [100, 70, 50, 120, 50, 100],
      borderColor: "#5090DC",
    },
  ];

  const token = sessionStorage.getItem("token");
  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const tenantId =
    decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];

  useEffect(() => {
    fetchplant();
  }, [org]);
  const fetchorg = async (logintenantId) => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery($logintenantId: Int!) {
          tnt_organization_structure(
            where: {
              parent_organization_id: { _eq: 0 },
              tenantid: { _eq: $logintenantId }
            }
          ) {
            organization_structure_id
            organization_node_name
          }
        }
      `,
          variables: {
            logintenantId: parseInt(logintenantId),
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );
      const fetchedOrgs = response.data.data.tnt_organization_structure;
      setorgs(fetchedOrgs);

      const parentIds = fetchedOrgs.map((org) => org.organization_structure_id);

      const childResponse = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery($parentIds: [Int!]) {
          tnt_organization_structure(
            where: {
              parent_organization_id: { _in: $parentIds }
            }
          ) {
            organization_structure_id
            organization_node_name
          }
        }
      `,
          variables: {
            parentIds: parentIds,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      const childOrgs = childResponse.data.data.tnt_organization_structure;

      const updatedOtherData = otherData.map((row) => {
        const matchingOrg = childOrgs.find(
          (org) => org.organization_structure_id === row.parent_organization_id
        );

        if (matchingOrg) {
          return {
            ...row,
            parent_organization_id: matchingOrg.organization_structure_id,
          };
        }

        return row;
      });

      setOtherData(childOrgs);
    } catch (error) {}
  };
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["sub"];
    let logintenantId = null;

    if (roleId == 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
    }

    fetchorg(logintenantId);
  }, []);
  const fetchplant = async () => {
    if (org) {
      // Only fetch if org is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
          query MyQuery($p: Int!) {
            tnt_organization_structure(where: {parent_organization_id: {_eq: $p}}) {
              organization_structure_id
              organization_node_name
            }
          }
        `,
            variables: {
              p: org,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        setplants(response.data.data.tnt_organization_structure);
      } catch (error) {}
    }
  };
  useEffect(() => {
    fetchdept();
  }, [unit]);
  const fetchdept = async () => {
    if (unit) {
      // Only fetch if org is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
          query MyQuery($d: Int!) {
            tnt_organization_structure(where: {parent_organization_id: {_eq: $d}}) {
              organization_structure_id
              organization_node_name
            }
          }
        `,
            variables: {
              d: unit,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        setdepts(response.data.data.tnt_organization_structure);
      } catch (error) {}
    }
  };

  useEffect(() => {
    fetchMachine();
  }, [dept]);
  const fetchMachine = async () => {
    if (dept) {
      // Only fetch if dept is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
          query MyQuery($m: Int!) {
           tnt_org_machine_assignment(where: {dept: {organization_structure_id: {_eq: $m}}} ) {
              machine_name
            }
          }
        `,
            variables: {
              m: dept,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        setMachines(response.data.data.tnt_org_machine_assignment || []);
      } catch (error) {}
    }
  };

  const handleOrgSelect = async (selectedOrgId) => {
    if (selectedOrgId) {
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
          query GetCostCenter($orgId: Int!) {
            tnt_organization_structure(where: { organization_structure_id: { _eq: $orgId } }) {
              cost_centre_code
            }
          }
        `,
            variables: {
              orgId: selectedOrgId,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );

        if (
          response.data &&
          response.data.data &&
          response.data.data.tnt_organization_structure &&
          response.data.data.tnt_organization_structure.length > 0
        ) {
          setSelectedOrgData(response.data.data.tnt_organization_structure[0]);
        } else {
          setSelectedOrgData(null);
        }
      } catch (error) {}
    } else {
      setSelectedOrgData(null);
    }

    setorg(selectedOrgId);
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const [bigChartData, setbigChartData] = React.useState("data1");
  const setBgChartData = (name) => {
    setbigChartData(name);
  };

  /////////////////////////////workforce availability gauge ////////////////////////////////////
  const [WorkforceAvailabilityData, setWorkforceAvailabilityData] = useState(0);
  useEffect(() => {
    WorkforceAvailability();
  }, []);
  const WorkforceAvailability = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            wdm_workforce_availability_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                  available_workforce
                }
              }
            }
          }
    `);
      if (
        data === undefined ||
        data.wdm_workforce_availability_daily_aggregate.aggregate.avg
          .available_workforce === null
      ) {
        setWorkforceAvailabilityData(0);
      } else {
        setWorkforceAvailabilityData(
          data.wdm_workforce_availability_daily_aggregate.aggregate.avg.available_workforce.toFixed(
            1
          )
        );
      }
    } catch (error) {
      setWorkforceAvailabilityData("No Data");
    }
  };
  /////////////////////////Avg. Production Achievement/////////////////////////////
  const [avgProductionAchivment, setAvgProductionAchivment] = useState(0);

  useEffect(() => {
    AverageProduction();
  }, []);
  const AverageProduction = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                  total_parts_produced
                }
              }
            }
          }
    `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.avg.total_parts_produced === null
      ) {
        setAvgProductionAchivment(0);
      } else {
        setAvgProductionAchivment(
          data.dm_daily_aggregate.aggregate.avg.total_parts_produced.toFixed(1)
        );
      }
    } catch (error) {
      setAvgProductionAchivment("No Data");
    }
  };

  return (
    <>
      <div className="content">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            height: "28rem",
            marginBottom: "2rem",
          }}
        >
          <div style={{ width: "39%" }}>
            <Card style={{ height: "100%" }}>
              <CardHeader style={{ margin: "0", padding: "0" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "1rem",
                  }}
                >
                  <h5 className="card-category">
                    Production out of workforce availability
                  </h5>
                </div>
              </CardHeader>
              <CardBody style={{ margin: "0", padding: "0" }}>
                <div style={{ height: "100%", width: "100%" }}>
                  <ComboBarLineChart
                    data={allValues}
                    title={title}
                    barChartName={barChartName}
                    lineChartName={lineChartName}
                    BackgroundColor={"#27293D"}
                  />
                </div>
              </CardBody>
            </Card>
          </div>
          <div
            style={{
              width: "21%",
              display: "flex",
              flexDirection: "column",
              rowGap: "0.5rem",
            }}
          >
            <div
              style={{
                backgroundColor: "#27293D",
                height: "53%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>Workforce Availability</p>
              <ReactSpeedometer
                value={WorkforceAvailabilityData}
                minValue={0}
                maxValue={100}
                width={250}
                height={160}
                needleColor="yellow"
                startColor="green"
                segments={5}
                endColor="orange"
                textColor="white"
                ringWidth={20}
                currentValueText="${value} %"
              />
            </div>
            <div
              style={{
                backgroundColor: "#27293D",
                height: "54%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>Avg. Production Achievement</p>
              <ReactSpeedometer
                value={42}
                minValue={0}
                maxValue={100}
                width={250}
                height={160}
                needleColor="yellow"
                startColor="green"
                segments={5}
                endColor="orange"
                textColor="white"
                ringWidth={20}
                currentValueText="${value} %"
              />
            </div>
          </div>
          <div style={{ width: "39%" }}>
            <Card style={{ height: "100%" }}>
              <CardHeader style={{ margin: "0", padding: "0" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "1rem",
                  }}
                >
                  <h5 className="card-category">Workforce Availability</h5>
                </div>
              </CardHeader>
              <CardBody style={{ margin: "0", padding: "0" }}>
                <div style={{ height: "100%", width: "100%" }}>
                  {/* {
                    loading
                        ? <p>Loading chart...</p>
                        : <SmoothLineChart  key={Math.random()} xAxisData={xAxisData} dataset={dataset} height="19rem" width="30rem" />
                } */}
                  <SmoothLineChart
                    key={Math.random()}
                    xAxisData={xAxisData}
                    dataset={dataset}
                    height="23rem"
                    width="30rem"
                  />
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
        <Row></Row>
      </div>
    </>
  );
}

export default FocusWorkForceAvailability;
