import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Progress,
} from "reactstrap";
import axios from "axios";
import { APIURL } from "./Apiurl.js";
import { toast } from "react-toastify";

function EmployeeAdd() {
  const [activeStep, setActiveStep] = useState(1);
  const [checked, setChecked] = useState(false);
  const [progress, setProgress] = useState(0);
  const [tenantClientName, setTenantClientName] = useState("");
  const [holdingCompanyName, setHoldingCompanyName] = useState("");
  const [holdingCompanyID, setHoldingCompanyID] = useState("");
  const [country, setCountry] = useState("");
  const [contact, setcontact] = useState("");
  const [password, setpassword] = useState("");
  const [location, setLocation] = useState("");
  const [address, setAddress] = useState("");
  const [industrySegment, setIndustrySegment] = useState("");
  const [machineType1, setMachineType1] = useState("");
  const [noOfMachinesType1, setNoOfMachinesType1] = useState(0);
  const [machineType2, setMachineType2] = useState(null);
  const [noOfMachinesType2, setNoOfMachinesType2] = useState(0);

  const [machineType3, setMachineType3] = useState(null);
  const [noOfMachinesType3, setNoOfMachinesType3] = useState(0);
  const [machineType4, setMachineType4] = useState(null);
  const [noOfMachinesType4, setNoOfMachinesType4] = useState(0);
  const [machineType5, setMachineType5] = useState(null);
  const [noOfMachinesType5, setNoOfMachinesType5] = useState(0);
  const [totalMachines, setTotalMachines] = useState(0);
  const [accountManager, setAccountManager] = useState("");
  const [accountManagerEmail, setAccountManagerEmail] = useState("");
  const [promoterOrDirector, setPromoterOrDirector] = useState("");
  const [directorEmail, setDirectorEmail] = useState("");
  const [financeSpocEmail, setFinanceSpocEmail] = useState("");

  const [purchaseSpocEmail, setPurchaseSpocEmail] = useState("");
  const [billingMethod, setBillingMethod] = useState("");
  const [invoiceFrequency, setInvoiceFrequency] = useState("");
  const [tenantSubdomain, setTenantSubdomain] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [machines, setmachines] = useState([]);
  const [countries, setcountries] = useState([]);
  const [billmethods, setbillmethods] = useState([]);
  const [invoices, setinvoices] = useState([]);

  useEffect(() => {
    fetchmachinetype();
    fetchcountry();
    fetchBillingMethod();
    fetchInvoicefrequency();
  }, []);
  useEffect(() => {
    // Calculate the sum whenever any of the machine counts change
    const sum =
      noOfMachinesType1 +
      noOfMachinesType2 +
      noOfMachinesType3 +
      noOfMachinesType4 +
      noOfMachinesType5;
    setTotalMachines(sum);
  }, [
    noOfMachinesType1,
    noOfMachinesType2,
    noOfMachinesType3,
    noOfMachinesType4,
    noOfMachinesType5,
  ]);
  const token = sessionStorage.getItem("token");

  const fetchmachinetype = async () => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery {
          ump_master(where: { type: { _neq: "Subtype" } }) {
            machine_profile
            type
            ump_id
          }
        }        
        `,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      setmachines(response.data.data.ump_master);
      console.log(response.data.data.ump_master);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchcountry = async () => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery {
          config_code_master(where: {code_type: {_eq: "Country"}}) {
            code_id
            code_type
            code
          }
        }
        `,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      setcountries(response.data.data.config_code_master);
      console.log(response.data.data.config_code_master);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchBillingMethod = async () => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery {
          config_code_master(where: {code_type: {_eq: "BillingMethod"}}) {
            code_id
            code_type
            code
          }
        }
        `,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      setbillmethods(response.data.data.config_code_master);
      console.log(response.data.data.config_code_master);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchInvoicefrequency = async () => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery {
          config_code_master(where: {code_type: {_eq: "InvoiceFrequency"}}) {
            code_id
            code_type
            code
          }
        }
        `,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      setinvoices(response.data.data.config_code_master);
      console.log(response.data.data.config_code_master);
    } catch (error) {
      console.error(error);
    }
  };
  const handleNext = () => {
    if (activeStep === 1 && progress === 0) {
      setProgress(50);
    } else if (activeStep === 2 && progress === 50) {
      setProgress(100);
    }
    setActiveStep(activeStep + 1);
  };

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    // Create an object with the form data for the first table
    const formDataTenantAdministration = {
      tenant_client_name: tenantClientName,
      holding_company_name: holdingCompanyName,
      holding_company_id: holdingCompanyID,
      country: country,
      location: location,
      address: address,
      industry_segment: industrySegment,
      machine_type1: machineType1,
      no_of_machine_type1: noOfMachinesType1,
      machine_type2: machineType2,
      no_of_machine_type2: noOfMachinesType2,
      machine_type3: machineType3,
      no_of_machine_type3: noOfMachinesType3,
      machine_type4: machineType4,
      no_of_machine_type4: noOfMachinesType4,
      machine_type5: machineType5,
      no_of_machine_type5: noOfMachinesType5,
      total_machines: totalMachines,
      account_manager: accountManager,
      account_manager_email_id: accountManager,
      director: promoterOrDirector,
      director_email: directorEmail,
      finance_spoc_email: financeSpocEmail,
      purchase_spoc_email: purchaseSpocEmail,
      billing_method: billingMethod,
      invoice_frequency: invoiceFrequency,
      tenant_subdomain: tenantSubdomain,
      start_date_time: startDate,
      // end_date_time: endDate,
      org_type: 22,
      is_active: "1",
    };

    // Insert data into the first table
    axios
      .post(
        APIURL,
        {
          query: `
        mutation InsertTenantAdministration($formData: tenant_administration_insert_input!) {
          insert_tenant_administration(objects: [$formData]) {
            affected_rows
            returning {
              tenantid
            }
          }
        }
      `,
          variables: {
            formData: formDataTenantAdministration,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      )
      .then((response) => {
        if (
          response.data.data &&
          response.data.data.insert_tenant_administration.affected_rows > 0
        ) {
          // Data inserted into the first table successfully
          const insertedTenantId =
            response.data.data.insert_tenant_administration.returning[0]
              .tenantid;

          // Create an object with the form data for the second table
          const formDataUsers = {
            name: accountManager,
            email: accountManagerEmail,
            contact: contact, // You might need to adjust this based on your data structure
            tenantid: insertedTenantId, // Use the tenant ID you obtained from the first table insertion
            password: password, // You can set a default password here or generate one securely
            role_id: 2,
          };
          const formDataOrganizationStructure = {
            tenantid: insertedTenantId,
            organization_node_name: holdingCompanyName,
            parent_organization_id: 0,
            is_active: "1",
            country_id: country,
          };

          // Insert data into the second table
          axios
            .post(
              APIURL,
              {
                query: `
            mutation InsertOrganizationStructure($formData: tnt_organization_structure_insert_input!) {
              insert_tnt_organization_structure(objects: [$formData]) {
                affected_rows
              }
            }
          `,
                variables: {
                  formData: formDataOrganizationStructure,
                },
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
                },
              }
            )
            .then((response) => {
              if (
                response.data.data &&
                response.data.data.insert_tnt_organization_structure
                  .affected_rows > 0
              ) {
                // Data inserted into the second table successfully
                toast.success("Data inserted into all tables successfully!");
                console.log("inserted");
                setTenantClientName("");
                setHoldingCompanyName("");
                setHoldingCompanyID("");
                setCountry("");
                setLocation("");
                setAddress("");
                setIndustrySegment("");
                setMachineType1("");
                setNoOfMachinesType1(0);
                setMachineType2(null);
                setNoOfMachinesType2(0);
                setMachineType3(null);
                setNoOfMachinesType3(0);
                setMachineType4(null);
                setNoOfMachinesType4(0);
                setMachineType5(null);
                setNoOfMachinesType5(0);
                setTotalMachines(0);
                setAccountManager("");
                setAccountManagerEmail("");
                setPromoterOrDirector("");
                setDirectorEmail("");
                setFinanceSpocEmail("");
                setPurchaseSpocEmail("");
                setBillingMethod("");
                setInvoiceFrequency("");
                setTenantSubdomain("");
                setStartDate("");
                setEndDate("");
                setcontact("");
                setpassword("");
                setProgress(0);
                setActiveStep(1);
                // ... (resetting form values and other actions)
              } else {
                toast.error("Failed to insert into the second table.");
              }
            })
            .catch((error) => {
              console.error(error);
              toast.error(
                "An error occurred while inserting into the second table."
              );
            });

          axios
            .post(
              APIURL,
              {
                query: `
            mutation InsertUsers($formData: users_insert_input!) {
              insert_users(objects: [$formData]) {
                affected_rows
              }
            }
          `,
                variables: {
                  formData: formDataUsers,
                },
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
                },
              }
            )
            .then((response) => {
              if (
                response.data.data &&
                response.data.data.insert_users.affected_rows > 0
              ) {
                // Data inserted into the users table successfully
                // This is where you might handle additional steps or actions
              } else {
                toast.error("Failed to insert into the users table.");
              }
            })
            .catch((error) => {
              console.error(error);
              toast.error(
                "An error occurred while inserting into the users table."
              );
            });
        } else {
          toast.error("Failed to insert into the first table.");
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("An error occurred while inserting into the first table.");
      });
  };
  const selectedValuesArray = [
    machineType1,
    machineType2,
    machineType3,
    machineType4,
  ];
  const filteredMachinesForType2 = machines.filter(
    (mach) => !selectedValuesArray.includes(mach.ump_id.toString())
  );
  const handleMachineType2Change = (e) => {
    console.log("Selected value:", e.target.value);
    setMachineType2(e.target.value);
  };
  const renderStepContent = () => {
    switch (activeStep) {
      case 1:
        return (
          <>
            <Row>
              <Col className="pl-md-1" md="4">
                <FormGroup>
                  <label>Tenant ClientName</label>
                  <Input
                    placeholder="Tenant ClientName"
                    type="text"
                    value={tenantClientName}
                    onChange={(e) => setTenantClientName(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="pr-md-1" md="4">
                <FormGroup>
                  <label>Holding Company Name</label>
                  <Input
                    placeholder="Holding Company Name"
                    type="text"
                    value={holdingCompanyName}
                    onChange={(e) => setHoldingCompanyName(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="pl-md-1" md="4">
                <FormGroup>
                  <label>Holding Company ID</label>
                  <Input
                    placeholder="Holding Company ID"
                    type="text"
                    value={holdingCompanyID}
                    onChange={(e) => setHoldingCompanyID(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>Country</label>
                  <Input
                    type="select"
                    id="orgtype"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    <option value="">Select Country</option>
                    {countries.map((cnt) => (
                      <option key={cnt.code_id} value={cnt.code_id}>
                        {cnt.code}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col className="pl-md-1" md="6">
                <FormGroup>
                  <label>Location</label>
                  <Input
                    placeholder="Location"
                    type="text"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>Address</label>
                  <Input
                    placeholder="Address"
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="pl-md-1" md="6">
                <FormGroup>
                  <label>Industry Segment</label>
                  <Input
                    placeholder="Industry Segment"
                    type="text"
                    value={industrySegment}
                    onChange={(e) => setIndustrySegment(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>Machine Type 1</label>
                  <Input
                    type="select"
                    id="machineType"
                    value={machineType1}
                    onChange={(e) => setMachineType1(e.target.value)}
                  >
                    <option value="">Select Machine Type 1</option>
                    {machines.map((mach) => (
                      <option key={mach.ump_id} value={mach.ump_id}>
                        {mach.machine_profile}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col className="pl-md-1" md="6">
                <FormGroup>
                  <label>No of Machines Type 1</label>
                  <Input
                    placeholder="No of Machines Type 1"
                    type="number"
                    value={noOfMachinesType1}
                    onChange={(e) =>
                      setNoOfMachinesType1(parseInt(e.target.value))
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>Machine Type 2</label>
                  <Input
                    type="select"
                    id="machineType2"
                    value={machineType2}
                    onChange={handleMachineType2Change}
                  >
                    <option value="">Select Machine Type 2</option>
                    {filteredMachinesForType2.map((mach) => (
                      <option key={mach.ump_id} value={mach.ump_id}>
                        {mach.machine_profile}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col className="pl-md-1" md="6">
                <FormGroup>
                  <label>No of Machines Type 2</label>
                  <Input
                    placeholder="No of Machines Type 2"
                    type="number"
                    value={noOfMachinesType2}
                    onChange={(e) =>
                      setNoOfMachinesType2(parseInt(e.target.value))
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        );
      case 2:
        return (
          <>
            <Row>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>Machine Type 3</label>
                  <Input
                    type="select"
                    id="machineType"
                    value={machineType3}
                    onChange={(e) => setMachineType3(e.target.value)}
                  >
                    <option value="">Select Machine Type 3</option>
                    {machines.map((mach) => (
                      <option key={mach.ump_id} value={mach.ump_id}>
                        {mach.machine_profile}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col className="pl-md-1" md="6">
                <FormGroup>
                  <label>No of Machines Type 3</label>
                  <Input
                    placeholder="No of Machines Type 3"
                    type="number"
                    value={noOfMachinesType3}
                    onChange={(e) =>
                      setNoOfMachinesType3(parseInt(e.target.value))
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>Machine Type 4</label>
                  <Input
                    type="select"
                    id="machineType"
                    value={machineType4}
                    onChange={(e) => setMachineType4(e.target.value)}
                  >
                    <option value="">Select Machine Type 4</option>
                    {machines.map((mach) => (
                      <option key={mach.ump_id} value={mach.ump_id}>
                        {mach.machine_profile}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col className="pl-md-1" md="6">
                <FormGroup>
                  <label>No of Machines Type 4</label>
                  <Input
                    placeholder="No of Machines Type 4"
                    type="number"
                    value={noOfMachinesType4}
                    onChange={(e) =>
                      setNoOfMachinesType4(parseInt(e.target.value))
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>Machine Type 5</label>
                  <Input
                    type="select"
                    id="machineType"
                    value={machineType5}
                    onChange={(e) => setMachineType5(e.target.value)}
                  >
                    <option value="">Select Machine Type 5</option>
                    <option value="">Other</option>
                    {machines.map((mach) => (
                      <option key={mach.ump_id} value={mach.ump_id}>
                        {mach.machine_profile}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col className="pl-md-1" md="6">
                <FormGroup>
                  <label>No of Machines Type 5</label>
                  <Input
                    placeholder="No of Machines Type 5"
                    type="number"
                    value={noOfMachinesType5}
                    onChange={(e) =>
                      setNoOfMachinesType5(parseInt(e.target.value))
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>Total Machines</label>
                  <Input
                    placeholder="Total Machines"
                    type="number"
                    value={totalMachines}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col className="pl-md-1" md="6">
                <FormGroup>
                  <label>Account Manager</label>
                  <Input
                    placeholder="Account Manager"
                    type="text"
                    value={accountManager}
                    onChange={(e) => setAccountManager(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>Account Manager Email ID</label>
                  <Input
                    placeholder="Account Manager Email ID"
                    type="email"
                    value={accountManagerEmail}
                    onChange={(e) => setAccountManagerEmail(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="pl-md-1" md="6">
                <FormGroup>
                  <label>Promoter or Director</label>
                  <Input
                    placeholder="Promoter or Director"
                    type="text"
                    value={promoterOrDirector}
                    onChange={(e) => setPromoterOrDirector(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>Director Email</label>
                  <Input
                    placeholder="Director Email"
                    type="email"
                    value={directorEmail}
                    onChange={(e) => setDirectorEmail(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="pl-md-1" md="6">
                <FormGroup>
                  <label>Finance SPOC Email ID</label>
                  <Input
                    placeholder="Finance SPOC Email ID"
                    type="email"
                    value={financeSpocEmail}
                    onChange={(e) => setFinanceSpocEmail(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        );
      case 3:
        return (
          <>
            <Row>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>Purchase SPOC Email ID</label>
                  <Input
                    placeholder="Purchase SPOC Email ID"
                    type="email"
                    value={purchaseSpocEmail}
                    onChange={(e) => setPurchaseSpocEmail(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="pl-md-1" md="6">
                <FormGroup>
                  <label>Billing Method</label>
                  <Input
                    type="select"
                    id="orgtype"
                    value={billingMethod}
                    onChange={(e) => setBillingMethod(e.target.value)}
                  >
                    <option value="">Select Billing Method</option>
                    {billmethods.map((bm) => (
                      <option key={bm.code_id} value={bm.code_id}>
                        {bm.code}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>Invoice Frequency</label>
                  <Input
                    type="select"
                    id="orgtype"
                    value={invoiceFrequency}
                    onChange={(e) => setInvoiceFrequency(e.target.value)}
                  >
                    <option value="">Select Invoice Frequency</option>
                    {invoices.map((invoice) => (
                      <option key={invoice.code_id} value={invoice.code_id}>
                        {invoice.code}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col className="pl-md-1" md="6">
                <FormGroup>
                  <label>Tenant Subdomain</label>
                  <Input
                    placeholder="Tenant Subdomain"
                    type="text"
                    value={tenantSubdomain}
                    onChange={(e) => setTenantSubdomain(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label> Account Manager Contact No.</label>
                  <Input
                    placeholder="Enter Contact No."
                    type="text"
                    value={contact}
                    onChange={(e) => setcontact(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="pl-md-1" md="6">
                <FormGroup>
                  <label>Password</label>
                  <Input
                    placeholder="Enter password"
                    type="text"
                    value={password}
                    onChange={(e) => setpassword(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>Start Date</label>
                  <Input
                    placeholder="Start Date"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        );
      default:
        return null;
    }
  };
  const calculateProgress = () => {
    return Math.round(((activeStep - 1) / 3) * 100); // Update the denominator to match the total number of steps minus one
  };

  const renderProgressBarDestinations = () => {
    const step1Class = activeStep >= 1 ? "progress-destination-active" : "";
    const step2Class = activeStep >= 2 ? "progress-destination-active" : "";
    const step3Class = activeStep >= 3 ? "progress-destination-active" : "";
    const step4Class = activeStep >= 4 ? "progress-destination-active" : "";

    return (
      <div className="progress-destinations">
        <div className={`progress-destination ${step1Class}`}>
          <span>1</span>
        </div>
        <div className={`progress-destination ${step2Class}`}>
          <span>2</span>
        </div>
        <div className={`progress-destination ${step3Class}`}>
          <span>3</span>
        </div>
        <div className={`progress-destination ${step4Class}`}>
          <span>4</span>
        </div>
      </div>
    );
  };
  return (
    <div className="content">
      <Row>
        <Col md="8">
          <div className="progress-container">
            {renderProgressBarDestinations()}
            <Progress
              max="100"
              value={calculateProgress()}
              className="progress-bar-sm progress-bar-striped"
            />
          </div>
        </Col>
      </Row>
      <Row className="progress-row">
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Add New Tenant</h5>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleFormSubmit}>{renderStepContent()}</Form>
            </CardBody>
            <CardFooter>
              <div className="text-right">
                {activeStep > 1 && (
                  <Button color="primary" onClick={handlePrevious}>
                    Previous
                  </Button>
                )}
                {activeStep < 3 && (
                  <Button color="primary" onClick={handleNext}>
                    Next
                  </Button>
                )}
                {activeStep === 3 && (
                  <Button color="success" onClick={handleFormSubmit}>
                    Submit
                  </Button>
                )}
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default EmployeeAdd;
