import React from "react";
import { useState } from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Progress,
  } from "reactstrap";
  import axios from "axios";
import  { APIURL } from './Apiurl.js';

function TenantOrganizationMaster() {
    const [activeStep, setActiveStep] = useState(1);
    const [checked, setChecked] = useState(false);
    const [progress, setProgress] = useState(0);
  
    const [orgnodeid, setorgnodeid] = useState("");
    const [orgnodename, setorgnodename] = useState("");
    const [orgshortname, setorgshortname] = useState("");
    const [orgcode, setorgcode] = useState("");
    const [description, setdescription] = useState("");
    const [orgtype, setorgtype] = useState("");
    const [orgcodeid, setorgcodeid] = useState("");
    const [orgcategory, setorgcategory] = useState("");
    const [orgprocesscat, setorgprocesscat] = useState("");
    const [countryid, setcountryid] = useState("");
    const [stateid, setstateid] = useState("");
    const [cityid, setcityid] = useState("");
    const [parentorgid, setparentorgid] = useState("");
    const [costcentercode, setcostcentercode] = useState("");
    const [orgnodeleader, setorgnodeleader] = useState("");
    const [plant, setplant] = useState("");
    const [createdby, setcreatedby] = useState("");
    const [modifiedby, setmodifiedby] = useState("");
    const [modificationreason, setmodificationreason] = useState("");

    const handleChange = (event) => {
        const isChecked = event.target.checked;
        setChecked(isChecked);
      };
  
    const handleNext = () => {
      if (activeStep === 1 && progress === 0) {
        setProgress(50);
      } else if (activeStep === 2 && progress === 50) {
        setProgress(100);
      }
      setActiveStep(activeStep + 1);
    };
  
    const handlePrevious = () => {
      setActiveStep(activeStep - 1);
    };
  
     const handleFormSubmit = (event) => {
    event.preventDefault();
    // Create an object with the form data
    const formData = {
        orgnodeid: orgnodeid,

        orgnodename: orgnodename,
        orgshortname: orgshortname,
        orgcode: orgcode,
        description: description,
        orgtype: orgtype,
        orgcodeid: orgcodeid,
        orgcategory: orgcategory,
        orgprocesscat: orgprocesscat,
        countryid: countryid,
        stateid: stateid,
        cityid:cityid,
        parentorgid: parentorgid,
        costcentercode: costcentercode,
        orgnodeleader: orgnodeleader,
        plant: plant,
        isrootnode: checked ? 1 : 0,
        createdby: createdby,
        modifiedby: modifiedby,
        modificationreason: modificationreason,
     
   
    };
    console.log(formData);    
    // Make a POST request to the Laravel backend
    axios
      .post(`${APIURL}/api/tom/add`, formData)
      .then((response) => {
        console.log(response.data); // Handle the response data as needed
        // Reset the form or show a success message
        if (response.status === 201) {
          // Redirect to admin/machine-all
          window.location.href = "/admin/tenant-organization-master/tom-all";
        } else {
          // Reset the form or show a success message
        }
      })
      .catch((error) => {
        console.error(error); // Handle the error
        // Show an error message
      });
  };
    const renderStepContent = () => {
      switch (activeStep) {
        case 1:
          return (
            <>
               
                <Row>
                    <Col className="pr-md-1" md="6">
                        <FormGroup>
                            <label>Organization Node Name</label>
                            <Input
                                placeholder="Organization Node Name"
                                type="text"
                                value={orgnodename}
                                onChange={(e) => setorgnodename(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                        <FormGroup>
                            <label>Organization Short Name</label>
                            <Input
                                placeholder="Organization Short Name"
                                type="text"
                                value={orgshortname}
                                onChange={(e) => setorgshortname(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-md-1" md="6">
                        <FormGroup>
                            <label>Organization Code (External)</label>
                            <Input
                                placeholder="Organization Code (External)"
                                type="text"
                                value={orgcode}
                                onChange={(e) => setorgcode(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                        <FormGroup>
                            <label>Description</label>
                            <Input
                                placeholder="Description"
                                type="text"
                                value={description}
                                onChange={(e) => setdescription(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-md-1" md="6">
                        <FormGroup>
                            <label>Organization Type</label>
                            <Input
                                placeholder="Organization Type"
                                type="text"
                                value={orgtype}
                                onChange={(e) => setorgtype(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                        <FormGroup>
                            <label>Org Code ID</label>
                            <Input
                                placeholder="Org Code ID"
                                type="text"
                                value={orgcodeid}
                                onChange={(e) => setorgcodeid(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="pr-md-1" md="6">
                        <FormGroup>
                            <label>Organization Category</label>
                            <Input
                                placeholder="Organization Category"
                                type="text"
                                value={orgcategory}
                                onChange={(e) => setorgcategory(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                        <FormGroup>
                            <label>Production Process Category</label>
                            <Input
                                placeholder="Production Process Category"
                                type="text"
                                value={orgprocesscat}
                                onChange={(e) => setorgprocesscat(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </>
          );
        case 2:
          return (
            <>
            <Row>
                <Col className="pr-md-1" md="6">
                    <FormGroup>
                        <label>Country ID</label>
                        <Input
                            placeholder="Country ID"
                            type="text"
                            value={countryid}
                            onChange={(e) => setcountryid(e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col className="pl-md-1" md="6">
                    <FormGroup>
                        <label>State ID</label>
                        <Input
                            placeholder="State ID"
                            type="text"
                            value={stateid}
                            onChange={(e) => setstateid(e.target.value)}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col className="pr-md-1" md="6">
                    <FormGroup>
                        <label>City ID</label>
                        <Input
                            placeholder="City ID"
                            type="text"
                            value={cityid}
                            onChange={(e) => setcityid(e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col className="pl-md-1" md="6">
                    <FormGroup>
                        <label>Parent Org ID</label>
                        <Input
                            placeholder="Parent Org ID"
                            type="text"
                            value={parentorgid}
                            onChange={(e) => setparentorgid(e.target.value)}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col className="pr-md-1" md="6">
                    <FormGroup>
                        <label>Cost Center Code</label>
                        <Input
                            placeholder="Cost Center Code"
                            type="text"
                            value={costcentercode}
                            onChange={(e) => setcostcentercode(e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col className="pl-md-1" md="6">
                    <FormGroup className="check-box">
                    <label>Is Root Node</label>
                    <Input
                        className="check-box1"
                        placeholder="Is Root Node"
                        type="checkbox"
                        checked={checked}
                        onChange={handleChange}
                    />
                    </FormGroup>
                </Col>
            </Row>
            
            </>
          );
        case 3:
          return (
            <>
        <Row>
                <Col className="pr-md-1" md="6">
                    <FormGroup>
                        <label>Organization Node Leader</label>
                        <Input
                            placeholder="Organization Node Leader"
                            type="text"
                            value={orgnodeleader}
                            onChange={(e) => setorgnodeleader(e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col className="pl-md-1" md="6">
                    <FormGroup>
                        <label>Plant/BUID</label>
                        <Input
                            placeholder="Plant/BUID"
                            type="text"
                            value={plant}
                            onChange={(e) => setplant(e.target.value)}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
               
                <Col className="pl-md-1" md="6">
                    <FormGroup>
                        <label>Created By</label>
                        <Input
                            placeholder="Created By"
                            type="text"
                            value={createdby}
                            onChange={(e) => setcreatedby(e.target.value)}
                        />
                    </FormGroup>
                </Col>
           
                <Col className="pl-md-1" md="6">
                    <FormGroup>
                        <label>Modified By</label>
                        <Input
                            placeholder="Modified By"
                            type="text"
                            value={modifiedby}
                            onChange={(e) => setmodifiedby(e.target.value)}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col className="pl-md-1" md="6">
                        <FormGroup>
                            <label>Org Node ID</label>
                            <Input
                                placeholder="Org Node ID"
                                type="text"
                                value={orgnodeid}
                                onChange={(e) => setorgnodeid(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                <Col className="pr-md-1" md="6">
                    <FormGroup>
                        <label>Modification Reason</label>
                        <Input
                            placeholder="Modification Reason"
                            type="text"
                            value={modificationreason}
                            onChange={(e) => setmodificationreason(e.target.value)}
                        />
                    </FormGroup>
                </Col>
            </Row>
          
            </>
          );
        default:
          return null;
      }
    };
    const calculateProgress = () => {
      return Math.round(((activeStep - 1) / 2) * 100); // Update the denominator to match the total number of steps minus one
    };
    
  
    const renderProgressBarDestinations = () => {
      const step1Class = activeStep >= 1 ? "progress-destination-active" : "";
      const step2Class = activeStep >= 2 ? "progress-destination-active" : "";
      const step3Class = activeStep >= 3 ? "progress-destination-active" : "";
      const step4Class = activeStep >= 4 ? "progress-destination-active" : "";
    
      return (
        <div className="progress-destinations">
          <div className={`progress-destination ${step1Class}`}>
            <span>1</span>
          </div>
          <div className={`progress-destination ${step2Class}`}>
            <span>2</span>
          </div>
          <div className={`progress-destination ${step3Class}`}>
            <span>3</span>
          </div>
          <div className={`progress-destination ${step4Class}`}>
            <span>4</span>
          </div>
        </div>
      );
    };

    return (
        <div className="content">
          <Row>
            <Col md="8">
              <div className="progress-container">
                {renderProgressBarDestinations()}
                <Progress
                  max="100"
                  value={calculateProgress()}
                  className="progress-bar-sm progress-bar-striped"
                />
              </div>
            </Col>
          </Row>
          <Row className="progress-row">
            <Col md="8">
              <Card>
                <CardHeader>
                  <h5 className="title">Add New Machine</h5>
                </CardHeader>
                <CardBody>
                  <Form>{renderStepContent()}</Form>
                </CardBody>
                <CardFooter>
                  <div className="text-right">
                    {activeStep > 1 && (
                      <Button color="primary" onClick={handlePrevious}>
                        Previous
                      </Button>
                    )}
                    {activeStep < 3 && (
                      <Button color="primary" onClick={handleNext}>
                        Next
                      </Button>
                    )}
                    {activeStep === 3 && (
                      <Button color="success" onClick={handleFormSubmit}>
                        Submit
                      </Button>
                    )}
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      );
}

export default TenantOrganizationMaster;
