import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "reactstrap";
import { TabContent, TabPane, Nav, NavItem } from "reactstrap";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import redirectToLoginIfTokenMissing from "views/TokenUtility";
import ProductionGrids from "./Grids/ProductionGrids";
import LossesGrids from "./Grids/LossesGrids";
import PowerConsumptionGrids from "./Grids/PowerConsumptionGrids";
import DownTimeGrids from "./Grids/DownTimeGrids";
import WorkforceHome from "views/WorkforceEfficiency";

import DeviationGrid from "./Grids/DeviationGrid";

function JindalMachineInsight(props) {
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const [activeTab, setActiveTab] = useState("1");
  const storedTenantId = sessionStorage.getItem("selectedTenantId");
  useEffect(() => {
    redirectToLoginIfTokenMissing();
  }, []);
  const token = sessionStorage.getItem("token");
  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const tenantId =
    decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="3">
            <Card
              className="production-volume-header"
              style={{
                height: "40px",
                fontSize: "16px",
                fontWeight: "bold",
                color: "white",
                marginTop: "30px",
                justifyContent: "center",
              }}
            >
              <span style={{ paddingLeft: "20px" }}>
                Machine Equipment Analysis
              </span>
            </Card>
          </Col>
          <Col lg="9">
            <Nav
              className="d-flex flex-row"
              tabs
              style={{
                borderBottom: "none",
                marginTop: "30px",
              }}
            >
              <NavItem
                className="flex-grow-1 text-center"
                style={{ margin: "0px 5px" }}
              >
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => toggleTab("1")}
                  style={{
                    display: "block",
                    width: "170px",
                    height: "40px",
                    backgroundColor:
                      activeTab === "1" ? "#BB86FC" : "transparent",
                    color: activeTab === "1" ? "white" : "white",
                    borderRadius: "5px",
                    border: "1px solid #D3D3D3",
                    padding: "10px",
                  }}
                >
                  Production
                </NavLink>
              </NavItem>
              <NavItem
                className="flex-grow-1 text-center"
                style={{ margin: "0px 5px" }}
              >
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => toggleTab("2")}
                  style={{
                    display: "block",
                    width: "170px",
                    height: "40px",
                    backgroundColor:
                      activeTab === "2" ? "#BB86FC" : "transparent",
                    color: activeTab === "2" ? "white" : "white",
                    borderRadius: "5px",
                    border: "1px solid #D3D3D3",
                    padding: "10px",
                  }}
                >
                  Losses
                </NavLink>
              </NavItem>
              <NavItem
                className="flex-grow-1 text-center"
                style={{ margin: "0px 5px" }}
              >
                <NavLink
                  className={classnames({ active: activeTab === "3" })}
                  onClick={() => toggleTab("3")}
                  style={{
                    display: "block",
                    width: "170px",
                    height: "40px",
                    backgroundColor:
                      activeTab === "3" ? "#BB86FC" : "transparent",
                    color: activeTab === "3" ? "white" : "white",
                    borderRadius: "5px",
                    border: "1px solid #D3D3D3",
                    padding: "10px",
                  }}
                >
                  Power Consumption
                </NavLink>
              </NavItem>
              <NavItem
                className="flex-grow-1 text-center"
                style={{ margin: "0px 5px" }}
              >
                <NavLink
                  className={classnames({ active: activeTab === "4" })}
                  onClick={() => toggleTab("4")}
                  style={{
                    display: "block",
                    width: "150px",
                    height: "40px",
                    backgroundColor:
                      activeTab === "4" ? "#BB86FC" : "transparent",
                    color: activeTab === "4" ? "white" : "white",
                    borderRadius: "5px",
                    border: "1px solid #D3D3D3",
                    padding: "10px",
                  }}
                >
                  Downtime
                </NavLink>
              </NavItem>
              <NavItem
                className="flex-grow-1 text-center"
                style={{ margin: "0px 5px" }}
              >
                <NavLink
                  className={classnames({ active: activeTab === "5" })}
                  onClick={() => toggleTab("5")}
                  style={{
                    display: "block",
                    width: "150px",
                    height: "40px",
                    backgroundColor:
                      activeTab === "5" ? "#BB86FC" : "transparent",
                    color: activeTab === "5" ? "white" : "white",
                    borderRadius: "5px",
                    border: "1px solid #D3D3D3",
                    padding: "10px",
                  }}
                >
                  Deviation
                </NavLink>
              </NavItem>
              <NavItem
                className="flex-grow-1 text-center"
                style={{ margin: "0px 5px" }}
              >
                <NavLink
                  className={classnames({ active: activeTab === "6" })}
                  onClick={() => toggleTab("6")}
                  style={{
                    display: "block",
                    width: "150px",
                    height: "40px",
                    backgroundColor:
                      activeTab === "6" ? "#BB86FC" : "transparent",
                    color: activeTab === "6" ? "white" : "white",
                    borderRadius: "5px",
                    border: "1px solid #D3D3D3",
                    padding: "10px",
                  }}
                >
                  WorkForce
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col>
                <ProductionGrids />
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="2">
            <Row>
              <Col>
                <LossesGrids />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col>
                <PowerConsumptionGrids />
              </Col>
            </Row>
          </TabPane>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="4">
              <Row>
                <Col>
                  <DownTimeGrids />
                </Col>
              </Row>
            </TabPane>

            <TabPane tabId="5">
              <Row>
                <Col>
                  <DeviationGrid />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="6">
              <Row>
                <Col>
                  <WorkforceHome />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </TabContent>
      </div>
    </>
  );
}

export default JindalMachineInsight;
