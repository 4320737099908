import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import redirectToLoginIfTokenMissing from "views/TokenUtility";
import { QueryApi } from "QueryApi.js";
import JindalTableComponent from "../JindalTableComponent";

function DeviationGrid() {
  const [activeTab, setActiveTab] = useState("1");
  const storedTenantId = sessionStorage.getItem("selectedTenantId");
  useEffect(() => {
    redirectToLoginIfTokenMissing();
  }, []);
  const [deviation, setDeviation] = useState([]);
  useEffect(() => {
    fetchDataList();
  }, []);
  const fetchDataList = async () => {
    const data = await QueryApi(`
  query MyQuery {
    dm_deviation(order_by: {date: desc}) {
        org {
            organization_node_name
          }
          unit {
            organization_node_name
          }
          dept {
            organization_node_name
          }
          date
          cycletime_deviation
          operating_time_deviation
          oee_deviation
          ooe_deviation
          teep_deviation
          throughput_deviation
          ole_deviation
  }
}
  
  `);
    setDeviation(data?.dm_deviation);
  };

  //////////////////////////////////////////////////////////////////
  const columnDeviationOfTime = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "unit.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "cycletime_deviation",
      text: "Cycle Time Deviation",
      headerStyle: { width: "500px" },
      formatter: (cell) => {
        return cell === null ? "null" : `${(cell / 1000).toFixed(2)} seconds`;
      },
    },
    {
      dataField: "operating_time_deviation",
      text: "Operating Time Deviation",
      headerStyle: { width: "500px" },
      formatter: (cell) => {
        return cell === null ? "null" : `${cell.toFixed(2)} %`;
      },
    },
  ];
  const columnDeviationOfProduction = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "unit.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "oee_deviation",
      text: "OEE Deviation",
      headerStyle: { width: "500px" },
      formatter: (cell) => {
        return cell === null ? "null" : `${cell.toFixed(2)} %`;
      },
    },
    {
      dataField: "ooe_deviation",
      text: "OOE Deviation",
      headerStyle: { width: "500px" },
      formatter: (cell) => {
        return cell === null ? "null" : `${cell.toFixed(2)} %`;
      },
    },
    {
      dataField: "teep_deviation",
      text: "TEEP Deviation",
      headerStyle: { width: "500px" },
      formatter: (cell) => {
        return cell === null ? "null" : `${cell.toFixed(2)} %`;
      },
    },
    {
      dataField: "throughput_deviation",
      text: "Throughput Deviation",
      headerStyle: { width: "500px" },
      formatter: (cell) => {
        return cell === null ? "null" : `${cell.toFixed(2)} %`;
      },
    },
    {
      dataField: "ole_deviation",
      text: "OLE Deviation",
      headerStyle: { width: "500px" },
      formatter: (cell) => {
        return cell === null ? "null" : `${cell.toFixed(2)} %`;
      },
    },
  ];
  return (
    <>
      <div className="content">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab === "1" ? "active" : ""}
              onClick={() => setActiveTab("1")}
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor: activeTab === "1" ? "#5555e7" : "transparent",
                color: activeTab === "1" ? "white" : "white",
              }}
            >
              Time Deviation
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "2" ? "active" : ""}
              onClick={() => setActiveTab("2")}
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor: activeTab === "2" ? "#5555e7" : "transparent",
                color: activeTab === "2" ? "white" : "white",
              }}
            >
              Production Deviation
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col>
                <JindalTableComponent
                  columns={columnDeviationOfTime}
                  apiData={deviation}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col>
                <JindalTableComponent
                  columns={columnDeviationOfProduction}
                  apiData={deviation}
                />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </>
  );
}

export default DeviationGrid;
