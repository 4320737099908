import React, { useEffect } from 'react';
import * as echarts from 'echarts';

const SpeedGauge = ({ refData, colorData, dataValue, circleWidth, mainText, circleNumber }) => {
    useEffect(() => {
        const dom = refData?.current;
        if (!dom) return; // Exit early if refData is null or undefined

        const myChart = echarts.init(dom, null, {
            renderer: 'canvas',
            useDirtyRect: false,
        });
        const option = {

            series: [
                {
                    type: 'gauge',
                    progress: {
                        show: true,
                        width: circleWidth,
                        itemStyle: {
                            color: colorData,
                        },
                    },
                    axisLine: {
                        lineStyle: {
                            width: circleWidth,
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        length: 7,
                        lineStyle: {
                            width: 1,
                            color: '#999',
                        },
                    },
                    axisLabel: {
                        distance: 13,
                        color: '#999',
                        fontSize: circleNumber,
                    },
                    anchor: {
                        show: true,
                        showAbove: true,
                        size: 20,
                        itemStyle: {
                            borderWidth: 10,
                        },
                    },
                    title: {
                        show: false,
                    },
                    detail: {
                        valueAnimation: true,
                        fontSize: mainText,
                        offsetCenter: [0, '70%'],
                        color: '#cccccc'
                    },
                    data: [
                        {
                            value: dataValue,
                        },
                    ],
                },
            ],
        };
        if (option && typeof option === 'object') {
            myChart.setOption(option);
        }
        return () => {
            myChart.dispose();
        };
    }, [refData, colorData, dataValue]); // Include refData, colorData, and dataValue as dependencies
};

export default SpeedGauge;