import React, { useState, useEffect } from "react";
import "./style.css";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Progress,
} from "reactstrap";
import axios from "axios";
import { APIURL } from "./Apiurl.js";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import { Link } from "react-router-dom";


function ProductionPlanEdit({handleCloseEditModal,selectedRow,fetchData}) {
  // const { id } = useParams();
  const [id,setId] = useState(selectedRow.planid)
  console.log("selectedRow in prodution plan edit=====>",selectedRow)
  // const [ id,setId ] = useState(selectedRow.toolordie_id);
  const [activeStep, setActiveStep] = useState(1);
  const [progress, setProgress] = useState(0);
  const [tenantId, setTenantId] = useState("");
  const [date, setDate] = useState(selectedRow.date);
  const [endCustomer, setEndCustomerName] = useState(selectedRow.end_customer);
  const [partNo, setPartNumber] = useState(selectedRow.partno);
  const [operationDetails, setOperationDetails] = useState(selectedRow.operation_details);
  const [machineNo, setMachineNo] = useState(selectedRow.machineid);
  const [machineName, setMachineName] = useState(selectedRow.machine_name);
  const [operator, setOperator] = useState("");
  const [operatorEmpCode, setOperatorEmpCode] = useState("");
  const [shiftName, setShiftName] = useState(selectedRow.shiftname);
  const [productionStartTime, setProductionStartTime] = useState(selectedRow.production_starttime_desired);
  const [target, setTarget] = useState(selectedRow.target);
  const [plannedQuantity, setPlannedQuantity] = useState(selectedRow.planned_quantity);
  const [noOfBatches, setNoOfBatches] = useState(selectedRow.no_of_batches);
  const [requiredTime, setRequiredTime] = useState(selectedRow.required_time_ideal);
  const [remark, setRemark] = useState(selectedRow.remarks);
  const [balanceScheduleQty, setBalanceScheduleQty] = useState(selectedRow.balance_schedule_quantity);
  const [actualRequiredQty, setActualRequiredQty] = useState(selectedRow.actual_required_quantity);
  const [availableWIP, setAvailableWIP] = useState(selectedRow.available_wip);
  const [isLastStep, setIsLastStep] = useState(false);
  useEffect(() => {
    fetchRecordToEdit();
  }, []);
  const handleNext = () => {
    if (activeStep === 1) {
      setProgress(100);
    }
    setActiveStep(activeStep + 1);
    if (activeStep + 1 === 3) {
      setIsLastStep(true);
    }
  };
  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
    setIsLastStep(false);
  };
  const fetchRecordToEdit = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const roleId = decodedToken["sub"];
      const logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
      const storedTenantId = sessionStorage.getItem("selectedTenantId");

      const response = await axios.post(
        APIURL,
        {
          query: `
          query GetProductionplan($id: Int!) {
            tnt_production_plan_master_by_pk(planid: $id) {
              tenantid
              date
              partno
              end_customer
              operation_details
              machineid
              machine_name
              shiftname
              production_starttime_desired
              target
              required_time_ideal
              actual_starttime
              actual_time_taken
              remarks
              planid
              target_uom
              planned_quantity_uom
              batch_start_time
              shift_id
              planned_quantity
              actual_quantity_produced
              no_of_batches
            }
          }
        `,
          variables: { id: parseInt(id) }, // Use the 'id' parameter from useParams
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const record = response.data.data.tnt_production_plan_master_by_pk;
      setTenantId(record.tenantid);
      setDate(record.date);
      setEndCustomerName(record.end_customer);
      setPartNumber(record.partno);
      setOperationDetails(record.operation_details);
      setMachineNo(record.machineid);
      setMachineName(record.machine_name);
      setShiftName(record.shiftname);
      setProductionStartTime(record.production_starttime_desired);
      setTarget(record.target);
      setPlannedQuantity(record.planned_quantity);
      setNoOfBatches(record.no_of_batches);
      setRequiredTime(record.required_time_ideal);
      setRemark(record.remarks);
      //   debugger;
      const name = record.ppap_revision_date;
    } catch (error) {
      console.error(error);
    }
  };
  const handleFormSubmit = (event) => {
    event.preventDefault();
    const token = sessionStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["role_id"];
    console.log(roleId);
    let logintenantId = null;
    if (roleId === 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-tenant-id"];
    }
    const formData = {
      tenantid: logintenantId,
      date: date,
      end_customer: endCustomer,
      partno: partNo,
      operation_details: operationDetails,
      machineid: machineNo,
      machine_name: machineName,
      shiftname: shiftName,
      production_starttime_desired: productionStartTime,
      target: target,
      planned_quantity: plannedQuantity,
      no_of_batches: noOfBatches,
      required_time_ideal: requiredTime,
      balance_schedule_quantity: balanceScheduleQty,
      actual_required_quantity: actualRequiredQty,
      available_wip: availableWIP,
      remarks: remark,
    };
    axios
      .post(
        APIURL,
        {
          query: `
              mutation UpdatePartAndCustomer($id: Int!, $formData: tnt_production_plan_master_set_input!) {
              update_tnt_production_plan_master_by_pk(pk_columns: { planid: $id }, _set: $formData) {
                planid
              }
            }
          `,
          variables: {
            id: parseInt(id),
            formData: formData,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-hasura-admin-secret": process.env.REACT_APP_X_API_SECRET_KEY, // Replace with your Hasura admin secret
          },
        }
      )
      .then((response) => {
        console.log(response); // Handle the response data as needed
        console.log(response.data);
        const updatedPartAndCust =
          response.data?.data?.update_tnt_production_plan_master_by_pk;
        if (updatedPartAndCust) {
          setProgress(1);
          setActiveStep(2);
          fetchData();
          toast.success("Data Updated successfully!");
         
          setDate("");
          setEndCustomerName("");
          setPartNumber("");
          setOperationDetails("");
          setMachineNo("");
          setMachineName("");
          setShiftName("");
          setProductionStartTime("");
          setTarget("");
          setPlannedQuantity("");
          setNoOfBatches("");
          setRequiredTime("");
          setBalanceScheduleQty("");
          setActualRequiredQty("");
          setAvailableWIP("");
          setRemark("");
        } else {
          toast.error("Failed to insert data.");
        }
      })
      .catch((error) => {
        console.error(error); // Handle the error
        toast.error("An error occurred while inserting data.");
      });
  };
  const handleDelete = () => {
    const confirmDeletion = window.confirm(
      "Are you sure you want to delete this record?"
    );
    if (confirmDeletion) {
      axios
        .post(APIURL, {
          query: `
                mutation DeletePartAndCustomer($id: Int!) {
                  delete_tnt_production_plan_master_by_pk(planid: $id) {
                    planid
                  }
                }
              `,
          variables: { id: parseInt(id) },
        })
        .then((response) => {
          const deleteProductionPlan =
            response.data?.data?.delete_tnt_production_plan_master_by_pk;
          if (deleteProductionPlan) {
            toast.success("Record deleted successfully!");
          } else {
            toast.error("Failed to delete the record.");
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error("An error occurred while deleting the record.");
        });
    }
  };
  const renderStepContent = () => {
    switch (activeStep) {
      case 1:
        return (
          <>
            <Row>
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="date" className="plan-admin">Date</Label>
                  <Input
                    type="text"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="endCustomer" className="plan-admin">End Customer</Label>
                  <Input
                    type="text"
                    value={endCustomer}
                    onChange={(e) => setEndCustomerName(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="partno"className="plan-admin">Part No</Label>
                  <Input
                    type="text"
                    value={partNo}
                    onChange={(e) => setPartNumber(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="operationDetails" className="plan-admin">Operation Details</Label>
                  <Input
                    placeholder=""
                    type="text"
                    value={operationDetails}
                    onChange={(e) => setOperationDetails(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <FormGroup>
                    <Label for="machineNo" className="plan-admin">Machine Number </Label>
                    <Input
                      type="text"
                      value={machineNo}
                      onChange={(e) => setMachineNo(e.target.value)}
                    />
                  </FormGroup>
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="4" >
                <FormGroup>
                  <Label for="machineName" className="plan-admin">Machine Name</Label>
                  <Input
                    type="text"
                    value={machineName}
                    onChange={(e) => setMachineName(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
            <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="shift" className="plan-admin">Shift</Label>
                  <Input
                    type="text"
                    value={shiftName}
                    onChange={(e) => setShiftName(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="production-start-time" className="plan-admin">
                    Production Start Time
                  </Label>
                  <Input
                    type="text"
                    value={productionStartTime}
                    onChange={(e) => setProductionStartTime(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="target" className="plan-admin">Target</Label>
                  <Input
                    type="text"
                    value={target}
                    onChange={(e) => setTarget(e.target.value)}
                  />
                </FormGroup>
              </Col>
             
            </Row>
          </>
        );
      case 2:
        return (
          <>
            <Row>
             
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="planned-quantity" className="plan-admin">Planned Quantity</Label>
                  <Input
                    type="text"
                    value={plannedQuantity}
                    onChange={(e) => setPlannedQuantity(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="no-of-batches" className="plan-admin">No. Of Batches</Label>
                  <Input
                    type="text field"
                    value={noOfBatches}
                    onChange={(e) => setNoOfBatches(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="required-time" className="plan-admin">Required Time (In Minutes)</Label>
                  <Input
                    type="text"
                    value={requiredTime}
                    onChange={(e) => setRequiredTime(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
            <Col className="px-md-1" sm="3" md="4">
                <FormGroup className="flex-nowrap">
                  <Label for="required_time" className="plan-admin">
                    {" "}
                    Balance Schedule Quantity 
                  </Label>
                  <Input
                    placeholder=""
                    type="text"
                    value={balanceScheduleQty}
                    onChange={(e) => setBalanceScheduleQty(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" sm="3" md="4">
                <FormGroup className="flex-nowrap">
                  <Label for="required_time" className="plan-admin" >
                    Actual Required Quantity 
                  </Label>
                  <Input
                    placeholder=""
                    type="text"
                    value={actualRequiredQty}
                    onChange={(e) => setActualRequiredQty(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" sm="3" md="4">
                <FormGroup className="flex-nowrap">
                  <Label for="required_time" className="plan-admin">
                    {" "}
                    Available WIP 
                  </Label>
                  <Input
                    placeholder=""
                    type="text"
                    value={availableWIP}
                    onChange={(e) => setAvailableWIP(e.target.value)}
                  />
                </FormGroup>
              </Col>
            
            </Row>
            <Row>
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="remark" className="plan-admin">Remarks</Label>
                  <Input
                    type="text"
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        );
      default:
        return null;
    }
  };
  const calculateProgress = () => {
    return Math.round(((activeStep - 1) / 2) * 100); // Update the denominator to match the total number of steps minus one
  };
  const renderProgressBarDestinations = () => {
    const step1Class = activeStep >= 1 ? "progress-destination-active" : "";
    const step2Class = activeStep >= 2 ? "progress-destination-active" : "";
    const step3Class = activeStep >= 3 ? "progress-destination-active" : "";
    return (
      <div className="progress-destinations">
        <div className={`progress-destination ${step1Class}`}>
          <span>1</span>
        </div>
        <div className={`progress-destination ${step2Class}`}>
          <span>2</span>
        </div>
        <div className={`progress-destination ${step3Class}`}>
          <span>3</span>
        </div>
      </div>
    );
  };
  const renderStepButtons = () => {
    if (activeStep === 1) {
      return (
        <>
          <Link to="/admin/planing-administration">
            <Button color="danger" style={{ marginRight: "5px" }} onClick={handleCloseEditModal}>
              {" "}
              Cancel
            </Button>
          </Link>
          <Button color="primary" onClick={handleNext}>
            Next
          </Button>
        </>
      );
    } else if (activeStep === 2) {
      return (
        <>
          <Link to="/admin/planing-administration">
            <Button color="danger" style={{ marginRight: "5px" }} onClick={handleCloseEditModal}>
              {" "}
              Close
            </Button>
          </Link>
          <Button color="primary" onClick={handlePrevious}>
            Previous
          </Button>
          <Button color="success" onClick={handleFormSubmit}>
            Save
          </Button>
        </>
      );
    }
  };
  return (
    <div className="content">
      <Row>
        <Col md="8">
          <div className="progress-container">
            {renderProgressBarDestinations()}
            <Progress
              max="100"
              value={calculateProgress()}
              className="progress-bar-sm progress-bar-striped"
            />
          </div>
        </Col>
      </Row>
      <Row className="progress-row">
        <Col md="8">
          <Card>
            <CardHeader>
              <h4 className="title" style={{ color: "#ffffff" }}>Update Your Production Plan
              <button type="button" className="close" aria-label="Close" 
                    style={{
                    // float: "right", 
                    background: "transparent",
                    border: "none",
                    color: "#ffffff" ,
                    fontSize: "35px",
                    cursor: "pointer",
                    marginTop: "-40px",
                    }}
                    onClick={handleCloseEditModal}
                    >
                    &times;
                    </button>
              </h4>
            </CardHeader>
            <CardBody>
              <Form>{renderStepContent()}</Form>
            </CardBody>
            <CardFooter>
              <div className="text-right">{renderStepButtons()}</div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
export default ProductionPlanEdit;
