import React, { useState, useEffect, useRef } from "react";
import "./Oee.css";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import axios from "axios";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PercentageCircle from "views/PercentageCircle";
import MachineWiseOEE from "./MachineWiseOEE";
import redirectToLoginIfTokenMissing from "views/TokenUtility";
import classNames from "classnames";
import { APIURL } from "views/Apiurl";
import SpeedGauge from "assets/charts/SpeedGauge";
import { QueryApi } from "QueryApi";
import cycleLost from "../../assets/img/cycleLost.svg";
import availabilityLost from "../../assets/img/availabilityLost.svg";
import ProjectionRejection from "../../assets/img/ProjectionRejection.svg";
import downTime from "../../assets/img/downTime.svg";
import IdlelostTime from "../../assets/img/IdlelostTime.svg";
import productionYeild from "../../assets/img/productionYeild.svg";
import upArrow from "../../assets/img/upArrow.svg";
import downArrow from "../../assets/img/downArrow.svg";
import minusArrow from "../../assets/img/minusArrow.svg";
import LineChart from "assets/charts/LineChart";
import { useNavigate } from "react-router-dom";

function OeeLevelOne(props) {
  const [activeTab, setActiveTab] = useState("1");
  const storedTenantId = sessionStorage.getItem("selectedTenantId");
  useEffect(() => {
    redirectToLoginIfTokenMissing(); // Call the utility function to validate the token
  }, []);
  const navigate = useNavigate();
  const [org, setorg] = useState("");
  const [unit, setunit] = useState("");
  const [dept, setdept] = useState("");
  const [depts, setdepts] = useState([]);
  const [machine, setMachine] = useState("");
  const [machines, setMachines] = useState([]);
  const [plants, setplants] = useState([]);
  const [orgs, setorgs] = useState([]);
  const [otherData, setOtherData] = useState([]);
  const [selectedOrgData, setSelectedOrgData] = useState(null);
  const [operationalEfficiency, setoperationalEfficiency] = useState(0);
  const [FullyProductiveTimeList, setFullyProductiveTimeList] = useState(0);

  const chartRef = useRef(null);
  SpeedGauge({
    refData: chartRef,
    colorData: "#1976D2",
    dataValue: operationalEfficiency,
    circleWidth: 10,
    mainText: 28,
    circleNumber: 18,
  });

  const chartRef1 = useRef(null);
  SpeedGauge({
    refData: chartRef1,
    colorData: "#1976D2",
    dataValue: FullyProductiveTimeList,
    circleWidth: 10,
    mainText: 28,
    circleNumber: 18,
  });

  const [apiData, setApiData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const currDay = day < 10 ? `0${day}` : day;
  const currMonth = month < 10 ? `0${month}` : month;
  let currentDate = JSON.stringify(`${year}-${currMonth}-${currDay}`);

  const Newdate = new Date();
  let Newday = Newdate.getDate() - 1;
  let Newmonth = Newdate.getMonth() + 1;
  let Newyear = Newdate.getFullYear();
  const NewcurrDay = Newday < 10 ? `0${Newday}` : Newday;
  const NewcurrMonth = Newmonth < 10 ? `0${Newmonth}` : Newmonth;
  let NewcurrentDate = JSON.stringify(
    `${Newyear}-${NewcurrMonth}-${NewcurrDay}`
  );

  const dateData = new Date();
  dateData.setDate(dateData.getDate() - 6);
  var dayDataValue = dateData.getDate();
  var monthDataValue = dateData.getMonth() + 1;
  var yearDataValue = dateData.getFullYear();
  var formattedDay = dayDataValue < 10 ? `0${dayDataValue}` : dayDataValue;
  var formattedMonth =
    monthDataValue < 10 ? `0${monthDataValue}` : monthDataValue;
  var weekDataDate = JSON.stringify(
    `${yearDataValue}-${formattedMonth}-${formattedDay}`
  );
  const NewdateData = new Date();
  NewdateData.setDate(NewdateData.getDate() - 14);
  var NewdayDataValue = NewdateData.getDate();
  var NewmonthDataValue = NewdateData.getMonth() + 1;
  var NewyearDataValue = NewdateData.getFullYear();
  var NewformattedDay =
    NewdayDataValue < 10 ? `0${NewdayDataValue}` : NewdayDataValue;
  var NewformattedMonth =
    NewmonthDataValue < 10 ? `0${NewmonthDataValue}` : NewmonthDataValue;
  var NewweekDataDate = JSON.stringify(
    `${NewyearDataValue}-${NewformattedMonth}-${NewformattedDay}`
  );

  const previousDate = new Date();
  previousDate.setDate(1);
  const previousDay = previousDate.getDate();
  const previousMonth = previousDate.getMonth() + 1;
  const previousYear = previousDate.getFullYear();
  const formattedPreviousDay =
    previousDay < 10 ? `0${previousDay}` : previousDay;
  const formattedPreviousMonth =
    previousMonth < 10 ? `0${previousMonth}` : previousMonth;
  const previousMonthDate = JSON.stringify(
    `${previousYear}-${formattedPreviousMonth}-${formattedPreviousDay}`
  );
  const NewpreviousDate = new Date();
  NewpreviousDate.setDate(1);
  const NewpreviousDay = NewpreviousDate.getDate();
  const NewpreviousMonth = NewpreviousDate.getMonth();
  const NewpreviousYear = NewpreviousDate.getFullYear();
  const NewformattedPreviousDay =
    NewpreviousDay < 10 ? `0${NewpreviousDay}` : NewpreviousDay;
  const NewformattedPreviousMonth =
    NewpreviousMonth < 10 ? `0${NewpreviousMonth}` : NewpreviousMonth;
  const NewpreviousMonthDate = JSON.stringify(
    `${NewpreviousYear}-${NewformattedPreviousMonth}-${NewformattedPreviousDay}`
  );

  const yearCurrentDate = new Date();
  yearCurrentDate.setDate(1);
  yearCurrentDate.setMonth(1);
  const yearDay = yearCurrentDate.getDate();
  const yearMonth = yearCurrentDate.getMonth();
  const yearYear = yearCurrentDate.getFullYear();
  const formattedYearDay = yearDay < 10 ? `0${yearDay}` : yearDay;
  const formattedYearMonth = yearMonth < 10 ? `0${yearMonth}` : yearMonth;
  const yearPreviousMonthDate = JSON.stringify(
    `${yearYear}-${formattedYearMonth}-${formattedYearDay}`
  );

  const xAxisData = ["org1", "org2", "org3", "org4", "org5"];
  const barStyleData = [
    {
      barName: "first bar",
      barColor: "#8187E9",
      barData: [50, 25, 40, 65, 50],
    },
    {
      barName: "second bar",
      barColor: "#A4EDB8",
      barData: [20, -20, -30, 20, 10],
    },
    {
      barName: "third bar",
      barColor: "#F39D54",
      barData: [30, 40, 40, 20, 10],
    },
  ];

  const xAxisDataNew = [
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const dataset = [
    {
      label: "data 1",
      data: [80, 100, 70, 80, 120, 80, 40, 100, 70, 80, 120, 80],
      borderColor: "#8087E8",
    },
    {
      label: "data 2",
      data: [100, 70, 50, 120, 50, 100, 100, 70, 50, 120, 50, 130],
      borderColor: "#647E7C",
    },
  ];

  const componentName = "User Form";
  const dataList = [
    { value: 60, name: "Operator", color: "#8187E9" },
    { value: 10, name: "Floor Manager", color: "#A3EDBA" },
    { value: 15, name: "Setters", color: "#F19D54" },
    { value: 5, name: "Helpers", color: "#6799A2" },
    { value: 10, name: "Supervisors", color: "#E1D369" },
  ];
  //   const chartRefData = useRef(null);
  //   PieChart(chartRefData, componentName, dataList)

  const YaxisDataSeriesOne = [
    {
      name: "User List",
      data: [
        140, 232, 101, 264, 90, 340, 250, 140, 232, 101, 264, 90, 340, 250,
      ],
      color: "#383757",
    },
  ];
  const YaxisDataSeriesTwo = [
    {
      name: "User List",
      data: [
        140, 232, 101, 264, 90, 340, 250, 140, 232, 101, 264, 90, 340, 250,
      ],
      color: "#5A706E",
    },
  ];
  const YaxisDataSeriesThree = [
    {
      name: "User List",
      data: [
        140, 232, 101, 264, 90, 340, 250, 140, 232, 101, 264, 90, 340, 250,
      ],
      color: "#745550",
    },
  ];
  const xAxisSeries = [
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
    "Sun",
  ];

  const token = sessionStorage.getItem("token");
  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const tenantId =
    decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
  useEffect(() => {
    fetchplant();
  }, [org]);
  const fetchorg = async (logintenantId) => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery($logintenantId: Int!) {
          tnt_organization_structure(
            where: {
              parent_organization_id: { _eq: 0 },
              tenantid: { _eq: $logintenantId }
            }
          ) {
            organization_structure_id
            organization_node_name
          }
        }
      `,
          variables: {
            logintenantId: parseInt(logintenantId),
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );
      const fetchedOrgs = response.data.data.tnt_organization_structure;
      setorgs(fetchedOrgs);
      const parentIds = fetchedOrgs.map((org) => org.organization_structure_id);
      const childResponse = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery($parentIds: [Int!]) {
          tnt_organization_structure(
            where: {
              parent_organization_id: { _in: $parentIds }
            }
          ) {
            organization_structure_id
            organization_node_name
          }
        }
      `,
          variables: {
            parentIds: parentIds,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      const childOrgs = childResponse.data.data.tnt_organization_structure;
      const updatedOtherData = otherData.map((row) => {
        const matchingOrg = childOrgs.find(
          (org) => org.organization_structure_id === row.parent_organization_id
        );

        if (matchingOrg) {
          return {
            ...row,
            parent_organization_id: matchingOrg.organization_structure_id,
          };
        }

        return row;
      });

      setOtherData(childOrgs);
    } catch (error) {}
  };
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["sub"];
    let logintenantId = null;

    if (roleId == 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
    }

    fetchorg(logintenantId);
  }, []);
  const fetchplant = async () => {
    if (org) {
      // Only fetch if org is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
          query MyQuery($p: Int!) {
            tnt_organization_structure(where: {parent_organization_id: {_eq: $p}}) {
              organization_structure_id
              organization_node_name
            }
          }
        `,
            variables: {
              p: org,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        setplants(response.data.data.tnt_organization_structure);
      } catch (error) {}
    }
  };
  useEffect(() => {
    fetchdept();
  }, [unit]);
  const fetchdept = async () => {
    if (unit) {
      // Only fetch if org is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
          query MyQuery($d: Int!) {
            tnt_organization_structure(where: {parent_organization_id: {_eq: $d}}) {
              organization_structure_id
              organization_node_name
            }
          }
        `,
            variables: {
              d: unit,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        setdepts(response.data.data.tnt_organization_structure);
      } catch (error) {}
    }
  };

  useEffect(() => {
    fetchMachine();
  }, [dept]);
  const fetchMachine = async () => {
    if (dept) {
      // Only fetch if dept is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
          query MyQuery($m: Int!) {
           tnt_org_machine_assignment(where: {dept: {organization_structure_id: {_eq: $m}}} ) {
              machine_name
            }
          }
        `,
            variables: {
              m: dept,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        setMachines(response.data.data.tnt_org_machine_assignment || []);
      } catch (error) {}
    }
  };

  const handleOrgSelect = async (selectedOrgId) => {
    if (selectedOrgId) {
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
          query GetCostCenter($orgId: Int!) {
            tnt_organization_structure(where: { organization_structure_id: { _eq: $orgId } }) {
              cost_centre_code
            }
          }
        `,
            variables: {
              orgId: selectedOrgId,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );

        if (
          response.data &&
          response.data.data &&
          response.data.data.tnt_organization_structure &&
          response.data.data.tnt_organization_structure.length > 0
        ) {
          setSelectedOrgData(response.data.data.tnt_organization_structure[0]);
        } else {
          setSelectedOrgData(null);
        }
      } catch (error) {}
    } else {
      setSelectedOrgData(null);
    }

    setorg(selectedOrgId);
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const [bigChartData, setbigChartData] = React.useState("data1");
  const setBgChartData = (name) => {
    setbigChartData(name);
  };

  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const roleId = decodedToken["sub"];
      const tenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-tenant-id"];
      const storedTenantId = sessionStorage.getItem("selectedTenantId");
      let variables = {};
      if (roleId === 1) {
        if (storedTenantId !== null) {
          variables = { tenantId: storedTenantId };
        }
      } else {
        variables = { tenantId: tenantId };
      }
      let query = `
      query MyQuery {
        dm_foundation {
          actual_cycletime
          actual_production_time
          all_available_time
          asset_utilization_percent
          availability_loss_percent
          availability_loss_time
          batch_id
          capacity_utilized_percent
          cycletime_deviation
          date
          department_id
          edge_id
          machine_availability_percent
          machine_id
          machine_idle_time
          machine_performance_percent
          machine_up_time
          net_available_time
          no_of_parts_rejected
          org_id
          parts_per_minute
          planned_downtime_percent
          planned_production_time
          shift_id
          target_parts
          tenant_id
          time_between_job_parts
          timestamp
          total_machine_downtime
          total_machine_runtime
          total_parts_produced
          total_planned_downtime
          total_production_per_batch
          unit_id
          unplanned_downtime
        }
      }`;
      const response = await axios.post(
        APIURL,
        {
          query,
          variables,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-hasura-admin-secret": process.env.REACT_APP_X_API_SECRET_KEY,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setApiData(response.data.data.dm_foundation);
    } catch (error) {}
  };

  const datalistValue = [
    {
      shift: "shift 1",
      percentage: 20,
    },
    {
      shift: "shift 2",
      percentage: 60,
    },
    {
      shift: "shift 3",
      percentage: 88,
    },
    {
      shift: "shift 4",
      percentage: 90,
    },
    {
      shift: "shift 5",
      percentage: 97,
    },
    {
      shift: "shift 6",
      percentage: 55,
    },
    {
      shift: "shift 7",
      percentage: 80,
    },
    {
      shift: "shift 8",
      percentage: 98,
    },
    {
      shift: "shift 9",
      percentage: 99,
    },
    {
      shift: "shift 10",
      percentage: 30,
    },
    {
      shift: "shift 11",
      percentage: 25,
    },
    {
      shift: "shift 12",
      percentage: 66,
    },
  ];

  //////////////////////////////////////////////////////////
  const mixedChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };
  const mixedChartData = {
    labels: ["January", "February", "March", "April", "May"],
    datasets: [
      {
        label: "Line Data 1",
        type: "line", // Specify line type
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 2,
        tension: 0.5,
        data: [7, 16, 25, 35, 54],
      },
      {
        label: "Line Data 2",
        type: "line", // Specify line type
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 2,
        tension: 0.5,
        data: [9, 23, 29, 32, 34],
      },
    ],
  };
  /////////////////bar graph ////////////////
  const allValues = [
    { xAxisData: "Jan", lineData: 2.3, barData: 160 },
    { xAxisData: "Mar", lineData: 6.3, barData: 300 },
    { xAxisData: "May", lineData: 16.2, barData: 400 },
    { xAxisData: "Jul", lineData: 22.8, barData: 200 },
    { xAxisData: "Sep", lineData: 14.5, barData: 100 },
    { xAxisData: "Nov", lineData: 8.9, barData: 20 },
  ];

  const title = "";
  const barChartName = "Month data";

  /// Availability Lost Time //////////////////////////////////////////////////////////////////////////////////////////

  const [AvailabilityLostTimeDaily, setAvailabilityLostTimeDaily] = useState(0);
  useEffect(() => {
    AvailabilityLostTimeDailyData();
  }, []);
  const AvailabilityLostTimeDailyData = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
              dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                  avg {
                    availability_loss_time
                  }
                }
              }
            }
      `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.avg.availability_loss_time === null
      ) {
        setAvailabilityLostTimeDaily("No Data");
      } else {
        setAvailabilityLostTimeDaily(
          (
            data.dm_daily_aggregate.aggregate.avg.availability_loss_time /
            (1000 * 60 * 60)
          ).toFixed(1)
        );
      }
    } catch (error) {
      setAvailabilityLostTimeDaily("No Data");
    }
  };
  const [NewAvailabilityLostTimeDaily, setNewAvailabilityLostTimeDaily] =
    useState(0);
  useEffect(() => {
    NewAvailabilityLostTimeDailyData();
  }, []);
  const NewAvailabilityLostTimeDailyData = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${NewcurrentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                  availability_loss_time
                }
              }
            }
          }
      `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.avg.availability_loss_time === null
      ) {
        setNewAvailabilityLostTimeDaily("No Data");
      } else {
        setNewAvailabilityLostTimeDaily(
          (
            data.dm_daily_aggregate.aggregate.avg.availability_loss_time /
            (1000 * 60 * 60)
          ).toFixed(1)
        );
      }
    } catch (error) {
      setNewAvailabilityLostTimeDaily("No Data");
    }
  };

  const [AvailabilityLostTimeWeek, setAvailabilityLostTimeWeek] = useState(0);
  useEffect(() => {
    AvailabilityLostTimeWeekData();
  }, []);
  const AvailabilityLostTimeWeekData = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
              dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                  avg {
                    availability_loss_time
                  }
                }
              }
            }
      `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.avg.availability_loss_time === null
      ) {
        setAvailabilityLostTimeWeek("No Data");
      } else {
        setAvailabilityLostTimeWeek(
          (
            data.dm_daily_aggregate.aggregate.avg.availability_loss_time /
            (1000 * 60 * 60)
          ).toFixed(1)
        );
      }
    } catch (error) {
      setAvailabilityLostTimeWeek("No Data");
    }
  };
  const [NewAvailabilityLostTimeWeek, setNewAvailabilityLostTimeWeek] =
    useState(0);
  useEffect(() => {
    NewAvailabilityLostTimeWeekData();
  }, []);
  const NewAvailabilityLostTimeWeekData = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${NewweekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
              dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                  avg {
                    availability_loss_time
                  }
                }
              }
            }
      `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.avg.availability_loss_time === null
      ) {
        setNewAvailabilityLostTimeWeek("No Data");
      } else {
        setNewAvailabilityLostTimeWeek(
          (
            data.dm_daily_aggregate.aggregate.avg.availability_loss_time /
            (1000 * 60 * 60)
          ).toFixed(1)
        );
      }
    } catch (error) {
      setNewAvailabilityLostTimeWeek("No Data");
    }
  };

  const [AvailabilityLostTimeMonth, setAvailabilityLostTimeMonth] = useState(0);
  useEffect(() => {
    AvailabilityLostTimeMonthData();
  }, []);
  const AvailabilityLostTimeMonthData = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
              dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                  avg {
                    availability_loss_time
                  }
                }
              }
            }
      `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.avg.availability_loss_time === null
      ) {
        setAvailabilityLostTimeMonth("No Data");
      } else {
        setAvailabilityLostTimeMonth(
          (
            data.dm_daily_aggregate.aggregate.avg.availability_loss_time /
            (1000 * 60 * 60)
          ).toFixed(1)
        );
      }
    } catch (error) {
      setAvailabilityLostTimeMonth("No Data");
    }
  };
  const [NewAvailabilityLostTimeMonth, setNewAvailabilityLostTimeMonth] =
    useState(0);
  useEffect(() => {
    NewAvailabilityLostTimeMonthData();
  }, []);
  const NewAvailabilityLostTimeMonthData = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${NewpreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
              dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                  avg {
                    availability_loss_time
                  }
                }
              }
            }
      `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.avg.availability_loss_time === null
      ) {
        setNewAvailabilityLostTimeMonth("No Data");
      } else {
        setNewAvailabilityLostTimeMonth(
          (
            data.dm_daily_aggregate.aggregate.avg.availability_loss_time /
            (1000 * 60 * 60)
          ).toFixed(1)
        );
      }
    } catch (error) {
      setNewAvailabilityLostTimeMonth("No Data");
    }
  };

  ///  Down Time //////////////////////////////////////////////////////////////////////////////////////////

  const [DownTimeDaily, setDownTimeDaily] = useState(0);
  useEffect(() => {
    DownTimeDailyData();
  }, []);
  const DownTimeDailyData = async () => {
    try {
      const data = await QueryApi(`
              query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                  aggregate {
                    sum {
                      total_machine_downtime
                    }
                  }
                }
              }
        `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.sum.total_machine_downtime === null
      ) {
        setDownTimeDaily("No Data");
      } else {
        setDownTimeDaily(
          (
            data.dm_daily_aggregate.aggregate.sum.total_machine_downtime /
            (1000 * 60 * 60)
          ).toFixed(1)
        );
      }
    } catch (error) {
      setDownTimeDaily("No Data");
    }
  };
  const [NewDownTimeDaily, setNewDownTimeDaily] = useState(0);
  useEffect(() => {
    NewDownTimeDailyData();
  }, []);
  const NewDownTimeDailyData = async () => {
    try {
      const data = await QueryApi(`
              query MyQuery($start_date: date = ${NewcurrentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                  aggregate {
                    sum {
                      total_machine_downtime
                    }
                  }
                }
              }
        `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.sum.total_machine_downtime === null
      ) {
        setNewDownTimeDaily("No Data");
      } else {
        setNewDownTimeDaily(
          (
            data.dm_daily_aggregate.aggregate.sum.total_machine_downtime /
            (1000 * 60 * 60)
          ).toFixed(1)
        );
      }
    } catch (error) {
      setNewDownTimeDaily("No Data");
    }
  };

  const [DownTimeWeek, setDownTimeWeek] = useState(0);
  useEffect(() => {
    DownTimeWeekData();
  }, []);
  const DownTimeWeekData = async () => {
    try {
      const data = await QueryApi(`
              query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                  aggregate {
                    sum {
                      total_machine_downtime
                    }
                  }
                }
              }
        `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.sum.total_machine_downtime === null
      ) {
        setDownTimeWeek("No Data");
      } else {
        setDownTimeWeek(
          (
            data.dm_daily_aggregate.aggregate.sum.total_machine_downtime /
            (1000 * 60 * 60)
          ).toFixed(1)
        );
      }
    } catch (error) {
      setDownTimeWeek("No Data");
    }
  };
  const [NewDownTimeWeek, setNewDownTimeWeek] = useState(0);
  useEffect(() => {
    NewDownTimeWeekData();
  }, []);
  const NewDownTimeWeekData = async () => {
    try {
      const data = await QueryApi(`
              query MyQuery($start_date: date = ${NewweekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                  aggregate {
                    sum {
                      total_machine_downtime
                    }
                  }
                }
              }
        `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.sum.total_machine_downtime === null
      ) {
        setNewDownTimeWeek("No Data");
      } else {
        setNewDownTimeWeek(
          (
            data.dm_daily_aggregate.aggregate.sum.total_machine_downtime /
            (1000 * 60 * 60)
          ).toFixed(1)
        );
      }
    } catch (error) {
      setNewDownTimeWeek("No Data");
    }
  };

  const [DownTimeMonth, setDownTimeMonth] = useState(0);
  useEffect(() => {
    DownTimeMonthData();
  }, []);
  const DownTimeMonthData = async () => {
    try {
      const data = await QueryApi(`
              query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                  aggregate {
                    sum {
                      total_machine_downtime
                    }
                  }
                }
              }
        `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.sum.total_machine_downtime === null
      ) {
        setDownTimeMonth("No Data");
      } else {
        setDownTimeMonth(
          (
            data.dm_daily_aggregate.aggregate.sum.total_machine_downtime /
            (1000 * 60 * 60)
          ).toFixed(1)
        );
      }
    } catch (error) {
      setDownTimeMonth("No Data");
    }
  };
  const [NewDownTimeMonth, setNewDownTimeMonth] = useState(0);
  useEffect(() => {
    NewDownTimeMonthData();
  }, []);
  const NewDownTimeMonthData = async () => {
    try {
      const data = await QueryApi(`
              query MyQuery($start_date: date = ${NewpreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                  aggregate {
                    sum {
                      total_machine_downtime
                    }
                  }
                }
              }
        `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.sum.total_machine_downtime === null
      ) {
        setNewDownTimeMonth("No Data");
      } else {
        setNewDownTimeMonth(
          (
            data.dm_daily_aggregate.aggregate.sum.total_machine_downtime /
            (1000 * 60 * 60)
          ).toFixed(1)
        );
      }
    } catch (error) {
      setNewDownTimeMonth("No Data");
    }
  };

  ///  Idle Time Lost //////////////////////////////////////////////////////////////////////////////////////////

  const [IdleTimeLostDaily, setIdleTimeLostDaily] = useState(0);
  useEffect(() => {
    IdleTimeLostDailyData();
  }, []);
  const IdleTimeLostDailyData = async () => {
    try {
      const data = await QueryApi(`
              query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                  aggregate {
                    sum {
                      machine_idle_time
                    }
                  }
                }
              }
        `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.sum.machine_idle_time === null
      ) {
        setIdleTimeLostDaily("No Data");
      } else {
        setIdleTimeLostDaily(
          (
            data.dm_daily_aggregate.aggregate.sum.machine_idle_time /
            (1000 * 60 * 60)
          ).toFixed(1)
        );
      }
    } catch (error) {
      setIdleTimeLostDaily("No Data");
    }
  };
  const [NewIdleTimeLostDaily, setNewIdleTimeLostDaily] = useState(0);
  useEffect(() => {
    NewIdleTimeLostDailyData();
  }, []);
  const NewIdleTimeLostDailyData = async () => {
    try {
      const data = await QueryApi(`
              query MyQuery($start_date: date = ${NewcurrentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                  aggregate {
                    sum {
                      machine_idle_time
                    }
                  }
                }
              }
        `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.sum.machine_idle_time === null
      ) {
        setNewIdleTimeLostDaily("No Data");
      } else {
        setNewIdleTimeLostDaily(
          (
            data.dm_daily_aggregate.aggregate.sum.machine_idle_time /
            (1000 * 60 * 60)
          ).toFixed(1)
        );
      }
    } catch (error) {
      setNewIdleTimeLostDaily("No Data");
    }
  };

  const [IdleTimeLostWeek, setIdleTimeLostWeek] = useState(0);
  useEffect(() => {
    IdleTimeLostWeekData();
  }, []);
  const IdleTimeLostWeekData = async () => {
    try {
      const data = await QueryApi(`
              query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                  aggregate {
                    sum {
                      machine_idle_time
                    }
                  }
                }
              }
        `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.sum.machine_idle_time === null
      ) {
        setIdleTimeLostWeek("No Data");
      } else {
        setIdleTimeLostWeek(
          (
            data.dm_daily_aggregate.aggregate.sum.machine_idle_time /
            (1000 * 60 * 60)
          ).toFixed(1)
        );
      }
    } catch (error) {
      setIdleTimeLostWeek("No Data");
    }
  };
  const [NewIdleTimeLostWeek, setNewIdleTimeLostWeek] = useState(0);
  useEffect(() => {
    NewIdleTimeLostWeekData();
  }, []);
  const NewIdleTimeLostWeekData = async () => {
    try {
      const data = await QueryApi(`
              query MyQuery($start_date: date = ${NewweekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                  aggregate {
                    sum {
                      machine_idle_time
                    }
                  }
                }
              }
        `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.sum.machine_idle_time === null
      ) {
        setNewIdleTimeLostWeek("No Data");
      } else {
        setNewIdleTimeLostWeek(
          (
            data.dm_daily_aggregate.aggregate.sum.machine_idle_time /
            (1000 * 60 * 60)
          ).toFixed(1)
        );
      }
    } catch (error) {
      setNewIdleTimeLostWeek("No Data");
    }
  };

  const [IdleTimeLostMonth, setIdleTimeLostMonth] = useState(0);
  useEffect(() => {
    IdleTimeLostMonthData();
  }, []);
  const IdleTimeLostMonthData = async () => {
    try {
      const data = await QueryApi(`
              query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                  aggregate {
                    sum {
                      machine_idle_time
                    }
                  }
                }
              }
        `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.sum.machine_idle_time === null
      ) {
        setIdleTimeLostMonth("No Data");
      } else {
        setIdleTimeLostMonth(
          (
            data.dm_daily_aggregate.aggregate.sum.machine_idle_time /
            (1000 * 60 * 60)
          ).toFixed(1)
        );
      }
    } catch (error) {
      setIdleTimeLostMonth("No Data");
    }
  };
  const [NewIdleTimeLostMonth, setNewIdleTimeLostMonth] = useState(0);
  useEffect(() => {
    NewIdleTimeLostMonthData();
  }, []);
  const NewIdleTimeLostMonthData = async () => {
    try {
      const data = await QueryApi(`
              query MyQuery($start_date: date = ${NewpreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                  aggregate {
                    sum {
                      machine_idle_time
                    }
                  }
                }
              }
        `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.sum.machine_idle_time === null
      ) {
        setNewIdleTimeLostMonth("No Data");
      } else {
        setNewIdleTimeLostMonth(
          (
            data.dm_daily_aggregate.aggregate.sum.machine_idle_time /
            (1000 * 60 * 60)
          ).toFixed(1)
        );
      }
    } catch (error) {
      setNewIdleTimeLostMonth("No Data");
    }
  };

  /////////////////////Operational Efficiency gauge /////////////////////////

  useEffect(() => {
    OperationalEfficiencyFun();
  }, []);
  const OperationalEfficiencyFun = async () => {
    try {
      const data = await QueryApi(`
        query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
          dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
            aggregate {
              avg {
                  oee
              }
            }
          }
        }
    `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.avg.oee === null
      ) {
        setoperationalEfficiency("No Data");
      } else {
        setoperationalEfficiency(
          data.dm_daily_aggregate.aggregate.avg.oee.toFixed(1)
        );
      }
    } catch (error) {
      setoperationalEfficiency("No Data");
    }
  };

  /////////OEE Level 1 performance , Availability , quality////////////////////////
  const [OeeDataList, setOeeDataList] = useState({
    machine_availability_percent: "No Data",
    machine_performance_percent: "No Data",
    quality_percent: "No Data",
  });
  useEffect(() => {
    OeeFun();
  }, []);
  const OeeFun = async () => {
    try {
      const data = await QueryApi(`
      query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        dm_foundation_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
          aggregate {
            avg {
              machine_availability_percent
              machine_performance_percent
              quality_percent
            }
          }
        }
      } `);

      // Format the values to three decimal places
      let formattedData = data.dm_foundation_aggregate.aggregate.avg;
      formattedData.machine_availability_percent = parseFloat(
        formattedData.machine_availability_percent.toFixed(1)
      );
      formattedData.machine_performance_percent = parseFloat(
        formattedData.machine_performance_percent.toFixed(1)
      );
      formattedData.quality_percent = parseFloat(
        formattedData.quality_percent.toFixed(1)
      );

      setOeeDataList(formattedData);
    } catch (error) {
      setOeeDataList({
        machine_availability_percent: "No Data",
        machine_performance_percent: "No Data",
        quality_percent: "No Data",
      });
    }
  };

  ///////// OEE TrendLine ///////////////////////////////////////////////////////////////////////////////////

  const [OEETrendLineChartData, setOEETrendLineChartData] =
    React.useState("data1");
  const [xAxisDataValueOEETrendLine, setXAxisDataValueOEETrendLine] = useState(
    []
  );
  const [plotPointOEETrendLine, setPlotPointOEETrendLine] = useState([]);
  const datasetOEETrendLine = [
    {
      label: "OEE TrendLine",
      data: plotPointOEETrendLine,
      borderColor: "#1F8EF1",
    },
  ];

  useEffect(() => {
    GraphDataListOEETrendLine();
  }, [OEETrendLineChartData]);
  const GraphDataListOEETrendLine = async () => {
    if (OEETrendLineChartData === "data1") {
      try {
        const data = await QueryApi(`
            query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
             dm_org_tenant_hourly(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                    oee
                    timestamp
                }
            }
          `);
        setXAxisDataValueOEETrendLine([
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
          20, 21, 22, 23, 24,
        ]);
        const oeeDatalistinfo = data.dm_org_tenant_hourly.map((data) => {
          const objData = {
            point: data.oee,
            Xaxis: data.timestamp,
          };
          return objData;
        });
        const pointDate = new Date();
        const resPointDate = pointDate.getHours();
        let plotValue = [];
        for (var i = 0; i <= resPointDate; i++) {
          plotValue[i] = 0;
        }
        oeeDatalistinfo.forEach((data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
          if (h >= 0 && h <= 1) {
            plotValue[0] = data.point;
          } else if (h >= 1 && h <= 2) {
            plotValue[1] = data.point;
          } else if (h >= 2 && h <= 3) {
            plotValue[2] = data.point;
          } else if (h >= 3 && h <= 4) {
            plotValue[3] = data.point;
          } else if (h >= 4 && h <= 5) {
            plotValue[4] = data.point;
          } else if (h >= 5 && h <= 6) {
            plotValue[5] = data.point;
          } else if (h >= 6 && h <= 7) {
            plotValue[6] = data.point;
          } else if (h >= 7 && h <= 8) {
            plotValue[7] = data.point;
          } else if (h >= 8 && h <= 9) {
            plotValue[8] = data.point;
          } else if (h >= 9 && h <= 10) {
            plotValue[9] = data.point;
          } else if (h >= 10 && h <= 11) {
            plotValue[10] = data.point;
          } else if (h >= 11 && h <= 12) {
            plotValue[11] = data.point;
          } else if (h >= 12 && h <= 13) {
            plotValue[12] = data.point;
          } else if (h >= 13 && h <= 14) {
            plotValue[13] = data.point;
          } else if (h >= 14 && h <= 15) {
            plotValue[14] = data.point;
          } else if (h >= 15 && h <= 16) {
            plotValue[15] = data.point;
          } else if (h >= 16 && h <= 17) {
            plotValue[16] = data.point;
          } else if (h >= 17 && h <= 18) {
            plotValue[17] = data.point;
          } else if (h >= 18 && h <= 19) {
            plotValue[18] = data.point;
          } else if (h >= 19 && h <= 20) {
            plotValue[19] = data.point;
          } else if (h >= 20 && h <= 21) {
            plotValue[20] = data.point;
          } else if (h >= 21 && h <= 22) {
            plotValue[21] = data.point;
          } else if (h >= 22 && h <= 23) {
            plotValue[22] = data.point;
          } else if (h >= 23 && h <= 24) {
            plotValue[23] = data.point;
          } else {
            return null;
          }
        });
        setPlotPointOEETrendLine(plotValue);
      } catch (error) {
        setXAxisDataValueOEETrendLine([]);
        setPlotPointOEETrendLine([]);
      }
    } else if (OEETrendLineChartData === "data2") {
      try {
        const data = await QueryApi(`
            query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                dm_org_tenant_daily(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                    oee
                    timestamp
                }
            }
          `);
        const plotAllDataOEETrendLine = data.dm_org_tenant_daily.map((data) => {
          const objData = {
            point: data.oee,
            Xaxis: data.timestamp,
          };
          return objData;
        });
        function getPlotAllDaysBetween(startDate, endDate) {
          var newDate = new Date(startDate);
          var weekDATE = new Date(endDate);
          var days = [];
          while (newDate <= weekDATE) {
            const dd = newDate.getDate();
            const mm = newDate.getMonth() + 1;
            const yy = newDate.getFullYear();
            const formattedd = dd < 10 ? `0${dd}` : dd;
            const formattedmm = mm < 10 ? `0${mm}` : mm;
            const fullDATE = `${yearYear}-${formattedmm}-${formattedd}`;
            days.push(fullDATE);
            newDate.setDate(newDate.getDate() + 1);
          }
          return days;
        }
        var daysBetween = getPlotAllDaysBetween(weekDataDate, currentDate);
        var pointArr = [];
        daysBetween.map((data) => {
          var arrlet = plotAllDataOEETrendLine.map((data) => {
            var arrDate = new Date(data.Xaxis);
            let nday = arrDate.getUTCDate();
            let nmonth = arrDate.getMonth() + 1;
            let nyear = arrDate.getFullYear();
            const ncurrDay = nday < 10 ? `0${nday}` : nday;
            const ncurrMonth = nmonth < 10 ? `0${nmonth}` : nmonth;
            let nDateList = `${nyear}-${ncurrMonth}-${ncurrDay}`;
            return nDateList;
          });
          const res = arrlet.includes(data);
          const resindex = arrlet.indexOf(data);
          if (res === true) {
            pointArr.push(plotAllDataOEETrendLine[resindex].point);
          } else {
            pointArr.push(0);
          }
        });
        setPlotPointOEETrendLine(pointArr);
        function getAllDaysBetween(startDate, endDate) {
          var currentDate = new Date(startDate);
          var days = [];
          while (currentDate <= endDate) {
            days.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
          }
          return days;
        }
        function getDayOfWeek(date) {
          var days = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];
          return days[date.getDay()];
        }
        function getDaysOfWeekBetween(startDate, endDate) {
          var daysBetween = getAllDaysBetween(startDate, endDate);
          var daysOfWeek = [];
          for (var i = 0; i < daysBetween.length; i++) {
            var dayOfWeek = getDayOfWeek(daysBetween[i]);
            daysOfWeek.push(dayOfWeek);
          }
          return daysOfWeek;
        }
        var daysOfWeekBetween = getDaysOfWeekBetween(
          new Date(weekDataDate),
          new Date(currentDate)
        );
        setXAxisDataValueOEETrendLine(daysOfWeekBetween);
      } catch (error) {}
    } else if (OEETrendLineChartData === "data3") {
      try {
        const data = await QueryApi(`
        query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_org_tenant_daily(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              oee
              date
            }
        }
    `);
        const plotAllDataProductionVolume = data.dm_org_tenant_daily.map(
          (data) => {
            const objData = {
              point: data.oee,
              Xaxis: data.date,
            };
            return objData;
          }
        );

        const bothAxisPoint = [];
        for (var i = 1; i <= 31; i++) {
          bothAxisPoint[i] = { pointValue: 0, XaxisValue: i };
        }
        plotAllDataProductionVolume.forEach((data) => {
          const allDate = new Date(data.Xaxis);
          const dayss = allDate.getDate();
          bothAxisPoint[dayss] = { pointValue: data.point, XaxisValue: dayss };
        });
        const NewPointData = bothAxisPoint.map((data) => data.pointValue);
        const dd = new Date();
        NewPointData.splice(dd.getDate() + 1, NewPointData.length);
        const NewXaxisData = bothAxisPoint.map((data) => data.XaxisValue);
        setPlotPointOEETrendLine(NewPointData);
        setXAxisDataValueOEETrendLine(NewXaxisData);
      } catch (error) {}
    } else if (OEETrendLineChartData === "data4") {
      try {
        const data = await QueryApi(`
            query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
              dm_org_tenant_monthly(where: {start_date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                 actual_cycletime
                 start_date
            }
          }
          `);
        const plotAllDataOEETrendLine = data.dm_org_tenant_monthly.map(
          (data) => {
            const objData = {
              point: data.actual_cycletime,
              Xaxis: data.start_date,
            };
            return objData;
          }
        );

        const plotPointDataOEETrendLine = plotAllDataOEETrendLine.map(
          (data) => data.point
        );
        setPlotPointOEETrendLine(plotPointDataOEETrendLine);
        setXAxisDataValueOEETrendLine([
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ]);
      } catch (error) {}
    } else {
      setOEETrendLineChartData("data1");
    }
  };

  ///////// OLE TrendLine-Workforce Efficiency ///////////////////////////////////////////////////////////////////////////////////

  const [WorkforceEfficiencyChartData, setWorkforceEfficiencyChartData] =
    React.useState("data1");
  const [
    xAxisDataValueWorkforceEfficiency,
    setXAxisDataValueWorkforceEfficiency,
  ] = useState([]);
  const [plotPointWorkforceEfficiency, setPlotPointWorkforceEfficiency] =
    useState([]);
  const datasetWorkforceEfficiency = [
    {
      label: "OLE TrendLine-Workforce Efficiency",
      data: plotPointWorkforceEfficiency,
      borderColor: "#1F8EF1",
    },
  ];

  useEffect(() => {
    GraphDataListWorkforceEfficiency();
  }, [WorkforceEfficiencyChartData]);
  const GraphDataListWorkforceEfficiency = async () => {
    if (WorkforceEfficiencyChartData === "data1") {
      try {
        const data = await QueryApi(`
            query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
             wfl0_workforce_efficiency_daily(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                   overall_labor_effectiveness
                    timestamp
                }
            }
          `);

        setXAxisDataValueWorkforceEfficiency([
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
          20, 21, 22, 23, 24,
        ]);
        const ValueWorkforceEfficiency =
          data.wfl0_workforce_efficiency_daily.map((data) => {
            const objData = {
              // point: (data.total_parts_produced / (1000 * 60 * 60)).toFixed(2),
              point: data.overall_labor_effectiveness,
              Xaxis: data.timestamp,
            };
            return objData;
          });
        const pointDate = new Date();
        const resPointDate = pointDate.getHours();
        let plotValue = [];
        for (var i = 0; i <= resPointDate; i++) {
          plotValue[i] = 0;
        }
        ValueWorkforceEfficiency.forEach((data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
          if (h >= 0 && h <= 1) {
            plotValue[0] = data.point;
          } else if (h >= 1 && h <= 2) {
            plotValue[1] = data.point;
          } else if (h >= 2 && h <= 3) {
            plotValue[2] = data.point;
          } else if (h >= 3 && h <= 4) {
            plotValue[3] = data.point;
          } else if (h >= 4 && h <= 5) {
            plotValue[4] = data.point;
          } else if (h >= 5 && h <= 6) {
            plotValue[5] = data.point;
          } else if (h >= 6 && h <= 7) {
            plotValue[6] = data.point;
          } else if (h >= 7 && h <= 8) {
            plotValue[7] = data.point;
          } else if (h >= 8 && h <= 9) {
            plotValue[8] = data.point;
          } else if (h >= 9 && h <= 10) {
            plotValue[9] = data.point;
          } else if (h >= 10 && h <= 11) {
            plotValue[10] = data.point;
          } else if (h >= 11 && h <= 12) {
            plotValue[11] = data.point;
          } else if (h >= 12 && h <= 13) {
            plotValue[12] = data.point;
          } else if (h >= 13 && h <= 14) {
            plotValue[13] = data.point;
          } else if (h >= 14 && h <= 15) {
            plotValue[14] = data.point;
          } else if (h >= 15 && h <= 16) {
            plotValue[15] = data.point;
          } else if (h >= 16 && h <= 17) {
            plotValue[16] = data.point;
          } else if (h >= 17 && h <= 18) {
            plotValue[17] = data.point;
          } else if (h >= 18 && h <= 19) {
            plotValue[18] = data.point;
          } else if (h >= 19 && h <= 20) {
            plotValue[19] = data.point;
          } else if (h >= 20 && h <= 21) {
            plotValue[20] = data.point;
          } else if (h >= 21 && h <= 22) {
            plotValue[21] = data.point;
          } else if (h >= 22 && h <= 23) {
            plotValue[22] = data.point;
          } else if (h >= 23 && h <= 24) {
            plotValue[23] = data.point;
          } else {
            return null;
          }
        });
        setPlotPointWorkforceEfficiency(plotValue);
      } catch (error) {
        setXAxisDataValueWorkforceEfficiency([]);
        setPlotPointWorkforceEfficiency([]);
      }
    } else if (WorkforceEfficiencyChartData === "data2") {
      try {
        const data = await QueryApi(`
            query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                wfl0_workforce_efficiency_daily(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                    overall_labor_effectiveness
                    timestamp
                }
            }
          `);
        const plotAllDataOEETrendLine =
          data.wfl0_workforce_efficiency_daily.map((data) => {
            const objData = {
              point: data.overall_labor_effectiveness,
              Xaxis: data.timestamp,
            };
            return objData;
          });
        function getPlotAllDaysBetween(startDate, endDate) {
          var newDate = new Date(startDate);
          var weekDATE = new Date(endDate);
          var days = [];
          while (newDate <= weekDATE) {
            const dd = newDate.getDate();
            const mm = newDate.getMonth() + 1;
            const yy = newDate.getFullYear();
            const formattedd = dd < 10 ? `0${dd}` : dd;
            const formattedmm = mm < 10 ? `0${mm}` : mm;
            const fullDATE = `${yearYear}-${formattedmm}-${formattedd}`;
            days.push(fullDATE);
            newDate.setDate(newDate.getDate() + 1);
          }
          return days;
        }
        var daysBetween = getPlotAllDaysBetween(weekDataDate, currentDate);
        var pointArr = [];
        daysBetween.map((data) => {
          var arrlet = plotAllDataOEETrendLine.map((data) => {
            var arrDate = new Date(data.Xaxis);
            let nday = arrDate.getUTCDate();
            let nmonth = arrDate.getMonth() + 1;
            let nyear = arrDate.getFullYear();
            const ncurrDay = nday < 10 ? `0${nday}` : nday;
            const ncurrMonth = nmonth < 10 ? `0${nmonth}` : nmonth;
            let nDateList = `${nyear}-${ncurrMonth}-${ncurrDay}`;
            return nDateList;
          });
          const res = arrlet.includes(data);
          const resindex = arrlet.indexOf(data);
          if (res === true) {
            pointArr.push(plotAllDataOEETrendLine[resindex].point);
          } else {
            pointArr.push(0);
          }
        });
        setPlotPointWorkforceEfficiency(pointArr);
        function getAllDaysBetween(startDate, endDate) {
          var currentDate = new Date(startDate);
          var days = [];
          while (currentDate <= endDate) {
            days.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
          }
          return days;
        }
        function getDayOfWeek(date) {
          var days = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];
          return days[date.getDay()];
        }
        function getDaysOfWeekBetween(startDate, endDate) {
          var daysBetween = getAllDaysBetween(startDate, endDate);
          var daysOfWeek = [];
          for (var i = 0; i < daysBetween.length; i++) {
            var dayOfWeek = getDayOfWeek(daysBetween[i]);
            daysOfWeek.push(dayOfWeek);
          }
          return daysOfWeek;
        }
        var daysOfWeekBetween = getDaysOfWeekBetween(
          new Date(weekDataDate),
          new Date(currentDate)
        );
        setXAxisDataValueWorkforceEfficiency(daysOfWeekBetween);
      } catch (error) {
        setXAxisDataValueWorkforceEfficiency([]);
        setPlotPointWorkforceEfficiency([]);
      }
    } else if (WorkforceEfficiencyChartData === "data3") {
      try {
        const data = await QueryApi(`
        query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            wfl0_workforce_efficiency_daily(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                overall_labor_effectiveness
                date
            }
          }
       `);
        const plotAllDataProductionVolume =
          data.wfl0_workforce_efficiency_daily.map((data) => {
            const objData = {
              point: data.overall_labor_effectiveness,
              Xaxis: data.date,
            };
            return objData;
          });
        const bothAxisPoint = [];
        for (var i = 1; i <= 31; i++) {
          bothAxisPoint[i] = { pointValue: 0, XaxisValue: i };
        }
        plotAllDataProductionVolume.forEach((data) => {
          const allDate = new Date(data.Xaxis);
          const dayss = allDate.getDate();
          bothAxisPoint[dayss] = { pointValue: data.point, XaxisValue: dayss };
        });
        const NewPointData = bothAxisPoint.map((data) => data.pointValue);
        const dd = new Date();
        NewPointData.splice(dd.getDate() + 1, NewPointData.length);
        const NewXaxisData = bothAxisPoint.map((data) => data.XaxisValue);
        setPlotPointWorkforceEfficiency(NewPointData);
        setXAxisDataValueWorkforceEfficiency(NewXaxisData);
      } catch (error) {
        setXAxisDataValueWorkforceEfficiency([]);
        setPlotPointWorkforceEfficiency([]);
      }
    } else if (WorkforceEfficiencyChartData === "data4") {
      try {
        const data = await QueryApi(`
            query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
              ole_tenant_monthly(where: {start_date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                overall_labor_effectiveness
                start_date
            }
          }
          `);

        const plotAllDataOEETrendLine = data.ole_tenant_monthly.map((data) => {
          const objData = {
            point: data.overall_labor_effectiveness,
            Xaxis: data.start_date,
          };
          return objData;
        });
        const plotPointDataOEETrendLine = plotAllDataOEETrendLine.map(
          (data) => data.point
        );
        setPlotPointWorkforceEfficiency(plotPointDataOEETrendLine);
        setXAxisDataValueWorkforceEfficiency([
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ]);
      } catch (error) {
        setXAxisDataValueWorkforceEfficiency([]);
        setPlotPointWorkforceEfficiency([]);
      }
    } else {
      setWorkforceEfficiencyChartData("data1");
    }
  };

  ///// Fully Productive Time ////////////////////////////////////////////////////////

  useEffect(() => {
    FullyProductiveTime();
  }, []);
  const FullyProductiveTime = async () => {
    try {
      const data = await QueryApi(`
      query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        fl2_oee_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
            aggregate {
              sum {
                fully_productive_time
              }
            }
          }
        } `);
      const dataValue = (
        data.fl2_oee_aggregate.aggregate.sum.fully_productive_time /
        (1000 * 60 * 60)
      ).toFixed(1);
      setFullyProductiveTimeList(dataValue);
    } catch (error) {
      setFullyProductiveTimeList(0);
    }
  };

  /////// Machine ///////////////////////////////////////////////////////////////////////////

  const [machineList, setMachineDataList] = useState([]);
  useEffect(() => {
    MachineData();
  }, []);
  const MachineData = async () => {
    try {
      const data = await QueryApi(`
       query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
           dm_daily(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
             machineid
             oee
             machine_availability_percent
             machine_performance_percent
             quality_percent
           }
         } `);
      setMachineDataList(data.dm_daily);
    } catch (error) {
      setMachineDataList(0);
    }
  };

  const [allMachineList, setAllMachineDataList] = useState([]);
  useEffect(() => {
    dataFirstCall();
  }, []);
  const dataFirstCall = async () => {
    try {
      const data = await QueryApi(`       
      query MyQuery($tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        tnt_org_machine_assignment(where: {tenantid: {_eq: $tenantid}}) {
          machineid
        }
      }
    `);
      setAllMachineDataList(data.tnt_org_machine_assignment);
    } catch (error) {
      setAllMachineDataList([]);
    }
  };

  const machineArr = allMachineList
    .filter(
      (data) => !machineList.some((dataM) => dataM.machineid === data.machineid)
    )
    .map((data) => data.machineid);

  let lastArr = machineList;
  machineArr.forEach((data, index) => {
    lastArr.push({
      machineid: machineArr[index],
      oee: "No Data",
      machine_availability_percent: "No Data",
      machine_performance_percent: "No Data",
      quality_percent: "No Data",
    });
  });

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="3">
            <Card
              style={{ fontSize: "16px", fontWeight: "bold", color: "white" }}
            >
              O.E.E – Level 1
            </Card>
          </Col>
        </Row>
        {/* this is 1st row  */}
        <Row>
          <Col sm="3">
            <Card style={{ height: "98%" }}>
              <CardHeader>
                <div className="icon-plus">
                  <a className="anchor-bg" href={`/admin/ifanalysis`}>
                    <FontAwesomeIcon icon={faArrowRightLong} />
                  </a>
                </div>
                <h5 className="card-category">Operational Efficiency</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-chart-pie text-info" />{" "}
                  {operationalEfficiency}%
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="gauge-container">
                  <div
                    ref={chartRef}
                    style={{
                      width: "100%",
                      height: "350px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </div>
                <div
                  className="percentage-circles-container"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div
                    style={{ cursor: "pointer" }}
                    className="percentage-circle-wrapper"
                    onClick={() => navigate("/admin/dashboard/productivity")}
                  >
                    <div className="percentage-circle-line"></div>
                    <PercentageCircle
                      percentage={OeeDataList.machine_performance_percent}
                      color="green"
                      size="30px"
                    />
                    <div className="percentage-circle-label">Performance</div>
                  </div>
                  <div style={{ width: "10%" }}></div>
                  <div
                    className="percentage-circle-wrapper"
                    style={{ textAlign: "center" }}
                  >
                    <div className="percentage-circle-line"></div>
                    <PercentageCircle
                      percentage={OeeDataList.machine_availability_percent}
                      color="blue"
                      size="30px"
                    />
                    <div className="percentage-circle-label">Availability</div>
                  </div>
                  <div style={{ width: "10%" }}></div>
                  <div
                    className="percentage-circle-wrapper"
                    style={{ textAlign: "center" }}
                  >
                    <div className="percentage-circle-line"></div>
                    <PercentageCircle
                      percentage={OeeDataList.quality_percent}
                      color="red"
                      size="30px"
                    />
                    <div className="percentage-circle-label">Quality</div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col
            sm="6"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.8rem",
              padding: "0",
              margin: "0",
              marginBottom: "0.8rem",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "0.8rem",
              }}
            >
              <div
                style={{
                  backgroundColor: "#27293D",
                  color: "white",
                  height: "12rem",
                  width: "100%",
                  borderRadius: "0.8rem",
                }}
              >
                <div style={{ margin: "1rem" }}>
                  <div
                    style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        height: "3rem",
                      }}
                    >
                      <div style={{ fontWeight: "550", fontSize: "1.1rem" }}>
                        Availability Lost Time
                      </div>
                      <div style={{ fontSize: "1.2rem" }}>
                        Org Running Average
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <img
                        src={availabilityLost}
                        style={{ height: "2.5rem", width: "3rem" }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                      height: "7rem",
                      marginTop: "0.5rem",
                      marginInline: "1rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontSize: "1.1rem" }}>Today</div>
                      <div style={{ fontSize: "1.1rem", color: "#1976D2" }}>
                        <span>{AvailabilityLostTimeDaily} Hrs</span>
                        <span style={{ marginLeft: "1rem" }}>
                          {AvailabilityLostTimeDaily >=
                          NewAvailabilityLostTimeDaily ? (
                            <img
                              src={upArrow}
                              style={{ height: "1.5rem", width: "2rem" }}
                            />
                          ) : AvailabilityLostTimeDaily <=
                            NewAvailabilityLostTimeDaily ? (
                            <img
                              src={downArrow}
                              style={{ height: "1.5rem", width: "2rem" }}
                            />
                          ) : (
                            <img
                              src={minusArrow}
                              style={{ height: "1.5rem", width: "2rem" }}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontSize: "1.1rem" }}>This Week</div>
                      <div style={{ fontSize: "1.1rem", color: "#1976D2" }}>
                        <span>{AvailabilityLostTimeWeek} Hrs</span>
                        <span style={{ marginLeft: "1rem" }}>
                          {AvailabilityLostTimeWeek >=
                          NewAvailabilityLostTimeWeek ? (
                            <img
                              src={upArrow}
                              style={{ height: "1.5rem", width: "2rem" }}
                            />
                          ) : AvailabilityLostTimeWeek <=
                            NewAvailabilityLostTimeWeek ? (
                            <img
                              src={downArrow}
                              style={{ height: "1.5rem", width: "2rem" }}
                            />
                          ) : (
                            <img
                              src={minusArrow}
                              style={{ height: "1.5rem", width: "2rem" }}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontSize: "1.1rem" }}>This Month</div>
                      <div style={{ fontSize: "1.1rem", color: "#1976D2" }}>
                        <span>{AvailabilityLostTimeMonth} Hrs</span>
                        <span style={{ marginLeft: "1rem" }}>
                          {AvailabilityLostTimeMonth >=
                          NewAvailabilityLostTimeMonth ? (
                            <img
                              src={upArrow}
                              style={{ height: "1.5rem", width: "2rem" }}
                            />
                          ) : AvailabilityLostTimeMonth <=
                            NewAvailabilityLostTimeMonth ? (
                            <img
                              src={downArrow}
                              style={{ height: "1.5rem", width: "2rem" }}
                            />
                          ) : (
                            <img
                              src={minusArrow}
                              style={{ height: "1.5rem", width: "2rem" }}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "#27293D",
                  color: "white",
                  height: "12rem",
                  width: "100%",
                  borderRadius: "0.8rem",
                }}
              >
                <div style={{ margin: "1rem" }}>
                  <div
                    style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        height: "3rem",
                      }}
                    >
                      <div style={{ fontWeight: "550", fontSize: "1.1rem" }}>
                        Cycle Time Loss
                      </div>
                      <div style={{ fontSize: "1.2rem" }}>
                        Org Running Average
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <img
                        src={cycleLost}
                        style={{ height: "2.5rem", width: "3rem" }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                      height: "7rem",
                      marginTop: "0.5rem",
                      marginInline: "1rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontSize: "1.1rem" }}>Today</div>
                      <div style={{ fontSize: "1.1rem", color: "#1976D2" }}>
                        No Data
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontSize: "1.1rem" }}>This Week</div>
                      <div style={{ fontSize: "1.1rem", color: "#1976D2" }}>
                        No Data
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontSize: "1.1rem" }}>This Month</div>
                      <div style={{ fontSize: "1.1rem", color: "#1976D2" }}>
                        No Data
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "0.8rem",
              }}
            >
              <div
                style={{
                  backgroundColor: "#27293D",
                  color: "white",
                  height: "12rem",
                  width: "100%",
                  borderRadius: "0.8rem",
                }}
              >
                <div style={{ margin: "1rem" }}>
                  <div
                    style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        height: "3rem",
                      }}
                    >
                      <div style={{ fontWeight: "550", fontSize: "1.1rem" }}>
                        Production Rejection
                      </div>
                      <div style={{ fontSize: "1.2rem" }}>
                        Org Running Average
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <img
                        src={ProjectionRejection}
                        style={{ height: "2.5rem", width: "3rem" }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                      height: "7rem",
                      marginTop: "0.5rem",
                      marginInline: "1rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontSize: "1.1rem" }}>Today</div>
                      <div style={{ fontSize: "1.1rem", color: "#1976D2" }}>
                        No Data
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontSize: "1.1rem" }}>This Week</div>
                      <div style={{ fontSize: "1.1rem", color: "#1976D2" }}>
                        No Data
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontSize: "1.1rem" }}>This Month</div>
                      <div style={{ fontSize: "1.1rem", color: "#1976D2" }}>
                        No Data
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "#27293D",
                  color: "white",
                  height: "12rem",
                  width: "100%",
                  borderRadius: "0.8rem",
                }}
              >
                <div style={{ margin: "1rem" }}>
                  <div
                    style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        height: "3rem",
                      }}
                    >
                      <div style={{ fontWeight: "550", fontSize: "1.1rem" }}>
                        Down Time
                      </div>
                      <div style={{ fontSize: "1.2rem" }}>
                        Org Running Average
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <img
                        src={downTime}
                        style={{ height: "2.5rem", width: "3rem" }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                      height: "7rem",
                      marginTop: "0.5rem",
                      marginInline: "1rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontSize: "1.1rem" }}>Today</div>
                      <div style={{ fontSize: "1.1rem", color: "#1976D2" }}>
                        <span>{DownTimeDaily} Hrs</span>
                        <span style={{ marginLeft: "1rem" }}>
                          {DownTimeDaily >= NewDownTimeDaily ? (
                            <img
                              src={upArrow}
                              style={{ height: "1.5rem", width: "2rem" }}
                            />
                          ) : DownTimeDaily <= NewDownTimeDaily ? (
                            <img
                              src={downArrow}
                              style={{ height: "1.5rem", width: "2rem" }}
                            />
                          ) : (
                            <img
                              src={minusArrow}
                              style={{ height: "1.5rem", width: "2rem" }}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontSize: "1.1rem" }}>This Week</div>
                      <div style={{ fontSize: "1.1rem", color: "#1976D2" }}>
                        <span>{DownTimeWeek} Hrs</span>
                        <span style={{ marginLeft: "1rem" }}>
                          {DownTimeWeek >= NewDownTimeWeek ? (
                            <img
                              src={upArrow}
                              style={{ height: "1.5rem", width: "2rem" }}
                            />
                          ) : DownTimeWeek <= NewDownTimeWeek ? (
                            <img
                              src={downArrow}
                              style={{ height: "1.5rem", width: "2rem" }}
                            />
                          ) : (
                            <img
                              src={minusArrow}
                              style={{ height: "1.5rem", width: "2rem" }}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontSize: "1.1rem" }}>This Month</div>
                      <div style={{ fontSize: "1.1rem", color: "#1976D2" }}>
                        <span>{DownTimeMonth} Hrs</span>
                        <span style={{ marginLeft: "1rem" }}>
                          {DownTimeMonth >= NewDownTimeMonth ? (
                            <img
                              src={upArrow}
                              style={{ height: "1.5rem", width: "2rem" }}
                            />
                          ) : DownTimeMonth <= NewDownTimeMonth ? (
                            <img
                              src={downArrow}
                              style={{ height: "1.5rem", width: "2rem" }}
                            />
                          ) : (
                            <img
                              src={minusArrow}
                              style={{ height: "1.5rem", width: "2rem" }}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "0.8rem",
              }}
            >
              <div
                style={{
                  backgroundColor: "#27293D",
                  color: "white",
                  height: "12rem",
                  width: "100%",
                  borderRadius: "0.8rem",
                }}
              >
                <div style={{ margin: "1rem" }}>
                  <div
                    style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        height: "3rem",
                      }}
                    >
                      <div style={{ fontWeight: "550", fontSize: "1.1rem" }}>
                        Idle Time Lost
                      </div>
                      <div style={{ fontSize: "1.2rem" }}>
                        Org Running Average
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <img
                        src={IdlelostTime}
                        style={{ height: "2.5rem", width: "3rem" }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                      height: "7rem",
                      marginTop: "0.5rem",
                      marginInline: "1rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontSize: "1.1rem" }}>Today</div>
                      <div style={{ fontSize: "1.1rem", color: "#1976D2" }}>
                        <span>{IdleTimeLostDaily} Hrs</span>
                        <span style={{ marginLeft: "1rem" }}>
                          {IdleTimeLostDaily >= NewIdleTimeLostDaily ? (
                            <img
                              src={upArrow}
                              style={{ height: "1.5rem", width: "2rem" }}
                            />
                          ) : IdleTimeLostDaily <= NewIdleTimeLostDaily ? (
                            <img
                              src={downArrow}
                              style={{ height: "1.5rem", width: "2rem" }}
                            />
                          ) : (
                            <img
                              src={minusArrow}
                              style={{ height: "1.5rem", width: "2rem" }}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontSize: "1.1rem" }}>This Week</div>
                      <div style={{ fontSize: "1.1rem", color: "#1976D2" }}>
                        <span>{IdleTimeLostWeek} Hrs</span>
                        <span style={{ marginLeft: "1rem" }}>
                          {IdleTimeLostWeek >= NewIdleTimeLostWeek ? (
                            <img
                              src={upArrow}
                              style={{ height: "1.5rem", width: "2rem" }}
                            />
                          ) : IdleTimeLostWeek <= NewIdleTimeLostWeek ? (
                            <img
                              src={downArrow}
                              style={{ height: "1.5rem", width: "2rem" }}
                            />
                          ) : (
                            <img
                              src={minusArrow}
                              style={{ height: "1.5rem", width: "2rem" }}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontSize: "1.1rem" }}>This Month</div>
                      <div style={{ fontSize: "1.1rem", color: "#1976D2" }}>
                        <span>{IdleTimeLostMonth} Hrs</span>
                        <span style={{ marginLeft: "1rem" }}>
                          {IdleTimeLostMonth >= NewIdleTimeLostMonth ? (
                            <img
                              src={upArrow}
                              style={{ height: "1.5rem", width: "2rem" }}
                            />
                          ) : IdleTimeLostMonth <= NewIdleTimeLostMonth ? (
                            <img
                              src={downArrow}
                              style={{ height: "1.5rem", width: "2rem" }}
                            />
                          ) : (
                            <img
                              src={minusArrow}
                              style={{ height: "1.5rem", width: "2rem" }}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "#27293D",
                  color: "white",
                  height: "12rem",
                  width: "100%",
                  borderRadius: "0.8rem",
                }}
              >
                <div style={{ margin: "1rem" }}>
                  <div
                    style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        height: "3rem",
                      }}
                    >
                      <div style={{ fontWeight: "550", fontSize: "1.1rem" }}>
                        Production Yield
                      </div>
                      <div style={{ fontSize: "1.2rem" }}>
                        Org Running Average
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <img
                        src={productionYeild}
                        style={{ height: "2.5rem", width: "3rem" }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                      height: "7rem",
                      marginTop: "0.5rem",
                      marginInline: "1rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontSize: "1.1rem" }}>Today</div>
                      <div style={{ fontSize: "1.1rem", color: "#1976D2" }}>
                        No Data
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontSize: "1.1rem" }}>This Week</div>
                      <div style={{ fontSize: "1.1rem", color: "#1976D2" }}>
                        No Data
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontSize: "1.1rem" }}>This Month</div>
                      <div style={{ fontSize: "1.1rem", color: "#1976D2" }}>
                        No Data
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col sm="3">
            <Card className="card-chart" style={{ height: "98%" }}>
              <CardHeader>
                <div className="icon-plus">
                  <a className="anchor-bg" href={""}>
                    <FontAwesomeIcon icon={faArrowRightLong} />
                  </a>
                  {/* <NavLink className="anchor-bg" href={`/admin/dashboard/oee`}>
                        <FontAwesomeIcon icon={faArrowRightLong} />
                      </NavLink> */}
                </div>
                <h5 className="card-category">Fully Productive Time</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-chart-pie text-info" />{" "}
                  {FullyProductiveTimeList} Hours
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="gauge-container">
                  <div
                    ref={chartRef1}
                    style={{
                      width: "100%",
                      height: "350px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <Card className="card-chart" style={{ height: "336px" }}>
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5 className="card-category">OEE TrendLine</h5>
                  </Col>
                  <Col sm="6">
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <Button
                        tag="label"
                        className={classNames("btn-simple", {
                          active: OEETrendLineChartData === "data1",
                        })}
                        color="info"
                        id="0"
                        size="sm"
                        onClick={() => setOEETrendLineChartData("data1")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Day
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-single-02" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: OEETrendLineChartData === "data2",
                        })}
                        onClick={() => setOEETrendLineChartData("data2")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Week
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-gift-2" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: OEETrendLineChartData === "data3",
                        })}
                        onClick={() => setOEETrendLineChartData("data3")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Month
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-tap-02" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: OEETrendLineChartData === "data4",
                        })}
                        onClick={() => setOEETrendLineChartData("data4")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Year
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-tap-02" />
                        </span>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <LineChart
                  xAxisData={xAxisDataValueOEETrendLine}
                  dataset={datasetOEETrendLine}
                  height="100%"
                  width="100%"
                />
              </CardBody>
            </Card>
          </Col>
          <Col lg="6">
            <Card className="card-chart" style={{ height: "336px" }}>
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5 className="card-category">
                      OLE TrendLine-Workforce Efficiency
                    </h5>
                  </Col>
                  <Col sm="6">
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <Button
                        tag="label"
                        className={classNames("btn-simple", {
                          active: WorkforceEfficiencyChartData === "data1",
                        })}
                        color="info"
                        id="0"
                        size="sm"
                        onClick={() => setWorkforceEfficiencyChartData("data1")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Day
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-single-02" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: WorkforceEfficiencyChartData === "data2",
                        })}
                        onClick={() => setWorkforceEfficiencyChartData("data2")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Week
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-gift-2" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: WorkforceEfficiencyChartData === "data3",
                        })}
                        onClick={() => setWorkforceEfficiencyChartData("data3")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Month
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-tap-02" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: WorkforceEfficiencyChartData === "data4",
                        })}
                        onClick={() => setWorkforceEfficiencyChartData("data4")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Year
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-tap-02" />
                        </span>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <LineChart
                  xAxisData={xAxisDataValueWorkforceEfficiency}
                  dataset={datasetWorkforceEfficiency}
                  height="100%"
                  width="100%"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row
        // style={{ marginTop: '-22rem' }}
        >
          <Col lg="6">
            <Card className="card-workforce">
              <CardHeader>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    background: "#1976D2",
                    height: "2rem",
                  }}
                >
                  <div
                    style={{
                      color: "white",
                      width: "13rem",
                      textAlign: "center",
                    }}
                  >
                    {machineList[0]?.machineid}
                  </div>
                  <div
                    style={{
                      color: "white",
                      width: "13rem",
                      textAlign: "center",
                    }}
                  >
                    {machineList[1]?.machineid}
                  </div>
                  <div
                    style={{
                      color: "white",
                      width: "13rem",
                      textAlign: "center",
                    }}
                  >
                    {machineList[2]?.machineid}
                  </div>
                </Row>
                <div
                  style={{
                    position: "absolute",
                    right: "0",
                    marginTop: "-2rem",
                    marginRight: "1rem",
                  }}
                >
                  <div className="icon-plus">
                    <a className="anchor-bg" href={`/admin/oee-machines`}>
                      <FontAwesomeIcon icon={faArrowRightLong} />
                    </a>
                  </div>
                </div>
              </CardHeader>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div>
                  <div
                    style={{
                      height: "9.5rem",
                      width: "13rem",
                      margin: "0rem 0rem 0rem 0rem",
                      display: "grid",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#313745",
                        borderRadius: "1rem",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            marginTop: "10px",
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          OEE{" "}
                          <div style={{ color: "white" }}>
                            {machineList[0]?.oee
                              ? machineList[0]?.oee !== "No Data"
                                ? machineList[0]?.oee + " %"
                                : "No Data"
                              : "No Data"}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          margin: "10px 1rem 0rem 1rem",
                          color: "white",
                        }}
                      >
                        <spam>Availability</spam>
                        <spam style={{ fontWeight: "bold", color: "white" }}>
                          {machineList[0]?.machine_availability_percent
                            ? machineList[0]?.machine_availability_percent !==
                              "No Data"
                              ? machineList[0]?.machine_availability_percent +
                                " %"
                              : "No Data"
                            : "No Data"}
                        </spam>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          paddingInline: "1rem",
                          color: "white",
                        }}
                      >
                        <spam>Performance</spam>
                        <spam style={{ fontWeight: "bold", color: "white" }}>
                          {machineList[0]?.machine_performance_percent
                            ? machineList[0]?.machine_performance_percent !==
                              "No Data"
                              ? machineList[0]?.machine_performance_percent +
                                " %"
                              : "No Data"
                            : "No Data"}
                        </spam>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          paddingInline: "1rem",
                          color: "white",
                        }}
                      >
                        <spam>Quality</spam>
                        <spam style={{ fontWeight: "bold", color: "white" }}>
                          {machineList[0]?.quality_percent
                            ? machineList[0]?.quality_percent !== "No Data"
                              ? machineList[0]?.quality_percent + "%"
                              : "No Data"
                            : "No Data"}
                        </spam>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      height: "9.5rem",
                      width: "13rem",
                      margin: "0rem 0rem 0rem 0rem",
                      display: "grid",
                      // border: "1px solid rgb(237,237,237)",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#313745",
                        borderRadius: "1rem",
                        // border: "1px solid rgb(252,215,108)",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            marginTop: "10px",
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          OEE
                        </div>
                        <div style={{ color: "white" }}>
                          {machineList[1]?.oee
                            ? machineList[1]?.oee !== "No Data"
                              ? machineList[1]?.oee + " %"
                              : "No Data"
                            : "No Data"}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          margin: "10px 1rem 0rem 1rem",
                          color: "white",
                        }}
                      >
                        <spam>Availability</spam>
                        <spam style={{ fontWeight: "bold", color: "white" }}>
                          {machineList[1]?.machine_availability_percent
                            ? machineList[1]?.machine_availability_percent !==
                              "No Data"
                              ? machineList[1]?.machine_availability_percent +
                                " %"
                              : "No Data"
                            : "No Data"}
                        </spam>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          paddingInline: "1rem",
                          color: "white",
                        }}
                      >
                        <spam>Performance</spam>
                        <spam style={{ fontWeight: "bold", color: "white" }}>
                          {machineList[1]?.machine_performance_percent
                            ? machineList[1]?.machine_performance_percent !==
                              "No Data"
                              ? machineList[1]?.machine_performance_percent +
                                " %"
                              : "No Data"
                            : "No Data"}
                        </spam>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          paddingInline: "1rem",
                          color: "white",
                        }}
                      >
                        <spam>Quality</spam>
                        <spam style={{ fontWeight: "bold", color: "white" }}>
                          {machineList[1]?.quality_percent
                            ? machineList[1]?.quality_percent !== "No Data"
                              ? machineList[1]?.quality_percent + "%"
                              : "No Data"
                            : "No Data"}
                        </spam>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      height: "9.5rem",
                      width: "13rem",
                      margin: "0rem 0rem 0rem 0rem",
                      display: "grid",
                      // border: "1px solid rgb(237,237,237)",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#313745",
                        borderRadius: "1rem",
                        // border: "1px solid rgb(252,215,108)",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            marginTop: "10px",
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          OEE
                        </div>
                        <div style={{ color: "white" }}>
                          {machineList[2]?.oee
                            ? machineList[2]?.oee !== "No Data"
                              ? machineList[2]?.oee + " %"
                              : "No Data"
                            : "No Data"}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          margin: "10px 1rem 0rem 1rem",
                          color: "white",
                        }}
                      >
                        <spam>Availability</spam>
                        <spam style={{ fontWeight: "bold", color: "white" }}>
                          {machineList[2]?.machine_availability_percent
                            ? machineList[2]?.machine_availability_percent !==
                              "No Data"
                              ? machineList[2]?.machine_availability_percent +
                                " %"
                              : "No Data"
                            : "No Data"}
                        </spam>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          paddingInline: "1rem",
                          color: "white",
                        }}
                      >
                        <spam>Performance</spam>
                        <spam style={{ fontWeight: "bold", color: "white" }}>
                          {machineList[2]?.machine_performance_percent
                            ? machineList[2]?.machine_performance_percent !==
                              "No Data"
                              ? machineList[2]?.machine_performance_percent +
                                " %"
                              : "No Data"
                            : "No Data"}
                        </spam>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          paddingInline: "1rem",
                          color: "white",
                        }}
                      >
                        <spam>Quality</spam>
                        <spam style={{ fontWeight: "bold", color: "white" }}>
                          {machineList[2]?.quality_percent
                            ? machineList[2]?.quality_percent !== "No Data"
                              ? machineList[2]?.quality_percent + "%"
                              : "No Data"
                            : "No Data"}
                        </spam>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col lg="6">
            <Card className="card-workforce">
              <CardHeader>
                <div className="icon-plus">
                  <a className="anchor-bg" href={""}>
                    <FontAwesomeIcon icon={faArrowRightLong} />
                  </a>
                  {/* <NavLink className="anchor-bg" href={`/admin/dashboard/oee`}>
                        <FontAwesomeIcon icon={faArrowRightLong} />
                      </NavLink> */}
                </div>
                <h5 className="card-category">
                  Machine Wise OEE Performance Dashboard
                </h5>
              </CardHeader>
            </Card>
            <Card className="card-chart">
              <CardBody>
                <div className="workforce-by-machine">
                  <MachineWiseOEE />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default OeeLevelOne;
