import React, { useState, useEffect } from "react";
import redirectToLoginIfTokenMissing from "views/TokenUtility";
import { TabContent, TabPane, Card, CardBody, Row, Col } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { Badge } from "reactstrap";
import { QueryApi } from "QueryApi.js";

function OleOperatorWise() {
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const currDay = day < 10 ? `0${day}` : day;
  const currMonth = month < 10 ? `0${month}` : month;
  let currentDate = JSON.stringify(`${year}-${currMonth}-${currDay}`);

  const [activeTab, setActiveTab] = useState(1);
  const [employees, setEmployees] = useState([]);
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    redirectToLoginIfTokenMissing();
  }, []);

  function handleEdit(row) {}
  const activeFormatter = (cell, row) => {
    const isActive = row.is_active === "1";

    return (
      <Badge color={isActive ? "info" : "danger"}>
        {isActive ? "Active" : "Inactive"}
      </Badge>
    );
  };

  const [apiData, setApiData] = useState([]);

  const fetchDataList = async () => {
    try {
      const data = await QueryApi(`
        query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
          wfl0_overall_labor_effectiveness(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
            org_id
            unit_id
            operator_id
            ideal_ole
            overall_labor_effectiveness
            ole_deviation
            Org {
              organization_node_name
            }
            Plant {
              organization_node_name
            }
            operator {
              first_name
              last_name
            }
          }
        } 
     `);
      setApiData(data?.wfl0_overall_labor_effectiveness);
    } catch (error) {
      setApiData([]);
    }
  };

  useEffect(() => {
    fetchDataList();
  }, []);

  const columns = [
    {
      dataField: "",
      text: "Sr.No",
      headerStyle: { width: "500px", border: "1px solid #ddd" },
      formatter: (cell, row, rowIndex) => rowIndex + 1,
    },
    {
      dataField: "Org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px", border: "1px solid #ddd" },
    },
    {
      dataField: "Plant.organization_node_name",
      text: "Plant/Unit",
      headerStyle: { width: "500px", border: "1px solid #ddd" },
    },
    {
      dataField: "operator",
      text: "Operator",
      formatter: (cell, row) =>
        `${row.operator.first_name} ${row.operator.last_name}`,
      headerStyle: { width: "500px", border: "1px solid #ddd" },
    },
    {
      dataField: "ideal_ole",
      text: "Idle OLE %",
      headerStyle: { width: "500px", border: "1px solid #ddd" },
    },
    {
      dataField: "overall_labor_effectiveness",
      text: "Actual OLE %",
      headerStyle: { width: "500px", border: "1px solid #ddd" },
    },
    {
      dataField: "ole_deviation",
      text: "Deviation",
      headerStyle: { width: "500px", border: "1px solid #ddd" },
    },
    {
      dataField: "",
      text: "Classification",
      headerStyle: { width: "500px", border: "1px solid #ddd" },
    },
  ];

  return (
    <div className="content">
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={employees}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <div>
                        <BootstrapTable
                          {...props.baseProps}
                          filter={filterFactory()}
                          pagination={paginationFactory()}
                          data={apiData || []}
                          headerClasses="sorted-asc sorted-desc"
                          wrapperClasses="table-responsive"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default OleOperatorWise;
