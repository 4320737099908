import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Progress,
} from "reactstrap";
import axios from "axios";
import { APIURL } from "./Apiurl.js";
import { toast } from "react-toastify";
import DynamicTable from "../components/DynamicTable/Dynamictable.js";
import "../components/DynamicTable/Dynamictable.css";

function ProductionAdd({ handleClose, fetchData }) {
  const plannedQuantityUOMOptions = [
    "No. of parts",
    "No. of batches",
    "tonnage processed",
  ];
  const targetUOMOptions = [
    "Strokes per Minute",
    "Parts per Minute",
    "Parts per Hour",
    "Parts per Day",
    "Batches Completed",
    "Batches per Day",
    "No. of Parts (in unit)",
    "Cycle time in seconds",
    "Cycle time in Minutes",
    "Cycle time in hours",
  ];
  const dynamicTableRef = useRef();
  const [activeStep, setActiveStep] = useState(1);
  const [progress, setProgress] = useState(0);
  const [tenantId, setTenantId] = useState("");
  const [plan_id, setPlan_id] = useState("");
  const [production_date, setProductionDate] = useState("");
  const [production_part_no, setProductionPartNo] = useState([]);
  const [part_no, setPartNo] = useState("");
  const [endcustname, setEndCustName] = useState("");
  const [production_operation_details, setProductionOperationDetails] =
    useState("");
  const [production_mahine_no, setProductionMachineNo] = useState([]);
  const [machineno, setMachineNo] = useState("");
  const [machinename, setMachineName] = useState("");
  const [operator_name, setOperatorName] = useState("");
  const [operator_emp_code, setOperatorEmpCode] = useState("");
  const [shift_name, setShiftName] = useState([]);
  const [shift, setshift] = useState("");
  const [production_starttime, setProductionStartTime] = useState("");
  const [target, setTargetSpm] = useState("");
  const [planned_quantity, setPlannedQuantity] = useState("");
  const [noofbatches, setNOBatches] = useState(null);
  const [required_time, setRequiredTime] = useState("");
  const [remark, setRemark] = useState(null);
  const [tenants, setTenants] = useState([]);
  const [plannedquantity_uam, setPlannedQuantity_Uam] = useState("");
  const [target_uom, setTarget_Uom] = useState("");
  const [isLastStep, setIsLastStep] = useState(false);
  const [isCardOpen, setIsCardOpen] = useState(true);
  const [balanceScheduleQty, setBalanceScheduleQty] = useState("");
  const [actualRequiredQty, setActualRequiredQty] = useState("");
  const [availableWIP, setAvailableWIP] = useState("");
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    fetchTenants();
    fetchrequired_time();
    fetchpartno();
    fetchshiftname();
    fetchmachineno();
  }, []);

  const handleButtonClick = () => {
    if (dynamicTableRef.current) {
      dynamicTableRef.current.handleFormSubmit1();
    }
  };
  const fetchrequired_time = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const roleId = decodedToken["role_id"];
      const tenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-tenant-id"];
      const storedTenantId = sessionStorage.getItem("selectedTenantId");
      let query = "";
      let variables = {};
      if (roleId === 1) {
        if (storedTenantId !== null) {
          query = `
          query ($tenantId: Int!) {
            tnt_production_plan_master (
              where: { tenant_id: { _eq: $tenantId } }
            ) {
              date
              actual_quantity_produced
              planid
              planned_quantity
              shift_id
              tenantid
              actual_starttime
              actual_time_taken
              end_customer
              machineid
              machine_name
              operation_details
              partno
              planned_quantity_uom
              remarks
              required_time_ideal
              shiftname
              target
              target_uom
              production_starttime_desired
              batch_start_time
            }
          }
        `;
          variables = { tenantId: storedTenantId };
        } else {
          query = `
            query {
              tnt_production_plan_master {
                date
                actual_quantity_produced
                planid
                planned_quantity
                shift_id
                tenantid
                actual_starttime
                actual_time_taken
                end_customer
                machineid
                machine_name
                operation_details
                partno
                planned_quantity_uom
                remarks
                required_time_ideal
                shiftname
                target
                target_uom
                production_starttime_desired
                batch_start_time
              }
            }
          `;
        }
      } else {
        query = `
          query ($tenantId: Int!) {
            tnt_production_plan_master (
              where: { tenant_id: { _eq: $tenantId } }
            ) {
              date
              actual_quantity_produced
              planid
              planned_quantity
              shift_id
              tenantid
              actual_starttime
              actual_time_taken
              end_customer
              machineid
              machine_name
              operation_details
              partno
              planned_quantity_uom
              remarks
              required_time_ideal
              shiftname
              target
              target_uom
              production_starttime_desired
              batch_start_time
            }
          }
        `;
        variables = { tenantId: tenantId };
      }
      const response = await axios.post(
        APIURL,
        {
          query,
          variables,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-hasura-admin-secret": process.env.REACT_APP_X_API_SECRET_KEY, // Replace with your Hasura admin secret
          },
        }
      );
      setRequiredTime(response.data.data.tnt_production_plan_master);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchpartno = async (logintenantId) => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery($logintenantId: Int!) {
          tnt_orgnization_partscustomer_master(where: {tenantid: {_eq: $logintenantId}}) {
            part_number
            end_customer_name
          }
        }
        
        `,
          variables: {
            logintenantId: parseInt(logintenantId),
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            // "x-hasura-admin-secret": process.env.REACT_APP_X_API_SECRET_KEY, // Replace with your Hasura admin secret
          },
        }
      );
      setProductionPartNo(
        response.data.data.tnt_orgnization_partscustomer_master
      );
      console.log(
        response.data.data.tnt_orgnization_partscustomer_master,
        "tnt_orgnization_partscustomer_master"
      );
    } catch (error) {
      console.error(error);
    }
  };

  const token = sessionStorage.getItem("token");
  const decodedToken = JSON.parse(atob(token.split(".")[1]));

  const fetchmachineno = async (logintenantId) => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery($logintenantId: Int!) {
          tnt_org_machine_assignment(where: {tenantid: {_eq: $logintenantId}}) {
            machine_name
            machineid
          }
        }
        `,
          variables: {
            logintenantId: parseInt(logintenantId),
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            // "x-hasura-admin-secret": process.env.REACT_APP_X_API_SECRET_KEY, // Replace with your Hasura admin secret
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProductionMachineNo(response.data.data.tnt_org_machine_assignment);
      console.log(
        response.data.data.tnt_org_machine_assignment,
        "tnt_org_machine_assignment"
      );
    } catch (error) {
      console.error(error);
    }
  };

  const fetchshiftname = async (logintenantId) => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery($logintenantId: Int!) {
          tnt_work_schedule_masters(where: {tenantid: {_eq: $logintenantId}}) {
            shift_name
          }
        }
        `,
          variables: {
            logintenantId: parseInt(logintenantId),
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            //"x-hasura-admin-secret": process.env.REACT_APP_X_API_SECRET_KEY, // Replace with your Hasura admin secret
          },
        }
      );
      setShiftName(response.data.data.tnt_work_schedule_masters);
      console.log(
        response.data.data.tnt_work_schedule_masters,
        "tnt_work_schedule_masters"
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["sub"];
    let logintenantId = null;

    if (roleId == 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
    }

    fetchmachineno(logintenantId);
    fetchpartno(logintenantId);
    fetchshiftname(logintenantId);
  }, []);

  const fetchTenants = async () => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
          query {
            tenants {
              tenant_id
              tenant_name
            }
          }
        `,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-hasura-admin-secret": process.env.REACT_APP_X_API_SECRET_KEY, // Replace with your Hasura admin secret
          },
        }
      );
      setTenants(response.data.data.tenants);
      console.log(response.data.data.tenants);
    } catch (error) {
      console.error(error);
    }
  };
  const handleOrgNodeChange = (e) => {
    const selectedNodeName = e.target.options[e.target.selectedIndex].text;
    setProductionDate(selectedNodeName);
  };
  const handleNext = () => {
    if (activeStep === 1) {
      setProgress(100);
    }
    setActiveStep(activeStep + 1);
    if (activeStep + 1 === 4) {
      setIsLastStep(true);
    }
  };
  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
    setIsLastStep(false);
  };
  const handlesaveandClose = () => {
    setIsCardOpen(false);
  };

  const convertIstToUtc = (istTime) => {
    if (!istTime) {
      return "";
    }
    const [hours, minutes] = istTime.split(":");
    const utcdate = new Date();
    utcdate.setUTCHours(hours - 5, minutes - 30, 0, 0);
    const utcTime = utcdate.toISOString().split("T")[1].substring(0, 8);
    return utcTime;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const token = sessionStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["sub"];
    console.log(roleId);
    let logintenantId = null;
    if (roleId === 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
    }

    // const formattedRequiredTime = `${required_time} Minutes`;

    const formData = {
      tenantid: logintenantId,
      date: production_date,
      partno: part_no,
      end_customer: endcustname,
      operation_details: production_operation_details,
      machine_name: machinename,
      shiftname: shift,
      production_starttime_desired: convertIstToUtc(production_starttime),
      // target: target,
      planned_quantity: planned_quantity,
      machineid: machineno,
      no_of_batches: noofbatches,
      required_time_ideal: required_time,
      remarks: remark,
      planned_quantity_uom: plannedquantity_uam,
      // target_uom: target_uom,
      balance_schedule_quantity: balanceScheduleQty,
      actual_required_quantity: actualRequiredQty,
      available_wip: availableWIP,
    };
    axios
      .post(
        APIURL,
        {
          query: `
            mutation InsertProductionPlan($formData: tnt_production_plan_master_insert_input!) {
                insert_tnt_production_plan_master(objects: [$formData]) {
                affected_rows
                }
            }
        `,
          variables: {
            formData: formData,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-hasura-admin-secret": process.env.REACT_APP_X_API_SECRET_KEY, // Replace with your Hasura admin secret
          },
        }
      )
      .then((response) => {
        console.log(response); // Handle the response data as needed
        if (
          response.data.data &&
          response.data.data.insert_tnt_production_plan_master.affected_rows > 0
        ) {
          setTenantId("");
          setPlan_id("");
          setProductionDate("");
          setPartNo("");
          setEndCustName("");
          setProductionOperationDetails("");
          setMachineNo("");
          setMachineName("");
          setOperatorName("");
          setOperatorEmpCode("");
          setShiftName("");
          setProductionStartTime("");
          setTargetSpm("");
          setPlannedQuantity("");
          setNOBatches("");
          setRemark("");
          setRequiredTime("");
          setPlannedQuantity_Uam("");
          setTarget_Uom("");
          setBalanceScheduleQty("");
          setActualRequiredQty("");
          setAvailableWIP("");

          toast.success("Data inserted successfully!");
          fetchData();
        } else {
          toast.error("Failed to insert data.");
        }
      })
      .catch((error) => {
        console.error(error); // Handle the error
        toast.error("An error occurred while inserting data.");
      });
  };
  const renderStepContent = () => {
    switch (activeStep) {
      case 1:
        return (
          <>
            <Row className="flex-nowrap">
              <Col className="px-md-1" sm="2" md="2">
                <FormGroup className="flex-nowrap">
                  <Label for="production_date" className="plan-admin">
                    Date   *
                  </Label>
                  <Input
                      type="date"
                      value={production_date}
                      min={new Date().toISOString().split('T')[0]}
                      onChange={(e) => {
                        const selectedDate = new Date(e.target.value);
                        const today = new Date();
                        today.setHours(0, 0, 0, 0); // remove time part
                        if (selectedDate < today) {
                          alert('Previous date selection is not allowed');
                        } else {
                          setProductionDate(e.target.value);
                        }
                      }}
                    />
                  {/* <Input
                    type="date"
                    value={production_date}
                    onChange={(e) => setProductionDate(e.target.value)}
                  /> */}
                </FormGroup>
              </Col>
              <Col className="px-md-1" sm="2" md="2">
                <FormGroup className="flex-nowrap">
                  <Label for="shift_name" className="plan-admin">
                    Shift Name *{" "}
                  </Label>
                  <Input
                    type="select"
                    value={shift}
                    onChange={(e) => setshift(e.target.value)}
                  >
                    <option value="" disabled>
                      Select Shift
                    </option>
                    {shift_name.map((shn) => (
                      <option key={shn.shift_name} value={shn.shift_name}>
                        {shn.shift_name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col className="px-md-1" sm="2" md="2">
                <FormGroup className="flex-nowrap">
                  <Label
                    for="production_starttime"
                    className="plan-admin"
                  >
                    Production Start Time  *
                  </Label>
                  <Input
                    type="time"
                    id="production_starttime"
                    value={production_starttime}
                    onChange={(e) => setProductionStartTime(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" sm="2" md="2">
                <FormGroup className="flex-nowrap">
                  <Label for="production_part_no" className="plan-admin">
                    Part No *
                  </Label>
                  <Input
                    type="select"
                    value={part_no}
                    onChange={(e) => {
                      setPartNo(e.target.value); // Update selected part_no
                      // Find the corresponding end_customer_name for the selected part number
                      const selectedPart = production_part_no.find(
                        (prn) => prn.part_number === e.target.value
                      );
                      if (selectedPart) {
                        setEndCustName(selectedPart.end_customer_name); // Set End Customer Name
                      } else {
                        setEndCustName(""); // Reset if no matching part number found
                      }
                    }}
                  >
                    <option value="" disabled>
                      Select Part Number
                    </option>
                    {production_part_no.map((prn) => (
                      <option key={prn.part_number} value={prn.part_number}>
                        {prn.part_number}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col className="px-md-1" sm="2" md="2">
                <FormGroup className="flex-nowrap">
                  <Label
                    for="production_end_customer_name"
                    className="plan-admin"
                  >
                    End Customer Name *
                  </Label>
                  <Input
                    type="text"
                    id="production_end_customer_name"
                    value={endcustname}
                    onChange={(e) => setEndCustName(e.target.value)}
                    readOnly={true}
                    style={{
                      textShadow: "1px 1px 1px rgba(0,0,0,0.5)",
                      color: "rgba(255, 255, 255, 0.8)",
                    }}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" sm="2" md="2">
                <FormGroup className="flex-nowrap">
                  <Label
                    for="production_operation_details"
                    className="plan-admin"
                  >
                    Operation/Process Details *
                  </Label>
                  <Input
                    placeholder=""
                    type="text"
                    value={production_operation_details}
                    onChange={(e) =>
                      setProductionOperationDetails(e.target.value)
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="flex-nowrap">
              <Col className="px-md-1" sm="2" md="2">
                <FormGroup className="flex-nowrap">
                  <Label
                    for="production_mahine_no"
                    className="plan-admin"
                  >
                    Production Machine No *
                  </Label>
                  <Input
                    type="select"
                    value={machineno}
                    onChange={(e) => {
                      setMachineNo(e.target.value);
                      // Find the corresponding end_customer_name for the selected part number
                      const selectedMachine = production_mahine_no.find(
                        (pmn) => pmn.machineid === e.target.value
                      );
                      if (selectedMachine) {
                        setMachineName(selectedMachine.machine_name); // Set End Customer Name
                      } else {
                        setMachineName(""); // Reset if no matching part number found
                      }
                    }}
                  >
                    <option value="" disabled>
                      Select Machine No
                    </option>
                    {production_mahine_no.map((pdn) => (
                      <option key={pdn.machineid} value={pdn.machineid}>
                        {pdn.machineid}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col className="px-md-1" sm="2" md="2">
                <FormGroup className="flex-nowrap">
                  <Label
                    for="production_machine_name"
                    className="plan-admin"
                  >
                    Production Machine Name *
                  </Label>
                  <Input
                    type="text"
                    value={machineno}
                    onChange={(e) => setMachineName(e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col className="px-md-1" sm="2" md="2">
                <FormGroup className="flex-nowrap">
                  <Label for="planned_quantity" className="plan-admin">
                    Planned Quantity *
                  </Label>
                  <Input
                    placeholder=""
                    type="text"
                    value={planned_quantity}
                    onChange={(e) => setPlannedQuantity(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" sm="2" md="2">
                <FormGroup className="flex-nowrap">
                  <Label
                    for="planned_quantity_uom"
                    className="plan-admin"
                  >
                    Unit of Measurement *
                  </Label>
                  <Input
                    type="select"
                    value={plannedquantity_uam}
                    onChange={(e) => setPlannedQuantity_Uam(e.target.value)}
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    {plannedQuantityUOMOptions.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col className="px-md-1" sm="2" md="2">
                <FormGroup className="flex-nowrap">
                  <Label for="noofbatches" className="plan-admin">
                    No.of Batches
                  </Label>
                  <Input
                    // placeholder="Enter Number of  Batches"
                    type="text"
                    value={noofbatches || ''}
                    onChange={(e) => setNOBatches(e.target.value || null)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" sm="2" md="2">
                <FormGroup className="flex-nowrap">
                  <Label for="required_time" className="plan-admin">
                    Required Time (in minutes) *
                  </Label>
                  <Input
                    placeholder="Required Time is Upto 600 Minutes"
                    type="integer"
                    value={required_time}
                    onChange={(e) => {
                      if (e.target.value > 600) {
                        setErrorMessage('Please enter time below 600 minutes');
                      } else {
                        setErrorMessage('');
                        setRequiredTime(e.target.value);
                      }
                    }}
                  />
                  {errorMessage && <div style={{ color: '#ec4555',fontSize:'14px' }}>{errorMessage}</div>}
                </FormGroup>
              </Col>
              {/* <Col className="px-md-1" sm="2" md="2">
                <FormGroup className="flex-nowrap">
                  <Label for="required_time" style={{ color: "#ffffff" }}>
                    Required Time (in minutes)
                  </Label>
                  <Input
                    placeholder="Required Time is Upto 600 Minutes"
                    type="number"
                    min={1}
                    max={600}
                    value={required_time}
                    onChange={(e) => setRequiredTime(e.target.value)}
                  />
                </FormGroup>
              </Col> */}
            </Row>
            <Row className="flex-nowrap">
              <Col className="px-md-1" sm="3" md="3">
                <FormGroup className="flex-nowrap">
                  <Label for="required_time" className="plan-admin">
                    {" "}
                    Balance Schedule Quantity *
                  </Label>
                  <Input
                    placeholder=""
                    type="text"
                    value={balanceScheduleQty}
                    onChange={(e) => setBalanceScheduleQty(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" sm="3" md="3">
                <FormGroup className="flex-nowrap">
                  <Label for="required_time" className="plan-admin">
                    Actual Required Quantity *
                  </Label>
                  <Input
                    placeholder=""
                    type="text"
                    value={actualRequiredQty}
                    onChange={(e) => setActualRequiredQty(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" sm="3" md="3">
                <FormGroup className="flex-nowrap">
                  <Label for="required_time" className="plan-admin">
                    {" "}
                    Available WIP *
                  </Label>
                  <Input
                    placeholder=""
                    type="text"
                    value={availableWIP}
                    onChange={(e) => setAvailableWIP(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="3">
                <FormGroup className="flex-nowrap">
                  <Label for="remark" className="plan-admin">
                    Remark
                  </Label>
                  <Input
                    placeholder=""
                    type="text"
                    value={remark || ''}
                    onChange={(e) => setRemark(e.target.value || null)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        );
      // case 2:
      //   return (
      //     <>
      //     <Row>
      //       <Col className="px-md-1" md="4">
      //         <FormGroup>
      //           <Label for="no_of_operator">No. of Operators</Label>
      //           {/* <Input
      //             type="number"
      //           /> */}
      //           <div className="dynamic-tbl">
      //             <DynamicTable ref={dynamicTableRef} />
      //           </div>
      //         </FormGroup>
      //       </Col>
      //     </Row>
      //   </>
      // <>
      //   <Row>
      //     <Col className="px-md-1" md="8">
      //       <FormGroup>
      //         <Label for="production_operation_details">
      //           Production Operation / Process Details
      //         </Label>
      //         <Input
      //           placeholder=""
      //           type="text"
      //           value={production_operation_details}
      //           onChange={(e) =>
      //             setProductionOperationDetails(e.target.value)
      //           }
      //         />
      //       </FormGroup>
      //     </Col>
      //   </Row>
      //   <Row>
      //     <Col className="px-md-1" md="3">
      //       <FormGroup>
      //         <Label for="planned_quantity">Planned Quantity</Label>
      //         <Input
      //           placeholder=""
      //           type="text"
      //           value={planned_quantity}
      //           onChange={(e) => setPlannedQuantity(e.target.value)}
      //         />
      //       </FormGroup>
      //     </Col>
      //     <Col className="px-md-1" md="6">
      //       <FormGroup>
      //         <Label for="planned_quantity_uom">
      //           Planned Quantity Unit of Measurement
      //         </Label>
      //         <Input
      //           type="select"
      //           value={plannedquantity_uam}
      //           onChange={(e) => setPlannedQuantity_Uam(e.target.value)}
      //         >
      //           <option value="" disabled>
      //             Select an option
      //           </option>
      //           {plannedQuantityUOMOptions.map((option, index) => (
      //             <option key={index} value={option}>
      //               {option}
      //             </option>
      //           ))}
      //         </Input>
      //       </FormGroup>
      //     </Col>
      //   </Row>
      //   <Row>
      //     <Col className="px-md-1" md="4">
      //       <FormGroup>
      //         <Label for="noofbatches">No.of Batches</Label>
      //         <Input
      //           placeholder="Enter Number of  Batches"
      //           type="text"
      //           value={noofbatches}
      //           onChange={(e) => setNOBatches(e.target.value)}
      //         />
      //       </FormGroup>
      //     </Col>
      //     <Col className="px-md-1" md="4">
      //       <FormGroup>
      //         <Label for="required_time">Required Time (in minutes)</Label>
      //         <Input
      //           placeholder=""
      //           type="text"
      //           value={required_time}
      //           onChange={(e) => setRequiredTime(e.target.value)}
      //         />
      //       </FormGroup>
      //     </Col>
      //     <Col className="px-md-1" md="4">
      //       <FormGroup>
      //         <Label for="remark">Remark</Label>
      //         <Input
      //           placeholder=""
      //           type="text"
      //           value={remark}
      //           onChange={(e) => setRemark(e.target.value)}
      //         />
      //       </FormGroup>
      //     </Col>
      //   </Row>
      // </>
      //   );
      // case 3:
      // return (
      //   <>
      //     <Row>
      //       <Col className="px-md-1" md="4">
      //         <FormGroup>
      //           <Label for="no_of_operator">No. of Operators</Label>
      //           {/* <Input
      //             type="number"
      //           /> */}
      //           <div className="dynamic-tbl">
      //             <DynamicTable ref={dynamicTableRef} />
      //           </div>
      //         </FormGroup>
      //       </Col>
      //     </Row>
      //   </>
      // );

      default:
        return null;
    }
  };
  const calculateProgress = () => {
    return Math.round(((activeStep - 1) / 2) * 100); // Update the denominator to match the total number of steps minus one
  };
  const renderProgressBarDestinations = () => {
    // const step1Class = activeStep >= 1 ? "progress-destination-active" : "";
    // const step2Class = activeStep >= 2 ? "progress-destination-active" : "";
    // const step3Class = activeStep >= 3 ? "progress-destination-active" : "";
    // const step4Class = activeStep >= 4 ? "progress-destination-active" : "";
    return (
      <div className="progress-destinations">
        {/* <div className={`progress-destination ${step1Class}`}>
          <span>1</span>
        </div> */}
        {/* <div className={`progress-destination ${step2Class}`}>
          <span>2</span>
        </div>
        <div className={`progress-destination ${step3Class}`}>
          <span>3</span>
        </div> */}
        {/* <div className={`progress-destination ${step4Class}`}>
          <span>4</span>
        </div> */}
      </div>
    );
  };
  const renderStepButtons = () => {
    if (activeStep === 1) {
      return (
        <>
          <Button color="danger" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color="success"
            onClick={(event) => {
              handleFormSubmit(event);
              handleClose(event);
              // handleNext();
            }}
          >
            Save
          </Button>
          {/* <Button color="primary" onClick={handleNext}>
            Next
          </Button> */}
        </>
      );
      // } else if (activeStep === 2) {
      //   return (
      //     <>
      //       <Button color="danger" onClick={handleClose}>
      //         Cancel
      //       </Button>
      //       <Button color="primary" onClick={handlePrevious}>
      //         Previous
      //       </Button>
      //       <Button
      //         color="success"
      //         onClick={(event) => {
      //           handleButtonClick();
      //           // handlesaveandClose(event);
      //           handleClose(event);
      //         }}
      //       >
      //         Save
      //       </Button>
      //     </>
      //   );
      // } else {
      //   return (
      //     <>
      //       <Button color="danger" onClick={handleClose}>
      //         Close
      //       </Button>
      //       <Button color="primary" onClick={handlePrevious}>
      //         Previous
      //       </Button>
      //     </>
      //   );
    }
  };
  return (
    <div className="content">
      {/* <Row className="progress-row">
        {isCardOpen && (
          <Col md="8">
            <div className="progress-container">
              {renderProgressBarDestinations()}
              <Progress
                max="100"
                value={calculateProgress()}
                className="progress-bar-sm progress-bar-striped"
              />
            </div>
          </Col>
        )}
      </Row> */}
      <Row className="progress-row">
        {isCardOpen && (
          <Col md="8">
            <Card>
              <CardHeader>
                <h4 className="title" style={{ color: "#ffffff" }}>
                  Add Production Plan
                  <button type="button" className="close" aria-label="Close" 
                    style={{
                    // float: "right", 
                    background: "transparent",
                    border: "none",
                    color: "#ffffff" ,
                    fontSize: "35px",
                    cursor: "pointer",
                    marginTop: "-40px",
                    }}
                    onClick={handleClose}
                    >
                    &times;
                    </button>
                </h4>
              </CardHeader>
              <CardBody>
                <Form>{renderStepContent()}</Form>
              </CardBody>
              <CardFooter>
                <div className="text-right">{renderStepButtons()}</div>
              </CardFooter>
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
}
export default ProductionAdd;
