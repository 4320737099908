import React from 'react';
import './style.css';
import './responsive.css';

const PercentageCircle = ({ percentage, color, borderColor, borderWidth }) => {
  const circleStyle = {
    stroke: `url(#circle-gradient-${color})`,
    strokeDasharray: `${percentage === null ? 0 : percentage}, 100`,
    strokeWidth: borderWidth !== undefined ? borderWidth : 2, // Adjust the border width
  };

  return (
    <div className="percentage-circle">
      <svg className="circle-svg" viewBox="0 0 32 32">
        <defs>
          <linearGradient id={`circle-gradient-${color}`} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor={color} />
            <stop offset="100%" stopColor="#ffffff" />
          </linearGradient>
        </defs>
        <path className="circle-background" d="M16,2
          A 14,14 0 0 1 30,16
          A 14,14 0 0 1 16,30
          A 14,14 0 0 1 2,16
          A 14,14 0 0 1 16,2 Z" />
        <path
          className="circle-progress"
          d="M16,2
            A 14,14 0 0 1 30,16
            A 14,14 0 0 1 16,30
            A 14,14 0 0 1 2,16
            A 14,14 0 0 1 16,2 Z"
          style={circleStyle}
          fill="transparent"
        />
        <text x="50%" y="50%" className="circle-text" >
          {percentage === null || percentage === 'undefined' ? 'no data' : percentage}
        </text>
      </svg>
    </div>
  );
};

export default PercentageCircle;
