import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Button, Card, CardHeader, CardBody, ButtonGroup } from "reactstrap";
import "components/OEE/Oee.css";
import classNames from "classnames";
import { QueryApi } from "QueryApi";
import TableComponentWithoutFilter from "components/ReusableComponents/TableComponentWithoutFilter";
import BarChart from "../../assets/charts/BarChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    
    faArrowRightLong,
    
  } from "@fortawesome/free-solid-svg-icons";

function SecondarySliterGraphs() {
  const [firstChart, setFirstChart] = useState("data1");
  const [SecondChart, setSecondChart] = useState("data1");
  const [ThirdChart, setThirdChart] = useState("data1");
  const [FourthChart, setFourthChart] = useState("data1");
  const [FifthChart, setFifthChart] = useState("data1");
  const [SixChart, setSixChart] = useState("data1");
  const [SevenChart, setSevenChart] = useState("data1");
  const [EightChart, setEightChart] = useState("data1");
  const [OeeMachineDate, setOeeMachineDate] = useState([]);
  const [OeeShiftDataByData, setOeeShiftDataByData] = useState([]);
  const [productionByMachine, setProductionByMachine] = useState([]);
  const [OleShiftData, setOleShiftData] = useState([]);
  const [OleOperatorExperiance, setOleOperatorExperiance] = useState([]);
  const [OleOperatorEducation, setOleOperatorEducation] = useState([]);

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const currDay = day < 10 ? `0${day}` : day;
  const currMonth = month < 10 ? `0${month}` : month;
  let currentDate = JSON.stringify(`${year}-${currMonth}-${currDay}`);

  const dateData = new Date();
  dateData.setDate(dateData.getDate() - 7);
  var dayDataValue = dateData.getDate();
  var monthDataValue = dateData.getMonth() + 1;
  var yearDataValue = dateData.getFullYear();
  var formattedDay = dayDataValue < 10 ? `0${dayDataValue}` : dayDataValue;
  var formattedMonth =
    monthDataValue < 10 ? `0${monthDataValue}` : monthDataValue;
  var weekDataDate = JSON.stringify(
    `${yearDataValue}-${formattedMonth}-${formattedDay}`
  );

  const previousDate = new Date();
  previousDate.setDate(1);
  const previousDay = previousDate.getDate();
  const previousMonth = previousDate.getMonth() + 1;
  const previousYear = previousDate.getFullYear();
  const formattedPreviousDay =
    previousDay < 10 ? `0${previousDay}` : previousDay;
  const formattedPreviousMonth =
    previousMonth < 10 ? `0${previousMonth}` : previousMonth;
  const previousMonthDate = JSON.stringify(
    `${previousYear}-${formattedPreviousMonth}-${formattedPreviousDay}`
  );

  const yearCurrentDate = new Date();
  yearCurrentDate.setDate(1);
  yearCurrentDate.getMonth(1);
  const yearDay = yearCurrentDate.getDate();
  const yearMonth = yearCurrentDate.getMonth();
  const yearYear = yearCurrentDate.getFullYear();
  const formattedYearDay = yearDay < 10 ? `0${yearDay}` : yearDay;
  const formattedYearMonth = yearMonth < 10 ? `0${yearMonth}` : yearMonth;
  const yearPreviousMonthDate = JSON.stringify(
    `${yearYear}-${formattedYearMonth}-${formattedYearDay}`
  );
////////////////////////// Bar chart data //////////////////////////////////////////
const [TotalProduction, setTotalProduction] = useState([]);
const [XaxisTotalProduction, setXaxisTotalProduction] = useState([]);

useEffect(() => {
  TotalProductionfun();
}, []);
const TotalProductionfun = async () => {
  try {
    const data = await QueryApi(`
    query MyQuery( $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
      bopp_fl0_output_ss_slit_secondary(where: { tenantid: {_eq: $tenantid}}) {
          total_ssslitter_output__volume
          machine_id
        }
    }
  `);
    var xAxisDataPerformance = data.bopp_fl0_output_ss_slit_secondary.map((data) => data.machine_id);
    setXaxisTotalProduction(xAxisDataPerformance);
    var DataPerformance = data.bopp_fl0_output_ss_slit_secondary.map((data) => {
      return { value: data.total_ssslitter_output__volume, color: "#1F8EF1" };
    });
    setTotalProduction(DataPerformance);
  } catch (error) {
    setTotalProduction([]);
    setXaxisTotalProduction([]);
  }
};


  ///// production by shift ///////////////////////////////////////////////////////////

  useEffect(() => {
    FourthChartData();
  }, [FourthChart]);
  const FourthChartData = async () => {
    if (FourthChart === "data1") {
      //  day data API
      try {
        const data = await QueryApi(`
      query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        dm_daily(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}},order_by: {date: desc}) {
          org {
            organization_node_name
          }
          unit {
            organization_node_name
          }
          dept {
            organization_node_name
          }
          date
          total_parts_produced
          machineid
        }
      } `);
        
        setProductionByMachine(data?.dm_daily);
      } catch (error) {}
    } else if (FourthChart === "data2") {
      // week data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        dm_daily(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}},order_by: {date: desc}) {
          org {
            organization_node_name
          }
          unit {
            organization_node_name
          }
          dept {
            organization_node_name
          }
          date
          total_parts_produced
          machineid
        }
      } `);
        setProductionByMachine(data?.dm_daily);
      } catch (error) {}

    } else if (FourthChart === "data3") {
      // month data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        dm_daily(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
          org {
            organization_node_name
          }
          unit {
            organization_node_name
          }
          dept {
            organization_node_name
          }
          date
          total_parts_produced
          machineid
        }
      } `);
        setProductionByMachine(data?.dm_daily);
      } catch (error) {}
    } else if (FourthChart === "data4") {
      // year data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        dm_daily(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
          org {
            organization_node_name
          }
          unit {
            organization_node_name
          }
          dept {
            organization_node_name
          }
          date
          total_parts_produced
          machineid
        }
      } `);
        setProductionByMachine(data?.dm_daily);
      } catch (error) {}
    } else {
      setFourthChart("data1");
    }
  };

  ///// OLE By Shift ///////////////////////////////////////////////////////////

  useEffect(() => {
    FifthChartData();
  }, [FifthChart]);
  const FifthChartData = async () => {
    if (FifthChart === "data1") {
      //  day data API
      try {
        const data = await QueryApi(`
      query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        wfl0_workforce_efficiency_shiftwise(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
          date
          shift
          time_efficiency_per
          production_efficiency_per
          quality_efficiency_per
          overall_labor_effectiveness
        }
      } `);
        const groupedData = groupBy(
          data.wfl0_workforce_efficiency_shiftwise,
          "shift"
        );
        const combinedData = Object.keys(groupedData).map((shift) => {
          const items = groupedData[shift];
          const time_efficiency_per_avg = average(items, "time_efficiency_per");
          const production_efficiency_per_avg = average(
            items,
            "production_efficiency_per"
          );
          const quality_efficiency_per_avg = average(
            items,
            "quality_efficiency_per"
          );
          const product =
            (time_efficiency_per_avg *
              production_efficiency_per_avg *
              quality_efficiency_per_avg) /
            10000;

          return {
            shift,
            time_efficiency_per: time_efficiency_per_avg,
            production_efficiency_per: production_efficiency_per_avg,
            overall_labor_effectiveness: product,
            quality_efficiency_per: quality_efficiency_per_avg,
          };
        });
        setOleShiftData(combinedData);
      } catch (error) {}

      function groupBy(array, key) {
        return array.reduce((result, currentItem) => {
          (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
          );
          return result;
        }, {});
      }

      function average(array, key) {
        return (
          array.reduce((result, currentItem) => result + currentItem[key], 0) /
          array.length
        );
      }
    } else if (FifthChart === "data2") {
      // week data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        wfl0_workforce_efficiency_shiftwise(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
          date
          shift
          time_efficiency_per
          production_efficiency_per
          quality_efficiency_per
          overall_labor_effectiveness
        }
      } `);
        const groupedData = groupBy(
          data.wfl0_workforce_efficiency_shiftwise,
          "shift"
        );
        const combinedData = Object.keys(groupedData).map((shift) => {
          const items = groupedData[shift];
          const time_efficiency_per_avg = average(items, "time_efficiency_per");
          const production_efficiency_per_avg = average(
            items,
            "production_efficiency_per"
          );
          const quality_efficiency_per_avg = average(
            items,
            "quality_efficiency_per"
          );
          const product =
            (time_efficiency_per_avg *
              production_efficiency_per_avg *
              quality_efficiency_per_avg) /
            10000;

          return {
            shift,
            time_efficiency_per: time_efficiency_per_avg,
            production_efficiency_per: production_efficiency_per_avg,
            overall_labor_effectiveness: product,
            quality_efficiency_per: quality_efficiency_per_avg,
          };
        });
        setOleShiftData(combinedData);
      } catch (error) {}

      function groupBy(array, key) {
        return array.reduce((result, currentItem) => {
          (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
          );
          return result;
        }, {});
      }

      function average(array, key) {
        return (
          array.reduce((result, currentItem) => result + currentItem[key], 0) /
          array.length
        );
      }
    } else if (FifthChart === "data3") {
      // month data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        wfl0_workforce_efficiency_shiftwise(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
          date
          shift
          time_efficiency_per
          production_efficiency_per
          quality_efficiency_per
          overall_labor_effectiveness
        }
      } `);
        const groupedData = groupBy(
          data.wfl0_workforce_efficiency_shiftwise,
          "shift"
        );
        const combinedData = Object.keys(groupedData).map((shift) => {
          const items = groupedData[shift];
          const time_efficiency_per_avg = average(items, "time_efficiency_per");
          const production_efficiency_per_avg = average(
            items,
            "production_efficiency_per"
          );
          const quality_efficiency_per_avg = average(
            items,
            "quality_efficiency_per"
          );
          const product =
            (time_efficiency_per_avg *
              production_efficiency_per_avg *
              quality_efficiency_per_avg) /
            10000;

          return {
            shift,
            time_efficiency_per: time_efficiency_per_avg,
            production_efficiency_per: production_efficiency_per_avg,
            overall_labor_effectiveness: product,
            quality_efficiency_per: quality_efficiency_per_avg,
          };
        });
        setOleShiftData(combinedData);
      } catch (error) {}

      function groupBy(array, key) {
        return array.reduce((result, currentItem) => {
          (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
          );
          return result;
        }, {});
      }

      function average(array, key) {
        return (
          array.reduce((result, currentItem) => result + currentItem[key], 0) /
          array.length
        );
      }
    } else if (FifthChart === "data4") {
      // year data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        wfl0_workforce_efficiency_shiftwise(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
          date
          shift
          time_efficiency_per
          production_efficiency_per
          quality_efficiency_per
          overall_labor_effectiveness
        }
      } `);
        const groupedData = groupBy(
          data.wfl0_workforce_efficiency_shiftwise,
          "shift"
        );
        const combinedData = Object.keys(groupedData).map((shift) => {
          const items = groupedData[shift];
          const time_efficiency_per_avg = average(items, "time_efficiency_per");
          const production_efficiency_per_avg = average(
            items,
            "production_efficiency_per"
          );
          const quality_efficiency_per_avg = average(
            items,
            "quality_efficiency_per"
          );
          const product =
            (time_efficiency_per_avg *
              production_efficiency_per_avg *
              quality_efficiency_per_avg) /
            10000;

          return {
            shift,
            time_efficiency_per: time_efficiency_per_avg,
            production_efficiency_per: production_efficiency_per_avg,
            overall_labor_effectiveness: product,
            quality_efficiency_per: quality_efficiency_per_avg,
          };
        });
        setOleShiftData(combinedData);
      } catch (error) {}

      function groupBy(array, key) {
        return array.reduce((result, currentItem) => {
          (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
          );
          return result;
        }, {});
      }

      function average(array, key) {
        return (
          array.reduce((result, currentItem) => result + currentItem[key], 0) /
          array.length
        );
      }
    } else {
      setFifthChart("data1");
    }
  };

  ///// OLE By Part ///////////////////////////////////////////////////////////

  useEffect(() => {
    SixChartData();
  }, [SixChart]);
  const SixChartData = async () => {
    if (SixChart === "data1") {
      //  day data API
    } else if (SixChart === "data2") {
      // week data API
    } else if (SixChart === "data3") {
      // month data API
    } else if (SixChart === "data4") {
      // year data API
    } else {
      setSixChart("data1");
    }
  };

  /////Production By Date ///////////////////////////////////////////////////////////

  useEffect(() => {
    SevenChartData();
  }, [SevenChart]);
  const SevenChartData = async () => {
    if (SevenChart === "data1") {
      //  day data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        dm_org_department_daily_mv(where: {start_date: {_eq: $start_date}, tenantid: {_eq: $tenantid}},order_by: {start_date: desc}) {
          org {
            organization_node_name
          }
          unit {
            organization_node_name
          }
          dept {
            organization_node_name
          }
          start_date
          total_parts_produced
        }
      } `);
        
        setOleOperatorExperiance(data.dm_org_department_daily_mv);
      } catch (error) {}

      
    } else if (SevenChart === "data2") {
      // week data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        dm_org_department_daily_mv(where: {start_date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}},order_by: {start_date: desc}) {
          org {
            organization_node_name
          }
          unit {
            organization_node_name
          }
          dept {
            organization_node_name
          }
          start_date
          total_parts_produced
        }
      } `);
       
        setOleOperatorExperiance(data.dm_org_department_daily_mv);
      } catch (error) {}

     
    } else if (SevenChart === "data3") {
      // month data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        dm_org_department_daily_mv(where: {start_date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}},order_by: {start_date: desc}) {
                org {
            organization_node_name
          }
          unit {
            organization_node_name
          }
          dept {
            organization_node_name
          }
          start_date
          total_parts_produced
        }
      } `);
       
        setOleOperatorExperiance(data.dm_org_department_daily_mv);
      } catch (error) {}

      
    } else if (SevenChart === "data4") {
      // year data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        dm_org_department_daily_mv(where: {start_date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}},order_by: {start_date: desc}) {
          org {
            organization_node_name
          }
          unit {
            organization_node_name
          }
          dept {
            organization_node_name
          }
          start_date
          total_parts_produced
        }
      } `);
       
        setOleOperatorExperiance(data.dm_org_department_daily_mv);
      } catch (error) {}

     
    } else {
      setSevenChart("data1");
    }
  };
  ///// OLE By Shift ///////////////////////////////////////////////////////////

  useEffect(() => {
    EightChartData();
  }, [EightChart]);
  const EightChartData = async () => {
    if (EightChart === "data1") {
      //  day data API
      try {
        const data = await QueryApi(`
      query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        dm_machine_shiftwise(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}},order_by: {date: desc}) {
          org {
            organization_node_name
          }
          unit {
            organization_node_name
          }
          dept {
            organization_node_name
          }
          date
          machineid
          shift_id
          total_parts_produced
        }
      } `);
        setOleOperatorEducation(data.dm_machine_shiftwise);
      } catch (error) {}
    } else if (EightChart === "data2") {
      // week data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        dm_machine_shiftwise(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}},order_by: {date: desc}) {
          org {
            organization_node_name
          }
          unit {
            organization_node_name
          }
          dept {
            organization_node_name
          }
          date
          machineid
          shift_id
          total_parts_produced
        }
      } `);
      
        setOleOperatorEducation(data.dm_machine_shiftwise);
      } catch (error) {}
    } else if (EightChart === "data3") {
      // month data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        dm_machine_shiftwise(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}},order_by: {date: desc}) {
          org {
            organization_node_name
          }
          unit {
            organization_node_name
          }
          dept {
            organization_node_name
          }
          date
          machineid
          shift_id
          total_parts_produced
        }
      } `);
        setOleOperatorEducation(data.dm_machine_shiftwise);
      } catch (error) {}
    } else if (EightChart === "data4") {
      // year data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        dm_machine_shiftwise(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}},order_by: {date: desc}) {
          org {
            organization_node_name
          }
          unit {
            organization_node_name
          }
          dept {
            organization_node_name
          }
          date
          machineid
          shift_id
          total_parts_produced
        }
      } `);
        setOleOperatorEducation(data.dm_machine_shiftwise);
      } catch (error) {}
    } else {
      setEightChart("data1");
    }
  };
  

  return (
    <>
      <div className="content">
        <Row>
        </Row>
        <Row>
          <Col lg="6">
            <Row>
              <Col lg="12">
                <Card className="card-workforce" style={{ height: "350px" }}>
                  <CardHeader>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Col>
                          <h5 className="card-category">Slit Efficiency of Line% by Plant</h5>
                        </Col>
                      </div>
                      <div className="icon-plus">
                <a
                  className="anchor-bg"
                  href={`/admin/Extrusion/IFA-Secondary-Slitter-efficiency`}
                >
                  <FontAwesomeIcon icon={faArrowRightLong} />
                </a>
              </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                  <div className="chart-area" style={{ height: "96%" }}>
                  <BarChart
                    xAxisData={XaxisTotalProduction}
                    yAxisData={TotalProduction}
                    height="100%"
                    width="100%"
                  />
                </div>
                   
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card className="card-workforce" style={{ height: "350px" }}>
                  <CardHeader>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Col>
                          <h5 className="card-category"> Slit Production (B)Mt by Plant</h5>
                        </Col>
                      </div>
                      {/* <div className="icon-plus">
                <a
                  className="anchor-bg"
                  href={`/admin/Extrusion/IFA-Secondary-Slitter-production`}
                >
                  <FontAwesomeIcon icon={faArrowRightLong} />
                </a>
              </div> */}
                    </div>
                  </CardHeader>
                  <CardBody>
                  <div className="chart-area" style={{ height: "96%" }}>
                  <BarChart
                    xAxisData={XaxisTotalProduction}
                    yAxisData={TotalProduction}
                    height="100%"
                    width="100%"
                  />
                </div>
                  
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
          {/* ///////////////////////////// Jumbo ////////////////////////////////////////////////// */}
          <Col lg="6">
            <Row>
              <Col lg="12">
                <Card className="card-workforce" style={{ height: "350px" }}>
                  <CardHeader>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Col>
                          <h5 className="card-category">Sum of T.Slit O/P% by Day
0 </h5>
                        </Col>
                      </div>
                      {/* <div className="icon-plus">
                <a
                  className="anchor-bg"
                  href={`/admin/Extrusion/IFA-Seconary-sliter/output`}
                >
                  <FontAwesomeIcon icon={faArrowRightLong} />
                </a>
              </div> */}
                    </div>
                  </CardHeader>
                  <CardBody>
                  <div className="chart-area" style={{ height: "96%" }}>
                  <BarChart
                    xAxisData={XaxisTotalProduction}
                    yAxisData={TotalProduction}
                    height="100%"
                    width="100%"
                  />
                </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card className="card-workforce" style={{ height: "350px" }}>
                  <CardHeader>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Col>
                          <h5 className="card-category">Sum of T.Slit O/P% by Day</h5>
                        </Col>
                      </div>
                      {/* <div className="icon-plus">
                <a
                  className="anchor-bg"
                  href={`/admin/Extrusion/IFA-Seconary-sliter/output`}
                >
                  <FontAwesomeIcon icon={faArrowRightLong} />
                </a>
              </div> */}
                    </div>
                  </CardHeader>
                  <CardBody>
                  <div className="chart-area" style={{ height: "96%" }}>
                  <BarChart
                    xAxisData={XaxisTotalProduction}
                    yAxisData={TotalProduction}
                    height="100%"
                    width="100%"
                  />
                </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
          {/* //////////////////////////// Jumbo ////////////////////////////////////////////////// */}
          <Col lg="6">
            <Row>
              <Col lg="12">
                <Card className="card-workforce" style={{ height: "350px" }}>
                  <CardHeader>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Col>
                          <h5 className="card-category">T.Slit O/P% by Day</h5>
                        </Col>
                      </div>
                      <div className="icon-plus">
                {/* <a
                  className="anchor-bg"
                  href={`/admin/Extrusion/IFA-Seconary-sliter/output`}
                >
                  <FontAwesomeIcon icon={faArrowRightLong} />
                </a> */}
              </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                  <div className="chart-area" style={{ height: "96%" }}>
                  <BarChart
                    xAxisData={XaxisTotalProduction}
                    yAxisData={TotalProduction}
                    height="100%"
                    width="100%"
                  />
                </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
          
        </Row>
      </div>
    </>
  );
}
export default SecondarySliterGraphs;
