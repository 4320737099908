import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from "reactstrap";
import axios from "axios";
import { APIURL } from "views/Apiurl";
import "ag-charts-enterprise";
import { AgChartsReact } from "ag-charts-react";
import Production from "components/New Productivity/Production";
import ProductionPlan from "views/ProductionPlan";

const convertUtcToIst = (utcTime) => {
  const utcDateTime = new Date(`1970-01-01T${utcTime}Z`);
  const istDateTime = new Date(
    utcDateTime.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
  );

  return istDateTime;
};

function GanttChart({ fetchData, handleClose }) {
  const [options, setOptions] = useState({
    data: [],
    title: {
      text: "Operator Utilization Schedule",
      color: "rgba(255, 255, 255, 0.8)",
    },
    subtitle: {
      text: "",
    },
    background: {
      visible: true,
      fill: "#27293d",
    },
    series: [
      {
        type: "range-bar",
        direction: "horizontal",
        xKey: "department",
        yLowKey: "low",
        yHighKey: "high",
        fill: "#4caf50",
        stroke: "#27293d",
        // stroke: "#388e3c",
        strokeWidth: 2, // Border width of the data points
        highlightStyle: {
          fill: "gold", // Color of the data points when highlighted
        },
        tooltip: {
          renderer: (params) => {
            return {
              content: `
                <b>End Customer:</b> ${params.datum.end_customer}<br/>
                <b>Machine:</b> ${params.datum.machine}<br/>
                <b>Operator:</b> ${params.datum.operator}<br>
                <b>Part:</b> ${params.datum.part}<br>
              `,
            };
          },
        },
      },
    ],
    axes: [
      {
        type: "time",
        position: "bottom",
        nice: "true",
        title: {
          text: "Time",
          fontSize: 14,
          color: "rgba(255, 255, 255, 0.8)",
        },
        label: {
          fontSize: 12,
          color: "rgba(255, 255, 255, 0.8)",
          formatter: (params) => {
            const date = new Date(params.value);
            const hours = date.getHours().toString().padStart(2, "0");
            const minutes = date.getMinutes().toString().padStart(2, "0");
            let shift = "Shift 3"; // Default to Shift 3
  
            if (hours >= 8 && hours < 16) {
              shift = "Shift 1";
            } else if (hours >= 16 && hours < 24) {
              shift = "Shift 2";
            }
            else{
              shift = "Shift 3";
            }
  
            return `${hours}:${minutes} (${shift})`; // Display only the hour and minute
          },
        },
        // tick: {
        //   count: (axis) => {
        //     const range = axis.getActualRange();
        //     const diffHours = (range[1] - range[0]) / 36e5; // Convert milliseconds to hours
        //     return Math.ceil(diffHours);
        //   },
        // },
      },
      {
        type: "category",
        position: "left",
        keys: ["department"],
        title: {
          text: "Operator",
          fontSize: 14,
          color: "rgba(255, 255, 255, 0.8)",
        },
        label: {
          fontSize: 12,
          color: "rgba(255, 255, 255, 0.8)",
        },
      },
    ],
  });

  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [operators, setOperators] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState("");
  const [selectedData, setSelectedData] = useState(null);
  const [productionPlanData, setProductionPlanData] = useState([]);
  const [operatorName, setOperatorName] = useState("");
  const [operatorId, setOperatorId] = useState("");
  const [fetchDate, setFetchedDate] = useState("");

  useEffect(() => {
    const fetchProductionPlanData = async () => {
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
              query ProductionPlan {
                operator_production_plan_assignment {
                  date
                  operator
                  operator_id
                  part_no
                  plan_id
                  opertoprod {
                    production_starttime_desired
                    end_customer
                    machineid
                    machine_name
                  }
                }
              }
            `,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );

        const productionPlanData =
          response.data.data.operator_production_plan_assignment;
        setProductionPlanData(productionPlanData);
        const uniqueDates = [
          ...new Set(productionPlanData.map((item) => item.date)),
        ];
        setDates(uniqueDates);
      } catch (error) {}
    };

    fetchProductionPlanData();
  }, []);

  useEffect(() => {
    if (selectedDate) {
      const operatorsByDate = productionPlanData
        .filter((item) => item.date === selectedDate)
        .map((item) => item.operator);
      setOperators([...new Set(operatorsByDate)]);
    }
  }, [selectedDate, productionPlanData]);

  const fetchDataByOperator = async () => {
    try {
      console.log("productionPlanData in operator---->", productionPlanData);

      // Sort data by operator and production start time
      productionPlanData.sort((a, b) => {
        if (a.operator === b.operator) {
          if (a.opertoprod && b.opertoprod) {
            return new Date(a.opertoprod.production_starttime_desired) >
              new Date(b.opertoprod.production_starttime_desired)
              ? 1
              : -1;
          }
          return 0;
        }
        return a.operator > b.operator ? 1 : -1;
      });

      // Generate data for the Gantt chart
      const chartData = productionPlanData
        .filter((item) => item.date === selectedDate && item.opertoprod)
        .map((item, index, self) => {
          const start = convertUtcToIst(
            item.opertoprod.production_starttime_desired
          );
          const end = new Date(start);

          // Find the next operation for the same operator
          const nextItem = self
            .slice(index + 1)
            .find((i) => i.operator === item.operator && i.opertoprod);

          // If next operation exists, set end time to its start time
          if (nextItem) {
            end.setTime(
              convertUtcToIst(
                nextItem.opertoprod.production_starttime_desired
              ).getTime()
            );
          } else {
            // Otherwise, set end time to start time plus 30 minutes
            end.setMinutes(end.getMinutes() + 30);
          }

          return {
            department: item.operator, // Assuming operator name as department
            low: start,
            high: end,
            end_customer: item.opertoprod.end_customer, // Include end_customer in the data
            machine: item.opertoprod.machineid, // Include machineid in the data
            operator: item.operator,
            part: item.part_no,
          };
        });

      setOptions({
        ...options,
        data: chartData,
      });
    } catch (error) {
      console.error(error);
    }
  };
  //main code
  // const fetchDataByOperator = async () => {
  //   try {
  //     console.log("productionPlanData in operator---->",productionPlanData)
  //     // Generate data for the Gantt chart
  //     const chartData = productionPlanData
  //       .filter((item) => item.date === selectedDate)
  //       .map((item) => {
  //         const start = convertUtcToIst(
  //           item.opertoprod.production_starttime_desired
  //         );
  //         const end = new Date(start);
  //         end.setMinutes(end.getMinutes() + 60); // Assume each operation lasts for 60 minutes
  //         console.log("end---->",end)
  //         return {
  //           department: item.operator, // Assuming operator name as department
  //           low: start,
  //           high: end,
  //           end_customer: item.opertoprod.end_customer, // Include end_customer in the data
  //           machine: item.opertoprod.machineid, // Include machineid in the data
  //           operator: item.operator,
  //           part: item.part_no,
  //         };
  //       });

  //     setOptions({
  //       ...options,
  //       data: chartData,
  //     });
  //   } catch (error) {
  //   }
  // };
  console.log("options-------->", options);
  const handleViewButtonClick = () => {
    if (selectedDate) {
      fetchDataByOperator();
    } else {
      // Handle error or show a message
    }
  };

  return (
    <div className="content">
      <Row>
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Operator Utilization Schedule</h5>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col className="px-md-1" md="4">
                    <FormGroup>
                      <Label for="date">Date</Label>
                      <Input
                        type="select"
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(e.target.value)}
                      >
                        <option value="">Select Date</option>
                        {dates
                          .sort((a, b) => new Date(b) - new Date(a))
                          .map((date) => (
                            <option key={date} value={date}>
                              {date}
                            </option>
                          ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="view"> </Label>
                      <Button
                        style={{ marginTop: "22px" }}
                        onClick={handleViewButtonClick}
                      >
                        View
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
                <div></div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <div>
        <AgChartsReact options={options} />
      </div>
    </div>
  );
}

export default GanttChart;
