import React, { useState, useEffect } from "react";
import classNames from "classnames";
// import "./style.css";
// import "./responsive.css";
// import "./progress.css";
// import "./gauge.css";
import timeLoss from "assets/img/timeLoss.svg";
import PopUpTotalDownTime from "components/New Production Volume/PopupTotalDownTime";
import PopUpActualProductionTime from "components/New Production Volume/PopUpActualProductionTime";
import PopupChart from "components/New WorkForce Efficiency/PopUpTrendLine";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { chartExample2 } from "variables/charts.js";
import "react-circular-progressbar/dist/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavLink } from "react-router-dom";
import redirectToLoginIfTokenMissing from "views/TokenUtility.js";
import axios from "axios";
import { APIURL } from "views/Apiurl";
import { QueryApi } from "QueryApi";
import TableComponent from "components/New Productivity/Productivity Grids/TableComponent";
// import GridProductionVolume from "components/New Production Volume/GridProductionVolume.js";
import JumboGraphs from "./JumboGraphs";
import SliterGraph from "./SliterGraph";

function JindalSliter(props) {
  const storedTenantId = sessionStorage.getItem("selectedTenantId");
  console.log("selected Tenant ID:", storedTenantId);
  useEffect(() => {
    redirectToLoginIfTokenMissing(); // Call the utility function to validate the token
    // Your component's logic...
  }, []);
  const [org, setorg] = useState("");
  const [orgnames, setOrgnames] = useState([]);

  const [unit, setunit] = useState("");

  const [dept, setdept] = useState("");
  const [depts, setdepts] = useState([]);

  const [plants, setplants] = useState([]);
  const [orgs, setorgs] = useState([]);
  const [otherData, setOtherData] = useState([]);
  const [selectedOrgData, setSelectedOrgData] = useState(null);
  const token = sessionStorage.getItem("token");
  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const tenantId =
    decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];

  const [PopUpTotalDownTimeOpen, setPopUpTotalDownTimeOpen] = useState(false);

  const [PopUpActualProductionTimeOpen, setPopUpActualProductionTimeOpen] =
    useState(false);

  const [
    popUpOvertimeMachineAvailability,
    setPopUpOvertimeMachineAvailability,
  ] = useState(false);
  const [popUpProductionTargetAdherance, setPopUpProductionTargetAdherance] =
    useState(false);
  const [popUpProductionVolumeDeviation, setPopUpProductionVolumeDeviation] =
    useState(false);

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const currDay = day < 10 ? `0${day}` : day;
  const currMonth = month < 10 ? `0${month}` : month;
  let currentDate = JSON.stringify(`${year}-${currMonth}-${currDay}`);

  const dateData = new Date();
  dateData.setDate(dateData.getDate() - 7);
  var dayDataValue = dateData.getDate();
  var monthDataValue = dateData.getMonth() + 1;
  var yearDataValue = dateData.getFullYear();
  var formattedDay = dayDataValue < 10 ? `0${dayDataValue}` : dayDataValue;
  var formattedMonth =
    monthDataValue < 10 ? `0${monthDataValue}` : monthDataValue;
  var weekDataDate = JSON.stringify(
    `${yearDataValue}-${formattedMonth}-${formattedDay}`
  );

  const previousDate = new Date();
  previousDate.setDate(1);
  const previousDay = previousDate.getDate();
  const previousMonth = previousDate.getMonth() + 1;
  const previousYear = previousDate.getFullYear();
  const formattedPreviousDay =
    previousDay < 10 ? `0${previousDay}` : previousDay;
  const formattedPreviousMonth =
    previousMonth < 10 ? `0${previousMonth}` : previousMonth;
  const previousMonthDate = JSON.stringify(
    `${previousYear}-${formattedPreviousMonth}-${formattedPreviousDay}`
  );

  const yearCurrentDate = new Date();
  yearCurrentDate.setDate(1);
  yearCurrentDate.setMonth(1);
  const yearDay = yearCurrentDate.getDate();
  const yearMonth = yearCurrentDate.getMonth();
  const yearYear = yearCurrentDate.getFullYear();
  const formattedYearDay = yearDay < 10 ? `0${yearDay}` : yearDay;
  const formattedYearMonth = yearMonth < 10 ? `0${yearMonth}` : yearMonth;
  const yearPreviousMonthDate = JSON.stringify(
    `${yearYear}-${formattedYearMonth}-${formattedYearDay}`
  );

  useEffect(() => {
    fetchplant();
  }, [org]);
  const fetchorg = async (logintenantId) => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
          query MyQuery($logintenantId: Int!) {
            tnt_organization_structure(
              where: {
                parent_organization_id: { _eq: 0 },
                tenant_id: { _eq: $logintenantId }
              }
            ) {
              organization_structure_id
              organization_node_name
            }
          }
        `,
          variables: {
            logintenantId: parseInt(logintenantId),
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      const fetchedOrgs = response.data.data.tnt_organization_structure;
      setorgs(fetchedOrgs);
      console.log(fetchedOrgs);
      const parentIds = fetchedOrgs.map((org) => org.organization_structure_id);
      console.log(parentIds);

      const childResponse = await axios.post(
        APIURL,
        {
          query: `
          query MyQuery($parentIds: [Int!]) {
            tnt_organization_structure(
              where: {
                parent_organization_id: { _in: $parentIds }
              }
            ) {
              organization_structure_id
              organization_node_name
            }
          }
        `,
          variables: {
            parentIds: parentIds,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      const childOrgs = childResponse.data.data.tnt_organization_structure;
      console.log(childOrgs);
      const updatedOtherData = otherData.map((row) => {
        const matchingOrg = childOrgs.find(
          (org) => org.organization_structure_id === row.parent_organization_id
        );

        if (matchingOrg) {
          return {
            ...row,
            parent_organization_id: matchingOrg.organization_structure_id,
          };
        }

        return row;
      });

      setOtherData(childOrgs);
      console.log(otherData);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["role_id"];
    let logintenantId = null;

    if (roleId === 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-tenant-id"];
    }

    fetchorg(logintenantId);
  }, []);
  const fetchplant = async () => {
    if (org) {
      // Only fetch if org is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
            query MyQuery($p: Int!) {
              tnt_organization_structure(where: {parent_organization_id: {_eq: $p}}) {
                organization_structure_id
                organization_node_name
              }
            }
          `,
            variables: {
              p: org,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        // console.log(org);
        setplants(response.data.data.tnt_organization_structure);
        console.log(response.data.data.tnt_organization_structure);
      } catch (error) {
        console.error(error);
      }
    }
  };
  useEffect(() => {
    fetchdept();
  }, [unit]);
  const fetchdept = async () => {
    if (unit) {
      // Only fetch if org is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
            query MyQuery($d: Int!) {
              tnt_organization_structure(where: {parent_organization_id: {_eq: $d}}) {
                organization_structure_id
                organization_node_name
              }
            }
          `,
            variables: {
              d: unit,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        // console.log(org);
        setdepts(response.data.data.tnt_organization_structure);
        console.log(response.data.data.tnt_organization_structure);
      } catch (error) {
        console.error(error);
      }
    }
  };
  console.log(process.env.REACT_APP_X_API_SECRET_KEY);

  const handleOrgSelect = async (selectedOrgId) => {
    if (selectedOrgId) {
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
            query GetCostCenter($orgId: Int!) {
              tnt_organization_structure(where: { organization_structure_id: { _eq: $orgId } }) {
                cost_centre_code
              }
            }
          `,
            variables: {
              orgId: selectedOrgId,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );

        if (
          response.data &&
          response.data.data &&
          response.data.data.tnt_organization_structure &&
          response.data.data.tnt_organization_structure.length > 0
        ) {
          setSelectedOrgData(response.data.data.tnt_organization_structure[0]);
        } else {
          console.log(response);
          setSelectedOrgData(null);
        }
      } catch (error) {
        console.error("Error fetching organization data:", error);
      }
    } else {
      setSelectedOrgData(null);
    }

    setorg(selectedOrgId);
  };

  const darkColor = "rgba(0, 0, 0, 0.8)";
  const lightColor = "rgba(0, 0, 0, 0.1)";

  const gradientSteps = 10; // Adjust the number of steps for the shading effect
  const colorStops = [];
  for (let i = 0; i <= gradientSteps; i++) {
    const intensity = i / gradientSteps;
    const r = Math.round(
      intensity * parseInt(lightColor.substring(4, 7)) +
        (1 - intensity) * parseInt(darkColor.substring(4, 7))
    );
    const g = Math.round(
      intensity * parseInt(lightColor.substring(9, 12)) +
        (1 - intensity) * parseInt(darkColor.substring(9, 12))
    );
    const b = Math.round(
      intensity * parseInt(lightColor.substring(14, 17)) +
        (1 - intensity) * parseInt(darkColor.substring(14, 17))
    );
    const colorStop = `rgb(${r}, ${g}, ${b})`;
    colorStops.push(colorStop);
  }
  const gradientColor = `linear-gradient(to right, ${colorStops.join(", ")})`;

  /////////Avg Number of machines available////////////////////////////////////////////////////////

  const [MachinesAvailableData, setMachinesAvailableData] = useState(0);
  useEffect(() => {
    MachinesAvailable();
  }, []);
  const MachinesAvailable = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
               dm_avg_machines_available_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                  aggregate {
                    avg {
                        available_machines_percent
                    }
                  }
                }
              }
       `);
      if (
        data === undefined ||
        data.dm_avg_machines_available_daily_aggregate.aggregate.avg
          .available_machines_percent === null
      ) {
        setMachinesAvailableData("No Data");
      } else {
        setMachinesAvailableData(
          data.dm_avg_machines_available_daily_aggregate.aggregate.avg.available_machines_percent.toFixed(
            1
          )
        );
      }
    } catch (error) {
      setMachinesAvailableData("No Data");
    }
  };

  const [MachinesAvailableList, setMachinesAvailableList] = useState([
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
  ]);

  useEffect(() => {
    MachinesAvailableDailyDataFun();
  }, []);
  const MachinesAvailableDailyDataFun = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}) {
            dm_avg_machines_available_daily_aggregate(where: {date: {_gte: $date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                  available_machines_percent
                }
                max {
                  available_machines_percent
                }
                min {
                  available_machines_percent
                }
              }
            }
          }
    `);
      if (data !== undefined) {
        setMachinesAvailableList((prevData) => {
          const newarr = [...prevData];
          newarr[0] = {
            percentage:
              data.dm_avg_machines_available_daily_aggregate.aggregate.min
                .available_machines_percent !== null
                ? data.dm_avg_machines_available_daily_aggregate.aggregate.min.available_machines_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[4] = {
            percentage:
              data.dm_avg_machines_available_daily_aggregate.aggregate.avg
                .available_machines_percent !== null
                ? data.dm_avg_machines_available_daily_aggregate.aggregate.avg.available_machines_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[8] = {
            percentage:
              data.dm_avg_machines_available_daily_aggregate.aggregate.max
                .available_machines_percent !== null
                ? data.dm_avg_machines_available_daily_aggregate.aggregate.max.available_machines_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setMachinesAvailableList((prevData) => {
        const newarr = [...prevData];
        newarr[0] = { percentage: "No Data" };
        newarr[4] = { percentage: "No Data" };
        newarr[8] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    MachinesAvailableWeeklyDataFun();
  }, []);
  const MachinesAvailableWeeklyDataFun = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_avg_machines_available_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                  available_machines_percent
                }
                max {
                  available_machines_percent
                }
                min {
                  available_machines_percent
                }
              }
            }
          }
    `);
      if (data !== undefined) {
        setMachinesAvailableList((prevData) => {
          const newarr = [...prevData];
          newarr[1] = {
            percentage:
              data.dm_avg_machines_available_daily_aggregate.aggregate.min
                .available_machines_percent !== null
                ? data.dm_avg_machines_available_daily_aggregate.aggregate.min.available_machines_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[5] = {
            percentage:
              data.dm_avg_machines_available_daily_aggregate.aggregate.avg
                .available_machines_percent !== null
                ? data.dm_avg_machines_available_daily_aggregate.aggregate.avg.available_machines_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[9] = {
            percentage:
              data.dm_avg_machines_available_daily_aggregate.aggregate.max
                .available_machines_percent !== null
                ? data.dm_avg_machines_available_daily_aggregate.aggregate.max.available_machines_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setMachinesAvailableList((prevData) => {
        const newarr = [...prevData];
        newarr[1] = { percentage: "No Data" };
        newarr[5] = { percentage: "No Data" };
        newarr[9] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    MachinesAvailableMonthlyDataFun();
  }, []);
  const MachinesAvailableMonthlyDataFun = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_avg_machines_available_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                  available_machines_percent
                }
                max {
                  available_machines_percent
                }
                min {
                  available_machines_percent
                }
              }
            }
          }
    `);
      if (data !== undefined) {
        setMachinesAvailableList((prevData) => {
          const newarr = [...prevData];
          newarr[2] = {
            percentage:
              data.dm_avg_machines_available_daily_aggregate.aggregate.min
                .available_machines_percent !== null
                ? data.dm_avg_machines_available_daily_aggregate.aggregate.min.available_machines_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[6] = {
            percentage:
              data.dm_avg_machines_available_daily_aggregate.aggregate.avg
                .available_machines_percent !== null
                ? data.dm_avg_machines_available_daily_aggregate.aggregate.avg.available_machines_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[10] = {
            percentage:
              data.dm_avg_machines_available_daily_aggregate.aggregate.max
                .available_machines_percent !== null
                ? data.dm_avg_machines_available_daily_aggregate.aggregate.max.available_machines_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setMachinesAvailableList((prevData) => {
        const newarr = [...prevData];
        newarr[2] = { percentage: "No Data" };
        newarr[6] = { percentage: "No Data" };
        newarr[10] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    MachinesAvailableYearlyDataFun();
  }, []);
  const MachinesAvailableYearlyDataFun = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_avg_machines_available_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                  available_machines_percent
                }
                max {
                  available_machines_percent
                }
                min {
                  available_machines_percent
                }
              }
            }
          }
    `);
      if (data !== undefined) {
        setMachinesAvailableList((prevData) => {
          const newarr = [...prevData];
          newarr[3] = {
            percentage:
              data.dm_avg_machines_available_daily_aggregate.aggregate.min
                .available_machines_percent !== null
                ? data.dm_avg_machines_available_daily_aggregate.aggregate.min.available_machines_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[7] = {
            percentage:
              data.dm_avg_machines_available_daily_aggregate.aggregate.avg
                .available_machines_percent !== null
                ? data.dm_avg_machines_available_daily_aggregate.aggregate.avg.available_machines_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[11] = {
            percentage:
              data.dm_avg_machines_available_daily_aggregate.aggregate.max
                .available_machines_percent !== null
                ? data.dm_avg_machines_available_daily_aggregate.aggregate.max.available_machines_percent.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setMachinesAvailableList((prevData) => {
        const newarr = [...prevData];
        newarr[3] = { percentage: "No Data" };
        newarr[7] = { percentage: "No Data" };
        newarr[11] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  const [xAxisDataValueMachinesAvailable, setXAxisDataValueMachinesAvailable] =
    useState([]);
  const [plotPointMachinesAvailable, setPlotPointMachinesAvailable] = useState(
    []
  );
  const datasetMachinesAvailable = [
    {
      label: "Avg Number Of Machines Available",
      data: plotPointMachinesAvailable,
      borderColor: "#1F8EF1",
    },
  ];
  useEffect(() => {
    GraphDataListMachinesAvailable();
  }, []);
  const GraphDataListMachinesAvailable = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
                dm_avg_machines_available_daily(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                    available_machines_percent
                    timestamp
                }
              }
        `);
      const plotAllDataMachinesAvailable =
        data.dm_avg_machines_available_daily.map((data) => {
          const objData = {
            point: data.available_machines_percent,
            Xaxis: data.timestamp,
          };
          return objData;
        });

      const plotPointDataMachinesAvailable = plotAllDataMachinesAvailable.map(
        (data) => data.point
      );
      setPlotPointMachinesAvailable(plotPointDataMachinesAvailable);

      const xAxisPointDataMachinesAvailable = plotAllDataMachinesAvailable.map(
        (data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
          var m = "0" + d.getMinutes();
          var ampm = h >= 12 ? "PM" : "AM";
          h = h % 12;
          h = h ? h : 12;
          var fullTime = h + ":" + m.substr(-2) + " " + ampm;
          return fullTime;
        }
      );
      setXAxisDataValueMachinesAvailable(xAxisPointDataMachinesAvailable);
    } catch (error) {
      console.log(error);
    }
  };

  //////Total Down Time///////////////////////////////////////////////////////////////////////////

  const [TotalDownTimeData, setTotalDownTimeData] = useState(0);
  useEffect(() => {
    TotalDownTime();
  }, []);
  const TotalDownTime = async () => {
    try {
      const data = await QueryApi(`
              query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                  aggregate {
                    sum {
                        total_machine_downtime
                    }
                  }
                }
              }
        `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.sum.total_machine_downtime === null
      ) {
        setTotalDownTimeData("No Data");
      } else {
        const hours =
          data.dm_daily_aggregate.aggregate.sum.total_machine_downtime /
          (1000 * 60 * 60);
        setTotalDownTimeData(hours.toFixed(2));
      }
    } catch (error) {
      console.log(error);
      setTotalDownTimeData("No Data");
    }
  };

  const [TotalDownTimeList, setTotalDownTimeList] = useState([
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
  ]);

  useEffect(() => {
    TotalDownTimeDailyDataFun();
  }, []);
  const TotalDownTimeDailyDataFun = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}) {
            dm_foundation_aggregate(where: {date: {_gte: $date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                avg {
                    total_machine_downtime
                }
                max {
                    total_machine_downtime
                }
                min{
                    total_machine_downtime
                }
                }
            }
          }
    `);
      if (data !== undefined) {
        setTotalDownTimeList((prevData) => {
          const newarr = [...prevData];
          newarr[0] = {
            percentage:
              data.dm_foundation_aggregate.aggregate.min
                .total_machine_downtime !== null
                ? (
                    data.dm_foundation_aggregate.aggregate.min
                      .total_machine_downtime /
                    (1000 * 60 * 60)
                  ).toFixed(2)
                : "No Data",
          };
          newarr[4] = {
            percentage:
              data.dm_foundation_aggregate.aggregate.avg
                .total_machine_downtime !== null
                ? (
                    data.dm_foundation_aggregate.aggregate.avg
                      .total_machine_downtime /
                    (1000 * 60 * 60)
                  ).toFixed(2)
                : "No Data",
          };
          newarr[8] = {
            percentage:
              data.dm_foundation_aggregate.aggregate.max
                .total_machine_downtime !== null
                ? (
                    data.dm_foundation_aggregate.aggregate.max
                      .total_machine_downtime /
                    (1000 * 60 * 60)
                  ).toFixed(2)
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setTotalDownTimeList((prevData) => {
        const newarr = [...prevData];
        newarr[0] = { percentage: "No Data" };
        newarr[4] = { percentage: "No Data" };
        newarr[8] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    TotalDownTimeWeeklyDataFun();
  }, []);
  const TotalDownTimeWeeklyDataFun = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                  total_machine_downtime
                }
                max {
                  total_machine_downtime
                }
                min {
                  total_machine_downtime
                }
              }
            }
          }
    `);
      if (data !== undefined) {
        setTotalDownTimeList((prevData) => {
          const newarr = [...prevData];
          newarr[1] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min.total_machine_downtime !==
              null
                ? (
                    data.dm_daily_aggregate.aggregate.min
                      .total_machine_downtime /
                    (1000 * 60 * 60)
                  ).toFixed(2)
                : "No Data",
          };
          newarr[5] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg.total_machine_downtime !==
              null
                ? (
                    data.dm_daily_aggregate.aggregate.avg
                      .total_machine_downtime /
                    (1000 * 60 * 60)
                  ).toFixed(2)
                : "No Data",
          };
          newarr[9] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max.total_machine_downtime !==
              null
                ? (
                    data.dm_daily_aggregate.aggregate.max
                      .total_machine_downtime /
                    (1000 * 60 * 60)
                  ).toFixed(2)
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setTotalDownTimeList((prevData) => {
        const newarr = [...prevData];
        newarr[1] = { percentage: "No Data" };
        newarr[5] = { percentage: "No Data" };
        newarr[9] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    TotalDownTimeMonthlyDataFun();
  }, []);
  const TotalDownTimeMonthlyDataFun = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                  total_machine_downtime
                }
                max {
                  total_machine_downtime
                }
                min {
                  total_machine_downtime
                }
              }
            }
          }
    `);
      if (data !== undefined) {
        setTotalDownTimeList((prevData) => {
          const newarr = [...prevData];
          newarr[2] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min.total_machine_downtime !==
              null
                ? (
                    data.dm_daily_aggregate.aggregate.min
                      .total_machine_downtime /
                    (1000 * 60 * 60)
                  ).toFixed(2)
                : "No Data",
          };
          newarr[6] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg.total_machine_downtime !==
              null
                ? (
                    data.dm_daily_aggregate.aggregate.avg
                      .total_machine_downtime /
                    (1000 * 60 * 60)
                  ).toFixed(2)
                : "No Data",
          };
          newarr[10] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max.total_machine_downtime !==
              null
                ? (
                    data.dm_daily_aggregate.aggregate.max
                      .total_machine_downtime /
                    (1000 * 60 * 60)
                  ).toFixed(2)
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setTotalDownTimeList((prevData) => {
        const newarr = [...prevData];
        newarr[2] = { percentage: "No Data" };
        newarr[6] = { percentage: "No Data" };
        newarr[10] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    TotalDownTimeYearlyDataFun();
  }, []);
  const TotalDownTimeYearlyDataFun = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                  total_machine_downtime
                }
                max {
                  total_machine_downtime
                }
                min {
                  total_machine_downtime
                }
              }
            }
          }
    `);
      if (data !== undefined) {
        setTotalDownTimeList((prevData) => {
          const newarr = [...prevData];
          newarr[3] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min.total_machine_downtime !==
              null
                ? (
                    data.dm_daily_aggregate.aggregate.min
                      .total_machine_downtime /
                    (1000 * 60 * 60)
                  ).toFixed(2)
                : "No Data",
          };
          newarr[7] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg.total_machine_downtime !==
              null
                ? (
                    data.dm_daily_aggregate.aggregate.avg
                      .total_machine_downtime /
                    (1000 * 60 * 60)
                  ).toFixed(2)
                : "No Data",
          };
          newarr[11] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max.total_machine_downtime !==
              null
                ? (
                    data.dm_daily_aggregate.aggregate.max
                      .total_machine_downtime /
                    (1000 * 60 * 60)
                  ).toFixed(2)
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setTotalDownTimeList((prevData) => {
        const newarr = [...prevData];
        newarr[3] = { percentage: "No Data" };
        newarr[7] = { percentage: "No Data" };
        newarr[11] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  const [xAxisDataValueTotalDownTime, setXAxisDataValueTotalDownTime] =
    useState([]);
  const [plotPointTotalDownTime, setPlotPointTotalDownTime] = useState([]);
  const datasetTotalDownTime = [
    {
      label: "Total Down Time",
      data: plotPointTotalDownTime,
      borderColor: "#1F8EF1",
    },
  ];
  useEffect(() => {
    GraphDataListTotalDownTime();
  }, []);
  const GraphDataListTotalDownTime = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
                dm_foundation(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                    total_machine_downtime
                    timestamp
                }
              }
        `);
      const plotAllDataTotalDownTime = data.dm_foundation.map((data) => {
        const objData = {
          point: data.total_machine_downtime,
          Xaxis: data.timestamp,
        };
        return objData;
      });

      const plotPointDataTotalDownTime = plotAllDataTotalDownTime.map(
        (data) => data.point
      );
      setPlotPointTotalDownTime(plotPointDataTotalDownTime);

      const xAxisPointDataTotalDownTime = plotAllDataTotalDownTime.map(
        (data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
          var m = "0" + d.getMinutes();
          var ampm = h >= 12 ? "PM" : "AM";
          h = h % 12;
          h = h ? h : 12;
          var fullTime = h + ":" + m.substr(-2) + " " + ampm;
          return fullTime;
        }
      );
      setXAxisDataValueTotalDownTime(xAxisPointDataTotalDownTime);
    } catch (error) {
      console.log(error);
    }
  };

  //// Actual Production Time ////////////////////////////////////////////////////////////////////

  const [ActualProductionTimeDataList, setActualProductionTimeDataList] =
    useState({
      actual_production_time: "No Data",
    });
  useEffect(() => {
    ActualProductionTime();
  }, []);
  const ActualProductionTime = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
              dm_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                    avg {
                        actual_production_time
                    }
                }
              }
            } `);
      const hoursDowntime =
        data.dm_daily_aggregate.aggregate.avg.actual_production_time /
        (1000 * 60 * 60);
      setActualProductionTimeDataList({
        actual_production_time: hoursDowntime.toFixed(2),
      });
    } catch (error) {
      setActualProductionTimeDataList({
        actual_production_time: "No Data",
      });
    }
  };

  const [ActualProductionList, setActualProductionList] = useState([
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
  ]);

  useEffect(() => {
    ActualProductionDailyDataFun();
  }, []);
  const ActualProductionDailyDataFun = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}) {
            dm_foundation_aggregate(where: {date: {_gte: $date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                avg {
                    actual_production_time
                }
                max {
                    actual_production_time
                }
                min{
                    actual_production_time
                }
                }
            }
          }
    `);
      if (data !== undefined) {
        setActualProductionList((prevData) => {
          const newarr = [...prevData];
          newarr[0] = {
            percentage:
              data.dm_foundation_aggregate.aggregate.min
                .actual_production_time !== null
                ? (
                    data.dm_foundation_aggregate.aggregate.min
                      .actual_production_time /
                    (1000 * 60 * 60)
                  ).toFixed(2)
                : "No Data",
          };
          newarr[4] = {
            percentage:
              data.dm_foundation_aggregate.aggregate.avg
                .actual_production_time !== null
                ? (
                    data.dm_foundation_aggregate.aggregate.avg
                      .actual_production_time /
                    (1000 * 60 * 60)
                  ).toFixed(2)
                : "No Data",
          };
          newarr[8] = {
            percentage:
              data.dm_foundation_aggregate.aggregate.max
                .actual_production_time !== null
                ? (
                    data.dm_foundation_aggregate.aggregate.max
                      .actual_production_time /
                    (1000 * 60 * 60)
                  ).toFixed(2)
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setActualProductionList((prevData) => {
        const newarr = [...prevData];
        newarr[0] = { percentage: "No Data" };
        newarr[4] = { percentage: "No Data" };
        newarr[8] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    ActualProductionWeeklyDataFun();
  }, []);
  const ActualProductionWeeklyDataFun = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                  actual_production_time
                }
                max {
                  actual_production_time
                }
                min {
                  actual_production_time
                }
              }
            }
          }
    `);
      if (data !== undefined) {
        setActualProductionList((prevData) => {
          const newarr = [...prevData];
          newarr[1] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min.actual_production_time !==
              null
                ? (
                    data.dm_daily_aggregate.aggregate.min
                      .actual_production_time /
                    (1000 * 60 * 60)
                  ).toFixed(2)
                : "No Data",
          };
          newarr[5] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg.actual_production_time !==
              null
                ? (
                    data.dm_daily_aggregate.aggregate.avg
                      .actual_production_time /
                    (1000 * 60 * 60)
                  ).toFixed(2)
                : "No Data",
          };
          newarr[9] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max.actual_production_time !==
              null
                ? (
                    data.dm_daily_aggregate.aggregate.max
                      .actual_production_time /
                    (1000 * 60 * 60)
                  ).toFixed(2)
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setActualProductionList((prevData) => {
        const newarr = [...prevData];
        newarr[1] = { percentage: "No Data" };
        newarr[5] = { percentage: "No Data" };
        newarr[9] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    ActualProductionMonthlyDataFun();
  }, []);
  const ActualProductionMonthlyDataFun = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                  actual_production_time
                }
                max {
                  actual_production_time
                }
                min {
                  actual_production_time
                }
              }
            }
          }
    `);
      if (data !== undefined) {
        setActualProductionList((prevData) => {
          const newarr = [...prevData];
          newarr[2] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min.actual_production_time !==
              null
                ? (
                    data.dm_daily_aggregate.aggregate.min
                      .actual_production_time /
                    (1000 * 60 * 60)
                  ).toFixed(2)
                : "No Data",
          };
          newarr[6] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg.actual_production_time !==
              null
                ? (
                    data.dm_daily_aggregate.aggregate.avg
                      .actual_production_time /
                    (1000 * 60 * 60)
                  ).toFixed(2)
                : "No Data",
          };
          newarr[10] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max.actual_production_time !==
              null
                ? (
                    data.dm_daily_aggregate.aggregate.max
                      .actual_production_time /
                    (1000 * 60 * 60)
                  ).toFixed(2)
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setActualProductionList((prevData) => {
        const newarr = [...prevData];
        newarr[2] = { percentage: "No Data" };
        newarr[6] = { percentage: "No Data" };
        newarr[10] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    ActualProductionYearlyDataFun();
  }, []);
  const ActualProductionYearlyDataFun = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                  actual_production_time
                }
                max {
                  actual_production_time
                }
                min {
                  actual_production_time
                }
              }
            }
          }
    `);
      if (data !== undefined) {
        setActualProductionList((prevData) => {
          const newarr = [...prevData];
          newarr[3] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min.actual_production_time !==
              null
                ? (
                    data.dm_daily_aggregate.aggregate.min
                      .actual_production_time /
                    (1000 * 60 * 60)
                  ).toFixed(2)
                : "No Data",
          };
          newarr[7] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg.actual_production_time !==
              null
                ? (
                    data.dm_daily_aggregate.aggregate.avg
                      .actual_production_time /
                    (1000 * 60 * 60)
                  ).toFixed(2)
                : "No Data",
          };
          newarr[11] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max.actual_production_time !==
              null
                ? (
                    data.dm_daily_aggregate.aggregate.max
                      .actual_production_time /
                    (1000 * 60 * 60)
                  ).toFixed(2)
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setActualProductionList((prevData) => {
        const newarr = [...prevData];
        newarr[3] = { percentage: "No Data" };
        newarr[7] = { percentage: "No Data" };
        newarr[11] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  const [xAxisDataValueActualProduction, setXAxisDataValueActualProduction] =
    useState([]);
  const [plotPointActualProduction, setPlotPointActualProduction] = useState(
    []
  );
  const datasetActualProduction = [
    {
      label: "Avg Number Of Machines Available",
      data: plotPointActualProduction,
      borderColor: "#1F8EF1",
    },
  ];
  useEffect(() => {
    GraphDataListActualProduction();
  }, []);
  const GraphDataListActualProduction = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
                dm_foundation(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                    actual_production_time
                    timestamp
                }
              }
        `);
      const plotAllDataActualProduction = data.dm_foundation.map((data) => {
        const objData = {
          point: data.actual_production_time,
          Xaxis: data.timestamp,
        };
        return objData;
      });

      const plotPointDataActualProduction = plotAllDataActualProduction.map(
        (data) => data.point
      );
      setPlotPointActualProduction(plotPointDataActualProduction);

      const xAxisPointDataActualProduction = plotAllDataActualProduction.map(
        (data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
          var m = "0" + d.getMinutes();
          var ampm = h >= 12 ? "PM" : "AM";
          h = h % 12;
          h = h ? h : 12;
          var fullTime = h + ":" + m.substr(-2) + " " + ampm;
          return fullTime;
        }
      );
      setXAxisDataValueActualProduction(xAxisPointDataActualProduction);
    } catch (error) {
      console.log(error);
    }
  };

  /////// Avg (YTD) /////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [AvgData, setAvgData] = useState("No data");
  useEffect(() => {
    Avg();
  }, []);
  const Avg = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                    plant_operating_time_percent
                }
              }
            }
          }
    `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent ===
          null
      ) {
        setAvgData("No Data");
      } else {
        setAvgData(
          data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent.toFixed(
            1
          )
        );
      }
    } catch (error) {
      console.log(error);
      setAvgData("No Data");
    }
  };

  ///////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////

  const [bigChartData, setBgChartData] = React.useState("data1");
  const [productionVolumeAllData, setProductionVolumeAllData] = useState([]);
  useEffect(() => {
    fetchAllCardData();
  }, [bigChartData]);
  const fetchAllCardData = async () => {
    if (bigChartData === "data1") {
      try {
        const data = await QueryApi(`
        query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
          dm_org_tenant_daily(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
            total_machine_downtime
            actual_production_time
            }
          }
        `);
        setProductionVolumeAllData(
          data !== undefined ? data.dm_org_tenant_daily[0] : []
        );
      } catch (error) {
        setProductionVolumeAllData([]);
      }
    } else if (bigChartData === "data2") {
      try {
        const data = await QueryApi(`
        query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! =  ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
          dm_org_tenant_weekly(where: {start_date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
            total_machine_downtime
            actual_production_time
            }
          }
        `);
        setProductionVolumeAllData(
          data !== undefined ? data.dm_org_tenant_weekly[0] : []
        );
      } catch (error) {
        setProductionVolumeAllData([]);
      }
    } else if (bigChartData === "data3") {
      try {
        const data = await QueryApi(`
        query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! =  ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
          dm_org_tenant_monthly(where: {start_date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
            total_machine_downtime
            actual_production_time
            }
          }
        `);
        setProductionVolumeAllData(
          data !== undefined ? data.dm_org_tenant_monthly[0] : []
        );
      } catch (error) {
        setProductionVolumeAllData([]);
      }
    } else if (bigChartData === "data4") {
      try {
        const data = await QueryApi(`
        query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
          dm_org_tenant_yearly(where: {start_date: {_eq: $start_date}, tenantid: {_eq: $tenantid}}) {
            total_machine_downtime
            actual_production_time
            }
          }
        `);
        setProductionVolumeAllData(data.dm_org_tenant_yearly[0]);
      } catch (error) {
        setProductionVolumeAllData([]);
      }
    }
  };
  return (
    <>
      <div className="content">
        <Row>
          {/* <Col lg="3">
            <Card
              className="production-volume-header"
              // style={{ fontSize: "20px", fontWeight: "bold", color: "white" }}
              style={{
                height: "40px",
                fontSize: "16px",
                fontWeight: "bold",
                color: "white",
                marginTop: "30px",
                justifyContent: "center",
              }}
            >
              <span style={{ paddingLeft: "20px" }}>
                Production Volume - Level 1
              </span>
            </Card>
          </Col> */}
        </Row>
        <Card
          style={{
            background: "transparent",
            boxShadow: "0px 10px 20px 2px rgba(0,0,0,0.75)",
            height: "12rem",
          }}
        >
          <CardHeader>
            <div className="d-flex justify-content-end">
              <div>
                <ButtonGroup
                  className="btn-group-toggle float-right"
                  data-toggle="buttons"
                >
                  <Button
                    tag="label"
                    className={classNames("btn-simple", {
                      active: bigChartData === "data1",
                    })}
                    color="info"
                    id="0"
                    size="sm"
                    onClick={() => setBgChartData("data1")}
                  >
                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      Day
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="tim-icons icon-single-02" />
                    </span>
                  </Button>
                  <Button
                    color="info"
                    id="1"
                    size="sm"
                    tag="label"
                    className={classNames("btn-simple", {
                      active: bigChartData === "data2",
                    })}
                    onClick={() => setBgChartData("data2")}
                  >
                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      Week
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="tim-icons icon-gift-2" />
                    </span>
                  </Button>
                  <Button
                    color="info"
                    id="2"
                    size="sm"
                    tag="label"
                    className={classNames("btn-simple", {
                      active: bigChartData === "data3",
                    })}
                    onClick={() => setBgChartData("data3")}
                  >
                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      Month
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="tim-icons icon-tap-02" />
                    </span>
                  </Button>
                  <Button
                    color="info"
                    id="2"
                    size="sm"
                    tag="label"
                    className={classNames("btn-simple", {
                      active: bigChartData === "data4",
                    })}
                    onClick={() => setBgChartData("data4")}
                  >
                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      Year
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="tim-icons icon-tap-02" />
                    </span>
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "0.8rem",
                marginLeft: "0.5rem",
                height: "13rem",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr",
                    gap: "0.8rem",
                  }}
                >
                  {/* ////////////////card 1////////////////////// */}
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        No Data
                        {/* Total Down Time */}
                      </div>
                      <div

                      // style={{
                      //   fontSize: "1.5rem",
                      //   color:
                      //     productionVolumeAllData?.total_machine_downtime ===
                      //     "No Data"
                      //       ? "#1976D2"
                      //       : "#1976D2",
                      // }}
                      >
                        {/* {productionVolumeAllData?.total_machine_downtime
                          ? (
                              productionVolumeAllData?.total_machine_downtime /
                              (1000 * 60 * 60)
                            ).toFixed(2)
                          : "No Data"}
                        {productionVolumeAllData?.total_machine_downtime ===
                        "No Data"
                          ? ""
                          : productionVolumeAllData?.total_machine_downtime
                            ?  " Hour"
                            : ""} */}
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "100%",
                      }}
                    ></div>
                  </div>
                  {/* /////////////////card 2///////////////////////// */}
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        No Data
                      </div>
                      <div
                      // style={{
                      //   fontSize: "1.5rem",
                      //   color:
                      //     MachinesAvailableData === "No Data"
                      //       ? "#1976D2"
                      //       : "#1976D2",
                      // }}
                      >
                        {/* {MachinesAvailableData}
                        {MachinesAvailableData === "No Data" ? "" : "%"} */}
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "100%",
                      }}
                    >
                      <div
                        className="icon-plus"
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: 0,
                        }}
                      ></div>
                    </div>
                  </div>
                  {/* ///////////////card 3 ////////////////////////////// */}
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        {/* Actual Production Time */}
                        No Data
                      </div>
                      <div
                        style={{
                          fontSize: "1.5rem",
                          color:
                            productionVolumeAllData?.actual_production_time ===
                            "No Data"
                              ? "#1976D2"
                              : "#1976D2",
                        }}
                      >
                        {/* {productionVolumeAllData?.actual_production_time
                          ? (
                              productionVolumeAllData?.actual_production_time /
                              (1000 * 60 * 60)
                            ).toFixed(2)
                          : "No Data"}
                        {productionVolumeAllData?.actual_production_time ===
                        "No Data"
                          ? ""
                          : productionVolumeAllData?.actual_production_time
                            ? " Hour"
                            : ""} */}
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "100%",
                      }}
                    ></div>
                  </div>
                  {/* ///////////////// card 4 ////////////////////// */}
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        No Data
                        {/* Over time to Machine Availability */}
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        {/* No Data */}
                        {/* {{AvgData}}
                        {WorkforceProductivityData === "No Data" ||
                        WorkforceProductivityData === null
                          ? ""
                          : "%"} */}
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "100%",
                      }}
                    >
                      <div
                        className="icon-plus"
                        style={{
                          position: "absolute", // Position the icon absolutely
                          top: "10px", // Align it to the top
                          right: 0, // Align it to the right of the image
                        }}
                      ></div>
                    </div>
                  </div>
                  {/* /////////////////// card 5 ///////////////////////////
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                      No Data
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        No Data
                        
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "100%",
                      }}
                    >
                      
                    </div>
                   
                  </div>
                  //////////////////////// card 6 //////////////////////////
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                      No Data
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        No Data
                        
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "100%",
                      }}
                    >
                     
                      <div
                        className="icon-plus"
                        style={{
                          position: "absolute", // Position the icon absolutely
                          top: "10px", // Align it to the top
                          right: 0, // Align it to the right of the image
                        }}
                      >
                        <NavLink className="anchor-bg" href="#">
                          
                        </NavLink>
                      </div>
                    </div>
                   
                  </div> */}
                </div>

                {/* ////////////////////////////////////////////////// */}
                {/* <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                    gap: "0.8rem",
                    marginBottom: "0.6rem",
                    marginTop: "0.8rem",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        No Data
                      </div>
                      <div style={{ fontSize: "1.2rem", color: "#1976D2" }}>
                        No Data
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "5.5rem",
                        width: "3rem",
                      }}
                    >
                      <img
                      
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        No Data
                      </div>
                      <div style={{ fontSize: "1.2rem", color: "#1976D2" }}>
                      No Data
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "3rem",
                      }}
                    >
                      <img
                      
                      />
                    </div>
                  </div>
                  <div
                    
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        No Data
                      </div>
                      <div style={{ fontSize: "1.2rem", color: "#1976D2" }}>
                      No Data
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        No Data
                      </div>
                      <div style={{ fontSize: "1.2rem", color: "#1976D2" }}>
                      No Data
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        No Data
                      </div>
                      <div style={{ fontSize: "1.2rem", color: "#1976D2" }}>
                      No Data
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        No Data
                      </div>
                      <div style={{ fontSize: "1.2rem", color: "#1976D2" }}>
                        
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    ></div>
                  </div>
                </div> */}
              </div>
            </div>
          </CardBody>
        </Card>
        <Row>
          <Col lg="12">
            <SliterGraph />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default JindalSliter;
