import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Progress,
} from "reactstrap";
import axios from "axios";
import  { APIURL } from './Apiurl.js';
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

function UdpParameterMapping() {
  const [activeStep, setActiveStep] = useState(1);
  const [checked, setChecked] = useState(false);
  const [progress, setProgress] = useState(0);
  const [udpedit, setudpedit] = useState({
    machinetype: "",
    udpid: "",
    machinesubtype: "",
    pname: "",
    pcategory: "",
    ptype: "",
    unitofmeasure: "",
    rank: "",
  });

  const { id } = useParams();
  const navigate = useNavigate();
 
  useEffect(() => {
    axios
      .get(`${APIURL}/api/udp-parameter/${id}`)
      .then((response) => {
        setudpedit((prevState) => ({
          ...prevState,
          machinetype: response.data.machine_type,
          udpid: response.data.udp_id,
          machinesubtype: response.data.machine_subtype,
          pname: response.data.parameter_name, 
          pcategory: response.data.parameter_category,
          ptype: response.data.parameter_type,
          unitofmeasure: response.data.unit_of_measure,
          rank: response.data.rank,
        }));
      console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);


  const handleNext = () => {
    if (activeStep === 1 && progress === 0) {
      setProgress(50);
    } else if (activeStep === 2 && progress === 50) {
      setProgress(100);
    }
    setActiveStep(activeStep + 1);
  };

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if the machine name is provided
    // if (!udpedit.deviceprofile) {
    //   alert("Please enter a machine name.");
    //   return;
    // }

    axios
      .post(`${APIURL}/api/udp-parameter/${id}`, udpedit)
      .then((response) => {
        console.log(response);
        navigate(`/udp-parameter/${id}`);
        if (response.status === 201) {
          window.location.href = "/admin/udp-parameter/pm-all";
        } else {
          // Reset the form or show a success message
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setudpedit((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

 
  const renderStepContent = () => {
    switch (activeStep) {
      case 1:
        return (
          <>
              <Row>
                    
                    <Col className="px-md-1" md="6">
                      <FormGroup>
                        <label>Machine Type</label>
                        <Input
                          placeholder="Machine Type"
                          type="text"
                          name="machinetype"
                          value={udpedit.machinetype}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                        UDPID
                        </label>
                        <Input placeholder="UDPID" type="text" 
                        name="udpid"
                        value={udpedit.udpid}
                        onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Machine SubType</label>
                        <Input
                          placeholder="Machine SubType"
                          type="text"
                          name="machinesubtype"
                          value={udpedit.machinesubtype}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Parameter Name</label>
                        <Input
                          
                          placeholder="Parameter Name"
                          type="text"
                          name="pname"
                          value={udpedit.pname}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                   
                  </Row>
                  
          </>
        );
      case 2:
        return (
          <>
         <Row>
            <Col className="pr-md-1" md="6">
                
                <FormGroup>
                <label>Parameter Category</label>
                <Input
                    placeholder="Parameter Category"
                    type="text"
                    name="pcategory"
                    value={udpedit.pcategory}
                          onChange={handleChange}
                />
                </FormGroup>
            </Col>
            <Col className="pl-md-1" md="6">
                <FormGroup>
                <label>Parameter Type</label>
                <Input
                    
                    placeholder="Parameter Type"
                    type="text"
                    name="ptype"
                    value={udpedit.ptype}
                          onChange={handleChange}
                />
                </FormGroup>
            </Col>
            </Row>
          </>
        );
      case 3:
        return (
          <>
      <Row>
                  <Col className="pr-md-1" md="6">
                      
                      <FormGroup>
                        <label>Unit of Measure</label>
                        <Input
                          placeholder="Unit of Measure"
                          type="text"
                          name="unitofmeasure"
                          value={udpedit.unitofmeasure}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Rank</label>
                        <Input
                          
                          placeholder="Rank"
                          type="number"
                          value={udpedit.rank}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
          </>
        );
      default:
        return null;
    }
  };
  const calculateProgress = () => {
    return Math.round(((activeStep - 1) / 2) * 100); // Update the denominator to match the total number of steps minus one
  };
  

  const renderProgressBarDestinations = () => {
    const step1Class = activeStep >= 1 ? "progress-destination-active" : "";
    const step2Class = activeStep >= 2 ? "progress-destination-active" : "";
    const step3Class = activeStep >= 3 ? "progress-destination-active" : "";
    const step4Class = activeStep >= 4 ? "progress-destination-active" : "";
  
    return (
      <div className="progress-destinations">
        <div className={`progress-destination ${step1Class}`}>
          <span>1</span>
        </div>
        <div className={`progress-destination ${step2Class}`}>
          <span>2</span>
        </div>
        <div className={`progress-destination ${step3Class}`}>
          <span>3</span>
        </div>
        <div className={`progress-destination ${step4Class}`}>
          <span>4</span>
        </div>
      </div>
    );
  };

  return (
    <div className="content">
      <Row>
        <Col md="8">
          <div className="progress-container">
            {renderProgressBarDestinations()}
            <Progress
              max="100"
              value={calculateProgress()}
              className="progress-bar-sm progress-bar-striped"
            />
          </div>
        </Col>
      </Row>
      <Row className="progress-row">
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Add New Machine</h5>
            </CardHeader>
            <CardBody>
              <Form>{renderStepContent()}</Form>
            </CardBody>
            <CardFooter>
              <div className="text-right">
                {activeStep > 1 && (
                  <Button color="primary" onClick={handlePrevious}>
                    Previous
                  </Button>
                )}
                {activeStep < 3 && (
                  <Button color="primary" onClick={handleNext}>
                    Next
                  </Button>
                )}
                {activeStep === 3 && (
                  <Button color="success" onClick={handleSubmit}>
                    Submit
                  </Button>
                )}
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default UdpParameterMapping;
