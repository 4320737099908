import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from "reactstrap";
import axios from "axios";
import { APIURL } from "./Apiurl.js";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.css";

function JobrolemasterAdd() {
  const [dept, setdept] = useState("");
  const [dashboard, setdashboard] = useState("");
  const [purpose, setpurpose] = useState("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const token = sessionStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["sub"];
    console.log(roleId);
    let logintenantId = null;
    if (roleId === 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
    }
    const valueToSubmit = isCheckboxChecked ? 1 : 0;
    // Create an object with the form data
    const formData = {
      tenantid: logintenantId,
      dashboard_name: dashboard,
      purpose: purpose,
      is_default: valueToSubmit,
      is_universal: 0,
      is_active: "1",
    };
    console.log(formData);
    axios
      .post(
        APIURL,
        {
          query: `
            mutation Inserttenant_dashboard_master($formData:tenant_dashboard_master_insert_input!) {
                insert_tenant_dashboard_master(objects: [$formData]) {
                affected_rows
                }
            }
        `,
          variables: {
            formData: formData,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response); // Handle the response data as needed
        if (
          response.data.data &&
          response.data.data.insert_tenant_dashboard_master.affected_rows > 0
        ) {
          console.log("hello");
          setdashboard("");
          setpurpose("");
          setIsCheckboxChecked("");
          toast.success("Dashboard Created successfully!");
        } else {
          toast.error("Failed to insert data.");
        }
      })
      .catch((error) => {
        console.error(error); // Handle the error
        toast.error("An error occurred while inserting data.");
      });
  };

  return (
    <div className="content">
      <Row className="progress-row">
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Add Dashboard</h5>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col className="px-md-1" md="4">
                    <FormGroup>
                      <Label for="Department">Dashboard Name</Label>
                      <Input
                        placeholder="Enter Dashboard Name"
                        type="text"
                        value={dashboard}
                        onChange={(e) => setdashboard(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="px-md-1" md="4">
                    <FormGroup>
                      <Label for="Department">Purpose</Label>
                      <Input
                        placeholder="Enter Purpose"
                        type="text"
                        value={purpose}
                        onChange={(e) => setpurpose(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col id="checkbox-div" className="px-md-1" md="2">
                    <Label for="Department">Is Default</Label>
                    <FormGroup>
                      <div>
                        <input
                          type="checkbox"
                          id="checkbox"
                          checked={isCheckboxChecked}
                          onChange={() =>
                            setIsCheckboxChecked(!isCheckboxChecked)
                          }
                        />{" "}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
            <CardFooter>
              <div className="text-right">
                <Button color="success" onClick={handleFormSubmit}>
                  Submit
                </Button>
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default JobrolemasterAdd;
