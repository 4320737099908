import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "./Apiurl.js";
import redirectToLoginIfTokenMissing from "./TokenUtility";
import { CSVLink } from "react-csv";
import {
  TabContent,
  TabPane,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import "./datatable.css";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import Modal from "@material-ui/core/Modal";
import ConfigImport from "./ConfigImport";
import PartandCustomerAdd from "./PartandCustomerAdd.js";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PartAndCustomerEdit from "./PartAndCustomerEdit.js";
import { Link } from "react-router-dom";

const { SearchBar } = Search;

function PartandCustomers() {
  const [dataToExport, setDataToExport] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [struct, setstruct] = useState([]);
  const [orgstruct, setorgstruct] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleOpenEditModal = (row) => {
    setSelectedRow(row);
    setOpenEditModal(true);
  };
  const handleCloseEditModal = (row) => {
    // setSelectedRow(row);
    setOpenEditModal(false);
  };



  const token = sessionStorage.getItem("token");
  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const tenantId =
    decodedToken["https://hasura.io/jwt/claims"]["x-hasura-tenant-id"];
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleOpen1 = () => {
    setOpen1(true);
  };
  useEffect(() => {
    fetchData();
    redirectToLoginIfTokenMissing(); // Call the utility function to validate the token
  }, []);

  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const roleId = decodedToken["sub"];
      const logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
      const storedTenantId = sessionStorage.getItem("selectedTenantId");

      let query = ""; // Define the query variable outside the if-else block
      let variables = {}; // Define the variables object

      if (roleId == 1) {
        if (storedTenantId !== null) {
          query = `
        query MyQuery($TenantId: Int!) {
          tnt_orgnization_partscustomer_master(where: { tenant_id: { _eq: $TenantId } },order_by: {tnt_part_no: desc}) {
          created_at
          created_by
          created_on
          drawing
          end_customer_location
          end_customer_name
          material_specification
          paking_std
          part_name_description
          part_number
          pfd
          ppap
          ppap_revision_date
          process_plan_stagewise
          quality_plan_pdir
          revision_no
          revisionno_date
          tenantid
          tnt_part_no
          thickness
          updated_at
          toolidordieid
          tag_id
          }
        }
      `;

          variables = {
            TenantId: storedTenantId,
          };
        } else {
          query = `
        query MyQuery {
          tnt_orgnization_partscustomer_master(order_by: {tnt_part_no: desc}) {
            created_at
            created_by
            created_on
            drawing
            end_customer_location
            end_customer_name
            material_specification
            paking_std
            part_name_description
            part_number
            pfd
            ppap
            ppap_revision_date
            process_plan_stagewise
            quality_plan_pdir
            revision_no
            revisionno_date
            tenantid
            tnt_part_no
            thickness
            updated_at
            toolidordieid
            tag_id
          }
        } 
      `;
        }
      } else {
        query = ` 
      query MyQuery {
      tnt_orgnization_partscustomer_master(order_by: {tnt_part_no: desc}) {
        created_at
        created_by
        created_on
        drawing
        end_customer_location
        end_customer_name
        material_specification
        paking_std
        part_name_description
        part_number
        pfd
        ppap
        ppap_revision_date
        process_plan_stagewise
        quality_plan_pdir
        revision_no
        revisionno_date
        tenantid
        tnt_part_no
        thickness
        updated_at
        toolidordieid
        tag_id
      } }
      `;
      }

      const response = await axios.post(
        APIURL,
        {
          query: query,
          variables: variables,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const tnt_orgnization_partscustomer_master =
        response.data.data.tnt_orgnization_partscustomer_master.map(
          (structure) => ({
            ...structure,
            status: "On",
          })
        );
      setorgstruct(tnt_orgnization_partscustomer_master);
      setDataToExport(tnt_orgnization_partscustomer_master);
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    {
      dataField: "tnt_part_no",
      text: "end customer no",
      
    },
    {
      dataField: "end_customer_name",
      text: "end Customer Name",
    },
    {
      dataField: "end_customer_location",
      text: "end customer location",
    },
    {
      dataField: "part_number",
      text: "part number",
    },
    {
      dataField: "part_name_description",
      text: "Part name Description",
      headerStyle: { width: '500px' }
    },
    {
      dataField: "drawing",
      text: "drawing",
    },
    {
      dataField: "revision_no",
      text: "revision no",
    },
    {
      dataField: "revisionno_date",
      text: "revisionno date",
    },
    {
      dataField: "pfd",
      text: "PFD",
    },
    {
      dataField: "process_plan_stagewise",
      text: "process plan stagewise",
      headerStyle: { width: '500px' }
    },
    {
      dataField: "paking_std",
      text: "paking std",
    },
    {
      dataField: "quality_plan_pdir",
      text: "quality plan PDIR",
      headerStyle: { width: '500px' }
    },
    {
      dataField: "ppap",
      text: "PPAP",
    },
    {
      dataField: "ppap_revision_date",
      text: "PPAP revisiondate",
    },
    {
      dataField: "material_specification",
      text: "material specification",
      headerStyle: { width: '500px' }
    },
    {
      dataField: "toolidordieid",
      text: "Tool ID or Die ID",
    },
    {
      dataField: "tag_id",
      text: "Tag ID",
    },
    {
      dataField: "thickness",
      text: "thickness",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: actionFormatter,
    },
  ];

  function actionFormatter(cell, row) {
    return (
      <div>
        <Col
          className="font-icon-list col-xs-6 col-xs-6"
          lg="2"
          md="3"
          sm="4"
        ></Col>
        <FontAwesomeIcon
          icon={faPencilAlt}
          className="btn btn-primary tim-icons icon-pencil"
          style={{
            fontSize: "70%",
            background: "none",
            // marginRight: "-46px",
            // pointerEvents: "none",
            cursor: "pointer"
          }}
          onClick={() => handleOpenEditModal(row)}
        />
      </div>
    );
  }
  // function actionFormatter(cell, row) {
  //   return (
  //     <div>
  //       <Col
  //         className="font-icon-list col-xs-6 col-xs-6"
  //         lg="2"
  //         md="3"
  //         sm="4"
  //       ></Col>
  //       <Link to={`/admin/part-and-customer/${row.tnt_part_no}`}>
  //         <FontAwesomeIcon
  //           icon={faPencilAlt}
  //           className="btn btn-primary tim-icons icon-pencil"
  //           style={{
  //             fontSize: "70%",
  //             background: "none",
  //             marginRight: "-46px",
  //             pointerEvents: "none",
  //           }}
  //         />
  //       </Link>
  //     </div>
  //   );
  // }
  return (
    <div className="content">
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4" className="table-card-header">
                    MANAGE PART AND CUSTOMER
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={struct}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <div>
                        <div
                          className="float-right"
                          style={{
                            display: "flex",
                            marginBottom: "18px",
                            gap: "12px",
                          }}
                        >
                          <div className="float-left">
                            <SearchBar {...props.searchProps} />
                          </div>
                          <div className="float-right">
                            <button
                              className="btn btn-secondary"
                              onClick={handleOpen}
                            >
                              Create New Part and Customer
                            </button>
                            <button
                              className="btn btn-secondary"
                              onClick={handleOpen1}
                            >
                              Import CSV file
                            </button>
                            <CSVLink
                              data={dataToExport}
                              filename="part_and_customer_data.csv" // Customize the filename
                              className="btn btn-primary"
                            >
                              Export to CSV
                            </CSVLink>
                          </div>
                        </div>
                        <div className="data-table-plan-admin">
                          <BootstrapTable
                            className="table-part-customer"
                            {...props.baseProps}
                            keyField="id"
                            data={orgstruct}
                            columns={columns}
                            filter={filterFactory()}
                            pagination={paginationFactory({
                              bootstrap4: true,
                            })}                            
                          headerClasses="sorted-asc sorted-desc"
                          wrapperClasses="table-responsive"
                          />
                        </div>
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId={2}>{/* Content for Tab 2 */}</TabPane>
        <TabPane tabId={3}>{/* Content for Tab 3 */}</TabPane>
      </TabContent>
      <Modal
        onClose={handleClose}
        open={open}
        style={{
          boxShadow: "2px solid black",
          width: "100%",
          top: "15%",
          left: "15%",
        }}
      >
        <div>
          {/* <button
            onClick={handleClose}
            style={{
              position: "fixed",
              top: "112px",
              right: "399px",
              background: "transparent",
              border: "none",
              color: "white",
              fontSize: "40px",
              cursor: "pointer",
              float: "right",
              zIndex: 1,
            }}
          >
            &times; 
          </button> */}
          <PartandCustomerAdd handleClose={handleClose} fetchData={fetchData} />
        </div>
      </Modal>
      <Modal
        onClose={handleClose1}
        open={open1}
        style={{
          boxShadow: "2px solid black",
          width: "100%",
          top: "15%",
          left: "15%",
        }}
      >
        <ConfigImport />
      </Modal>
      <Modal
          onClose={() => setOpenEditModal(false)}
          open={openEditModal}
          style={{
            boxShadow: "2px solid black",
            width: "100%",
            top: "12%",
            left: "15%",
            right: "15%",
            
          }}
        >
            <PartAndCustomerEdit 
            selectedRow={selectedRow} 
            handleCloseEditModal={handleCloseEditModal} 
            fetchData={fetchData}
            />
          
        </Modal>
    </div>
  );
}
export default PartandCustomers;
