import React, { useState, useEffect } from "react";
import { TabContent, TabPane, Card, CardBody, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";

const MachineWiseCycleTime = (props) => {
  const [activeTab, setActiveTab] = useState(1);
  const [employees, setEmployees] = useState([]);
  const [apiData, setApiData] = useState([]);
  useEffect(() => {
    setApiData(props.apiData);
  });

  function actionFormatter(cell, row) {
    return (
      <div>
        <Col
          className="font-icon-list col-xs-6 col-xs-6"
          lg="2"
          md="3"
          sm="4"
          onClick={() => handleEdit(row)}
        ></Col>
        <FontAwesomeIcon
          icon={faPencilAlt}
          className="btn btn-primary tim-icons icon-pencil"
          style={{
            fontSize: "70%",
            background: "none",
            marginRight: "-46px",
            pointerEvents: "none",
          }}
        />
        <Link to={`/admin/org-structure/${row.organization_structure_id}`}>
          <FontAwesomeIcon
            icon={faTrash}
            className="btn btn-primary tim-icons icon-pencil"
            style={{
              fontSize: "70%",
              background: "none",
              pointerEvents: "none",
            }}
          />
        </Link>
      </div>
    );
  }
  function handleEdit(row) {}
  const columns = [
    {
      dataField: "index",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "Date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machineid",
      text: "Machine id",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "actual_cycletime",
      text: "Avg Cycle time",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        const minutes = data / 60000;
        return `${minutes.toFixed(3)} minutes`;//minutes.toFixed(3);
      },
    },
    {
      dataField: "machine_idle_time",
      text: "Ideal Cycle time",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        const minutes = data / 60000;
        return minutes.toFixed(3);
      },
    },
    {
      dataField: "cycletime_deviation",
      text: "Cycle time deviation",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        const minutes = data / 60000;
        return `${minutes.toFixed(3)} minutes`;//minutes.toFixed(3);
      },
    },
    {
      dataField: "time_between_job_parts",
      text: "Time between jobs",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        const minutes = data / 60000;
        return `${minutes.toFixed(3)} minutes`;//minutes.toFixed(3);
      },
    },
    {
      dataField: "machine_performance_percent",
      text: "Performance (Equipment Performance)",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        return `${data.toFixed(3)}%`;
      },
    },
    {
      dataField: "machine_availability_percent",
      text: "Availability (Equipment Availability)",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        return `${data.toFixed(3)}%`;
      },
    },
    {
      dataField: "capacity_utilized_percent",
      text: "Capacity Utilization %",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        return `${data.toFixed(3)}%`;
      },
    },
    {
      dataField: "action",
      text: "Details",
      formatter: actionFormatter,
      headerStyle: { width: "500px" },
    },
  ];

  return (
    <div className="content">
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={employees}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <div>
                        <BootstrapTable
                          {...props.baseProps}
                          keyField="id"
                          data={apiData}
                          columns={columns}
                          filter={filterFactory()}
                          pagination={paginationFactory({
                            bootstrap4: true,
                          })}
                          headerClasses="sorted-asc sorted-desc"
                          wrapperClasses="table-responsive"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default MachineWiseCycleTime;
