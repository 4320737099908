import React, { useState } from "react";
import { AgChartsReact } from "ag-charts-react";

const ComboBarLineChart = (props) => {
  const [options, setOptions] = useState({
    title: { text: props.title, color: '#ffffff' },
    // subtitle: { text: "Data from 2022" },
    data: props.data,
    series: [
      {
        type: "bar",
        xKey: Object.keys(props.data[0])[0],
        yKey: Object.keys(props.data[0])[2],
        yName: props.barChartName,
        color: '#ffffff'
      },
      {
        type: "line",
        xKey: Object.keys(props.data[0])[0],
        yKey: Object.keys(props.data[0])[1],
        yName: props.lineChartName,
        color: '#ffffff'
      },
    ],
    axes: [
      {
        type: "category",
        position: "bottom",
        label: { color: '#ffffff' }
      },
      {
        type: "number",
        position: "left",
        keys: ["barData"],
        label: {
          formatter: (params) => {
            return params.value;
          },
          color: '#ffffff'
        },
      },
      {
        type: "number",
        position: "right",
        keys: ["lineData"],
        label: {
          formatter: (params) => {
            return params.value;
          },
        },
        color: '#ffffff'
      },
    ],
    legend: {
      position: "top",
      title: { color: '#ffffff'}
    },
    background: {
      fill: props.BackgroundColor,
    },
  });
  return <AgChartsReact options={options} />;
};


export default ComboBarLineChart;
