import React, { useState, useEffect } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import redirectToLoginIfTokenMissing from "views/TokenUtility";
import classNames from "classnames";
import LineChart from "assets/charts/LineChart";
import { QueryApi } from "QueryApi";

function PopUpPlantOperatingTime({ closePopup }) {
  const [activeTab, setActiveTab] = useState("1");
  const storedTenantId = sessionStorage.getItem("selectedTenantId");
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const currDay = day < 10 ? `0${day}` : day;
  const currMonth = month < 10 ? `0${month}` : month;
  let currentDate = JSON.stringify(`${year}-${currMonth}-${currDay}`);

  const dateData = new Date();
  dateData.setDate(dateData.getDate() - 7);
  var dayDataValue = dateData.getDate();
  var monthDataValue = dateData.getMonth() + 1;
  var yearDataValue = dateData.getFullYear();
  var formattedDay = dayDataValue < 10 ? `0${dayDataValue}` : dayDataValue;
  var formattedMonth =
    monthDataValue < 10 ? `0${monthDataValue}` : monthDataValue;
  var weekDataDate = JSON.stringify(
    `${yearDataValue}-${formattedMonth}-${formattedDay}`
  );

  const previousDate = new Date();
  previousDate.setDate(1);
  const previousDay = previousDate.getDate();
  const previousMonth = previousDate.getMonth() + 1;
  const previousYear = previousDate.getFullYear();
  const formattedPreviousDay =
    previousDay < 10 ? `0${previousDay}` : previousDay;
  const formattedPreviousMonth =
    previousMonth < 10 ? `0${previousMonth}` : previousMonth;
  const previousMonthDate = JSON.stringify(
    `${previousYear}-${formattedPreviousMonth}-${formattedPreviousDay}`
  );

  const yearCurrentDate = new Date();
  yearCurrentDate.setDate(1);
  yearCurrentDate.getMonth(1);
  const yearDay = yearCurrentDate.getDate();
  const yearMonth = yearCurrentDate.getMonth();
  const yearYear = yearCurrentDate.getFullYear();
  const formattedYearDay = yearDay < 10 ? `0${yearDay}` : yearDay;
  const formattedYearMonth = yearMonth < 10 ? `0${yearMonth}` : yearMonth;
  const yearPreviousMonthDate = JSON.stringify(
    `${yearYear}-${formattedYearMonth}-${formattedYearDay}`
  );

  useEffect(() => {
    redirectToLoginIfTokenMissing();
  }, []);

  const [plantOperatingTimeData, setPlantOperatingTimeData] = useState(0);
  useEffect(() => {
    plantOperatingTime();
  }, []);
  const plantOperatingTime = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
              dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                  avg {
                    plant_operating_time_percent
                  }
                }
              }
            }
      `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent ===
          null
      ) {
        setPlantOperatingTimeData("No Data");
      } else {
        setPlantOperatingTimeData(
          data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent.toFixed(
            1
          )
        );
      }
    } catch (error) {
      setPlantOperatingTimeData("No Data");
    }
  };

  const [bigChartData, setBgChartData] = React.useState("data1");
  const [xAxisDataValue, setXAxisDataValue] = useState([]);
  const [plotPoint, setPlotPoint] = useState([]);
  const dataset = [
    {
      label: "Plant Operating Time",
      data: plotPoint,
      borderColor: "#1F8EF1",
    },
  ];
  useEffect(() => {
    GraphDataList();
  }, [bigChartData]);
  const pointDate = new Date();
  const resPointDate = pointDate.getHours();
  const GraphDataList = async () => {
    if (bigChartData === "data1") {
      try {
        const data = await QueryApi(`
                query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
                    dm_org_tenant_hourly(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                        plant_operating_time_percent
                        timestamp
                    }
                }
            `);
        setXAxisDataValue([
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
          20, 21, 22, 23, 24,
        ]);
        const plotAllDataProductionVolume = data.dm_org_tenant_hourly.map(
          (data) => {
            const objData = {
              // point: (data.plant_operating_time_percent / (1000 * 60 * 60)).toFixed(2),
              point: data.plant_operating_time_percent,
              Xaxis: data.timestamp,
            };
            return objData;
          }
        );
        let plotValue = [];
        for (var i = 0; i <= resPointDate; i++) {
          plotValue[i] = 0;
        }
        plotAllDataProductionVolume.forEach((data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
          if (h >= 0 && h <= 1) {
            plotValue[0] = data.point;
          } else if (h >= 1 && h <= 2) {
            plotValue[1] = data.point;
          } else if (h >= 2 && h <= 3) {
            plotValue[2] = data.point;
          } else if (h >= 3 && h <= 4) {
            plotValue[3] = data.point;
          } else if (h >= 4 && h <= 5) {
            plotValue[4] = data.point;
          } else if (h >= 5 && h <= 6) {
            plotValue[5] = data.point;
          } else if (h >= 6 && h <= 7) {
            plotValue[6] = data.point;
          } else if (h >= 7 && h <= 8) {
            plotValue[7] = data.point;
          } else if (h >= 8 && h <= 9) {
            plotValue[8] = data.point;
          } else if (h >= 9 && h <= 10) {
            plotValue[9] = data.point;
          } else if (h >= 10 && h <= 11) {
            plotValue[10] = data.point;
          } else if (h >= 11 && h <= 12) {
            plotValue[11] = data.point;
          } else if (h >= 12 && h <= 13) {
            plotValue[12] = data.point;
          } else if (h >= 13 && h <= 14) {
            plotValue[13] = data.point;
          } else if (h >= 14 && h <= 15) {
            plotValue[14] = data.point;
          } else if (h >= 15 && h <= 16) {
            plotValue[15] = data.point;
          } else if (h >= 16 && h <= 17) {
            plotValue[16] = data.point;
          } else if (h >= 17 && h <= 18) {
            plotValue[17] = data.point;
          } else if (h >= 18 && h <= 19) {
            plotValue[18] = data.point;
          } else if (h >= 19 && h <= 20) {
            plotValue[19] = data.point;
          } else if (h >= 20 && h <= 21) {
            plotValue[20] = data.point;
          } else if (h >= 21 && h <= 22) {
            plotValue[21] = data.point;
          } else if (h >= 22 && h <= 23) {
            plotValue[22] = data.point;
          } else if (h >= 23 && h <= 24) {
            plotValue[23] = data.point;
          } else {
            return null;
          }
        });
        setPlotPoint(plotValue);
      } catch (error) {
        setXAxisDataValue([]);
        setPlotPoint([]);
      }
    } else if (bigChartData === "data2") {
      try {
        const data = await QueryApi(`
                query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                    dm_org_tenant_daily(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                        plant_operating_time_percent
                        timestamp
                    }
                }
            `);
        const plotAllDataProductionVolume = data.dm_org_tenant_daily.map(
          (data) => {
            const objData = {
              point: data.plant_operating_time_percent,
              Xaxis: data.timestamp,
            };
            return objData;
          }
        );

        // const plotPointDataProductionVolume = plotAllDataProductionVolume.map(
        //     (data) => data.point
        // );
        // setPlotPoint(plotPointDataProductionVolume);

        function getPlotAllDaysBetween(startDate, endDate) {
          var newDate = new Date(startDate);
          var weekDATE = new Date(endDate);
          var days = [];
          while (newDate <= weekDATE) {
            const dd = newDate.getDate();
            const mm = newDate.getMonth() + 1;
            const yy = newDate.getFullYear();
            const formattedd = dd < 10 ? `0${dd}` : dd;
            const formattedmm = mm < 10 ? `0${mm}` : mm;
            const fullDATE = `${yearYear}-${formattedmm}-${formattedd}`;
            days.push(fullDATE);
            newDate.setDate(newDate.getDate() + 1);
          }
          return days;
        }
        var daysBetween = getPlotAllDaysBetween(weekDataDate, currentDate);
        var pointArr = [];
        daysBetween.map((data) => {
          var arrlet = plotAllDataProductionVolume.map((data) => {
            var arrDate = new Date(data.Xaxis);
            let nday = arrDate.getUTCDate();
            let nmonth = arrDate.getMonth() + 1;
            let nyear = arrDate.getFullYear();
            const ncurrDay = nday < 10 ? `0${nday}` : nday;
            const ncurrMonth = nmonth < 10 ? `0${nmonth}` : nmonth;
            let nDateList = `${nyear}-${ncurrMonth}-${ncurrDay}`;
            return nDateList;
          });
          const res = arrlet.includes(data);
          const resindex = arrlet.indexOf(data);
          if (res === true) {
            pointArr.push(plotAllDataProductionVolume[resindex].point);
          } else {
            pointArr.push(0);
          }
        });
        setPlotPoint(pointArr);

        function getAllDaysBetween(startDate, endDate) {
          var currentDate = new Date(startDate);
          var days = [];
          while (currentDate <= endDate) {
            days.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
          }
          return days;
        }
        function getDayOfWeek(date) {
          var days = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];
          return days[date.getDay()];
        }
        function getDaysOfWeekBetween(startDate, endDate) {
          var daysBetween = getAllDaysBetween(startDate, endDate);
          var daysOfWeek = [];
          for (var i = 0; i < daysBetween.length; i++) {
            var dayOfWeek = getDayOfWeek(daysBetween[i]);
            daysOfWeek.push(dayOfWeek);
          }
          return daysOfWeek;
        }
        var daysOfWeekBetween = getDaysOfWeekBetween(
          new Date(weekDataDate),
          new Date(currentDate)
        );
        setXAxisDataValue(daysOfWeekBetween);
      } catch (error) {
        setXAxisDataValue([]);
        setPlotPoint([]);
      }
    } else if (bigChartData === "data3") {
      try {
        const data = await QueryApi(`
                query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                    dm_org_tenant_daily(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                        plant_operating_time_percent
                        date
                    }
                }
            `);
        const plotAllDataProductionVolume = data.dm_org_tenant_daily.map(
          (data) => {
            const objData = {
              point: data.plant_operating_time_percent,
              Xaxis: data.date,
            };
            return objData;
          }
        );

        const bothAxisPoint = [];
        for (var i = 1; i <= 31; i++) {
          bothAxisPoint[i] = { pointValue: 0, XaxisValue: i };
        }
        plotAllDataProductionVolume.forEach((data) => {
          const allDate = new Date(data.Xaxis);
          const dayss = allDate.getDate();
          bothAxisPoint[dayss] = { pointValue: data.point, XaxisValue: dayss };
        });
        const NewPointData = bothAxisPoint.map((data) => data.pointValue);
        const dd = new Date();
        NewPointData.splice(dd.getDate() + 1, NewPointData.length);
        const NewXaxisData = bothAxisPoint.map((data) => data.XaxisValue);
        setPlotPoint(NewPointData);
        setXAxisDataValue(NewXaxisData);
      } catch (error) {
        setXAxisDataValue([]);
        setPlotPoint([]);
      }
    } else if (bigChartData === "data4") {
      try {
        const data = await QueryApi(`
                query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                    dm_org_tenant_monthly(where: {start_date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                       plant_operating_time_percent
                       start_date
                  }
                }
            `);
        const plotAllDataProductionVolume = data.dm_org_tenant_monthly.map(
          (data) => {
            const objData = {
              point: data.plant_operating_time_percent,
              Xaxis: data.timestamp,
            };
            return objData;
          }
        );

        const plotPointDataProductionVolume = plotAllDataProductionVolume.map(
          (data) => data.point
        );
        setPlotPoint(plotPointDataProductionVolume);
        setXAxisDataValue([
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ]);
      } catch (error) {
        setXAxisDataValue([]);
        setPlotPoint([]);
      }
    } else {
      setBgChartData("data1");
    }
  };

  const [AvgData, setAvgData] = useState("No data");
  useEffect(() => {
    Avg();
  }, []);
  const Avg = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                avg {
                    plant_operating_time_percent
                }
              }
            }
          }
    `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent ===
          null
      ) {
        setAvgData("No Data");
      } else {
        setAvgData(
          data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent.toFixed(
            1
          )
        );
      }
    } catch (error) {
      setAvgData("No Data");
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="8">
            <Card
              className="card-chart"
              style={{ height: "25rem", width: "35rem" }}
            >
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5 className="card-category">Plant Operating Time</h5>
                  </Col>
                  <Col sm="4">
                    <ButtonGroup
                      style={{ marginTop: "1rem" }}
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <Button
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data1",
                        })}
                        color="info"
                        id="0"
                        size="sm"
                        onClick={() => setBgChartData("data1")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Day
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-single-02" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data2",
                        })}
                        onClick={() => setBgChartData("data2")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Week
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-gift-2" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data3",
                        })}
                        onClick={() => setBgChartData("data3")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Month
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-tap-02" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data4",
                        })}
                        onClick={() => setBgChartData("data4")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Year
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-tap-02" />
                        </span>
                      </Button>
                    </ButtonGroup>
                  </Col>
                  <Col sm="2">
                    <button
                      style={{
                        border: "none",
                        zIndex: 1,
                        marginLeft: "7rem",
                        backgroundColor: "transparent",
                        color: "white",
                        fontSize: "40px",
                      }}
                      onClick={() => closePopup(false)}
                    >
                      &times;
                    </button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <LineChart
                  xAxisData={xAxisDataValue}
                  dataset={dataset}
                  height="100%"
                  width="100%"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default PopUpPlantOperatingTime;
