import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';

const ColorLineChart = (props) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const myChart = echarts.init(chartRef.current);
    const nameData = props.YaxisData.map((data) => data.name);
    const seriesData = props.YaxisData.map((data) => {
      return {
        name: data.name,
        type: 'line',
        stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: data.color
            }
          ])
        },
        emphasis: {
          focus: 'series'
        },
        data: data.data
      }
    }
    )
    const option = {
      // title: {
      //   text: 'Gradient Stacked Area Chart'
      // },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      legend: {
        data: nameData,
        textStyle: {
          color: '#4E505F',
        },
      },
      toolbox: {
        feature: {
          saveAsImage: {}
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          data: props.xAxisData
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: seriesData
    };

    option && myChart.setOption(option);

    return () => {
      myChart.dispose();
    };
  }, []);

  return <div ref={chartRef} style={{ width: props.width, height: props.height }} />;
};

export default ColorLineChart;
