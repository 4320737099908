// TokenUtility.js
import jwtDecode from 'jwt-decode';

const redirectToLoginIfTokenMissing = () => {
  const token = sessionStorage.getItem('token');
  try {
    // Decode the token (no verification)
    const decodedToken = jwtDecode(token);

    if (decodedToken.exp) {
      // Get the current timestamp (in seconds since epoch)
      const currentTimestamp = Math.floor(Date.now() / 1000);

      // Check if the token has expired
      if (decodedToken.exp < currentTimestamp) {
        console.log('Token has expired');
        window.location.href = '/login';
      } else {
        console.log('Token is still valid');
        console.log(decodedToken.exp);
        console.log(Math.floor(Date.now() / 1000));
      }
    } else {
      console.log('Invalid token format');
    }
  } catch (error) {
    console.error('Error decoding token:', error);
  }
};

export default redirectToLoginIfTokenMissing;
