import React, { useState, useEffect } from "react";
import "./style.css";
import {
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
} from "@fortawesome/free-solid-svg-icons";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import OrganizationTree from "./OrganizationTree";
import AllOrganization from "./AllOrganization";
import WorkscheduleAll from "./WorkScheduleAll";
import OrganizationHolidays from "./ManageOrganization/Holidays";
import OrganizationWeeklyOffs from "./ManageOrganization/Weeklyoffs";

function ManageOrg(props) {
  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      sessionStorage.setItem("activeTab", tab);
    }
  };
  useEffect(() => {
    // Retrieve the active tab from sessionStorage
    const storedActiveTab = sessionStorage.getItem("activeTab");
    if (storedActiveTab) {
      setActiveTab(storedActiveTab);
    }
  }, []);
  const getBreadcrumbItemText = (tabId) => {
    switch (tabId) {
      case "1":
        return "Organization Tree";
      case "2":
        return "All Organization";
      case "3":
        return "Create Organization";
      case "4":
        return "Holidays and Weekly Offs";
      case "5":
        return "Organization Machine Parameters";
      default:
        return "Manage Organization";
    }
  };

  return (
    <>
      <div className="content">
        <div class="container1">
        <h1 className="main-heading">Manage Organization</h1>
        <div class="breadcrumb-container">
          <Breadcrumb>
            <BreadcrumbItem><FontAwesomeIcon icon={faHouse} /></BreadcrumbItem>
            <BreadcrumbItem>Configuration Manager</BreadcrumbItem>
            <BreadcrumbItem active>{getBreadcrumbItemText(activeTab)}</BreadcrumbItem>
          </Breadcrumb>
        </div>
        </div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => toggleTab("1")}
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor:
                  activeTab === "1" ? "#5555e7" : "transparent",
                color: activeTab === "1" ? "white" : "white",
              }}
            >
              Organization Tree
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => toggleTab("2")}
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor:
                  activeTab === "2" ? "#5555e7" : "transparent",
                color: activeTab === "2" ? "white" : "white",
              }}
            >
              All Organization
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => toggleTab("3")}
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor:
                  activeTab === "3" ? "#5555e7" : "transparent",
                color: activeTab === "3" ? "white" : "white",
              }}
            >
              Work Schedule
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "4" })}
              onClick={() => toggleTab("4")}
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor:
                  activeTab === "4" ? "#5555e7" : "transparent",
                color: activeTab === "4" ? "white" : "white",
              }}
            >
              Holidays and Weekly Offs
            </NavLink>
          </NavItem>
         
        </Nav>

        <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col>
              <OrganizationTree />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col>
              <AllOrganization />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row>
            <Col>
              <WorkscheduleAll />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="4">
          <Row>
            <Col>
            <OrganizationWeeklyOffs />
            </Col>
            <Col>
              <OrganizationHolidays />
            </Col>
          </Row>
        </TabPane>
        
      </TabContent>
      </div>
    </>
  );
}

export default ManageOrg;
