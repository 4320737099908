import React, { useRef, useState, useEffect } from "react";
import "./style.css";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong} from "@fortawesome/free-solid-svg-icons";
import redirectToLoginIfTokenMissing from './TokenUtility'; 
import { Bar } from "react-chartjs-2";
import ReactSpeedometer from 'react-d3-speedometer';
import PercentageCircle from './PercentageCircle';
import { ProgressBar } from "react-bootstrap"; 
import { QueryApi } from "QueryApi";

function ManagePeople(props) {
  const [activeTab, setActiveTab] = useState("1");
  const storedTenantId = sessionStorage.getItem('selectedTenantId');
  console.log("selected Tenant ID:", storedTenantId);
useEffect(() => {
  redirectToLoginIfTokenMissing(); // Call the utility function to validate the token
  // Your component's logic...
}, []);
const [org, setorg] = useState("");
const [orgnames, setOrgnames] = useState([]);

const [unit, setunit] = useState("");

const [dept, setdept] = useState("");
const [depts, setdepts] = useState([]);

const [plants, setplants] = useState([]);
const [orgs, setorgs] = useState([]);
const [otherData, setOtherData] = useState([]);
const [selectedOrgData, setSelectedOrgData] = useState(null);
const [containerWidth, setContainerWidth] = useState(0);
const gaugeContainerRef = useRef(null);
const token = sessionStorage.getItem('token');
const decodedToken = JSON.parse(atob(token.split('.')[1]));
const tenantId = decodedToken['https://hasura.io/jwt/claims']['x-hasura-org-id'];
  

const date = new Date();
let day = date.getDate();
let month = date.getMonth() + 1;
let year = date.getFullYear();
const currDay = (day < 10) ? `0${day}` : day;
const currMonth = (month < 10) ? `0${month}` : month;
let currentDate = JSON.stringify(`${year}-${currMonth}-${currDay}`);


const [bigChartData, setbigChartData] = React.useState("data1");
const setBgChartData = (name) => {
setbigChartData(name);
};
const [bigChartData1, setbigChartData1] = React.useState("data1");
  const setBgChartData1 = (name) => {
    setbigChartData1(name);
  };
const [bigChartData2, setbigChartData2] = React.useState("data1");
  const setBgChartData2 = (name) => {
    setbigChartData2(name);
  };

useEffect(() => {
const updateContainerWidth = () => {
    if (gaugeContainerRef.current) {
    setContainerWidth(gaugeContainerRef.current.clientWidth);
    }
    console.log(gaugeContainerRef.current.clientWidth);
};

// Update the container width when the window is resized
window.addEventListener('resize', updateContainerWidth);

// Initial width calculation
updateContainerWidth();

// Clean up the event listener when the component unmounts
return () => {
    window.removeEventListener('resize', updateContainerWidth);
};
}, []);
const mixedChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };
  const mixedChartData = {
    labels: ['January', 'February', 'March', 'April', 'May'],
    datasets: [
      {
        label: 'Line Data 1',
        type: 'line', // Specify line type
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 2,
        tension: 0.5, 
        data: [7, 16, 25, 35, 54],
      },
      {
        label: 'Line Data 2',
        type: 'line', // Specify line type
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 2,
        tension: 0.5, 
        data: [9, 23, 29, 32, 34],
      },
    ],
  };

  
  //// Availability ////////////////////////////////////////////////////////////////////////

  const [AvailabilityDataList, setAvailabilityDataList] = useState({
    machine_availability_percent: "No Data"
  })
  useEffect(() => {
    Availability();
  }, [])
  const Availability = async () => {
    try {
      const data = await QueryApi(`
        query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem('tenantid')}) {
          dm_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
            aggregate {
              avg {
                machine_availability_percent
              }
            }
          }
        } `);
      setAvailabilityDataList({
        machine_availability_percent: data.dm_daily_aggregate.aggregate.avg.machine_availability_percent
      })
    } catch (error) {
      setAvailabilityDataList({
        machine_availability_percent: "No Data"
      })
    }
  };

  ///// Average Downtime ////////////////////////////////////////////

  const [AverageDowntimeDataList, setAverageDowntimeDataList] = useState({
    total_machine_downtime: "No Data"
  })
  useEffect(() => {
    AverageDowntime();
  }, [])
  const AverageDowntime = async () => {
    try {
      const data = await QueryApi(`
        query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem('tenantid')}) {
          dm_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
            aggregate {
              sum {
                total_machine_downtime
              }
            }
          }
        } `);
        const hoursDowntime = data.dm_daily_aggregate.aggregate.sum.total_machine_downtime / (1000 * 60 * 60);
      setAverageDowntimeDataList({
        total_machine_downtime: hoursDowntime.toFixed(0)
      })
    } catch (error) {
      setAverageDowntimeDataList({
        total_machine_downtime: "No Data"
      })
    }
  };

  //// Performance Availability Quality ///////////////////////////////////////////////////////////////
 
  const [OeeDataList, setOeeDataList] = useState({
    machine_availability_percent: "No Data",
    machine_performance_percent: "No Data",
    quality_percent: "No Data"
  })
  useEffect(() => {
    OeeFun();
  }, [])
  const OeeFun = async () => {
    try {
      const data = await QueryApi(`
      query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem('tenantid')}) {
        dm_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
          aggregate {
            avg {
              machine_availability_percent
              machine_performance_percent
              quality_percent
            }
          }
        }
      } `);
      setOeeDataList(data.dm_daily_aggregate.aggregate.avg)
    } catch (error) {
      setOeeDataList({
        machine_availability_percent: "No Data",
        machine_performance_percent: "No Data",
        quality_percent: "No Data"
      })
    }
  };

  //// Avg Productivity // Target Adherence ////////////////////////////////////////////////////////////////////

  const [targetAdherenceDataList, setTargetAdherenceDataList] = useState({
    parts_per_minute: 0,
    target_adherence: 0
  })
  useEffect(() => {
    targetAdherence();
  }, [])
  const targetAdherence = async () => {
    try {
      const data = await QueryApi(`
      query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem('tenantid')}) {
        dm_daily(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
              parts_per_minute
              target_adherence
            }
      }`);
      const partsAvg = data.dm_daily.reduce((acc, curr) => acc + curr.parts_per_minute, 0);
      const partAvgData = parseInt(partsAvg / data.dm_daily.length)
      const targetAvg = data.dm_daily.reduce((acc, curr) => acc + curr.target_adherence, 0);
      const targetAvgData = parseInt(targetAvg / data.dm_daily.length)
      setTargetAdherenceDataList({
        parts_per_minute: partAvgData,
        target_adherence: targetAvgData,
      })
    } catch (error) {
      setTargetAdherenceDataList({
        parts_per_minute: 0,
        target_adherence: 0
      })
    }
  }
  
  return (
    <>
      <div className="content">
        <Row>
            <Col lg="3" md="6" sm="12">
                <Card className="w-100 h-100">
                    <CardHeader>
                        <h5 className="card-category">Operational Efficiency</h5>
                    </CardHeader>
                    <CardBody className="d-flex justify-content-center align-items-center">
                        <div className="gauge-container" ref={gaugeContainerRef}>
                            <ReactSpeedometer
                                width={230} // Set the width based on the container's width
                                value={45} // Change this to your desired value
                                minValue={0}
                                maxValue={100}
                                height={140}
                                needleColor="wheat" // Customize the needle color
                                startColor="green" // Customize the start color of the gauge
                                segments={5} // Number of segments on the gauge
                                endColor="blue" // Customize the end color of the gauge
                                textColor="white" // Text color
                                ringWidth={20}
                                currentValueText="${value} %" // Text shown in the center
                            />
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col lg="3" md="6" sm="12">
                <Card className="w-100 h-100">
                    <CardBody className="d-flex justify-content-center align-items-center">
                        <div className="percentage-circles-container d-flex justify-content-center flex-wrap" style={{ gap: '20px' }}>
                            <div className="percentage-circle-wrapper">
                                <div className="percentage-circle-line"></div>
                                <PercentageCircle percentage={OeeDataList.machine_performance_percent} color="green" size="30px" />
                                <div className="percentage-circle-label">Performance</div>
                            </div>
                            <div className="percentage-circle-wrapper">
                                <div className="percentage-circle-line"></div>
                                <PercentageCircle percentage={OeeDataList.machine_availability_percent} color="blue" size="30px" />
                                <div className="percentage-circle-label">Availability</div>
                            </div>
                            <div className="percentage-circle-wrapper">
                                <div className="percentage-circle-line"></div>
                                <PercentageCircle percentage={OeeDataList.quality_percent} color="red" size="30px" />
                                <div className="percentage-circle-label">Quality</div>
                            </div>
                        </div>
                    </CardBody>
                   
                </Card>
            </Col>
            <Col lg="4" md="12" sm="12">
                <div className="d-flex flex-column h-100">
                    <Row className="circle-row">
                        <Col lg="3" md="6" sm="6">
                            <Card className="h-100">
                                <CardHeader>
                                    <div className="text-center">Avg Productivity</div>
                                </CardHeader>
                                <CardBody>
                                    <div className="percentage-circle-wrapper">
                                        <div className="percentage-circle">
                                            <PercentageCircle percentage={targetAdherenceDataList.parts_per_minute ? targetAdherenceDataList.parts_per_minute : "No Data"} color="blue" size="60px" />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="3" md="6" sm="6">
                            <Card className="h-100">
                                <CardHeader>
                                    <div className="text-center">Target Adherence</div>
                                </CardHeader>
                                <CardBody>
                                    <div className="percentage-circle-wrapper">
                                        <div className="percentage-circle-line"></div>
                                        <PercentageCircle percentage={targetAdherenceDataList.target_adherence ? targetAdherenceDataList.target_adherence : "No Data"} color="blue" size="60px" />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="3" md="6" sm="6">
                            <Card className="h-100">
                                <CardHeader>
                                    <div className="icon-plus"> 
                                        <a className="anchor-bg" href={`/admin/dashboard/cycle-time-analysis`}>
                                            <FontAwesomeIcon icon={faArrowRightLong} />
                                        </a>
                                    </div>
                                    <div className="text-center">Cycle Time</div>
                                </CardHeader>
                                <CardBody>
                                    <div className="percentage-circle-wrapper">
                                        <div className="percentage-circle-line"></div>
                                        <PercentageCircle percentage={68} color="red" size="60px" />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="3" md="6" sm="6">
                            <Card className="h-100">
                                <CardHeader>
                                    <div className="text-center">Avg Plan Adherence</div>
                                </CardHeader>
                                <CardBody>
                                    <div className="percentage-circle-wrapper">
                                        <div className="percentage-circle-line"></div>
                                        <PercentageCircle percentage={90} color="Yellow" size="60px" />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
        <Row className="mt-2">
            <Col lg="4">
                <Card className="card-chart">
                    <CardHeader>
                        <h5 className="card-category">Cycle Time Performance</h5>
                    </CardHeader>
                    <CardBody>
                        <div className="chart-area">
                            <Bar
                                data={mixedChartData} // Use mixedChartData here
                                options={mixedChartOptions}
                            />
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col lg="2" md="6" sm="6" className="d-flex">
                  <Card className="w-100">
                    <CardHeader>
                      <h5 className="card-category">Availability</h5>
                    </CardHeader>
                    <CardBody className="d-flex justify-content-center align-items-center">
                    <div className="gauge-container" ref={gaugeContainerRef}>
                      <ReactSpeedometer
                        width={200} // Set the width based on the container's width
                        value={AvailabilityDataList.machine_availability_percent} // Change this to your desired value
                        minValue={0}
                        maxValue={100}
                        height={140}
                        needleColor="wheat" // Customize the needle color
                        startColor="green" // Customize the start color of the gauge
                        segments={5} // Number of segments on the gauge
                        endColor="blue" // Customize the end color of the gauge
                        textColor="white" // Text color
                        ringWidth={20}
                        currentValueText="${value} %" // Text shown in the center
                      />
                    </div>

                    </CardBody>
                  </Card>
            </Col>
            <Col lg="2" md="6" sm="6" className="d-flex">
            <Card className="w-100">
                <CardHeader>
                <h5 className="card-category">Average Downtime</h5>
                </CardHeader>
                <CardBody className="d-flex justify-content-center align-items-center">
                <div className="gauge-container" ref={gaugeContainerRef}>
                <ReactSpeedometer
                    width={200} // Set the width based on the container's width
                    value={AverageDowntimeDataList.total_machine_downtime} // Change this to your desired value
                    minValue={0}
                    maxValue={100}
                    height={140}
                    needleColor="wheat" // Customize the needle color
                    startColor="green" // Customize the start color of the gauge
                    segments={5} // Number of segments on the gauge
                    endColor="blue" // Customize the end color of the gauge
                    textColor="white" // Text color
                    ringWidth={20}
                    currentValueText="${value} %" // Text shown in the center
                />
                </div>
                </CardBody>
            </Card>
            </Col>
            <Col lg="3" md="8" sm="8" className="d-flex">
                <Card className="card-chart" style={{paddingLeft: '35px', paddingRight: '35px'}}>
              
                <CardBody className="dashboard-page">
                    <p className="p-font">Shift 1</p>
                
                <ProgressBar now = {35} label = {`35% completed`} animated />
                <br></br>
                <p className="p-font">Shift 2</p>
                <ProgressBar now = {50} label = {`50% completed`} animated />
                <br></br>
                <p className="p-font">Shift 3</p>
                <ProgressBar now = {70} label = {`70% completed`} animated />
               <br></br>
                </CardBody>
                </Card>
            </Col>
        </Row>
      </div>
    </>
  );
}

export default ManagePeople;

