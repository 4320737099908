import React, { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import "./style.css";
import "./responsive.css";
import "./progress.css";
import "./gauge.css";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import m8Image from "../assets/img/m81.png";
import PercentageCircle from "./PercentageCircle";
import "react-circular-progressbar/dist/styles.css";
import { ProgressBar } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDashboard,
  faCheckCircle,
  faChartArea,
  faBan,
  faArrowRightLong,
  faChartBar,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import redirectToLoginIfTokenMissing from "./TokenUtility";
import axios from "axios";
import { APIURL } from "./Apiurl.js";
import SpeedGauge from "../assets/charts/SpeedGauge";
import LineChart from "assets/charts/LineChart";
import { QueryApi } from "QueryApi";
import targetImg from "../assets/img/targetImg.svg";
import cycleTime from "../assets/img/cycleTime.svg";
import todayProductionImg from "../assets/img/todayProductionsImg.svg";
import workforceAvailability from "../assets/img/workforceAvailiblity.svg";
import runTime from "../assets/img/runTime.svg";
import timeLoss from "../assets/img/timeLoss.svg";
import { useNavigate } from "react-router-dom";

function NewDashboard(props) {
  const navigate = useNavigate();
  const [OEEListData, setOEEListData] = useState(0);
  const storedTenantId = sessionStorage.getItem("selectedTenantId");
  const [dashboardData, setDashboardData] = useState([]);
  const [orgstruct, setorgstruct] = useState([]);
  const [dailyUpdatesDataList, setDailyUpdatesDataList] = useState("No Data");
  const [capacityUtilizationDataList, setCapacityUtilizationDataList] =
    useState({
      availability_loss_percent: 0,
      capacity_utilized_percent: 0,
    });
  const [workforceEfficienciesDataList, setWorkforceEfficienciesDataList] =
    useState({
      overall_labor_effectiveness: 0,
    });
  const [containerWidth, setContainerWidth] = useState(0);

  const [bigChartData, setBgChartData] = React.useState("data1");
  const [DowntimebigChartData, setDowntimebigChartData] =
    React.useState("data1");
  const [CyclebigChartData, setCyclebigChartData] = React.useState("data1");

  const gaugeContainerRef = useRef(null);
  const chartRef = useRef(null);
  SpeedGauge({
    refData: chartRef,
    colorData: "#1976D2",
    dataValue: OEEListData,
    circleWidth: 10,
    mainText: 28,
    circleNumber: 18,
  });
  const CapacityUtilizationRef = useRef(null);
  SpeedGauge({
    refData: CapacityUtilizationRef,
    colorData: "#1976D2",
    dataValue: capacityUtilizationDataList.capacity_utilized_percent,
    circleWidth: 7,
    mainText: 20,
    circleNumber: 13,
  });
  const WorkforceEfficienciesRef = useRef(null);
  SpeedGauge({
    refData: WorkforceEfficienciesRef,
    colorData: "#1976D2",
    dataValue: workforceEfficienciesDataList.overall_labor_effectiveness,
    circleWidth: 7,
    mainText: 20,
    circleNumber: 13,
  });

  const pointDate = new Date();
  const resPointDate = pointDate.getHours();

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const currDay = day < 10 ? `0${day}` : day;
  const currMonth = month < 10 ? `0${month}` : month;
  let currentDate = JSON.stringify(`${year}-${currMonth}-${currDay}`);

  const dateData = new Date();
  dateData.setDate(dateData.getDate() - 6);
  var dayDataValue = dateData.getDate();
  var monthDataValue = dateData.getMonth() + 1;
  var yearDataValue = dateData.getFullYear();
  var formattedDay = dayDataValue < 10 ? `0${dayDataValue}` : dayDataValue;
  var formattedMonth =
    monthDataValue < 10 ? `0${monthDataValue}` : monthDataValue;
  var weekDataDate = JSON.stringify(
    `${yearDataValue}-${formattedMonth}-${formattedDay}`
  );

  const previousDate = new Date();
  previousDate.setDate(1);
  const previousDay = previousDate.getDate();
  const previousMonth = previousDate.getMonth() + 1;
  const previousYear = previousDate.getFullYear();
  const formattedPreviousDay =
    previousDay < 10 ? `0${previousDay}` : previousDay;
  const formattedPreviousMonth =
    previousMonth < 10 ? `0${previousMonth}` : previousMonth;
  const previousMonthDate = JSON.stringify(
    `${previousYear}-${formattedPreviousMonth}-${formattedPreviousDay}`
  );

  const yearCurrentDate = new Date();
  yearCurrentDate.setDate(1);
  yearCurrentDate.setMonth(1);
  const yearDay = yearCurrentDate.getDate();
  const yearMonth = yearCurrentDate.getMonth();
  const yearYear = yearCurrentDate.getFullYear();
  const formattedYearDay = yearDay < 10 ? `0${yearDay}` : yearDay;
  const formattedYearMonth = yearMonth < 10 ? `0${yearMonth}` : yearMonth;
  const yearPreviousMonthDate = JSON.stringify(
    `${yearYear}-${formattedYearMonth}-${formattedYearDay}`
  );

  useEffect(() => {
    const updateContainerWidth = () => {
      if (gaugeContainerRef.current) {
        setContainerWidth(gaugeContainerRef.current.clientWidth);
      }
    };
    window.addEventListener("resize", updateContainerWidth);
    updateContainerWidth();
    return () => {
      window.removeEventListener("resize", updateContainerWidth);
    };
  }, []);
  console.log("selected Tenant ID:", storedTenantId);
  useEffect(() => {
    redirectToLoginIfTokenMissing(); // Call the utility function to validate the token
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const roleId = decodedToken["sub"];
      console.log("roleId-->", roleId);
      const logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
      console.log("logintenantId---->", logintenantId);
      const storedTenantId = sessionStorage.getItem("selectedTenantId");
      console.log("storedTenantId-->", storedTenantId);
      let query = ""; // Define the query variable outside the if-else block
      let variables = {}; // Define the variables object

      if (roleId === 1) {
        if (storedTenantId !== null) {
          query = `
      query MyQuery($TenantId: Int!) {
        dm_daily(where: {tenantid: {_eq: $TenantId}, date: {_eq: "2024-01-01"}}) {
          machine_availability_percent
          quality_percent
          machine_performance_percent
        }
      }
      
  `;
          variables = {
            TenantId: storedTenantId,
          };
        } else {
          query = `
      query MyQuery {
        dm_daily(where: {date: {_eq: "2024-01-01"}}) {
          machine_availability_percent
          machine_performance_percent
          quality_percent
        }
      }
      
      
  `;
        }
      } else {
        query = `
    query MyQuery {
      dm_daily(where: {date: {_eq: "2024-01-01"}}) {
        machine_availability_percent
        machine_performance_percent
        quality_percent
      }
    }
    
    
  `;
      }
      const response = await axios.post(
        APIURL,
        {
          query: query,
          variables: variables,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const dm_daily = response.data.data.dm_daily.map((structure) => ({
        ...structure,
        status: "On",
      }));
      setorgstruct(dm_daily);
    } catch (error) {
      console.error(error);
    }
  };

  const token = sessionStorage.getItem("token");

  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const roleId = decodedToken["sub"];
  console.log(roleId);
  let logintenantId = null;
  if (roleId === 1) {
    logintenantId = sessionStorage.getItem("selectedTenantId");
  } else {
    logintenantId =
      decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem("token");
        const response = await axios.post(
          APIURL,
          {
            query: `
              query tenantdashboardmaster($logintenantId: Int!) {
                tenant_dashboard_master(where: { is_default: { _eq: 1 }, tenantid: { _eq: $logintenantId } }) {
                  tenant_dashboard_master_id
                  is_default
                  dashboard_name
                }
              }
            `,
            variables: {
              logintenantId: logintenantId,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json", // Add your secret key here
            },
          }
        );

        setDashboardData(response.data.data.tenant_dashboard_master);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const needleShadow = "0 0 10px rgba(0, 0, 0, 0.3)";
  const percentage = 65; // Change this value to set the gauge level (between 0 and 100)
  const darkColor = "rgba(0, 0, 0, 0.8)";
  const lightColor = "rgba(0, 0, 0, 0.1)";

  const data = [
    { name: "Score", value: 80, fill: "#0088FE" },
    { name: "Remaining", value: 20, fill: "#E6E6E6" },
  ];
  const highlights = [
    { from: 0, to: 20, color: "#e63946" }, // Red
    { from: 20, to: 40, color: "#f1a9a0" }, // Orange
    { from: 40, to: 60, color: "#feca57" }, // Yellow
    { from: 60, to: 80, color: "#00b4d8" }, // Blue
    { from: 80, to: 100, color: "#90e0ef" }, // Light Blue
  ];
  const gradientSteps = 10; // Adjust the number of steps for the shading effect
  const colorStops = [];
  for (let i = 0; i <= gradientSteps; i++) {
    const intensity = i / gradientSteps;
    const r = Math.round(
      intensity * parseInt(lightColor.substring(4, 7)) +
        (1 - intensity) * parseInt(darkColor.substring(4, 7))
    );
    const g = Math.round(
      intensity * parseInt(lightColor.substring(9, 12)) +
        (1 - intensity) * parseInt(darkColor.substring(9, 12))
    );
    const b = Math.round(
      intensity * parseInt(lightColor.substring(14, 17)) +
        (1 - intensity) * parseInt(darkColor.substring(14, 17))
    );
    const colorStop = `rgb(${r}, ${g}, ${b})`;
    colorStops.push(colorStop);
  }

  const [targetAdherenceDataList, setTargetAdherenceDataList] = useState({
    parts_per_minute: 0,
    target_adherence: 0,
  });
  useEffect(() => {
    targetAdherence();
  }, []);
  const targetAdherence = async () => {
    try {
      const data = await QueryApi(`
      query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        dm_daily(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
              parts_per_minute
              target_adherence
            }
      }`);
      const partsAvg = data.dm_daily.reduce(
        (acc, curr) => acc + curr.parts_per_minute,
        0
      );
      const partAvgData = parseInt(partsAvg / data.dm_daily.length);
      const targetAvg = data.dm_daily.reduce(
        (acc, curr) => acc + curr.target_adherence,
        0
      );
      const targetAvgData = parseInt(targetAvg / data.dm_daily.length);
      setTargetAdherenceDataList({
        parts_per_minute: partAvgData,
        target_adherence: targetAvgData,
      });
    } catch (error) {
      setTargetAdherenceDataList({
        parts_per_minute: 0,
        target_adherence: 0,
      });
    }
  };

  useEffect(() => {
    capacityUtilization();
  }, []);
  const capacityUtilization = async () => {
    try {
      const data = await QueryApi(`
      query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        dm_daily(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
              capacity_utilized_percent
              availability_loss_percent
            }
          }`);
      const firstAvg = data.dm_daily.reduce(
        (acc, curr) => acc + curr.capacity_utilized_percent,
        0
      );
      const firstAvgData = parseInt(firstAvg / data.dm_daily.length);
      const secondAvg = data.dm_daily.reduce(
        (acc, curr) => acc + curr.availability_loss_percent,
        0
      );
      const secondAvgData = parseInt(secondAvg / data.dm_daily.length);
      setCapacityUtilizationDataList({
        availability_loss_percent: firstAvgData,
        capacity_utilized_percent: secondAvgData,
      });
    } catch (error) {
      setCapacityUtilizationDataList({
        availability_loss_percent: 0,
        capacity_utilized_percent: 0,
      });
    }
  };
  const [equipmentInsightsDataList, setEquipmentInsightsDataList] = useState({
    available_machines: "No Data",
    machines_under_maintenance: "No Data",
    unavailable_machines: "No Data",
  });

  useEffect(() => {
    equipmentInsights();
  }, []);
  const equipmentInsights = async () => {
    try {
      const data = await QueryApi(`
        query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
          fl2_avg_machines_available_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
            aggregate {
              avg {
                available_machines
                machines_under_maintenance
                unavailable_machines
              }
            }
          }
        } `);

      setEquipmentInsightsDataList({
        available_machines:
          data.fl2_avg_machines_available_aggregate.aggregate.avg
            .available_machines,
        machines_under_maintenance:
          data.fl2_avg_machines_available_aggregate.aggregate.avg
            .machines_under_maintenance,
        unavailable_machines:
          data.fl2_avg_machines_available_aggregate.aggregate.avg
            .unavailable_machines,
      });
    } catch (error) {
      setEquipmentInsightsDataList({
        available_machines: "No Data",
        machines_under_maintenance: "No Data",
        unavailable_machines: "No Data",
      });
    }
  };

  useEffect(() => {
    WorkforceEfficiencies();
  }, []);
  const WorkforceEfficiencies = async () => {
    try {
      const data = await QueryApi(`
    query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
      wfl0_overall_labor_effectiveness(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
        overall_labor_effectiveness
          }
        }
     `);
      const avg = data.wfl0_overall_labor_effectiveness.reduce(
        (acc, curr) => acc + curr.overall_labor_effectiveness,
        0
      );
      const avgData = parseInt(
        avg / data.wfl0_overall_labor_effectiveness.length
      );
      setWorkforceEfficienciesDataList({
        overall_labor_effectiveness: avgData,
      });
    } catch (error) {
      setWorkforceEfficienciesDataList({
        overall_labor_effectiveness: 0,
      });
    }
  };

  /////// productivity API //////////////////////////////////////////////////////////

  const dailyUpdates = async () => {
    try {
      const data = await QueryApi(`
      query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        dm_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
            aggregate {
                avg {
                    parts_per_minute
                }
            }
          }
        }`);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.avg.parts_per_minute === null
      ) {
        setDailyUpdatesDataList("No Data");
      } else {
        setDailyUpdatesDataList(
          data.dm_daily_aggregate.aggregate.avg.parts_per_minute.toFixed(1)
        );
      }
    } catch (error) {
      setDailyUpdatesDataList("No Data");
    }
  };

  ////// OEE ///////////////////////////////////////////////////////////

  const [OeeDataList, setOeeDataList] = useState({
    machine_availability_percent: "No Data",
    machine_performance_percent: "No Data",
    quality_percent: "No Data",
  });
  useEffect(() => {
    OeeFun();
  }, []);
  const OeeFun = async () => {
    try {
      const data = await QueryApi(`
      query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        dm_foundation_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
          aggregate {
            avg {
              machine_availability_percent
              machine_performance_percent
              quality_percent
            }
          }
        }
      } `);

      // Format the values to three decimal places
      let formattedData = data.dm_foundation_aggregate.aggregate.avg;
      formattedData.machine_availability_percent = parseFloat(
        formattedData.machine_availability_percent.toFixed(1)
      );
      formattedData.machine_performance_percent = parseFloat(
        formattedData.machine_performance_percent.toFixed(1)
      );
      formattedData.quality_percent = parseFloat(
        formattedData.quality_percent.toFixed(1)
      );

      setOeeDataList(formattedData);
    } catch (error) {
      setOeeDataList({
        machine_availability_percent: "No Data",
        machine_performance_percent: "No Data",
        quality_percent: "No Data",
      });
    }
  };

  //// Production Volume /////////////////////////////////////////////////////////////////////////////

  const [xAxisDataValueProductionVolume, setXAxisDataValueProductionVolume] =
    useState([]);
  const [plotPointProductionVolume, setPlotPointProductionVolume] = useState(
    []
  );
  const datasetProductionVolume = [
    {
      label: "Production Volume",
      data: plotPointProductionVolume,
      borderColor: "#1F8EF1",
    },
  ];

  useEffect(() => {
    GraphDataListProductionVolume();
  }, [bigChartData]);
  const GraphDataListProductionVolume = async () => {
    if (bigChartData === "data1") {
      try {
        const data = await QueryApi(`
                    query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
                        dm_org_tenant_hourly(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                            total_parts_produced
                            timestamp
                        }
                    }
                `);
        setXAxisDataValueProductionVolume([
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
          20, 21, 22, 23, 24,
        ]);
        const plotAllDataProductionVolume = data.dm_org_tenant_hourly.map(
          (data) => {
            const objData = {
              // point: (data.total_parts_produced / (1000 * 60 * 60)).toFixed(2),
              point: data.total_parts_produced,
              Xaxis: data.timestamp,
            };
            return objData;
          }
        );
        let plotValue = [];
        for (var i = 0; i <= resPointDate; i++) {
          plotValue[i] = 0;
        }
        plotAllDataProductionVolume.forEach((data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
          if (h >= 0 && h <= 1) {
            plotValue[0] = data.point;
          } else if (h >= 1 && h <= 2) {
            plotValue[1] = data.point;
          } else if (h >= 2 && h <= 3) {
            plotValue[2] = data.point;
          } else if (h >= 3 && h <= 4) {
            plotValue[3] = data.point;
          } else if (h >= 4 && h <= 5) {
            plotValue[4] = data.point;
          } else if (h >= 5 && h <= 6) {
            plotValue[5] = data.point;
          } else if (h >= 6 && h <= 7) {
            plotValue[6] = data.point;
          } else if (h >= 7 && h <= 8) {
            plotValue[7] = data.point;
          } else if (h >= 8 && h <= 9) {
            plotValue[8] = data.point;
          } else if (h >= 9 && h <= 10) {
            plotValue[9] = data.point;
          } else if (h >= 10 && h <= 11) {
            plotValue[10] = data.point;
          } else if (h >= 11 && h <= 12) {
            plotValue[11] = data.point;
          } else if (h >= 12 && h <= 13) {
            plotValue[12] = data.point;
          } else if (h >= 13 && h <= 14) {
            plotValue[13] = data.point;
          } else if (h >= 14 && h <= 15) {
            plotValue[14] = data.point;
          } else if (h >= 15 && h <= 16) {
            plotValue[15] = data.point;
          } else if (h >= 16 && h <= 17) {
            plotValue[16] = data.point;
          } else if (h >= 17 && h <= 18) {
            plotValue[17] = data.point;
          } else if (h >= 18 && h <= 19) {
            plotValue[18] = data.point;
          } else if (h >= 19 && h <= 20) {
            plotValue[19] = data.point;
          } else if (h >= 20 && h <= 21) {
            plotValue[20] = data.point;
          } else if (h >= 21 && h <= 22) {
            plotValue[21] = data.point;
          } else if (h >= 22 && h <= 23) {
            plotValue[22] = data.point;
          } else if (h >= 23 && h <= 24) {
            plotValue[23] = data.point;
          } else {
            return null;
          }
        });
        setPlotPointProductionVolume(plotValue);
      } catch (error) {
        console.log(error);
        setXAxisDataValueProductionVolume([]);
        setPlotPointProductionVolume([]);
      }
    } else if (bigChartData === "data2") {
      try {
        const data = await QueryApi(`
                    query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                        dm_org_tenant_daily(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                            total_parts_produced
                            timestamp
                        }
                    }
                `);
        const plotAllDataProductionVolume = data.dm_org_tenant_daily.map(
          (data) => {
            const objData = {
              point: data.total_parts_produced,
              Xaxis: data.timestamp,
            };
            return objData;
          }
        );

        // const plotPointDataProductionVolume = plotAllDataProductionVolume.map(
        //     (data) => data.point
        // );
        // setPlotPointProductionVolume(plotPointDataProductionVolume);

        function getPlotAllDaysBetween(startDate, endDate) {
          var newDate = new Date(startDate);
          var weekDATE = new Date(endDate);
          var days = [];
          while (newDate <= weekDATE) {
            const dd = newDate.getDate();
            const mm = newDate.getMonth() + 1;
            const yy = newDate.getFullYear();
            const formattedd = dd < 10 ? `0${dd}` : dd;
            const formattedmm = mm < 10 ? `0${mm}` : mm;
            const fullDATE = `${yearYear}-${formattedmm}-${formattedd}`;
            days.push(fullDATE);
            newDate.setDate(newDate.getDate() + 1);
          }
          return days;
        }
        var daysBetween = getPlotAllDaysBetween(weekDataDate, currentDate);
        var pointArr = [];
        daysBetween.map((data) => {
          var arrlet = plotAllDataProductionVolume.map((data) => {
            var arrDate = new Date(data.Xaxis);
            let nday = arrDate.getUTCDate();
            let nmonth = arrDate.getMonth() + 1;
            let nyear = arrDate.getFullYear();
            const ncurrDay = nday < 10 ? `0${nday}` : nday;
            const ncurrMonth = nmonth < 10 ? `0${nmonth}` : nmonth;
            let nDateList = `${nyear}-${ncurrMonth}-${ncurrDay}`;
            return nDateList;
          });
          const res = arrlet.includes(data);
          const resindex = arrlet.indexOf(data);
          if (res === true) {
            pointArr.push(plotAllDataProductionVolume[resindex].point);
          } else {
            pointArr.push(0);
          }
        });
        setPlotPointProductionVolume(pointArr);

        function getAllDaysBetween(startDate, endDate) {
          var currentDate = new Date(startDate);
          var days = [];
          while (currentDate <= endDate) {
            days.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
          }
          return days;
        }
        function getDayOfWeek(date) {
          var days = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];
          return days[date.getDay()];
        }
        function getDaysOfWeekBetween(startDate, endDate) {
          var daysBetween = getAllDaysBetween(startDate, endDate);
          var daysOfWeek = [];
          for (var i = 0; i < daysBetween.length; i++) {
            var dayOfWeek = getDayOfWeek(daysBetween[i]);
            daysOfWeek.push(dayOfWeek);
          }
          return daysOfWeek;
        }
        var daysOfWeekBetween = getDaysOfWeekBetween(
          new Date(weekDataDate),
          new Date(currentDate)
        );
        setXAxisDataValueProductionVolume(daysOfWeekBetween);
      } catch (error) {
        console.log(error);
        setXAxisDataValueProductionVolume([]);
        setPlotPointProductionVolume([]);
      }
    } else if (bigChartData === "data3") {
      try {
        const data = await QueryApi(`
                    query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                        dm_org_tenant_daily(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                            total_parts_produced
                            date
                        }
                    }
                `);
        const plotAllDataProductionVolume = data.dm_org_tenant_daily.map(
          (data) => {
            const objData = {
              point: data.total_parts_produced,
              Xaxis: data.date,
            };
            return objData;
          }
        );

        const bothAxisPoint = [];
        for (var i = 1; i <= 31; i++) {
          bothAxisPoint[i] = { pointValue: 0, XaxisValue: i };
        }
        plotAllDataProductionVolume.forEach((data) => {
          const allDate = new Date(data.Xaxis);
          const dayss = allDate.getDate();
          bothAxisPoint[dayss] = { pointValue: data.point, XaxisValue: dayss };
        });
        const NewPointData = bothAxisPoint.map((data) => data.pointValue);
        const dd = new Date();
        NewPointData.splice(dd.getDate() + 1, NewPointData.length);
        const NewXaxisData = bothAxisPoint.map((data) => data.XaxisValue);
        setPlotPointProductionVolume(NewPointData);
        setXAxisDataValueProductionVolume(NewXaxisData);
      } catch (error) {
        console.log(error);
        setXAxisDataValueProductionVolume([]);
        setPlotPointProductionVolume([]);
      }
    } else if (bigChartData === "data4") {
      try {
        const data = await QueryApi(`
                    query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                        dm_org_tenant_monthly(where: {start_date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                           total_parts_produced
                           start_date
                      }
                    }
                `);
        const plotAllDataProductionVolume = data.dm_org_tenant_monthly.map(
          (data) => {
            const objData = {
              point: data.total_parts_produced,
              Xaxis: data.timestamp,
            };
            return objData;
          }
        );

        const plotPointDataProductionVolume = plotAllDataProductionVolume.map(
          (data) => data.point
        );
        setPlotPointProductionVolume(plotPointDataProductionVolume);
        setXAxisDataValueProductionVolume([
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ]);
      } catch (error) {
        console.log(error);
        setXAxisDataValueProductionVolume([]);
        setPlotPointProductionVolume([]);
      }
    } else {
      setBgChartData("data1");
    }
  };

  //////Today's Production//////////////////////////////////////////////////////////////////////

  const [TodayProductionData, setTodayProductionData] = useState(0);
  useEffect(() => {
    TodayProduction();
  }, []);
  const TodayProduction = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
              dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                  sum {
                    total_parts_produced
                  }
                }
              }
            }
      `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.sum.total_parts_produced === null
      ) {
        setTodayProductionData("No Data");
      } else {
        setTodayProductionData(
          data.dm_daily_aggregate.aggregate.sum.total_parts_produced.toFixed(1)
        );
      }
    } catch (error) {
      console.log(error);
      setTodayProductionData("No Data");
    }
  };

  const [AvailabilityDataList, setAvailabilityDataList] = useState({
    machine_availability_percent: "No Data",
  });
  useEffect(() => {
    Availability();
  }, []);
  const Availability = async () => {
    try {
      const data = await QueryApi(`
        query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
          dm_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
            aggregate {
              avg {
                machine_availability_percent
              }
            }
          }
        } `);
      setAvailabilityDataList({
        machine_availability_percent:
          data.dm_daily_aggregate.aggregate.avg.machine_availability_percent,
      });
    } catch (error) {
      setAvailabilityDataList({
        machine_availability_percent: "No Data",
      });
    }
  };
  /////////////////////////// Down Time Trendline ////////////////////////////////////////////////////
  const mixedChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };
  const mixedChartData = {
    labels: ["January", "February", "March", "April", "May"],
    datasets: [
      {
        label: "Line Data 1",
        type: "line", // Specify line type
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 2,
        tension: 0.5,
        data: [7, 16, 25, 35, 54],
      },
      {
        label: "Line Data 2",
        type: "line", // Specify line type
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 2,
        tension: 0.5,
        data: [9, 23, 29, 32, 34],
      },
    ],
  };

  /////////////////////// downtime trendline ///////////////////////////////////////////////////

  const [
    xAxisDataValuePerformanceInsights,
    setXAxisDataValuePerformanceInsights,
  ] = useState([]);
  const [plotPointPerformanceInsights, setPlotPointPerformanceInsights] =
    useState([]);
  const datasetPerformanceInsights = [
    {
      label: "Down Time Trendline",
      data: plotPointPerformanceInsights,
      borderColor: "#1F8EF1",
    },
  ];

  useEffect(() => {
    GraphDataListPerformanceInsights();
  }, [DowntimebigChartData]);
  const GraphDataListPerformanceInsights = async () => {
    if (DowntimebigChartData === "data1") {
      try {
        const data = await QueryApi(`
                query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
                 dm_org_tenant_hourly(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                       total_machine_downtime
                        timestamp
                    }
                }
              `);
        setXAxisDataValuePerformanceInsights([
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
          20, 21, 22, 23, 24,
        ]);
        const plotAllDataPerformanceInsights = data.dm_org_tenant_hourly.map(
          (data) => {
            const objData = {
              point: (data.total_machine_downtime / (1000 * 60 * 60)).toFixed(
                2
              ),
              Xaxis: data.timestamp,
            };
            return objData;
          }
        );
        let plotValue = [];
        for (var i = 0; i <= resPointDate; i++) {
          plotValue[i] = 0;
        }
        plotAllDataPerformanceInsights.forEach((data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
          if (h >= 0 && h <= 1) {
            plotValue[0] = data.point;
          } else if (h >= 1 && h <= 2) {
            plotValue[1] = data.point;
          } else if (h >= 2 && h <= 3) {
            plotValue[2] = data.point;
          } else if (h >= 3 && h <= 4) {
            plotValue[3] = data.point;
          } else if (h >= 4 && h <= 5) {
            plotValue[4] = data.point;
          } else if (h >= 5 && h <= 6) {
            plotValue[5] = data.point;
          } else if (h >= 6 && h <= 7) {
            plotValue[6] = data.point;
          } else if (h >= 7 && h <= 8) {
            plotValue[7] = data.point;
          } else if (h >= 8 && h <= 9) {
            plotValue[8] = data.point;
          } else if (h >= 9 && h <= 10) {
            plotValue[9] = data.point;
          } else if (h >= 10 && h <= 11) {
            plotValue[10] = data.point;
          } else if (h >= 11 && h <= 12) {
            plotValue[11] = data.point;
          } else if (h >= 12 && h <= 13) {
            plotValue[12] = data.point;
          } else if (h >= 13 && h <= 14) {
            plotValue[13] = data.point;
          } else if (h >= 14 && h <= 15) {
            plotValue[14] = data.point;
          } else if (h >= 15 && h <= 16) {
            plotValue[15] = data.point;
          } else if (h >= 16 && h <= 17) {
            plotValue[16] = data.point;
          } else if (h >= 17 && h <= 18) {
            plotValue[17] = data.point;
          } else if (h >= 18 && h <= 19) {
            plotValue[18] = data.point;
          } else if (h >= 19 && h <= 20) {
            plotValue[19] = data.point;
          } else if (h >= 20 && h <= 21) {
            plotValue[20] = data.point;
          } else if (h >= 21 && h <= 22) {
            plotValue[21] = data.point;
          } else if (h >= 22 && h <= 23) {
            plotValue[22] = data.point;
          } else if (h >= 23 && h <= 24) {
            plotValue[23] = data.point;
          } else {
            return null;
          }
        });
        setPlotPointPerformanceInsights(plotValue);
      } catch (error) {
        console.log(error);
        setXAxisDataValuePerformanceInsights([]);
        setPlotPointPerformanceInsights([]);
      }
    } else if (DowntimebigChartData === "data2") {
      try {
        const data = await QueryApi(`
                query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                    dm_org_tenant_daily(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                        total_machine_downtime
                        timestamp
                    }
                }
              `);
        const plotAllDataPerformanceInsights = data.dm_org_tenant_daily.map(
          (data) => {
            const objData = {
              point: (data.total_machine_downtime / (1000 * 60 * 60)).toFixed(
                2
              ),
              Xaxis: data.timestamp,
            };
            return objData;
          }
        );
        function getPlotAllDaysBetween(startDate, endDate) {
          var newDate = new Date(startDate);
          var weekDATE = new Date(endDate);
          var days = [];
          while (newDate <= weekDATE) {
            const dd = newDate.getDate();
            const mm = newDate.getMonth() + 1;
            const yy = newDate.getFullYear();
            const formattedd = dd < 10 ? `0${dd}` : dd;
            const formattedmm = mm < 10 ? `0${mm}` : mm;
            const fullDATE = `${yearYear}-${formattedmm}-${formattedd}`;
            days.push(fullDATE);
            newDate.setDate(newDate.getDate() + 1);
          }
          return days;
        }
        var daysBetween = getPlotAllDaysBetween(weekDataDate, currentDate);
        var pointArr = [];
        daysBetween.map((data) => {
          var arrlet = plotAllDataPerformanceInsights.map((data) => {
            var arrDate = new Date(data.Xaxis);
            let nday = arrDate.getUTCDate();
            let nmonth = arrDate.getMonth() + 1;
            let nyear = arrDate.getFullYear();
            const ncurrDay = nday < 10 ? `0${nday}` : nday;
            const ncurrMonth = nmonth < 10 ? `0${nmonth}` : nmonth;
            let nDateList = `${nyear}-${ncurrMonth}-${ncurrDay}`;
            return nDateList;
          });
          const res = arrlet.includes(data);
          const resindex = arrlet.indexOf(data);
          if (res === true) {
            pointArr.push(plotAllDataPerformanceInsights[resindex].point);
          } else {
            pointArr.push(0);
          }
        });
        setPlotPointPerformanceInsights(pointArr);

        function getAllDaysBetween(startDate, endDate) {
          var currentDate = new Date(startDate);
          var days = [];
          while (currentDate <= endDate) {
            days.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
          }
          return days;
        }
        function getDayOfWeek(date) {
          var days = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];
          return days[date.getDay()];
        }
        function getDaysOfWeekBetween(startDate, endDate) {
          var daysBetween = getAllDaysBetween(startDate, endDate);
          var daysOfWeek = [];
          for (var i = 0; i < daysBetween.length; i++) {
            var dayOfWeek = getDayOfWeek(daysBetween[i]);
            daysOfWeek.push(dayOfWeek);
          }
          return daysOfWeek;
        }
        var daysOfWeekBetween = getDaysOfWeekBetween(
          new Date(weekDataDate),
          new Date(currentDate)
        );
        setXAxisDataValuePerformanceInsights(daysOfWeekBetween);
      } catch (error) {
        console.log(error);
        setXAxisDataValuePerformanceInsights([]);
        setPlotPointPerformanceInsights([]);
      }
    } else if (DowntimebigChartData === "data3") {
      try {
        const data = await QueryApi(`
                query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                    dm_org_tenant_daily(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                        total_machine_downtime
                        date
                    }
                }
              `);
        const plotAllDataPerformanceInsights = data.dm_org_tenant_daily.map(
          (data) => {
            const objData = {
              point: (data.total_machine_downtime / (1000 * 60 * 60)).toFixed(
                2
              ),
              Xaxis: data.date,
            };
            return objData;
          }
        );

        const bothAxisPoint = [];
        for (var i = 1; i <= 31; i++) {
          bothAxisPoint[i] = { pointValue: 0, XaxisValue: i };
        }
        plotAllDataPerformanceInsights.forEach((data) => {
          const allDate = new Date(data.Xaxis);
          const dayss = allDate.getDate();
          bothAxisPoint[dayss] = { pointValue: data.point, XaxisValue: dayss };
        });
        const NewPointData = bothAxisPoint.map((data) => data.pointValue);
        const dd = new Date();
        NewPointData.splice(dd.getDate() + 1, NewPointData.length);
        const NewXaxisData = bothAxisPoint.map((data) => data.XaxisValue);
        setPlotPointPerformanceInsights(NewPointData);
        setXAxisDataValuePerformanceInsights(NewXaxisData);
      } catch (error) {
        console.log(error);
        setXAxisDataValuePerformanceInsights([]);
        setPlotPointPerformanceInsights([]);
      }
    } else if (DowntimebigChartData === "data4") {
      try {
        const data = await QueryApi(`
                query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                    dm_org_tenant_monthly(where: {start_date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                        total_machine_downtime
                       start_date
                  }
                }
            `);
        const plotAllDataInsights = data.dm_org_tenant_monthly.map((data) => {
          const objData = {
            point: (data.total_machine_downtime / (1000 * 60 * 60)).toFixed(2),
            Xaxis: data.timestamp,
          };
          return objData;
        });

        const plotPointDataInsights = plotAllDataInsights.map(
          (data) => data.point
        );
        setPlotPointPerformanceInsights(plotPointDataInsights);
        setXAxisDataValuePerformanceInsights([
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ]);
      } catch (error) {
        console.log(error);
        setXAxisDataValuePerformanceInsights([]);
        setPlotPointPerformanceInsights([]);
      }
    } else {
      setDowntimebigChartData("data1");
    }
  };

  /////////////////////// Cycle Time Performance ///////////////////////////////////////////////////

  const [
    xAxisDataValueCycleTimePerformance,
    setXAxisDataValueCycleTimePerformance,
  ] = useState([]);
  const [plotPointCycleTimePerformance, setPlotPointCycleTimePerformance] =
    useState([]);
  const datasetCycleTimePerformance = [
    {
      label: "Cycle Time Performace ",
      data: plotPointCycleTimePerformance,
      borderColor: "#1F8EF1",
    },
  ];

  useEffect(() => {
    GraphDataListCycleTimePerformance();
  }, [CyclebigChartData]);
  const GraphDataListCycleTimePerformance = async () => {
    if (CyclebigChartData === "data1") {
      try {
        const data = await QueryApi(`
                query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
                    dm_org_tenant_hourly(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                        actual_cycletime
                        timestamp
                        }
                    }
                `);
        setXAxisDataValueCycleTimePerformance([
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
          20, 21, 22, 23, 24,
        ]);
        const plotPointDataCycleTimePerformance = data.dm_org_tenant_hourly.map(
          (data) => {
            const objData = {
              point: (data.actual_cycletime / (1000 * 60 * 60)).toFixed(2),
              Xaxis: data.timestamp,
            };
            return objData;
          }
        );
        let plotValue = [];
        for (var i = 0; i <= resPointDate; i++) {
          plotValue[i] = 0;
        }
        plotPointDataCycleTimePerformance.forEach((data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
          if (h >= 0 && h <= 1) {
            plotValue[0] = data.point;
          } else if (h >= 1 && h <= 2) {
            plotValue[1] = data.point;
          } else if (h >= 2 && h <= 3) {
            plotValue[2] = data.point;
          } else if (h >= 3 && h <= 4) {
            plotValue[3] = data.point;
          } else if (h >= 4 && h <= 5) {
            plotValue[4] = data.point;
          } else if (h >= 5 && h <= 6) {
            plotValue[5] = data.point;
          } else if (h >= 6 && h <= 7) {
            plotValue[6] = data.point;
          } else if (h >= 7 && h <= 8) {
            plotValue[7] = data.point;
          } else if (h >= 8 && h <= 9) {
            plotValue[8] = data.point;
          } else if (h >= 9 && h <= 10) {
            plotValue[9] = data.point;
          } else if (h >= 10 && h <= 11) {
            plotValue[10] = data.point;
          } else if (h >= 11 && h <= 12) {
            plotValue[11] = data.point;
          } else if (h >= 12 && h <= 13) {
            plotValue[12] = data.point;
          } else if (h >= 13 && h <= 14) {
            plotValue[13] = data.point;
          } else if (h >= 14 && h <= 15) {
            plotValue[14] = data.point;
          } else if (h >= 15 && h <= 16) {
            plotValue[15] = data.point;
          } else if (h >= 16 && h <= 17) {
            plotValue[16] = data.point;
          } else if (h >= 17 && h <= 18) {
            plotValue[17] = data.point;
          } else if (h >= 18 && h <= 19) {
            plotValue[18] = data.point;
          } else if (h >= 19 && h <= 20) {
            plotValue[19] = data.point;
          } else if (h >= 20 && h <= 21) {
            plotValue[20] = data.point;
          } else if (h >= 21 && h <= 22) {
            plotValue[21] = data.point;
          } else if (h >= 22 && h <= 23) {
            plotValue[22] = data.point;
          } else if (h >= 23 && h <= 24) {
            plotValue[23] = data.point;
          } else {
            return null;
          }
        });
        setPlotPointCycleTimePerformance(plotValue);
      } catch (error) {
        console.log(error);
      }
    } else if (CyclebigChartData === "data2") {
      try {
        const data = await QueryApi(`
                query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                    dm_org_tenant_daily(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                        actual_cycletime
                        timestamp
                        }
                    }
                `);
        const plotAllDataCycleTimePerformance = data.dm_org_tenant_daily.map(
          (data) => {
            const objData = {
              point: (data.actual_cycletime / (1000 * 60 * 60)).toFixed(2),
              Xaxis: data.timestamp,
            };
            return objData;
          }
        );

        function getPlotAllDaysBetween(startDate, endDate) {
          var newDate = new Date(startDate);
          var weekDATE = new Date(endDate);
          var days = [];
          while (newDate <= weekDATE) {
            const dd = newDate.getDate();
            const mm = newDate.getMonth() + 1;
            const yy = newDate.getFullYear();
            const formattedd = dd < 10 ? `0${dd}` : dd;
            const formattedmm = mm < 10 ? `0${mm}` : mm;
            const fullDATE = `${yearYear}-${formattedmm}-${formattedd}`;
            days.push(fullDATE);
            newDate.setDate(newDate.getDate() + 1);
          }
          return days;
        }
        var daysBetween = getPlotAllDaysBetween(weekDataDate, currentDate);
        var pointArr = [];
        daysBetween.map((data) => {
          var arrlet = plotAllDataCycleTimePerformance.map((data) => {
            var arrDate = new Date(data.Xaxis);
            let nday = arrDate.getUTCDate();
            let nmonth = arrDate.getMonth() + 1;
            let nyear = arrDate.getFullYear();
            const ncurrDay = nday < 10 ? `0${nday}` : nday;
            const ncurrMonth = nmonth < 10 ? `0${nmonth}` : nmonth;
            let nDateList = `${nyear}-${ncurrMonth}-${ncurrDay}`;
            return nDateList;
          });
          const res = arrlet.includes(data);
          const resindex = arrlet.indexOf(data);
          if (res === true) {
            pointArr.push(plotAllDataCycleTimePerformance[resindex].point);
          } else {
            pointArr.push(0);
          }
        });
        setPlotPointCycleTimePerformance(pointArr);

        function getAllDaysBetween(startDate, endDate) {
          var currentDate = new Date(startDate);
          var days = [];
          while (currentDate <= endDate) {
            days.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
          }
          return days;
        }
        function getDayOfWeek(date) {
          var days = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];
          return days[date.getDay()];
        }
        function getDaysOfWeekBetween(startDate, endDate) {
          var daysBetween = getAllDaysBetween(startDate, endDate);
          var daysOfWeek = [];
          for (var i = 0; i < daysBetween.length; i++) {
            var dayOfWeek = getDayOfWeek(daysBetween[i]);
            daysOfWeek.push(dayOfWeek);
          }
          return daysOfWeek;
        }
        var daysOfWeekBetween = getDaysOfWeekBetween(
          new Date(weekDataDate),
          new Date(currentDate)
        );
        setXAxisDataValueCycleTimePerformance(daysOfWeekBetween);
      } catch (error) {
        console.log(error);
        setXAxisDataValueCycleTimePerformance([]);
        setPlotPointCycleTimePerformance([]);
      }
    } else if (CyclebigChartData === "data3") {
      try {
        const data = await QueryApi(`
                query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                    dm_org_tenant_daily(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                            actual_cycletime
                            date
                        }
                    }
                `);
        const plotAllDataCycleTimePerformance = data.dm_org_tenant_daily.map(
          (data) => {
            const objData = {
              point: (data.actual_cycletime / (1000 * 60 * 60)).toFixed(2),
              Xaxis: data.date,
            };
            return objData;
          }
        );
        const bothAxisPoint = [];
        for (var i = 1; i <= 31; i++) {
          bothAxisPoint[i] = { pointValue: 0, XaxisValue: i };
        }
        plotAllDataCycleTimePerformance.forEach((data) => {
          const allDate = new Date(data.Xaxis);
          const dayss = allDate.getDate();
          bothAxisPoint[dayss] = { pointValue: data.point, XaxisValue: dayss };
        });
        const NewPointData = bothAxisPoint.map((data) => data.pointValue);
        const dd = new Date();
        NewPointData.splice(dd.getDate() + 1, NewPointData.length);
        const NewXaxisData = bothAxisPoint.map((data) => data.XaxisValue);
        setPlotPointCycleTimePerformance(NewPointData);
        setXAxisDataValueCycleTimePerformance(NewXaxisData);
      } catch (error) {
        console.log(error);
        setXAxisDataValueCycleTimePerformance([]);
        setPlotPointCycleTimePerformance([]);
      }
    } else if (CyclebigChartData === "data4") {
      try {
        const data = await QueryApi(`
                query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                    dm_org_tenant_monthly(where: {start_date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                        actual_cycletime
                       start_date
                  }
                }
            `);
        const plotAllDataProductionVolume = data.dm_org_tenant_monthly.map(
          (data) => {
            const objData = {
              point: (data.actual_cycletime / (1000 * 60 * 60)).toFixed(2),
              Xaxis: data.start_date,
            };
            return objData;
          }
        );
        const plotPointDataCycleTimePerformance =
          plotAllDataProductionVolume.map((data) => data.point);
        setPlotPointCycleTimePerformance(plotPointDataCycleTimePerformance);
        setXAxisDataValueCycleTimePerformance([
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ]);
      } catch (error) {
        console.log(error);
      }
    } else {
      setCycleTimeData("data1");
    }
  };

  /////// Capacity Utilization /////////////////////////////////////////////////////////////

  const [CapacityUtilizationShiftData, setCapacityUtilizationShiftData] =
    useState([]);
  useEffect(() => {
    CapacityUtilizationShift();
  }, []);
  const CapacityUtilizationShift = async () => {
    try {
      const data = await QueryApi(`
         query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
             dm_shiftwise(where: {date: {_gte: $start_date, _lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                capacity_utilized_percent
                timestamp
             }
           }
     `);

      if (data === undefined || data.dm_shiftwise === null) {
        setCapacityUtilizationShiftData([
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ]);
      } else {
        const CapacityUtilizationShift = [
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ];

        data.dm_shiftwise.map((data) => {
          var shiftDate = new Date(data.timestamp);
          var HoursshiftDate = shiftDate.getHours();
          if (HoursshiftDate >= 8 && HoursshiftDate <= 16) {
            CapacityUtilizationShift[0] = {
              name: "Shift 1",
              dataValue: data.capacity_utilized_percent,
            };
          } else if (HoursshiftDate >= 16 && HoursshiftDate <= 24) {
            CapacityUtilizationShift[1] = {
              name: "Shift 2",
              dataValue: data.capacity_utilized_percent,
            };
          } else if (HoursshiftDate >= 0 && HoursshiftDate <= 8) {
            CapacityUtilizationShift[2] = {
              name: "Shift 3",
              dataValue: data.capacity_utilized_percent,
            };
          }
        });
        setCapacityUtilizationShiftData(CapacityUtilizationShift);
      }
    } catch (error) {
      console.log(error);
      setCapacityUtilizationShiftData([
        {
          name: "shift 1",
          dataValue: "0",
        },
        {
          name: "shift 2",
          dataValue: "0",
        },
        {
          name: "shift 3",
          dataValue: "0",
        },
      ]);
    }
  };

  ///// Workforce Efficiency ///////////////////////////////////////////////////////////////////////////////////

  const [WorkforceEfficiencyShiftData, setWorkforceEfficiencyShiftData] =
    useState([]);
  useEffect(() => {
    WorkforceEfficiencyShift();
  }, []);
  const WorkforceEfficiencyShift = async () => {
    try {
      const data = await QueryApi(`
         query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = 15, $end_date: date = ${currentDate}) {
             wfl0_workforce_efficiency_shiftwise(where: {date: {_gte: $start_date, _lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                 overall_labor_effectiveness
                 shift
                 timestamp
               } 
           }
     `);
      if (
        data === undefined ||
        data.wfl0_workforce_efficiency_shiftwise === null
      ) {
        setWorkforceEfficiencyShiftData([
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ]);
      } else {
        const WorkforceEfficiencyShift = [
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ];

        data.wfl0_workforce_efficiency_shiftwise.map((data) => {
          var shiftDate = new Date(data.timestamp);
          var HoursshiftDate = shiftDate.getHours();
          if (HoursshiftDate >= 8 && HoursshiftDate <= 16) {
            WorkforceEfficiencyShift[0] = {
              name: "Shift 1",
              dataValue: data.overall_labor_effectiveness,
            };
          } else if (HoursshiftDate >= 16 && HoursshiftDate <= 24) {
            WorkforceEfficiencyShift[1] = {
              name: "Shift 2",
              dataValue: data.overall_labor_effectiveness,
            };
          } else if (HoursshiftDate >= 0 && HoursshiftDate <= 8) {
            WorkforceEfficiencyShift[2] = {
              name: "Shift 3",
              dataValue: data.overall_labor_effectiveness,
            };
          }
        });
        setWorkforceEfficiencyShiftData(WorkforceEfficiencyShift);
      }
    } catch (error) {
      console.log(error);
      setWorkforceEfficiencyShiftData([
        {
          name: "shift 1",
          dataValue: "0",
        },
        {
          name: "shift 2",
          dataValue: "0",
        },
        {
          name: "shift 3",
          dataValue: "0",
        },
      ]);
    }
  };

  ////// Cycle Time //////////////////////////////////////////////////////////////////////

  const [CycleTimeData, setCycleTimeData] = useState(0);
  useEffect(() => {
    CycleTime();
  }, []);
  const CycleTime = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
              dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                  avg {
                    actual_cycletime
                  }
                }
              }
            }
      `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.avg.actual_cycletime === null
      ) {
        setCycleTimeData("No Data");
      } else {
        const cycleValue =
          data.dm_daily_aggregate.aggregate.avg.actual_cycletime / 1000;
        setCycleTimeData(cycleValue.toFixed(1));
      }
    } catch (error) {
      console.log(error);
      setCycleTimeData("No Data");
    }
  };

  ////// Workforce Availability //////////////////////////////////////////////////////////////////////

  const [WorkforceAvailabilityData, setWorkforceAvailabilityData] = useState(0);
  useEffect(() => {
    WorkforceAvailability();
  }, []);
  const WorkforceAvailability = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
              wdm_workforce_availability_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                  avg {
                    available_workforce
                  }
                }
              }
            }
      `);
      if (
        data === undefined ||
        data.wdm_workforce_availability_daily_aggregate.aggregate.avg
          .available_workforce === null
      ) {
        setWorkforceAvailabilityData("No Data");
      } else {
        setWorkforceAvailabilityData(
          data.wdm_workforce_availability_daily_aggregate.aggregate.avg.available_workforce.toFixed(
            1
          )
        );
      }
    } catch (error) {
      console.log(error);
      setWorkforceAvailabilityData("No Data");
    }
  };

   //////////////////////// Today's Run Time card data ////////////////////////////
   const [todayRuntimeCardData, setTodaysRuntimeCardData] = useState(0);
  
   useEffect(() => {
     TodaysRuntime();
   }, []);
   const TodaysRuntime = async () => {
     try {
       const data = await QueryApi(`
             query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
               dm_org_tenant_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                 aggregate {
                   sum {
                     total_machine_runtime
                   }
                 }
               }
             }
       `);
       if (
         data === undefined ||
         data.dm_org_tenant_daily_aggregate.aggregate.sum.total_machine_runtime === null
       ) {
         setTodaysRuntimeCardData("No Data");
       } else {
         setTodaysRuntimeCardData(
           data.dm_org_tenant_daily_aggregate.aggregate.sum.total_machine_runtime.toFixed(1)
         );
       }
     } catch (error) {
       console.log(error);
       setTodaysRuntimeCardData("No Data");
     }
   };
  ////// Today's Runtime //////////////////////////////////////////////////////////////////////

  const [TodayRuntimeData, setTodayRuntimeData] = useState(0);
  useEffect(() => {
    TodayRuntime();
  }, []);
  const TodayRuntime = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
              dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                  sum {
                    total_machine_runtime
                  }
                }
              }
            }
      `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.sum.total_machine_runtime === null
      ) {
        setTodayRuntimeData("No Data");
      } else {
        const numberData =
          data.dm_daily_aggregate.aggregate.sum.total_machine_runtime /
          (1000 * 60 * 60);
        setTodayRuntimeData(numberData.toFixed(1));
      }
    } catch (error) {
      console.log(error);
      setTodayRuntimeData("No Data");
    }
  };

  ////// Today's Time Loss //////////////////////////////////////////////////////////////////////

  const [TodayTimeLossData, setTodayTimeLossData] = useState(0);
  useEffect(() => {
    TodayTimeLoss();
  }, []);
  const TodayTimeLoss = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                sum {
                  availability_loss_time
                }
              }
            }
          }
      `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.sum.availability_loss_time === null
      ) {
        setTodayTimeLossData("No Data");
      } else {
        const numberData =
          data.dm_daily_aggregate.aggregate.sum.availability_loss_time /
          (1000 * 60 * 60);
        setTodayTimeLossData(numberData.toFixed(1));
      }
    } catch (error) {
      console.log(error);
      setTodayTimeLossData("No Data");
    }
  };
///////// Today's Down Time Data //////////////////////
const [todayDownTimeData, setTodayDownTimeData] = useState(0);
useEffect(() => {
  TodayDowntime();
}, []);
const TodayDowntime = async () => {
  try {
    const data = await QueryApi(`
          query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_org_tenant_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                sum {
                  total_machine_downtime
                }
              }
            }
          }
    `);
    if (
      data === undefined ||
      data.dm_org_tenant_daily_aggregate.aggregate.sum.total_machine_downtime === null
    ) {
      setTodayDownTimeData("No Data");
    } else {
      const numberData =
        data.dm_org_tenant_daily_aggregate.aggregate.sum.total_machine_downtime /
        (1000 * 60 * 60);
        setTodayDownTimeData(numberData.toFixed(1));
    }
  } catch (error) {
    console.log(error);
    setTodayDownTimeData("No Data");
  }
};

  ///// OEE //////////////////////////////////////////////////////////////////////

  useEffect(() => {
    OEEList();
  }, []);
  const OEEList = async () => {
    try {
      const data = await QueryApi(`
              query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                  aggregate {
                    avg {
                        oee
                    }
                  }
                }
              }
          `);
      if (
        data === undefined ||
        data.dm_daily_aggregate.aggregate.avg.oee === null
      ) {
        setOEEListData("No Data");
      } else {
        setOEEListData(data.dm_daily_aggregate.aggregate.avg.oee.toFixed(1));
      }
    } catch (error) {
      console.log(error);
      setOEEListData("No Data");
    }
  };

  return (
    <>
      <div className="content">
        <Row noGutters>
          {dashboardData.map((dashboard) => (
            <Col
              lg="2"
              className="text-center"
              key={dashboard.tenant_dashboard_master_id}
            >
              <a
                href={`/admin/custom-dashboard/${dashboard.tenant_dashboard_master_id}`}
                onClick={() => {
                  sessionStorage.setItem(
                    "selectedDashboardId",
                    dashboard.tenant_dashboard_master_id
                  );
                  sessionStorage.setItem("is_default", dashboard.is_default);
                  window.location.reload();
                }}
              >
                <Card className="card-chart">
                  <CardHeader>
                    <div className="icon-circle">
                      <FontAwesomeIcon
                        icon={faChartBar}
                        rotation={270}
                        className="text-info icon-size"
                      />
                    </div>
                  </CardHeader>
                  <CardTitle tag="h4" className="mt-2">
                    <span
                      className="header-title"
                      style={{ fontSize: "1.3rem" }}
                    >
                      My Dashboard
                    </span>
                  </CardTitle>
                </Card>
              </a>
            </Col>
          ))}
          {/* {dashboardData.map((dashboard) => (
                        <Col
                            lg="2"
                            className="text-center"
                            key={dashboard.tenant_dashboard_master_id}
                        >
                            <Card className="card-chart">
                                <CardHeader>
                                    <div className="icon-circle">
                                        <FontAwesomeIcon
                                            icon={faChartBar}
                                            rotation={270}
                                            className="text-info icon-size"
                                        />
                                    </div>
                                </CardHeader>
                                <CardTitle tag="h4" className="mt-2">
                                    <a
                                        href={`/admin/custom-dashboard/${dashboard.tenant_dashboard_master_id}`}
                                        onClick={() => {
                                            sessionStorage.setItem(
                                                "selectedDashboardId",
                                                dashboard.tenant_dashboard_master_id
                                            );
                                            sessionStorage.setItem(
                                                "is_default",
                                                dashboard.is_default
                                            );
                                            window.location.reload();
                                        }}
                                        className="header-title"
                                        style={{ fontSize: '1.3rem' }}
                                    >
                                        My Dashboard
                                    </a>
                                </CardTitle>
                            </Card>
                        </Col>
                    ))} */}
          <Col lg="2" className="text-center">
            <Card className="card-chart">
              <CardHeader>
                <div className="icon-circle">
                  <FontAwesomeIcon
                    icon={faDashboard}
                    className="text-info icon-size"
                  />
                </div>
              </CardHeader>
              <CardTitle tag="h4" className="mt-2">
                <span className="header-title" style={{ fontSize: "1.3rem" }}>
                  Dashboard
                </span>
                {/* <a href="#" className="header-title" style={{ fontSize: '1.3rem' }}
                                >
                                    Dashboard
                                </a> */}
              </CardTitle>
            </Card>
          </Col>
          <Col lg="2" className="text-center">
            <a href={`/admin/mindmap`}>
              <Card className="card-chart">
                <CardHeader>
                  <div className="icon-circle">
                    <FontAwesomeIcon
                      icon={faVolumeUp}
                      className="text-info icon-size"
                    />
                  </div>
                </CardHeader>
                <CardTitle tag="h4" className="mt-2">
                  <span className="header-title" style={{ fontSize: "1.3rem" }}>
                    Insights
                  </span>
                </CardTitle>
              </Card>
            </a>
          </Col>
          <Col lg="2" className="text-center">
            <a href={`/admin/IFAAnomalyAndDeviation`}>
              <Card className="card-chart">
                <CardHeader>
                  <div className="icon-circle">
                    <FontAwesomeIcon
                      icon={faBan}
                      className="text-info icon-size"
                    />
                  </div>
                </CardHeader>
                <CardTitle tag="h4" className="mt-2">
                  <span className="header-title" style={{ fontSize: "1.3rem" }}>
                    Anomaly & Deviations
                  </span>
                </CardTitle>
              </Card>
            </a>
          </Col>
          <Col lg="2" className="text-center">
            <Card className="card-chart">
              <CardHeader>
                <div className="icon-circle">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-info icon-size"
                  />
                </div>
              </CardHeader>
              <CardTitle tag="h4" className="mt-2">
                <span className="header-title" style={{ fontSize: "1.3rem" }}>
                  Decisions
                </span>

                {/* <a href="#" className="header-title" style={{ fontSize: '1.3rem' }}
                                >
                                    {" "}
                                    Decisions{" "}
                                </a> */}
              </CardTitle>
            </Card>
          </Col>
          <Col lg="2" className="text-center">
            <Card className="card-chart">
              <CardHeader>
                <div className="icon-circle">
                  <FontAwesomeIcon
                    icon={faChartArea}
                    className="text-info icon-size"
                  />
                </div>
              </CardHeader>
              <CardTitle tag="h4" className="mt-2">
                <span className="header-title" style={{ fontSize: "1.3rem" }}>
                  Predictions
                </span>
                {/* <a href="#" className="header-title" style={{ fontSize: '1.3rem' }}
                                >
                                    Predictions
                                </a> */}
              </CardTitle>
            </Card>
          </Col>
        </Row>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "0.8rem",
            marginLeft: "0.5rem",
            height: "13rem",
          }}
        >
          <div
            style={{
              width: "70%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                gap: "0.8rem",
              }}
            >
              <div
                onClick={() => navigate("/admin/IFATargetAdherance")}
                style={{
                  backgroundColor: "#27293D",
                  color: "white",
                  height: "6rem",
                  width: "100%",
                  borderRadius: "0.8rem",
                  display: "grid",
                  gridTemplateColumns: "75% 25%",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    marginLeft: "1rem",
                  }}
                >
                  <div style={{ fontWeight: "550", fontSize: "1.1rem" }}>
                    Target Adherence
                  </div>
                  <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                    {targetAdherenceDataList.target_adherence
                      ? targetAdherenceDataList.target_adherence
                      : "No Data"}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "6rem",
                    width: "3rem",
                  }}
                >
                  <img src={targetImg} />
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "#27293D",
                  color: "white",
                  height: "6rem",
                  width: "100%",
                  borderRadius: "0.8rem",
                  display: "grid",
                  gridTemplateColumns: "75% 25%",
                  cursor: "pointer",
                }}
                // onClick={() => navigate('/admin/dashboard/cycle-time-analysis')}
                onClick={() => navigate("/admin/IFACycleTime")}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    marginLeft: "1rem",
                  }}
                >
                  <div style={{ fontWeight: "550", fontSize: "1.1rem" }}>
                    Cycle Time
                  </div>
                  <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                    {" "}
                    {CycleTimeData > 0 ? `${CycleTimeData} Seconds` : "No Data"}
                    {/* {CycleTimeData ? CycleTimeData : "No Data"}{" Seconds"} */}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "6rem",
                    width: "3rem",
                  }}
                >
                  <img src={cycleTime} />
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "#27293D",
                  color: "white",
                  height: "6rem",
                  width: "100%",
                  borderRadius: "0.8rem",
                  display: "grid",
                  gridTemplateColumns: "75% 25%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    marginLeft: "1rem",
                  }}
                >
                  <div style={{ fontWeight: "550", fontSize: "1.1rem" }}>
                    Today's Production
                  </div>
                  <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                    {" "}
                    {TodayProductionData > 0 ? `${TodayProductionData} Parts` : "No Data"}
                    {/* {TodayProductionData ? TodayProductionData : "No Data"}{" Parts"} */}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "6rem",
                    width: "3rem",
                  }}
                >
                  <img src={todayProductionImg} />
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "#27293D",
                  color: "white",
                  height: "6rem",
                  width: "100%",
                  borderRadius: "0.8rem",
                  display: "grid",
                  gridTemplateColumns: "75% 25%",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/admin/IFAWorkForceAvailability")}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    marginLeft: "1rem",
                  }}
                >
                  <div style={{ fontWeight: "550", fontSize: "1.1rem" }}>
                    {" "}
                    Workforce Availability
                  </div>
                  <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                    {" "}
                    {WorkforceAvailabilityData > 0 ? `${WorkforceAvailabilityData}` : "No Data"}
                    {/* {WorkforceAvailabilityData
                      ? WorkforceAvailabilityData
                      : "No Data"}{" "} */}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "6rem",
                    width: "3rem",
                  }}
                >
                  <img src={workforceAvailability} />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                gap: "0.8rem",
                marginBottom: "0.6rem",
              }}
            >
              <div
                style={{
                  backgroundColor: "#27293D",
                  color: "white",
                  height: "6rem",
                  width: "100%",
                  borderRadius: "0.8rem",
                  display: "grid",
                  gridTemplateColumns: "75% 25%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    marginLeft: "1rem",
                  }}
                >
                  <div style={{ fontWeight: "550", fontSize: "1.1rem" }}>
                    Today's Runtime
                  </div>
                  <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                    {" "}
                    {TodayTimeLossData > 0 ? `${(todayRuntimeCardData / 3600000).toFixed(2)} Hours` : "No Data"}
                    {/* {todayRuntimeCardData ? (todayRuntimeCardData / 3600000).toFixed(2) : "No Data"}{" Hours"} */}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "5.5rem",
                    width: "3rem",
                  }}
                >
                  <img src={runTime} />
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "#27293D",
                  color: "white",
                  height: "6rem",
                  width: "100%",
                  borderRadius: "0.8rem",
                  display: "grid",
                  gridTemplateColumns: "75% 25%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    marginLeft: "1rem",
                  }}
                >
                  <div style={{ fontWeight: "550", fontSize: "1.1rem" }}>
                    {" "}
                    Today's Time Loss
                  </div>
                  <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                    {" "}
                    {TodayTimeLossData > 0 ? `${TodayTimeLossData} Hours` : "No Data"}
                    {/* {TodayTimeLossData ? TodayTimeLossData : "No Data"}{" Hours"} */}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "6rem",
                    width: "3rem",
                  }}
                >
                  <img src={timeLoss} />
                </div>
              </div>
              <div
                onClick={() => {
                  navigate("/admin/IFADownTime");
                }}
                style={{
                  backgroundColor: "#27293D",
                  color: "white",
                  height: "6rem",
                  width: "100%",
                  borderRadius: "0.8rem",
                  display: "grid",
                  gridTemplateColumns: "75% 25%",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    marginLeft: "1rem",
                  }}
                >
                  <div style={{ fontWeight: "550", fontSize: "1.1rem" }}>
                    Today's downtime
                  </div>
                  <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                  {todayDownTimeData > 0 ? `${todayDownTimeData} Hours` : "No Data"}
                    {/* {todayDownTimeData ? `${todayDownTimeData}`: "No Data"} */}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  *
                </div>
              </div>
              <div
                onClick={() => {
                  navigate("/admin/IFALosses");
                }}
                style={{
                  backgroundColor: "#27293D",
                  color: "white",
                  height: "6rem",
                  width: "100%",
                  borderRadius: "0.8rem",
                  display: "grid",
                  gridTemplateColumns: "75% 25%",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    marginLeft: "1rem",
                  }}
                >
                  <div style={{ fontWeight: "550", fontSize: "1.1rem" }}>
                    Losses
                  </div>
                  <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                    {" "}
                    No Data{" "}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  *
                </div>
              </div>
            </div>
          </div>
          <Card style={{ flex: 1 }}>
            <CardHeader>
              <div className="icon-plus">
                <a
                  className="anchor-bg"
                  href={`/admin/dashboard/machine-equipment`}
                >
                  <FontAwesomeIcon icon={faArrowRightLong} />
                </a>
              </div>
              <h5 className="card-category">Machine/Equipment Insights</h5>
            </CardHeader>
            <CardBody>
              <div
                className="card-container machine-cards"
                style={{ marginTop: "0.6rem", height: "100%" }}
              >
                <div>
                  <div
                    className="card card-stats card-primary"
                    style={{
                      height: "100%",
                      border: "1px solid #151414",
                      paddingInline: "0.8rem",
                      filter:
                        "drop-shadow(-4px 10px 7px 11px rgba(0, 0, 0, 0.1))",
                    }}
                  >
                    <div
                      style={{
                        borderRadius: "13px",
                        textAlign: "center",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <p
                          className="card-title"
                          style={{ fontSize: "18px", color: "#1976D2" }}
                        >
                          {equipmentInsightsDataList.unavailable_machines
                            ? equipmentInsightsDataList.unavailable_machines
                            : "No Data"}
                        </p>
                        {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '3rem', width: '3rem' }}>
                                                    <img src={machineRed} />
                                                </div> */}
                      </div>
                      <div>
                        <p className="card-text">Unavailable Machines</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className="card card-stats card-secondary"
                    style={{
                      height: "100%",
                      border: "1px solid #151414",
                      paddingInline: "0.8rem",
                      filter:
                        "drop-shadow(-4px 10px 7px 11px rgba(0, 0, 0, 0.1))",
                    }}
                  >
                    <div
                      style={{
                        borderRadius: "13px",
                        textAlign: "center",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <p
                          className="card-title"
                          style={{ fontSize: "18px", color: "#1976D2" }}
                        >
                          {equipmentInsightsDataList.machines_under_maintenance
                            ? equipmentInsightsDataList.machines_under_maintenance
                            : "No Data"}
                        </p>
                        {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '3rem', width: '3rem' }}>
                                                    <img src={machineYellow} />
                                                </div> */}
                      </div>
                      <p className="card-text">Under Maintenance</p>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className="card card-stats card-tertiary"
                    style={{
                      height: "100%",
                      border: "1px solid #151414",
                      paddingInline: "0.8rem",
                      filter:
                        "drop-shadow(-4px 10px 7px 11px rgba(0, 0, 0, 0.1))",
                    }}
                  >
                    <div
                      style={{
                        borderRadius: "13px",
                        textAlign: "center",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <p
                          className="card-title"
                          style={{ fontSize: "18px", color: "#1976D2" }}
                        >
                          {equipmentInsightsDataList.available_machines
                            ? equipmentInsightsDataList.available_machines
                            : "No Data"}
                        </p>
                        {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '3rem', width: '3rem' }}>
                                                    <img src={machineGreen} />
                                                </div> */}
                      </div>
                      <p className="card-text">Running Machines</p>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <Row
          style={{
            height: "33.5rem",
            display: "flex",
            gap: "0.8rem",
            marginLeft: "0.5rem",
          }}
        >
          <div style={{ width: "21%" }}>
            <Card>
              <CardHeader>
                <div className="icon-plus">
                  <a className="anchor-bg" href={`/admin/oee`}>
                    <FontAwesomeIcon icon={faArrowRightLong} />
                  </a>
                </div>
                <h5 className="card-category">O.E.E</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-chart-pie text-info" />{" "}
                  {OEEListData > 0 ? `${OEEListData}%` : "No Data"}
                  {/* {OEEListData}{"%"} */}
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="gauge-container" style={{ height: "20rem" }}>
                  <div
                    ref={chartRef}
                    style={{
                      width: "100%",
                      height: "350px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </div>
                <div
                  className="percentage-circles-container"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div
                    style={{ cursor: "pointer" }}
                    className="percentage-circle-wrapper"
                    onClick={() => navigate("/admin/dashboard/productivity")}
                  >
                    <div className="percentage-circle-line"></div>
                    <PercentageCircle
                      percentage={Math.round(OeeDataList.machine_performance_percent)}
                      // percentage={OeeDataList.machine_performance_percent}
                      color="green"
                      size="30px"
                      borderWidth={1.5}
                    />
                    <div className="percentage-circle-label">Performance</div>
                  </div>
                  <div style={{ width: "10%" }}></div>
                  <div
                    className="percentage-circle-wrapper"
                    style={{ textAlign: "center",cursor: "pointer"}}
                    onClick={() => navigate("/admin/IFAAvailability")}
                  >
                    <div className="percentage-circle-line"></div>
                    <PercentageCircle
                      percentage={Math.round(OeeDataList.machine_availability_percent)}
                      // percentage={OeeDataList.machine_availability_percent}
                      color="blue"
                      size="30px"
                      borderWidth={1.5}
                    />
                    <div className="percentage-circle-label">Availability</div>
                  </div>
                  <div style={{ width: "10%" }}></div>
                  <div
                    className="percentage-circle-wrapper"
                    style={{ textAlign: "center" }}
                  >
                    <div className="percentage-circle-line"></div>
                    <PercentageCircle
                      percentage={Math.round(OeeDataList.quality_percent)}
                      // percentage={OeeDataList.quality_percent}
                      color="red"
                      size="30px"
                      borderWidth={1.5}
                    />
                    <div className="percentage-circle-label">Quality</div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div style={{ width: "32%" }}>
            <Card style={{ height: "39.5%" }}>
              <CardHeader>
                <div className="icon-plus">
                  <a className="anchor-bg" href="#">
                    <FontAwesomeIcon icon={faArrowRightLong} />
                  </a>
                </div>
                <h5 className="card-category">Capacity Utilization</h5>
              </CardHeader>
              <CardBody>
                <Row className="capacity-utilization-row">
                  <Col md="6" className="d-flex flex-column align-items-center">
                    <div
                      ref={CapacityUtilizationRef}
                      style={{
                        width: "100%",
                        height: "280px",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "-2.8rem",
                      }}
                    />
                  </Col>
                  <Col lg="6">
                    <CardBody
                      className="dashboard-page"
                      id="progress-container"
                      style={{ height: "100%" }}
                    >
                      <div
                        style={{
                          height: "100%",
                          display: "grid",
                          gridTemplateRows: "1fr 1fr 1fr",
                        }}
                      >
                        {CapacityUtilizationShiftData.map((data, index) => (
                          <div>
                            <p>{data.name}</p>
                            <ProgressBar
                              now={data.dataValue}
                              label={data.dataValue}
                              animated
                            />
                          </div>
                        ))}
                      </div>
                    </CardBody>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card style={{ height: "39.5%" }}>
              <CardHeader>
                <div className="icon-plus">
                  <a
                    className="anchor-bg"
                    href={`/admin/dashboard/workforce-efficiency`}
                  >
                    <FontAwesomeIcon icon={faArrowRightLong} />
                  </a>
                </div>
                <h5 className="card-category">Workforce Efficiencies</h5>
              </CardHeader>
              <CardBody>
                <Row className="capacity-utilization-row">
                  <Col md="6" className="d-flex flex-column align-items-center">
                    <div
                      ref={WorkforceEfficienciesRef}
                      style={{
                        width: "100%",
                        height: "280px",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "-2.8rem",
                      }}
                    />
                  </Col>
                  <Col lg="6">
                    <CardBody
                      className="dashboard-page"
                      id="progress-container"
                      style={{ height: "100%" }}
                    >
                      <div
                        style={{
                          height: "100%",
                          display: "grid",
                          gridTemplateRows: "1fr 1fr 1fr",
                        }}
                      >
                        {WorkforceEfficiencyShiftData.map((data, index) => (
                          <div>
                            <p>{data.name}</p>
                            <ProgressBar
                              now={data.dataValue}
                              label={data.dataValue}
                              animated
                            />
                          </div>
                        ))}
                      </div>
                    </CardBody>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
          <div style={{ width: "30%", height: "100%" }}>
            <div style={{ height: "100%" }}>
              <div className="card" style={{ height: "97%" }}>
                <Card lg="3" sm="8" style={{ height: "100%" }}>
                  <CardHeader>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Col>
                          <h5 className="card-category">Production Volume</h5>
                        </Col>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ paddingRight: "5px" }}>
                          <ButtonGroup
                            className="btn-group-toggle float-right"
                            data-toggle="buttons"
                          >
                            <Button
                              tag="label"
                              className={classNames("btn-simple", {
                                active: bigChartData === "data1",
                              })}
                              color="info"
                              id="0"
                              size="sm"
                              onClick={() => setBgChartData("data1")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Day
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-single-02" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="1"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: bigChartData === "data2",
                              })}
                              onClick={() => setBgChartData("data2")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Week
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-gift-2" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="2"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: bigChartData === "data3",
                              })}
                              onClick={() => setBgChartData("data3")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Month
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-tap-02" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="2"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: bigChartData === "data4",
                              })}
                              onClick={() => setBgChartData("data4")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Year
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-tap-02" />
                              </span>
                            </Button>
                          </ButtonGroup>
                        </div>
                        <div style={{ paddingLeft: "5px" }}>
                          <div className="icon-plus">
                            <a
                              className="anchor-bg"
                              href={`/admin/dashboard/production-volume`}
                            >
                              <FontAwesomeIcon icon={faArrowRightLong} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <LineChart
                      xAxisData={xAxisDataValueProductionVolume}
                      dataset={datasetProductionVolume}
                      height="100%"
                      width="100%"
                    />
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
          <div style={{ flex: "1", height: "97%", overflow: "hidden" }}>
            <Card id="img-bg" style={{ background: "transperant !important" }}>
              <div className="image-container">
                <CardTitle tag="h3" className="text-center">
                  <img
                    src={m8Image}
                    alt="Image"
                    className="image1"
                    style={{
                      height: "595px",
                      padding: "30px",
                      filter:
                        "brightness(2) sepia(1) hue-rotate(240deg) saturate(2)",
                    }}
                  />
                </CardTitle>
              </div>
            </Card>
          </div>
        </Row>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "0.8rem",
          }}
        >
          <div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="card" style={{ flex: "1 0 auto" }}>
                <Card className="card-chart" style={{ height: "336px" }}>
                  <CardHeader>
                    <Row>
                      <Col className="text-left" sm="6">
                        <h5 className="card-category">Downtime Trendline</h5>
                      </Col>
                      <Col sm="6">
                        <ButtonGroup
                          className="btn-group-toggle float-right"
                          data-toggle="buttons"
                        >
                          <Button
                            tag="label"
                            className={classNames("btn-simple", {
                              active: DowntimebigChartData === "data1",
                            })}
                            color="info"
                            id="0"
                            size="sm"
                            onClick={() => setDowntimebigChartData("data1")}
                          >
                            <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                              Day
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="tim-icons icon-single-02" />
                            </span>
                          </Button>
                          <Button
                            color="info"
                            id="1"
                            size="sm"
                            tag="label"
                            className={classNames("btn-simple", {
                              active: DowntimebigChartData === "data2",
                            })}
                            onClick={() => setDowntimebigChartData("data2")}
                          >
                            <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                              Week
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="tim-icons icon-gift-2" />
                            </span>
                          </Button>
                          <Button
                            color="info"
                            id="2"
                            size="sm"
                            tag="label"
                            className={classNames("btn-simple", {
                              active: DowntimebigChartData === "data3",
                            })}
                            onClick={() => setDowntimebigChartData("data3")}
                          >
                            <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                              Month
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="tim-icons icon-tap-02" />
                            </span>
                          </Button>
                          <Button
                            color="info"
                            id="2"
                            size="sm"
                            tag="label"
                            className={classNames("btn-simple", {
                              active: DowntimebigChartData === "data4",
                            })}
                            onClick={() => setDowntimebigChartData("data4")}
                          >
                            <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                              Year
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="tim-icons icon-tap-02" />
                            </span>
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <LineChart
                      xAxisData={xAxisDataValuePerformanceInsights}
                      dataset={datasetPerformanceInsights}
                      height="100%"
                      width="100%"
                    />
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
          <div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="card" style={{ flex: "1 0 auto" }}>
                <Card className="card-chart" style={{ height: "336px" }}>
                  <CardHeader>
                    <Row>
                      <Col className="text-left" sm="6">
                        <h5 className="card-category">
                          Cycle Time Performance
                        </h5>
                      </Col>
                      <Col sm="6">
                        <ButtonGroup
                          className="btn-group-toggle float-right"
                          data-toggle="buttons"
                        >
                          <Button
                            tag="label"
                            className={classNames("btn-simple", {
                              active: CyclebigChartData === "data1",
                            })}
                            color="info"
                            id="0"
                            size="sm"
                            onClick={() => setCyclebigChartData("data1")}
                          >
                            <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                              Day
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="tim-icons icon-single-02" />
                            </span>
                          </Button>
                          <Button
                            color="info"
                            id="1"
                            size="sm"
                            tag="label"
                            className={classNames("btn-simple", {
                              active: CyclebigChartData === "data2",
                            })}
                            onClick={() => setCyclebigChartData("data2")}
                          >
                            <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                              Week
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="tim-icons icon-gift-2" />
                            </span>
                          </Button>
                          <Button
                            color="info"
                            id="2"
                            size="sm"
                            tag="label"
                            className={classNames("btn-simple", {
                              active: CyclebigChartData === "data3",
                            })}
                            onClick={() => setCyclebigChartData("data3")}
                          >
                            <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                              Month
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="tim-icons icon-tap-02" />
                            </span>
                          </Button>
                          <Button
                            color="info"
                            id="2"
                            size="sm"
                            tag="label"
                            className={classNames("btn-simple", {
                              active: CyclebigChartData === "data4",
                            })}
                            onClick={() => setCyclebigChartData("data4")}
                          >
                            <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                              Year
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="tim-icons icon-tap-02" />
                            </span>
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <LineChart
                      xAxisData={xAxisDataValueCycleTimePerformance}
                      dataset={datasetCycleTimePerformance}
                      height="100%"
                      width="100%"
                    />
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewDashboard;
