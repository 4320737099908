import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Progress,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import Select from "react-select";
import axios from "axios";
import  { APIURL } from './Apiurl.js';
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

const machineTypeOptions = [
  { value: "type1", label: "Type 1" },
  { value: "type2", label: "Type 2" },
  { value: "type3", label: "Type 3" },
];

function TenantAdd() {
  const [selectedMachineType, setSelectedMachineType] = useState(null);
  const [udpedit, setudpedit] = useState({
    tenantclientname: "",
    tenantsubdomain: "",
    // widgetid: "",
  });

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${APIURL}/api/tenant/${id}`)
      .then((response) => {
        setudpedit((prevState) => ({
          ...prevState,
          tenantclientname: response.data.tenant_client_name,
          tenantsubdomain: response.data.tenant_sub_domain,
        //   widgetid: response.data.widget_id,
         


        }));
       
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if the machine name is provided
   
    axios
      .post(`${APIURL}/api/tenant/${id}`, udpedit)
      .then((response) => {
        console.log(response);
        navigate(`/tenant/${id}`);
        if (response.status === 201) {
          window.location.href = "/admin/tenant/tenant-all";
        } else {
          // Reset the form or show a success message
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setudpedit((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };



  const handleMachineTypeChange = (selectedOption) => {
    setSelectedMachineType(selectedOption);
  };
  
  return (
    <div className="content">
      <h1>Tenant All</h1>
      <Breadcrumb>
        <BreadcrumbItem>Home</BreadcrumbItem>
        <BreadcrumbItem>Tenant All</BreadcrumbItem>
        <BreadcrumbItem active>Tenant Add</BreadcrumbItem>
      </Breadcrumb>
      <Row className="progress-row">
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Add New Tenant</h5>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit}>
              <Row>
                   
                    <Col className="pr-md-1" md="6">
                        <FormGroup>
                        <label htmlFor="exampleInputEmail1">Tenant Client Name</label>
                        <Input
                            placeholder="Tenant Client Name"
                            type="text"
                            name="tenantclientname"
                            value={udpedit.tenantclientname}
                            onChange={handleChange}
                        />
                        </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                        <FormGroup>
                        <label htmlFor="exampleInputEmail1">Tenant Sub-domain</label>
                        <Input
                            placeholder="Tenant Sub-domain"
                            type="text"
                            name="tenantsubdomain"
                            value={udpedit.tenantsubdomain}
                            onChange={handleChange}
                        />
                        </FormGroup>
                    </Col>
                    
                </Row>
                <Row>
                  <Col className="justify-end">
                    <Button type="submit" className="btn btn-primary">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default TenantAdd;
