import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import redirectToLoginIfTokenMissing from "views/TokenUtility";
import DeviationOfTime from "./DeviationOfTime";
import DeviationOfProduction from "./DeviationOfProduction";

function InfoAnomalyAndDeviation(props) {
  const [activeTab, setActiveTab] = useState("1");
  const storedTenantId = sessionStorage.getItem("selectedTenantId");
  useEffect(() => {
    redirectToLoginIfTokenMissing();
  }, []);
  return (
    <>
        <div className="content">
            <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={activeTab === "1" ? "active" : ""}
                            onClick={() => setActiveTab("1")}
                            style={{
                              width: "100%",
                              textAlign: "center",
                              backgroundColor:
                                activeTab === "1" ? "#5555e7" : "transparent",
                              color: activeTab === "1" ? "white" : "white",
                            }}
                        >
                             Time Deviation
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={activeTab === "2" ? "active" : ""}
                            onClick={() => setActiveTab("2")}
                            style={{
                              width: "100%",
                              textAlign: "center",
                              backgroundColor:
                                activeTab === "2" ? "#5555e7" : "transparent",
                              color: activeTab === "2" ? "white" : "white",
                            }}
                        >
                             Production Deviation
                        </NavLink>
                    </NavItem>
            </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col>
                <DeviationOfTime />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col>
                <DeviationOfProduction />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </>
  );
}

export default InfoAnomalyAndDeviation;
