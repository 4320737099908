import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Progress,
} from "reactstrap";
import axios from "axios";
import { APIURL } from './Apiurl.js';
import { toast } from 'react-toastify';

function JobrolemasterAdd() {
  const [activeStep, setActiveStep] = useState(1);
  const [progress, setProgress] = useState(0);

  const [organization_node_id, setOrganizationNodeId] = useState("");
  const [organization_node_name, setOrganizationNodeName] = useState("");
  const [organization_code, setOrganizationCode] = useState("");
  const [country_id, setCountryId] = useState("");
  const [city_id, setCityId] = useState("");
  const [parent_organization_id, setParentOrganizationId] = useState("");
  const [cost_centre_code, setCostCentreCode] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [orgnodes, setOrgNodes] = useState([]);
  
  const [orgnames, setOrgnames] = useState([]);

  useEffect(() => {
    fetchOrgNode();
    fetchOrgnames();
  }, []);

  const fetchOrgnames = async () => {
    try {
      const response = await axios.post(APIURL, {
        query: `
          query {
            organization {
                org_id
                org_code
              }
          }
        `,
      });

      setOrgnames(response.data.data.organization);
      console.log(response.data.data.organization);
    } catch (error) {
      console.error(error);
    }
    
  };
 
  const fetchOrgNode = async () => {
    try {
      const response = await axios.post(APIURL, {
        query: `
          query {
            organization_node {
                organization_node_id
                organization_node_name
              }
          }
        `,
      });

      setOrgNodes(response.data.data.organization_node);
      console.log(response.data.data.organization_node);
    } catch (error) {
      console.error(error);
    }
  };
  
  const handleOrgNodeChange = (e) => {
    const selectedNodeName = e.target.options[e.target.selectedIndex].text;
    setOrganizationNodeName(selectedNodeName);
  };

  const handleNext = () => {
    if (activeStep === 1 && progress === 0) {
      setProgress(50);
    } else if (activeStep === 2 && progress === 50) {
      setProgress(100);
    }
    setActiveStep(activeStep + 1);
  };

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    
    // Create an object with the form data
    const formData = {
      org_code: organization_code,
      org_node_type: organization_node_id,
      org_node_name: organization_node_name,
      country_id: country_id,
      city_name: city_id,
      parent_org: parent_organization_id,
      cost_center: cost_centre_code,
      is_active: "1",
    };
    console.log(formData);
    axios
    .post(APIURL, {
        query: `
            mutation InsertOrganization($formData: organization_insert_input!) {
                insert_organization(objects: [$formData]) {
                affected_rows
                }
            }
        `,
        variables: {
          formData: formData,
        },
      })
      .then((response) => {
        console.log(response); // Handle the response data as needed
        if (response.data.data && response.data.data.insert_organization.affected_rows > 0) {
            console.log("hello");
            setOrganizationCode("");
            setOrganizationNodeId("");
            setOrganizationNodeName("");
            setCountryId("");
            setCityId("");
            setParentOrganizationId("");
            setCostCentreCode("");
            setCreatedBy("");
            setProgress(0);
            setActiveStep(1);
          toast.success('Organization inserted successfully!');
        } else {
          toast.error('Failed to insert data.');
        }
      })
      .catch((error) => {
        console.error(error); // Handle the error
        toast.error('An error occurred while inserting data.');
      });
    };

  
  return (
    <div className="content">
      <Row className="progress-row">
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Organization</h5>
            </CardHeader>
            <CardBody>
              <Form>
              <Row>
               
               <Col className="px-md-1" md="6">
                 <FormGroup>
                   <Label for="organization_code">Organization Code</Label>
                   <Input
                     placeholder="Organization Code"
                     type="text"
                     value={organization_code}
                     onChange={(e) => setOrganizationCode(e.target.value)}
                   />
                 </FormGroup>
               </Col>
                 <Col className="px-md-1" md="6">
                 <FormGroup>
                   <Label for="orgnodeid">Organization Node ID</Label>
                   <Input
                     type="select"
                     id="orgnodeid"
                     value={organization_node_id}
                     onChange={(e) => {
                         setOrganizationNodeId(e.target.value);
                         handleOrgNodeChange(e); // Call handleOrgNodeChange function here
                       }}    
                   >
                     <option value="">Select Organization Node ID</option>
                     {orgnodes.map((orgnode) => (
                       <option
                         key={orgnode.organization_node_id}
                         value={orgnode.organization_node_id}
                       >
                         {orgnode.organization_node_name}
                       </option>
                     ))}
                   </Input>
                 </FormGroup>
               </Col>
               </Row>
               <Row>
                <Col className="px-md-1" md="6">
                    <FormGroup>
                    <Label for="organization_node_name">Organization Node Name</Label>
                    <Input
                        placeholder="Organization Node Name"
                        type="text"
                        value={organization_node_name}
                        onChange={(e) => setOrganizationNodeName(e.target.value)}
                    />
                    </FormGroup>
                </Col>
                <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="description">Country</Label>
                  <Input
                       type="select"
                       id="orgtype"
                       value={country_id}
                       onChange={(e) => setCountryId(e.target.value)}
                   >
                       <option value="">Select Country</option>
                       <option value="1">India</option>
                       <option value="2">USA</option>
                       <option value="3">England</option>
                       <option value="4">Bangladesh</option>
                       <option value="5">Shrilanka</option>
                      
                       
                   </Input>
                </FormGroup>
              </Col>
              </Row>
              <Row>
               <Col className="px-md-1" md="6">
                   <FormGroup>
                   <Label for="orgtype">City Name</Label>
                   <Input
                       type="text"
                       id="orgtype"
                       value={city_id}
                       onChange={(e) => setCityId(e.target.value)}
                   />
                     
                   </FormGroup>
               </Col>
               <Col className="px-md-1" md="6">
                   <FormGroup>
                   <Label for="orgtype">Parent Organization</Label>
                   <Input
                       type="select"
                       id="orgtype"
                       value={parent_organization_id}
                       onChange={(e) => setParentOrganizationId(e.target.value)}
                   >
                       <option value="">Select Parent ID</option>
                       {orgnames.map((orgname) => (
                       <option key={orgname.org_id} value={orgname.org_id}>
                           {orgname.org_code}
                       </option>
                       ))}
                   </Input>
                   </FormGroup>
               </Col>
              
             </Row>
             <Row>
            
               <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="cost_centre_code">Cost Centre Code</Label>
                  <Input
                    placeholder="Cost Centre Code"
                    type="text"
                    value={cost_centre_code}
                    onChange={(e) => setCostCentreCode(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
              </Form>
            </CardBody>
            <CardFooter>
              <div className="text-right">
                  <Button color="success" onClick={handleFormSubmit}>
                    Submit
                  </Button>
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );

}

export default JobrolemasterAdd;
