import React, { useState } from "react";
import { Card, Row, Col } from "reactstrap";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import JindalInfoProductionVolume from "./InfoProductionVolume";
import ActionReusable from "components/ReusableComponents/ActionsReusable";
import FocusProductionVolume from "./FocusProductionVolume";
import ExtruderPage from "./ExtruderPage";
import JumboPage from "./JumboPage";
import PrimarySliter from "./PrimarySliter";
import SecondarySliter from "./SecondarySliter";
import JindalSliter from "./JindalSliter";

function JindalIFAProductionVolume(props) {
  const [activeTab, setActiveTab] = useState("1");

  const tableData = [
    { id: 1, name: " ", description: " " },
    { id: 2, name: " ", description: "" },
    { id: 3, name: " ", description: "" },
    { id: 4, name: " ", description: "" },
    { id: 5, name: " ", description: "" },
  ];
  const tableData1 = [
    { id: 1, name: " ", description: " " },
    { id: 2, name: " ", description: "" },
    { id: 3, name: " ", description: "" },
    { id: 4, name: " ", description: "" },
    { id: 5, name: " ", description: "" },
  ];

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="3">
            <Card
              className="Workforce-availability"
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                color: "white",
                height: "40px",
                display: "flex",
                alignItems: "left",
                justifyContent: "center",
              }}
            >
              Production Volume
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Nav
              tabs
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                borderBottom: "none",
                marginLeft: "-1rem",
              }}
            >
              <NavItem style={{ flex: 1, padding: "0 10px" }}>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => setActiveTab("1")}
                  style={{
                    width: "100%",
                    textAlign: "center",
                    backgroundColor:
                      activeTab === "1" ? "white" : "transparent",
                    color: activeTab === "1" ? "blue" : "white",
                    borderRadius: "5px",
                    margin: "0px 20px",
                    border: "1px solid #D3D3D3",
                  }}
                >
                  JUMBO
                </NavLink>
              </NavItem>
              <NavItem style={{ flex: 1, padding: "0 10px" }}>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => setActiveTab("2")}
                  style={{
                    width: "100%",
                    textAlign: "center",
                    backgroundColor:
                      activeTab === "2" ? "white" : "transparent",
                    color: activeTab === "2" ? "blue" : "white",
                    borderRadius: "5px",
                    margin: "0px 20px",
                    border: "1px solid #D3D3D3",
                  }}
                >
                  PRIMARY SLITTER 
                </NavLink>
              </NavItem>
              <NavItem style={{ flex: 1, padding: "0 10px" }}>
                <NavLink
                  className={classnames({ active: activeTab === "3" })}
                  onClick={() => setActiveTab("3")}
                  style={{
                    width: "100%",
                    textAlign: "center",
                    backgroundColor:
                      activeTab === "3" ? "white" : "transparent",
                    color: activeTab === "3" ? "blue" : "white",
                    borderRadius: "5px",
                    margin: "0px 20px",
                    border: "1px solid #D3D3D3",
                  }}
                >
                  SECONDARY SLITTER
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
        {activeTab === "1" ? (
          <JindalInfoProductionVolume />
        ) : activeTab === "2" ? (
          <JindalSliter />
        ) : activeTab === "3" ? (
          <SecondarySliter />
        ) : (
          <></>
        )}
        {/* {activeTab === "1" ? (
          <FocusProductionVolume />
        ) : activeTab === "2" ? (
          <JindalInfoProductionVolume />
        ) : activeTab === "3" ? <ActionReusable  tableData={[
          { id: 1, name: "", description: "" },
          { id: 2, name: "", description: "" },
          { id: 3, name: "", description: "" },
          { id: 4, name: "", description: "" },
          { id: 5, name: "", description: "" },
         
        ]}
        tableData1={[
          { id: 1, name: "", description: "" },
          { id: 2, name: "", description: "" },
          { id: 3, name: "", description: "" },
          { id: 4, name: "", description: "" },
          { id: 5, name: "", description: "" },
         
        ]}/> : null} */}
      </div>
    </>
  );
}

export default JindalIFAProductionVolume;
