import React from 'react'
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import AdminLayout from "layouts/Admin/Admin.js";
import RTLLayout from "layouts/RTL/RTL.js";
import Login from "views/Login";
import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";
import { TenantProvider } from './views/TenantContext';

const App = () => {
  return (
    <ThemeContextWrapper>
      <BackgroundColorWrapper>
        <TenantProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/admin/*" element={<AdminLayout />} />
              <Route path="/rtl/*" element={<RTLLayout />} />
              <Route path="/user/*" element={<Login />} />
              <Route
                path="*"
                element={<Navigate to="/user/login" replace />}
              />
            </Routes>
          </BrowserRouter>
        </TenantProvider>
      </BackgroundColorWrapper>
    </ThemeContextWrapper>
  )
}

export default App
