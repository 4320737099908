import React from 'react'
import '../../styles/charts/DayChart.scss'

const DayChart = ({ shiftPercentageList }) => {
    const shiftPercentage = shiftPercentageList;
    // console.log(' new day chart data =========> ', shiftPercentageList)
    return (
        <div className='day_chart'>
            <div className='day_chart_list' style={{justifyContent: 'center', alignItems: 'center'}}>
                <div></div>
                <div className='first_main_text_style'>Day</div>
                <div className='first_main_text_style'>Week</div>
                <div className='first_main_text_style'>Month</div>
                <div className='first_main_text_style'>Year</div>
            </div>
            <div className='day_chart_list'>
                <div className='main_text_style'>Low</div>
                <div className='details_box' style={{ backgroundColor: `${shiftPercentage[0].percentage > 85 ? '#77dd77' : shiftPercentage[0].percentage > 50 ? '#FF964F' : '#FF6961'}` }}>
                    <div className='percentage_style'>{shiftPercentage[0].percentage}</div>
                </div>
                <div className='details_box' style={{ backgroundColor: `${shiftPercentage[1].percentage > 85 ? '#77dd77' : shiftPercentage[1].percentage > 50 ? '#FF964F' : '#FF6961'}` }}>
                    <div className='percentage_style'>{shiftPercentage[1].percentage}</div>
                </div>
                <div className='details_box' style={{ backgroundColor: `${shiftPercentage[2].percentage > 85 ? '#77dd77' : shiftPercentage[2].percentage > 50 ? '#FF964F' : '#FF6961'}` }}>
                    <div className='percentage_style'>{shiftPercentage[2].percentage}</div>
                </div>
                <div className='details_box' style={{ backgroundColor: `${shiftPercentage[3].percentage > 85 ? '#77dd77' : shiftPercentage[3].percentage > 50 ? '#FF964F' : '#FF6961'}` }}>
                    <div className='percentage_style'>{shiftPercentage[3].percentage}</div>
                </div>
            </div>
            <div className='day_chart_list'>
                <div className='main_text_style'>Medium</div>
                <div className='details_box' style={{ backgroundColor: `${shiftPercentage[4].percentage > 85 ? '#77dd77' : shiftPercentage[4].percentage > 50 ? '#FF964F' : '#FF6961'}` }}>
                    <div className='percentage_style'>{shiftPercentage[4].percentage}</div>
                </div>
                <div className='details_box' style={{ backgroundColor: `${shiftPercentage[5].percentage > 85 ? '#77dd77' : shiftPercentage[5].percentage > 50 ? '#FF964F' : '#FF6961'}` }}>
                    <div className='percentage_style'>{shiftPercentage[5].percentage}</div>
                </div>
                <div className='details_box' style={{ backgroundColor: `${shiftPercentage[6].percentage > 85 ? '#77dd77' : shiftPercentage[6].percentage > 50 ? '#FF964F' : '#FF6961'}` }}>
                    <div className='percentage_style'>{shiftPercentage[6].percentage}</div>
                </div>
                <div className='details_box' style={{ backgroundColor: `${shiftPercentage[7].percentage > 85 ? '#77dd77' : shiftPercentage[7].percentage > 50 ? '#FF964F' : '#FF6961'}` }}>
                    <div className='percentage_style'>{shiftPercentage[7].percentage}</div>
                </div>
            </div>
            <div className='day_chart_list'>
                <div className='main_text_style'>High</div>
                <div className='details_box' style={{ backgroundColor: `${shiftPercentage[8].percentage > 85 ? '#77dd77' : shiftPercentage[8].percentage > 50 ? '#FF964F' : '#FF6961'}` }}>
                    <div className='percentage_style'>{shiftPercentage[8].percentage}</div>
                </div>
                <div className='details_box' style={{ backgroundColor: `${shiftPercentage[9].percentage > 85 ? '#77dd77' : shiftPercentage[9].percentage > 50 ? '#FF964F' : '#FF6961'}` }}>
                    <div className='percentage_style'>{shiftPercentage[9].percentage}</div>
                </div>
                <div className='details_box' style={{ backgroundColor: `${shiftPercentage[10].percentage > 85 ? '#77dd77' : shiftPercentage[10].percentage > 50 ? '#FF964F' : '#FF6961'}` }}>
                    <div className='percentage_style'>{shiftPercentage[10].percentage}</div>
                </div>
                <div className='details_box' style={{ backgroundColor: `${shiftPercentage[11].percentage > 85 ? '#77dd77' : shiftPercentage[11].percentage > 50 ? '#FF964F' : '#FF6961'}` }}>
                    <div className='percentage_style'>{shiftPercentage[11].percentage}</div>
                </div>
            </div>
        </div>
    )
}

export default DayChart


// import React from 'react';
// import '../../styles/charts/DayChart.scss';

// const DayChart = ({ shiftPercentageList }) => {
//     return (
//         <div className='day_chart'>
//             <div className='day_chart_list'>
//                 <div></div>
//                 <div className='first_main_text_style'>Daily</div>
//                 <div className='first_main_text_style'>Weekly</div>
//                 <div className='first_main_text_style'>Monthly</div>
//                 <div className='first_main_text_style'>Yearly</div>
//             </div>
//             {[0, 1, 2].map((index) => (
//                 <div className='day_chart_list' key={index}>
//                     <div className='main_text_style'>{['Low', 'Medium', 'High'][index]}</div>
//                     {shiftPercentageList.slice(index * 4, (index + 1) * 4).map((item, i) => (
//                         <div key={i} className='details_box' style={{ backgroundColor: `${item.percentage > 85 ? '#02463E' : item.percentage > 50 ? '#6C4702' : '#E14444'}` }}>
//                             <div>{item.shift}</div>
//                             <div className='percentage_style'>{item.percentage}</div>
//                         </div>
//                     ))}
//                 </div>
//             ))}
//         </div>
//     );
// };

// export default DayChart;
