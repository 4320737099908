import { createContext, useContext, useState } from 'react';

const TenantContext = createContext();

export function TenantProvider({ children }) {
  const [selectedTenantId, setSelectedTenantId] = useState(null);

  return (
    <TenantContext.Provider value={{ selectedTenantId, setSelectedTenantId }}>
      {children}
    </TenantContext.Provider>
  );
}

export function useTenantContext() {
  return useContext(TenantContext);
}
