import React, { useState, useEffect } from "react";
import classNames from "classnames";
import "../../views/style.css";
import "../../views/responsive.css";
import "../../views/progress.css";
import "../../views/gauge.css";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { chartExample2 } from "variables/charts.js";
import "react-circular-progressbar/dist/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import redirectToLoginIfTokenMissing from "views/TokenUtility";
import axios from "axios";
import { APIURL } from "views/Apiurl";
import { Link } from "react-router-dom";
import { QueryApi } from "QueryApi";
import timeLoss from "assets/img/timeLoss.svg";
import PopupWorkForceAvailability from "components/New WorkForce Efficiency/PopupWorkforceAvailability";
import PopupWorkForceProductivity from "components/New WorkForce Efficiency/PopUpWorkForceProductivity";
import WorkforceEfficiencyTable from "components/New WorkForce Efficiency/WorkforceEfficiencyTable";
import PopupChart from "components/New WorkForce Efficiency/PopUpTrendLine";
import TableComponent from "components/New Productivity/Productivity Grids/TableComponent";
function WorkforceHome(props) {
  const [bigChartData, setBgChartData] = React.useState("data1");
  const storedTenantId = sessionStorage.getItem("selectedTenantId");
  const [isOpen, setIsOpen] = useState(false);
  const [PopupWorkForceAvailabilityOpen, setPopupWorkForceAvailabilityOpen] =
    useState(false);
  const [PopupWorkForceProductivityOpen, setPopupWorkForceProductivityOpen] =
    useState(false);
  const [workForceEfficiencyPopUp, setWorkForceEfficiencyPopUp] =
    useState(false);
  const [popUpWorkForceUtilization, setPopUpWorkForceUtilization] =
    useState(false);
  const [popUpOperatorMachineProxy, setPopUpOperatorMachineProxy] =
    useState(false);
  const [popUpCapacityShortfall, setPopUpCapacityShortfall] = useState(false);

  useEffect(() => {
    redirectToLoginIfTokenMissing(); // Call the utility function to validate the token
    // Your component's logic...
  }, []);
  const [org, setorg] = useState("");
  const [orgnames, setOrgnames] = useState([]);

  const [unit, setunit] = useState("");

  const [dept, setdept] = useState("");
  const [depts, setdepts] = useState([]);

  const [plants, setplants] = useState([]);
  const [orgs, setorgs] = useState([]);
  const [otherData, setOtherData] = useState([]);
  const [selectedOrgData, setSelectedOrgData] = useState(null);
  const token = sessionStorage.getItem("token");
  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const tenantId =
    decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
  useEffect(() => {
    fetchplant();
  }, [org]);

  const fetchorg = async (logintenantId) => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
          query MyQuery($logintenantId: Int!) {
            tnt_organization_structure(
              where: {
                parent_organization_id: { _eq: 0 },
                tenant_id: { _eq: $logintenantId }
              }
            ) {
              organization_structure_id
              organization_node_name
            }
          }
        `,
          variables: {
            logintenantId: parseInt(logintenantId),
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      const fetchedOrgs = response.data.data.tnt_organization_structure;
      setorgs(fetchedOrgs);
      const parentIds = fetchedOrgs.map((org) => org.organization_structure_id);

      const childResponse = await axios.post(
        APIURL,
        {
          query: `
          query MyQuery($parentIds: [Int!]) {
            tnt_organization_structure(
              where: {
                parent_organization_id: { _in: $parentIds }
              }
            ) {
              organization_structure_id
              organization_node_name
            }
          }
        `,
          variables: {
            parentIds: parentIds,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      const childOrgs = childResponse.data.data.tnt_organization_structure;
      const updatedOtherData = otherData.map((row) => {
        const matchingOrg = childOrgs.find(
          (org) => org.organization_structure_id === row.parent_organization_id
        );

        if (matchingOrg) {
          return {
            ...row,
            parent_organization_id: matchingOrg.organization_structure_id,
          };
        }

        return row;
      });

      setOtherData(childOrgs);
    } catch (error) {
      // console.error(error);
    }
  };
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["sub"];
    let logintenantId = null;

    if (roleId === 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
    }

    fetchorg(logintenantId);
  }, []);
  const fetchplant = async () => {
    if (org) {
      // Only fetch if org is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
            query MyQuery($p: Int!) {
              tnt_organization_structure(where: {parent_organization_id: {_eq: $p}}) {
                organization_structure_id
                organization_node_name
              }
            }
          `,
            variables: {
              p: org,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        setplants(response.data.data.tnt_organization_structure);
      } catch (error) {
        // console.error(error);
      }
    }
  };
  useEffect(() => {
    fetchdept();
  }, [unit]);
  const fetchdept = async () => {
    if (unit) {
      // Only fetch if org is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
            query MyQuery($d: Int!) {
              tnt_organization_structure(where: {parent_organization_id: {_eq: $d}}) {
                organization_structure_id
                organization_node_name
              }
            }
          `,
            variables: {
              d: unit,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        setdepts(response.data.data.tnt_organization_structure);
      } catch (error) {
        // console.error(error);
      }
    }
  };
  const handleOrgSelect = async (selectedOrgId) => {
    if (selectedOrgId) {
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
            query GetCostCenter($orgId: Int!) {
              tnt_organization_structure(where: { organization_structure_id: { _eq: $orgId } }) {
                cost_centre_code
              }
            }
          `,
            variables: {
              orgId: selectedOrgId,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );

        if (
          response.data &&
          response.data.data &&
          response.data.data.tnt_organization_structure &&
          response.data.data.tnt_organization_structure.length > 0
        ) {
          setSelectedOrgData(response.data.data.tnt_organization_structure[0]);
        } else {
          setSelectedOrgData(null);
        }
      } catch (error) {
        // console.error("Error fetching organization data:", error);
      }
    } else {
      setSelectedOrgData(null);
    }

    setorg(selectedOrgId);
  };

  const darkColor = "rgba(0, 0, 0, 0.8)";
  const lightColor = "rgba(0, 0, 0, 0.1)";

  const gradientSteps = 10; // Adjust the number of steps for the shading effect
  const colorStops = [];
  for (let i = 0; i <= gradientSteps; i++) {
    const intensity = i / gradientSteps;
    const r = Math.round(
      intensity * parseInt(lightColor.substring(4, 7)) +
        (1 - intensity) * parseInt(darkColor.substring(4, 7))
    );
    const g = Math.round(
      intensity * parseInt(lightColor.substring(9, 12)) +
        (1 - intensity) * parseInt(darkColor.substring(9, 12))
    );
    const b = Math.round(
      intensity * parseInt(lightColor.substring(14, 17)) +
        (1 - intensity) * parseInt(darkColor.substring(14, 17))
    );
    const colorStop = `rgb(${r}, ${g}, ${b})`;
    colorStops.push(colorStop);
  }
  const gradientColor = `linear-gradient(to right, ${colorStops.join(", ")})`;

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const currDay = day < 10 ? `0${day}` : day;
  const currMonth = month < 10 ? `0${month}` : month;
  let currentDate = JSON.stringify(`${year}-${currMonth}-${currDay}`);

  const dateData = new Date();
  dateData.setDate(dateData.getDate() - 7);
  var dayDataValue = dateData.getDate();
  var monthDataValue = dateData.getMonth() + 1;
  var yearDataValue = dateData.getFullYear();
  var formattedDay = dayDataValue < 10 ? `0${dayDataValue}` : dayDataValue;
  var formattedMonth =
    monthDataValue < 10 ? `0${monthDataValue}` : monthDataValue;
  var weekDataDate = JSON.stringify(
    `${yearDataValue}-${formattedMonth}-${formattedDay}`
  );

  const previousDate = new Date();
  previousDate.setDate(1);
  const previousDay = previousDate.getDate();
  const previousMonth = previousDate.getMonth() + 1;
  const previousYear = previousDate.getFullYear();
  const formattedPreviousDay =
    previousDay < 10 ? `0${previousDay}` : previousDay;
  const formattedPreviousMonth =
    previousMonth < 10 ? `0${previousMonth}` : previousMonth;
  const previousMonthDate = JSON.stringify(
    `${previousYear}-${formattedPreviousMonth}-${formattedPreviousDay}`
  );

  const yearCurrentDate = new Date();
  yearCurrentDate.setDate(1);
  yearCurrentDate.getMonth(1);
  const yearDay = yearCurrentDate.getDate();
  const yearMonth = yearCurrentDate.getMonth();
  const yearYear = yearCurrentDate.getFullYear();
  const formattedYearDay = yearDay < 10 ? `0${yearDay}` : yearDay;
  const formattedYearMonth = yearMonth < 10 ? `0${yearMonth}` : yearMonth;
  const yearPreviousMonthDate = JSON.stringify(
    `${yearYear}-${formattedYearMonth}-${formattedYearDay}`
  );

  ////// Workforce Availability //////////////////////////////////////////////////////////////////////////

  const [WorkforceAvailabilityShiftData, setWorkforceAvailabilityShiftData] =
    useState([]);
  useEffect(() => {
    WorkforceAvailabilityShift();
  }, []);
  const WorkforceAvailabilityShift = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = 15, $end_date: date = ${currentDate}) {
                wdm_workforce_availability_shiftwise(where: {date: {_gte: $start_date, _lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                    workforce_availability_per
                    shift
                    timestamp
                  } 
              }
        `);
      if (
        data === undefined ||
        data.wdm_workforce_availability_shiftwise === null
      ) {
        setWorkforceAvailabilityShiftData([
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ]);
      } else {
        const WorkforceAvailabilityShift = [
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ];

        data.wdm_workforce_availability_shiftwise.map((data) => {
          var shiftDate = new Date(data.timestamp);
          var HoursshiftDate = shiftDate.getHours();
          if (HoursshiftDate >= 8 && HoursshiftDate <= 16) {
            WorkforceAvailabilityShift[0] = {
              name: "Shift 1",
              dataValue: data.workforce_availability_per,
            };
          } else if (HoursshiftDate >= 16 && HoursshiftDate <= 24) {
            WorkforceAvailabilityShift[1] = {
              name: "Shift 2",
              dataValue: data.workforce_availability_per,
            };
          } else if (HoursshiftDate >= 0 && HoursshiftDate <= 8) {
            WorkforceAvailabilityShift[2] = {
              name: "Shift 3",
              dataValue: data.workforce_availability_per,
            };
          }
        });
        setWorkforceAvailabilityShiftData(WorkforceAvailabilityShift);
      }
    } catch (error) {
      setWorkforceAvailabilityShiftData([
        {
          name: "shift 1",
          dataValue: "0",
        },
        {
          name: "shift 2",
          dataValue: "0",
        },
        {
          name: "shift 3",
          dataValue: "0",
        },
      ]);
    }
  };

  ///////////////////////

  const [
    xAxisDataValueOWorkforceAvailability,
    setXAxisDataValueOWorkforceAvailability,
  ] = useState([]);
  const [plotPointOWorkforceAvailability, setPlotPointOWorkforceAvailability] =
    useState([]);
  const datasetOWorkforceAvailability = [
    {
      label: "Workforce Availability",
      data: plotPointOWorkforceAvailability,
      borderColor: "#1F8EF1",
    },
  ];
  useEffect(() => {
    GraphDataListOWorkforceAvailability();
  }, []);
  const GraphDataListOWorkforceAvailability = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
                wdm_workforce_availability(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                    workforce_availability_per
                    timestamp
                }
            }
        `);
      const plotAllDataOWorkforceAvailability =
        data.wdm_workforce_availability.map((data) => {
          const objData = {
            point: data.workforce_availability_per,
            Xaxis: data.timestamp,
          };
          return objData;
        });

      const plotPointDataOWorkforceAvailability =
        plotAllDataOWorkforceAvailability.map((data) => data.point);
      setPlotPointOWorkforceAvailability(plotPointDataOWorkforceAvailability);

      const xAxisPointDataOWorkforceAvailability =
        plotAllDataOWorkforceAvailability.map((data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
          var m = "0" + d.getMinutes();
          var ampm = h >= 12 ? "PM" : "AM";
          h = h % 12;
          h = h ? h : 12;
          var fullTime = h + ":" + m.substr(-2) + " " + ampm;
          return fullTime;
        });
      setXAxisDataValueOWorkforceAvailability(
        xAxisPointDataOWorkforceAvailability
      );
    } catch (error) {
      // console.log(error);
    }
  };

  const [WorkforceAvailability, setWorkforceAvailability] = useState([
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
  ]);

  useEffect(() => {
    DailyDataFun();
  }, []);
  const DailyDataFun = async () => {
    try {
      const data = await QueryApi(`
              query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
                wdm_workforce_availability_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                  aggregate {
                    avg {
                      workforce_availability_per
                    }
                    max {
                      workforce_availability_per
                    }
                    min {
                      workforce_availability_per
                    }
                  }
                }
              }
           `);
      if (data !== undefined) {
        setWorkforceAvailability((prevData) => {
          const newarr = [...prevData];
          newarr[0] = {
            percentage:
              data.wdm_workforce_availability_daily_aggregate.aggregate.min
                .workforce_availability_per !== null
                ? data.wdm_workforce_availability_daily_aggregate.aggregate.min.workforce_availability_per.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[4] = {
            percentage:
              data.wdm_workforce_availability_daily_aggregate.aggregate.avg
                .workforce_availability_per !== null
                ? data.wdm_workforce_availability_daily_aggregate.aggregate.avg.workforce_availability_per.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[8] = {
            percentage:
              data.wdm_workforce_availability_daily_aggregate.aggregate.max
                .workforce_availability_per !== null
                ? data.wdm_workforce_availability_daily_aggregate.aggregate.max.workforce_availability_per.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setWorkforceAvailability((prevData) => {
        const newarr = [...prevData];
        newarr[0] = { percentage: "No Data" };
        newarr[4] = { percentage: "No Data" };
        newarr[8] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    WeeklyDataFun();
  }, []);
  const WeeklyDataFun = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${weekDataDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
                wdm_workforce_availability_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}){
                aggregate {
                    avg {
                        workforce_availability_per
                    }
                    max {
                        workforce_availability_per
                    }
                    min {
                        workforce_availability_per
                    }
                    }
                }
              }    
            `);
      if (data !== undefined) {
        setWorkforceAvailability((prevData) => {
          const newarr = [...prevData];
          newarr[1] = {
            percentage:
              data.wdm_workforce_availability_daily_aggregate.aggregate.min
                .workforce_availability_per !== null
                ? data.wdm_workforce_availability_daily_aggregate.aggregate.min.workforce_availability_per.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[5] = {
            percentage:
              data.wdm_workforce_availability_daily_aggregate.aggregate.avg
                .workforce_availability_per !== null
                ? data.wdm_workforce_availability_daily_aggregate.aggregate.avg.workforce_availability_per.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[9] = {
            percentage:
              data.wdm_workforce_availability_daily_aggregate.aggregate.max
                .workforce_availability_per !== null
                ? data.wdm_workforce_availability_daily_aggregate.aggregate.max.workforce_availability_per.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setWorkforceAvailability((prevData) => {
        const newarr = [...prevData];
        newarr[1] = { percentage: "No Data" };
        newarr[5] = { percentage: "No Data" };
        newarr[9] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    MonthlyDataFun();
  }, []);
  const MonthlyDataFun = async () => {
    try {
      //     const data = await QueryApi(`
      //     query MyQuery($start_date: date = ${previousMonthDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem('tenantid')}) {
      //         wdm_workforce_availability_monthly_aggregate(where: {start_date: {_eq: $start_date}, end_date: {_eq: $end_date}, tenantid: {_eq: $tenantid}}) {
      //             aggregate {
      //                 avg {
      //                   workforce_availability_per
      //                 }
      //                 max {
      //                   workforce_availability_per
      //                 }
      //                 min {
      //                   workforce_availability_per
      //                 }
      //               }
      //             }
      //       }
      // `);
      //     if (data !== undefined) {
      //         setWorkforceAvailability((prevData) => {
      //             const newarr = [...prevData];
      //             newarr[2] = { percentage: data.wdm_workforce_availability_monthly_aggregate.aggregate.min.workforce_availability_per !== null ? data.wdm_workforce_availability_monthly_aggregate.aggregate.min.workforce_availability_per.toFixed(1) : "No Data" };
      //             newarr[6] = { percentage: data.wdm_workforce_availability_monthly_aggregate.aggregate.avg.workforce_availability_per !== null ? data.wdm_workforce_availability_monthly_aggregate.aggregate.avg.workforce_availability_per.toFixed(1) : "No Data" };
      //             newarr[10] = { percentage: data.wdm_workforce_availability_monthly_aggregate.aggregate.max.workforce_availability_per !== null ? data.wdm_workforce_availability_monthly_aggregate.aggregate.max.workforce_availability_per.toFixed(1) : "No Data" };
      //             return newarr
      //         })
      //     }

      const data = await QueryApi(`
        query MyQuery($start_date: date = ${previousMonthDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
            wdm_workforce_availability_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}){
            aggregate {
                avg {
                    workforce_availability_per
                }
                max {
                    workforce_availability_per
                }
                min {
                    workforce_availability_per
                }
                }
            }
          }    
        `);
      if (data !== undefined) {
        setWorkforceAvailability((prevData) => {
          const newarr = [...prevData];
          newarr[2] = {
            percentage:
              data.wdm_workforce_availability_daily_aggregate.aggregate.min
                .workforce_availability_per !== null
                ? data.wdm_workforce_availability_daily_aggregate.aggregate.min.workforce_availability_per.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[6] = {
            percentage:
              data.wdm_workforce_availability_daily_aggregate.aggregate.avg
                .workforce_availability_per !== null
                ? data.wdm_workforce_availability_daily_aggregate.aggregate.avg.workforce_availability_per.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[10] = {
            percentage:
              data.wdm_workforce_availability_daily_aggregate.aggregate.max
                .workforce_availability_per !== null
                ? data.wdm_workforce_availability_daily_aggregate.aggregate.max.workforce_availability_per.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setWorkforceAvailability((prevData) => {
        const newarr = [...prevData];
        newarr[2] = { percentage: "No Data" };
        newarr[6] = { percentage: "No Data" };
        newarr[10] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    YearlyDataFun();
  }, []);
  const YearlyDataFun = async () => {
    try {
      //     const data = await QueryApi(`
      //     query MyQuery($start_date: date = ${yearPreviousMonthDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem('tenantid')}) {
      //         wdm_workforce_availability_yearly_aggregate(where: {start_date: {_eq: $start_date}, end_date: {_eq: $end_date}, tenantid: {_eq: $tenantid}}) {
      //             aggregate {
      //                 avg {
      //                   workforce_availability_per
      //                 }
      //                 max {
      //                   workforce_availability_per
      //                 }
      //                 min {
      //                   workforce_availability_per
      //                 }
      //               }
      //             }
      //       }
      // `);
      //     if (data !== undefined) {
      //         setWorkforceAvailability((prevData) => {
      //             const newarr = [...prevData];
      //             newarr[3] = { percentage: data.wdm_workforce_availability_yearly_aggregate.aggregate.min.workforce_availability_per !== null ? data.wdm_workforce_availability_yearly_aggregate.aggregate.min.workforce_availability_per.toFixed(1) : "No Data" };
      //             newarr[7] = { percentage: data.wdm_workforce_availability_yearly_aggregate.aggregate.avg.workforce_availability_per !== null ? data.wdm_workforce_availability_yearly_aggregate.aggregate.avg.workforce_availability_per.toFixed(1) : "No Data" };
      //             newarr[11] = { percentage: data.wdm_workforce_availability_yearly_aggregate.aggregate.max.workforce_availability_per !== null ? data.wdm_workforce_availability_yearly_aggregate.aggregate.max.workforce_availability_per.toFixed(1) : "No Data" };
      //             return newarr
      //         })
      //     }

      const data = await QueryApi(`
        query MyQuery($start_date: date = ${yearPreviousMonthDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
            wdm_workforce_availability_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}){
            aggregate {
                avg {
                    workforce_availability_per
                }
                max {
                    workforce_availability_per
                }
                min {
                    workforce_availability_per
                }
                }
            }
          }    
        `);
      if (data !== undefined) {
        setWorkforceAvailability((prevData) => {
          const newarr = [...prevData];
          newarr[3] = {
            percentage:
              data.wdm_workforce_availability_daily_aggregate.aggregate.min
                .workforce_availability_per !== null
                ? data.wdm_workforce_availability_daily_aggregate.aggregate.min.workforce_availability_per.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[7] = {
            percentage:
              data.wdm_workforce_availability_daily_aggregate.aggregate.avg
                .workforce_availability_per !== null
                ? data.wdm_workforce_availability_daily_aggregate.aggregate.avg.workforce_availability_per.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[11] = {
            percentage:
              data.wdm_workforce_availability_daily_aggregate.aggregate.max
                .workforce_availability_per !== null
                ? data.wdm_workforce_availability_daily_aggregate.aggregate.max.workforce_availability_per.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setWorkforceAvailability((prevData) => {
        const newarr = [...prevData];
        newarr[3] = { percentage: "No Data" };
        newarr[7] = { percentage: "No Data" };
        newarr[11] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  //////// Workforce Efficiency //////////////////////////////////////////////////////////////////////////

  const [WorkforceEfficiencyShiftData, setWorkforceEfficiencyShiftData] =
    useState([]);
  useEffect(() => {
    WorkforceEfficiencyShift();
  }, []);
  const WorkforceEfficiencyShift = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = 15, $end_date: date = ${currentDate}) {
                wfl0_workforce_efficiency_shiftwise(where: {date: {_gte: $start_date, _lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                    overall_labor_effectiveness
                    shift
                    timestamp
                  } 
              }
        `);
      if (
        data === undefined ||
        data.wfl0_workforce_efficiency_shiftwise === null
      ) {
        setWorkforceEfficiencyShiftData([
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ]);
      } else {
        const WorkforceEfficiencyShift = [
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ];

        data.wfl0_workforce_efficiency_shiftwise.map((data) => {
          var shiftDate = new Date(data.timestamp);
          var HoursshiftDate = shiftDate.getHours();
          if (HoursshiftDate >= 8 && HoursshiftDate <= 16) {
            WorkforceEfficiencyShift[0] = {
              name: "Shift 1",
              dataValue: data.overall_labor_effectiveness,
            };
          } else if (HoursshiftDate >= 16 && HoursshiftDate <= 24) {
            WorkforceEfficiencyShift[1] = {
              name: "Shift 2",
              dataValue: data.overall_labor_effectiveness,
            };
          } else if (HoursshiftDate >= 0 && HoursshiftDate <= 8) {
            WorkforceEfficiencyShift[2] = {
              name: "Shift 3",
              dataValue: data.overall_labor_effectiveness,
            };
          }
        });
        setWorkforceEfficiencyShiftData(WorkforceEfficiencyShift);
      }
    } catch (error) {
      setWorkforceEfficiencyShiftData([
        {
          name: "shift 1",
          dataValue: "0",
        },
        {
          name: "shift 2",
          dataValue: "0",
        },
        {
          name: "shift 3",
          dataValue: "0",
        },
      ]);
    }
  };

  const [WorkforceEfficiency, setWorkforceEfficiency] = useState([
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
  ]);

  useEffect(() => {
    WorkforceEfficiencyDailyDataFun();
  }, []);
  const WorkforceEfficiencyDailyDataFun = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
            wfl0_workforce_efficiency_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                avg {
                  overall_labor_effectiveness
                }
                max {
                  overall_labor_effectiveness
                }
                min {
                  overall_labor_effectiveness
                }
              }
            }
          }
       `);
      if (data !== undefined) {
        setWorkforceEfficiency((prevData) => {
          const newarr = [...prevData];
          newarr[0] = {
            percentage:
              data.wfl0_workforce_efficiency_daily_aggregate.aggregate.min
                .overall_labor_effectiveness !== null
                ? data.wfl0_workforce_efficiency_daily_aggregate.aggregate.min.overall_labor_effectiveness.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[4] = {
            percentage:
              data.wfl0_workforce_efficiency_daily_aggregate.aggregate.avg
                .overall_labor_effectiveness !== null
                ? data.wfl0_workforce_efficiency_daily_aggregate.aggregate.avg.overall_labor_effectiveness.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[8] = {
            percentage:
              data.wfl0_workforce_efficiency_daily_aggregate.aggregate.max
                .overall_labor_effectiveness !== null
                ? data.wfl0_workforce_efficiency_daily_aggregate.aggregate.max.overall_labor_effectiveness.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setWorkforceEfficiency((prevData) => {
        const newarr = [...prevData];
        newarr[0] = { percentage: "No Data" };
        newarr[4] = { percentage: "No Data" };
        newarr[8] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    WorkforceEfficiencyWeeklyDataFun();
  }, []);
  const WorkforceEfficiencyWeeklyDataFun = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${weekDataDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
            wfl0_workforce_efficiency_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}){
            aggregate {
                avg {
                    overall_labor_effectiveness
                }
                max {
                    overall_labor_effectiveness
                }
                min {
                    overall_labor_effectiveness
                }
                }
            }
          }  
       `);
      if (data !== undefined) {
        setWorkforceEfficiency((prevData) => {
          const newarr = [...prevData];
          newarr[1] = {
            percentage:
              data.wfl0_workforce_efficiency_daily_aggregate.aggregate.min
                .overall_labor_effectiveness !== null
                ? data.wfl0_workforce_efficiency_daily_aggregate.aggregate.min.overall_labor_effectiveness.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[5] = {
            percentage:
              data.wfl0_workforce_efficiency_daily_aggregate.aggregate.avg
                .overall_labor_effectiveness !== null
                ? data.wfl0_workforce_efficiency_daily_aggregate.aggregate.avg.overall_labor_effectiveness.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[9] = {
            percentage:
              data.wfl0_workforce_efficiency_daily_aggregate.aggregate.max
                .overall_labor_effectiveness !== null
                ? data.wfl0_workforce_efficiency_daily_aggregate.aggregate.max.overall_labor_effectiveness.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setWorkforceEfficiency((prevData) => {
        const newarr = [...prevData];
        newarr[1] = { percentage: "No Data" };
        newarr[5] = { percentage: "No Data" };
        newarr[9] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    WorkforceEfficiencyMonthlyDataFun();
  }, []);
  const WorkforceEfficiencyMonthlyDataFun = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${previousMonthDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
            wfl0_workforce_efficiency_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}){
            aggregate {
                avg {
                    overall_labor_effectiveness
                }
                max {
                    overall_labor_effectiveness
                }
                min {
                    overall_labor_effectiveness
                }
                }
            }
          }
       `);
      if (data !== undefined) {
        setWorkforceEfficiency((prevData) => {
          const newarr = [...prevData];
          newarr[2] = {
            percentage:
              data.wfl0_workforce_efficiency_daily_aggregate.aggregate.min
                .overall_labor_effectiveness !== null
                ? data.wfl0_workforce_efficiency_daily_aggregate.aggregate.min.overall_labor_effectiveness.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[6] = {
            percentage:
              data.wfl0_workforce_efficiency_daily_aggregate.aggregate.avg
                .overall_labor_effectiveness !== null
                ? data.wfl0_workforce_efficiency_daily_aggregate.aggregate.avg.overall_labor_effectiveness.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[10] = {
            percentage:
              data.wfl0_workforce_efficiency_daily_aggregate.aggregate.max
                .overall_labor_effectiveness !== null
                ? data.wfl0_workforce_efficiency_daily_aggregate.aggregate.max.overall_labor_effectiveness.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setWorkforceEfficiency((prevData) => {
        const newarr = [...prevData];
        newarr[2] = { percentage: "No Data" };
        newarr[6] = { percentage: "No Data" };
        newarr[10] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    WorkforceEfficiencyYearlyDataFun();
  }, []);
  const WorkforceEfficiencyYearlyDataFun = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($start_date: date = ${yearPreviousMonthDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
            wfl0_workforce_efficiency_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}){
            aggregate {
                avg {
                    overall_labor_effectiveness
                }
                max {
                    overall_labor_effectiveness
                }
                min {
                    overall_labor_effectiveness
                }
                }
            }
          }
       `);
      if (data !== undefined) {
        setWorkforceEfficiency((prevData) => {
          const newarr = [...prevData];
          newarr[3] = {
            percentage:
              data.wfl0_workforce_efficiency_daily_aggregate.aggregate.min
                .overall_labor_effectiveness !== null
                ? data.wfl0_workforce_efficiency_daily_aggregate.aggregate.min.overall_labor_effectiveness.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[7] = {
            percentage:
              data.wfl0_workforce_efficiency_daily_aggregate.aggregate.avg
                .overall_labor_effectiveness !== null
                ? data.wfl0_workforce_efficiency_daily_aggregate.aggregate.avg.overall_labor_effectiveness.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[11] = {
            percentage:
              data.wfl0_workforce_efficiency_daily_aggregate.aggregate.max
                .overall_labor_effectiveness !== null
                ? data.wfl0_workforce_efficiency_daily_aggregate.aggregate.max.overall_labor_effectiveness.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setWorkforceEfficiency((prevData) => {
        const newarr = [...prevData];
        newarr[3] = { percentage: "No Data" };
        newarr[7] = { percentage: "No Data" };
        newarr[11] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  ///////////// Workforce Utilization //////////////////////////////////////////////////////////////////////////

  const [WorkforceUtilizationShiftData, setWorkforceUtilizationShiftData] =
    useState([]);
  useEffect(() => {
    WorkforceUtilizationShift();
  }, []);
  const WorkforceUtilizationShift = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = 15, $end_date: date = ${currentDate}) {
                wfl0_workforce_utilization_shiftwise(where: {date: {_gte: $start_date, _lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                    workforce_utilization_per
                    timestamp   
                 } 
            }
        `);
      if (
        data === undefined ||
        data.wfl0_workforce_utilization_shiftwise === null
      ) {
        setWorkforceUtilizationShiftData([
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ]);
      } else {
        const WorkforceUtilizationShift = [
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ];

        data.wfl0_workforce_utilization_shiftwise.map((data) => {
          var shiftDate = new Date(data.timestamp);
          var HoursshiftDate = shiftDate.getHours();
          if (HoursshiftDate >= 8 && HoursshiftDate <= 16) {
            WorkforceUtilizationShift[0] = {
              name: "Shift 1",
              dataValue: data.workforce_utilization_per,
            };
          } else if (HoursshiftDate >= 16 && HoursshiftDate <= 24) {
            WorkforceUtilizationShift[1] = {
              name: "Shift 2",
              dataValue: data.workforce_utilization_per,
            };
          } else if (HoursshiftDate >= 0 && HoursshiftDate <= 8) {
            WorkforceUtilizationShift[2] = {
              name: "Shift 3",
              dataValue: data.workforce_utilization_per,
            };
          }
        });
        setWorkforceUtilizationShiftData(WorkforceUtilizationShift);
      }
    } catch (error) {
      setWorkforceUtilizationShiftData([
        {
          name: "shift 1",
          dataValue: "0",
        },
        {
          name: "shift 2",
          dataValue: "0",
        },
        {
          name: "shift 3",
          dataValue: "0",
        },
      ]);
    }
  };

  const [WorkforceUtilization, setWorkforceUtilization] = useState([
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
  ]);

  useEffect(() => {
    WorkforceUtilizationDailyDataFun();
  }, []);
  const WorkforceUtilizationDailyDataFun = async () => {
    try {
      const data = await QueryApi(`
                query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
                    wfl0_workforce_utilization_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                    aggregate {
                    avg {
                        workforce_utilization_per
                    }
                    max {
                        workforce_utilization_per
                    }
                    min {
                        workforce_utilization_per
                    }
                    }
                }
                }
            `);
      if (data !== undefined) {
        setWorkforceUtilization((prevData) => {
          const newarr = [...prevData];
          newarr[0] = {
            percentage:
              data.wfl0_workforce_utilization_daily_aggregate.aggregate.min
                .workforce_utilization_per !== null
                ? data.wfl0_workforce_utilization_daily_aggregate.aggregate.min.workforce_utilization_per.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[4] = {
            percentage:
              data.wfl0_workforce_utilization_daily_aggregate.aggregate.avg
                .workforce_utilization_per !== null
                ? data.wfl0_workforce_utilization_daily_aggregate.aggregate.avg.workforce_utilization_per.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[8] = {
            percentage:
              data.wfl0_workforce_utilization_daily_aggregate.aggregate.max
                .workforce_utilization_per !== null
                ? data.wfl0_workforce_utilization_daily_aggregate.aggregate.max.workforce_utilization_per.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setWorkforceUtilization((prevData) => {
        const newarr = [...prevData];
        newarr[0] = { percentage: "No Data" };
        newarr[4] = { percentage: "No Data" };
        newarr[8] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    WorkforceUtilizationWeeklyDataFun();
  }, []);
  const WorkforceUtilizationWeeklyDataFun = async () => {
    try {
      //     const data = await QueryApi(`
      //     query MyQuery($start_date: date = ${weekDataDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem('tenantid')}) {
      //         wfl0_workforce_utilization_weekly(where: {start_date: {_eq: $start_date}, end_date: {_eq: $end_date}, tenantid: {_eq: $tenantid}}) {
      //             workforce_utilization_per
      //         }
      //     }
      //   `);
      //     const WeeklySort = data.wfl0_workforce_utilization_weekly.sort((a, b) => a.workforce_utilization_per - b.workforce_utilization_per);
      //     const midValue = (WeeklySort[0].workforce_utilization_per.toFixed(1) + WeeklySort[WeeklySort.length - 1].workforce_utilization_per.toFixed(1)) / 2;
      //     setWorkforceUtilization((prevData) => {
      //         const newarr = [...prevData];
      //         newarr[1] = { percentage: WeeklySort[0].workforce_utilization_per.toFixed(1) };
      //         newarr[5] = { percentage: midValue.toFixed(1) };
      //         newarr[8] = { percentage: WeeklySort[WeeklySort.length - 1].workforce_utilization_per.toFixed(1) };
      //         return newarr
      //     })

      const data = await QueryApi(`
        query MyQuery($start_date: date = ${weekDataDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
            wfl0_workforce_utilization_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}){
          aggregate {
              avg {
                  workforce_utilization_per
              }
              max {
                  workforce_utilization_per
              }
              min {
                  workforce_utilization_per
              }
              }
          }
        }  
     `);
      if (data !== undefined) {
        setWorkforceUtilization((prevData) => {
          const newarr = [...prevData];
          newarr[1] = {
            percentage:
              data.wfl0_workforce_utilization_daily_aggregate.aggregate.min
                .workforce_utilization_per !== null
                ? data.wfl0_workforce_utilization_daily_aggregate.aggregate.min.workforce_utilization_per.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[5] = {
            percentage:
              data.wfl0_workforce_utilization_daily_aggregate.aggregate.avg
                .workforce_utilization_per !== null
                ? data.wfl0_workforce_utilization_daily_aggregate.aggregate.avg.workforce_utilization_per.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[9] = {
            percentage:
              data.wfl0_workforce_utilization_daily_aggregate.aggregate.max
                .workforce_utilization_per !== null
                ? data.wfl0_workforce_utilization_daily_aggregate.aggregate.max.workforce_utilization_per.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setWorkforceUtilization((prevData) => {
        const newarr = [...prevData];
        newarr[1] = { percentage: "No Data" };
        newarr[5] = { percentage: "No Data" };
        newarr[9] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    WorkforceUtilizationMonthlyDataFun();
  }, []);
  const WorkforceUtilizationMonthlyDataFun = async () => {
    try {
      //     const data = await QueryApi(`
      //     query MyQuery($start_date: date = ${previousMonthDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem('tenantid')}) {
      //         wfl0_workforce_utilization_monthly(where: {start_date: {_eq: $start_date}, end_date: {_eq: $end_date}, tenantid: {_eq: $tenantid}}) {
      //             workforce_utilization_per
      //         }
      //     }
      //   `);
      //     const MonthlySort = data.wfl0_workforce_utilization_monthly.sort((a, b) => a.workforce_utilization_per - b.workforce_utilization_per);
      //     const midValue = (MonthlySort[0].workforce_utilization_per.toFixed(1) + MonthlySort[MonthlySort.length - 1].workforce_utilization_per.toFixed(1)) / 2;
      //     setWorkforceUtilization((prevData) => {
      //         const newarr = [...prevData];
      //         newarr[2] = { percentage: MonthlySort[0].workforce_utilization_per.toFixed(1) };
      //         newarr[6] = { percentage: midValue.toFixed(1) };
      //         newarr[10] = { percentage: MonthlySort[MonthlySort.length - 1].workforce_utilization_per.toFixed(1) };
      //         return newarr
      //     })
      const data = await QueryApi(`
        query MyQuery($start_date: date = ${previousMonthDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
            wfl0_workforce_utilization_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}){
          aggregate {
              avg {
                  workforce_utilization_per
              }
              max {
                  workforce_utilization_per
              }
              min {
                  workforce_utilization_per
              }
              }
          }
        }
     `);
      if (data !== undefined) {
        setWorkforceUtilization((prevData) => {
          const newarr = [...prevData];
          newarr[2] = {
            percentage:
              data.wfl0_workforce_utilization_daily_aggregate.aggregate.min
                .workforce_utilization_per !== null
                ? data.wfl0_workforce_utilization_daily_aggregate.aggregate.min.workforce_utilization_per.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[6] = {
            percentage:
              data.wfl0_workforce_utilization_daily_aggregate.aggregate.avg
                .workforce_utilization_per !== null
                ? data.wfl0_workforce_utilization_daily_aggregate.aggregate.avg.workforce_utilization_per.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[10] = {
            percentage:
              data.wfl0_workforce_utilization_daily_aggregate.aggregate.max
                .workforce_utilization_per !== null
                ? data.wfl0_workforce_utilization_daily_aggregate.aggregate.max.workforce_utilization_per.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setWorkforceUtilization((prevData) => {
        const newarr = [...prevData];
        newarr[2] = { percentage: "No Data" };
        newarr[6] = { percentage: "No Data" };
        newarr[10] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    WorkforceUtilizationYearlyDataFun();
  }, []);
  const WorkforceUtilizationYearlyDataFun = async () => {
    try {
      //     const data = await QueryApi(`
      //     query MyQuery($start_date: date = ${yearPreviousMonthDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem('tenantid')}) {
      //         wfl0_workforce_utilization_yearly(where: {start_date: {_eq: $start_date}, end_date: {_eq: $end_date}, tenantid: {_eq: $tenantid}}) {
      //             workforce_utilization_per
      //         }
      //     }
      //   `);
      //     const YearlySort = data.wfl0_workforce_utilization_yearly.sort((a, b) => a.workforce_utilization_per - b.workforce_utilization_per);
      //     const midValue = (YearlySort[0].workforce_utilization_per.toFixed(1) + YearlySort[YearlySort.length - 1].workforce_utilization_per.toFixed(1)) / 2;
      //     setWorkforceUtilization((prevData) => {
      //         const newarr = [...prevData];
      //         newarr[3] = { percentage: YearlySort[0].workforce_utilization_per.toFixed(1) };
      //         newarr[7] = { percentage: midValue.toFixed(1) };
      //         newarr[11] = { percentage: YearlySort[YearlySort.length - 1].workforce_utilization_per.toFixed(1) };
      //         return newarr
      //     })

      const data = await QueryApi(`
        query MyQuery($start_date: date = ${yearPreviousMonthDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
            wfl0_workforce_utilization_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}){
          aggregate {
              avg {
                  workforce_utilization_per
              }
              max {
                  workforce_utilization_per
              }
              min {
                  workforce_utilization_per
              }
              }
          }
        }
     `);
      if (data !== undefined) {
        setWorkforceUtilization((prevData) => {
          const newarr = [...prevData];
          newarr[3] = {
            percentage:
              data.wfl0_workforce_utilization_daily_aggregate.aggregate.min
                .workforce_utilization_per !== null
                ? data.wfl0_workforce_utilization_daily_aggregate.aggregate.min.workforce_utilization_per.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[7] = {
            percentage:
              data.wfl0_workforce_utilization_daily_aggregate.aggregate.avg
                .workforce_utilization_per !== null
                ? data.wfl0_workforce_utilization_daily_aggregate.aggregate.avg.workforce_utilization_per.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[11] = {
            percentage:
              data.wfl0_workforce_utilization_daily_aggregate.aggregate.max
                .workforce_utilization_per !== null
                ? data.wfl0_workforce_utilization_daily_aggregate.aggregate.max.workforce_utilization_per.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setWorkforceUtilization((prevData) => {
        const newarr = [...prevData];
        newarr[3] = { percentage: "No Data" };
        newarr[7] = { percentage: "No Data" };
        newarr[11] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  ///////////// Workforce Productivity //////////////////////////////////////////////////////////////////////////

  const [WorkforceProductivityShiftData, setWorkforceProductivityShiftData] =
    useState([]);
  useEffect(() => {
    WorkforceProductivityShift();
  }, []);
  const WorkforceProductivityShift = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = 15, $end_date: date = ${currentDate}) {
                dm_shiftwise(where: {date: {_gte: $start_date, _lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                  parts_per_minute
                  timestamp
                }
              }
        `);
      if (data === undefined || data.dm_shiftwise === null) {
        setWorkforceProductivityShiftData([
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ]);
      } else {
        const WorkforceProductivityShift = [
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ];

        data.dm_shiftwise.map((data) => {
          var shiftDate = new Date(data.timestamp);
          var HoursshiftDate = shiftDate.getHours();
          if (HoursshiftDate >= 8 && HoursshiftDate <= 16) {
            WorkforceProductivityShift[0] = {
              name: "Shift 1",
              dataValue: data.parts_per_minute,
            };
          } else if (HoursshiftDate >= 16 && HoursshiftDate <= 24) {
            WorkforceProductivityShift[1] = {
              name: "Shift 2",
              dataValue: data.parts_per_minute,
            };
          } else if (HoursshiftDate >= 0 && HoursshiftDate <= 8) {
            WorkforceProductivityShift[2] = {
              name: "Shift 3",
              dataValue: data.parts_per_minute,
            };
          }
        });
        setWorkforceProductivityShiftData(WorkforceProductivityShift);
      }
    } catch (error) {
      setWorkforceProductivityShiftData([
        {
          name: "shift 1",
          dataValue: "0",
        },
        {
          name: "shift 2",
          dataValue: "0",
        },
        {
          name: "shift 3",
          dataValue: "0",
        },
      ]);
    }
  };
  //////////////////////////// WorkForce Productivity //////////////////////////////////
  /////////////////////////// Daily ///////////////////////

  ////////////////////////////////////////////////////////////////////////////

  ///////////////////////////////// single query /////////////////////////////

  const [WorkforceProductivityAllData, setWorkforceProductivityAllData] =
    useState("No Data");
  const [timePeriod, setTimePeriod] = useState("day");

  useEffect(() => {
    WorkforceProductivityFunData();
  }, []);
  const WorkforceProductivityFunData = async () => {
    try {
      const data = await QueryApi(`
        query MyQuery($tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
          dm_foundation(where: {tenantid: {_eq: $tenantid}}) {
                date
                parts_per_minute
            }
        }
      `);

      if (data === undefined || data.dm_daily === null) {
        setWorkforceProductivityAllData("No Data");
      } else {
        const dailyData = groupByTimePeriod(data.dm_foundation, "day");
        const weeklyData = groupByTimePeriod(data.dm_foundation, "week");
        const monthlyData = groupByTimePeriod(data.dm_foundation, "month");
        const yearlyData = groupByTimePeriod(data.dm_foundation, "year");
        setWorkforceProductivityAllData({
          daily: dailyData,
          weekly: weeklyData,
          monthly: monthlyData,
          yearly: yearlyData,
        });
      }
    } catch (error) {
      setWorkforceProductivityAllData("No Data");
    }
  };

  // Helper function to group data by time period
  // Helper function to group data by time period
  function groupByTimePeriod(data, period) {
    const now = new Date();
    const filteredData = data.filter((item) => {
      const date = new Date(item.date);
      switch (period) {
        case "day":
          return date.toDateString() === now.toDateString();

        case "week":
          const oneWeekAgo = new Date();
          oneWeekAgo.setDate(now.getDate() - 7);
          return date > oneWeekAgo && date <= now;
        case "month":
          return (
            date.getMonth() === now.getMonth() &&
            date.getFullYear() === now.getFullYear()
          );
        case "year":
          return date.getFullYear() === now.getFullYear() - 1;
      }
    });

    return filteredData.reduce((acc, item) => {
      let key;

      switch (period) {
        case "day":
          key = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
          break;
        case "week":
          key = `${date.getFullYear()}-W${Math.floor(date.getDate() / 7) + 1}`;
          break;
        case "month":
          key = `${date.getFullYear()}-${date.getMonth() + 1}`;
          break;
        case "year":
          key = `${date.getFullYear()}`;
          break;
      }

      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push(item.parts_per_minute);

      return acc;
    }, {});
  }

  // Helper function to calculate average
  function calculateAverage(arr) {
    const sum = arr.reduce((a, b) => a + b, 0);
    return sum / arr.length || 0;
  }

  //////////////////////////////////////////////////////////////////////////////
  const [
    xAxisDataValueWorkforceProductivity,
    setXAxisDataValueWorkforceProductivity,
  ] = useState([]);
  const [plotPointWorkforceProductivity, setPlotPointWorkforceProductivity] =
    useState([]);
  const datasetWorkforceProductivity = [
    {
      label: "Workforce Productivity",
      data: plotPointWorkforceProductivity,
      borderColor: "#1F8EF1",
    },
  ];
  useEffect(() => {
    GraphDataListWorkforceProductivity();
  }, []);
  const GraphDataListWorkforceProductivity = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
                dm_foundation(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                    parts_per_minute
                  timestamp
                }
              }
        `);

      const plotAllDataWorkforceProductivity = data.dm_foundation.map(
        (data) => {
          const objData = {
            point: data.parts_per_minute,
            Xaxis: data.timestamp,
          };
          return objData;
        }
      );

      const plotPointDataWorkforceProductivity =
        plotAllDataWorkforceProductivity.map((data) => data.point);
      setPlotPointWorkforceProductivity(plotPointDataWorkforceProductivity);

      const xAxisPointDataWorkforceProductivity =
        plotAllDataWorkforceProductivity.map((data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
          var m = "0" + d.getMinutes();
          var ampm = h >= 12 ? "PM" : "AM";
          h = h % 12;
          h = h ? h : 12;
          var fullTime = h + ":" + m.substr(-2) + " " + ampm;
          return fullTime;
        });
      setXAxisDataValueWorkforceProductivity(
        xAxisPointDataWorkforceProductivity
      );
    } catch (error) {
      console.log(error);
    }
  };

  const [WorkforceProductivity, setWorkforceProductivity] = useState([
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
  ]);

  useEffect(() => {
    WorkforceProductivityDailyDataFun();
  }, []);
  const WorkforceProductivityDailyDataFun = async () => {
    try {
      const data = await QueryApi(`
        query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
            dm_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
              avg {
                parts_per_minute
              }
              max {
                parts_per_minute
              }
              min {
                parts_per_minute
              }
            }
          }
        }
     `);
      if (data !== undefined) {
        setWorkforceProductivity((prevData) => {
          const newarr = [...prevData];
          newarr[0] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min.parts_per_minute !== null
                ? data.dm_daily_aggregate.aggregate.min.parts_per_minute.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[4] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg.parts_per_minute !== null
                ? data.dm_daily_aggregate.aggregate.avg.parts_per_minute.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[8] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max.parts_per_minute !== null
                ? data.dm_daily_aggregate.aggregate.max.parts_per_minute.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setWorkforceProductivity((prevData) => {
        const newarr = [...prevData];
        newarr[0] = { percentage: "No Data" };
        newarr[4] = { percentage: "No Data" };
        newarr[8] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    parts_per_minuteWeeklyDataFun();
  }, []);
  const parts_per_minuteWeeklyDataFun = async () => {
    try {
      const data = await QueryApi(`
        query MyQuery($start_date: date = ${weekDataDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
            dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}){
          aggregate {
              avg {
                  parts_per_minute
              }
              max {
                  parts_per_minute
              }
              min {
                  parts_per_minute
              }
              }
          }
        }  
     `);
      if (data !== undefined) {
        setWorkforceProductivity((prevData) => {
          const newarr = [...prevData];
          newarr[1] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min.parts_per_minute !== null
                ? data.dm_daily_aggregate.aggregate.min.parts_per_minute.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[5] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg.parts_per_minute !== null
                ? data.dm_daily_aggregate.aggregate.avg.parts_per_minute.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[9] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max.parts_per_minute !== null
                ? data.dm_daily_aggregate.aggregate.max.parts_per_minute.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setWorkforceProductivity((prevData) => {
        const newarr = [...prevData];
        newarr[1] = { percentage: "No Data" };
        newarr[5] = { percentage: "No Data" };
        newarr[9] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    WorkforceProductivityMonthlyDataFun();
  }, []);
  const WorkforceProductivityMonthlyDataFun = async () => {
    try {
      const data = await QueryApi(`
        query MyQuery($start_date: date = ${previousMonthDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
            dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}){
          aggregate {
              avg {
                  parts_per_minute
              }
              max {
                  parts_per_minute
              }
              min {
                  parts_per_minute
              }
              }
          }
        }
     `);
      if (data !== undefined) {
        setWorkforceProductivity((prevData) => {
          const newarr = [...prevData];
          newarr[2] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min.parts_per_minute !== null
                ? data.dm_daily_aggregate.aggregate.min.parts_per_minute.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[6] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg.parts_per_minute !== null
                ? data.dm_daily_aggregate.aggregate.avg.parts_per_minute.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[10] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max.parts_per_minute !== null
                ? data.dm_daily_aggregate.aggregate.max.parts_per_minute.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setWorkforceProductivity((prevData) => {
        const newarr = [...prevData];
        newarr[2] = { percentage: "No Data" };
        newarr[6] = { percentage: "No Data" };
        newarr[10] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    WorkforceProductivityYearlyDataFun();
  }, []);
  const WorkforceProductivityYearlyDataFun = async () => {
    try {
      const data = await QueryApi(`
        query MyQuery($start_date: date = ${yearPreviousMonthDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
          dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}){
          aggregate {
              avg {
                  parts_per_minute
              }
              max {
                  parts_per_minute
              }
              min {
                  parts_per_minute
              }
            }
          }
        }
     `);
      if (data !== undefined) {
        setWorkforceProductivity((prevData) => {
          const newarr = [...prevData];
          newarr[3] = {
            percentage:
              data.dm_daily_aggregate.aggregate.min.parts_per_minute !== null
                ? data.dm_daily_aggregate.aggregate.min.parts_per_minute.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[7] = {
            percentage:
              data.dm_daily_aggregate.aggregate.avg.parts_per_minute !== null
                ? data.dm_daily_aggregate.aggregate.avg.parts_per_minute.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[11] = {
            percentage:
              data.dm_daily_aggregate.aggregate.max.parts_per_minute !== null
                ? data.dm_daily_aggregate.aggregate.max.parts_per_minute.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setWorkforceProductivity((prevData) => {
        const newarr = [...prevData];
        newarr[3] = { percentage: "No Data" };
        newarr[7] = { percentage: "No Data" };
        newarr[11] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  /////// Avg (YTD) /////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //// Absenteeism Ratio ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [AbsenteeismRatioShiftData, setAbsenteeismRatioShiftData] = useState(
    []
  );
  useEffect(() => {
    AbsenteeismRatioShift();
  }, []);
  const AbsenteeismRatioShift = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = 15, $end_date: date = ${currentDate}) {
                wdm_workforce_availability_shiftwise(where: {date: {_gte: $start_date, _lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                    unavailable_workforce_per
                    timestamp   
                 } 
              }
        `);
      if (
        data === undefined ||
        data.wdm_workforce_availability_shiftwise === null
      ) {
        setAbsenteeismRatioShiftData([
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ]);
      } else {
        const AbsenteeismRatioShift = [
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ];

        data.wdm_workforce_availability_shiftwise.map((data) => {
          var shiftDate = new Date(data.timestamp);
          var HoursshiftDate = shiftDate.getHours();
          if (HoursshiftDate >= 8 && HoursshiftDate <= 16) {
            AbsenteeismRatioShift[0] = {
              name: "Shift 1",
              dataValue: data.unavailable_workforce_per,
            };
          } else if (HoursshiftDate >= 16 && HoursshiftDate <= 24) {
            AbsenteeismRatioShift[1] = {
              name: "Shift 2",
              dataValue: data.unavailable_workforce_per,
            };
          } else if (HoursshiftDate >= 0 && HoursshiftDate <= 8) {
            AbsenteeismRatioShift[2] = {
              name: "Shift 3",
              dataValue: data.unavailable_workforce_per,
            };
          }
        });
        setAbsenteeismRatioShiftData(AbsenteeismRatioShift);
      }
    } catch (error) {
      console.log(error);
      setAbsenteeismRatioShiftData([
        {
          name: "shift 1",
          dataValue: "0",
        },
        {
          name: "shift 2",
          dataValue: "0",
        },
        {
          name: "shift 3",
          dataValue: "0",
        },
      ]);
    }
  };

  const [AbsenteeismRatioData, setAbsenteeismRatioData] = useState("No Data");
  useEffect(() => {
    AbsenteeismRatioFun();
  }, []);

  const AbsenteeismRatioFun = async () => {
    try {
      const data = await QueryApi(`
        query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            wdm_workforce_availability_aggregate(where: {date: {_gte: $start_date, _lte: $end_date}, tenantid: {_eq: $tenantid}}) {
              aggregate {
                  avg {
                    unavailable_workforce_per
                  }
              }
            }
          }          
       `);
      if (
        data === undefined ||
        data.wdm_workforce_availability_aggregate.aggregate.avg
          .unavailable_workforce_per === null
      ) {
        setAbsenteeismRatioData("No Data");
      } else {
        setAbsenteeismRatioData(
          data.wdm_workforce_availability_aggregate.aggregate.avg.unavailable_workforce_per.toFixed(
            1
          )
        );
      }
    } catch (error) {
      setAbsenteeismRatioData("No Data");
    }
  };

  const [xAxisDataValueOAbsenteeismRatio, setXAxisDataValueOAbsenteeismRatio] =
    useState([]);
  const [plotPointOAbsenteeismRatio, setPlotPointOAbsenteeismRatio] = useState(
    []
  );
  const datasetOAbsenteeismRatio = [
    {
      label: "Absenteeism Ratio",
      data: plotPointOAbsenteeismRatio,
      borderColor: "#1F8EF1",
    },
  ];
  useEffect(() => {
    GraphDataListOAbsenteeismRatio();
  }, []);
  const GraphDataListOAbsenteeismRatio = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                wdm_workforce_availability(where: {date: {_gte: $start_date, _lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                    unavailable_workforce_per
                    timestamp
                }
            }
        `);
      const plotAllDataOAbsenteeismRatio = data.wdm_workforce_availability.map(
        (data) => {
          const objData = {
            point: data.unavailable_workforce_per,
            Xaxis: data.timestamp,
          };
          return objData;
        }
      );

      const plotPointDataOAbsenteeismRatio = plotAllDataOAbsenteeismRatio.map(
        (data) => data.point
      );
      setPlotPointOAbsenteeismRatio(plotPointDataOAbsenteeismRatio);

      const xAxisPointDataOAbsenteeismRatio = plotAllDataOAbsenteeismRatio.map(
        (data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
          var m = "0" + d.getMinutes();
          var ampm = h >= 12 ? "PM" : "AM";
          h = h % 12;
          h = h ? h : 12;
          var fullTime = h + ":" + m.substr(-2) + " " + ampm;
          return fullTime;
        }
      );
      setXAxisDataValueOAbsenteeismRatio(xAxisPointDataOAbsenteeismRatio);
    } catch (error) {
      console.log(error);
    }
  };

  const [AbsenteeismRatio, setAbsenteeismRatio] = useState([
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
  ]);

  useEffect(() => {
    AbsenteeismRatioDailyDataFun();
  }, []);
  const AbsenteeismRatioDailyDataFun = async () => {
    try {
      const data = await QueryApi(`
        query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            wdm_workforce_availability_aggregate(where: {date: {_gte: $start_date, _lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                    avg {
                        unavailable_workforce_per
                    }
                    max {
                        unavailable_workforce_per
                    }
                    min {
                        unavailable_workforce_per
                    }
                }
            }
        }  
     `);
      if (data !== undefined) {
        setAbsenteeismRatio((prevData) => {
          const newarr = [...prevData];
          newarr[0] = {
            percentage:
              data.wdm_workforce_availability_aggregate.aggregate.min
                .unavailable_workforce_per !== null
                ? data.wdm_workforce_availability_aggregate.aggregate.min.unavailable_workforce_per.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[4] = {
            percentage:
              data.wdm_workforce_availability_aggregate.aggregate.avg
                .unavailable_workforce_per !== null
                ? data.wdm_workforce_availability_aggregate.aggregate.avg.unavailable_workforce_per.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[8] = {
            percentage:
              data.wdm_workforce_availability_aggregate.aggregate.max
                .unavailable_workforce_per !== null
                ? data.wdm_workforce_availability_aggregate.aggregate.max.unavailable_workforce_per.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setAbsenteeismRatio((prevData) => {
        const newarr = [...prevData];
        newarr[0] = { percentage: "No Data" };
        newarr[4] = { percentage: "No Data" };
        newarr[8] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    AbsenteeismRatioWeeklyDataFun();
  }, []);
  const AbsenteeismRatioWeeklyDataFun = async () => {
    try {
      const data = await QueryApi(`
      query MyQuery($start_date: date = ${weekDataDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        wdm_workforce_availability_aggregate(where: {date: {_gte: $start_date, _lte: $end_date}, tenantid: {_eq: $tenantid}}) {
            aggregate {
                avg {
                    unavailable_workforce_per
                }
                max {
                    unavailable_workforce_per
                }
                min {
                    unavailable_workforce_per
                }
            }
          }
      }  
   `);
      if (data !== undefined) {
        setAbsenteeismRatio((prevData) => {
          const newarr = [...prevData];
          newarr[1] = {
            percentage:
              data.wdm_workforce_availability_aggregate.aggregate.min
                .unavailable_workforce_per !== null
                ? data.wdm_workforce_availability_aggregate.aggregate.min.unavailable_workforce_per.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[5] = {
            percentage:
              data.wdm_workforce_availability_aggregate.aggregate.avg
                .unavailable_workforce_per !== null
                ? data.wdm_workforce_availability_aggregate.aggregate.avg.unavailable_workforce_per.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[9] = {
            percentage:
              data.wdm_workforce_availability_aggregate.aggregate.max
                .unavailable_workforce_per !== null
                ? data.wdm_workforce_availability_aggregate.aggregate.max.unavailable_workforce_per.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setAbsenteeismRatio((prevData) => {
        const newarr = [...prevData];
        newarr[1] = { percentage: "No Data" };
        newarr[5] = { percentage: "No Data" };
        newarr[9] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    AbsenteeismRatioMonthlyDataFun();
  }, []);
  const AbsenteeismRatioMonthlyDataFun = async () => {
    try {
      const data = await QueryApi(`
      query MyQuery($start_date: date = ${previousMonthDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        wdm_workforce_availability_aggregate(where: {date: {_gte: $start_date, _lte: $end_date}, tenantid: {_eq: $tenantid}}) {
            aggregate {
                avg {
                    unavailable_workforce_per
                }
                max {
                    unavailable_workforce_per
                }
                min {
                    unavailable_workforce_per
                }
            }
        }
      }
   `);
      if (data !== undefined) {
        setAbsenteeismRatio((prevData) => {
          const newarr = [...prevData];
          newarr[2] = {
            percentage:
              data.wdm_workforce_availability_aggregate.aggregate.min
                .unavailable_workforce_per !== null
                ? data.wdm_workforce_availability_aggregate.aggregate.min.unavailable_workforce_per.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[6] = {
            percentage:
              data.wdm_workforce_availability_aggregate.aggregate.avg
                .unavailable_workforce_per !== null
                ? data.wdm_workforce_availability_aggregate.aggregate.avg.unavailable_workforce_per.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[10] = {
            percentage:
              data.wdm_workforce_availability_aggregate.aggregate.max
                .unavailable_workforce_per !== null
                ? data.wdm_workforce_availability_aggregate.aggregate.max.unavailable_workforce_per.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setAbsenteeismRatio((prevData) => {
        const newarr = [...prevData];
        newarr[2] = { percentage: "No Data" };
        newarr[6] = { percentage: "No Data" };
        newarr[10] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    AbsenteeismRatioYearlyDataFun();
  }, []);
  const AbsenteeismRatioYearlyDataFun = async () => {
    try {
      const data = await QueryApi(`
      query MyQuery($start_date: date = ${yearPreviousMonthDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        wdm_workforce_availability_aggregate(where: {date: {_gte: $start_date, _lte: $end_date}, tenantid: {_eq: $tenantid}}) {
            aggregate {
                avg {
                    unavailable_workforce_per
                }
                max {
                    unavailable_workforce_per
                }
                min {
                    unavailable_workforce_per
                }
            }
          }
      }
   `);
      if (data !== undefined) {
        setAbsenteeismRatio((prevData) => {
          const newarr = [...prevData];
          newarr[3] = {
            percentage:
              data.wdm_workforce_availability_aggregate.aggregate.min
                .unavailable_workforce_per !== null
                ? data.wdm_workforce_availability_aggregate.aggregate.min.unavailable_workforce_per.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[7] = {
            percentage:
              data.wdm_workforce_availability_aggregate.aggregate.avg
                .unavailable_workforce_per !== null
                ? data.wdm_workforce_availability_aggregate.aggregate.avg.unavailable_workforce_per.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[11] = {
            percentage:
              data.wdm_workforce_availability_aggregate.aggregate.max
                .unavailable_workforce_per !== null
                ? data.wdm_workforce_availability_aggregate.aggregate.max.unavailable_workforce_per.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setAbsenteeismRatio((prevData) => {
        const newarr = [...prevData];
        newarr[3] = { percentage: "No Data" };
        newarr[7] = { percentage: "No Data" };
        newarr[11] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  /////// Operator Machine Proximity ///////////////////////////////////////////////////////////////

  const [
    OperatorMachineProximityShiftData,
    setOperatorMachineProximityShiftData,
  ] = useState([]);
  useEffect(() => {
    OperatorMachineProximityShift();
  }, []);
  const OperatorMachineProximityShift = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = 15, $end_date: date = ${currentDate}) {
                wfl0_operators_machine_availability_shiftwise(where: {date: {_gte: $start_date, _lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                    total_effective_proximity_time
                    timestamp   
                 } 
            }
        `);
      if (
        data === undefined ||
        data.wfl0_operators_machine_availability_shiftwise === null
      ) {
        setOperatorMachineProximityShiftData([
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ]);
      } else {
        const OperatorMachineProximityShift = [
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ];

        data.wfl0_operators_machine_availability_shiftwise.map((data) => {
          var shiftDate = new Date(data.timestamp);
          var HoursshiftDate = shiftDate.getHours();
          if (HoursshiftDate >= 8 && HoursshiftDate <= 16) {
            OperatorMachineProximityShift[0] = {
              name: "Shift 1",
              dataValue: data.total_effective_proximity_time,
            };
          } else if (HoursshiftDate >= 16 && HoursshiftDate <= 24) {
            OperatorMachineProximityShift[1] = {
              name: "Shift 2",
              dataValue: data.total_effective_proximity_time,
            };
          } else if (HoursshiftDate >= 0 && HoursshiftDate <= 8) {
            OperatorMachineProximityShift[2] = {
              name: "Shift 3",
              dataValue: data.total_effective_proximity_time,
            };
          }
        });
        setOperatorMachineProximityShiftData(OperatorMachineProximityShift);
      }
    } catch (error) {
      console.log(error);
      setOperatorMachineProximityShiftData([
        {
          name: "shift 1",
          dataValue: "0",
        },
        {
          name: "shift 2",
          dataValue: "0",
        },
        {
          name: "shift 3",
          dataValue: "0",
        },
      ]);
    }
  };

  const [
    xAxisDataValueOOperatorMachineProximity,
    setXAxisDataValueOOperatorMachineProximity,
  ] = useState([]);
  const [
    plotPointOOperatorMachineProximity,
    setPlotPointOOperatorMachineProximity,
  ] = useState([]);
  const datasetOOperatorMachineProximity = [
    {
      label: "Operator Machine Proximity",
      data: plotPointOOperatorMachineProximity,
      borderColor: "#1F8EF1",
    },
  ];
  useEffect(() => {
    GraphDataListOOperatorMachineProximity();
  }, []);
  const GraphDataListOOperatorMachineProximity = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
                wfl0_operators_machine_availability_daily(where: {date: {_gte: $start_date, _lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                    total_effective_proximity_time
                    timestamp
                }
            }
        `);
      const plotAllDataOOperatorMachineProximity =
        data.wfl0_operators_machine_availability_daily.map((data) => {
          const objData = {
            point: data.total_effective_proximity_time,
            Xaxis: data.timestamp,
          };
          return objData;
        });

      const plotPointDataOOperatorMachineProximity =
        plotAllDataOOperatorMachineProximity.map((data) => data.point);
      setPlotPointOOperatorMachineProximity(
        plotPointDataOOperatorMachineProximity
      );

      const xAxisPointDataOOperatorMachineProximity =
        plotAllDataOOperatorMachineProximity.map((data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
          var m = "0" + d.getMinutes();
          var ampm = h >= 12 ? "PM" : "AM";
          h = h % 12;
          h = h ? h : 12;
          var fullTime = h + ":" + m.substr(-2) + " " + ampm;
          return fullTime;
        });
      setXAxisDataValueOOperatorMachineProximity(
        xAxisPointDataOOperatorMachineProximity
      );
    } catch (error) {
      console.log(error);
    }
  };

  const [OperatorMachineProximity, setOperatorMachineProximity] = useState([
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
  ]);

  useEffect(() => {
    OperatorMachineProximityDailyDataFun();
  }, []);
  const OperatorMachineProximityDailyDataFun = async () => {
    try {
      const data = await QueryApi(`
        query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            wfl0_operators_machine_availability_daily_aggregate(where: {date: {_gte: $start_date, _lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                    avg {
                        total_effective_proximity_time
                    }
                    max {
                        total_effective_proximity_time
                    }
                    min {
                        total_effective_proximity_time
                    }
                }
            }
        }  
     `);
      if (data !== undefined) {
        setOperatorMachineProximity((prevData) => {
          const newarr = [...prevData];
          newarr[0] = {
            percentage:
              data.wfl0_operators_machine_availability_daily_aggregate.aggregate
                .min.total_effective_proximity_time !== null
                ? data.wfl0_operators_machine_availability_daily_aggregate.aggregate.min.total_effective_proximity_time.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[4] = {
            percentage:
              data.wfl0_operators_machine_availability_daily_aggregate.aggregate
                .avg.total_effective_proximity_time !== null
                ? data.wfl0_operators_machine_availability_daily_aggregate.aggregate.avg.total_effective_proximity_time.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[8] = {
            percentage:
              data.wfl0_operators_machine_availability_daily_aggregate.aggregate
                .max.total_effective_proximity_time !== null
                ? data.wfl0_operators_machine_availability_daily_aggregate.aggregate.max.total_effective_proximity_time.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setOperatorMachineProximity((prevData) => {
        const newarr = [...prevData];
        newarr[0] = { percentage: "No Data" };
        newarr[4] = { percentage: "No Data" };
        newarr[8] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    OperatorMachineProximityWeeklyDataFun();
  }, []);
  const OperatorMachineProximityWeeklyDataFun = async () => {
    try {
      const data = await QueryApi(`
      query MyQuery($start_date: date = ${weekDataDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        wfl0_operators_machine_availability_daily_aggregate(where: {date: {_gte: $start_date, _lte: $end_date}, tenantid: {_eq: $tenantid}}) {
            aggregate {
                avg {
                    total_effective_proximity_time
                }
                max {
                    total_effective_proximity_time
                }
                min {
                    total_effective_proximity_time
                }
            }
          }
      }  
   `);
      if (data !== undefined) {
        setOperatorMachineProximity((prevData) => {
          const newarr = [...prevData];
          newarr[1] = {
            percentage:
              data.wfl0_operators_machine_availability_daily_aggregate.aggregate
                .min.total_effective_proximity_time !== null
                ? data.wfl0_operators_machine_availability_daily_aggregate.aggregate.min.total_effective_proximity_time.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[5] = {
            percentage:
              data.wfl0_operators_machine_availability_daily_aggregate.aggregate
                .avg.total_effective_proximity_time !== null
                ? data.wfl0_operators_machine_availability_daily_aggregate.aggregate.avg.total_effective_proximity_time.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[9] = {
            percentage:
              data.wfl0_operators_machine_availability_daily_aggregate.aggregate
                .max.total_effective_proximity_time !== null
                ? data.wfl0_operators_machine_availability_daily_aggregate.aggregate.max.total_effective_proximity_time.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setOperatorMachineProximity((prevData) => {
        const newarr = [...prevData];
        newarr[1] = { percentage: "No Data" };
        newarr[5] = { percentage: "No Data" };
        newarr[9] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    OperatorMachineProximityMonthlyDataFun();
  }, []);
  const OperatorMachineProximityMonthlyDataFun = async () => {
    try {
      const data = await QueryApi(`
      query MyQuery($start_date: date = ${previousMonthDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        wfl0_operators_machine_availability_daily_aggregate(where: {date: {_gte: $start_date, _lte: $end_date}, tenantid: {_eq: $tenantid}}) {
            aggregate {
                avg {
                    total_effective_proximity_time
                }
                max {
                    total_effective_proximity_time
                }
                min {
                    total_effective_proximity_time
                }
            }
        }
      }
   `);
      if (data !== undefined) {
        setOperatorMachineProximity((prevData) => {
          const newarr = [...prevData];
          newarr[2] = {
            percentage:
              data.wfl0_operators_machine_availability_daily_aggregate.aggregate
                .min.total_effective_proximity_time !== null
                ? data.wfl0_operators_machine_availability_daily_aggregate.aggregate.min.total_effective_proximity_time.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[6] = {
            percentage:
              data.wfl0_operators_machine_availability_daily_aggregate.aggregate
                .avg.total_effective_proximity_time !== null
                ? data.wfl0_operators_machine_availability_daily_aggregate.aggregate.avg.total_effective_proximity_time.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[10] = {
            percentage:
              data.wfl0_operators_machine_availability_daily_aggregate.aggregate
                .max.total_effective_proximity_time !== null
                ? data.wfl0_operators_machine_availability_daily_aggregate.aggregate.max.total_effective_proximity_time.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setOperatorMachineProximity((prevData) => {
        const newarr = [...prevData];
        newarr[2] = { percentage: "No Data" };
        newarr[6] = { percentage: "No Data" };
        newarr[10] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    OperatorMachineProximityYearlyDataFun();
  }, []);
  const OperatorMachineProximityYearlyDataFun = async () => {
    try {
      const data = await QueryApi(`
      query MyQuery($start_date: date = ${yearPreviousMonthDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        wfl0_operators_machine_availability_daily_aggregate(where: {date: {_gte: $start_date, _lte: $end_date}, tenantid: {_eq: $tenantid}}) {
            aggregate {
                avg {
                    total_effective_proximity_time
                }
                max {
                    total_effective_proximity_time
                }
                min {
                    total_effective_proximity_time
                }
            }
          }
      }
   `);
      if (data !== undefined) {
        setOperatorMachineProximity((prevData) => {
          const newarr = [...prevData];
          newarr[3] = {
            percentage:
              data.wfl0_operators_machine_availability_daily_aggregate.aggregate
                .min.total_effective_proximity_time !== null
                ? data.wfl0_operators_machine_availability_daily_aggregate.aggregate.min.total_effective_proximity_time.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[7] = {
            percentage:
              data.wfl0_operators_machine_availability_daily_aggregate.aggregate
                .avg.total_effective_proximity_time !== null
                ? data.wfl0_operators_machine_availability_daily_aggregate.aggregate.avg.total_effective_proximity_time.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[11] = {
            percentage:
              data.wfl0_operators_machine_availability_daily_aggregate.aggregate
                .max.total_effective_proximity_time !== null
                ? data.wfl0_operators_machine_availability_daily_aggregate.aggregate.max.total_effective_proximity_time.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setOperatorMachineProximity((prevData) => {
        const newarr = [...prevData];
        newarr[3] = { percentage: "No Data" };
        newarr[7] = { percentage: "No Data" };
        newarr[11] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  ///// Production to wage ratio ////////////////////////////////////////////////////////////////////

  const [ProductionToWageRatioShiftData, setProductionToWageRatioShiftData] =
    useState([]);
  useEffect(() => {
    ProductionToWageRatioShift();
  }, []);
  const ProductionToWageRatioShift = async () => {
    try {
      const data = await QueryApi(`
        query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            wfl0_production_wage_ratio_shiftwise(where: {date: {_gte: $start_date, _lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                production_wage_ratio
                timestamp
              } 
          }
    `);
      if (
        data === undefined ||
        data.wfl0_production_wage_ratio_shiftwise === null
      ) {
        setProductionToWageRatioShiftData([
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ]);
      } else {
        const ProductionToWageRatioShift = [
          {
            name: "shift 1",
            dataValue: "0",
          },
          {
            name: "shift 2",
            dataValue: "0",
          },
          {
            name: "shift 3",
            dataValue: "0",
          },
        ];

        data.wfl0_production_wage_ratio_shiftwise.map((data) => {
          var shiftDate = new Date(data.timestamp);
          var HoursshiftDate = shiftDate.getHours();
          if (HoursshiftDate >= 8 && HoursshiftDate <= 16) {
            ProductionToWageRatioShift[0] = {
              name: "Shift 1",
              dataValue: data.production_wage_ratio,
            };
          } else if (HoursshiftDate >= 16 && HoursshiftDate <= 24) {
            ProductionToWageRatioShift[1] = {
              name: "Shift 2",
              dataValue: data.production_wage_ratio,
            };
          } else if (HoursshiftDate >= 0 && HoursshiftDate <= 8) {
            ProductionToWageRatioShift[2] = {
              name: "Shift 3",
              dataValue: data.production_wage_ratio,
            };
          }
        });
        setProductionToWageRatioShiftData(ProductionToWageRatioShift);
      }
    } catch (error) {
      console.log(error);
      setProductionToWageRatioShiftData([
        {
          name: "shift 1",
          dataValue: "0",
        },
        {
          name: "shift 2",
          dataValue: "0",
        },
        {
          name: "shift 3",
          dataValue: "0",
        },
      ]);
    }
  };

  const [ProductionToWageRatioData, setProductionToWageRatioData] =
    useState("No Data");
  useEffect(() => {
    ProductionToWageRatioFun();
  }, []);

  const ProductionToWageRatioFun = async () => {
    try {
      const data = await QueryApi(`
            query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            wfl0_production_wage_ratio_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
            aggregate {
                    avg {
                        production_wage_ratio
                    }
                }
            }
        }
   `);

      console.log("this is data ==================>", data);

      if (
        data === undefined ||
        data.wfl0_production_wage_ratio_daily_aggregate.aggregate.avg
          .production_wage_ratio === null
      ) {
        setProductionToWageRatioData("No Data");
      } else {
        setProductionToWageRatioData(
          data.wfl0_production_wage_ratio_daily_aggregate.aggregate.avg.production_wage_ratio.toFixed(
            1
          )
        );
      }
    } catch (error) {
      setProductionToWageRatioData("No Data");
    }
  };

  const [ProductionToWageRatio, setProductionToWageRatio] = useState([
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
    {
      percentage: "No Data",
    },
  ]);

  useEffect(() => {
    ProductionToWageRatioDailyDataFun();
  }, []);
  const ProductionToWageRatioDailyDataFun = async () => {
    try {
      const data = await QueryApi(`
      query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        wfl0_production_wage_ratio_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
            aggregate {
            avg {
              production_wage_ratio
            }
            max {
              production_wage_ratio
            }
            min {
              production_wage_ratio
            }
          }
        }
      }
   `);
      if (data !== undefined) {
        setProductionToWageRatio((prevData) => {
          const newarr = [...prevData];
          newarr[0] = {
            percentage:
              data.wfl0_production_wage_ratio_daily_aggregate.aggregate.min
                .production_wage_ratio !== null
                ? data.wfl0_production_wage_ratio_daily_aggregate.aggregate.min.production_wage_ratio.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[4] = {
            percentage:
              data.wfl0_production_wage_ratio_daily_aggregate.aggregate.avg
                .production_wage_ratio !== null
                ? data.wfl0_production_wage_ratio_daily_aggregate.aggregate.avg.production_wage_ratio.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[8] = {
            percentage:
              data.wfl0_production_wage_ratio_daily_aggregate.aggregate.max
                .production_wage_ratio !== null
                ? data.wfl0_production_wage_ratio_daily_aggregate.aggregate.max.production_wage_ratio.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setProductionToWageRatio((prevData) => {
        const newarr = [...prevData];
        newarr[0] = { percentage: "No Data" };
        newarr[4] = { percentage: "No Data" };
        newarr[8] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    ProductionToWageRatioWeeklyDataFun();
  }, []);
  const ProductionToWageRatioWeeklyDataFun = async () => {
    try {
      const data = await QueryApi(`
      query MyQuery($start_date: date = ${weekDataDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        wfl0_production_wage_ratio_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}){
        aggregate {
            avg {
                production_wage_ratio
            }
            max {
                production_wage_ratio
            }
            min {
                production_wage_ratio
            }
            }
        }
      }  
   `);
      if (data !== undefined) {
        setProductionToWageRatio((prevData) => {
          const newarr = [...prevData];
          newarr[1] = {
            percentage:
              data.wfl0_production_wage_ratio_daily_aggregate.aggregate.min
                .production_wage_ratio !== null
                ? data.wfl0_production_wage_ratio_daily_aggregate.aggregate.min.production_wage_ratio.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[5] = {
            percentage:
              data.wfl0_production_wage_ratio_daily_aggregate.aggregate.avg
                .production_wage_ratio !== null
                ? data.wfl0_production_wage_ratio_daily_aggregate.aggregate.avg.production_wage_ratio.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[9] = {
            percentage:
              data.wfl0_production_wage_ratio_daily_aggregate.aggregate.max
                .production_wage_ratio !== null
                ? data.wfl0_production_wage_ratio_daily_aggregate.aggregate.max.production_wage_ratio.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setProductionToWageRatio((prevData) => {
        const newarr = [...prevData];
        newarr[1] = { percentage: "No Data" };
        newarr[5] = { percentage: "No Data" };
        newarr[9] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    ProductionToWageRatioMonthlyDataFun();
  }, []);
  const ProductionToWageRatioMonthlyDataFun = async () => {
    try {
      const data = await QueryApi(`
      query MyQuery($start_date: date = ${previousMonthDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        wfl0_production_wage_ratio_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}){
        aggregate {
            avg {
                production_wage_ratio
            }
            max {
                production_wage_ratio
            }
            min {
                production_wage_ratio
            }
            }
        }
      }
   `);
      if (data !== undefined) {
        setProductionToWageRatio((prevData) => {
          const newarr = [...prevData];
          newarr[2] = {
            percentage:
              data.wfl0_production_wage_ratio_daily_aggregate.aggregate.min
                .production_wage_ratio !== null
                ? data.wfl0_production_wage_ratio_daily_aggregate.aggregate.min.production_wage_ratio.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[6] = {
            percentage:
              data.wfl0_production_wage_ratio_daily_aggregate.aggregate.avg
                .production_wage_ratio !== null
                ? data.wfl0_production_wage_ratio_daily_aggregate.aggregate.avg.production_wage_ratio.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[10] = {
            percentage:
              data.wfl0_production_wage_ratio_daily_aggregate.aggregate.max
                .production_wage_ratio !== null
                ? data.wfl0_production_wage_ratio_daily_aggregate.aggregate.max.production_wage_ratio.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setProductionToWageRatio((prevData) => {
        const newarr = [...prevData];
        newarr[2] = { percentage: "No Data" };
        newarr[6] = { percentage: "No Data" };
        newarr[10] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  useEffect(() => {
    ProductionToWageRatioYearlyDataFun();
  }, []);
  const ProductionToWageRatioYearlyDataFun = async () => {
    try {
      const data = await QueryApi(`
      query MyQuery($start_date: date = ${yearPreviousMonthDate}, $end_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        wfl0_production_wage_ratio_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}){
        aggregate {
            avg {
                production_wage_ratio
            }
            max {
                production_wage_ratio
            }
            min {
                production_wage_ratio
            }
            }
        }
      }
   `);
      if (data !== undefined) {
        setProductionToWageRatio((prevData) => {
          const newarr = [...prevData];
          newarr[3] = {
            percentage:
              data.wfl0_production_wage_ratio_daily_aggregate.aggregate.min
                .production_wage_ratio !== null
                ? data.wfl0_production_wage_ratio_daily_aggregate.aggregate.min.production_wage_ratio.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[7] = {
            percentage:
              data.wfl0_production_wage_ratio_daily_aggregate.aggregate.avg
                .production_wage_ratio !== null
                ? data.wfl0_production_wage_ratio_daily_aggregate.aggregate.avg.production_wage_ratio.toFixed(
                    1
                  )
                : "No Data",
          };
          newarr[11] = {
            percentage:
              data.wfl0_production_wage_ratio_daily_aggregate.aggregate.max
                .production_wage_ratio !== null
                ? data.wfl0_production_wage_ratio_daily_aggregate.aggregate.max.production_wage_ratio.toFixed(
                    1
                  )
                : "No Data",
          };
          return newarr;
        });
      }
    } catch (error) {
      setProductionToWageRatio((prevData) => {
        const newarr = [...prevData];
        newarr[3] = { percentage: "No Data" };
        newarr[7] = { percentage: "No Data" };
        newarr[11] = { percentage: "No Data" };
        return newarr;
      });
    }
  };

  ///////////////////////////////////////////////////////////////////////////////////////

  const [WorkforceAvailabilityData, setWorkforceAvailabilityData] =
    useState("No Data");
  const [WorkforceEfficiencyData, setWorkforceEfficiencyData] =
    useState("No Data");
  const [WorkforceUtilizationData, setWorkforceUtilizationData] =
    useState("No Data");
  const [WorkforceProductivityData, setWorkforceProductivityData] =
    useState("No Data");
  const [OperatorMachineProximityData, setOperatorMachineProximityData] =
    useState("No Data");
  const [AvgData, setAvgData] = useState("No data");
  const [apiData, setApiData] = useState([]);

  useEffect(() => {
    WorkforceEfficiencyAllCard();
  }, [bigChartData]);
  const WorkforceEfficiencyAllCard = async () => {
    if (bigChartData === "data1") {
      try {
        const data = await QueryApi(`
          query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
             wdm_workforce_availability_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                    aggregate {
                      avg {
                        workforce_availability_per
                      }
                    }
                  }
                }
          `);

        if (
          data === undefined ||
          data.wdm_workforce_availability_daily_aggregate.aggregate.avg
            .workforce_availability_per === null
        ) {
          setWorkforceAvailabilityData("No Data");
        } else {
          setWorkforceAvailabilityData(
            data.wdm_workforce_availability_daily_aggregate.aggregate.avg.workforce_availability_per.toFixed(
              1
            )
          );
        }
      } catch (error) {
        setWorkforceAvailabilityData("No Data");
      }
      try {
        const data = await QueryApi(`
           query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
             wfl0_workforce_efficiency_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                  avg {
                    overall_labor_effectiveness
                  }
                }
              }
            }
         `);

        if (
          data === undefined ||
          data.wfl0_workforce_efficiency_daily_aggregate.aggregate.avg
            .overall_labor_effectiveness === null
        ) {
          setWorkforceEfficiencyData("No Data");
        } else {
          setWorkforceEfficiencyData(
            data.wfl0_workforce_efficiency_daily_aggregate.aggregate.avg.overall_labor_effectiveness.toFixed(
              1
            )
          );
        }
      } catch (error) {
        setWorkforceEfficiencyData("No Data");
      }
      try {
        const data = await QueryApi(`
           query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
              wfl0_workforce_utilization_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                      aggregate {
                          avg {
                              workforce_utilization_per
                          }
                      }
                  }
              }
              `);

        if (
          data === undefined ||
          data.wfl0_workforce_utilization_daily_aggregate.aggregate.avg
            .workforce_utilization_per === null
        ) {
          setWorkforceUtilizationData("No Data");
        } else {
          setWorkforceUtilizationData(
            data.wfl0_workforce_utilization_daily_aggregate.aggregate.avg.workforce_utilization_per.toFixed(
              1
            )
          );
        }
      } catch (error) {
        setWorkforceUtilizationData("No Data");
      }
      try {
        const data = await QueryApi(`
        query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
          dm_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                  aggregate {
                      avg {
                         parts_per_minute
                      }
                  }
              }
          }
          `);
        if (
          data === undefined ||
          data.dm_daily_aggregate.aggregate.avg.parts_per_minute === null
        ) {
          setWorkforceProductivityData("No Data");
        } else {
          setWorkforceProductivityData(
            data.dm_daily_aggregate.aggregate.avg.parts_per_minute.toFixed(1)
          );
        }
      } catch (error) {
        setWorkforceProductivityData("No Data");
      }
      try {
        const data = await QueryApi(`
          query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
              wfl0_operators_machine_availability_daily_aggregate(where: {date: {_gte: $start_date, _lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                    avg {
                      total_effective_proximity_time
                    }
                }
              }
            }          
         `);
        if (
          data === undefined ||
          data.wfl0_operators_machine_availability_daily_aggregate.aggregate.avg
            .total_effective_proximity_time === null
        ) {
          setOperatorMachineProximityData("No Data");
        } else {
          setOperatorMachineProximityData(
            data.wfl0_operators_machine_availability_daily_aggregate.aggregate.avg.total_effective_proximity_time.toFixed(
              1
            )
          );
        }
      } catch (error) {
        setOperatorMachineProximityData("No Data");
      }
      try {
        const data = await QueryApi(`
            query MyQuery($start_date: date = ${currentDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
              dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                  avg {
                      plant_operating_time_percent
                  }
                }
              }
            }
      `);
        if (
          data === undefined ||
          data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent ===
            null
        ) {
          setAvgData("No Data");
        } else {
          setAvgData(
            data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent.toFixed(
              1
            )
          );
        }
      } catch (error) {
        console.log(error);
        setAvgData("No Data");
      }
      try {
        const data = await QueryApi(`
        query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
            dm_workforce(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}},order_by: {date: desc}) {
              org_id
              Org {
                organization_node_name
              }
              unit_id
              unit {
                organization_node_name
              }
              department_id
              dept {
                organization_node_name
              }
              operator_id
              Operator {
                employee_id
                first_name
                middle_name
                last_name
              }
              job_role
              skill
              workforce_utilization_per
              ole
              performance_per
              quality_per
              availability_per
              total_parts_produced
              proximity_in_time_total
              machineid
              date
            }
          }
          
      
      `);
        setApiData(data?.dm_workforce);
      } catch (error) {}
      /////////////////////////////////
    } else if (bigChartData === "data2") {
      try {
        const data = await QueryApi(`
          query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
             wdm_workforce_availability_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                    aggregate {
                      avg {
                        workforce_availability_per
                      }
                    }
                  }
                }
          `);

        if (
          data === undefined ||
          data.wdm_workforce_availability_daily_aggregate.aggregate.avg
            .workforce_availability_per === null
        ) {
          setWorkforceAvailabilityData("No Data");
        } else {
          setWorkforceAvailabilityData(
            data.wdm_workforce_availability_daily_aggregate.aggregate.avg.workforce_availability_per.toFixed(
              1
            )
          );
        }
      } catch (error) {
        setWorkforceAvailabilityData("No Data");
      }
      try {
        const data = await QueryApi(`
         query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            wfl0_workforce_efficiency_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                  avg {
                    overall_labor_effectiveness
                  }
                }
              }
            }
         `);

        if (
          data === undefined ||
          data.wfl0_workforce_efficiency_daily_aggregate.aggregate.avg
            .overall_labor_effectiveness === null
        ) {
          setWorkforceEfficiencyData("No Data");
        } else {
          setWorkforceEfficiencyData(
            data.wfl0_workforce_efficiency_daily_aggregate.aggregate.avg.overall_labor_effectiveness.toFixed(
              1
            )
          );
        }
      } catch (error) {
        setWorkforceEfficiencyData("No Data");
      }
      try {
        const data = await QueryApi(`
          query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
             wfl0_workforce_utilization_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                      aggregate {
                          avg {
                              workforce_utilization_per
                          }
                      }
                  }
              }
              `);

        if (
          data === undefined ||
          data.wfl0_workforce_utilization_daily_aggregate.aggregate.avg
            .workforce_utilization_per === null
        ) {
          setWorkforceUtilizationData("No Data");
        } else {
          setWorkforceUtilizationData(
            data.wfl0_workforce_utilization_daily_aggregate.aggregate.avg.workforce_utilization_per.toFixed(
              1
            )
          );
        }
      } catch (error) {
        setWorkforceUtilizationData("No Data");
      }
      try {
        const data = await QueryApi(`
         query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                  aggregate {
                      avg {
                         parts_per_minute
                      }
                  }
              }
          }
          `);
        if (
          data === undefined ||
          data.dm_daily_aggregate.aggregate.avg.parts_per_minute === null
        ) {
          setWorkforceProductivityData("No Data");
        } else {
          setWorkforceProductivityData(
            data.dm_daily_aggregate.aggregate.avg.parts_per_minute.toFixed(1)
          );
        }
      } catch (error) {
        setWorkforceProductivityData("No Data");
      }
      try {
        const data = await QueryApi(`
          query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
              wfl0_operators_machine_availability_daily_aggregate(where: {date: {_gte: $start_date, _lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                    avg {
                      total_effective_proximity_time
                    }
                }
              }
            }          
         `);
        if (
          data === undefined ||
          data.wfl0_operators_machine_availability_daily_aggregate.aggregate.avg
            .total_effective_proximity_time === null
        ) {
          setOperatorMachineProximityData("No Data");
        } else {
          setOperatorMachineProximityData(
            data.wfl0_operators_machine_availability_daily_aggregate.aggregate.avg.total_effective_proximity_time.toFixed(
              1
            )
          );
        }
      } catch (error) {
        setOperatorMachineProximityData("No Data");
      }
      try {
        const data = await QueryApi(`
            query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
              dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                  avg {
                      plant_operating_time_percent
                  }
                }
              }
            }
      `);
        if (
          data === undefined ||
          data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent ===
            null
        ) {
          setAvgData("No Data");
        } else {
          setAvgData(
            data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent.toFixed(
              1
            )
          );
        }
      } catch (error) {
        console.log(error);
        setAvgData("No Data");
      }
      try {
        const data = await QueryApi(`
        query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! =  ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_workforce(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}},order_by: {date: desc}) {
              org_id
              Org {
                organization_node_name
              }
              unit_id
              unit {
                organization_node_name
              }
              department_id
              dept {
                organization_node_name
              }
              operator_id
              Operator {
                employee_id
                first_name
                middle_name
                last_name
              }
              job_role
              skill
              workforce_utilization_per
              ole
              performance_per
              quality_per
              availability_per
              total_parts_produced
              proximity_in_time_total
              machineid
              date
            }
          }
          
      
      `);
        setApiData(data?.dm_workforce);
      } catch (error) {}
    } else if (bigChartData === "data3") {
      try {
        const data = await QueryApi(`
          query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
             wdm_workforce_availability_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                    aggregate {
                      avg {
                        workforce_availability_per
                      }
                    }
                  }
                }
          `);

        if (
          data === undefined ||
          data.wdm_workforce_availability_daily_aggregate.aggregate.avg
            .workforce_availability_per === null
        ) {
          setWorkforceAvailabilityData("No Data");
        } else {
          setWorkforceAvailabilityData(
            data.wdm_workforce_availability_daily_aggregate.aggregate.avg.workforce_availability_per.toFixed(
              1
            )
          );
        }
      } catch (error) {
        setWorkforceAvailabilityData("No Data");
      }
      try {
        const data = await QueryApi(`
         query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            wfl0_workforce_efficiency_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                  avg {
                    overall_labor_effectiveness
                  }
                }
              }
            }
         `);

        if (
          data === undefined ||
          data.wfl0_workforce_efficiency_daily_aggregate.aggregate.avg
            .overall_labor_effectiveness === null
        ) {
          setWorkforceEfficiencyData("No Data");
        } else {
          setWorkforceEfficiencyData(
            data.wfl0_workforce_efficiency_daily_aggregate.aggregate.avg.overall_labor_effectiveness.toFixed(
              1
            )
          );
        }
      } catch (error) {
        setWorkforceEfficiencyData("No Data");
      }
      try {
        const data = await QueryApi(`
          query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
             wfl0_workforce_utilization_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                      aggregate {
                          avg {
                              workforce_utilization_per
                          }
                      }
                  }
              }
              `);

        if (
          data === undefined ||
          data.wfl0_workforce_utilization_daily_aggregate.aggregate.avg
            .workforce_utilization_per === null
        ) {
          setWorkforceUtilizationData("No Data");
        } else {
          setWorkforceUtilizationData(
            data.wfl0_workforce_utilization_daily_aggregate.aggregate.avg.workforce_utilization_per.toFixed(
              1
            )
          );
        }
      } catch (error) {
        setWorkforceUtilizationData("No Data");
      }
      try {
        const data = await QueryApi(`
         query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                  aggregate {
                      avg {
                         parts_per_minute
                      }
                  }
              }
          }
          `);
        if (
          data === undefined ||
          data.dm_daily_aggregate.aggregate.avg.parts_per_minute === null
        ) {
          setWorkforceProductivityData("No Data");
        } else {
          setWorkforceProductivityData(
            data.dm_daily_aggregate.aggregate.avg.parts_per_minute.toFixed(1)
          );
        }
      } catch (error) {
        setWorkforceProductivityData("No Data");
      }
      try {
        const data = await QueryApi(`
          query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
              wfl0_operators_machine_availability_daily_aggregate(where: {date: {_gte: $start_date, _lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                    avg {
                      total_effective_proximity_time
                    }
                }
              }
            }          
         `);
        if (
          data === undefined ||
          data.wfl0_operators_machine_availability_daily_aggregate.aggregate.avg
            .total_effective_proximity_time === null
        ) {
          setOperatorMachineProximityData("No Data");
        } else {
          setOperatorMachineProximityData(
            data.wfl0_operators_machine_availability_daily_aggregate.aggregate.avg.total_effective_proximity_time.toFixed(
              1
            )
          );
        }
      } catch (error) {
        setOperatorMachineProximityData("No Data");
      }
      try {
        const data = await QueryApi(`
            query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
              dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                  avg {
                      plant_operating_time_percent
                  }
                }
              }
            }
      `);
        if (
          data === undefined ||
          data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent ===
            null
        ) {
          setAvgData("No Data");
        } else {
          setAvgData(
            data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent.toFixed(
              1
            )
          );
        }
      } catch (error) {
        console.log(error);
        setAvgData("No Data");
      }
      try {
        const data = await QueryApi(`
        query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! =  ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_workforce(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}},order_by: {date: desc}) {
              org_id
              Org {
                organization_node_name
              }
              unit_id
              unit {
                organization_node_name
              }
              department_id
              dept {
                organization_node_name
              }
              operator_id
              Operator {
                employee_id
                first_name
                middle_name
                last_name
              }
              job_role
              skill
              workforce_utilization_per
              ole
              performance_per
              quality_per
              availability_per
              total_parts_produced
              proximity_in_time_total
              machineid
              date
            }
          }
          
      
      `);
        setApiData(data?.dm_workforce);
      } catch (error) {}
    } else if (bigChartData === "data4") {
      try {
        const data = await QueryApi(`
          query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
             wdm_workforce_availability_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                    aggregate {
                      avg {
                        workforce_availability_per
                      }
                    }
                  }
                }
          `);

        if (
          data === undefined ||
          data.wdm_workforce_availability_daily_aggregate.aggregate.avg
            .workforce_availability_per === null
        ) {
          setWorkforceAvailabilityData("No Data");
        } else {
          setWorkforceAvailabilityData(
            data.wdm_workforce_availability_daily_aggregate.aggregate.avg.workforce_availability_per.toFixed(
              1
            )
          );
        }
      } catch (error) {
        setWorkforceAvailabilityData("No Data");
      }
      try {
        const data = await QueryApi(`
         query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            wfl0_workforce_efficiency_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                  avg {
                    overall_labor_effectiveness
                  }
                }
              }
            }
         `);

        if (
          data === undefined ||
          data.wfl0_workforce_efficiency_daily_aggregate.aggregate.avg
            .overall_labor_effectiveness === null
        ) {
          setWorkforceEfficiencyData("No Data");
        } else {
          setWorkforceEfficiencyData(
            data.wfl0_workforce_efficiency_daily_aggregate.aggregate.avg.overall_labor_effectiveness.toFixed(
              1
            )
          );
        }
      } catch (error) {
        setWorkforceEfficiencyData("No Data");
      }
      try {
        const data = await QueryApi(`
          query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
             wfl0_workforce_utilization_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                      aggregate {
                          avg {
                              workforce_utilization_per
                          }
                      }
                  }
              }
              `);

        if (
          data === undefined ||
          data.wfl0_workforce_utilization_daily_aggregate.aggregate.avg
            .workforce_utilization_per === null
        ) {
          setWorkforceUtilizationData("No Data");
        } else {
          setWorkforceUtilizationData(
            data.wfl0_workforce_utilization_daily_aggregate.aggregate.avg.workforce_utilization_per.toFixed(
              1
            )
          );
        }
      } catch (error) {
        setWorkforceUtilizationData("No Data");
      }
      try {
        const data = await QueryApi(`
         query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
            dm_daily_aggregate(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                  aggregate {
                      avg {
                         parts_per_minute
                      }
                  }
              }
          }
          `);
        if (
          data === undefined ||
          data.dm_daily_aggregate.aggregate.avg.parts_per_minute === null
        ) {
          setWorkforceProductivityData("No Data");
        } else {
          setWorkforceProductivityData(
            data.dm_daily_aggregate.aggregate.avg.parts_per_minute.toFixed(1)
          );
        }
      } catch (error) {
        setWorkforceProductivityData("No Data");
      }
      try {
        const data = await QueryApi(`
          query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
              wfl0_operators_machine_availability_daily_aggregate(where: {date: {_gte: $start_date, _lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                    avg {
                      total_effective_proximity_time
                    }
                }
              }
            }          
         `);
        if (
          data === undefined ||
          data.wfl0_operators_machine_availability_daily_aggregate.aggregate.avg
            .total_effective_proximity_time === null
        ) {
          setOperatorMachineProximityData("No Data");
        } else {
          setOperatorMachineProximityData(
            data.wfl0_operators_machine_availability_daily_aggregate.aggregate.avg.total_effective_proximity_time.toFixed(
              1
            )
          );
        }
      } catch (error) {
        setOperatorMachineProximityData("No Data");
      }
      try {
        const data = await QueryApi(`
            query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
              dm_daily_aggregate(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
                aggregate {
                  avg {
                      plant_operating_time_percent
                  }
                }
              }
            }
      `);
        if (
          data === undefined ||
          data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent ===
            null
        ) {
          setAvgData("No Data");
        } else {
          setAvgData(
            data.dm_daily_aggregate.aggregate.avg.plant_operating_time_percent.toFixed(
              1
            )
          );
        }
      } catch (error) {
        console.log(error);
        setAvgData("No Data");
      }
      try {
        const data = await QueryApi(`
        query MyQuery($tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
            dm_workforce (order_by: {date: desc}) {
              org_id
              Org {
                organization_node_name
              }
              unit_id
              unit {
                organization_node_name
              }
              department_id
              dept {
                organization_node_name
              }
              operator_id
              Operator {
                employee_id
                first_name
                middle_name
                last_name
              }
              job_role
              skill
              workforce_utilization_per
              ole
              performance_per
              quality_per
              availability_per
              total_parts_produced
              proximity_in_time_total
              machineid
              date
              tenantid
            }
          }
          
      
      `);
        console.log("apiData workforce===>", data?.dm_workforce);
        setApiData(data?.dm_workforce);
      } catch (error) {}
    } else {
      setBgChartData("data1");
    }
  };
  //////////////////////////////
  const nullFormatter = (cell) => {
    return cell === null ? "Null" : cell;
  };
  const columnsBreakDownTime = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "Org.organization_node_name",
      text: "organization",
      headerStyle: { width: "500px" },
      formatter: nullFormatter,
    },
    {
      dataField: "unit.organization_node_name",
      text: "Unit",
      headerStyle: { width: "500px" },
      formatter: nullFormatter,
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
      formatter: nullFormatter,
    },
    {
      dataField: "operator_id",
      text: "Operator Id",
      headerStyle: { width: "500px" },
      formatter: nullFormatter,
    },
    {
      dataField: "Operator",
      text: "Operator Name",
      headerStyle: { width: "500px" },
      formatter: (cell) => {
        if (cell === null) {
          return "Null"; // or whatever default value you want
        } else {
          return `${cell.first_name} ${cell.last_name}`;
        }
      },
    },
    {
      dataField: "job_role",
      text: "job role",
      headerStyle: { width: "500px" },
      formatter: nullFormatter,
    },
    {
      dataField: "skill",
      text: "skill",
      headerStyle: { width: "500px" },
      formatter: nullFormatter,
    },
    {
      dataField: "workforce_utilization_per",
      text: "workforce utilization %",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        if (data == null) {
          return "Null"; // or whatever default value you want
        } else {
          return `${data.toFixed(3)}%`;
        }
      },
    },
    {
      dataField: "ole",
      text: "ole",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        if (data == null) {
          return "Null"; // or whatever default value you want
        } else {
          return `${data.toFixed(3)}%`;
        }
      },
    },
    {
      dataField: "availability_per",
      text: "availability %",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        if (data == null) {
          return "Null"; // or whatever default value you want
        } else {
          return `${data.toFixed(3)}%`;
        }
      },
    },
    {
      dataField: "performance_per",
      text: "performance %",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        if (data == null) {
          return "Null"; // or whatever default value you want
        } else {
          return `${data.toFixed(3)}%`;
        }
      },
    },

    {
      dataField: "quality_per",
      text: "quality %",
      headerStyle: { width: "500px" },
      formatter: (data) => {
        if (data == null) {
          return "Null"; // or whatever default value you want
        } else {
          return `${data.toFixed(3)}%`;
        }
      },
    },
    {
      dataField: "total_parts_produced",
      text: "total parts produced",
      headerStyle: { width: "500px" },
      formatter: nullFormatter,
    },
    {
      dataField: "proximity_in_time_total",
      text: "proximity time (minutes)",
      headerStyle: { width: "500px" },
      formatter: (cell) => {
        if (cell === null) {
          return "Null"; // or whatever default value you want
        } else {
          let minutes = (cell / 60000).toFixed(3);
          return `${minutes}`;
        }
      },
    },
  ];

  return (
    <>
      <div className="content">
        {/* <Row>
          <Col lg="3">
            <Card
              className="production-volume-header"
              style={{
                height: "40px",
                fontSize: "16px",
                fontWeight: "bold",
                color: "white",
                marginTop: "30px",
                justifyContent: "center",
              }}
            >
              <span style={{ paddingLeft: "20px" }}>
                Workforce Efficiencies - Level 1
              </span>
            </Card>
          </Col>
        </Row> */}

        <Card
          style={{
            background: "transparent",
            boxShadow: "0px 10px 20px 2px rgba(0,0,0,0.75)",
            height: "12rem",
            width: "100%",
          }}
        >
          <CardHeader>
            <div className="d-flex justify-content-end">
              <div>
                <ButtonGroup
                  className="btn-group-toggle float-right"
                  data-toggle="buttons"
                >
                  <Button
                    tag="label"
                    className={classNames("btn-simple", {
                      active: bigChartData === "data1",
                    })}
                    color="info"
                    id="0"
                    size="sm"
                    onClick={() => setBgChartData("data1")}
                  >
                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      Day
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="tim-icons icon-single-02" />
                    </span>
                  </Button>
                  <Button
                    color="info"
                    id="1"
                    size="sm"
                    tag="label"
                    className={classNames("btn-simple", {
                      active: bigChartData === "data2",
                    })}
                    onClick={() => setBgChartData("data2")}
                  >
                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      Week
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="tim-icons icon-gift-2" />
                    </span>
                  </Button>
                  <Button
                    color="info"
                    id="2"
                    size="sm"
                    tag="label"
                    className={classNames("btn-simple", {
                      active: bigChartData === "data3",
                    })}
                    onClick={() => setBgChartData("data3")}
                  >
                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      Month
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="tim-icons icon-tap-02" />
                    </span>
                  </Button>
                  <Button
                    color="info"
                    id="2"
                    size="sm"
                    tag="label"
                    className={classNames("btn-simple", {
                      active: bigChartData === "data4",
                    })}
                    onClick={() => setBgChartData("data4")}
                  >
                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      Year
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="tim-icons icon-tap-02" />
                    </span>
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "0.8rem",
                marginLeft: "0.5rem",
                height: "13rem",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                    gap: "0.8rem",
                  }}
                >
                  {/* ////////////////card 1////////////////////// */}
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        Workforce Availability (%)
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        {WorkforceAvailabilityData}
                        {WorkforceAvailabilityData === "No Data" ? "" : "%"}
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "100%",
                      }}
                    >
                      <img
                        src={timeLoss}
                        style={{
                          height: "2rem",
                          width: "2rem",
                          position: "absolute",
                          top: "10px",
                          right: "2.5rem",
                          cursor: "pointer",
                        }}
                        onClick={() => setPopupWorkForceAvailabilityOpen(true)}
                        title="Trend Line"
                      />
                      <div
                        className="icon-plus"
                        style={{
                          position: "absolute", // Position the icon absolutely
                          top: "10px", // Align it to the top
                          right: 0, // Align it to the right of the image
                        }}
                      >
                        <Link to="/admin/IFAWorkForceAvailability">
                          <FontAwesomeIcon icon={faArrowRightLong} />
                        </Link>
                      </div>
                    </div>
                    {PopupWorkForceAvailabilityOpen && (
                      <>
                        <div
                          style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100vw",
                            height: "100vh",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            backdropFilter: "blur(3px)",
                            zIndex: 999,
                          }}
                        />
                        <div
                          style={{
                            position: "fixed",
                            top: "150px",
                            left: "70%",
                            transform: "translate(-50%, 0)",
                            zIndex: 1000,
                            width: "100%",
                            height: "120vh",
                            padding: "1rem",
                            boxSizing: "border-box",
                          }}
                        >
                          <div style={{ position: "relative", height: "100%" }}>
                            <PopupWorkForceAvailability
                              closePopup={setPopupWorkForceAvailabilityOpen}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {/* /////////////////card 2///////////////////////// */}
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        Workforce Efficiency (%)
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        {WorkforceEfficiencyData}
                        {WorkforceEfficiencyData === "No Data" ? "" : "%"}
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "100%",
                      }}
                    >
                      <img
                        src={timeLoss}
                        style={{
                          height: "2rem",
                          width: "2rem",
                          position: "absolute",
                          top: "10px",
                          right: "2.5rem",
                          cursor: "pointer",
                        }}
                        onClick={() => setWorkForceEfficiencyPopUp(true)}
                        title="Trend Line"
                      />
                      <div
                        className="icon-plus"
                        style={{
                          position: "absolute", // Position the icon absolutely
                          top: "10px", // Align it to the top
                          right: 0, // Align it to the right of the image
                        }}
                      >
                        <Link to="/admin/IFAWorkForceAEfficiency">
                          {" "}
                          <FontAwesomeIcon icon={faArrowRightLong} />
                        </Link>
                      </div>
                    </div>
                    {workForceEfficiencyPopUp && (
                      <>
                        <div
                          style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100vw",
                            height: "100vh",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            backdropFilter: "blur(3px)",
                            zIndex: 999,
                          }}
                        />
                        <div
                          style={{
                            position: "fixed",
                            top: "150px",
                            left: "70%",
                            transform: "translate(-50%, 0)",
                            zIndex: 1000,
                            width: "100%",
                            height: "100vh",
                            padding: "1rem",
                            boxSizing: "border-box",
                          }}
                        >
                          <div style={{ position: "relative", height: "100%" }}>
                            <PopupChart
                              title="Workforce Efficiency (%)"
                              xAxisData={chartExample2.data}
                              dataset={chartExample2.options}
                              closePopup={setWorkForceEfficiencyPopUp}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {/* ///////////////card 3 ////////////////////////////// */}
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        Workforce Utilization (%)
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        {WorkforceUtilizationData}
                        {WorkforceUtilizationData === "No Data" ? "" : "%"}
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "100%",
                      }}
                    >
                      <img
                        src={timeLoss}
                        style={{
                          height: "2rem",
                          width: "2rem",
                          position: "absolute",
                          top: "10px",
                          right: "2.5rem",
                          cursor: "pointer",
                        }}
                        onClick={() => setPopUpWorkForceUtilization(true)}
                        title="Trend Line"
                      />
                      <div
                        className="icon-plus"
                        style={{
                          position: "absolute", // Position the icon absolutely
                          top: "10px", // Align it to the top
                          right: 0, // Align it to the right of the image
                        }}
                      >
                        <Link to="/admin/IFAWorkForceAEfficiency">
                          <FontAwesomeIcon icon={faArrowRightLong} />
                        </Link>
                      </div>
                    </div>
                    {popUpWorkForceUtilization && (
                      <>
                        <div
                          style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100vw",
                            height: "100vh",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            backdropFilter: "blur(3px)",
                            zIndex: 999,
                          }}
                        />
                        <div
                          style={{
                            position: "fixed",
                            top: "150px",
                            left: "70%",
                            transform: "translate(-50%, 0)",
                            zIndex: 1000,
                            width: "100%",
                            height: "100vh",
                            padding: "1rem",
                            boxSizing: "border-box",
                          }}
                        >
                          <div style={{ position: "relative", height: "100%" }}>
                            <PopupChart
                              title="Workforce Utilization (%)"
                              xAxisData={chartExample2.data}
                              dataset={chartExample2.options}
                              closePopup={setPopUpWorkForceUtilization}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {/* ///////////////// card 4 ////////////////////// */}
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        Workforce Productivity (%)
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        {WorkforceProductivityData}
                        {WorkforceProductivityData === "No Data" ||
                        WorkforceProductivityData === null
                          ? ""
                          : "%"}
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "100%",
                      }}
                    >
                      <img
                        src={timeLoss}
                        style={{
                          height: "2rem",
                          width: "2rem",
                          position: "absolute",
                          top: "10px",
                          right: "2.5rem",
                          cursor: "pointer",
                        }}
                        onClick={() => setPopupWorkForceProductivityOpen(true)}
                        title="Trend Line"
                      />
                      <div
                        className="icon-plus"
                        style={{
                          position: "absolute", // Position the icon absolutely
                          top: "10px", // Align it to the top
                          right: 0, // Align it to the right of the image
                        }}
                      >
                        <Link to="/admin/IFAWorkforceProductivity">
                          <FontAwesomeIcon icon={faArrowRightLong} />
                        </Link>
                      </div>
                    </div>
                    {PopupWorkForceProductivityOpen && (
                      <>
                        <div
                          style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100vw",
                            height: "100vh",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            backdropFilter: "blur(3px)",
                            zIndex: 999,
                          }}
                        />
                        <div
                          style={{
                            position: "fixed",
                            top: "150px",
                            left: "70%",
                            transform: "translate(-50%, 0)",
                            zIndex: 1000,
                            width: "100%",
                            height: "120vh",
                            padding: "1rem",
                            boxSizing: "border-box",
                          }}
                        >
                          <div style={{ position: "relative", height: "100%" }}>
                            <PopupWorkForceProductivity
                              closePopup={setPopupWorkForceProductivityOpen}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {/* /////////////////// card 5 /////////////////////////// */}
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        Operator Machine Proximity
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        {OperatorMachineProximityData}
                        {OperatorMachineProximityData !== "No Data" ? "%" : ""}
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "100%",
                      }}
                    >
                      <img
                        src={timeLoss}
                        style={{
                          height: "2rem",
                          width: "2rem",
                          position: "absolute",
                          top: "10px",
                          right: "2.5rem",
                          cursor: "pointer",
                        }}
                        onClick={() => setPopUpOperatorMachineProxy(true)}
                        title="Trend Line"
                      />
                      <div
                        className="icon-plus"
                        style={{
                          position: "absolute", // Position the icon absolutely
                          top: "10px", // Align it to the top
                          right: 0, // Align it to the right of the image
                        }}
                      >
                        <Link to="/admin/IFAOperatorMachineProximity">
                          <FontAwesomeIcon icon={faArrowRightLong} />
                        </Link>
                      </div>
                    </div>
                    {popUpOperatorMachineProxy && (
                      <>
                        <div
                          style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100vw",
                            height: "100vh",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            backdropFilter: "blur(3px)",
                            zIndex: 999,
                          }}
                        />
                        <div
                          style={{
                            position: "fixed",
                            top: "150px",
                            left: "70%",
                            transform: "translate(-50%, 0)",
                            zIndex: 1000,
                            width: "100%",
                            height: "100vh",
                            padding: "1rem",
                            boxSizing: "border-box",
                          }}
                        >
                          <div style={{ position: "relative", height: "100%" }}>
                            <PopupChart
                              title="Operator Machine Proximity"
                              xAxisData={chartExample2.data}
                              dataset={chartExample2.options}
                              closePopup={setPopUpOperatorMachineProxy}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {/* //////////////////////// card 6 ////////////////////////// */}
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        Capacity Shortfall
                      </div>
                      <div style={{ fontSize: "1.5rem", color: "#1976D2" }}>
                        {AvgData}
                        {AvgData !== "No Data" ? "%" : ""}
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "100%",
                      }}
                    >
                      <img
                        src={timeLoss}
                        style={{
                          height: "2rem",
                          width: "2rem",
                          position: "absolute",
                          top: "10px",
                          right: "2.5rem",
                          cursor: "pointer",
                        }}
                        onClick={() => setPopUpCapacityShortfall(true)}
                        title="Trend Line"
                      />
                      <div
                        className="icon-plus"
                        style={{
                          position: "absolute", // Position the icon absolutely
                          top: "10px", // Align it to the top
                          right: 0, // Align it to the right of the image
                        }}
                      >
                        <NavLink className="anchor-bg" href="#">
                          {/* <FontAwesomeIcon icon={faArrowRightLong} /> */}
                        </NavLink>
                      </div>
                    </div>
                    {popUpCapacityShortfall && (
                      <>
                        <div
                          style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100vw",
                            height: "100vh",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            backdropFilter: "blur(3px)",
                            zIndex: 999,
                          }}
                        />
                        <div
                          style={{
                            position: "fixed",
                            top: "150px",
                            left: "70%",
                            transform: "translate(-50%, 0)",
                            zIndex: 1000,
                            width: "100%",
                            height: "100vh",
                            padding: "1rem",
                            boxSizing: "border-box",
                          }}
                        >
                          <div style={{ position: "relative", height: "100%" }}>
                            <PopupChart
                              title="Capacity Shortfall"
                              xAxisData={chartExample2.data}
                              dataset={chartExample2.options}
                              closePopup={setPopUpCapacityShortfall}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                {/* ////////////////////////////////////////////////// */}
                {/* <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                    gap: "0.8rem",
                    marginBottom: "0.6rem",
                    marginTop: "0.8rem",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        No Data
                      </div>
                      <div style={{ fontSize: "1.2rem", color: "#1976D2" }}>
                        No Data
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "5.5rem",
                        width: "3rem",
                      }}
                    >
                      <img
                      // src={runTime}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        No Data
                      </div>
                      <div style={{ fontSize: "1.2rem", color: "#1976D2" }}>
                      No Data
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "6rem",
                        width: "3rem",
                      }}
                    >
                      <img
                      // src={timeLoss}
                      />
                    </div>
                  </div>
                  <div
                    //  onClick={() => navigate('/admin/Workforce/productivity')}
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        No Data
                      </div>
                      <div style={{ fontSize: "1.2rem", color: "#1976D2" }}>
                      No Data
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        No Data
                      </div>
                      <div style={{ fontSize: "1.2rem", color: "#1976D2" }}>
                      No Data
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        No Data
                      </div>
                      <div style={{ fontSize: "1.2rem", color: "#1976D2" }}>
                      No Data
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#27293D",
                      color: "white",
                      height: "6rem",
                      width: "100%",
                      borderRadius: "0.8rem",
                      display: "grid",
                      gridTemplateColumns: "75% 25%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        marginLeft: "1rem",
                      }}
                    >
                      <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                        No Data
                      </div>
                      <div style={{ fontSize: "1.2rem", color: "#1976D2" }}>
                      No Data
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    ></div>
                  </div>
                </div> */}
              </div>
            </div>
          </CardBody>
        </Card>
        {/* ///////////////////////////// Table ///////////////////////////////////////// */}
        <Row>
          <Col lg="12">
            <TableComponent
              title={"WorkForce"}
              columns={columnsBreakDownTime}
              apiData={apiData}
            />
            {/* <WorkforceEfficiencyTable /> */}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default WorkforceHome;
