import React, { useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';

const MultipleBarTwoSideChart = (props) => {
  useEffect(() => {
    return () => {
    };
  }, []);
  const emphasisStyle = {
    itemStyle: {
      shadowBlur: 10,
      shadowColor: 'rgba(0,0,0,0.3)',
    },
  };
  const seriesData = props.barStyleData.map((datas) => {
    return {
      name: datas.barName,
      type: 'bar',
      stack: datas.barName,
      emphasis: emphasisStyle,
      data: datas.barData,
      color: datas.barColor
    }
  })
  const barName = props.barStyleData.map((data) => data.barName);

  const option = {
    title: {
      text: props.title,
      left: 'center',
      top: 0,
      textStyle: {
        color: 'white',
        fontSize: 16
      }
    },
    legend: {
      data: barName,
      left: '10%',
      top: 30,
      textStyle: {
        color: '#555865', 
      },
    },
    brush: {
      // toolbox: ['rect', 'polygon', 'lineX', 'lineY', 'keep', 'clear'],
      toolbox: ['clear'],
      xAxisIndex: 0,
    },
    toolbox: {
      feature: {
        magicType: {
          type: ['stack'],
        },
        // dataView: {},
      },
    },
    tooltip: {},
    xAxis: {
      data: props.xAxisData,
      name: 'X Axis',
      axisLine: { onZero: true },
      splitLine: { show: false },
      splitArea: { show: false },
    },
    yAxis: {},
    grid: {
      bottom: 100,
    },
    series: seriesData
  };

  const onBrushSelected = (params) => {
    const brushed = [];
    const brushComponent = params.batch[0];
    for (let sIdx = 0; sIdx < brushComponent.selected.length; sIdx++) {
      const rawIndices = brushComponent.selected[sIdx].dataIndex;
      brushed.push('[Series ' + sIdx + '] ' + rawIndices.join(', '));
    }
  };

  return (
    <ReactEcharts
      option={option}
      onEvents={{ brushSelected: onBrushSelected }}
      style={{ height: props.height, width: props.width }}
    />
  );
};

export default MultipleBarTwoSideChart;


