import React, { useState, useEffect } from "react";
import axios from "axios";
import  { APIURL } from './Apiurl.js';
import {
  TabContent,
  TabPane,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { Badge } from "reactstrap";
const { SearchBar } = Search;

function JobrolemasterAll() {
  const [activeTab, setActiveTab] = useState(1);
  const [employees, setEmployees] = useState([]);
  const [selectedTenantId, setSelectedTenantId] = useState(null);
  console.log(selectedTenantId);
  useEffect(() => {
    fetchData();
  }, []);
  
  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem('token');
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const roleId = decodedToken['sub'];
    const logintenantId = decodedToken['https://hasura.io/jwt/claims']['x-hasura-org-id'];
    const storedTenantId = sessionStorage.getItem('selectedTenantId');
      const response = await axios.post(APIURL, {
        query: `
        query MyQuery {
          tenant_administration {
            tenantid
            tenant_client_name
            account_manager
          }
        }
        `,
      },
      {
        headers: {
          'Content-Type': 'application/json',
           Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
        },
      });
      const tenantsWithSerial = response.data.data.tenant_administration.map(
        (tenant, index) => ({
          ...tenant,
          serialNo: index + 1, // Calculate serial number
        })
      );
      setEmployees(tenantsWithSerial);
      console.log(tenantsWithSerial);
    } catch (error) {
      console.error(error);
    }
  };

 
  function actionFormatter(cell, row) {
    const handleLoginClick = (tenantId) => {
      setSelectedTenantId(tenantId);
      sessionStorage.setItem('selectedTenantId', tenantId);
      window.location.href = "/admin/dashboard";
    };
  return (
    <div>
      <button
          className="btn btn-primary tim-icons"
          style={{
            background: "none",
          }}
          onClick={() => handleLoginClick(row.tenantid)}
        >
          Login
        </button>
    </div>
  );
}


  function handleEdit(row) {
    // Logic for handling the edit action
    console.log("Editing row:", row);
  }
  const activeFormatter = (cell, row) => {
    const isActive = row.is_active === "1";
  
    return (
      <Badge color={isActive ? "info" : "danger"}>
        {isActive ? "Active" : "Inactive"}
      </Badge>
    );
  };
  const columns = [
    {
      dataField: "serialNo",
      text: "ID",
    },
    {
      dataField: "tenantid",
      text: "Tenant ID",
    },
    {
      dataField: "tenant_client_name",
      text: "Tenant Client Name",
    },
    {
        dataField: "account_manager",
        text: "Tenant Account Manager",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: actionFormatter,
    },
  ];
  

  return (
    <div className="content">
      
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4" className="table-card-header">
                    Tenant Master
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={employees}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <div>
                        <div
                          className="float-right"
                          style={{
                            display: "flex",
                            marginBottom: "18px",
                            gap: "12px",
                          }}
                        >
                          <div className="float-left">
                            <SearchBar {...props.searchProps} />
                          </div>
                          <div className="float-right">
                          
                          </div>
                        </div>
                        <BootstrapTable
                          {...props.baseProps}
                          filter={filterFactory()}
                          pagination={paginationFactory()}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId={2}>
          {/* Content for Tab 2 */}
        </TabPane>
        <TabPane tabId={3}>
          {/* Content for Tab 3 */}
        </TabPane>
      </TabContent>
    </div>
  );
}

export default JobrolemasterAll;
