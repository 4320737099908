// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.day_chart {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-gap: 0.7rem;
  gap: 0.7rem;
  height: 95%;
}
.day_chart .day_chart_list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.day_chart .day_chart_list .first_main_text_style {
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-right: 1rem;
}
.day_chart .day_chart_list .main_text_style {
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.day_chart .day_chart_list .details_box {
  height: 4.5rem;
  width: 90%;
  border-radius: 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  color: white;
}
.day_chart .day_chart_list .details_box .percentage_style {
  font-size: 1.2rem;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/styles/charts/DayChart.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mCAAA;EACA,gBAAA;EAAA,WAAA;EACA,WAAA;AACJ;AACI;EACI,aAAA;EACA,0CAAA;AACR;AACQ;EACI,YAAA;EACA,iBAAA;EACA,iBAAA;EACA,aAAA;EACA,qBAAA;EACA,uBAAA;EACA,kBAAA;AACZ;AAEQ;EACI,YAAA;EACA,iBAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAZ;AAGQ;EACI,cAAA;EACA,UAAA;EACA,qBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,YAAA;AADZ;AAGY;EACI,iBAAA;EACA,gBAAA;AADhB","sourcesContent":[".day_chart {\n    display: grid;\n    grid-template-rows: 1fr 1fr 1fr 1fr;\n    gap: 0.7rem;\n    height: 95%;\n\n    .day_chart_list {\n        display: grid;\n        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;\n\n        .first_main_text_style {\n            color: white;\n            font-weight: bold;\n            font-size: 1.2rem;\n            display: flex;\n            align-items: flex-end;\n            justify-content: center;\n            margin-right: 1rem;\n        }\n\n        .main_text_style {\n            color: white;\n            font-weight: bold;\n            font-size: 1.2rem;\n            display: flex;\n            align-items: center;\n            justify-content: center;\n        }\n\n        .details_box {\n            height: 4.5rem;\n            width: 90%;\n            border-radius: 0.7rem;\n            display: flex;\n            flex-direction: column;\n            justify-content: center;\n            align-items: center;\n            flex-wrap: wrap;\n            color: white;\n\n            .percentage_style {\n                font-size: 1.2rem;\n                font-weight: 500;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
