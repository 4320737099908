import React, { useState, useEffect } from "react";
import axios from "axios";
import  { APIURL } from './Apiurl.js';
import {
  TabContent,
  TabPane,
  NavLink,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  
} from "reactstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";

const { SearchBar } = Search;

function UdpMasterAll() {
  const [activeTab, setActiveTab] = useState(1);
  const [machines, setMachines] = useState([]);

  useEffect(() => {
    // Fetch the machine data from the API
    axios
      .get(`${APIURL}/api/udp/allData`)
      .then((response) => {
        setMachines(response.data); // Set the fetched machine data in the state
      })
      .catch((error) => {
        console.error(error); // Handle the error
      });
  }, []);

  const toggleTab = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  function actionFormatter(cell, row) {
    return (
      <div>
        <Col
          className="font-icon-list col-xs-6 col-xs-6"
          lg="2"
          md="3"
          sm="4"
          onClick={() => handleEdit(row)}
        ></Col>
       <Link to={`/admin/udp/${row.udp_id}`}>
          <i className="btn btn-primary tim-icons icon-pencil" />
        </Link>
      </div>
    );
  }

  function handleEdit(row) {
    // Logic for handling the edit action
    console.log("Editing row:", row);
  }

  const columns = [
    {
      dataField: "udp_id",
      text: "ID",
    },
    {
      dataField: "device_profile",
      text: "Device Profile",
    },
    {
        dataField: "device_profile_type",
        text: "Device Profile Type",
    },
    {
        dataField: "parent_udp_id",
        text: "Parent UDP ID",
    },
    {
      dataField: "created_at",
      text: "Created At",
      formatter: (cell, row) => new Date(cell).toLocaleString(),
    },
    {
      dataField: "updated_at",
      text: "Updated At",
      formatter: (cell, row) => new Date(cell).toLocaleString(),
    },
    {
      dataField: "action",
      text: "Action",
      formatter: actionFormatter,
    },
  ];
  

  return (
    <div className="content">
      <h1>UDP All</h1>
      <Breadcrumb>
        <BreadcrumbItem>Home</BreadcrumbItem>
        <BreadcrumbItem active>UDP All</BreadcrumbItem>
      </Breadcrumb>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4" className="table-card-header">
                    Manage UDP
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={machines}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <div>
                        <div
                          className="float-right"
                          style={{
                            display: "flex",
                            marginBottom: "18px",
                            gap: "12px",
                          }}
                        >
                          <div className="float-left">
                            <SearchBar {...props.searchProps} />
                          </div>
                          <div className="float-right">
                            <NavLink
                              href="/admin/udp/udp-add"
                              className="btn btn-secondary"
                            >
                              <FontAwesomeIcon
                                icon={faPlus}
                                className="mr-2"
                              />
                              Add new UDP
                            </NavLink>
                          </div>
                        </div>
                        <BootstrapTable
                          {...props.baseProps}
                          filter={filterFactory()}
                          pagination={paginationFactory()}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId={2}>
          {/* Content for Tab 2 */}
        </TabPane>
        <TabPane tabId={3}>
          {/* Content for Tab 3 */}
        </TabPane>
      </TabContent>
    </div>
  );
}

export default UdpMasterAll;
