import React, { useState, useEffect } from "react";
import "./style.css";
import {
  Card,
  Row,
  Col,
  CardHeader,
  CardBody,
} from "reactstrap";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import redirectToLoginIfTokenMissing from "./TokenUtility";
import classnames from "classnames";
import axios from "axios";
import { APIURL } from "./Apiurl.js";
import WorkForcePerformance from "components/WorkForceAvailability/WorkForcePerformance";
import WorkForceByJobRole from "components/WorkForceAvailability/WorkforceAvailByJobRole";
import WorkforceAvailBySkill from "components/WorkForceAvailability/WorkforceAvailBySkill";
import WorkforceAvailByShift from "components/WorkForceAvailability/WorkforceAvailByShift";
import WorkforceAvailByInsights from "components/WorkForceAvailability/WorkforceAvailByInsights";
import ComboBarLineChart from "assets/charts/ComboBarLineChart";
import SmoothLineChart from "assets/charts/SmoothLineChart";
import ReactSpeedometer from "react-d3-speedometer";


function WorkForceAvailability(props) {
  const [activeTab, setActiveTab] = useState("1");
  const storedTenantId = sessionStorage.getItem("selectedTenantId");
  console.log("selected Tenant ID:", storedTenantId);
  useEffect(() => {
    redirectToLoginIfTokenMissing();
  }, []);
  const [org, setorg] = useState("");
  const [unit, setunit] = useState("");
  const [dept, setdept] = useState("");
  const [depts, setdepts] = useState([]);
  const [machine, setMachine] = useState("");
  const [machines, setMachines] = useState([]);
  const [plants, setplants] = useState([]);
  const [orgs, setorgs] = useState([]);
  const [otherData, setOtherData] = useState([]);
  const [selectedOrgData, setSelectedOrgData] = useState(null);

  const allValues = [
    { xAxisData: "Jan", lineData: 2.3, barData: 162000 },
    { xAxisData: "Mar", lineData: 6.3, barData: 302000 },
    { xAxisData: "May", lineData: 16.2, barData: 800000 },
    { xAxisData: "Jul", lineData: 22.8, barData: 1254000 },
    { xAxisData: "Sep", lineData: 14.5, barData: 950000 },
    { xAxisData: "Nov", lineData: 8.9, barData: 200000 },
  ]
  const title = ""
  const barChartName = "Month data"
  const lineChartName = "Average data"


  const xAxisData = ["JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  const dataset = [
    {
      label: 'data 1',
      data: [80, 100, 70, 80, 120, 80],
      borderColor: 'red',
    },
    {
      label: 'data 2',
      data: [100, 70, 50, 120, 50, 100],
      borderColor: 'blue',
    },
  ]


  const token = sessionStorage.getItem("token");
  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const tenantId =
    decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
  console.log(tenantId, "is a tenantId for cycle time");
  useEffect(() => {
    fetchplant();
  }, [org]);
  const fetchorg = async (logintenantId) => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery($logintenantId: Int!) {
          tnt_organization_structure(
            where: {
              parent_organization_id: { _eq: 0 },
              tenantid: { _eq: $logintenantId }
            }
          ) {
            organization_structure_id
            organization_node_name
          }
        }
      `,
          variables: {
            logintenantId: parseInt(logintenantId),
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );
      const fetchedOrgs = response.data.data.tnt_organization_structure;
      setorgs(fetchedOrgs);
      console.log(fetchedOrgs);
      const parentIds = fetchedOrgs.map((org) => org.organization_structure_id);
      console.log(parentIds);

      const childResponse = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery($parentIds: [Int!]) {
          tnt_organization_structure(
            where: {
              parent_organization_id: { _in: $parentIds }
            }
          ) {
            organization_structure_id
            organization_node_name
          }
        }
      `,
          variables: {
            parentIds: parentIds,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      const childOrgs = childResponse.data.data.tnt_organization_structure;
      console.log(childOrgs);
      const updatedOtherData = otherData.map((row) => {
        const matchingOrg = childOrgs.find(
          (org) => org.organization_structure_id === row.parent_organization_id
        );

        if (matchingOrg) {
          return {
            ...row,
            parent_organization_id: matchingOrg.organization_structure_id,
          };
        }

        return row;
      });

      setOtherData(childOrgs);
      console.log(otherData);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["sub"];
    let logintenantId = null;

    if (roleId == 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
    }

    fetchorg(logintenantId);
  }, []);
  const fetchplant = async () => {
    if (org) {
      // Only fetch if org is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
          query MyQuery($p: Int!) {
            tnt_organization_structure(where: {parent_organization_id: {_eq: $p}}) {
              organization_structure_id
              organization_node_name
            }
          }
        `,
            variables: {
              p: org,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        setplants(response.data.data.tnt_organization_structure);
        console.log(response.data.data.tnt_organization_structure);
      } catch (error) {
        console.error(error);
      }
    }
  };
  useEffect(() => {
    fetchdept();
  }, [unit]);
  const fetchdept = async () => {
    if (unit) {
      // Only fetch if org is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
          query MyQuery($d: Int!) {
            tnt_organization_structure(where: {parent_organization_id: {_eq: $d}}) {
              organization_structure_id
              organization_node_name
            }
          }
        `,
            variables: {
              d: unit,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        setdepts(response.data.data.tnt_organization_structure);
        console.log(response.data.data.tnt_organization_structure);
      } catch (error) {
        console.error(error);
      }
    }
  };
  console.log(process.env.REACT_APP_X_API_SECRET_KEY);

  useEffect(() => {
    fetchMachine();
  }, [dept]);
  const fetchMachine = async () => {
    if (dept) {
      // Only fetch if dept is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
          query MyQuery($m: Int!) {
           tnt_org_machine_assignment(where: {dept: {organization_structure_id: {_eq: $m}}} ) {
              machine_name
            }
          }
        `,
            variables: {
              m: dept,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        setMachines(response.data.data.tnt_org_machine_assignment || []);
        console.log(response.data.data.tnt_organization_structure);
      } catch (error) {
        console.error(error);
      }
    }
  };

  console.log(process.env.REACT_APP_X_API_SECRET_KEY);

  const handleOrgSelect = async (selectedOrgId) => {
    if (selectedOrgId) {
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
          query GetCostCenter($orgId: Int!) {
            tnt_organization_structure(where: { organization_structure_id: { _eq: $orgId } }) {
              cost_centre_code
            }
          }
        `,
            variables: {
              orgId: selectedOrgId,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );

        if (
          response.data &&
          response.data.data &&
          response.data.data.tnt_organization_structure &&
          response.data.data.tnt_organization_structure.length > 0
        ) {
          setSelectedOrgData(response.data.data.tnt_organization_structure[0]);
        } else {
          console.log(response);
          setSelectedOrgData(null);
        }
      } catch (error) {
        console.error("Error fetching organization data:", error);
      }
    } else {
      setSelectedOrgData(null);
    }

    setorg(selectedOrgId);
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const [bigChartData, setbigChartData] = React.useState("data1");
  const setBgChartData = (name) => {
    setbigChartData(name);
  };

  return (
    <>
      <div className="content">
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '20rem', marginBottom: '2rem' }}>
          <div style={{ width: '39%' }}>
            <Card>
              <CardHeader style={{ margin: '0', padding: '0' }}>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1rem' }}>
                  <h5 className="card-category">Production out of workforce availability</h5>
                </div>
              </CardHeader>
              <CardBody style={{ margin: '0', padding: '0' }}>
                <div style={{ height: '100%', width: '100%' }}>
                  <ComboBarLineChart data={allValues} title={title} barChartName={barChartName} lineChartName={lineChartName} BackgroundColor={'#27293D'} />
                </div>
              </CardBody>
            </Card>
          </div>
          <div style={{ width: '21%', display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
            <div style={{ backgroundColor: '#27293D', height: '53%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <p>Workforce Availability</p>
              <ReactSpeedometer
                value={68} // Change this to your desired value
                minValue={0}
                maxValue={100}
                width={250}
                height={130}
                needleColor="yellow" // Customize the needle color
                startColor="green" // Customize the start color of the gauge
                segments={5} // Number of segments on the gauge
                endColor="orange" // Customize the end color of the gauge
                textColor="white" // Text color
                ringWidth={20}
                currentValueText="${value} %" // Text shown in the center
              />
            </div>
            <div style={{ backgroundColor: '#27293D', height: '53%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <p>Avg. Production Achievement</p>
              <ReactSpeedometer
                value={35} // Change this to your desired value
                minValue={0}
                maxValue={100}
                width={250}
                height={130}
                needleColor="yellow" // Customize the needle color
                startColor="green" // Customize the start color of the gauge
                segments={5} // Number of segments on the gauge
                endColor="red" // Customize the end color of the gauge
                textColor="white" // Text color
                ringWidth={20}
                currentValueText="${value} %" // Text shown in the center
              />
            </div>
          </div>
          <div style={{ width: '39%' }}>
            {/* <div style={{ height: '100%', width: '100%', backgroundColor: '#27293D' }}>
              <SmoothLineChart xAxisData={xAxisData} dataset={dataset} height="21rem" width="30rem" />
            </div> */}
            <Card>
              <CardHeader style={{ margin: '0', padding: '0' }}>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1rem' }}>
                  <h5 className="card-category">Workforce Availability</h5>
                </div>
              </CardHeader>
              <CardBody style={{ margin: '0', padding: '0' }}>
                <div style={{ height: '100%', width: '100%' }}>
                  <SmoothLineChart xAxisData={xAxisData} dataset={dataset} height="19rem" width="30rem" />
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
        <Row>
          <Col lg="3">
            <Card className="Workforce-availability"
              style={{
                fontSize: "16px", fontWeight: "bold", color: "white", height: "40px", display: "flex",
                alignItems: "left",
                justifyContent: "center",
              }}
            >
              Workforce Availability
            </Card>
          </Col>
        </Row>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => toggleTab("1")}
              style={{
                backgroundColor:
                  activeTab === "1" ? "#5555e7" : "transparent",
                color: activeTab === "1" ? "white" : "white",
              }}
            >
              Workforce availability performance

            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => toggleTab("2")}
              style={{
                backgroundColor:
                  activeTab === "2" ? "#5555e7" : "transparent",
                color: activeTab === "2" ? "white" : "white",
              }}
            >
              Workforce availability by Job role
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => toggleTab("3")}
              style={{
                backgroundColor:
                  activeTab === "3" ? "#5555e7" : "transparent",
                color: activeTab === "3" ? "white" : "white",
              }}
            >
              Workforce Availability by Skill
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "4" })}
              onClick={() => toggleTab("4")}
              style={{
                backgroundColor:
                  activeTab === "4" ? "#5555e7" : "transparent",
                color: activeTab === "4" ? "white" : "white",
              }}
            >
              Shift wise Workforce Availability
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "5" })}
              onClick={() => toggleTab("5")}
              style={{
                backgroundColor:
                  activeTab === "5" ? "#5555e7" : "transparent",
                color: activeTab === "5" ? "white" : "white",
              }}
            >
              Workforce Availability Insights
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col>
                <WorkForcePerformance />
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="2">
            <Row>
              <Col>
                <WorkForceByJobRole />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col>
                <WorkforceAvailBySkill />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="4">
            <Row>
              <Col>
                <WorkforceAvailByShift />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="5">
            <Row>
              <Col>
                <WorkforceAvailByInsights />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </>
  );
}

export default WorkForceAvailability;
