import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Progress,
} from "reactstrap";
import axios from "axios";
import { APIURL } from "./Apiurl.js";
import { toast } from "react-toastify";

const EmployeeAdd = (props) => {
  const [activeStep, setActiveStep] = useState(1);
  const [checked, setChecked] = useState(false);
  const [progress, setProgress] = useState(0);
  const [tenantId, setTenantId] = useState("");
  const [employeecode, setemployeecode] = useState("");
  const [prefix, setprefix] = useState("");
  const [fname, setfname] = useState("");
  const [mname, setmname] = useState("");
  const [lname, setlname] = useState("");
  const [dob, setdob] = useState("");
  const [birthlocation, setbirthlocation] = useState("");
  const [birthcountry, setbirthcountry] = useState("");
  const [doj, setdoj] = useState("");
  const [certification, setcertification] = useState("");
  const [empcategory, setempcategory] = useState("");
  const [jobroleid, setjobroleid] = useState("");

  const [emptype, setemptype] = useState("");
  const [bloodgroup, setbloodgroup] = useState("");
  const [yearexperience, setyearexperience] = useState("");
  const [monthexperience, setmonthexperience] = useState("");
  const [roleid, setroleid] = useState("");
  const [primaryemp, setprimaryemp] = useState("");
  const [maritalstatus, setmaritalstatus] = useState("");
  const [createdby, setcreatedby] = useState("");
  const [contact, setcontact] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [rm, setrm] = useState(null);
  const [rolecat, setrolecat] = useState("");
  const [gender, setgender] = useState("");

  const [monthlyctc, setmonthlyctc] = useState("");
  const [EmployeePositon, setEmployeePositon] = useState("");

  const [modificationreason, setmodificationreason] = useState("");

  const [reportmanager, setreportmanager] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [orgcats, setOrgCats] = useState([]);
  const [orgTypes, setOrgTyes] = useState([]);
  const [prefixes, setprefixes] = useState([]);
  const [marital, setmarital] = useState([]);
  const [bgroups, setbgroups] = useState([]);

  const [org, setorg] = useState("");
  const [orgnames, setOrgnames] = useState([]);

  const [unit, setunit] = useState("");
  const [units, setunits] = useState([]);

  const [dept, setdept] = useState("");
  const [depts, setdepts] = useState([]);

  const [orgs, setOrgs] = useState([]);
  const [plants, setplants] = useState([]);
  const [empjobroles, setempjobroles] = useState([]);

  const [edu, setedu] = useState("");
  const [educations, seteducatons] = useState([]);

  const [secedu, setsecedu] = useState("");

  const [otherData, setOtherData] = useState([]);
  const [selectedgender, setSelectedgender] = useState(null);
  const handlegenderChange = (selectedOption) => {
    setSelectedgender(selectedOption);
  };
  useEffect(() => {
    fetchCats();
    fetchTypes();
    fetchJobRole();
    fetchprefix();
    fetchmaritalstatus();
    fetchbgroups();
    fetchorg();
    fetcheducation();
  }, [rolecat]);
  useEffect(() => {
    fetchEmployees();
    fetchplant();
  }, [org]);
  const fetchorg = async (logintenantId) => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
          query MyQuery($logintenantId: Int!) {
            tnt_organization_structure(
              where: {
                parent_organization_id: { _eq: 0 },
                tenantid: { _eq: $logintenantId }
              }
            ) {
              organization_structure_id
              organization_node_name
            }
          }
        `,
          variables: {
            logintenantId: parseInt(logintenantId),
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      const fetchedOrgs = response.data.data.tnt_organization_structure;
      setOrgs(fetchedOrgs);
      console.log(fetchedOrgs);
      const parentIds = fetchedOrgs.map((org) => org.organization_structure_id);
      console.log(parentIds);

      const childResponse = await axios.post(
        APIURL,
        {
          query: `
          query MyQuery($parentIds: [Int!]) {
            tnt_organization_structure(
              where: {
                parent_organization_id: { _in: $parentIds }
              }
            ) {
              organization_structure_id
              organization_node_name
            }
          }
        `,
          variables: {
            parentIds: parentIds,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      const childOrgs = childResponse.data.data.tnt_organization_structure;
      console.log(childOrgs);
      const updatedOtherData = otherData.map((row) => {
        const matchingOrg = childOrgs.find(
          (org) => org.organization_structure_id === row.parent_organization_id
        );

        if (matchingOrg) {
          return {
            ...row,
            parent_organization_id: matchingOrg.organization_structure_id,
          };
        }

        return row;
      });

      setOtherData(childOrgs);
      console.log(otherData);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["sub"];
    let logintenantId = null;

    if (roleId === 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
    }

    fetchorg(logintenantId);
  }, []);
  const fetchplant = async () => {
    if (org) {
      // Only fetch if org is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
            query MyQuery($p: Int!) {
              tnt_organization_structure(where: {parent_organization_id: {_eq: $p}}) {
                organization_structure_id
                organization_node_name
              }
            }
          `,
            variables: {
              p: org,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        // console.log(org);
        setplants(response.data.data.tnt_organization_structure);
        console.log(response.data.data.tnt_organization_structure);
      } catch (error) {
        console.error(error);
      }
    }
  };
  useEffect(() => {
    fetchdept();
  }, [unit]);
  const fetchdept = async () => {
    if (unit) {
      // Only fetch if org is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
            query MyQuery($d: Int!) {
              tnt_organization_structure(where: {parent_organization_id: {_eq: $d}}) {
                organization_structure_id
                organization_node_name
              }
            }
          `,
            variables: {
              d: unit,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        // console.log(org);
        setdepts(response.data.data.tnt_organization_structure);
        console.log(response.data.data.tnt_organization_structure);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const fetchCats = async () => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery {
          config_code_master(where: {code_type: {_eq: "Employee Category"}}) {
            code_id
            code_type
            code
          }
        }
        `,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      setOrgCats(response.data.data.config_code_master); // Make sure 'organization_category' is an array
    } catch (error) {
      console.error(error);
    }
  };
  const fetcheducation = async () => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery {
          config_code_master(where: {code_type: {_eq: "Education"}}) {
            code_id
            code_type
            code
          }
        }
        `,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      seteducatons(response.data.data.config_code_master); // Make sure 'organization_category' is an array
    } catch (error) {
      console.error(error);
    }
  };
  const fetchTypes = async () => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery {
          config_code_master(where: {code_type: {_eq: "EmployeeType"}}) {
            code_id
            code_type
            code
          }
        }
        `,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      setOrgTyes(response.data.data.config_code_master);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchJobRole = async () => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
        query GetJobRoleMaster($jr: String!)  {
          job_role_master(where: {job_role_category: {_eq: $jr}}) {
            job_role
            job_id
          }
        }
        `,
          variables: {
            jr: rolecat,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      setempjobroles(response.data.data.job_role_master);
      console.log(response.data.data.job_role_master);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchEmployees = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const roleId = decodedToken["sub"];
      let logintenantId = null;
      if (roleId === 1) {
        logintenantId = sessionStorage.getItem("selectedTenantId");
      } else {
        logintenantId =
          decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
      }
      console.log(logintenantId);

      console.log(org);
      const response = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery($logintenantId: Int!, $orgid: Int!) {
            tenant_employees(
              where: {
                tenantid: {_eq: $logintenantId}
                organization_id: {_eq: $orgid}
              }
            ) {
                employee_id
                first_name
                middle_name
                last_name
                employee_code
            }
          }
        `,
          variables: {
            logintenantId: logintenantId,
            orgid: org,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      setreportmanager(response.data.data.tenant_employees); // Make sure 'organization_category' is an array
    } catch (error) {
      console.error(error);
    }
  };
  const fetchprefix = async () => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery {
          config_code_master(where: {code_type: {_eq: "Prefix / Salutation"}}) {
            code_id
            code_type
            code
          }
        }
        `,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      setprefixes(response.data.data.config_code_master); // Make sure 'organization_category' is an array
    } catch (error) {
      console.error(error);
    }
  };
  const fetchmaritalstatus = async () => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery {
          config_code_master(where: {code_type: {_eq: "Marital Status"}}) {
            code
            code_id
          }
        }
        `,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      setmarital(response.data.data.config_code_master); // Make sure 'organization_category' is an array
    } catch (error) {
      console.error(error);
    }
  };
  const fetchbgroups = async () => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery {
          config_code_master(where: {code_type: {_eq: "Blood Group"}}) {
            code
            code_id
          }
        }
        `,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      setbgroups(response.data.data.config_code_master); // Make sure 'organization_category' is an array
    } catch (error) {
      console.error(error);
    }
  };

  const handleNext = () => {
    if (activeStep === 1 && progress === 0) {
      setProgress(50);
    } else if (activeStep === 2 && progress === 50) {
      setProgress(100);
    }
    setActiveStep(activeStep + 1);
  };

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };
  const token = sessionStorage.getItem("token");
  const handleFormSubmit = (event) => {
    event.preventDefault();
    const token = sessionStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["sub"];
    // const logintenantId = decodedToken['https://hasura.io/jwt/claims']['x-hasura-org-id'];
    let logintenantId = null;
    if (roleId === 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
    }
    console.log(jobroleid);
    const formData = {
      tenantid: logintenantId,
      employee_code: employeecode,
      job_role_id: jobroleid,
      role_id: 3,
      prefix: prefix,
      first_name: fname,
      middle_name: mname,
      last_name: lname,
      dob: dob,
      birth_location: birthlocation,
      country: birthcountry,
      doj: doj,
      organization_id: org,
      plant: unit,
      department: dept,
      certification: certification,
      emp_position: EmployeePositon,
      monthly_ctc: monthlyctc,
      education: edu,
      secondary_education: secedu,
      employee_category: empcategory,
      employee_type: emptype,
      blood_group: bloodgroup,
      gender: gender,
      total_exp_yr: yearexperience,
      total_exp_mn: monthexperience,
      reporting_manager_id: rm,
      marital_status: maritalstatus,
      created_by: logintenantId,
      contact_number: contact,
      password: password,

      is_active: "1",
    };
    const userFormData = {
      name: formData.first_name + " " + formData.last_name,
      email: email,
      contact: formData.contact_number,
      password: formData.password,
      tenantid: logintenantId,
      employee_code: employeecode,
      role_id: 3,
    };
    console.log(formData);
    axios
      .post(
        APIURL,
        {
          query: `
            mutation InsertTenantEmployees($formData: tenant_employees_insert_input!) {
              insert_tenant_employees(objects: [$formData]) {
                affected_rows
                }
            }
        `,
          variables: {
            formData: formData,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      )
      .then((response) => {
        console.log(response.data); // Handle the response data as needed
        if (
          response.data.data &&
          response.data.data.insert_tenant_employees.affected_rows > 0
        ) {
          // First mutation (insert into tenant_employees) was successful
          // Now, send the second mutation (insert into users)
          axios
            .post(
              APIURL,
              {
                query: `
                  mutation InsertUser($userFormData: users_insert_input!) {
                    insert_users(objects: [$userFormData]) {
                      affected_rows
                    }
                  }
                `,
                variables: {
                  userFormData: userFormData,
                },
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((userResponse) => {
              console.log(userResponse.data); // Handle the response data as needed

              if (
                userResponse.data.data &&
                userResponse.data.data.insert_users.affected_rows > 0
              ) {
                // Second mutation (insert into users) was successful
                console.log("inserted");
                // Reset form fields and perform other actions as needed
                // ...
                setprefix('');
                setfname('');
                setmname('');
                setlname('');
                setgender('');
                setdob('');
                setdoj('');
                setempcategory('');
                setemptype('');
                setprimaryemp('');
                setorg('');
                setunit('');
                setdept('');
                setrolecat('');
                setjobroleid('');
                setrm('');
                setcertification('');
                setedu('');
                setsecedu('');
                setmaritalstatus('');
                setEmployeePositon('');
                setmonthlyctc('');
                setyearexperience('');
                setmonthexperience('');
                setbloodgroup('');
                setbirthlocation('');
                setemail('');
                setbirthcountry('');
                setemployeecode('');
                setcontact('');
                setpassword('');

                toast.success("Employees Created successfully!");
                props.onInsertSuccess();
              } else {
                // Handle insertion failure for the second mutation
                toast.error("Failed to insert user data.");
              }
            })
            .catch((userError) => {
              console.error(userError); // Handle the error for the second mutation
              toast.error("An error occurred while inserting user data.");
            });
        } else {
          // Handle insertion failure for the first mutation
          toast.error("Failed to insert employee data.");
        }
      })
      .catch((error) => {
        console.error(error); // Handle the error for the first mutation
        toast.error("An error occurred while inserting employee data.");
      });
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case 1:
        return (
          <>
            <Row>
              <Col className="pl-md-1" md="6">
                <FormGroup>
                  <label>Prefix</label>
                  <Input
                    type="select"
                    id="orgtype"
                    value={prefix}
                    onChange={(e) => setprefix(e.target.value)}
                  >
                    <option value="">Select Prefix</option>
                    {prefixes.map((prf) => (
                      <option key={prf.code_id} value={prf.code_id}>
                        {prf.code}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>First Name</label>
                  <Input
                    placeholder="First Name"
                    type="text"
                    value={fname}
                    onChange={(e) => setfname(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="px-md-1" md="6">
                <FormGroup>
                  <label>Middle Name</label>
                  <Input
                    placeholder="Middle Name"
                    type="text"
                    value={mname}
                    onChange={(e) => setmname(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="pl-md-1" md="6">
                <FormGroup>
                  <label>Last Name</label>
                  <Input
                    placeholder="Last Name"
                    type="text"
                    value={lname}
                    onChange={(e) => setlname(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="gender">Select Gender</Label>
                  <Input
                    type="select"
                    id="gender"
                    value={gender}
                    onChange={(e) => setgender(e.target.value)}
                  >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>DOB</label>
                  <Input
                    placeholder="DOB"
                    type="date"
                    value={dob}
                    onChange={(e) => setdob(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pl-md-1" md="6">
                <FormGroup>
                  <label>DOJ</label>
                  <Input
                    placeholder="DOJ"
                    type="date"
                    value={doj}
                    onChange={(e) => setdoj(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="pl-md-1" md="6">
                <FormGroup>
                  <label>Employee Category</label>
                  <Input
                    type="select"
                    id="tenantId"
                    value={empcategory}
                    onChange={(e) => setempcategory(e.target.value)}
                  >
                    <option value="">Select Category</option>
                    {orgcats.map((orgcat) => (
                      <option key={orgcat.code_id} value={orgcat.code_id}>
                        {orgcat.code}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>Employee Type</label>
                  <Input
                    type="select"
                    id="tenantId"
                    value={emptype}
                    onChange={(e) => setemptype(e.target.value)}
                  >
                    <option value="">Select Type</option>
                    {orgTypes.map((emptype) => (
                      <option key={emptype.code_id} value={emptype.code_id}>
                        {emptype.code}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col className="pl-md-1" md="6">
                <FormGroup>
                  <label>Primary Employer</label>
                  <Input
                    placeholder="Primary Employer"
                    type="text"
                    value={primaryemp}
                    onChange={(e) => setprimaryemp(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        );
      case 2:
        return (
          <>
            <Row>
              <Col className="pr-md-1" md="4">
                <FormGroup>
                  <label>Organization</label>
                  <Input
                    type="select"
                    value={org}
                    onChange={(e) => setorg(e.target.value)}
                  >
                    <option value="">Select Organization</option>
                    {otherData.map((org) => (
                      <option
                        key={org.organization_structure_id}
                        value={org.organization_structure_id}
                      >
                        {org.organization_node_name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col className="pr-md-1" md="4">
                <FormGroup>
                  <label>Plant/Unit</label>
                  <Input
                    type="select"
                    value={unit}
                    onChange={(e) => setunit(e.target.value)}
                  >
                    <option value="">Select Unit/Plant</option>
                    {plants.map((plant) => (
                      <option
                        key={plant.organization_structure_id}
                        value={plant.organization_structure_id}
                      >
                        {plant.organization_node_name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="4">
                <FormGroup>
                  <Label for="Department">Department</Label>
                  <Input
                    type="select"
                    value={dept}
                    onChange={(e) => setdept(e.target.value)}
                  >
                    <option value="">Select department</option>
                    {depts.map((dpt) => (
                      <option
                        key={dpt.organization_structure_id}
                        value={dpt.organization_structure_id}
                      >
                        {dpt.organization_node_name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="tenantId">Select Job Role Category</Label>
                  <Input
                    type="select"
                    id="tenantId"
                    value={rolecat}
                    onChange={(e) => setrolecat(e.target.value)}
                  >
                    <option value="">Select Job Category</option>
                    <option value="Automation Technician">
                      Automation Technician
                    </option>
                    <option value="Mechatronics Engineers">
                      Mechatronics Engineers
                    </option>
                    <option value="Assembler">Assembler</option>
                    <option value="Welder">Welder</option>
                    <option value="Machinist / Operator">
                      Machinist / Operator
                    </option>
                    <option value="Production">Production</option>
                    <option value="Quality Assurance & Control">
                      Quality Assurance & Control
                    </option>
                    <option value="Production Support">
                      Production Support
                    </option>
                    <option value="Maintenance">Maintenance</option>
                    <option value="Management">Management</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="tenantId">Select Job Role</Label>
                  <Input
                    type="select"
                    id="tenantId"
                    value={jobroleid} // The selected value should be stored in the jobroleid state
                    onChange={(e) => setjobroleid(e.target.value)} // Update the jobroleid state when the selection changes
                  >
                    <option value="">Select Job Role</option>
                    {empjobroles.map((empjobs) => (
                      <option key={empjobs.job_id} value={empjobs.job_id}>
                        {empjobs.job_role}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              {/* <Col className="pr-md-1" md="4">
              <FormGroup>
                <label>Role Id</label>
                <Input 
                placeholder="Set Role Id" 
                type="text"
                value={roleid}
                onChange={(e) => setroleid(e.target.value)}
                />
              </FormGroup>
            </Col> */}
            </Row>
            <Row>
              <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="tenantId">Reporting Manager</Label>
                  <Input
                    type="select"
                    id="employeeId"
                    value={rm}
                    onChange={(e) => setrm(e.target.value)}
                  >
                    <option value="">Select Employee</option>
                    {reportmanager.map((emp) => (
                      <option key={emp.employee_id} value={emp.employee_id}>
                        {emp.first_name} {emp.last_name} ({emp.employee_code})
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>Certification</label>
                  <Input
                    placeholder="Certification"
                    type="text"
                    value={certification}
                    onChange={(e) => setcertification(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="px-md-1" md="6">
                <FormGroup>
                  <Label for="tenantId">Education</Label>
                  <Input
                    type="select"
                    id="employeeId"
                    value={edu}
                    onChange={(e) => setedu(e.target.value)}
                  >
                    <option value="">Select Education</option>
                    {educations.map((ed) => (
                      <option key={ed.code_id} value={ed.code_id}>
                        {ed.code}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>Trade/ Brach</label>
                  <Input
                    placeholder="Please Mention your branch"
                    type="text"
                    value={secedu}
                    onChange={(e) => setsecedu(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        );
      case 3:
        return (
          <>
            <Row>
              <Col className="pr-md-1" md="4">
                <FormGroup>
                  <label>Marital Status</label>
                  <Input
                    type="select"
                    id="orgtype"
                    value={maritalstatus}
                    onChange={(e) => setmaritalstatus(e.target.value)}
                  >
                    <option value="">Select Status</option>
                    {marital.map((mr) => (
                      <option key={mr.code_id} value={mr.code_id}>
                        {mr.code}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col className="pl-md-1" md="4">
                <FormGroup>
                  <label>Employee Position</label>
                  <Input
                    placeholder="Enter Employee Position"
                    type="text"
                    value={EmployeePositon}
                    onChange={(e) => setEmployeePositon(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="pl-md-1" md="4">
                <FormGroup>
                  <label>Monthly CTC</label>
                  <Input
                    placeholder="Enter Monthly CTC"
                    type="text"
                    value={monthlyctc}
                    onChange={(e) => setmonthlyctc(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pr-md-1" md="4">
                <FormGroup>
                  <label>Experience (Years)</label>
                  <Input
                    placeholder="Experience (Years)"
                    type="select"
                    value={yearexperience}
                    onChange={(e) => setyearexperience(e.target.value)}
                  >
                    <option value="">Select year of Experience</option>
                    {Array.from({ length: 51 }, (_, index) => (
                      <option key={index} value={index}>
                        {index}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col className="pr-md-1" md="4">
                <FormGroup>
                  <label>Experience (Months)</label>
                  <Input
                    placeholder="Experience (Months)"
                    type="select"
                    value={monthexperience}
                    onChange={(e) => setmonthexperience(e.target.value)}
                  >
                    <option value="">Select month of Experience</option>
                    {Array.from({ length: 12 }, (_, index) => (
                      <option key={index} value={index}>
                        {index}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col className="pl-md-1" md="4">
                <FormGroup>
                  <label>Blood Group</label>
                  <Input
                    type="select"
                    id="orgtype"
                    value={bloodgroup}
                    onChange={(e) => setbloodgroup(e.target.value)}
                  >
                    <option value="">Select bloodgroup</option>
                    {bgroups.map((bg) => (
                      <option key={bg.code_id} value={bg.code_id}>
                        {bg.code}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col className="pl-md-1" md="6">
                <FormGroup>
                  <label>Birth Location</label>
                  <Input
                    placeholder="Birth Location"
                    type="text"
                    value={birthlocation}
                    onChange={(e) => setbirthlocation(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>Email ID</label>
                  <Input
                    placeholder="Enter Email ID"
                    type="text"
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>Country of Birth</label>
                  <Input
                    type="select"
                    id="orgtype"
                    value={birthcountry}
                    onChange={(e) => setbirthcountry(e.target.value)}
                  >
                    <option value="">Select Country</option>
                    <option value="1">India</option>
                    <option value="2">USA</option>
                    <option value="3">England</option>
                    <option value="4">Bangladesh</option>
                    <option value="5">Shrilanka</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>Employee Code</label>
                  <Input
                    placeholder="Employee Code"
                    type="text"
                    value={employeecode}
                    onChange={(e) => setemployeecode(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pl-md-1" md="6">
                <FormGroup>
                  <label>Contact No</label>
                  <Input
                    placeholder="Contact No"
                    type="text"
                    value={contact}
                    onChange={(e) => setcontact(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="pl-md-1" md="6">
                <FormGroup>
                  <label>Password</label>
                  <Input
                    placeholder="Enter Password"
                    type="password"
                    value={password}
                    onChange={(e) => setpassword(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {/* <Col className="pr-md-1" md="6">
              <FormGroup>
                <label>Modification Reason</label>
                <Input
                  placeholder="Modification Reason"
                  type="text"
                  value={modificationreason}
                onChange={(e) => setmodificationreason(e.target.value)}
                />
              </FormGroup>
            </Col> */}
            </Row>
          </>
        );
      default:
        return null;
    }
  };
  const calculateProgress = () => {
    return Math.round(((activeStep - 1) / 3) * 100); // Update the denominator to match the total number of steps minus one
  };

  const renderProgressBarDestinations = () => {
    const step1Class = activeStep >= 1 ? "progress-destination-active" : "";
    const step2Class = activeStep >= 2 ? "progress-destination-active" : "";
    const step3Class = activeStep >= 3 ? "progress-destination-active" : "";
    const step4Class = activeStep >= 4 ? "progress-destination-active" : "";

    return (
      <div className="progress-destinations">
        <div className={`progress-destination ${step1Class}`}>
          <span>1</span>
        </div>

        <div className="progress-heading">Emp details</div>
        <div className={`progress-destination ${step2Class}`}>
          <span>2</span>
        </div>
        <div className="progress-heading">Emp Job Details</div>
        <div className={`progress-destination ${step3Class}`}>
          <span>3</span>
        </div>
        <div className="progress-heading">Other Details</div>
        <div className={`progress-destination ${step4Class}`}>
          <span>4</span>
        </div>
      </div>
    );
  };
  return (
    <div className="content">
      <Row>
        <Col md="8">
          <div className="progress-container">
            {renderProgressBarDestinations()}
            <Progress
              max="100"
              value={calculateProgress()}
              className="progress-bar-sm progress-bar-striped"
            />
          </div>
        </Col>
      </Row>
      <Row className="progress-row">
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Add New Employee</h5>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleFormSubmit}>{renderStepContent()}</Form>
            </CardBody>
            <CardFooter>
              <div className="text-right">
                {activeStep > 1 && (
                  <Button color="primary" onClick={handlePrevious}>
                    Previous
                  </Button>
                )}
                {activeStep < 3 && (
                  <Button color="primary" onClick={handleNext}>
                    Next
                  </Button>
                )}
                {activeStep === 3 && (
                  <Button color="success" onClick={handleFormSubmit}>
                    Submit
                  </Button>
                )}
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default EmployeeAdd;
