import React, { useState, useEffect, useRef } from "react";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import redirectToLoginIfTokenMissing from "views/TokenUtility";
import axios from "axios";
import { APIURL } from "views/Apiurl";
import WorkforceByMachine from "./WorkforceAailByMachine";
import WorkforcebyTopPerform from "./WorkForcebyTopPerform";
import WorkforcebyBottomPerform from "./WorkForcebyBottomPerform";
import ByTopManegerSuperwisor from "./TopManSuperwiser";
import ByBottomManegerSuperwisor from "./BottomManSuperwisor";
import MultipleBarTwoSideChart from "assets/charts/MultipleBarTwoSideChart";
import SmoothLineChart from "assets/charts/SmoothLineChart";
import PieChart from "assets/charts/PieChart";
import ColorLineChart from "assets/charts/ColorLineChart";
import DayChart from "assets/charts/DayChart";

function WorkForceEfficiencyLevel3(props) {
  const [activeTab, setActiveTab] = useState("1");
  const storedTenantId = sessionStorage.getItem("selectedTenantId");
  useEffect(() => {
    redirectToLoginIfTokenMissing(); // Call the utility function to validate the token
  }, []);
  const [org, setorg] = useState("");
  const [unit, setunit] = useState("");
  const [dept, setdept] = useState("");
  const [depts, setdepts] = useState([]);
  const [machine, setMachine] = useState("");
  const [machines, setMachines] = useState([]);
  const [plants, setplants] = useState([]);
  const [orgs, setorgs] = useState([]);
  const [otherData, setOtherData] = useState([]);
  const [selectedOrgData, setSelectedOrgData] = useState(null);
  const [apiData, setApiData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);
  const title = "Utilization Insight-Workforce Performance";
  const xAxisData = ["org1", "org2", "org3", "org4", "org5"];
  const barStyleData = [
    {
      barName: "first bar",
      barColor: "#8187E9",
      barData: [50, 25, 40, 65, 50],
    },
    {
      barName: "second bar",
      barColor: "#A4EDB8",
      barData: [20, -20, -30, 20, 10],
    },
    {
      barName: "third bar",
      barColor: "#F39D54",
      barData: [30, 40, 40, 20, 10],
    },
  ];

  const titleNew = "Utilization Insight";
  const xAxisDataNew = [
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const dataset = [
    {
      label: "data 1",
      data: [80, 100, 70, 80, 120, 80, 40, 100, 70, 80, 120, 80],
      borderColor: "#8087E8",
    },
    {
      label: "data 2",
      data: [100, 70, 50, 120, 50, 100, 100, 70, 50, 120, 50, 130],
      borderColor: "#647E7C",
    },
  ];

  const componentName = "User Form";
  const dataList = [
    { value: 60, name: "Operator", color: "#8187E9" },
    { value: 10, name: "Floor Manager", color: "#A3EDBA" },
    { value: 15, name: "Setters", color: "#F19D54" },
    { value: 5, name: "Helpers", color: "#6799A2" },
    { value: 10, name: "Supervisors", color: "#E1D369" },
  ];
  const chartRefData = useRef(null);
  PieChart(chartRefData, componentName, dataList);

  const YaxisDataSeriesOne = [
    {
      name: "User List",
      data: [
        140, 232, 101, 264, 90, 340, 250, 140, 232, 101, 264, 90, 340, 250,
      ],
      color: "#383757",
    },
  ];
  const YaxisDataSeriesTwo = [
    {
      name: "User List",
      data: [
        140, 232, 101, 264, 90, 340, 250, 140, 232, 101, 264, 90, 340, 250,
      ],
      color: "#5A706E",
    },
  ];
  const YaxisDataSeriesThree = [
    {
      name: "User List",
      data: [
        140, 232, 101, 264, 90, 340, 250, 140, 232, 101, 264, 90, 340, 250,
      ],
      color: "#745550",
    },
  ];
  const xAxisSeries = [
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
    "Sun",
  ];

  const token = sessionStorage.getItem("token");
  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const tenantId =
    decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
  useEffect(() => {
    fetchplant();
  }, [org]);
  const fetchorg = async (logintenantId) => {
    try {
      const response = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery($logintenantId: Int!) {
          tnt_organization_structure(
            where: {
              parent_organization_id: { _eq: 0 },
              tenantid: { _eq: $logintenantId }
            }
          ) {
            organization_structure_id
            organization_node_name
          }
        }
      `,
          variables: {
            logintenantId: parseInt(logintenantId),
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );
      const fetchedOrgs = response.data.data.tnt_organization_structure;
      setorgs(fetchedOrgs);
      const parentIds = fetchedOrgs.map((org) => org.organization_structure_id);

      const childResponse = await axios.post(
        APIURL,
        {
          query: `
        query MyQuery($parentIds: [Int!]) {
          tnt_organization_structure(
            where: {
              parent_organization_id: { _in: $parentIds }
            }
          ) {
            organization_structure_id
            organization_node_name
          }
        }
      `,
          variables: {
            parentIds: parentIds,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
          },
        }
      );

      const childOrgs = childResponse.data.data.tnt_organization_structure;
      const updatedOtherData = otherData.map((row) => {
        const matchingOrg = childOrgs.find(
          (org) => org.organization_structure_id === row.parent_organization_id
        );

        if (matchingOrg) {
          return {
            ...row,
            parent_organization_id: matchingOrg.organization_structure_id,
          };
        }

        return row;
      });

      setOtherData(childOrgs);
    } catch (error) {}
  };
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const roleId = decodedToken["sub"];
    let logintenantId = null;

    if (roleId == 1) {
      logintenantId = sessionStorage.getItem("selectedTenantId");
    } else {
      logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
    }

    fetchorg(logintenantId);
  }, []);
  const fetchplant = async () => {
    if (org) {
      // Only fetch if org is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
          query MyQuery($p: Int!) {
            tnt_organization_structure(where: {parent_organization_id: {_eq: $p}}) {
              organization_structure_id
              organization_node_name
            }
          }
        `,
            variables: {
              p: org,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        setplants(response.data.data.tnt_organization_structure);
      } catch (error) {}
    }
  };
  useEffect(() => {
    fetchdept();
  }, [unit]);
  const fetchdept = async () => {
    if (unit) {
      // Only fetch if org is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
          query MyQuery($d: Int!) {
            tnt_organization_structure(where: {parent_organization_id: {_eq: $d}}) {
              organization_structure_id
              organization_node_name
            }
          }
        `,
            variables: {
              d: unit,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        setdepts(response.data.data.tnt_organization_structure);
      } catch (error) {}
    }
  };

  useEffect(() => {
    fetchMachine();
  }, [dept]);
  const fetchMachine = async () => {
    if (dept) {
      // Only fetch if dept is selected
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
          query MyQuery($m: Int!) {
           tnt_org_machine_assignment(where: {dept: {organization_structure_id: {_eq: $m}}} ) {
              machine_name
            }
          }
        `,
            variables: {
              m: dept,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );
        setMachines(response.data.data.tnt_org_machine_assignment || []);
      } catch (error) {}
    }
  };

  const handleOrgSelect = async (selectedOrgId) => {
    if (selectedOrgId) {
      try {
        const response = await axios.post(
          APIURL,
          {
            query: `
          query GetCostCenter($orgId: Int!) {
            tnt_organization_structure(where: { organization_structure_id: { _eq: $orgId } }) {
              cost_centre_code
            }
          }
        `,
            variables: {
              orgId: selectedOrgId,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your Hasura admin secret
            },
          }
        );

        if (
          response.data &&
          response.data.data &&
          response.data.data.tnt_organization_structure &&
          response.data.data.tnt_organization_structure.length > 0
        ) {
          setSelectedOrgData(response.data.data.tnt_organization_structure[0]);
        } else {
          setSelectedOrgData(null);
        }
      } catch (error) {}
    } else {
      setSelectedOrgData(null);
    }

    setorg(selectedOrgId);
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const [bigChartData, setbigChartData] = React.useState("data1");
  const setBgChartData = (name) => {
    setbigChartData(name);
  };

  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const roleId = decodedToken["sub"];
      const tenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-tenant-id"];
      const storedTenantId = sessionStorage.getItem("selectedTenantId");
      let variables = {};
      if (roleId === 1) {
        if (storedTenantId !== null) {
          variables = { tenantId: storedTenantId };
        }
      } else {
        variables = { tenantId: tenantId };
      }
      let query = `
      query MyQuery {
        dm_foundation {
          actual_cycletime
          actual_production_time
          all_available_time
          asset_utilization_percent
          availability_loss_percent
          availability_loss_time
          batch_id
          capacity_utilized_percent
          cycletime_deviation
          date
          department_id
          edge_id
          machine_availability_percent
          machine_id
          machine_idle_time
          machine_performance_percent
          machine_up_time
          net_available_time
          no_of_parts_rejected
          org_id
          parts_per_minute
          planned_downtime_percent
          planned_production_time
          shift_id
          target_parts
          tenant_id
          time_between_job_parts
          timestamp
          total_machine_downtime
          total_machine_runtime
          total_parts_produced
          total_planned_downtime
          total_production_per_batch
          unit_id
          unplanned_downtime
        }
      }`;
      const response = await axios.post(
        APIURL,
        {
          query,
          variables,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-hasura-admin-secret": process.env.REACT_APP_X_API_SECRET_KEY,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setApiData(response.data.data.dm_foundation);
    } catch (error) {}
  };

  const datalistValue = [
    {
      shift: "shift 1",
      percentage: 20,
    },
    {
      shift: "shift 2",
      percentage: 60,
    },
    {
      shift: "shift 3",
      percentage: 88,
    },
    {
      shift: "shift 4",
      percentage: 90,
    },
    {
      shift: "shift 5",
      percentage: 97,
    },
    {
      shift: "shift 6",
      percentage: 55,
    },
    {
      shift: "shift 7",
      percentage: 80,
    },
    {
      shift: "shift 8",
      percentage: 98,
    },
    {
      shift: "shift 9",
      percentage: 99,
    },
    {
      shift: "shift 10",
      percentage: 30,
    },
    {
      shift: "shift 11",
      percentage: 25,
    },
    {
      shift: "shift 12",
      percentage: 66,
    },
  ];

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="3">
            <Card
              style={{ fontSize: "16px", fontWeight: "bold", color: "white" }}
            >
              Workforce Efficiency Analysis – Level 1
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="4">
            <Card className="card-chart">
              <CardHeader style={{ margin: "0", padding: "0" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "1rem",
                  }}
                >
                  <h5 className="card-category">
                    Utilization Insight-Workforce Performance
                  </h5>
                </div>
              </CardHeader>
              <CardBody>
                <MultipleBarTwoSideChart
                  xAxisData={xAxisData}
                  height="400px"
                  width="100%"
                  barStyleData={barStyleData}
                />
                {/* <MultipleBarTwoSideChart  xAxisData={xAxisData} height='400px' width='100%' barStyleData={barStyleData} /> */}
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card className="card-chart">
              <CardHeader style={{ margin: "0", padding: "0" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "1rem",
                  }}
                >
                  <h5 className="card-category">
                    Workforce Utilization Rate %
                  </h5>
                </div>
              </CardHeader>
              <CardBody>
                <SmoothLineChart
                  titleNew={titleNew}
                  xAxisData={xAxisDataNew}
                  dataset={dataset}
                  height="25rem"
                  width="100%"
                />
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card className="card-chart" style={{ height: "97%" }}>
              <CardBody>
                <DayChart shiftPercentageList={datalistValue} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="4">
            <Card className="card-chart">
              <CardHeader style={{ margin: "0", padding: "0" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "1rem",
                  }}
                >
                  <h5 className="card-category">
                    Utilization Insight-job role-based Utilization
                  </h5>
                </div>
              </CardHeader>
              <CardBody>
                <div
                  ref={chartRefData}
                  style={{ width: "100%", height: "350px" }}
                />
              </CardBody>
            </Card>
          </Col>
          <Col lg="5">
            <Card className="card-workforce">
              <CardHeader>
                <h5 className="card-category">Machine Wise Utilization</h5>
              </CardHeader>
            </Card>
            <Card className="card-chart">
              <CardBody>
                <div className="workforce-by-machine">
                  <WorkforceByMachine />
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col lg="3">
            <Card>
              <CardHeader> Utilization % </CardHeader>
              <CardBody>
                <ColorLineChart
                  xAxisData={xAxisSeries}
                  YaxisData={YaxisDataSeriesOne}
                  height="15rem"
                  width="100%"
                />
              </CardBody>
            </Card>
            <Card>
              <CardHeader> Production Outcomes </CardHeader>
              <CardBody>
                <ColorLineChart
                  xAxisData={xAxisSeries}
                  YaxisData={YaxisDataSeriesTwo}
                  height="15rem"
                  width="100%"
                />
              </CardBody>
            </Card>
            <Card>
              <CardHeader>Cycle Time Deviation </CardHeader>
              <CardBody>
                <ColorLineChart
                  xAxisData={xAxisSeries}
                  YaxisData={YaxisDataSeriesThree}
                  height="15rem"
                  width="100%"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row style={{ marginTop: "-32rem" }}>
          <Col lg="4">
            <Card className="card-workforce">
              <CardHeader>
                <h5 className="card-category">Top Performers</h5>
              </CardHeader>
            </Card>
            <Card className="card-chart">
              <CardBody>
                <div className="workforce-by-machine">
                  <WorkforcebyTopPerform />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card className="card-workforce">
              <CardHeader>
                <h5 className="card-category">Bottom Performers</h5>
              </CardHeader>
            </Card>
            <Card className="card-chart">
              <CardBody>
                <div className="workforce-by-machine">
                  <WorkforcebyBottomPerform />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="4">
            <Card className="card-workforce"></Card>
            <Card className="card-chart">
              <CardBody>
                <div className="workforce-by-machine">
                  <ByTopManegerSuperwisor />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card className="card-workforce"></Card>
            <Card className="card-chart">
              <CardBody>
                <div className="workforce-by-machine">
                  <ByBottomManegerSuperwisor />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row></Row>
      </div>
    </>
  );
}

export default WorkForceEfficiencyLevel3;
