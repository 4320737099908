import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "./Apiurl.js";
import {
  TabContent,
  TabPane,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import "./datatable.css";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "@material-ui/core/Modal";
import DashboardAdd from "./DashboardAdd";
import { Badge } from "reactstrap";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const { SearchBar } = Search;

function AllOrganization() {
  const [activeTab, setActiveTab] = useState(1);
  const [struct, setStruct] = useState([]);
  const [orgStruct, setOrgStruct] = useState([]);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true); // Open the modal
  };
  const [currentDashboard, setCurrentDashboard] = useState(null);
  const [isDefault, setIsDefault] = useState(
    currentDashboard ? currentDashboard.is_default === 1 : false
  );

  useEffect(() => {
    fetchData();
  }, []);
  const token = sessionStorage.getItem("token");
  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const roleId = decodedToken["sub"];
  let logintenantId = null;
  if (roleId == 1) {
    logintenantId = sessionStorage.getItem("selectedTenantId");
  } else {
    logintenantId =
      decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
  }
  const fetchData = async () => {
    try {
      const storedTenantId = sessionStorage.getItem("selectedTenantId");

      let query = "";
      let variables = {};

      if (roleId == 1) {
        if (storedTenantId !== null) {
          query = `
            query TntDashboardForAD($tenantId: Int!) {
              tenant_dashboard_master(where: { tenantid: { _eq: $tenantId } }) {
                  tenant_dashboard_master_id
                  created_at
                  dashboard_name
                  is_active
                  is_default
                  is_universal
                  purpose
                  tenantid
                  updated_at
              }
            }
          `;

          variables = {
            tenantId: storedTenantId,
          };
        } else {
          query = `
            query TntDashboard {
              tenant_dashboard_master {
                  tenant_dashboard_master_id  
                  created_at
                  dashboard_name
                  is_active
                  is_default
                  is_universal
                  purpose
                  tenantid
                  updated_at
               }
            }
            `;
        }
      } else {
        query = `
          query TntDashboardForT {
            tenant_dashboard_master {
                tenant_dashboard_master_id  
                created_at
                dashboard_name
                is_active
                is_default
                is_universal
                purpose
                tenantid
                updated_at
            }
          }
        `;
      }

      const response = await axios.post(
        APIURL,
        {
          query: query,
          variables: variables,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setOrgStruct(response.data.data.tenant_dashboard_master);
    } catch (error) {
      console.error(error);
    }
  };

  const activeFormatter = (cell, row) => {
    const isActive = row.is_active === "1";

    return (
      <Badge color={isActive ? "info" : "danger"}>
        {isActive ? "Active" : "Inactive"}
      </Badge>
    );
  };

  function handleEdit(row) {
    // Logic for handling the edit action
    console.log("Editing row:", row);
  }

  const handleToggleDefault = (row) => {
    // Find the index of the clicked dashboard
    const clickedDashboardIndex = orgStruct.findIndex(
      (dashboard) =>
        dashboard.tenant_dashboard_master_id === row.tenant_dashboard_master_id
    );

    // Check if the clicked dashboard is already set as "On" (1)
    if (row.is_default === 1) {
      // If it's already "On," no need to toggle
      return;
    }

    // Clone the array of dashboards and set is_default to 0 for all dashboards
    const updatedDashboards = [...orgStruct].map((dashboard, index) => ({
      ...dashboard,

      is_default: index === clickedDashboardIndex ? 1 : 0, // Set the clicked dashboard as "On"
      serialNo: index + 1,
    }));

    // Update the state with the modified dashboards
    setOrgStruct(updatedDashboards);

    // Call the GraphQL mutation to update the database with both mutations in a single operation
    axios
      .post(
        APIURL,
        {
          query: `
            mutation UpdateDashboard($dashboardId: Int!, $isDefault: Int!, $logintenantId: Int!) {
              updateDefault: update_tenant_dashboard_master(
                where: { is_default: { _eq: 1 }, tenantid: { _eq: $logintenantId } }
                _set: { is_default: 0 }
              ) {
                affected_rows
              }
              updateClicked: update_tenant_dashboard_master(
                where: { tenant_dashboard_master_id: { _eq: $dashboardId } }
                _set: { is_default: $isDefault }
              ) {
                affected_rows
              }
            }
          `,
          variables: {
            dashboardId: row.tenant_dashboard_master_id,
            isDefault: 1,
            logintenantId: logintenantId,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // Handle the response if needed
        if (response.data.data) {
          // Data updated successfully
        }
      })
      .catch((error) => {
        // Handle errors if the mutation fails
        console.error(error);
      });
  };

  const columns = [
    {
      dataField: "tenant_dashboard_master_id",
      text: "Dashboard ID",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dashboard_name",
      text: "Dashboard Name",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "purpose",
      text: "Purpose",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "is_default",
      text: "Is Default",
      formatter: (cell, row) => {
        return (
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id={`switch-${row.tenant_dashboard_master_id}`}
              checked={row.is_default === 1}
              onChange={() => handleToggleDefault(row)}
            />
            <label
              className="custom-control-label"
              htmlFor={`switch-${row.tenant_dashboard_master_id}`}
            >
              {/* {row.is_default === 1 ? "On" : "Off"} */}
            </label>
          </div>
        );
      },
      headerStyle: { width: "500px" },
    },

    {
      dataField: "is_active",
      text: "Status",
      formatter: activeFormatter,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: actionFormatter,
      headerStyle: { width: "500px" },
    },
  ];

  function actionFormatter(cell, row) {
    return (
      <div class="action-icons">
        {/* Add the eye icon and link */}
        <div
          onClick={() => {
            console.log("Row object:", row);
            sessionStorage.setItem(
              "selectedDashboardId",
              row.tenant_dashboard_master_id
            );
            sessionStorage.setItem("is_default", row.is_default);
            setCurrentDashboard(row);
            setIsDefault(row.is_default === 1);
          }}
        >
          <Link
            to={`/admin/custom-dashboard/${row.tenant_dashboard_master_id}`}
            style={{ marginLeft: "110px" }}
          >
            <FontAwesomeIcon
              icon={faEye}
              className="btn btn-primary tim-icons icon-pencil"
              style={{
                fontSize: "70%",
                background: "none",
                pointerEvents: "none",
              }}
            />
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="content">
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4" className="table-card-header">
                    Dashboards
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={struct}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <div>
                        <div
                          className="float-right"
                          style={{
                            display: "flex",
                            marginBottom: "18px",
                            gap: "12px",
                          }}
                        >
                          <div className="float-left">
                            <SearchBar {...props.searchProps} />
                          </div>
                          <div className="float-right">
                            <button
                              className="btn btn-secondary"
                              onClick={handleOpen}
                            >
                              Add new Dashboard
                            </button>
                          </div>
                        </div>
                        <BootstrapTable
                          {...props.baseProps}
                          keyField="id"
                          data={orgStruct}
                          columns={columns}
                          filter={filterFactory()}
                          pagination={paginationFactory({
                            bootstrap4: true,
                          })}
                          headerClasses="sorted-asc sorted-desc"
                          wrapperClasses="table-responsive"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
      <Modal
        onClose={handleClose}
        open={open}
        style={{
          boxShadow: "2px solid black",
          width: "100%",
          top: "15%",
          left: "15%",
        }}
      >
        <DashboardAdd />
      </Modal>
    </div>
  );
}

export default AllOrganization;
