import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "reactstrap";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import OeeFocus from "./OeeFocus";
import OeeAction from "./OeeAction";
import classnames from "classnames";
import OeeGrids from "./OeeGrids";
import { QueryApi } from "QueryApi";

function IfaAnalysis(props) {
  const [activeTab, setActiveTab] = useState("1");
  const [xAxisDataList, setXAxisDataList] = useState([]);
  const [datasetList, setDatasetList] = useState([]);
  useEffect(() => {
    fetchFocusData();
    fetchstackData();
  }, []);
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const currDay = day < 10 ? `0${day}` : day;
  const currMonth = month < 10 ? `0${month}` : month;
  let currentDate = JSON.stringify(`${year}-${currMonth}-${currDay}`);

  const fetchFocusData = async () => {
    try {
      const data = await QueryApi(`
          query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
           dm_foundation(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
                  oee
                  machineid
                  timestamp
              }
          }
        `);
      const machineName = data.dm_foundation.map((data) => data.machineid);
      const MachineNameSet = new Set(machineName);
      const machineNameArray = Array.from(MachineNameSet);
      const mainPlotData = machineNameArray.map((field) => {
        // const plotValue = [{ oee: 0, Xaxis: 0 }, { oee: 0, Xaxis: 0 }, { oee: 0, Xaxis: 0 }, { oee: 0, Xaxis: 0 }, { oee: 0, Xaxis: 0 }, { oee: 0, Xaxis: 0 }, { oee: 0, Xaxis: 0 }, { oee: 0, Xaxis: 0 }, { oee: 0, Xaxis: 0 }, { oee: 0, Xaxis: 0 }, { oee: 0, Xaxis: 0 }, { oee: 0, Xaxis: 0 }, { oee: 0, Xaxis: 0 }, { oee: 0, Xaxis: 0 }, { oee: 0, Xaxis: 0 }, { oee: 0, Xaxis: 0 }, { oee: 0, Xaxis: 0 }, { oee: 0, Xaxis: 0 }, { oee: 0, Xaxis: 0 }, { oee: 0, Xaxis: 0 }, { oee: 0, Xaxis: 0 }, { oee: 0, Xaxis: 0 }, { oee: 0, Xaxis: 0 }, { oee: 0, Xaxis: 0 }, { oee: 0, Xaxis: 0 }]
        const pointDate = new Date();
        const resPointDate = pointDate.getHours();
        let plotValue = [];
        for (var i = 0; i <= resPointDate; i++) {
          plotValue[i] = { oee: 0, Xaxis: 0 };
        }
        data.dm_foundation.map((data) => {
          if (data.machineid === field) {
            // plotValue.push({ oee: data.oee, Xaxis: data.timestamp })
            var d = new Date(data.timestamp);
            var h = d.getHours();
            if (h >= 0 && h <= 1) {
              plotValue[0] = { oee: data.oee, Xaxis: data.timestamp };
            } else if (h >= 1 && h <= 2) {
              plotValue[1] = { oee: data.oee, Xaxis: data.timestamp };
            } else if (h >= 2 && h <= 3) {
              plotValue[2] = { oee: data.oee, Xaxis: data.timestamp };
            } else if (h >= 3 && h <= 4) {
              plotValue[3] = { oee: data.oee, Xaxis: data.timestamp };
            } else if (h >= 4 && h <= 5) {
              plotValue[4] = { oee: data.oee, Xaxis: data.timestamp };
            } else if (h >= 5 && h <= 6) {
              plotValue[5] = { oee: data.oee, Xaxis: data.timestamp };
            } else if (h >= 6 && h <= 7) {
              plotValue[6] = { oee: data.oee, Xaxis: data.timestamp };
            } else if (h >= 7 && h <= 8) {
              plotValue[7] = { oee: data.oee, Xaxis: data.timestamp };
            } else if (h >= 8 && h <= 9) {
              plotValue[8] = { oee: data.oee, Xaxis: data.timestamp };
            } else if (h >= 9 && h <= 10) {
              plotValue[9] = { oee: data.oee, Xaxis: data.timestamp };
            } else if (h >= 10 && h <= 11) {
              plotValue[10] = { oee: data.oee, Xaxis: data.timestamp };
            } else if (h >= 11 && h <= 12) {
              plotValue[11] = { oee: data.oee, Xaxis: data.timestamp };
            } else if (h >= 12 && h <= 13) {
              plotValue[12] = { oee: data.oee, Xaxis: data.timestamp };
            } else if (h >= 13 && h <= 14) {
              plotValue[13] = { oee: data.oee, Xaxis: data.timestamp };
            } else if (h >= 14 && h <= 15) {
              plotValue[14] = { oee: data.oee, Xaxis: data.timestamp };
            } else if (h >= 15 && h <= 16) {
              plotValue[15] = { oee: data.oee, Xaxis: data.timestamp };
            } else if (h >= 16 && h <= 17) {
              plotValue[16] = { oee: data.oee, Xaxis: data.timestamp };
            } else if (h >= 17 && h <= 18) {
              plotValue[17] = { oee: data.oee, Xaxis: data.timestamp };
            } else if (h >= 18 && h <= 19) {
              plotValue[18] = { oee: data.oee, Xaxis: data.timestamp };
            } else if (h >= 19 && h <= 20) {
              plotValue[19] = { oee: data.oee, Xaxis: data.timestamp };
            } else if (h >= 20 && h <= 21) {
              plotValue[20] = { oee: data.oee, Xaxis: data.timestamp };
            } else if (h >= 21 && h <= 22) {
              plotValue[21] = { oee: data.oee, Xaxis: data.timestamp };
            } else if (h >= 22 && h <= 23) {
              plotValue[22] = { oee: data.oee, Xaxis: data.timestamp };
            } else if (h >= 23 && h <= 24) {
              plotValue[23] = { oee: data.oee, Xaxis: data.timestamp };
            } else {
              return null;
            }
          } else {
          }
        });
        return {
          label: field,
          data: plotValue,
          borderColor: "blue",
        };
      });
      const lastPlotValue = mainPlotData.map((data, index) => {
        return {
          label: data.label,
          data: data.data.map((newVal) => newVal.oee),
          borderColor: index === 0 ? "blue" : index === 1 ? "red" : "yellow",
        };
      });
      setXAxisDataList([
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24,
      ]);
      setDatasetList(lastPlotValue);
    } catch (error) {
      setXAxisDataList([]);
      setDatasetList([]);
    }
  };

  const [shiftData1, setShiftData1] = useState([]);
  const [shiftData2, setShiftData2] = useState([]);
  const [shiftData3, setShiftData3] = useState([]);

  const [xAxisDataStack, setXAxisDataStack] = useState([]);
  const barStyleDataStack = [
    {
      barName: "shift 1",
      barColor: "blue",
      barData: shiftData1,
    },
    {
      barName: "shift 2",
      barColor: "yellow",
      barData: shiftData2,
    },
    {
      barName: "shift 3",
      barColor: "green",
      barData: shiftData3,
    },
  ];

  const fetchstackData = async () => {
    try {
      const data = await QueryApi(`
    query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
      dm_machine_shiftwise(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
        machineid
        oee
        shift_id       
      }
    }
  `);
      const oneData = data.dm_machine_shiftwise.map((data) => {
        if (data.shift_id === "Shift_1") {
          return data.oee;
        } else {
          return 0;
        }
      });
      const newOneData = oneData.filter((data) => data !== 0);
      setShiftData1(newOneData);

      const twoData = data.dm_machine_shiftwise.map((data) => {
        if (data.shift_id === "Shift_2") {
          return data.oee;
        } else {
          return 0;
        }
      });
      const newtwoData = twoData.filter((data) => data !== 0);
      setShiftData2(newtwoData);

      const threeData = data.dm_machine_shiftwise.map((data) => {
        if (data.shift_id === "Shift_3") {
          return data.oee;
        } else {
          return 0;
        }
      });
      const newthreeData = threeData.filter((data) => data !== 0);
      setShiftData3(newthreeData);

      const machineNameList = data.dm_machine_shiftwise.map(
        (data) => data.machineid
      );
      const machineNameListSet = new Set(machineNameList);
      const machineNameListArr = Array.from(machineNameListSet);
      setXAxisDataStack(machineNameListArr);
    } catch (error) {
      setXAxisDataStack([]);
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="3">
            <Card
              className="Workforce-availability"
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                color: "white",
                height: "40px",
                display: "flex",
                alignItems: "left",
                justifyContent: "center",
              }}
            >
              OEE/OLE-Analysis
            </Card>
          </Col>
          <Col lg="9">
            <Nav
              tabs
              style={{
                display: "flex", // Use flex to create a horizontal layout
                justifyContent: "space-between", // Add space between the buttons
                width: "100%",
                borderBottom: "none",
              }}
            >
              <NavItem style={{ flex: 1, padding: "0 10px" }}>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => setActiveTab("1")}
                  style={{
                    width: "100%",
                    textAlign: "center",
                    backgroundColor:
                      activeTab === "1" ? "white" : "transparent",
                    color: activeTab === "1" ? "blue" : "white",
                    borderRadius: "5px", // Make the buttons rectangular
                    margin: "0px 20px", // Add space around each button
                    border: "1px solid #D3D3D3",
                  }}
                >
                  Information
                </NavLink>
              </NavItem>
              <NavItem style={{ flex: 1, padding: "0 10px" }}>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => setActiveTab("2")}
                  style={{
                    width: "100%",
                    textAlign: "center",
                    backgroundColor:
                      activeTab === "2" ? "white" : "transparent",
                    color: activeTab === "2" ? "blue" : "white",
                    borderRadius: "5px", // Make the buttons rectangular
                    margin: "0px 20px", // Add space around each button
                    border: "1px solid #D3D3D3",
                  }}
                >
                  Focus
                </NavLink>
              </NavItem>
              <NavItem style={{ flex: 1, padding: "0 10px" }}>
                <NavLink
                  className={classnames({ active: activeTab === "3" })}
                  onClick={() => setActiveTab("3")}
                  style={{
                    width: "100%",
                    textAlign: "center",
                    backgroundColor:
                      activeTab === "3" ? "white" : "transparent",
                    color: activeTab === "3" ? "blue" : "white",
                    borderRadius: "5px", // Make the buttons rectangular
                    margin: "0px 20px", // Add space around each button
                    border: "1px solid #D3D3D3",
                  }}
                >
                  Action
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col>
                <OeeGrids />
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="2">
            <Row>
              <Col>
                <OeeFocus
                  xAxisDataList={xAxisDataList}
                  datasetList={datasetList}
                  xAxisDataStack={xAxisDataStack}
                  barStyleDataStack={barStyleDataStack}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col>
                <OeeAction />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </>
  );
}

export default IfaAnalysis;
