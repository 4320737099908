import React, { useState, useEffect } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import redirectToLoginIfTokenMissing from "views/TokenUtility";
import classNames from "classnames";
import axios from "axios";
import ReactSpeedometer from "react-d3-speedometer";
import { APIURL } from "views/Apiurl";
import { QueryApi } from "QueryApi";
import LineChart from "assets/charts/LineChart";

function FocusPowerConsumption(props) {
  const [DowntimebigChartData, setDowntimebigChartData] =
    React.useState("data1");
  const [activeTab, setActiveTab] = useState("1");
  const storedTenantId = sessionStorage.getItem("selectedTenantId");
  useEffect(() => {
    redirectToLoginIfTokenMissing();
  }, []);
  const [apiData, setApiData] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const currDay = day < 10 ? `0${day}` : day;
  const currMonth = month < 10 ? `0${month}` : month;
  let currentDate = JSON.stringify(`${year}-${currMonth}-${currDay}`);

  const dateData = new Date();
  dateData.setDate(dateData.getDate() - 6);
  var dayDataValue = dateData.getDate();
  var monthDataValue = dateData.getMonth() + 1;
  var yearDataValue = dateData.getFullYear();
  var formattedDay = dayDataValue < 10 ? `0${dayDataValue}` : dayDataValue;
  var formattedMonth =
    monthDataValue < 10 ? `0${monthDataValue}` : monthDataValue;
  var weekDataDate = JSON.stringify(
    `${yearDataValue}-${formattedMonth}-${formattedDay}`
  );

  const previousDate = new Date();
  previousDate.setDate(1);
  const previousDay = previousDate.getDate();
  const previousMonth = previousDate.getMonth() + 1;
  const previousYear = previousDate.getFullYear();
  const formattedPreviousDay =
    previousDay < 10 ? `0${previousDay}` : previousDay;
  const formattedPreviousMonth =
    previousMonth < 10 ? `0${previousMonth}` : previousMonth;
  const previousMonthDate = JSON.stringify(
    `${previousYear}-${formattedPreviousMonth}-${formattedPreviousDay}`
  );

  const yearCurrentDate = new Date();
  yearCurrentDate.setDate(1);
  yearCurrentDate.setMonth(1);
  const yearDay = yearCurrentDate.getDate();
  const yearMonth = yearCurrentDate.getMonth();
  const yearYear = yearCurrentDate.getFullYear();
  const formattedYearDay = yearDay < 10 ? `0${yearDay}` : yearDay;
  const formattedYearMonth = yearMonth < 10 ? `0${yearMonth}` : yearMonth;
  const yearPreviousMonthDate = JSON.stringify(
    `${yearYear}-${formattedYearMonth}-${formattedYearDay}`
  );

  const token = sessionStorage.getItem("token");
  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const tenantId =
    decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
  const [bigChartData, setbigChartData] = React.useState("data1");
  const setBgChartData = (name) => {
    setbigChartData(name);
  };

  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const roleId = decodedToken["sub"];
      const tenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-tenant-id"];
      const storedTenantId = sessionStorage.getItem("selectedTenantId");
      let variables = {};
      if (roleId === 1) {
        if (storedTenantId !== null) {
          variables = { tenantId: storedTenantId };
        }
      } else {
        variables = { tenantId: tenantId };
      }
      let query = `
      query MyQuery {
        dm_foundation {
          actual_cycletime
          actual_production_time
          all_available_time
          asset_utilization_percent
          availability_loss_percent
          availability_loss_time
          batch_id
          capacity_utilized_percent
          cycletime_deviation
          date
          department_id
          edgeid
          machine_availability_percent
          machine_idle_time
          machine_performance_percent
          machine_up_time
          machineid
          net_available_time
          no_of_parts_rejected
          operator_id
          org_id
          parts_per_minute
          planned_downtime_percent
          planned_production_time
          plant_operating_time_percent
          quality_percent
          shift_id
          supervisor_id
          target_adherence
          target_parts
          tenantid
          time_between_job_parts
          month
          upt
          total_machine_runtime
          total_parts_produced
          total_planned_downtime
          total_production_per_batch
          unit_id
          unplanned_downtime
          org {
            organization_node_name
          }
          dept {
            organization_node_name
          }
        }
      }
      `;
      const response = await axios.post(
        APIURL,
        {
          query,
          variables,
        },
        {
          headers: {
            "Content-Type": "application/json",
            // "x-hasura-admin-secret": process.env.REACT_APP_X_API_SECRET_KEY,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setApiData(response.data.data.dm_foundation);
    } catch (error) {}
  };

  const [meterDataList, setMeterDataList] = useState();

  useEffect(() => {
    meterData();
  }, []);

  const meterData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const roleId = decodedToken["sub"];
      const tenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-tenant-id"];
      const storedTenantId = sessionStorage.getItem("selectedTenantId");
      let variables = {};
      if (roleId === 1) {
        if (storedTenantId !== null) {
          variables = { tenantId: storedTenantId };
        }
      } else {
        variables = { tenantId: tenantId };
      }
      let query = `
      query MyQuery($date: date = "", $start_date: date = "2024-01-20", $end_date: date = "2024-01-20") {
        dm_foundation_aggregate(where: {date: {_gte: $start_date, _lte: $end_date}}) {
          aggregate {
            avg {
              machine_availability_percent
              upt
              cycletime_deviation
            }
          }
        }
      }      
      `;
      const response = await axios.post(
        APIURL,
        {
          query,
          variables,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-hasura-admin-secret": process.env.REACT_APP_X_API_SECRET_KEY,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMeterDataList(
        response.data.data.dm_foundation_aggregate.aggregate.avg
      );
    } catch (error) {}
  };

  //// Downtime Trendline ///////////////////////////////////////

  const pointDate = new Date();
  const resPointDate = pointDate.getHours();
  ///////// OPP-1 /////////////////////
  const array=[0.1,0.2,0.3,0.4,0.5,0.6,0.7,0.8,0.9,1]
  const array1=[0.3,0.2,0.1,0.2,0.5,0.5,0.6,0.3,0.4,0.1,]
  const array3=[0.4,0.3,0.5,0.1,0.2,0.6]
  /////////// OPP-2 ////////////////////////
  const arrayOpp2=[0.6,0.4,0.2,0.5,0.1,0.3]
  const array1Opp2=[0.3,0.6,0.1,0.1,0.5,0.5]
  const array3Opp2=[0.4,0.1,0.5,0.3,0.2,0.6]

  const [productionMT, setProductionMT] = useState([]);
  const [deviationData, setDeviationData] = useState([]);
  const [uptData, setUptData] = useState([]);

  const [totalDownTime, setTotalDownTime] = useState([]);
  const [plantEfficiency, setPlantEfficiency] = useState([]);
  const [packedProduction, setPackedProduction] = useState([]);

  const [nonUsableWasteOutput, setNonUsableWasteOutput] = useState([]);
  const [totalUsableWasteGeneration, setTotalUsableWasteGeneration] = useState([]);
  const [totalUsablePer, setTotalUsablePer] = useState([]);

  const [extrudedMaterialOutput, setExtrudedMaterialOutput] = useState([]);
  const [totalVersionExtrusion, settotalVersionExtrusion] = useState([]);
  const [totalRecycledExtrusion, settotalRecycledExtrusion] = useState([]);


  const [
    xAxisDataValuePerformanceInsights,
    setXAxisDataValuePerformanceInsights,
  ] = useState([]);
  const [plotPointPerformanceInsights, setPlotPointPerformanceInsights] =
    useState([]);
  const datasetPerformanceInsights = [
    {
      label: "Production Mt",
      data: productionMT,
      borderColor: "#1F8EF1",
    },
    {
      label: "UPT",
      data: uptData,
      borderColor: "rgb(255, 99, 132)",
    },
    {
      label: "Deviation",
      data: deviationData,
      borderColor: "#ffd600",
    },
    
  ];
  const datasetGraph2 = [
    {
      label: "Total Down Time",
      data: totalDownTime,
      borderColor: "#1F8EF1",
    },
    {
      label: "Plant Efficiency %",
      data: plantEfficiency,
      borderColor: "rgb(255, 99, 132)",
    },
    {
      label: "Packed Production",
      data: packedProduction,
      borderColor: "#ffd600",
    },
    
  ];
  const datasetGraph3 = [
    // {
    //   label: "Non Usable Waste %",
    //   data: plotPointPerformanceInsights,
    //   borderColor: "#1F8EF1",
    // },
    {
      label: "Non Usable Waste Output Volume",
      data: nonUsableWasteOutput,
      borderColor: "rgb(255, 99, 132)",
    },
    {
      label: "Total Usable  Waste Generation Volume",
      data: totalUsableWasteGeneration,
      borderColor: "#1F8EF1",
    },
    {
      label: "Total Usable  Waste %",
      data: totalUsablePer,
      borderColor: "#ffd600",
    },
    
  ];
  const datasetGraph4 = [
    {
      label: "Extruded Material output volume",
      data: extrudedMaterialOutput,
      borderColor: "#1F8EF1",
    },
    {
      label: "Total Virgin Extrusion Volume",
      data: totalVersionExtrusion,
      borderColor: "rgb(255, 99, 132)",
    },
    {
      label: "Total Recycled Extrusion Volume",
      data: totalRecycledExtrusion,
      borderColor: "#ffd600",
    },
    
  ];

  useEffect(() => {
    GraphDataListPerformanceInsights();
  }, [DowntimebigChartData]);
  // const GraphDataListPerformanceInsights = async () => {
  //   if (DowntimebigChartData === "data1") {
  //     try {
  //       // Remove or comment out the API call
  //       // const data = await QueryApi(`
  //       // query MyQuery($tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
  //       //     corr_power_consumption(where: {tenantid: {_eq: $tenantid},department_id: {_eq: "83"}}) {
  //       //       Total__VirginExtrusion_Volume
  //       //       department_id
  //       //     }
  //       //   }
  //       //     `);
  
  //       setXAxisDataValuePerformanceInsights([
  //         "July", "Aug", "Sept", "Oct", "Nov", "Dec"
  //       ]);
  
  //       // Use predefined arrays instead of API data
  //       const plotAllDataPerformanceInsights = array.map((value, index) => {
  //         return {
  //           point: value,
  //           Xaxis: index // Assuming Xaxis as index for demonstration, modify as needed
  //         };
  //       });
  
  //       let plotValue = [];
  //       for (var i = 0; i <= resPointDate; i++) {
  //         plotValue[i] = array1[i] || 0; // Use array1 values or 0 if index is out of bounds
  //       }
  
  //       // If you need to manipulate the data further based on original logic, do it here
  //       plotAllDataPerformanceInsights.forEach((data) => {
  //         var d = new Date(data.Xaxis);
  //         var h = d.getHours();
  //       });
  
  //       setPlotPointPerformanceInsights(plotValue);
  //     } catch (error) {
  //       setXAxisDataValuePerformanceInsights([]);
  //       setPlotPointPerformanceInsights([]);
  //     }
  //   }
  //  else if (DowntimebigChartData === "data2") {
  //     try {
  //       const data = await QueryApi(`
  //             query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
  //                 dm_org_tenant_daily(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
  //                     upt
  //                     month
  //                 }
  //             }
  //           `);
  //       const plotAllDataPerformanceInsights = data.dm_org_tenant_daily.map(
  //         (data) => {
  //           const objData = {
  //             point: data.upt,
  //             Xaxis: data.month,
  //           };
  //           return objData;
  //         }
  //       );
  //       function getPlotAllDaysBetween(startDate, endDate) {
  //         var newDate = new Date(startDate);
  //         var weekDATE = new Date(endDate);
  //         var days = [];
  //         while (newDate <= weekDATE) {
  //           const dd = newDate.getDate();
  //           const mm = newDate.getMonth() + 1;
  //           const yy = newDate.getFullYear();
  //           const formattedd = dd < 10 ? `0${dd}` : dd;
  //           const formattedmm = mm < 10 ? `0${mm}` : mm;
  //           const fullDATE = `${yearYear}-${formattedmm}-${formattedd}`;
  //           days.push(fullDATE);
  //           newDate.setDate(newDate.getDate() + 1);
  //         }
  //         return days;
  //       }
  //       var daysBetween = getPlotAllDaysBetween(weekDataDate, currentDate);
  //       var pointArr = [];
  //       daysBetween.map((data) => {
  //         var arrlet = plotAllDataPerformanceInsights.map((data) => {
  //           var arrDate = new Date(data.Xaxis);
  //           let nday = arrDate.getUTCDate();
  //           let nmonth = arrDate.getMonth() + 1;
  //           let nyear = arrDate.getFullYear();
  //           const ncurrDay = nday < 10 ? `0${nday}` : nday;
  //           const ncurrMonth = nmonth < 10 ? `0${nmonth}` : nmonth;
  //           let nDateList = `${nyear}-${ncurrMonth}-${ncurrDay}`;
  //           return nDateList;
  //         });
  //         const res = arrlet.includes(data);
  //         const resindex = arrlet.indexOf(data);
  //         if (res === true) {
  //           pointArr.push(plotAllDataPerformanceInsights[resindex].point);
  //         } else {
  //           pointArr.push(0);
  //         }
  //       });
  //       setPlotPointPerformanceInsights(pointArr);

  //       function getAllDaysBetween(startDate, endDate) {
  //         var currentDate = new Date(startDate);
  //         var days = [];
  //         while (currentDate <= endDate) {
  //           days.push(new Date(currentDate));
  //           currentDate.setDate(currentDate.getDate() + 1);
  //         }
  //         return days;
  //       }
  //       function getDayOfWeek(date) {
  //         var days = [
  //           "Sunday",
  //           "Monday",
  //           "Tuesday",
  //           "Wednesday",
  //           "Thursday",
  //           "Friday",
  //           "Saturday",
  //         ];
  //         return days[date.getDay()];
  //       }
  //       function getDaysOfWeekBetween(startDate, endDate) {
  //         var daysBetween = getAllDaysBetween(startDate, endDate);
  //         var daysOfWeek = [];
  //         for (var i = 0; i < daysBetween.length; i++) {
  //           var dayOfWeek = getDayOfWeek(daysBetween[i]);
  //           daysOfWeek.push(dayOfWeek);
  //         }
  //         return daysOfWeek;
  //       }
  //       var daysOfWeekBetween = getDaysOfWeekBetween(
  //         new Date(weekDataDate),
  //         new Date(currentDate)
  //       );
  //       setXAxisDataValuePerformanceInsights(daysOfWeekBetween);
  //     } catch (error) {
  //       setXAxisDataValuePerformanceInsights([]);
  //       setPlotPointPerformanceInsights([]);
  //     }
  //   } else if (DowntimebigChartData === "data3") {
  //     try {
  //       const data = await QueryApi(`
  //             query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
  //                 dm_org_tenant_daily(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
  //                     upt
  //                     date
  //                 }
  //             }
  //           `);
  //       const plotAllDataPerformanceInsights = data.dm_org_tenant_daily.map(
  //         (data) => {
  //           const objData = {
  //             point: (data.upt / (1000 * 60 * 60)).toFixed(
  //               2
  //             ),
  //             Xaxis: data.date,
  //           };
  //           return objData;
  //         }
  //       );

  //       const bothAxisPoint = [];
  //       for (var i = 1; i <= 31; i++) {
  //         bothAxisPoint[i] = { pointValue: 0, XaxisValue: i };
  //       }
  //       plotAllDataPerformanceInsights.forEach((data) => {
  //         const allDate = new Date(data.Xaxis);
  //         const dayss = allDate.getDate();
  //         bothAxisPoint[dayss] = { pointValue: data.point, XaxisValue: dayss };
  //       });
  //       const NewPointData = bothAxisPoint.map((data) => data.pointValue);
  //       const dd = new Date();
  //       NewPointData.splice(dd.getDate() + 1, NewPointData.length);
  //       const NewXaxisData = bothAxisPoint.map((data) => data.XaxisValue);
  //       setPlotPointPerformanceInsights(NewPointData);
  //       setXAxisDataValuePerformanceInsights(NewXaxisData);
  //     } catch (error) {
  //       setXAxisDataValuePerformanceInsights([]);
  //       setPlotPointPerformanceInsights([]);
  //     }
  //   } else if (DowntimebigChartData === "data4") {
  //     try {
  //       const data = await QueryApi(`
  //             query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
  //                 dm_org_tenant_monthly(where: {start_date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
  //                     upt
  //                    start_date
  //               }
  //             }
  //         `);
  //       const plotAllDataInsights = data.dm_org_tenant_monthly.map((data) => {
  //         const objData = {
  //           point: data.upt,
  //           Xaxis: data.month,
  //         };
  //         return objData;
  //       });

  //       const plotPointDataInsights = plotAllDataInsights.map(
  //         (data) => data.point
  //       );
  //       setPlotPointPerformanceInsights(plotPointDataInsights);
  //       setXAxisDataValuePerformanceInsights([
  //         "January",
  //         "February",
  //         "March",
  //         "April",
  //         "May",
  //         "June",
  //         "July",
  //         "August",
  //         "September",
  //         "October",
  //         "November",
  //         "December",
  //       ]);
  //     } catch (error) {
  //       setXAxisDataValuePerformanceInsights([]);
  //       setPlotPointPerformanceInsights([]);
  //     }
  //   } else {
  //     setDowntimebigChartData("data1");
  //   }
  // };

  const GraphDataListPerformanceInsights = async () => {
    if (DowntimebigChartData === "data1") {
      try {
        const data = await QueryApi(`
        query MyQuery($tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
            corr_power_consumption(where: {tenantid: {_eq: $tenantid},department_id: {_eq: "83"}}) {
              Total__VirginExtrusion_Volume
              department_id
              deviation
              extruded_material_output_volume
              line_name
              month
              non_usable_waste_output_volume
              non_usable_waste_percent
              packed_production
              plant_efficiency__
              power_consumption
              production_mt
              tenantid
              total_downtime
              total_recycled_extrusion_volume
              total_usable_waste_generation_volume
              total_usable_waste_percent
              upt
            }
          }
            `);
        setXAxisDataValuePerformanceInsights([
         "July","Aug","Sept","Oct","Nov","Dec"
        ]);
        const plotAllDataPerformanceInsights = data.corr_power_consumption.map(
          (data) => {
            const objData = {
              point: (data.upt),
              Xaxis: data.month,
            };
            return objData;
          }
        );

      const productionMTData = data.corr_power_consumption.map(data => data.production_mt);
      const deviationDataList = data.corr_power_consumption.map(data => data.deviation);
      const uptDataList = data.corr_power_consumption.map(data => data.upt);

      const totalDownTimeList = data.corr_power_consumption.map(data => data.total_downtime);
      const plantEfficiencyList = data.corr_power_consumption.map(data => data.plant_efficiency__);
      const packedProductionList = data.corr_power_consumption.map(data => data.packed_production);

      const nonUsableWasteOutputList = data.corr_power_consumption.map(data => data.non_usable_waste_output_volume);
      const totalUsableWasteGenerationList = data.corr_power_consumption.map(data => data.total_usable_waste_generation_volume);
      const totalUsablePerList = data.corr_power_consumption.map(data => data.total_usable_waste_percent);

      const extrudedMaterialOutputList = data.corr_power_consumption.map(data => data.extruded_material_output_volume);
      const totalVersionExtrusionList = data.corr_power_consumption.map(data => data.Total__VirginExtrusion_Volume);
      const totalRecycledExtrusionList = data.corr_power_consumption.map(data => data.total_recycled_extrusion_volume);

      // Set the state variables
      setProductionMT(productionMTData);
      setDeviationData(deviationDataList);
      setUptData(uptDataList);
      setTotalDownTime(totalDownTimeList);
      setPlantEfficiency(plantEfficiencyList);
      setPackedProduction(packedProductionList);
      setNonUsableWasteOutput(nonUsableWasteOutputList);
      setTotalUsableWasteGeneration(totalUsableWasteGenerationList);
      setTotalUsablePer(totalUsablePerList);
      setExtrudedMaterialOutput(extrudedMaterialOutputList);
      settotalVersionExtrusion(totalVersionExtrusionList);
      settotalRecycledExtrusion(totalRecycledExtrusionList);

        let plotValue = [];
        for (var i = 0; i <= resPointDate; i++) {
          plotValue[i] = 0;
        }
        plotAllDataPerformanceInsights.forEach((data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
         
        });
        setPlotPointPerformanceInsights(plotValue);
      } catch (error) {
        setXAxisDataValuePerformanceInsights([]);
        setPlotPointPerformanceInsights([]);
      }
    } else if (DowntimebigChartData === "data2") {
      try {
        const data = await QueryApi(`
        query MyQuery($tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
            corr_power_consumption(where: {tenantid: {_eq: $tenantid},department_id: {_eq: "84"}}) {
              Total__VirginExtrusion_Volume
              department_id
              deviation
              extruded_material_output_volume
              line_name
              month
              non_usable_waste_output_volume
              non_usable_waste_percent
              packed_production
              plant_efficiency__
              power_consumption
              production_mt
              tenantid
              total_downtime
              total_recycled_extrusion_volume
              total_usable_waste_generation_volume
              total_usable_waste_percent
              upt
            }
          }
            `);
        setXAxisDataValuePerformanceInsights([
         "July","Aug","Sept","Oct","Nov","Dec"
        ]);
        const plotAllDataPerformanceInsights = data.corr_power_consumption.map(
          (data) => {
            const objData = {
              point: (data.upt),
              Xaxis: data.month,
            };
            return objData;
          }
        );

      const productionMTData = data.corr_power_consumption.map(data => data.production_mt);
      const deviationDataList = data.corr_power_consumption.map(data => data.deviation);
      const uptDataList = data.corr_power_consumption.map(data => data.upt);

      const totalDownTimeList = data.corr_power_consumption.map(data => data.total_downtime);
      const plantEfficiencyList = data.corr_power_consumption.map(data => data.plant_efficiency__);
      const packedProductionList = data.corr_power_consumption.map(data => data.packed_production);

      const nonUsableWasteOutputList = data.corr_power_consumption.map(data => data.non_usable_waste_output_volume);
      const totalUsableWasteGenerationList = data.corr_power_consumption.map(data => data.total_usable_waste_generation_volume);
      const totalUsablePerList = data.corr_power_consumption.map(data => data.total_usable_waste_percent);

      const extrudedMaterialOutputList = data.corr_power_consumption.map(data => data.extruded_material_output_volume);
      const totalVersionExtrusionList = data.corr_power_consumption.map(data => data.Total__VirginExtrusion_Volume);
      const totalRecycledExtrusionList = data.corr_power_consumption.map(data => data.total_recycled_extrusion_volume);

      // Set the state variables
      setProductionMT(productionMTData);
      setDeviationData(deviationDataList);
      setUptData(uptDataList);
      setTotalDownTime(totalDownTimeList);
      setPlantEfficiency(plantEfficiencyList);
      setPackedProduction(packedProductionList);
      setNonUsableWasteOutput(nonUsableWasteOutputList);
      setTotalUsableWasteGeneration(totalUsableWasteGenerationList);
      setTotalUsablePer(totalUsablePerList);
      setExtrudedMaterialOutput(extrudedMaterialOutputList);
      settotalVersionExtrusion(totalVersionExtrusionList);
      settotalRecycledExtrusion(totalRecycledExtrusionList);

        let plotValue = [];
        for (var i = 0; i <= resPointDate; i++) {
          plotValue[i] = 0;
        }
        plotAllDataPerformanceInsights.forEach((data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
         
        });
        setPlotPointPerformanceInsights(plotValue);
      } catch (error) {
        setXAxisDataValuePerformanceInsights([]);
        setPlotPointPerformanceInsights([]);
      }
    } else if (DowntimebigChartData === "data3") {
      try {
        const data = await QueryApi(`
        query MyQuery($tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
            corr_power_consumption(where: {tenantid: {_eq: $tenantid},department_id: {_eq: "86"}}) {
              Total__VirginExtrusion_Volume
              department_id
              deviation
              extruded_material_output_volume
              line_name
              month
              non_usable_waste_output_volume
              non_usable_waste_percent
              packed_production
              plant_efficiency__
              power_consumption
              production_mt
              tenantid
              total_downtime
              total_recycled_extrusion_volume
              total_usable_waste_generation_volume
              total_usable_waste_percent
              upt
            }
          }
            `);
        setXAxisDataValuePerformanceInsights([
         "July","Aug","Sept","Oct","Nov","Dec"
        ]);
        const plotAllDataPerformanceInsights = data.corr_power_consumption.map(
          (data) => {
            const objData = {
              point: (data.upt),
              Xaxis: data.month,
            };
            return objData;
          }
        );

      const productionMTData = data.corr_power_consumption.map(data => data.production_mt);
      const deviationDataList = data.corr_power_consumption.map(data => data.deviation);
      const uptDataList = data.corr_power_consumption.map(data => data.upt);

      const totalDownTimeList = data.corr_power_consumption.map(data => data.total_downtime);
      const plantEfficiencyList = data.corr_power_consumption.map(data => data.plant_efficiency__);
      const packedProductionList = data.corr_power_consumption.map(data => data.packed_production);

      const nonUsableWasteOutputList = data.corr_power_consumption.map(data => data.non_usable_waste_output_volume);
      const totalUsableWasteGenerationList = data.corr_power_consumption.map(data => data.total_usable_waste_generation_volume);
      const totalUsablePerList = data.corr_power_consumption.map(data => data.total_usable_waste_percent);

      const extrudedMaterialOutputList = data.corr_power_consumption.map(data => data.extruded_material_output_volume);
      const totalVersionExtrusionList = data.corr_power_consumption.map(data => data.Total__VirginExtrusion_Volume);
      const totalRecycledExtrusionList = data.corr_power_consumption.map(data => data.total_recycled_extrusion_volume);

      // Set the state variables
      setProductionMT(productionMTData);
      setDeviationData(deviationDataList);
      setUptData(uptDataList);
      setTotalDownTime(totalDownTimeList);
      setPlantEfficiency(plantEfficiencyList);
      setPackedProduction(packedProductionList);
      setNonUsableWasteOutput(nonUsableWasteOutputList);
      setTotalUsableWasteGeneration(totalUsableWasteGenerationList);
      setTotalUsablePer(totalUsablePerList);
      setExtrudedMaterialOutput(extrudedMaterialOutputList);
      settotalVersionExtrusion(totalVersionExtrusionList);
      settotalRecycledExtrusion(totalRecycledExtrusionList);

        let plotValue = [];
        for (var i = 0; i <= resPointDate; i++) {
          plotValue[i] = 0;
        }
        plotAllDataPerformanceInsights.forEach((data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
         
        });
        setPlotPointPerformanceInsights(plotValue);
      } catch (error) {
        setXAxisDataValuePerformanceInsights([]);
        setPlotPointPerformanceInsights([]);
      }
    } 
    else if (DowntimebigChartData === "data4") {
      try {
        const data = await QueryApi(`
        query MyQuery($tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
            corr_power_consumption(where: {tenantid: {_eq: $tenantid},department_id: {_eq: "87"}}) {
              Total__VirginExtrusion_Volume
              department_id
              deviation
              extruded_material_output_volume
              line_name
              month
              non_usable_waste_output_volume
              non_usable_waste_percent
              packed_production
              plant_efficiency__
              power_consumption
              production_mt
              tenantid
              total_downtime
              total_recycled_extrusion_volume
              total_usable_waste_generation_volume
              total_usable_waste_percent
              upt
            }
          }
            `);
        setXAxisDataValuePerformanceInsights([
         "July","Aug","Sept","Oct","Nov","Dec"
        ]);
        const plotAllDataPerformanceInsights = data.corr_power_consumption.map(
          (data) => {
            const objData = {
              point: (data.upt),
              Xaxis: data.month,
            };
            return objData;
          }
        );

      const productionMTData = data.corr_power_consumption.map(data => data.production_mt);
      const deviationDataList = data.corr_power_consumption.map(data => data.deviation);
      const uptDataList = data.corr_power_consumption.map(data => data.upt);

      const totalDownTimeList = data.corr_power_consumption.map(data => data.total_downtime);
      const plantEfficiencyList = data.corr_power_consumption.map(data => data.plant_efficiency__);
      const packedProductionList = data.corr_power_consumption.map(data => data.packed_production);

      const nonUsableWasteOutputList = data.corr_power_consumption.map(data => data.non_usable_waste_output_volume);
      const totalUsableWasteGenerationList = data.corr_power_consumption.map(data => data.total_usable_waste_generation_volume);
      const totalUsablePerList = data.corr_power_consumption.map(data => data.total_usable_waste_percent);

      const extrudedMaterialOutputList = data.corr_power_consumption.map(data => data.extruded_material_output_volume);
      const totalVersionExtrusionList = data.corr_power_consumption.map(data => data.Total__VirginExtrusion_Volume);
      const totalRecycledExtrusionList = data.corr_power_consumption.map(data => data.total_recycled_extrusion_volume);

      // Set the state variables
      setProductionMT(productionMTData);
      setDeviationData(deviationDataList);
      setUptData(uptDataList);
      setTotalDownTime(totalDownTimeList);
      setPlantEfficiency(plantEfficiencyList);
      setPackedProduction(packedProductionList);
      setNonUsableWasteOutput(nonUsableWasteOutputList);
      setTotalUsableWasteGeneration(totalUsableWasteGenerationList);
      setTotalUsablePer(totalUsablePerList);
      setExtrudedMaterialOutput(extrudedMaterialOutputList);
      settotalVersionExtrusion(totalVersionExtrusionList);
      settotalRecycledExtrusion(totalRecycledExtrusionList);

        let plotValue = [];
        for (var i = 0; i <= resPointDate; i++) {
          plotValue[i] = 0;
        }
        plotAllDataPerformanceInsights.forEach((data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
         
        });
        setPlotPointPerformanceInsights(plotValue);
      } catch (error) {
        setXAxisDataValuePerformanceInsights([]);
        setPlotPointPerformanceInsights([]);
      }
    } 
    else if (DowntimebigChartData === "data5") {
      try {
        const data = await QueryApi(`
        query MyQuery($tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
            corr_power_consumption(where: {tenantid: {_eq: $tenantid},department_id: {_eq: "88"}}) {
              Total__VirginExtrusion_Volume
              department_id
              deviation
              extruded_material_output_volume
              line_name
              month
              non_usable_waste_output_volume
              non_usable_waste_percent
              packed_production
              plant_efficiency__
              power_consumption
              production_mt
              tenantid
              total_downtime
              total_recycled_extrusion_volume
              total_usable_waste_generation_volume
              total_usable_waste_percent
              upt
            }
          }
            `);
        setXAxisDataValuePerformanceInsights([
         "July","Aug","Sept","Oct","Nov","Dec"
        ]);
        const plotAllDataPerformanceInsights = data.corr_power_consumption.map(
          (data) => {
            const objData = {
              point: (data.upt),
              Xaxis: data.month,
            };
            return objData;
          }
        );

      const productionMTData = data.corr_power_consumption.map(data => data.production_mt);
      const deviationDataList = data.corr_power_consumption.map(data => data.deviation);
      const uptDataList = data.corr_power_consumption.map(data => data.upt);

      const totalDownTimeList = data.corr_power_consumption.map(data => data.total_downtime);
      const plantEfficiencyList = data.corr_power_consumption.map(data => data.plant_efficiency__);
      const packedProductionList = data.corr_power_consumption.map(data => data.packed_production);

      const nonUsableWasteOutputList = data.corr_power_consumption.map(data => data.non_usable_waste_output_volume);
      const totalUsableWasteGenerationList = data.corr_power_consumption.map(data => data.total_usable_waste_generation_volume);
      const totalUsablePerList = data.corr_power_consumption.map(data => data.total_usable_waste_percent);

      const extrudedMaterialOutputList = data.corr_power_consumption.map(data => data.extruded_material_output_volume);
      const totalVersionExtrusionList = data.corr_power_consumption.map(data => data.Total__VirginExtrusion_Volume);
      const totalRecycledExtrusionList = data.corr_power_consumption.map(data => data.total_recycled_extrusion_volume);

      // Set the state variables
      setProductionMT(productionMTData);
      setDeviationData(deviationDataList);
      setUptData(uptDataList);
      setTotalDownTime(totalDownTimeList);
      setPlantEfficiency(plantEfficiencyList);
      setPackedProduction(packedProductionList);
      setNonUsableWasteOutput(nonUsableWasteOutputList);
      setTotalUsableWasteGeneration(totalUsableWasteGenerationList);
      setTotalUsablePer(totalUsablePerList);
      setExtrudedMaterialOutput(extrudedMaterialOutputList);
      settotalVersionExtrusion(totalVersionExtrusionList);
      settotalRecycledExtrusion(totalRecycledExtrusionList);

        let plotValue = [];
        for (var i = 0; i <= resPointDate; i++) {
          plotValue[i] = 0;
        }
        plotAllDataPerformanceInsights.forEach((data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
         
        });
        setPlotPointPerformanceInsights(plotValue);
      } catch (error) {
        setXAxisDataValuePerformanceInsights([]);
        setPlotPointPerformanceInsights([]);
      }
    } 
    else if (DowntimebigChartData === "data6") {
      try {
        const data = await QueryApi(`
        query MyQuery($tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
            corr_power_consumption(where: {tenantid: {_eq: $tenantid},department_id: {_eq: "89"}}) {
              Total__VirginExtrusion_Volume
              department_id
              deviation
              extruded_material_output_volume
              line_name
              month
              non_usable_waste_output_volume
              non_usable_waste_percent
              packed_production
              plant_efficiency__
              power_consumption
              production_mt
              tenantid
              total_downtime
              total_recycled_extrusion_volume
              total_usable_waste_generation_volume
              total_usable_waste_percent
              upt
            }
          }
            `);
        setXAxisDataValuePerformanceInsights([
         "July","Aug","Sept","Oct","Nov","Dec"
        ]);
        const plotAllDataPerformanceInsights = data.corr_power_consumption.map(
          (data) => {
            const objData = {
              point: (data.upt),
              Xaxis: data.month,
            };
            return objData;
          }
        );

      const productionMTData = data.corr_power_consumption.map(data => data.production_mt);
      const deviationDataList = data.corr_power_consumption.map(data => data.deviation);
      const uptDataList = data.corr_power_consumption.map(data => data.upt);

      const totalDownTimeList = data.corr_power_consumption.map(data => data.total_downtime);
      const plantEfficiencyList = data.corr_power_consumption.map(data => data.plant_efficiency__);
      const packedProductionList = data.corr_power_consumption.map(data => data.packed_production);

      const nonUsableWasteOutputList = data.corr_power_consumption.map(data => data.non_usable_waste_output_volume);
      const totalUsableWasteGenerationList = data.corr_power_consumption.map(data => data.total_usable_waste_generation_volume);
      const totalUsablePerList = data.corr_power_consumption.map(data => data.total_usable_waste_percent);

      const extrudedMaterialOutputList = data.corr_power_consumption.map(data => data.extruded_material_output_volume);
      const totalVersionExtrusionList = data.corr_power_consumption.map(data => data.Total__VirginExtrusion_Volume);
      const totalRecycledExtrusionList = data.corr_power_consumption.map(data => data.total_recycled_extrusion_volume);

      // Set the state variables
      setProductionMT(productionMTData);
      setDeviationData(deviationDataList);
      setUptData(uptDataList);
      setTotalDownTime(totalDownTimeList);
      setPlantEfficiency(plantEfficiencyList);
      setPackedProduction(packedProductionList);
      setNonUsableWasteOutput(nonUsableWasteOutputList);
      setTotalUsableWasteGeneration(totalUsableWasteGenerationList);
      setTotalUsablePer(totalUsablePerList);
      setExtrudedMaterialOutput(extrudedMaterialOutputList);
      settotalVersionExtrusion(totalVersionExtrusionList);
      settotalRecycledExtrusion(totalRecycledExtrusionList);

        let plotValue = [];
        for (var i = 0; i <= resPointDate; i++) {
          plotValue[i] = 0;
        }
        plotAllDataPerformanceInsights.forEach((data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
         
        });
        setPlotPointPerformanceInsights(plotValue);
      } catch (error) {
        setXAxisDataValuePerformanceInsights([]);
        setPlotPointPerformanceInsights([]);
      }
    } 
    else if (DowntimebigChartData === "data7") {
      try {
        const data = await QueryApi(`
        query MyQuery($tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
            corr_power_consumption(where: {tenantid: {_eq: $tenantid},department_id: {_eq: "90"}}) {
              Total__VirginExtrusion_Volume
              department_id
              deviation
              extruded_material_output_volume
              line_name
              month
              non_usable_waste_output_volume
              non_usable_waste_percent
              packed_production
              plant_efficiency__
              power_consumption
              production_mt
              tenantid
              total_downtime
              total_recycled_extrusion_volume
              total_usable_waste_generation_volume
              total_usable_waste_percent
              upt
            }
          }
            `);
        setXAxisDataValuePerformanceInsights([
         "July","Aug","Sept","Oct","Nov","Dec"
        ]);
        const plotAllDataPerformanceInsights = data.corr_power_consumption.map(
          (data) => {
            const objData = {
              point: (data.upt),
              Xaxis: data.month,
            };
            return objData;
          }
        );

      const productionMTData = data.corr_power_consumption.map(data => data.production_mt);
      const deviationDataList = data.corr_power_consumption.map(data => data.deviation);
      const uptDataList = data.corr_power_consumption.map(data => data.upt);

      const totalDownTimeList = data.corr_power_consumption.map(data => data.total_downtime);
      const plantEfficiencyList = data.corr_power_consumption.map(data => data.plant_efficiency__);
      const packedProductionList = data.corr_power_consumption.map(data => data.packed_production);

      const nonUsableWasteOutputList = data.corr_power_consumption.map(data => data.non_usable_waste_output_volume);
      const totalUsableWasteGenerationList = data.corr_power_consumption.map(data => data.total_usable_waste_generation_volume);
      const totalUsablePerList = data.corr_power_consumption.map(data => data.total_usable_waste_percent);

      const extrudedMaterialOutputList = data.corr_power_consumption.map(data => data.extruded_material_output_volume);
      const totalVersionExtrusionList = data.corr_power_consumption.map(data => data.Total__VirginExtrusion_Volume);
      const totalRecycledExtrusionList = data.corr_power_consumption.map(data => data.total_recycled_extrusion_volume);

      // Set the state variables
      setProductionMT(productionMTData);
      setDeviationData(deviationDataList);
      setUptData(uptDataList);
      setTotalDownTime(totalDownTimeList);
      setPlantEfficiency(plantEfficiencyList);
      setPackedProduction(packedProductionList);
      setNonUsableWasteOutput(nonUsableWasteOutputList);
      setTotalUsableWasteGeneration(totalUsableWasteGenerationList);
      setTotalUsablePer(totalUsablePerList);
      setExtrudedMaterialOutput(extrudedMaterialOutputList);
      settotalVersionExtrusion(totalVersionExtrusionList);
      settotalRecycledExtrusion(totalRecycledExtrusionList);

        let plotValue = [];
        for (var i = 0; i <= resPointDate; i++) {
          plotValue[i] = 0;
        }
        plotAllDataPerformanceInsights.forEach((data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
         
        });
        setPlotPointPerformanceInsights(plotValue);
      } catch (error) {
        setXAxisDataValuePerformanceInsights([]);
        setPlotPointPerformanceInsights([]);
      }
    } 
    else if (DowntimebigChartData === "data8") {
      try {
        const data = await QueryApi(`
        query MyQuery($tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
            corr_power_consumption(where: {tenantid: {_eq: $tenantid},department_id: {_eq: "91"}}) {
              Total__VirginExtrusion_Volume
              department_id
              deviation
              extruded_material_output_volume
              line_name
              month
              non_usable_waste_output_volume
              non_usable_waste_percent
              packed_production
              plant_efficiency__
              power_consumption
              production_mt
              tenantid
              total_downtime
              total_recycled_extrusion_volume
              total_usable_waste_generation_volume
              total_usable_waste_percent
              upt
            }
          }
            `);
        setXAxisDataValuePerformanceInsights([
         "July","Aug","Sept","Oct","Nov","Dec"
        ]);
        const plotAllDataPerformanceInsights = data.corr_power_consumption.map(
          (data) => {
            const objData = {
              point: (data.upt),
              Xaxis: data.month,
            };
            return objData;
          }
        );

      const productionMTData = data.corr_power_consumption.map(data => data.production_mt);
      const deviationDataList = data.corr_power_consumption.map(data => data.deviation);
      const uptDataList = data.corr_power_consumption.map(data => data.upt);

      const totalDownTimeList = data.corr_power_consumption.map(data => data.total_downtime);
      const plantEfficiencyList = data.corr_power_consumption.map(data => data.plant_efficiency__);
      const packedProductionList = data.corr_power_consumption.map(data => data.packed_production);

      const nonUsableWasteOutputList = data.corr_power_consumption.map(data => data.non_usable_waste_output_volume);
      const totalUsableWasteGenerationList = data.corr_power_consumption.map(data => data.total_usable_waste_generation_volume);
      const totalUsablePerList = data.corr_power_consumption.map(data => data.total_usable_waste_percent);

      const extrudedMaterialOutputList = data.corr_power_consumption.map(data => data.extruded_material_output_volume);
      const totalVersionExtrusionList = data.corr_power_consumption.map(data => data.Total__VirginExtrusion_Volume);
      const totalRecycledExtrusionList = data.corr_power_consumption.map(data => data.total_recycled_extrusion_volume);

      // Set the state variables
      setProductionMT(productionMTData);
      setDeviationData(deviationDataList);
      setUptData(uptDataList);
      setTotalDownTime(totalDownTimeList);
      setPlantEfficiency(plantEfficiencyList);
      setPackedProduction(packedProductionList);
      setNonUsableWasteOutput(nonUsableWasteOutputList);
      setTotalUsableWasteGeneration(totalUsableWasteGenerationList);
      setTotalUsablePer(totalUsablePerList);
      setExtrudedMaterialOutput(extrudedMaterialOutputList);
      settotalVersionExtrusion(totalVersionExtrusionList);
      settotalRecycledExtrusion(totalRecycledExtrusionList);

        let plotValue = [];
        for (var i = 0; i <= resPointDate; i++) {
          plotValue[i] = 0;
        }
        plotAllDataPerformanceInsights.forEach((data) => {
          var d = new Date(data.Xaxis);
          var h = d.getHours();
         
        });
        setPlotPointPerformanceInsights(plotValue);
      } catch (error) {
        setXAxisDataValuePerformanceInsights([]);
        setPlotPointPerformanceInsights([]);
      }
    } 
    else {
      setDowntimebigChartData("data1");
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col sm="12" lg="12">
            <Button
              tag="label"
              className={classNames("btn-simple", {
                active: DowntimebigChartData === "data1",
              })}
              color="info"
              id="0"
              size="lg"
              onClick={() => setDowntimebigChartData("data1")}
            >
              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                OPP-1
              </span>
            </Button>
            <Button
              color="info"
              id="1"
              size="lg"
              tag="label"
              className={classNames("btn-simple", {
                active: DowntimebigChartData === "data2",
              })}
              onClick={() => setDowntimebigChartData("data2")}
            >
              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                OPP-2
              </span>
              <span className="d-block d-sm-none">
                <i className="tim-icons icon-gift-2" />
              </span>
            </Button>
            <Button
              color="info"
              id="2"
              size="lg"
              tag="label"
              className={classNames("btn-simple", {
                active: DowntimebigChartData === "data3",
              })}
              onClick={() => setDowntimebigChartData("data3")}
            >
              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                OPP-3
              </span>
              <span className="d-block d-sm-none">
                <i className="tim-icons icon-tap-02" />
              </span>
            </Button>
            <Button
              color="info"
              id="2"
              size="lg"
              tag="label"
              className={classNames("btn-simple", {
                active: DowntimebigChartData === "data4",
              })}
              onClick={() => setDowntimebigChartData("data4")}
            >
              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                OPP-4
              </span>
              <span className="d-block d-sm-none">
                <i className="tim-icons icon-tap-02" />
              </span>
            </Button>
            <Button
              tag="label"
              className={classNames("btn-simple", {
                active: DowntimebigChartData === "data5",
              })}
              color="info"
              id="0"
              size="lg"
              onClick={() => setDowntimebigChartData("data5")}
            >
              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                OPP-5
              </span>
              <span className="d-block d-sm-none">
                <i className="tim-icons icon-single-02" />
              </span>
            </Button>
            <Button
              color="info"
              id="1"
              size="lg"
              tag="label"
              className={classNames("btn-simple", {
                active: DowntimebigChartData === "data6",
              })}
              onClick={() => setDowntimebigChartData("data6")}
            >
              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                OPP-6
              </span>
              <span className="d-block d-sm-none">
                <i className="tim-icons icon-gift-2" />
              </span>
            </Button>
            <Button
              color="info"
              id="2"
              size="lg"
              tag="label"
              className={classNames("btn-simple", {
                active: DowntimebigChartData === "data7",
              })}
              onClick={() => setDowntimebigChartData("data7")}
            >
              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                OPP-7
              </span>
              <span className="d-block d-sm-none">
                <i className="tim-icons icon-tap-02" />
              </span>
            </Button>
            <Button
              color="info"
              id="2"
              size="lg"
              tag="label"
              className={classNames("btn-simple", {
                active: DowntimebigChartData === "data8",
              })}
              onClick={() => setDowntimebigChartData("data8")}
            >
              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                OPP-8
              </span>
              <span className="d-block d-sm-none">
                <i className="tim-icons icon-tap-02" />
              </span>
            </Button>
          </Col>
        </Row>
        <Row>
        <Col sm="12"md="12" >
            <div className="mt-2 mb-2">
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <Button
                        tag="label"
                        className={classNames("btn-simple", {
                          active: DowntimebigChartData === "data",
                        })}
                        color="info"
                        id="0"
                        size="sm"
                        onClick={() => setDowntimebigChartData("data")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Day
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-single-02" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: DowntimebigChartData === "data",
                        })}
                        onClick={() => setDowntimebigChartData("data")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Week
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-gift-2" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: DowntimebigChartData === "data",
                        })}
                        onClick={() => setDowntimebigChartData("data")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Month
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-tap-02" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: DowntimebigChartData === "data",
                        })}
                        onClick={() => setDowntimebigChartData("data")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Year
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-tap-02" />
                        </span>
                      </Button>
                    </ButtonGroup>
            </div>
                  </Col>
           
        </Row>
        <Row className="mt-3">
          <Col xs="6">
            <Card className="card-chart" style={{ height: "336px" }}>
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5 className="card-category">Power to Production</h5>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <LineChart
                  key={Math.random()}
                  xAxisData={xAxisDataValuePerformanceInsights}
                  dataset={datasetPerformanceInsights}
                  height="100%"
                  width="100%"
                />
              </CardBody>
            </Card>
          </Col>
          <Col xs="6">
            <Card className="card-chart" style={{ height: "336px" }}>
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5 className="card-category">Power to Downtime</h5>
                  </Col>
                  
                </Row>
              </CardHeader>
              <CardBody>
                <LineChart
                  key={Math.random()}
                  xAxisData={xAxisDataValuePerformanceInsights}
                  dataset={datasetGraph2}
                  height="100%"
                  width="100%"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs="6">
            <Card className="card-chart" style={{ height: "336px" }}>
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5 className="card-category">Power to Wastage</h5>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <LineChart
                  key={Math.random()}
                  xAxisData={xAxisDataValuePerformanceInsights}
                  dataset={datasetGraph3}
                  height="100%"
                  width="100%"
                />
              </CardBody>
            </Card>
          </Col>
          <Col xs="6">
            <Card className="card-chart" style={{ height: "336px" }}>
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5 className="card-category">Power to Material</h5>
                  </Col>
                  
                </Row>
              </CardHeader>
              <CardBody>
                <LineChart
                  key={Math.random()}
                  xAxisData={xAxisDataValuePerformanceInsights}
                  dataset={datasetGraph4}
                  height="100%"
                  width="100%"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default FocusPowerConsumption;
