import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Button, Card, CardHeader, CardBody, ButtonGroup } from "reactstrap";
import "components/OEE/Oee.css";
import classNames from "classnames";
import { QueryApi } from "QueryApi";
import TableComponentWithoutFilter from "components/ReusableComponents/TableComponentWithoutFilter";

function IfaPrimarySliterPage() {
  const [FourthChart, setFourthChart] = useState("data1");
  const [FifthChart, setFifthChart] = useState("data1");
  const [SevenChart, setSevenChart] = useState("data1");
  const [EightChart, setEightChart] = useState("data1");
  const [productionByMachine, setProductionByMachine] = useState([]);
  const [OleShiftData, setOleShiftData] = useState([]);
  const [jumboByProduct, setJumboByProduct] = useState([]);
  const [OleOperatorEducation, setOleOperatorEducation] = useState([]);

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const currDay = day < 10 ? `0${day}` : day;
  const currMonth = month < 10 ? `0${month}` : month;
  let currentDate = JSON.stringify(`${year}-${currMonth}-${currDay}`);

  const dateData = new Date();
  dateData.setDate(dateData.getDate() - 7);
  var dayDataValue = dateData.getDate();
  var monthDataValue = dateData.getMonth() + 1;
  var yearDataValue = dateData.getFullYear();
  var formattedDay = dayDataValue < 10 ? `0${dayDataValue}` : dayDataValue;
  var formattedMonth =
    monthDataValue < 10 ? `0${monthDataValue}` : monthDataValue;
  var weekDataDate = JSON.stringify(
    `${yearDataValue}-${formattedMonth}-${formattedDay}`
  );

  const previousDate = new Date();
  previousDate.setDate(1);
  const previousDay = previousDate.getDate();
  const previousMonth = previousDate.getMonth() + 1;
  const previousYear = previousDate.getFullYear();
  const formattedPreviousDay =
    previousDay < 10 ? `0${previousDay}` : previousDay;
  const formattedPreviousMonth =
    previousMonth < 10 ? `0${previousMonth}` : previousMonth;
  const previousMonthDate = JSON.stringify(
    `${previousYear}-${formattedPreviousMonth}-${formattedPreviousDay}`
  );

  const yearCurrentDate = new Date();
  yearCurrentDate.setDate(1);
  yearCurrentDate.getMonth(1);
  const yearDay = yearCurrentDate.getDate();
  const yearMonth = yearCurrentDate.getMonth();
  const yearYear = yearCurrentDate.getFullYear();
  const formattedYearDay = yearDay < 10 ? `0${yearDay}` : yearDay;
  const formattedYearMonth = yearMonth < 10 ? `0${yearMonth}` : yearMonth;
  const yearPreviousMonthDate = JSON.stringify(
    `${yearYear}-${formattedYearMonth}-${formattedYearDay}`
  );

  useEffect(() => {
    SevenChartData();
  }, [SevenChart]);
  const SevenChartData = async () => {
    try {
      const data = await QueryApi(`
        query MyQuery {
          bopp_fl0_output_ps_slit_primary(order_by: {date: desc}) {
            date
            org_id
            department_id
            machine_id
            product_id
            total_psslitter_output_volume
            shiftid
            total_psslitter_output__volume
            unit_id
            org {
              organization_node_name
            }
            plant {
              organization_node_name
            }
            dept {
              organization_node_name
            }
          }
        }`);

      setJumboByProduct(data.bopp_fl0_output_ps_slit_primary);
    } catch (error) {}
  };
  ///////////////////production by date/////////////////////////////
  const columnsProductionByDate = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "Date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "plant.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "product_id",
      text: "product_id",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "total_psslitter_output__volume",
      text: "primary Slitter Output",
      headerStyle: { width: "500px" },
    },
  ];
  ////////////////////production by machine ///////////////////////////
  const columnsProductionByMachine = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "400px" },
    },
    {
      dataField: "date",
      text: "date",
      headerStyle: { width: "400px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "plant.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machine_id",
      text: "Machine",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "",
      text: "line",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "total_psslitter_output__volume",
      text: "primary Slitter Output",
      headerStyle: { width: "500px" },
    },
  ];
  ///////////////////production by part no /////////////////////////////
  const columnsProductionByPartNo = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "plant.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "total_psslitter_output__volume",
      text: "primary Slitter Output",
      headerStyle: { width: "500px" },
    },
  ];
  /////////////////production by shift /////////////////////////////////
  const columnsProductionByShift = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machine_id",
      text: "machine",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "plant.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "",
      text: "shift",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "total_psslitter_output__volume",
      text: "primary Slitter Output",
      headerStyle: { width: "500px" },
    },
  ];

  return (
    <>
      <div className="content">
        <Row></Row>
        <Row>
          <Col lg="6">
            <Row>
              <Col lg="12">
                <Card className="card-workforce" style={{ height: "380px" }}>
                  <CardHeader>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Col>
                          <h5 className="card-category">
                            Production by Product
                          </h5>
                        </Col>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ paddingRight: "5px" }}></div>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <TableComponentWithoutFilter
                      columns={columnsProductionByDate}
                      apiData={jumboByProduct}
                      sizePerPage={5}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card className="card-workforce" style={{ height: "380px" }}>
                  <CardHeader>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Col>
                          <h5 className="card-category">Production by Date</h5>
                        </Col>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ paddingRight: "5px" }}></div>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <TableComponentWithoutFilter
                      columns={columnsProductionByPartNo}
                      apiData={jumboByProduct}
                      sizePerPage={5}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
          {/* ///////////////////////////// ////////////////////////////////////////////////// */}
          <Col lg="6">
            <Row>
              <Col lg="12">
                <Card className="card-workforce" style={{ height: "380px" }}>
                  <CardHeader>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Col>
                          <h5 className="card-category">Production by Line </h5>
                        </Col>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ paddingRight: "5px" }}></div>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <TableComponentWithoutFilter
                      columns={columnsProductionByMachine}
                      apiData={jumboByProduct}
                      sizePerPage={5}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card className="card-workforce" style={{ height: "380px" }}>
                  <CardHeader>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Col>
                          <h5 className="card-category">
                            Production by shift{" "}
                          </h5>
                        </Col>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ paddingRight: "5px" }}></div>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <TableComponentWithoutFilter
                      columns={columnsProductionByShift}
                      apiData={jumboByProduct}
                      sizePerPage={5}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default IfaPrimarySliterPage;
