import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import redirectToLoginIfTokenMissing from "views/TokenUtility";
import { QueryApi } from "QueryApi.js";
import JindalTableComponent from "../JindalTableComponent";
import JindalMachineBreakdownDiagnostics from "views/Jindal/downtime/JindalMachineBreakdownDiagnostics";
import BreakdownByShift from "views/BreakdownByShift";
import BreakDownByPart from "views/BreakdownByPart";
import BreakDownOperatorInsights from "views/BreakdownOperatorInsights";

function DownTimeGrids(props) {
  const [activeTab, setActiveTab] = useState("1");
  const storedTenantId = sessionStorage.getItem("selectedTenantId");
  useEffect(() => {
    redirectToLoginIfTokenMissing();
  }, []);
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    redirectToLoginIfTokenMissing();
    downTimeData();
  }, []);

  const downTimeData = async () => {
    try {
      const data = await QueryApi(`
        query MyQuery {
           extrusion_dt_reason_daily(order_by: {date: desc}){
            downtime_contribution_percent
            downtime_reason_category
            downtime_type
            downtime_subtype
            machineid
            mean_time_bet_failures
            recurrances
             dept {
            organization_node_name
            organization_structure_id
           }
            org {
              organization_node_name
            }
            unit {
              organization_shortname
            }
          }
        }
      `);
      setApiData(data?.extrusion_dt_reason_daily || []);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  const convertMillisecondsToHours = (milliseconds) => {
    const hours = milliseconds / (1000 * 60 * 60);
    return hours.toFixed(2);
  };

  const formatPercentage = (percentage) => {
    return parseFloat(percentage).toFixed(3);
  };
  //////////////////////////////////////////////////
  const columns = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machineid",
      text: "Machine Name",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "unit.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "downtime_reason_category",
      text: "Breakdown Category",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "downtime_type",
      text: "Breakdown Type",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "downtime_subtype",
      text: "Breakdown Subtype",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "recurrances",
      text: "Recurrances",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "downtime_contribution_percent",
      text: "% Time loss Contribution",
      formatter: (cell) => formatPercentage(cell),
      headerStyle: { width: "500px" },
    },
    {
      dataField: "mean_time_bet_failures",
      text: "MTBF(in hrs.)",
      formatter: (cell) => convertMillisecondsToHours(cell),
      headerStyle: { width: "500px" },
    },
  ];
  return (
    <>
      <div className="content">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab === "1" ? "active" : ""}
              onClick={() => setActiveTab("1")}
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor: activeTab === "1" ? "#5555e7" : "transparent",
                color: activeTab === "1" ? "white" : "white",
              }}
            >
              Machine Breakdown Diagnostics
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "2" ? "active" : ""}
              onClick={() => setActiveTab("2")}
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor: activeTab === "2" ? "#5555e7" : "transparent",
                color: activeTab === "2" ? "white" : "white",
              }}
            >
              Breakdown by Shift
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "3" ? "active" : ""}
              onClick={() => setActiveTab("3")}
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor: activeTab === "3" ? "#5555e7" : "transparent",
                color: activeTab === "3" ? "white" : "white",
              }}
            >
              Breakdown by Part
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "4" ? "active" : ""}
              onClick={() => setActiveTab("4")}
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor: activeTab === "4" ? "#5555e7" : "transparent",
                color: activeTab === "4" ? "white" : "white",
              }}
            >
              Operator Wise breakdown Insights
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "5" ? "active" : ""}
              onClick={() => setActiveTab("5")}
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor: activeTab === "5" ? "#5555e7" : "transparent",
                color: activeTab === "5" ? "white" : "white",
              }}
            >
              Cross Functional Rating Index
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col>
                <JindalMachineBreakdownDiagnostics />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col>
                <BreakdownByShift />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col>
                <BreakDownByPart />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="4">
            <Row>
              <Col>
                <BreakDownOperatorInsights />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="5">
            <Row>
              <Col>
                <BreakdownByShift />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </>
  );
}

export default DownTimeGrids;
