import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';

const PieChart = (refData, componentName, dataList) => {

    useEffect(() => {
        const chartDom = refData.current;
        const myChart = echarts.init(chartDom);
        const colorData = dataList.map((data) => data.color)
        const nameANDvalue = dataList.map(({ color, ...rest }) => rest)
        const option = {
            tooltip: {
                trigger: 'item'
            },
            legend: {
                // top: '5%',
                left: 'center',
                textStyle: {
                    color: '#4E505F',
                  },
            },
            series: [
                {
                    name: componentName,
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'center',
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: 25,
                            fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: nameANDvalue,
                    color: colorData
                }
            ]
        };
        myChart.setOption(option);
        return () => {
            myChart.dispose();
        };
    }, [dataList]);
};

export default PieChart;
