import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL, SECRET_KEY } from "./Apiurl.js";
import redirectToLoginIfTokenMissing from "./TokenUtility";
import { CSVLink } from "react-csv";
import {
  TabContent,
  TabPane,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import "./datatable.css";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import Modal from "@material-ui/core/Modal";
import ConfigImport from "./ConfigImport";
import { Badge } from "reactstrap";
import PartandCustomerAdd from "./PartandCustomerAdd.js";
import DynamictableNew from "components/DynamicTable/DynamictableNew.js";

const { SearchBar } = Search;

function ProductionPlanOperator() {
  const [dataToExport, setDataToExport] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [struct, setstruct] = useState([]);
  const [orgstruct, setorgstruct] = useState([]);
  console.log(orgstruct,"operator data")
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);

  const token = sessionStorage.getItem("token");
  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const tenantId =
    decodedToken["https://hasura.io/jwt/claims"]["x-hasura-tenant-id"];

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleOpen1 = () => {
    setOpen1(true);
  };
  useEffect(() => {
    fetchData();
    redirectToLoginIfTokenMissing();
  }, []);

  // axios.defaults.headers.common["x-hasura-admin-secret"] = SECRET_KEY;

  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const roleId = decodedToken["sub"];
      const logintenantId =
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
      const storedTenantId = sessionStorage.getItem("selectedTenantId");

      let query = ""; // Define the query variable outside the if-else block
      let variables = {}; // Define the variables object

      if (roleId == 1) {
        if (storedTenantId !== null) {
          query = `
        query MyQuery($TenantId: Int!) {
          operator_production_plan_assignment(order_by: {plan_id: desc},where: { tenant_id: { _eq: $TenantId } }) {
            operator_id
            date
            assignment_id
            plan_id
            tenantid
            estimated_time
            expected_start_time
            machine_id
            operator
            part_no
            skill
            machine_name
          }
        }
      `;
          variables = {
            TenantId: storedTenantId,
          };
        } else {
          query = `
        query MyQuery {
          operator_production_plan_assignment(order_by: {plan_id: desc}) {
            operator_id
            date
            assignment_id
            plan_id
            tenantid
            estimated_time
            expected_start_time
            machine_id
            operator
            part_no
            skill
            machine_name
          }
        } 
      `;
        }
      } else {
        query = ` 
      query MyQuery {
      operator_production_plan_assignment(order_by: {plan_id: desc}) {
        operator_id
        date
        assignment_id
        plan_id
        tenantid
        estimated_time
        expected_start_time
        machine_id
        operator
        part_no
        skill
        machine_name
      } }
      `;
      }
      const response = await axios.post(
        APIURL,
        {
          query: query,
          variables: variables,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const operator_production_plan_assignment =
        response.data.data.operator_production_plan_assignment.map(
          (structure) => ({
            ...structure,
            status: "On",
          })
        );
      setorgstruct(operator_production_plan_assignment);
      setDataToExport(operator_production_plan_assignment);
    } catch (error) {
      console.error(error);
    }
  };
  const activeFormatter = (cell, row) => {
    const isActive = row.is_active === "1";
    return (
      <Badge color={isActive ? "info" : "danger"}>
        {isActive ? "Active" : "Inactive"}
      </Badge>
    );
  };
  const columns = [
    {
      dataField: "plan_id",
      text: "Plan ID",
      headerStyle: { width: '500px' }
    },
    {
      dataField: "date",
      text: "Date",
      headerStyle: { width: '500px' }
    },
    {
      dataField: "part_no",
      text: "Part Number",
      headerStyle: { width: '500px' }
    },
    {
      dataField: "machine_name",
      text: "Machine Name",
      headerStyle: { width: '500px' }
    },
    {
      dataField: "operator",
      text: "Operator",
      headerStyle: { width: '500px' }
    },
    {
      dataField: "skill",
      text: "Skill",
      headerStyle: { width: '500px' }
    },
    {
      dataField: "estimated_time",
      text: "Required Time",
      headerStyle: { width: '500px' }
    },
  ];
  return (
    <div className="content">
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4" className="table-card-header">
                    Assigned Operators For Production Plan
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={struct}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <div>
                        <div
                          className="float-right"
                          style={{
                            display: "flex",
                            marginBottom: "18px",
                            gap: "12px",
                          }}
                        >
                          <div className="float-left">
                            <SearchBar {...props.searchProps} />
                          </div>
                          <div className="float-right">
                            <CSVLink
                              data={dataToExport}
                              filename="production_plan_operator_data.csv" // Customize the filename
                              className="btn btn-primary"
                            >
                              Export to CSV
                            </CSVLink>
                          </div>
                        </div>
                        <BootstrapTable
                          {...props.baseProps}
                          keyField="id"
                          data={orgstruct}
                          columns={columns}
                          filter={filterFactory()}
                          pagination={paginationFactory({
                            bootstrap4: true,
                          })}                          
                          headerClasses="sorted-asc sorted-desc"
                          wrapperClasses="table-responsive"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId={2}>{/* Content for Tab 2 */}</TabPane>
        <TabPane tabId={3}>{/* Content for Tab 3 */}</TabPane>
      </TabContent>
      <Modal
        onClose={handleClose}
        open={open}
        style={{
          boxShadow: "2px solid black",
          width: "100%",
          top: "15%",
          left: "15%",
        }}
      >
        <PartandCustomerAdd />
      </Modal>
      <Modal
        onClose={handleClose1}
        open={open1}
        style={{
          boxShadow: "2px solid black",
          width: "100%",
          top: "15%",
          left: "15%",
        }}
      >
        <ConfigImport />
      </Modal>
      <Modal
      >
        <DynamictableNew fetchData={fetchData}  />
      </Modal>
    </div>
  );
}
export default ProductionPlanOperator;
