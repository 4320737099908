import React, { useState, useEffect } from "react";
import redirectToLoginIfTokenMissing from "views/TokenUtility";
import { TabContent, TabPane, Card, CardBody, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { Badge } from "reactstrap";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { QueryApi } from "QueryApi";

function WorkforceByMachine() {
  const [activeTab, setActiveTab] = useState(1);
  const [struct, setstruct] = useState([]);
  const [orgstruct, setorgstruct] = useState([]);
  const [open, setOpen] = React.useState(false);

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const currDay = day < 10 ? `0${day}` : day;
  const currMonth = month < 10 ? `0${month}` : month;
  let currentDate = JSON.stringify(`${year}-${currMonth}-${currDay}`);

  const token = sessionStorage.getItem("token");
  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const tenantId =
    decodedToken["https://hasura.io/jwt/claims"]["x-hasura-tenant-id"];

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    redirectToLoginIfTokenMissing();
  }, []);

  const [apiData, setApiData] = useState([]);
  useEffect(() => {
    fetchDataList();
  }, []);
  const fetchDataList = async () => {
    const data = await QueryApi(`
  query MyQuery($_eq: date = ${currentDate}) {
    wfl0_workforce_utilization_daily(where: {date: {_eq: $_eq}}) {
      date
      machineid
      workforce_utilization_per
      organization {
        organization_node_name
      }
      plant {
        organization_node_name
      }
    }
  }
  
  
  `);
    setApiData(data?.wfl0_workforce_utilization_daily);
  };

  const activeFormatter = (cell, row) => {
    const isActive = row.is_active === "1";

    return (
      <Badge color={isActive ? "info" : "danger"}>
        {isActive ? "Active" : "Inactive"}
      </Badge>
    );
  };
  function actionFormatter(cell, row) {
    return (
      <div>
        <Col
          className="font-icon-list col-xs-6 col-xs-6"
          lg="2"
          md="3"
          sm="4"
          onClick={() => handleEdit(row)}
        ></Col>
        <Link to={`/admin/employee-job-details/${row.emp_job_id}`}>
          <i className="btn btn-primary tim-icons icon-pencil" />
        </Link>
      </div>
    );
  }

  function handleEdit(row) {}

  const columns = [
    {
      dataField: "fieldname",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px", border: "1px solid #ddd" },
    },
    {
      dataField: "organization.organization_node_name",
      text: " Organization",
      headerStyle: { width: "500px", border: "1px solid #ddd" },
    },
    {
      dataField: "plant.organization_node_name",
      text: "Plant / Unit",
      headerStyle: { width: "500px", border: "1px solid #ddd" },
    },
    {
      dataField: "machineid",
      text: "Machine",
      headerStyle: { width: "500px", border: "1px solid #ddd" },
    },
    {
      dataField: "workforce_utilization_per",
      text: "Utilization %",
      headerStyle: { width: "500px", border: "1px solid #ddd" },
    },
  ];

  function actionFormatter(cell, row) {
    return (
      <div>
        <Col
          className="font-icon-list col-xs-6 col-xs-6"
          lg="2"
          md="3"
          sm="4"
          onClick={() => handleEdit(row)}
        ></Col>
        <FontAwesomeIcon
          icon={faPencilAlt}
          className="btn btn-primary tim-icons icon-pencil"
          style={{
            fontSize: "70%",
            background: "none",
            marginRight: "-46px",
            pointerEvents: "none",
          }}
        />
        <Link to={`/admin/org-structure/${row.organization_structure_id}`}>
          <FontAwesomeIcon
            icon={faTrash}
            className="btn btn-primary tim-icons icon-pencil"
            style={{
              fontSize: "70%",
              background: "none",
              pointerEvents: "none",
            }}
          />
        </Link>
      </div>
    );
  }
  const paginationClass = {
    noPagination: true, // Disable pagination
    paginationSize: 0, // Remove the dropdown button for records per page
    showTotal: (from, to, size) => `Total ${size} records`, // Customize the pagination label
  };

  return (
    <div className="content">
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={apiData}
                    columns={columns}
                    search
                  >
                    {(props) => (
                      <div>
                        {/* <div style={{ width: "50rem" ,height: "25rem"}}> */}
                        <BootstrapTable
                          {...props.baseProps}
                          keyField="id"
                          data={apiData || []}
                          columns={columns}
                          filter={filterFactory()}
                          pagination={paginationFactory({
                            bootstrap4: true,
                            sizePerPage: 5,
                            paginationPosition: "top",
                          })}
                          headerClasses="sorted-asc sorted-desc"
                          wrapperClasses="table-responsive"
                        />
                        {/* </div> */}
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default WorkforceByMachine;
