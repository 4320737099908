import React, { useState, useEffect } from "react";
import { Card, Row, Col, CardHeader, CardBody } from "reactstrap";
import redirectToLoginIfTokenMissing from "views/TokenUtility.js";
import ComboBarLineChart from "assets/charts/ComboBarLineChart";
import LineChart from "assets/charts/LineChart";
import StackChart from "assets/charts/StackChart";

const getData = () => {
  return [
    { quarter: "shift 1", Machine1: 10, Machine2: 5, Machine3: 8 },
    { quarter: "shift 2", Machine1: 12, Machine2: 6, Machine3: 9 },
    { quarter: "shift 3", Machine1: 12, Machine2: 6, Machine3: 9 },
  ];
};

function OeeFocus(props) {
  const [activeTab, setActiveTab] = useState("1");
  const storedTenantId = sessionStorage.getItem("selectedTenantId");
  useEffect(() => {
    redirectToLoginIfTokenMissing();
  }, []);

  const allValues = [
    { xAxisData: "Jan", lineData: 2.3, barData: 162000 },
    { xAxisData: "Mar", lineData: 6.3, barData: 302000 },
    { xAxisData: "May", lineData: 16.2, barData: 800000 },
    { xAxisData: "Jul", lineData: 22.8, barData: 1254000 },
    { xAxisData: "Sep", lineData: 14.5, barData: 950000 },
    { xAxisData: "Nov", lineData: 8.9, barData: 200000 },
  ];
  const barChartName = "Month data";
  const lineChartName = "Average data";

  //////////////// combo bar chart///////////////////////
  const [options, setOptions] = useState({
    data: getData(),
    series: [
      {
        type: "bar",
        xKey: "quarter",
        yKey: "Machine1",
        yName: "Machine1",
        stacked: true,
      },
      {
        type: "bar",
        xKey: "quarter",
        yKey: "Machine2",
        yName: "Machine2",
        stacked: true,
      },
      {
        type: "bar",
        xKey: "quarter",
        yKey: "Machine3",
        yName: "Machine3",
        stacked: true,
      },
    ],
    chart: {
      background: {
        fill: "transparent", // Set your desired background color here
      },
    },
    height: "100%",
  });

  const tableData = [
    { id: 1, name: " ", description: "" },
    { id: 2, name: " ", description: "" },
    { id: 3, name: " ", description: "" },
    { id: 4, name: " ", description: "" },
    { id: 5, name: " ", description: "" },
  ];

  const idColumnStyle = {
    border: "1px solid #dddddd",
    textAlign: "center",
    padding: "8px",
    backgroundColor: "blue", // Set background color to blue
    width: "70px", // Set the width of the ID column
    color: "white",
    fontWeight: "bold",
  };

  const tableCellStyle = {
    border: "1px solid #dddddd",
    textAlign: "left",
    padding: "8px",
    backgroundColor: "#27293d", // Set background color to white
    height: "70px",
  };

  const nameColumnStyle = {
    border: "1px solid #dddddd",
    textAlign: "center",
    padding: "8px",
    width: "200px", // Set the width of the Name column
    backgroundColor: "#27293d",
  };

  const comboBarLineChartStyle = {
    height: "100%",
    width: "100%",
    backgroundColor: "grey", // Set your desired background color here
  };

  const updatedComboBarLineChartStyle = {
    ...comboBarLineChartStyle,
    backgroundColor: "blue", // replace 'desiredColor' with the color you want
  };

  return (
    <>
      <div className="content">
        <Row style={{ display: "flex", alignItems: "center" }}>
          <Col lg="4">
            <Card
              lg="3"
              sm="8"
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CardHeader style={{ height: "20%" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <h5 className="card-category">
                    How has my OEE changed over time?
                  </h5>
                </div>
              </CardHeader>
              <CardBody style={{ height: "75%" }}>
                <LineChart
                  xAxisData={props.xAxisDataList}
                  dataset={props.datasetList}
                  height="100%"
                  width="100%"
                />
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card
              lg="3"
              sm="8"
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CardHeader style={{ height: "20%" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <h5 className="card-category">
                    Which Parts Have Biggest Loss Impact
                  </h5>
                </div>
              </CardHeader>
              <CardBody
                style={{ height: "21rem", marginTop: "0", paddingTop: "0" }}
              >
                <ComboBarLineChart
                  background="transparent"
                  data={allValues}
                  style={updatedComboBarLineChartStyle}
                  barChartName={barChartName}
                  lineChartName={lineChartName}
                  BackgroundColor={"#27293D"}
                />
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card
              lg="3"
              sm="8"
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CardHeader style={{ height: "20%" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <h5 className="card-category">OEE by Shift</h5>
                </div>
              </CardHeader>
              <CardBody
                style={{ height: "21rem", marginTop: "0", paddingTop: "0" }}
              >
                <StackChart
                  xAxisData={props.xAxisDataStack}
                  height="24rem"
                  width="100%"
                  barStyleData={props.barStyleDataStack}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col
            lg="5"
            style={{
              textAlign: "center",
              border: "1px solid white",
              paddingTop: "10px",
              paddingBottom: "1px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4>What's Working</h4>
          </Col>
          <Col lg="2"></Col>
          <Col
            lg="5"
            style={{
              textAlign: "center",
              border: "1px solid white",
              paddingTop: "10px",
              paddingBottom: "1px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4>What's Not Working</h4>
          </Col>
        </Row>
        <Row>
          <Col lg="5" style={{ paddingTop: "40px" }}>
            <table style={{ borderCollapse: "collapse", width: "100%" }}>
              <tbody>
                {tableData.map((item) => (
                  <tr key={item.id}>
                    <td style={idColumnStyle}>{item.id}</td>
                    <td style={nameColumnStyle}>{item.name}</td>
                    <td style={tableCellStyle}>{item.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
          <Col lg="2"></Col>
          <Col lg="5" style={{ paddingTop: "40px" }}>
            <table style={{ borderCollapse: "collapse", width: "100%" }}>
              <tbody>
                {tableData.map((item) => (
                  <tr key={item.id}>
                    <td style={idColumnStyle}>{item.id}</td>
                    <td style={nameColumnStyle}>{item.name}</td>
                    <td style={tableCellStyle}>{item.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default OeeFocus;
