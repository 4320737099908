import React from "react";
import './style.css';
import './responsive.css';import {
  Card,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import 
{
    faBriefcase,
    faUsers,
    faServer,
    faKey,
    faSlidersH,
    faFile,
    faFilm,
    faChartLine,
    faHouse
} from '@fortawesome/free-solid-svg-icons';
function ConfigurationManager(props){
  return (
    <>
      <div className="content">
      
        <div class="container1">
        <h1 className="main-heading">Configuration Manager</h1>
        <div class="breadcrumb-container">
          <Breadcrumb>
            <BreadcrumbItem><FontAwesomeIcon icon={faHouse} /></BreadcrumbItem>
            <BreadcrumbItem active>Configuration Manager</BreadcrumbItem>
          </Breadcrumb>
        </div>
      </div>
        <Row>
        <Col lg="3" className="text-center">
          <a href="manage-organization">
            <Card className="card-chart">
              <CardHeader>
                <div className="icon-circle">
                  <FontAwesomeIcon icon={faBriefcase} className="text-info icon-size" />
                </div>
              </CardHeader>
              <CardTitle tag="h4" className="mt-2">
                <span className="header-title">Manage Organization</span>
              </CardTitle>
            </Card>
          </a>
        </Col>
        
          {/* <Col lg="3" className="text-center">
            <Card className="card-chart">
              <CardHeader>
                <div className="icon-circle">
                    <FontAwesomeIcon icon={faBriefcase} className="text-info icon-size" />
                </div>
              </CardHeader>
              <CardTitle tag="h4" className="mt-2">
              <a href="manage-organization" className="header-title">Manage Organization</a>
              </CardTitle>
            </Card>
          </Col> */}
          <Col lg="3" className="text-center">
          <a href="manage-people">
            <Card className="card-chart">
              <CardHeader>
                <div className="icon-circle">
                <FontAwesomeIcon icon={faUsers} className="text-info icon-size"/>
                </div>
              </CardHeader>
              <CardTitle tag="h4" className="mt-2">
                <span className="header-title">Manage People</span>
              {/* <a href="manage-people" className="header-title">Manage People</a> */}
              </CardTitle>
            </Card>
            </a>
          </Col>
          <Col lg="3" className="text-center">
          <a href="device-management">
            <Card className="card-chart">
              <CardHeader>
                <div className="icon-circle">
                <FontAwesomeIcon icon={faServer} className="text-info icon-size"/>
                </div>
              </CardHeader>
              <CardTitle tag="h4" className="mt-2">
                {/* <a href="device-management" className="header-title">Machine/Equipment Management</a> */}
                <span className="header-title">Machine/Equipment Management</span>
              </CardTitle>
            </Card>
            </a>
          </Col>
          <Col lg="3" className="text-center">
          <a href="manage-role">
            <Card className="card-chart">
              <CardHeader>
                <div className="icon-circle">
                    <FontAwesomeIcon icon={faKey} className="text-info icon-size" />
                </div>
              </CardHeader>
              <CardTitle tag="h4" className="mt-2">
                <span className="header-title">Access and Role Admin</span>
              {/* <a href="manage-role" className="header-title">Access and Role Admin</a> */}
              </CardTitle>
            </Card>
            </a>
          </Col>
        </Row>
        <Row>
          <Col lg="3" className="text-center">
          <a href="#">
            <Card className="card-chart">
              <CardHeader>
              <div className="icon-circle">
                <FontAwesomeIcon icon={faFile} className="text-info icon-size" /> 
                </div>
              </CardHeader>
              <CardTitle tag="h4" className="mt-2">
                <span className="header-title">Evaluation Parameter</span>
              {/* <a href="#" className="header-title">Evaluation Parameter</a> */}
              </CardTitle>
            </Card>
            </a>
          </Col>
          <Col lg="3" className="text-center">
          <a href="#">
            <Card className="card-chart">
              <CardHeader>
                <div className="icon-circle">
                    <FontAwesomeIcon icon={faSlidersH} className="text-info icon-size" /> 
                </div>
              </CardHeader>
              <CardTitle tag="h4" className="mt-2">
                <span className="header-title">Parameter library</span>
              {/* <a href="#" className="header-title">Parameter library</a> */}
              </CardTitle>
            </Card>
            </a>
          </Col>
          <Col lg="3" className="text-center">
          <a href="#">
            <Card className="card-chart">
              <CardHeader>
                <div className="icon-circle">
                <FontAwesomeIcon icon={faFilm} className="text-info icon-size"/>
                </div>
              </CardHeader>
              <CardTitle tag="h4" className="mt-2">
                <span className="header-title">Event library</span>
                {/* <a href="#" className="header-title">Event library</a> */}
              </CardTitle>
            </Card>
            </a>
          </Col>
          <Col lg="3" className="text-center">
          <a href="#">
            <Card className="card-chart">
              <CardHeader>
                <div className="icon-circle">
                    <FontAwesomeIcon icon={faChartLine} className="text-info icon-size" />
                </div>
              </CardHeader>
              <CardTitle tag="h4" className="mt-2">
                <span className="header-title">Financial and Cost</span>
              {/* <a href="#" className="header-title">Financial and Cost</a> */}
              </CardTitle>
            </Card>
            </a>
          </Col>
        </Row> 
        <Row>
          <Col lg="3" className="text-center">
          <a href={`/admin/planing-administration`} >
            <Card className="card-chart">
              <CardHeader>
              <div className="icon-circle">
                <FontAwesomeIcon icon={faFile} className="text-info icon-size" /> 
                </div>
              </CardHeader>
              <CardTitle tag="h4" className="mt-2">
                <span className="header-title">Planing And Administration</span>
              {/* <a href={`/admin/planing-administration`} className="header-title">Planing And Administration</a> */}
              </CardTitle>
            </Card>
            </a>
          </Col>
        </Row> 
      </div>
    </>
  );
}

export default ConfigurationManager;
