import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Nav } from "reactstrap";
import axios from "axios";
import "./stylesidebar.css";
import { APIURL } from "../../views/Apiurl.js";

function Sidebar(props) {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(null);
  const [dashboardData, setDashboardData] = useState([]);
  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  const isActive = (routeName) => {
    return location.pathname === routeName;
  };
  const token = sessionStorage.getItem("token");
  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const roleId = decodedToken["sub"];
  const logintenantId =
    decodedToken["https://hasura.io/jwt/claims"]["x-hasura-org-id"];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem("token");
        const response = await axios.post(
          APIURL,
          {
            query: `
              query MyQuery {
                tenant_dashboard_master(where: { is_default: { _eq: 1 } }) {
                  tenant_dashboard_master_id
                  is_default
                  dashboard_name
                }
              }
            `,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json", // Add your secret key here
            },
          }
        );

        setDashboardData(response.data.data.tenant_dashboard_master);
      } catch (error) {}
    };

    fetchData();
  }, []);

  const [pathRoute, setPathRoute] = useState("");

  useEffect(() => {
    if (logintenantId === "29") {
      setPathRoute("/admin/Extrusion");
    } else {
      setPathRoute("/admin/dashboard");
    }
  }, []);

  return (
    <div className="sidebar">
      <div className="sidebar-wrapper">
        <Nav>
          <SidebarItem
            route={pathRoute}
            iconClassName="fas fa-home"
            isActive={isActive("/admin/dashboard")}
            onClick={handleItemClick}
            activeItem={activeItem}
          />
          <SidebarItem
            route="/admin/configuration-manager"
            iconClassName="fas fa-cogs"
            isActive={isActive("/configuration-manager")}
            onClick={handleItemClick}
            activeItem={activeItem}
          />
          {roleId == 1 && (
            <SidebarItem
              route="/admin/tenants"
              iconClassName="fas fa-users"
              isActive={isActive("/tenants")}
              onClick={handleItemClick}
              activeItem={activeItem}
            />
          )}
          <SidebarItem
            route="/admin/custom-dashboard"
            iconClassName="fas fa-dashboard"
            isActive={isActive("/custom-dasboard")}
            onClick={handleItemClick}
            activeItem={activeItem}
          />
        </Nav>
      </div>
    </div>
  );
}

const SidebarItem = ({
  route,
  iconClassName,
  isActive,
  activeItem,
  onClick,
}) => {
  const isClicked = activeItem === route;

  const handleClick = () => {
    onClick(route);
  };

  return (
    <li
      className={`${isActive ? "active" : ""} ${isClicked ? "clicked" : ""}`}
      onClick={handleClick}
    >
      <NavLink to={route} className="nav-link">
        <i className={`${iconClassName} ${isClicked ? "active-icon" : ""}`} />
      </NavLink>
    </li>
  );
};

export default Sidebar;
