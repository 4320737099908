import React, { useState, useEffect } from "react";
import "./style.css";
import { Card, CardHeader, CardBody } from "reactstrap";
import MachineAlermAndTriggers from "./MachineAlermAndTriggers";
import PreventiveMaintainanceLog from "./PreventiveMaintainanceLog";
import MaintenanceLog from "./MaintenanceLog";
import ToolLog from "./ToolLog";

function ManageOrg(props) {
  const [activeTab, setActiveTab] = useState("1");

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      sessionStorage.setItem("activeTab", tab);
    }
  };
  useEffect(() => {
    // Retrieve the active tab from sessionStorage
    const storedActiveTab = sessionStorage.getItem("activeTab");
    if (storedActiveTab) {
      setActiveTab(storedActiveTab);
    }
  }, []);

  return (
    <>
      <div className="content">
        <div style={{ display: "grid", gridTemplateColumns: "70% 30%" }}>
          <Card>
            <CardHeader>Machine Alarms And Triggers</CardHeader>
            <CardBody>
              <MachineAlermAndTriggers />
            </CardBody>
          </Card>
          <div>
            <Card style={{ marginLeft: "1rem", height: "95%" }}>
              <CardHeader>Key Trends</CardHeader>
              <CardBody></CardBody>
            </Card>
          </div>
        </div>

        <div style={{ display: "grid", gridTemplateColumns: "70% 30%" }}>
          <Card>
            <CardHeader>Preventive Maintenance Log</CardHeader>
            <CardBody>
              <PreventiveMaintainanceLog />
            </CardBody>
          </Card>
          <div>
            <Card style={{ marginLeft: "1rem", height: "95%" }}>
              <CardHeader>Key Trends</CardHeader>
              <CardBody></CardBody>
            </Card>
          </div>
        </div>

        <div style={{ display: "grid", gridTemplateColumns: "70% 30%" }}>
          <Card>
            <CardHeader>Maintainance Log</CardHeader>
            <CardBody>
              <MaintenanceLog />
            </CardBody>
          </Card>
          <div>
            <Card style={{ marginLeft: "1rem", height: "95%" }}>
              <CardHeader>Key Trends</CardHeader>
              <CardBody></CardBody>
            </Card>
          </div>
        </div>

        <div style={{ display: "grid", gridTemplateColumns: "70% 30%" }}>
          <Card>
            <CardHeader>Tool/Die Log</CardHeader>
            <CardBody>
              <ToolLog />
            </CardBody>
          </Card>
          <div>
            <Card style={{ marginLeft: "1rem", height: "95%" }}>
              <CardHeader>Key Trends</CardHeader>
              <CardBody></CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageOrg;
