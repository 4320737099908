import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Container } from "reactstrap";

function Footer() {
  return (
    <footer className="footer">
      <Container fluid>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={true}
        />
        <div className="copyright">
          © {new Date().getFullYear()}{" "}
          <a href="#" target="_blank">
            Parentheses System
          </a>{" "}
          .
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
