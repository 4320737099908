import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Button, Card, CardHeader, CardBody, ButtonGroup } from "reactstrap";
import "components/OEE/Oee.css";
import classNames from "classnames";
import { QueryApi } from "QueryApi";
import TableComponentWithoutFilter from "components/ReusableComponents/TableComponentWithoutFilter";

function GridProductionVolumeGraphOne() {
  const [firstChart, setFirstChart] = useState("data1");
  const [SecondChart, setSecondChart] = useState("data1");
  const [ThirdChart, setThirdChart] = useState("data1");
  const [FourthChart, setFourthChart] = useState("data1");
  const [FifthChart, setFifthChart] = useState("data1");
  const [SixChart, setSixChart] = useState("data1");
  const [SevenChart, setSevenChart] = useState("data1");
  const [EightChart, setEightChart] = useState("data1");
  const [OeeMachineDate, setOeeMachineDate] = useState([]);
  const [OeeShiftDataByData, setOeeShiftDataByData] = useState([]);
  const [productionByMachine, setProductionByMachine] = useState([]);
  const [OleShiftData, setOleShiftData] = useState([]);
  const [OleOperatorExperiance, setOleOperatorExperiance] = useState([]);
  const [OleOperatorEducation, setOleOperatorEducation] = useState([]);

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const currDay = day < 10 ? `0${day}` : day;
  const currMonth = month < 10 ? `0${month}` : month;
  let currentDate = JSON.stringify(`${year}-${currMonth}-${currDay}`);

  const dateData = new Date();
  dateData.setDate(dateData.getDate() - 7);
  var dayDataValue = dateData.getDate();
  var monthDataValue = dateData.getMonth() + 1;
  var yearDataValue = dateData.getFullYear();
  var formattedDay = dayDataValue < 10 ? `0${dayDataValue}` : dayDataValue;
  var formattedMonth =
    monthDataValue < 10 ? `0${monthDataValue}` : monthDataValue;
  var weekDataDate = JSON.stringify(
    `${yearDataValue}-${formattedMonth}-${formattedDay}`
  );

  const previousDate = new Date();
  previousDate.setDate(1);
  const previousDay = previousDate.getDate();
  const previousMonth = previousDate.getMonth() + 1;
  const previousYear = previousDate.getFullYear();
  const formattedPreviousDay =
    previousDay < 10 ? `0${previousDay}` : previousDay;
  const formattedPreviousMonth =
    previousMonth < 10 ? `0${previousMonth}` : previousMonth;
  const previousMonthDate = JSON.stringify(
    `${previousYear}-${formattedPreviousMonth}-${formattedPreviousDay}`
  );

  const yearCurrentDate = new Date();
  yearCurrentDate.setDate(1);
  yearCurrentDate.getMonth(1);
  const yearDay = yearCurrentDate.getDate();
  const yearMonth = yearCurrentDate.getMonth();
  const yearYear = yearCurrentDate.getFullYear();
  const formattedYearDay = yearDay < 10 ? `0${yearDay}` : yearDay;
  const formattedYearMonth = yearMonth < 10 ? `0${yearMonth}` : yearMonth;
  const yearPreviousMonthDate = JSON.stringify(
    `${yearYear}-${formattedYearMonth}-${formattedYearDay}`
  );

  ///// production by shift ///////////////////////////////////////////////////////////

  useEffect(() => {
    FourthChartData();
  }, [FourthChart]);
  const FourthChartData = async () => {
    if (FourthChart === "data1") {
      //  day data API
      try {
        const data = await QueryApi(`
      query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        dm_daily(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}},order_by: {date: desc}) {
          org {
            organization_node_name
          }
          unit {
            organization_node_name
          }
          dept {
            organization_node_name
          }
          date
          total_parts_produced
          machineid
        }
      } `);
        
        setProductionByMachine(data?.dm_daily);
      } catch (error) {}
    } else if (FourthChart === "data2") {
      // week data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        dm_daily(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}},order_by: {date: desc}) {
          org {
            organization_node_name
          }
          unit {
            organization_node_name
          }
          dept {
            organization_node_name
          }
          date
          total_parts_produced
          machineid
        }
      } `);
        setProductionByMachine(data?.dm_daily);
      } catch (error) {}

    } else if (FourthChart === "data3") {
      // month data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        dm_daily(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
          org {
            organization_node_name
          }
          unit {
            organization_node_name
          }
          dept {
            organization_node_name
          }
          date
          total_parts_produced
          machineid
        }
      } `);
        setProductionByMachine(data?.dm_daily);
      } catch (error) {}
    } else if (FourthChart === "data4") {
      // year data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        dm_daily(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
          org {
            organization_node_name
          }
          unit {
            organization_node_name
          }
          dept {
            organization_node_name
          }
          date
          total_parts_produced
          machineid
        }
      } `);
        setProductionByMachine(data?.dm_daily);
      } catch (error) {}
    } else {
      setFourthChart("data1");
    }
  };

  ///// OLE By Shift ///////////////////////////////////////////////////////////

  useEffect(() => {
    FifthChartData();
  }, [FifthChart]);
  const FifthChartData = async () => {
    if (FifthChart === "data1") {
      //  day data API
      try {
        const data = await QueryApi(`
      query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        wfl0_workforce_efficiency_shiftwise(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
          date
          shift
          time_efficiency_per
          production_efficiency_per
          quality_efficiency_per
          overall_labor_effectiveness
        }
      } `);
        const groupedData = groupBy(
          data.wfl0_workforce_efficiency_shiftwise,
          "shift"
        );
        const combinedData = Object.keys(groupedData).map((shift) => {
          const items = groupedData[shift];
          const time_efficiency_per_avg = average(items, "time_efficiency_per");
          const production_efficiency_per_avg = average(
            items,
            "production_efficiency_per"
          );
          const quality_efficiency_per_avg = average(
            items,
            "quality_efficiency_per"
          );
          const product =
            (time_efficiency_per_avg *
              production_efficiency_per_avg *
              quality_efficiency_per_avg) /
            10000;

          return {
            shift,
            time_efficiency_per: time_efficiency_per_avg,
            production_efficiency_per: production_efficiency_per_avg,
            overall_labor_effectiveness: product,
            quality_efficiency_per: quality_efficiency_per_avg,
          };
        });
        setOleShiftData(combinedData);
      } catch (error) {}

      function groupBy(array, key) {
        return array.reduce((result, currentItem) => {
          (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
          );
          return result;
        }, {});
      }

      function average(array, key) {
        return (
          array.reduce((result, currentItem) => result + currentItem[key], 0) /
          array.length
        );
      }
    } else if (FifthChart === "data2") {
      // week data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        wfl0_workforce_efficiency_shiftwise(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
          date
          shift
          time_efficiency_per
          production_efficiency_per
          quality_efficiency_per
          overall_labor_effectiveness
        }
      } `);
        const groupedData = groupBy(
          data.wfl0_workforce_efficiency_shiftwise,
          "shift"
        );
        const combinedData = Object.keys(groupedData).map((shift) => {
          const items = groupedData[shift];
          const time_efficiency_per_avg = average(items, "time_efficiency_per");
          const production_efficiency_per_avg = average(
            items,
            "production_efficiency_per"
          );
          const quality_efficiency_per_avg = average(
            items,
            "quality_efficiency_per"
          );
          const product =
            (time_efficiency_per_avg *
              production_efficiency_per_avg *
              quality_efficiency_per_avg) /
            10000;

          return {
            shift,
            time_efficiency_per: time_efficiency_per_avg,
            production_efficiency_per: production_efficiency_per_avg,
            overall_labor_effectiveness: product,
            quality_efficiency_per: quality_efficiency_per_avg,
          };
        });
        setOleShiftData(combinedData);
      } catch (error) {}

      function groupBy(array, key) {
        return array.reduce((result, currentItem) => {
          (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
          );
          return result;
        }, {});
      }

      function average(array, key) {
        return (
          array.reduce((result, currentItem) => result + currentItem[key], 0) /
          array.length
        );
      }
    } else if (FifthChart === "data3") {
      // month data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        wfl0_workforce_efficiency_shiftwise(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
          date
          shift
          time_efficiency_per
          production_efficiency_per
          quality_efficiency_per
          overall_labor_effectiveness
        }
      } `);
        const groupedData = groupBy(
          data.wfl0_workforce_efficiency_shiftwise,
          "shift"
        );
        const combinedData = Object.keys(groupedData).map((shift) => {
          const items = groupedData[shift];
          const time_efficiency_per_avg = average(items, "time_efficiency_per");
          const production_efficiency_per_avg = average(
            items,
            "production_efficiency_per"
          );
          const quality_efficiency_per_avg = average(
            items,
            "quality_efficiency_per"
          );
          const product =
            (time_efficiency_per_avg *
              production_efficiency_per_avg *
              quality_efficiency_per_avg) /
            10000;

          return {
            shift,
            time_efficiency_per: time_efficiency_per_avg,
            production_efficiency_per: production_efficiency_per_avg,
            overall_labor_effectiveness: product,
            quality_efficiency_per: quality_efficiency_per_avg,
          };
        });
        setOleShiftData(combinedData);
      } catch (error) {}

      function groupBy(array, key) {
        return array.reduce((result, currentItem) => {
          (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
          );
          return result;
        }, {});
      }

      function average(array, key) {
        return (
          array.reduce((result, currentItem) => result + currentItem[key], 0) /
          array.length
        );
      }
    } else if (FifthChart === "data4") {
      // year data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        wfl0_workforce_efficiency_shiftwise(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}}) {
          date
          shift
          time_efficiency_per
          production_efficiency_per
          quality_efficiency_per
          overall_labor_effectiveness
        }
      } `);
        const groupedData = groupBy(
          data.wfl0_workforce_efficiency_shiftwise,
          "shift"
        );
        const combinedData = Object.keys(groupedData).map((shift) => {
          const items = groupedData[shift];
          const time_efficiency_per_avg = average(items, "time_efficiency_per");
          const production_efficiency_per_avg = average(
            items,
            "production_efficiency_per"
          );
          const quality_efficiency_per_avg = average(
            items,
            "quality_efficiency_per"
          );
          const product =
            (time_efficiency_per_avg *
              production_efficiency_per_avg *
              quality_efficiency_per_avg) /
            10000;

          return {
            shift,
            time_efficiency_per: time_efficiency_per_avg,
            production_efficiency_per: production_efficiency_per_avg,
            overall_labor_effectiveness: product,
            quality_efficiency_per: quality_efficiency_per_avg,
          };
        });
        setOleShiftData(combinedData);
      } catch (error) {}

      function groupBy(array, key) {
        return array.reduce((result, currentItem) => {
          (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
          );
          return result;
        }, {});
      }

      function average(array, key) {
        return (
          array.reduce((result, currentItem) => result + currentItem[key], 0) /
          array.length
        );
      }
    } else {
      setFifthChart("data1");
    }
  };

  ///// OLE By Part ///////////////////////////////////////////////////////////

  useEffect(() => {
    SixChartData();
  }, [SixChart]);
  const SixChartData = async () => {
    if (SixChart === "data1") {
      //  day data API
    } else if (SixChart === "data2") {
      // week data API
    } else if (SixChart === "data3") {
      // month data API
    } else if (SixChart === "data4") {
      // year data API
    } else {
      setSixChart("data1");
    }
  };

  /////Production By Date ///////////////////////////////////////////////////////////

  useEffect(() => {
    SevenChartData();
  }, [SevenChart]);
  const SevenChartData = async () => {
    if (SevenChart === "data1") {
      //  day data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        dm_org_department_daily_mv(where: {start_date: {_eq: $start_date}, tenantid: {_eq: $tenantid}},order_by: {start_date: desc}) {
          org {
            organization_node_name
          }
          unit {
            organization_node_name
          }
          dept {
            organization_node_name
          }
          start_date
          total_parts_produced
        }
      } `);
        
        setOleOperatorExperiance(data.dm_org_department_daily_mv);
      } catch (error) {}

      
    } else if (SevenChart === "data2") {
      // week data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        dm_org_department_daily_mv(where: {start_date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}},order_by: {start_date: desc}) {
          org {
            organization_node_name
          }
          unit {
            organization_node_name
          }
          dept {
            organization_node_name
          }
          start_date
          total_parts_produced
        }
      } `);
       
        setOleOperatorExperiance(data.dm_org_department_daily_mv);
      } catch (error) {}

     
    } else if (SevenChart === "data3") {
      // month data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        dm_org_department_daily_mv(where: {start_date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}},order_by: {start_date: desc}) {
                org {
            organization_node_name
          }
          unit {
            organization_node_name
          }
          dept {
            organization_node_name
          }
          start_date
          total_parts_produced
        }
      } `);
       
        setOleOperatorExperiance(data.dm_org_department_daily_mv);
      } catch (error) {}

      
    } else if (SevenChart === "data4") {
      // year data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        dm_org_department_daily_mv(where: {start_date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}},order_by: {start_date: desc}) {
          org {
            organization_node_name
          }
          unit {
            organization_node_name
          }
          dept {
            organization_node_name
          }
          start_date
          total_parts_produced
        }
      } `);
       
        setOleOperatorExperiance(data.dm_org_department_daily_mv);
      } catch (error) {}

     
    } else {
      setSevenChart("data1");
    }
  };
  ///// OLE By Shift ///////////////////////////////////////////////////////////

  useEffect(() => {
    EightChartData();
  }, [EightChart]);
  const EightChartData = async () => {
    if (EightChart === "data1") {
      //  day data API
      try {
        const data = await QueryApi(`
      query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        dm_machine_shiftwise(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}},order_by: {date: desc}) {
          org {
            organization_node_name
          }
          unit {
            organization_node_name
          }
          dept {
            organization_node_name
          }
          date
          machineid
          shift_id
          total_parts_produced
        }
      } `);
        setOleOperatorEducation(data.dm_machine_shiftwise);
      } catch (error) {}
    } else if (EightChart === "data2") {
      // week data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${weekDataDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        dm_machine_shiftwise(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}},order_by: {date: desc}) {
          org {
            organization_node_name
          }
          unit {
            organization_node_name
          }
          dept {
            organization_node_name
          }
          date
          machineid
          shift_id
          total_parts_produced
        }
      } `);
      
        setOleOperatorEducation(data.dm_machine_shiftwise);
      } catch (error) {}
    } else if (EightChart === "data3") {
      // month data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${previousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        dm_machine_shiftwise(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}},order_by: {date: desc}) {
          org {
            organization_node_name
          }
          unit {
            organization_node_name
          }
          dept {
            organization_node_name
          }
          date
          machineid
          shift_id
          total_parts_produced
        }
      } `);
        setOleOperatorEducation(data.dm_machine_shiftwise);
      } catch (error) {}
    } else if (EightChart === "data4") {
      // year data API
      try {
        const data = await QueryApi(`
      query MyQuery($start_date: date = ${yearPreviousMonthDate}, $tenantid: Int! = ${sessionStorage.getItem("tenantid")}, $end_date: date = ${currentDate}) {
        dm_machine_shiftwise(where: {date: {_gte: $start_date,_lte: $end_date}, tenantid: {_eq: $tenantid}},order_by: {date: desc}) {
          org {
            organization_node_name
          }
          unit {
            organization_node_name
          }
          dept {
            organization_node_name
          }
          date
          machineid
          shift_id
          total_parts_produced
        }
      } `);
        setOleOperatorEducation(data.dm_machine_shiftwise);
      } catch (error) {}
    } else {
      setEightChart("data1");
    }
  };
  ///////////////////production by date/////////////////////////////
  const columnsProductionByDate = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "start_date",
      text: "Date",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "unit.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "total_parts_produced",
      text: "total production",
      headerStyle: { width: "500px" },
    },
  ];
  ////////////////////production by machine ///////////////////////////
  const columnsProductionByMachine = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "400px" },
    },
    {
      dataField: "date",
      text: "date",
      headerStyle: { width: "400px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "unit.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machineid",
      text: "Machine",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "total_parts_produced",
      text: "total production",
      headerStyle: { width: "500px" },
    },
  ];
  ///////////////////production by part no /////////////////////////////
  const columnsProductionByPartNo = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "date",
      text: "part no",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "unit.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machineid",
      text: "total production",
      headerStyle: { width: "500px" },
    },
  ];
  /////////////////production by shift /////////////////////////////////
  const columnsProductionByShift = [
    {
      dataField: "serialNo",
      text: "Sr No",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "500px" },
    },
    {
      dataField: "shift_id",
      text: "shift",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machineid",
      text: "machine",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "org.organization_node_name",
      text: "Organization",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "unit.organization_node_name",
      text: "Plant",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "dept.organization_node_name",
      text: "Department",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "total_parts_produced",
      text: "total production",
      headerStyle: { width: "500px" },
    },
  ];

  return (
    <>
      <div className="content">
        <Row>
        </Row>
        <Row>
          <Col lg="6">
            <Row>
              <Col lg="12">
                <Card className="card-workforce" style={{ height: "350px" }}>
                  <CardHeader>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Col>
                          <h5 className="card-category">Production by date</h5>
                        </Col>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ paddingRight: "5px" }}>
                        <ButtonGroup
                            className="btn-group-toggle float-right"
                            data-toggle="buttons"
                            // style={{ marginLeft: '50px' }}
                          >
                            <Button
                              tag="label"
                              className={classNames("btn-simple", {
                                active: SevenChart === "data1",
                              })}
                              color="info"
                              id="0"
                              size="sm"
                              onClick={() => setSevenChart("data1")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Day
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-single-02" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="1"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: SevenChart === "data2",
                              })}
                              onClick={() => setSevenChart("data2")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Week
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-gift-2" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="2"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: SevenChart === "data3",
                              })}
                              onClick={() => setSevenChart("data3")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Month
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-tap-02" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="2"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: SevenChart === "data4",
                              })}
                              onClick={() => setSevenChart("data4")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Year
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-tap-02" />
                              </span>
                            </Button>
                          </ButtonGroup>


                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <TableComponentWithoutFilter columns={columnsProductionByDate} apiData={OleOperatorExperiance} sizePerPage={5}/>
                   
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card className="card-workforce" style={{ height: "350px" }}>
                  <CardHeader>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Col>
                          <h5 className="card-category">Production by Product</h5>
                        </Col>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ paddingRight: "5px" }}>
                          <ButtonGroup
                            className="btn-group-toggle float-right"
                            data-toggle="buttons"
                            // style={{ marginLeft: '50px' }}
                          >
                            <Button
                              tag="label"
                              className={classNames("btn-simple", {
                                active: FifthChart === "data1",
                              })}
                              color="info"
                              id="0"
                              size="sm"
                              onClick={() => setFifthChart("data1")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Day
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-single-02" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="1"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: FifthChart === "data2",
                              })}
                              onClick={() => setFifthChart("data2")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Week
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-gift-2" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="2"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: FifthChart === "data3",
                              })}
                              onClick={() => setFifthChart("data3")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Month
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-tap-02" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="2"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: FifthChart === "data4",
                              })}
                              onClick={() => setFifthChart("data4")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Year
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-tap-02" />
                              </span>
                            </Button>
                          </ButtonGroup>
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                  <TableComponentWithoutFilter columns={columnsProductionByPartNo} sizePerPage={5}/>
                  
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
          {/* ///////////////////////////// OLE ////////////////////////////////////////////////// */}
          <Col lg="6">
            <Row>
              <Col lg="12">
                <Card className="card-workforce" style={{ height: "350px" }}>
                  <CardHeader>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Col>
                          <h5 className="card-category">Production by Machine </h5>
                        </Col>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ paddingRight: "5px" }}>
                         
                          <ButtonGroup
                            className="btn-group-toggle float-right"
                            data-toggle="buttons"
                            // style={{ marginLeft: '50px' }}
                          >
                            <Button
                              tag="label"
                              className={classNames("btn-simple", {
                                active: FourthChart === "data1",
                              })}
                              color="info"
                              id="0"
                              size="sm"
                              onClick={() => setFourthChart("data1")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Day
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-single-02" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="1"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: FourthChart === "data2",
                              })}
                              onClick={() => setFourthChart("data2")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Week
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-gift-2" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="2"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: FourthChart === "data3",
                              })}
                              onClick={() => setFourthChart("data3")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Month
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-tap-02" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="2"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: FourthChart === "data4",
                              })}
                              onClick={() => setFourthChart("data4")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Year
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-tap-02" />
                              </span>
                            </Button>
                          </ButtonGroup>
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                  <TableComponentWithoutFilter columns={columnsProductionByMachine} apiData={productionByMachine} sizePerPage={5}/>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card className="card-workforce" style={{ height: "350px" }}>
                  <CardHeader>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Col>
                          <h5 className="card-category">Production volume by shift </h5>
                        </Col>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ paddingRight: "5px" }}>
                          <ButtonGroup
                            className="btn-group-toggle float-right"
                            data-toggle="buttons"
                            // style={{ marginLeft: '50px' }}
                          >
                            <Button
                              tag="label"
                              className={classNames("btn-simple", {
                                active: EightChart === "data1",
                              })}
                              color="info"
                              id="0"
                              size="sm"
                              onClick={() => setEightChart("data1")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Day
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-single-02" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="1"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: EightChart === "data2",
                              })}
                              onClick={() => setEightChart("data2")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Week
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-gift-2" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="2"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: EightChart === "data3",
                              })}
                              onClick={() => setEightChart("data3")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Month
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-tap-02" />
                              </span>
                            </Button>
                            <Button
                              color="info"
                              id="2"
                              size="sm"
                              tag="label"
                              className={classNames("btn-simple", {
                                active: EightChart === "data4",
                              })}
                              onClick={() => setEightChart("data4")}
                            >
                              <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                Year
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="tim-icons icon-tap-02" />
                              </span>
                            </Button>
                          </ButtonGroup>
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                  <TableComponentWithoutFilter columns={columnsProductionByShift} apiData={OleOperatorEducation} sizePerPage={5} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default GridProductionVolumeGraphOne;
