import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Progress,
} from "reactstrap";
import Select from "react-select";
import axios from "axios";
import  { APIURL } from './Apiurl.js';
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

function UdpMasterAdd() {
  const [activeStep, setActiveStep] = useState(1);
  const [checked, setChecked] = useState(false);
  const [progress, setProgress] = useState(0);
  const [udpedit, setudpedit] = useState({
    deviceprofile: "",
    selectedprofiletype: "",
    devicedescription: "",
    parentupdid: "",
    startdate: "",
    enddate: "",
  });

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${APIURL}/api/udp/${id}`)
      .then((response) => {
        setudpedit((prevState) => ({
          ...prevState,
          deviceprofile: response.data.device_profile,
          devicedescription: response.data.device_description,
          parentupdid: response.data.parent_udp_id,
          startdate: response.data.start_date, 
          enddate: response.data.end_date,
          selectedprofiletype: response.data.device_profile_type,
        }));
        const initialProfileType = response.data.device_profile_type;
      const selectedOption = profiletypeOptions.find(option => option.value === initialProfileType);
      setselectedprofiletype(selectedOption);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if the machine name is provided
    if (!udpedit.deviceprofile) {
      alert("Please enter a machine name.");
      return;
    }

    axios
      .post(`${APIURL}/api/udp/${id}`, udpedit)
      .then((response) => {
        console.log(response);
        navigate(`/udp/${id}`);
        if (response.status === 201) {
          window.location.href = "/admin/udp/udp-all";
        } else {
          // Reset the form or show a success message
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setudpedit((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleNext = () => {
    if (activeStep === 1 && progress === 0) {
      setProgress(50);
    } else if (activeStep === 2 && progress === 50) {
      setProgress(100);
    }
    setActiveStep(activeStep + 1);
  };

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };
  const profiletypeOptions = [
    { value: "Type 1", label: "Type 1" },
    { value: "Type 2", label: "Type 2" },
    { value: "Type 3", label: "Type 3" },
  ];
  const [selectedprofiletype, setselectedprofiletype] = useState(null);
  const handleprofiletypeChange = (selectedOption) => {
    setselectedprofiletype(selectedOption);
    setudpedit((prevState) => ({
      ...prevState,
      selectedprofiletype: selectedOption.value,
    }));
  };
  const selectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      borderColor: "#ccc",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "rgba(255, 255, 255, 0.9)",
    }),
  };
  const renderStepContent = () => {
    switch (activeStep) {
      case 1:
        return (
          <>
            <Row>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>Device Profile</label>
                  <Input
                    placeholder="Device Profile"
                    type="text"
                    name="deviceprofile"
                    value={udpedit.deviceprofile}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>Select Device Profile Type</label>
                  <Select
                    options={profiletypeOptions}
                    value={selectedprofiletype}
                    onChange={handleprofiletypeChange}
                    styles={selectStyles}
                    />
                </FormGroup>
              </Col>
            </Row>
          </>
        );
      case 2:
        return (
          <>
            <Row>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>Device Description</label>
                  <Input
                    placeholder="Device Description"
                    type="text"
                    name="devicedescription"
                    value={udpedit.devicedescription}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>Parent UDP ID</label>
                  <Input
                    placeholder="Parent UDP ID"
                    type="text"
                    name="parentupdid"
                    value={udpedit.parentupdid}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              
            </Row>
          </>
        );
      case 3:
        return (
          <>
            <Row>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>Start Date</label>
                  <Input
                    placeholder="Start Date"
                    type="date"
                    name="startdate"
                    value={udpedit.startdate}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>End Date</label>
                  <Input
                    placeholder="End Date"
                    type="date"
                    name="enddate"
                    value={udpedit.enddate}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        );
      default:
        return null;
    }
  };

  const calculateProgress = () => {
    return Math.round(((activeStep - 1) / 2) * 100); // Update the denominator to match the total number of steps minus one
  };

  const renderProgressBarDestinations = () => {
    const step1Class = activeStep >= 1 ? "progress-destination-active" : "";
    const step2Class = activeStep >= 2 ? "progress-destination-active" : "";
    const step3Class = activeStep >= 3 ? "progress-destination-active" : "";
    const step4Class = activeStep >= 4 ? "progress-destination-active" : "";

    return (
      <div className="progress-destinations">
        <div className={`progress-destination ${step1Class}`}>
          <span>1</span>
        </div>
        <div className={`progress-destination ${step2Class}`}>
          <span>2</span>
        </div>
        <div className={`progress-destination ${step3Class}`}>
          <span>3</span>
        </div>
        <div className={`progress-destination ${step4Class}`}>
          <span>4</span>
        </div>
      </div>
    );
  };

  return (
    <div className="content">
      <Row>
        <Col md="8">
          <div className="progress-container">
            {renderProgressBarDestinations()}
            <Progress
              max="100"
              value={calculateProgress()}
              className="progress-bar-sm progress-bar-striped"
            />
          </div>
        </Col>
      </Row>
      <Row className="progress-row">
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Add New Employee</h5>
            </CardHeader>
            <CardBody>
              <Form>{renderStepContent()}</Form>
            </CardBody>
            <CardFooter>
              <div className="text-right">
                {activeStep > 1 && (
                  <Button color="primary" onClick={handlePrevious}>
                    Previous
                  </Button>
                )}
                {activeStep < 3 && (
                  <Button color="primary" onClick={handleNext}>
                    Next
                  </Button>
                )}
                {activeStep === 3 && (
                  <Button color="success" onClick={handleSubmit}>
                    Submit
                  </Button>
                )}
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default UdpMasterAdd;
