import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper } from '@fortawesome/free-solid-svg-icons';
import TableComponentWithoutFilter from "components/ReusableComponents/TableComponentWithoutFilter";
import Modal from "@material-ui/core/Modal";
import { QueryApi } from "QueryApi";

function OeeMachinesLevelOne(props) {
  const [ showReport,setReport] =useState(false);
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const currDay = day < 10 ? `0${day}` : day;
  const currMonth = month < 10 ? `0${month}` : month;
  let currentDate = JSON.stringify(`${year}-${currMonth}-${currDay}`);

  /////// Machine ///////////////////////////////////////////////////////////////////////////

  const [machineList, setMachineDataList] = useState([]);
  useEffect(() => {
    MachineData();
  }, []);
  const MachineData = async () => {
    try {
      const data = await QueryApi(`
       query MyQuery($date: date = ${currentDate}, $tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
           dm_daily(where: {date: {_eq: $date}, tenantid: {_eq: $tenantid}}) {
             machineid
             oee
             machine_availability_percent
             machine_performance_percent
             quality_percent
           }
         } `);
      setMachineDataList(data.dm_daily);
    } catch (error) {
      setMachineDataList(0);
    }
  };
  //////////////////////////////////////////////////////////////////////////
  const columns = [
    {
      dataField: "Machine",
      text: "Machine",
    },
    {
      dataField: "",
      text: "Part No",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "parto",
      text: "Part Name",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "end_customer",
      text: "Operator",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "operation_details",
      text: "Production Start Time",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machineid",
      text: "machine no",
      headerStyle: { width: "500px" },
    },
    {
      dataField: "machine_name",
      text: "Production End Time",
      headerStyle: { width: "500px" },
    },
  ];

  const [allMachineList, setAllMachineDataList] = useState([]);
  useEffect(() => {
    dataFirstCall();
  }, []);
  const dataFirstCall = async () => {
    try {
      const data = await QueryApi(`       
      query MyQuery($tenantid: Int = ${sessionStorage.getItem("tenantid")}) {
        tnt_org_machine_assignment(where: {tenantid: {_eq: $tenantid}}) {
          machineid
        }
      }
    `);
      setAllMachineDataList(data.tnt_org_machine_assignment);
    } catch (error) {
      setAllMachineDataList([]);
    }
  };

  const machineArr = allMachineList
    .filter(
      (data) => !machineList.some((dataM) => dataM.machineid === data.machineid)
    )
    .map((data) => data.machineid);

  let lastArr = machineList;
  machineArr.forEach((data, index) => {
    lastArr.push({
      machineid: machineArr[index],
      oee: "No Data",
      machine_availability_percent: "No Data",
      machine_performance_percent: "No Data",
      quality_percent: "No Data",
    });
  });

  return (
    <>
      <div className="content">
        <div></div>
        <Row>
          <Col lg="3">
            <Card
              className="Workforce-availability"
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                color: "white",
                height: "40px",
                display: "flex",
                alignItems: "left",
                justifyContent: "center",
              }}
            >
              OEE Machines
            </Card>
          </Col>
        </Row>

        <Row
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
          }}
        >
          {machineList.map((data) => (
            <Col sm="3" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
              <div style={{ padding: "5px" }}>
                <Card
                  style={{ height: "15rem", color: "white", padding: "1rem",position: "relative",pointer:"cursor"  }}
                  onClick={() => setReport(true)}
                >
                  <FontAwesomeIcon 
                  icon={faNewspaper} 
                  style={{ position: "absolute", top: "10px", right: "10px",height:"40px",width:"40px", }} 
                 
                  />
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "20px",
                    }}
                  >
                    {data.machineid}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      paddingTop: "0.3rem",
                      fontSize: "20px",
                    }}
                  >
                    OEE ={" "}
                    <span style={{ color: "#1976D2" }}>
                      {data.oee} {data.oee === "No Data" ? "" : "%"}
                    </span>
                  </div>

                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      paddingTop: "1rem",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ paddingTop: "0.5rem", fontSize: "20px" }}>
                        Availability
                      </div>
                      <div style={{ paddingTop: "0.5rem", fontSize: "20px" }}>
                        Performance
                      </div>
                      <div style={{ paddingTop: "0.5rem", fontSize: "20px" }}>
                        Quality
                      </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{
                          paddingTop: "0.5rem",
                          fontSize: "20px",
                          marginLeft: "10px",
                          color: "#1976D2",
                        }}
                      >
                        {data.machine_availability_percent}
                        {data.machine_availability_percent === "No Data"
                          ? ""
                          : "%"}
                      </div>
                      <div
                        style={{
                          paddingTop: "0.5rem",
                          fontSize: "20px",
                          marginLeft: "10px",
                          color: "#1976D2",
                        }}
                      >
                        {data.machine_performance_percent}
                        {data.machine_performance_percent === "No Data"
                          ? ""
                          : "%"}
                      </div>
                      <div
                        style={{
                          paddingTop: "0.5rem",
                          fontSize: "20px",
                          marginLeft: "10px",
                          color: "#1976D2",
                        }}
                      >
                        {data.quality_percent}
                        {data.quality_percent === "No Data" ? "" : "%"}
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </Col>
          ))}
        </Row>

       
       {/* <Modal
          open={showReport}
          onClose={() => setReport(false)}
          style={{
            boxShadow: "2px solid black",
            width: "70%",
            top: "15%",
            left: "15%",
            
          }}
          
        >
          
            <TableComponentWithoutFilter columns={columns} />
          
        </Modal> */}
      </div>
    </>
  );
}

export default OeeMachinesLevelOne;
